import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        noContentContainer: {
            textAlign: 'center',
            marginTop: '8rem',
        },
        noLabel: {
            fontSize: '1.5rem',
            opacity: '0.2',
        },
        container: {
            paddingBottom: '6rem',
        },
        checkboxConatiner: {},
        actionsContainer: {
            padding: '0.6rem',
            textAlign: 'right',
            position: 'sticky',
            width: '100%',
            zIndex: '1',
            top: '78px',
        },
        timelineDot: {
            backgroundColor: 'white',
            margin: '0px',
            padding: '0',
        },
        timelineAvatar: {
            height: '50px',
            width: '50px',
        },
        timelineContent: {
            padding: '0rem',
            paddingBottom: '1rem',
            marginLeft: '1rem',
            marginTop: '0.5rem',
        },
        timelineContentInnerContainer: {
            padding: '1rem',
        },
        nameDate: {
            fontSize: '12px',
            color: '#6f6f6f',
        },
        timelineFooter: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        description: {
            fontSize: '15px',
            marginTop: '5px',
            marginBottom: '5px',
        },
        timeplineImage: {
            display: 'inline-block',
        },
        modalImage: {
            height: '100px',
            width: '100px',
            marginTop: '10px',
            marginRight: '10px',
            marginLeft: '5px',
            display: 'inline-block',
        },
        readBy: {
            fontSize: '12px',
            color: 'red',
        },
        paginationContainer: {
            justifyContent: 'center',
        },
        contentContainer: {
            marginTop: '72px',
        },
    };
});
