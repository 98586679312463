import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        missingOppositeContent: {
            '&:before': {
                content: '""!important',
                flex: '0!important',
                padding: '0px!important',
            },
        },
        timelineDot: {
            backgroundColor: 'white',
            margin: '0px',
            padding: '0',
        },
        timelineAvatar: {
            height: '50px',
            width: '50px',
        },
        timelineContent: {
            padding: '0rem',
            paddingBottom: '1rem',
            marginLeft: '1rem',
            marginTop: '0.2rem',
        },
        timelineContentInnerContainer: {
            padding: '1rem',
        },
        name: {
            fontSize: '12px',
            color: '#6f6f6f',
            marginBottom: '0.3rem',
            marginTop: '-0.3rem',
        },
        nameDate: {
            fontSize: '12px',
            color: '#6f6f6f',
        },
        timelineFooter: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        description: {
            fontSize: '15px',
            marginTop: '5px',
            marginBottom: '5px',
        },
        timeplineImage: {
            display: 'inline-block',
        },
        modalImage: {
            height: '100px',
            width: '100px',
            marginTop: '10px',
            marginRight: '10px',
            marginLeft: '5px',
            display: 'inline-block',
            objectFit: 'contain',
        },
        dicomImage: {
            height: '100px',
            width: '100px',
            marginTop: '10px',
            marginRight: '10px',
            marginLeft: '5px',
            display: 'inline-block',
            objectFit: 'contain',
            cursor: 'pointer',
        },
        readStatusContainer:{
            width: '50%'
        },
        readBy: {
            fontSize: '12px',
            fontStyle: 'oblique',
            marginTop: '5px',
        },
        red: {
            color: 'red',
        },
        green: {
            color: 'green',
        },
        orange: {
            color: '#FFBF00',
        },
        readByClick: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
        createdAtContainer:{
            width: '100%', 
            textAlign: 'right'
        }
    };
});
