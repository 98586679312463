import UsersIcon from '@material-ui/icons/Group';
import BusinessIcon from '@material-ui/icons/Business';
import HospitalIcon from '@material-ui/icons/LocalHospital';
import VendorsIcon from '@material-ui/icons/AssignmentInd';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationsIcon from '@material-ui/icons/Notifications';
import FolderIcon from '@material-ui/icons/Folder';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import BarChartIcon from '@material-ui/icons/BarChart';
import ApartmentIcon from '@material-ui/icons/Apartment';

export const PAGE_SIZE = 10;
export const PAGE_SIZE_OPTIONS = [5, 10, 20, 50];

export const superAdminMenu = [
    {
        name: 'Users',
        path: '/users',
        icon: <UsersIcon/>,
    },
    {
        name: 'Organizations',
        path: '/orgs',
        icon: <BusinessIcon/>,
    },
    {
        name: 'Healthcare Organizations',
        path: '/healthcareOrgs',
        icon: <HospitalIcon/>,
    },
    {
        name: 'Integration Vendors',
        path: '/vendors',
        icon: <VendorsIcon/>,
    },
    {
        name: 'Data Utilities',
        path: '/dataUtilities',
        icon: <SettingsIcon/>,
    },
    // {
    //   name: 'Transaction Monitoring',
    //   path: '/transactionMonitoring',
    //   icon: <ImportExportIcon />,
    // },
];

export const userMenu = [
    {
        name: 'New Updates',
        isBadge: true,
        path: '/newUpdates',
        icon: <NotificationsIcon/>,
    },
    {
        name: 'Cases',
        path: '/cases',
        icon: <FolderIcon/>,
    },
    {
        name: '-',
        path: '/athletes',
        icon: <DirectionsRunIcon/>,
    },
    {
        name: 'Analytics Dashboard',
        path: '/analyticsDashboard',
        icon: <BarChartIcon/>,
    },
];

export const coachMenu = [
    {
        name: 'Play Status',
        path: '/playStatusTeamRoster',
        icon: <DirectionsRunIcon/>,
    },
];

export const orgAdminMenu = [
    {
        name: 'Organizations',
        path: '/orgs',
        icon: <BusinessIcon/>,
    },
    {
        name: 'Users',
        path: '/users',
        icon: <UsersIcon/>,
    },
    {
        name: 'Data Utilities',
        path: '/dataUtilities',
        icon: <SettingsIcon/>,
    },
];

export const deptAdminMenu = [
    {
        name: 'Departments',
        path: '/departments',
        icon: <ApartmentIcon/>,
    },
    {
        name: 'Users',
        path: '/users',
        icon: <UsersIcon/>,
    },
    {
        name: 'Data Utilities',
        path: '/dataUtilities',
        icon: <SettingsIcon/>,
    },
];

export const bothAdminMenu = [
    {
        name: 'Organizations',
        path: '/orgs',
        icon: <BusinessIcon/>,
    },
    {
        name: 'Departments',
        path: '/departments',
        icon: <ApartmentIcon/>,
    },
    {
        name: 'Users',
        path: '/users',
        icon: <UsersIcon/>,
    },
    {
        name: 'Data Utilities',
        path: '/dataUtilities',
        icon: <SettingsIcon/>,
    },
];

export const APP_URLS = {
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgotPassword',

    ORGANIZATIONS: '/orgs',
    ORGANIZATIONS_DEPTS: '/orgs/departments',
    ORGANIZATIONS_ADD: '/orgs/add',
    ORGANIZATIONS_EDIT: '/orgs/edit',
    ORGANIZATIONS_ADD_ADMIN: '/orgs/addAdmin',
    ORGANIZATIONS_DEPTS_ADD_ADMIN: '/orgs/departments/addAdmin', 
    ORGANIZATIONS_ADD_CAREGIVER: '/orgs/addCaregiver',
    ORGANIZATIONS_DEPTS_ADD_CAREGIVER: '/orgs/departments/addCaregiver', 

    DEPARTMENTS: '/departments',
    DEPARTMENTS_ADD_CAREGIVER: '/departments/addCaregiver',

    HEALTHCARE_ORGANIZATIONS: '/healthcareOrgs',
    HEALTHCARE_ORGANIZATIONS_ADD: '/healthcareOrgs/add',
    HEALTHCARE_ORGANIZATIONS_EDIT: '/healthcareOrgs/edit',
    HEALTHCARE_ORGANIZATIONS_ORGS: '/healthcareOrgs/orgs',
    HEALTHCARE_ORGANIZATIONS_ORGS_EDIT: '/healthcareOrgs/orgs/edit',
    HEALTHCARE_ORGANIZATIONS_ORGS_ADD_CAREGIVER: '/healthcareOrgs/orgs/addCaregiver',
    HEALTHCARE_ORGANIZATIONS_ORGS_ADD_ADMIN: '/healthcareOrgs/orgs/addAdmin',
    HEALTHCARE_ORGANIZATIONS_ORGS_DEPTS: '/healthcareOrgs/orgs/departments',
    HEALTHCARE_ORGANIZATIONS_ORGS_DEPTS_ADD_CAREGIVER: '/healthcareOrgs/orgs/departments/addCaregiver',
    HEALTHCARE_ORGANIZATIONS_ORGS_DEPTS_ADD_ADMIN: '/healthcareOrgs/orgs/departments/addAdmin',

    NEW_UPDATES: '/newUpdates',
    ATHLETES: '/athletes',
    CASES: '/cases',
    CASES_ADD: '/cases/addCase',

    PLAY_STATUS_TEAM_ROSTER: '/playStatusTeamRoster'
}

export const ROLES = {
   LIMITED_USER : 'Limited User',
   SIRENMD_ADMIN : 'SirenMD Admin',
   COACH : 'Coach',
   DOCTOR: 'Doctor',
   ATHLETIC_TRAINER: 'Athletic Trainer',
   NURSE: 'Nurse',
   PHYSICIAN_ASSISTANT : 'Physician Assistant',
   PHYSICAL_THERAPIST: 'Physical Therapist',
   HEALTHCARE_PROFESSIONAL : 'Healthcare Professional'
}

export const ORGANIZATION_TYPES = {
    HEALTHCARE_PROVIDER: 1,
    EDUCATIONAL_INSTITUTION: 2,
    NON_HEALTHCARE_BUSINESS_OR_Corporation: 3
}

export const IMAGE_FILE_TYPES = ['jpg', 'jpeg', 'webp', 'png'];


export const playingStatusList = [
    {
        id: 1,
        desc: 'Playing',
    },
    {
        id: 0,
        desc: 'Not Playing',
    },
];

export const timePeriodList = [
    {
        id: 1,
        description: 'past 7 days',
    },
    {
        id: 2,
        description: 'past 30 days',
    },
    {
        id: 3,
        description: 'past 90 days',
    },
    {
        id: 4,
        description: 'past 6 months',
    },
    {
        id: 5,
        description: 'Past year',
    },
    {
        id: 6,
        description: 'All time',
    },
];

export const filtersList = [
    {
        id: 1,
        desc: 'Last Name',
        value: 'lastName',
    },
    {
        id: 2,
        desc: 'Jersey Number',
        value: 'jursyNum',
    },
];