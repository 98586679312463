import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        actionButtonsContainer: {
            marginRight: '1rem',
            marginBottom: '0.5rem',
        },
        contentContainer:{
            height: '100%',
            overflowY: 'auto',
            
            [theme.breakpoints.up('md')]: {
                height: '100%',
                overflowY: 'hidden',
            },

            [theme.breakpoints.down('md')]: {
                height: '100%',
                overflowY: 'hidden',
            },
            
        }
    };
});
