import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, NavLink, useParams} from 'react-router-dom';
import {Box, Breadcrumbs, Button, Icon, Paper, Tab, Typography,} from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import HealthcareVendors from '../../../IntegrationVendors/components/VendorsForHCOrg/VendorsForHCOrg';
import OrganizationsForHCOrg from '../../../Organizations/components/OrganizationsForHCOrg/OrganizationsForHCOrg';
import HealthcareCaregivers from '../../../Caregivers/components/HealthcareCaregivers/HealthcareCaregivers';
import {getHCOrgByIdRequest, toggleActivationRequest,} from '../../HealthcareOrganizationsApiActions';
import ConfirmationDialog from '../../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import {useStyles} from './styles';
import defaultProfile from '../../../../assets/group.png';
import {handleTabChange} from '../../HealthcareOrganizationsActions';
import {getIsTeamList, getUserInfo} from '../../../../utilities/storage';
import ImageLoader from 'react-load-image';
import Preloader from '../../../../utilities/components/Preloader/Preloader';
import {ROLES, APP_URLS} from '../../../../utilities/constants';

/**
 * function to get message for activation dialog
 * @param hcorg: hcorg details
 * @param classes: classes from css
 * @returns {JSX.Element}
 * @constructor
 */
const ToggleActivationDialogMessage = ({hcorg, classes}) => {
    return (
        <>
            {hcorg && (
                <Box>
                    Are you sure you want to {hcorg.active ? 'deactivate' : 'activate'}{' '}
                    the <span className={classes.dialogMessageLabel}>{hcorg.name}</span>?
                </Box>
            )}
        </>
    );
};

/**
 * function to render healthcare organization profile
 * @param getHCOrgById: function get organisation by id
 * @param hcorg: healthcare organization
 * @param handleTabChange: function to handle tab change
 * @param tabValue: tab value
 * @param toggleActivation: function to toggle organization activation
 * @returns {JSX.Element}
 * @constructor
 */
const HealthcareOrganisationProfile = ({
                                           getHCOrgById,
                                           hcorg,
                                           handleTabChange,
                                           tabValue,
                                           toggleActivation,
                                       }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const params = useParams();
    const [
        openToggleActivationConfirmationDialog,
        setOpenToggleActivationConfirmationDialog,
    ] = useState(false);

    useEffect(() => {
        getHCOrgById(params.id).then(res => {
        });
    }, [params.id, getHCOrgById]);

    /**
     * function to handle tab change
     * @param e: event from button
     * @param newValue: updated value
     */
    const handleChange = (e, newValue) => {
        handleTabChange(newValue);
    };

    /**
     * function to toggle healthcare organisation activation
     */
    const onToggleActivation = () => {
        const data = {
            active: !hcorg.active,
        };

        toggleActivation(params.id, data).then(res => {
            setOpenToggleActivationConfirmationDialog(false);
        });
    };

    return (
        <Box
            className={
                role === ROLES.SIRENMD_ADMIN &&
                getIsTeamList() === 'false'
                    ? ''
                    : classes.outerContainer
            }
        >
            <Box
                className={
                    role === ROLES.SIRENMD_ADMIN &&
                    getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainer
                        : getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainerForAdmins
                        : classes.breadcrumbsContainerForUsers
                }
                component={Paper}
                p={1}
            >
                <Breadcrumbs>
                    <Box
                        component={NavLink}
                        exact
                        to={APP_URLS.HEALTHCARE_ORGANIZATIONS}
                        className={classes.breadcrumbItem}
                        activeClassName={classes.activeBreadcrumbItem}
                    >
                        <Icon className={classes.breadcrumpIcon}>local_hospital</Icon>

                        <Box className={classes.breadcrumpLabel}>
                            Healthcare Organizations
                        </Box>
                    </Box>

                    <Box
                        component={NavLink}
                        exact
                        to={`${APP_URLS.HEALTHCARE_ORGANIZATIONS}/${params.id}`}
                        className={classes.breadcrumbItem}
                        activeClassName={classes.activeBreadcrumbItem}
                    >
                        <Box className={classes.breadcrumpLabel}>{hcorg && hcorg.name}</Box>
                    </Box>
                </Breadcrumbs>
            </Box>

            <Box mt={3}>
                {hcorg && (
                    <Box>
                        <Box component={Paper} className={classes.profileContainer}>
                            <Box className={classes.profileContentContainer}>
                                <Box>
                                    <Box>
                                        <ImageLoader
                                            src={hcorg.imageUrl ? hcorg.imageUrl : defaultProfile}
                                        >
                                            <img
                                                alt='profile'
                                                className={`${classes.profileImage} ${
                                                    hcorg.imageUrl ? '' : classes.imageBorder
                                                }`}
                                            />
                                            <div className={classes.profileImageError}>
                                                {hcorg.id}
                                            </div>
                                            <Preloader
                                                className={`${classes.profileImage} ${
                                                    hcorg.imageUrl ? '' : classes.imageBorder
                                                }`}
                                            />
                                        </ImageLoader>
                                    </Box>
                                </Box>

                                <Box className={classes.profileTextContainer}>
                                    <Typography
                                        classes={{
                                            root: classes.orgNameText,
                                        }}
                                    >
                                        {hcorg.name}
                                    </Typography>
                                    <Typography
                                        classes={{
                                            root: classes.orgDescText,
                                        }}
                                    >
                                        {hcorg.desc}
                                    </Typography>

                                    <Box className={classes.profileStatusContainer}>
                                        {hcorg.active ? (
                                            <Icon
                                                className={`${classes.statusIcon} ${classes.active}`}
                                            >
                                                check_circle_outline
                                            </Icon>
                                        ) : (
                                            <Icon
                                                className={`${classes.statusIcon} ${classes.inactive}`}
                                            >
                                                block
                                            </Icon>
                                        )}
                                        <Typography classes={{root: classes.statusText}}>
                                            {hcorg.active ? 'Active' : 'Inactive'}
                                        </Typography>
                                    </Box>

                                    <Box>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            disableElevation={true}
                                            startIcon={<Icon>edit</Icon>}
                                            component={Link}
                                            to={`${APP_URLS.HEALTHCARE_ORGANIZATIONS_EDIT}/${params.id}`}
                                            classes={{root: classes.profileButton}}
                                        >
                                            Edit Details
                                        </Button>
                                    </Box>

                                    <Box>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            disableElevation={true}
                                            startIcon={
                                                hcorg.active === false ? (
                                                    <Icon>check_circle_outline</Icon>
                                                ) : (
                                                    <Icon>block</Icon>
                                                )
                                            }
                                            onClick={() =>
                                                setOpenToggleActivationConfirmationDialog(true)
                                            }
                                            classes={{
                                                root: `${classes.profileButton} ${
                                                    hcorg.active
                                                        ? classes.blockButton
                                                        : classes.activeButton
                                                }`,
                                            }}
                                        >
                                            {hcorg.active === false ? 'Activate' : 'Deactivate'}
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box component={Paper} mt={2}>
                            <TabContext value={tabValue}>
                                <TabList
                                    onChange={handleChange}
                                    indicatorColor='primary'
                                    textColor='primary'
                                    variant='fullWidth'
                                >
                                    <Tab label='Integration Vendors' value='1'/>
                                    <Tab label='Organizations' value='2'/>
                                    <Tab label='Caregivers' value='3'/>
                                </TabList>
                                <TabPanel
                                    value='1'
                                    classes={{root: classes.tabPanelContainer}}
                                >
                                    <HealthcareVendors
                                        hcorgIdHistory={hcorg.id}
                                        hcorgNameHistory={hcorg.name}
                                    />
                                </TabPanel>
                                <TabPanel
                                    value='2'
                                    classes={{root: classes.tabPanelContainer}}
                                >
                                    <OrganizationsForHCOrg
                                        hcorgIdHistory={hcorg.id}
                                        hcorgNameHistory={hcorg.name}
                                    />
                                </TabPanel>
                                <TabPanel
                                    value='3'
                                    classes={{root: classes.tabPanelContainer}}
                                >
                                    <HealthcareCaregivers
                                        hcorgIdHistory={hcorg.id}
                                        hcorgNameHistory={hcorg.name}
                                    />
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </Box>
                )}

                {hcorg && (
                    <Box>
                        <ConfirmationDialog
                            title={hcorg.active ? 'Deactivate' : 'Activate'}
                            message={
                                <ToggleActivationDialogMessage
                                    hcorg={hcorg}
                                    classes={classes}
                                />
                            }
                            open={openToggleActivationConfirmationDialog}
                            setOpen={setOpenToggleActivationConfirmationDialog}
                            onConfirm={onToggleActivation}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        hcorg: state.hcorganisations.hcorg,
        tabValue: state.hcorganisations.tabValue,
    };
};

const mapDispatchToProp = dispatch => {
    return {
        handleTabChange: value => {
            return dispatch(handleTabChange(value));
        },
        getHCOrgById: id => {
            return dispatch(getHCOrgByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        toggleActivation: (orgId, data) => {
            return dispatch(toggleActivationRequest(orgId, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProp
)(HealthcareOrganisationProfile);
