import Cookies from 'universal-cookie';

const cookies = new Cookies();

/**
 * function to set user info in cookies
 * @param data: user data 
 */
export const setUserInfo = data => {
    delete data['orgs'];
    delete data['teams'];
    delete data['TeamCareGivers'];
    delete data['UserDevices'];
    delete data['Admin'];
    cookies.set('user', data, {path: '/'});
};

/**
 * function to get user info from cookies
 * @returns {Object}
 */
export const getUserInfo = () => {
    return cookies.get('user');
};

/**
 * function to set access token in cookies
 * @param data: access token 
 */
export const setAccessToken = data => {
    cookies.set('access_token', data, {path: '/'});
};

/**
 * function to get access token from cookies
 * @returns {String}
 */
export const getAccessToken = () => {
    return cookies.get('access_token');
};

/**
 * function to check if user is logged in
 * @returns {Boolean}
 */
export const isLoggedIn = () => {
    return !!getUserInfo();
};

/**
 * function to remove cookies for logged in user upon logout
 */
export const logoutUser = () => {
    cookies.remove('user', {path: '/'});
    cookies.remove('access_token', {path: '/'});
    cookies.remove('department', {path: '/'});
    cookies.remove('isTeamList', {path: '/'});
    cookies.remove('admin', {path: '/'});
};

/**
 * function to set selected department in cookies
 * @param data: selected department
 */
export const setDepartment = data => {
    cookies.set('department', data, {path: '/'});
};

/**
 * function to get selected department from cookies
 * @returns {Object}
 */
export const getDepartment = () => {
    return cookies.get('department');
};

/**
 * function to set is team list flag in cookies
 * @param data: boolean for is team list
 */
export const setIsTeamList = data => {
    cookies.set('isTeamList', data, {path: '/'});
};

/**
 * function to get is team list
 * @returns {Boolean}
 */
export const getIsTeamList = () => {
    return cookies.get('isTeamList');
};

/**
 * function to set admin info in cookies
 * @param data: admin info
 */
export const setAdmin = data => {
    cookies.set('admin', data, {path: '/'});
};

/**
 * function to get admin info from cookies
 * @returns {Object}
 */
export const getAdmin = () => {
    return cookies.get('admin');
};
