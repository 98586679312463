import React, {useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {Box, Button, Container, CssBaseline, Grid, Typography,} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {acceptAgreementRequest, signInRequest} from '../../AuthApiActions';
import {
    getAdmin,
    getIsTeamList,
    getUserInfo,
    isLoggedIn,
    setAccessToken,
    setAdmin,
    setIsTeamList,
    setUserInfo,
} from '../../../../utilities/storage';
import logo from '../../../../assets/logo.png';
import MessageDialog from '../../../../utilities/components/MessageDialog/MessageDialog';
import UserAgreement from '../../../../utilities/components/UserAgreement/UserAgreement';
import {ROLES, APP_URLS} from '../../../../utilities/constants';
import './Login.css';

/**
 * function to render login form
 * @param history: history from router
 * @param signIn: function to sign in
 * @param acceptAgreement: function to accept agreement
 * @returns {JSX.Element}
 * @constructor
 */
const Login = ({history, signIn, acceptAgreement}) => {
    const role = getUserInfo() ? getUserInfo().Roles[0].name : '';
    const isTeamList = getIsTeamList() ? getIsTeamList() === 'true' ? true : false : '';
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showDisabledUserDialog, setShowDisabledUserDialog] = useState(false);
    const [showNoActiveTeamDialog, setShowNoActiveTeamDialog] = useState(false);
    const [showUserAgreementDialog, setShowUserAgreementDialog] = useState(false);
    const [showBannedUserDialog, setShowBannedUserDialog] = useState(false);
    const [bannedUserMessage, setBannedUserMessage] = useState('');

    /**
     * function to sign in on button click
     * @param e: event from button click
     */
    const onSubmit = e => {
        e.preventDefault();

        const data = {
            username: email,
            password: password,
        };

        signIn(data).then(res => {
            if (res.user.enabled) {
                if (!res.user.acceptedTerms) {
                    setShowUserAgreementDialog(true);
                } else {
                    if (res.user.Roles[0].name === ROLES.SIRENMD_ADMIN) {
                        if (res.user.teams.length > 0) {
                            setIsTeamList(true);
                            setUserInfo(res.user);
                            setAccessToken(res.access_token);
                            history.push(APP_URLS.NEW_UPDATES);
                        } else {
                            setIsTeamList(false);
                            setUserInfo(res.user);
                            setAccessToken(res.access_token);
                            history.push(APP_URLS.ORGANIZATIONS);
                        }
                    } else {
                        if (
                            res.user.Admin.Organization.length !== 0 ||
                            res.user.Admin.Team.length !== 0
                        ) {
                            if (res.user.teams.length > 0) {
                                setIsTeamList(true);
                                if (
                                    res.user.Admin.Team.length > 0 &&
                                    res.user.Admin.Organization.length > 0
                                ) {
                                    const adminData = {
                                        isBothAdmin: true,
                                    };
                                    setAdmin(adminData);
                                    setUserInfo(res.user);
                                    setAccessToken(res.access_token);
                                    history.push(APP_URLS.NEW_UPDATES);
                                } else if (res.user.Admin.Organization.length > 0) {
                                    const adminData = {
                                        isOrgAdmin: true,
                                    };
                                    setAdmin(adminData);
                                    setUserInfo(res.user);
                                    setAccessToken(res.access_token);
                                    history.push(APP_URLS.NEW_UPDATES);
                                } else if (res.user.Admin.Team.length > 0) {
                                    const adminData = {
                                        isDeptAdmin: true,
                                    };
                                    setAdmin(adminData);
                                    setUserInfo(res.user);
                                    setAccessToken(res.access_token);
                                    history.push(APP_URLS.NEW_UPDATES);
                                }
                            } else {
                                setIsTeamList(false);
                                if (
                                    res.user.Admin.Team.length > 0 &&
                                    res.user.Admin.Organization.length > 0
                                ) {
                                    const adminData = {
                                        isBothAdmin: true,
                                    };
                                    setAdmin(adminData);
                                    setUserInfo(res.user);
                                    setAccessToken(res.access_token);
                                    history.push(APP_URLS.ORGANIZATIONS);
                                } else if (res.user.Admin.Organization.length > 0) {
                                    const adminData = {
                                        isOrgAdmin: true,
                                    };
                                    setAdmin(adminData);
                                    setUserInfo(res.user);
                                    setAccessToken(res.access_token);
                                    history.push(APP_URLS.ORGANIZATIONS);
                                } else if (res.user.Admin.Team.length > 0) {
                                    const adminData = {
                                        isDeptAdmin: true,
                                    };
                                    setAdmin(adminData);
                                    setUserInfo(res.user);
                                    setAccessToken(res.access_token);
                                    history.push(APP_URLS.DEPARTMENTS);
                                }
                            }
                        } else {
                            if (res.user.teams.length > 0) {
                                setIsTeamList(true);
                                setUserInfo(res.user);
                                setAccessToken(res.access_token);
                                if (res.user.Roles[0].name === ROLES.COACH) {
                                    history.push(APP_URLS.PLAY_STATUS_TEAM_ROSTER);
                                } else {
                                    history.push(APP_URLS.NEW_UPDATES);
                                }
                            } else {
                                setShowNoActiveTeamDialog(true);
                            }
                        }
                    }
                }
            } else {
                setShowDisabledUserDialog(true);
            }
        }, error => {
            if(error.response.data.error){
                setBannedUserMessage(error.response.data.error);
                setShowBannedUserDialog(true);
            }
        });
    };

    /**
     * function to handle accept agreement
     * @param e: event from button click
     */
    const onAgree = e => {
        const data = {
            acceptedTerms: true,
            email: email,
        };

        acceptAgreement(data).then(() => {
            onSubmit(e);
        });
    };

    if (isLoggedIn()) {
        if (role === ROLES.SIRENMD_ADMIN) {
            return <Redirect to={APP_URLS.ORGANIZATIONS} />;
        } else if (role === ROLES.COACH) {
            return <Redirect to={APP_URLS.PLAY_STATUS_TEAM_ROSTER} />;
        } else if (!isTeamList && getAdmin().isOrgAdmin === true) {
            return <Redirect to={APP_URLS.ORGANIZATIONS} />;
        } else if (!isTeamList && getAdmin().isDeptAdmin === true) {
            return <Redirect to={APP_URLS.DEPARTMENTS} />;
        } else if (!isTeamList && getAdmin().isBothAdmin === true) {
            return <Redirect to={APP_URLS.ORGANIZATIONS} />;
        } else if (isTeamList) {
            return <Redirect to={APP_URLS.NEW_UPDATES} />;
        }
    }

    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline/>

            <MessageDialog
                open={showDisabledUserDialog}
                setOpen={setShowDisabledUserDialog}
                title='Contact'
                message='Your account has been temporarily locked out of SirenMD. Please
            contact help@sirenmd.com.'
            />

            <MessageDialog
                open={showNoActiveTeamDialog}
                setOpen={setShowNoActiveTeamDialog}
                title='Message'
                message={`You have not yet been assigned as caregiver to any team. Please contact your organization's administrator to request access. If you are already an administrator of an organization or team, please login to your SirenMD Admin portal.`}
            />

            <MessageDialog
                open={showBannedUserDialog}
                setOpen={setShowBannedUserDialog}
                title='Contact'
                message={bannedUserMessage}
            />

            <UserAgreement
                open={showUserAgreementDialog}
                setOpen={setShowUserAgreementDialog}
                onAgree={onAgree}
            />

            <Box className='login-form-container'>
                <img src={logo} alt='logo'/>
                <Typography component='h1' variant='h5' style={{marginTop: '1.5rem'}}>
                    Sign in
                </Typography>

                <ValidatorForm noValidate className='login-form' onSubmit={onSubmit}>
                    <TextValidator
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label='Email'
                        validators={['required', 'isEmail']}
                        errorMessages={['Email is required', 'Email is not valid']}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />

                    <TextValidator
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        label='Password'
                        type='password'
                        validators={['required']}
                        errorMessages={['Password is required']}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />

                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        className='login-button'
                    >
                        Sign In
                    </Button>

                    <Grid container>
                        <Grid item xs>
                            <Typography
                                color='primary'
                                component={Link}
                                to={APP_URLS.FORGOT_PASSWORD}
                                className='login-forget-password'
                            >
                                Forgot password?
                            </Typography>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Box>
        </Container>
    );
};

const mapStateToProps = state => {
    return {
        loggedInUser: state.auth.loggedInUser,
    };
};

const mapDispatchToProp = dispatch => {
    return {
        signIn: data => {
            return dispatch(signInRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        acceptAgreement: data => {
            return dispatch(acceptAgreementRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProp)(Login);
