import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {NavLink, useParams} from 'react-router-dom';
import {Box, Breadcrumbs, Button, Card, Grid, Icon, Paper, Typography,} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {AutocompleteValidator, PhoneInputValidator,} from '../../../../utilities/validators';
import UtilHelper from '../../../../utilities/validators/UtilHelper';
import {
    addHCOrganisationRequest,
    getHCOrgByIdRequest,
    getSignedUrlRequest,
    updateHCOrgRequest,
    uploadToS3Request,
} from '../../HealthcareOrganizationsApiActions';
import {getStatesRequest} from '../../../Users/UsersApiActions';
import {useStyles} from './styles';
import ConfirmationDialog from '../../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import ImageField from '../../../../utilities/components/ImageField/ImageField';
import {dataURLtoFile} from '../../../../utilities/utilityFunctions';
import {getIsTeamList, getUserInfo} from '../../../../utilities/storage';
import {ROLES, APP_URLS} from '../../../../utilities/constants';

/**
 * function to render organization form
 * @param history: history from router
 * @param getStates: function to get states
 * @param states: states list
 * @param addHCOrganisation: function to add healthcare organization
 * @param getHCOrgById: function to get healthcare organization by id
 * @param updateHCOrg: function to update existing healthcare organization
 * @param getSignedUrl: function to get signed url
 * @param uploadToS3: function to upload file on signed url
 * @returns {JSX.Element}
 * @constructor
 */
const HealthcareOrganizationForm = ({
                                        history,
                                        getStates,
                                        states,
                                        addHCOrganisation,
                                        getHCOrgById,
                                        updateHCOrg,
                                        getSignedUrl,
                                        uploadToS3,
                                    }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const params = useParams();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [npmNumber, setNpmNumber] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [selectedState, setSelectedState] = useState(null);
    const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);
    const [rowImageFile, setRowImageFile] = useState(null);
    const [cropper, setCropper] = useState(null);
    const [croppedImageFile, setCroppedImageFile] = useState(null);
    const [showImageEditor, setShowImageEditor] = useState(false);

    useEffect(() => {
        ValidatorForm.addValidationRule('isBlank', value =>
            UtilHelper.validateBlankSpace(value)
        );
        ValidatorForm.addValidationRule('isValidMobile', value =>
            UtilHelper.validateMobile(value)
        );
        ValidatorForm.addValidationRule('isNPI', value =>
            UtilHelper.validateNpi(value)
        );
        ValidatorForm.addValidationRule('isZipCode', value =>
            UtilHelper.validateZpiCode(value)
        );
        ValidatorForm.addValidationRule('isMaxLength', value =>
            UtilHelper.validateMaxLength(value)
        );

        return () => {
            ValidatorForm.removeValidationRule('isValidMobile');
            ValidatorForm.removeValidationRule('isBlank');
            ValidatorForm.removeValidationRule('isNPI');
            ValidatorForm.removeValidationRule('isZipCode');
            ValidatorForm.removeValidationRule('isMaxLength');
        };
    }, []);

    useEffect(() => {
        getStates().then(res => {
        });
    }, [getStates]);

    useEffect(() => {
        if (params.id) {
            getHCOrgById(params.id).then(res => {
                setCroppedImageFile(res.imageUrl);
                setName(res.name);
                setDescription(res.desc);
                setEmail(res.email);
                setPhone(res.phone);
                setNpmNumber(res.npi);
                setAddressLine1(res.addressLine1);
                setAddressLine2(res.addressLine2);
                setCity(res.city);
                setZipCode(res.zipCode);
                setSelectedState(res.State);
            });
        }
    }, [params.id, getHCOrgById]);

    const onImageCrop = () => {
        const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL();
        const croppedImageFileObject = dataURLtoFile(
            croppedImageDataUrl,
            rowImageFile[0].file.name
        );

        const data = {
            dataURL: croppedImageDataUrl,
            file: croppedImageFileObject,
        };

        setCroppedImageFile(data);
        setShowImageEditor(false);
    };

    /**
     * function to add healthcare organization on button click
     * @param e: event from button click
     */
    const onAdd = e => {
        e.preventDefault();

        const data = {
            name: name,
            desc: description,
            email: email,
            phone: phone,
            npi: npmNumber,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            StateId: selectedState.id,
            zipCode: zipCode,
        };

        if (croppedImageFile) {
            const imageData = {
                fileName: croppedImageFile.file.name,
                fileType: `.${croppedImageFile.file.name.split('.').pop()}`,
            };

            getSignedUrl(imageData).then(res => {
                uploadToS3(res.urls, croppedImageFile.file).then(uploadRes => {
                    data['imageUrl'] = res.path;

                    addHCOrganisation(data).then(res => {
                        history.push(`${APP_URLS.HEALTHCARE_ORGANIZATIONS}/${res.id}`);
                    });
                });
            });
        } else {
            addHCOrganisation(data).then(res => {
                history.push(`${APP_URLS.HEALTHCARE_ORGANIZATIONS}/${res.id}`);
            });
        }
    };

    /**
     * function to update healthcare organization on button click
     * @param e: event from button click
     */
    const onUpdate = e => {
        e.preventDefault();

        if (typeof croppedImageFile !== 'string') {
            const imageData = {
                fileName: croppedImageFile.file.name,
                fileType: `.${croppedImageFile.file.name.split('.').pop()}`,
            };

            getSignedUrl(imageData).then(res => {
                uploadToS3(res.urls, croppedImageFile.file).then(uploadRes => {
                    const data = {
                        imageUrl: res.path,
                        name: name,
                        desc: description,
                        email: email,
                        phone: phone,
                        npi: npmNumber,
                        addressLine1: addressLine1,
                        addressLine2: addressLine2,
                        city: city,
                        StateId: selectedState.id,
                        zipCode: zipCode,
                    };

                    updateHCOrg(params.id, data).then(res => {
                        history.goBack();
                    });
                });
            });
        } else {
            const data = {
                name: name,
                desc: description,
                email: email,
                phone: phone,
                npi: npmNumber,
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                StateId: selectedState.id,
                zipCode: zipCode,
            };

            updateHCOrg(params.id, data).then(res => {
                history.goBack();
            });
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    return (
        <Box
            className={
                role === ROLES.SIRENMD_ADMIN &&
                getIsTeamList() === 'false'
                    ? ''
                    : classes.outerContainer
            }
        >
            <Box
                className={
                    role === ROLES.SIRENMD_ADMIN &&
                    getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainer
                        : getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainerForAdmins
                        : classes.breadcrumbsContainerForUsers
                }
                component={Paper}
                p={1}
            >
                <Breadcrumbs>
                    <Box
                        component={NavLink}
                        exact
                        to={APP_URLS.HEALTHCARE_ORGANIZATIONS}
                        className={classes.breadcrumbItem}
                        activeClassName={classes.activeBreadcrumbItem}
                    >
                        <Icon className={classes.breadcrumpIcon}>local_hospital</Icon>

                        <Box className={classes.breadcrumpLabel}>
                            Healthcare Organizations
                        </Box>
                    </Box>

                    {!params.id && (
                        <Box
                            component={NavLink}
                            exact
                            to={APP_URLS.HEALTHCARE_ORGANIZATIONS_ADD}
                            className={classes.breadcrumbItem}
                            activeClassName={classes.activeBreadcrumbItem}
                        >
                            <Box className={classes.breadcrumpLabel}>
                                Add Healthcare Organization
                            </Box>
                        </Box>
                    )}

                    {params.id && (
                        <Box
                            component={NavLink}
                            exact
                            to={`${APP_URLS.HEALTHCARE_ORGANIZATIONS_EDIT}/${params.id}`}
                            className={classes.breadcrumbItem}
                            activeClassName={classes.activeBreadcrumbItem}
                        >
                            <Box className={classes.breadcrumpLabel}>
                                Update Healthcare Organization
                            </Box>
                        </Box>
                    )}
                </Breadcrumbs>
            </Box>

            <Box mt={2}>
                <ValidatorForm
                    noValidate
                    autoComplete='off'
                    onSubmit={params.id ? onUpdate : onAdd}
                >
                    <Box className={classes.container}>
                        <Box className={classes.labelContainer}>
                            <Typography className={classes.label}>
                                {params.id ? 'Update' : 'Create'} Healthcare Organization
                            </Typography>
                        </Box>

                        <Box className={classes.formContainer}>
                            <Box component={Paper} className={classes.formInnerContainer}>
                                <Box className={classes.formOrgDetailsLabel}>
                                    <Typography variant='h6'>Organization Details</Typography>
                                </Box>
                                <Grid container spacing={2} justify='space-around'>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <Box>
                                            <ImageField
                                                rowImageFile={rowImageFile}
                                                setRowImageFile={setRowImageFile}
                                                showImageEditor={showImageEditor}
                                                setShowImageEditor={setShowImageEditor}
                                                setCropper={setCropper}
                                                croppedImageFile={croppedImageFile}
                                                onImageCrop={onImageCrop}
                                                options={{
                                                    shape: 'rect',
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextValidator
                                                    label='Name *'
                                                    variant='outlined'
                                                    fullWidth
                                                    size='small'
                                                    value={name}
                                                    onChange={e => setName(e.target.value)}
                                                    validators={['required', 'isBlank', 'isMaxLength']}
                                                    errorMessages={[
                                                        'Name is required',
                                                        'Please enter name',
                                                        'Maximum 100 letters are accepted.',
                                                    ]}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextValidator
                                                    label='Description *'
                                                    variant='outlined'
                                                    fullWidth
                                                    size='small'
                                                    value={description}
                                                    onChange={e => setDescription(e.target.value)}
                                                    validators={['required', 'isBlank', 'isMaxLength']}
                                                    errorMessages={[
                                                        'Description is required',
                                                        'Please enter description',
                                                        'Maximum 100 letters are accepted.',
                                                    ]}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextValidator
                                                    label='Email *'
                                                    variant='outlined'
                                                    fullWidth
                                                    size='small'
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                    validators={['required', 'isEmail']}
                                                    errorMessages={[
                                                        'Email is required',
                                                        'Please enter valid email',
                                                    ]}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <PhoneInputValidator
                                                    autoFormat={false}
                                                    variant='outlined'
                                                    size='small'
                                                    label='Phone *'
                                                    fullWidth
                                                    defaultCountry='us'
                                                    countryCodeEditable={false}
                                                    value={phone}
                                                    onChange={(phone, data) => {
                                                        let phoneNumber =
                                                            '+' + phone.replace(/[^0-9]+/g, '');
                                                        setPhone(phoneNumber);
                                                    }}
                                                    validators={['required', 'isValidMobile']}
                                                    errorMessages={[
                                                        'Phone is required',
                                                        'Please enter valid phone',
                                                    ]}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextValidator
                                                    label='NPI Number *'
                                                    variant='outlined'
                                                    fullWidth
                                                    size='small'
                                                    value={npmNumber}
                                                    onChange={e => setNpmNumber(e.target.value)}
                                                    validators={['required', 'isBlank', 'isNPI']}
                                                    errorMessages={[
                                                        'NPI number is required',
                                                        'Please enter NPI number',
                                                        'Please enter valid NPI',
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box component={Paper} className={classes.addInfoContainer}>
                                <Box className={classes.addInfoLabel}>
                                    <Typography variant='h6'>Address Information</Typography>
                                </Box>

                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.addInfoContentContainer}
                                >
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='Address Line 1 *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={addressLine1}
                                            onChange={e => setAddressLine1(e.target.value)}
                                            validators={['required', 'isBlank', 'isMaxLength']}
                                            errorMessages={[
                                                'Address line 1 is required',
                                                'Please enter address line 1',
                                                'Maximum 100 letters are accepted.',
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='Address Line 2 *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={addressLine2}
                                            onChange={e => setAddressLine2(e.target.value)}
                                            validators={['required', 'isBlank', 'isMaxLength']}
                                            errorMessages={[
                                                'Address line 2 is required',
                                                'Please enter address line 2',
                                                'Maximum 100 letters are accepted.',
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='City *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={city}
                                            onChange={e => setCity(e.target.value)}
                                            validators={['required', 'isBlank', 'isMaxLength']}
                                            errorMessages={[
                                                'City is required',
                                                'Please enter city',
                                                'Maximum 100 letters are accepted.',
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <AutocompleteValidator
                                            fullWidth
                                            size='small'
                                            value={selectedState}
                                            onChange={(e, value) => {
                                                setSelectedState(value);
                                            }}
                                            options={states}
                                            getOptionLabel={option => {
                                                return `${option.initial} - ${option.desc
                                                    .charAt(0)
                                                    .toUpperCase()}${option.desc.slice(1)}`;
                                            }}
                                            getOptionSelected={(option, value) =>
                                                option.id === value.id
                                            }
                                            renderInput={params => (
                                                <TextValidator
                                                    {...params}
                                                    label='Select State *'
                                                    variant='outlined'
                                                />
                                            )}
                                            validators={['required']}
                                            errorMessages={['State is required']}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='Zip Code *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={zipCode}
                                            onChange={e => setZipCode(e.target.value)}
                                            validators={['required', 'isBlank', 'isZipCode']}
                                            errorMessages={[
                                                'Zip code is required',
                                                'Please enter zip code',
                                                'Please enter valid zip code',
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>

                    <Box className={classes.footerActionsButtonsContainer}>
                        <Box
                            component={Card}
                            raised
                            className={classes.footerActionsButtonsInnerContainer}
                        >
                            <Box>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    disableElevation={true}
                                    onClick={() => setOpenCancelConfirmation(true)}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    variant='contained'
                                    color='primary'
                                    disableElevation={true}
                                    className={classes.saveButton}
                                    type='submit'
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </ValidatorForm>
            </Box>

            <Box>
                <ConfirmationDialog
                    open={openCancelConfirmation}
                    setOpen={setOpenCancelConfirmation}
                    title='Cancel'
                    message='Are you sure you want to cancel?'
                    onConfirm={handleCancel}
                />
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        states: state.sirenUsers.states,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addHCOrganisation: data => {
            return dispatch(addHCOrganisationRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getHCOrgById: id => {
            return dispatch(getHCOrgByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateHCOrg: (id, data) => {
            return dispatch(updateHCOrgRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getSignedUrl: data => {
            return dispatch(getSignedUrlRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        uploadToS3: (url, file) => {
            return dispatch(uploadToS3Request(url, file)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getStates: () => {
            return dispatch(getStatesRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HealthcareOrganizationForm);
