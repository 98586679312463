import HttpRequest from '../../utilities/httpRequest';
import {
    addOrganisation,
    getOrganisations,
    getOrganisationsWithoutParams,
    getOrgById,
    getOrgsForHCOrg,
    getOrgSubTypes,
    getOrgTypes,
    getReadReceiptStatus,
    toggleActivation,
    updateOrg,
} from './OrganisationsActions';
import {hideLoader, showLoader, showSnackbar,} from '../../utilities/UtilitiesActions';

/**
 * function to get  organizations
 * @param data: data for api request
 * @param isHideLoader: isHideLoader for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getOrganisationsRequest = (data, isHideLoader) => {
    let offset = data.page * data.rowsPerPage;
    let limit = data.rowsPerPage;
    let searchData = data.searchData ? data.searchData : '';

    return dispatch => {
        if (!isHideLoader) {
            dispatch(showLoader());
        }

        const url = `org?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
            searchData
        )}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(getOrganisations(res.data.data));

                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.resolve(res.data.data);
            },
            error => {
                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get organizations without params
 * @returns {function(*=): Promise<unknown>}
 */
export const getOrganisationsWithoutParamsRequest = () => {
    return dispatch => {
        dispatch(showLoader());
        const url = `org`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getOrganisationsWithoutParams(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to add  organization
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const addOrganisationRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = 'org';

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Organization created successfully', 'success'));

                dispatch(addOrganisation(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get organization by id
 * @param id: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getOrgByIdRequest = id => {
    return dispatch => {
        dispatch(showLoader());

        const url = `org/${id}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getOrgById(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to update organization
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const updateOrgRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());

        const url = `org/${id}`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Organization updated successfully', 'success'));

                dispatch(updateOrg(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get read receipt status
 * @returns {function(*=): Promise<unknown>}
 */
export const getReadReceiptStatusRequest = () => {
    return dispatch => {
        dispatch(showLoader());

        const url = 'org/readReceipt/all';

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getReadReceiptStatus(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get organization types
 * @returns {function(*=): Promise<unknown>}
 */
export const getOrgTypesRequest = () => {
    return dispatch => {
        dispatch(showLoader());

        const url = 'org/types/all';

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getOrgTypes(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get organization sub types
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getOrgSubTypesRequest = id => {
    return dispatch => {
        dispatch(showLoader());

        const url = `org/subtypes/${id}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getOrgSubTypes(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get organizations for a healthcare organization
 * @param orgId: orgId for api request
 * @param data: data for api request
 * @param isHideLoader: isHideLoader for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getOrgsForHCOrgRequest = (orgId, data, isHideLoader) => {
    let offset = data.page * data.rowsPerPage;
    let limit = data.rowsPerPage;
    let searchData = data.searchData ? data.searchData : '';

    return dispatch => {
        if (!isHideLoader) {
            dispatch(showLoader());
        }

        const url = `healthcareorgByOrg/${orgId}?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
            searchData
        )}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                dispatch(getOrgsForHCOrg(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to toggle activation
 * @param orgId: orgId for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const toggleActivationRequest = (orgId, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `org/${orgId}/activateOrDeactivateAccount`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(toggleActivation(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get signed url
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getSignedUrlRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = `org/profilePic/signedURL`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};

/**
 * function to upload file on signed url
 * @param url: url for api request
 * @param file: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const uploadToS3Request = (url, file) => {
    return dispatch => {
        dispatch(showLoader());
        return new HttpRequest(dispatch, url, 'put', file)
            .s3Upload()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};
