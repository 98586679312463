import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import moment from 'moment';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import {connect} from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Avatar, Box, Button, Card, Chip, Grid, IconButton, Paper, Typography,} from '@material-ui/core';
import {Autocomplete, Timeline} from '@material-ui/lab';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {AutocompleteValidator, DatePickerValidator,} from '../../../../utilities/validators';
import AddAthlete from '../../../Athletes/components/AthleteFormDialog/AthleteFormDialog';
import {getAthletesWithoutParamsRequest} from '../../../Athletes/AthletesApiActions';
import {
    addCaseRequest,
    findDicomImageRequest,
    getCaregiversOfDeptRequest,
    getCasePrioritiesRequest,
    getCaseStatusForDoctorRequest,
    getCaseStatusRequest,
    getDicomUrlRequest,
    getSignedUrlRequest,
    uploadToS3Request,
} from '../../CasesApiAction';
import {getUserInfo} from '../../../../utilities/storage';
import NoteTimeline from './components/NoteItem/NoteItem';
import {useStyles} from './styles';
import NoteForm from '../NoteForm/NoteForm';
import defaultUser from '../../../../assets/user.png';
import {ORGANIZATION_TYPES} from '../../../../utilities/constants';

/**
 * function to render case form
 * @param history: history from router
 * @param selectedDept: selected department
 * @param getAllAthletes: function to get all athletes
 * @param athletes: athletes list
 * @param getCasePriorities: function to get case priorities
 * @param casePriorities: case priorities list
 * @param getCaseStatus: function to get case status
 * @param getCaseStatusForDoctor: function to get case status for health provider organization departments
 * @param caseStatus: case status list
 * @param getCaregiversOfDept: function to get caregivers of departments
 * @param caregivers: caregivers list
 * @param addCase: function to add case
 * @param getDicomUrl: function to get dicom url
 * @param findDicomImage: function to get signed url
 * @param getSignedUrl: function to get signed url
 * @param uploadToS3: function to upload file on signed url
 * @returns {JSX.Element}
 * @constructor
 */
const AddCase = ({
                     history,
                     selectedDept,
                     getAllAthletes,
                     athletes,
                     getCasePriorities,
                     casePriorities,
                     getCaseStatus,
                     getCaseStatusForDoctor,
                     caseStatus,
                     getCaregiversOfDept,
                     caregivers,
                     addCase,
                     getDicomUrl,
                     findDicomImage,
                     getSignedUrl,
                     uploadToS3,
                 }) => {
    const classes = useStyles();
    const {search} = useLocation();
    const query = new URLSearchParams(search);
    const athleteID = Number(query.get('athleteID'));

    const [selectedAthlete, setSelectedAthlete] = useState(null);
    const [status, setStatus] = useState(null);
    const [injuryDate, setInjuryDate] = useState(null);
    const [onSetDate, setOnSetDate] = useState(null);
    const [firstSeenDate, setFirstSeenDate] = useState(null);
    const [description, setDescription] = useState('');
    const [caseUrgency, setCaseUrgency] = useState(null);
    const [selectedCaregivers, setSelectedCaregivers] = useState([]);
    const [fixedCaregiver, setFixedCaregiver] = useState([]);
    const [showNoteBox, setShowNoteBox] = useState(false);
    const [openCreateAthleteModal, setOpenCreateAthleteModal] = useState(false);
    const [noteImages, setNoteImages] = useState([]);
    const [currentDicomMediaId, setCurrentDicomMediaId] = useState(null);
    const [noteDicomImages, setNoteDicomImages] = useState([]);
    const [note, setNote] = useState('');
    const [noteItems, setNoteItems] = useState([]);
    const [showNoteErrorMessage, setShowNoteErrorMessage] = useState('');
    const [openMedicalUpload, setOpenMedicalUpload] = useState(false);

    useEffect(() => {
        if (noteDicomImages.length > 0) {
            setShowNoteErrorMessage('');
        }
    }, [noteDicomImages]);

    useEffect(() => {
        if (athleteID) {
            const athlete =
                athletes &&
                athletes.rows &&
                athletes.rows.find(athlete => athlete.id === athleteID);

            if (athlete) {
                setSelectedAthlete(athlete);
            }
        }
    }, [athleteID, athletes]);

    useEffect(() => {
        if (caregivers) {
            const caregiver = caregivers.rows.filter(
                caregiver => caregiver.userId === getUserInfo().id
            );
            setFixedCaregiver(caregiver);
            setSelectedCaregivers([...caregiver]);
        }
    }, [caregivers]);

    useEffect(() => {
            getAllAthletes(selectedDept.team_id).then(res => {
        });
    }, [getAllAthletes, selectedDept.team_id]);

    useEffect(() => {
        getCasePriorities().then(res => {
        });
    }, [getCasePriorities]);

    useEffect(() => {
        if (selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
            getCaseStatusForDoctor().then(res => {
            });
        } else {
            getCaseStatus().then(res => {
            });
        }
    }, [getCaseStatus, getCaseStatusForDoctor, selectedDept]);

    useEffect(() => {
        getCaregiversOfDept(selectedDept.team_id).then(res => {
        });
    }, [getCaregiversOfDept, selectedDept.team_id]);

    /**
     * function to handle closing of note form box
     */
    const handleNoteBoxClose = () => {
        setShowNoteBox(false);
        setNote('');
        setNoteImages([]);
        setNoteDicomImages([]);
        setShowNoteErrorMessage('');
    };

    /**
     * function to handle add note
     */
    const handleNotes = () => {
        if (
            noteImages.length === 0 &&
            note.length === 0 &&
            noteDicomImages.length === 0
        ) {
            setShowNoteErrorMessage(
                'Please enter note or select image or medical image'
            );
        }

        if (
            note.length !== 0 ||
            noteImages.length !== 0 ||
            noteDicomImages.length !== 0
        ) {
            const data = {
                noteText: note,
                noteImages: noteImages,
                mediaUrls: [],
                mediaId: noteDicomImages,
            };

            noteImages.forEach(image => {
                const imageData = {
                    fileName: image.file.name,
                    fileType: `.${image.file.name.split('.').pop()}`,
                };

                getSignedUrl(imageData).then(res => {
                    uploadToS3(res.urls, image.file).then(() => {
                        data.mediaUrls.push(res.path);
                    });
                });
            });

            setNoteItems(noteItems => [data, ...noteItems]);
            setShowNoteBox(false);
            setNoteImages([]);
            setNote('');
            setNoteDicomImages([]);
        }
    };

    /**
     * function to add case
     * @param e: event object
     */
    const onAdd = e => {
        e.preventDefault();

        const caregiverIds = [];
        selectedCaregivers.map(caregiver => caregiverIds.push(caregiver.userId));
        const logItems = [];
        noteItems.map(item =>
            logItems.push({
                text: item.noteText,
                mediaUrls: item.mediaUrls,
                mediaId: item.mediaId,
            })
        );

        const data = {
            priority: caseUrgency.id,
            teamId: selectedDept.team_id,
            athletId: selectedAthlete.id,
            desc: description,
            status: status.id,
            date: moment(injuryDate).format('MM-DD-YYYY'),
            onSetDate: onSetDate ? moment(onSetDate).format('MM-DD-YYYY') : null,
            firstSeenDate: firstSeenDate
                ? moment(firstSeenDate).format('MM-DD-YYYY')
                : null,
            caregiver: caregiverIds,
            logItem: logItems,
        };

        if (selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
            delete data['date'];
        }

        if (selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
            delete data['onSetDate'];
            delete data['firstSeenDate'];
        }

        addCase(data).then(res => {
            history.push(`/cases/${res.id}`);
        });
    };

    /**
     * function to render caregiver chip
     * @param props: caregiver details
     * @returns {JSX.Element}
     * @constructor
     */
    const CaregiverChip = props => (
            <Chip avatar={<Avatar alt='profile' src={props.url}/>} {...props} />
        );

    const athleteOptions =
        athletes && athletes.rows ? [...athletes.rows, {id: -1}] : [{id: -1}];

    /**
     * function to handle athlete selection
     * @param e: event object
     * @param value: athlete details
     */
    const handleAtheleteSelection = (e, value) => {
        if (value && value.id === -1) {
            setOpenCreateAthleteModal(true);
        } else {
            setSelectedAthlete(value);
        }
    };

    /**
     * function to handle dicom upload dialog open
     */
    const handleMedicalUploadOpen = () => {
        getDicomUrl().then(res => {
            setCurrentDicomMediaId(res.mediaId);
            setOpenMedicalUpload(true);
        });
    };

    /**
     * function to handle dicom upload dialog close
     */
    const handleMedicalUploadClose = () => {
        const data = {
            mediaId: currentDicomMediaId,
        };
        findDicomImage(data).then(res => {
            if (res.status === 404) {
                setOpenMedicalUpload(false);
            } else {
                setOpenMedicalUpload(false);
                setNoteDicomImages(pre => [...pre, res.mediaId]);
            }
        });
    };

    /**
     * function to handle dicom remove
     * @param index: index of dicom
     */
    const handleRemoveDicom = index => {
        const tempDicom = [...noteDicomImages];
        tempDicom.splice(index, 1);

        setNoteDicomImages(tempDicom);
    };

    return (
        <>
            <ValidatorForm onSubmit={onAdd}>
                <Box>
                    <Box className={classes.container}>
                        <Box>
                            <Box className={classes.titleContainer}>
                                <Typography className={classes.title}>Create Case</Typography>
                            </Box>

                            <Box>
                                <Box component={Paper} p={3} m={2}>
                                    <Grid container spacing={2}>
                                        {selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER && (
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <AutocompleteValidator
                                                    disabled={!!athleteID}
                                                    fullWidth
                                                    size='small'
                                                    options={athleteOptions}
                                                    value={selectedAthlete}
                                                    onChange={handleAtheleteSelection}
                                                    getOptionSelected={(option, value) =>
                                                        option.id === value.id
                                                    }
                                                    getOptionLabel={option => {
                                                        if (option.id !== -1) {
                                                            return `${option.firstName} ${option.lastName} ${
                                                                option.jerseyNum ? `, #${option.jerseyNum}` : ''
                                                            }`;
                                                        } else {
                                                            return '';
                                                        }
                                                    }}
                                                    renderOption={option => {
                                                        if (option.id !== -1) {
                                                            return (
                                                                <Box className={classes.optionsContainer}>
                                                                    <Box className={classes.optionAvatar}>
                                                                        <img
                                                                            alt='profile'
                                                                            src={
                                                                                option.imageUrl
                                                                                    ? option.imageUrl
                                                                                    : defaultUser
                                                                            }
                                                                            className={classes.optionAvatarImage}
                                                                        />
                                                                    </Box>
                                                                    <Box
                                                                        className={classes.optionNameContainer}
                                                                    >{`${option.firstName} ${option.lastName}`}</Box>
                                                                    <Box>
                                                                        {option.jerseyNum &&
                                                                        `, #${option.jerseyNum}`}
                                                                    </Box>
                                                                </Box>
                                                            );
                                                        } else {
                                                            return (
                                                                <Box className={classes.addOption}>
                                                                    Add Athlete
                                                                </Box>
                                                            );
                                                        }
                                                    }}
                                                    renderInput={params => (
                                                        <TextValidator
                                                            {...params}
                                                            label='Athlete *'
                                                            variant='outlined'
                                                        />
                                                    )}
                                                    validators={['required']}
                                                    errorMessages={['Athlete is required']}
                                                />
                                            </Grid>
                                        )}

                                        {selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER && (
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <AutocompleteValidator
                                                    disabled={!!athleteID}
                                                    fullWidth
                                                    size='small'
                                                    options={athleteOptions}
                                                    value={selectedAthlete}
                                                    onChange={handleAtheleteSelection}
                                                    getOptionSelected={(option, value) =>
                                                        option.id === value.id
                                                    }
                                                    getOptionLabel={option => {
                                                        if (option.id !== -1) {
                                                            return `${option.firstName} ${option.lastName} ${
                                                                option.mrn && `, #${option.mrn}`
                                                            } `;
                                                        } else {
                                                            return '';
                                                        }
                                                    }}
                                                    renderOption={option => {
                                                        if (option.id !== -1) {
                                                            return (
                                                                <Box className={classes.optionsContainer}>
                                                                    <Box className={classes.optionAvatar}>
                                                                        <img
                                                                            alt='profile'
                                                                            src={
                                                                                option.imageUrl
                                                                                    ? option.imageUrl
                                                                                    : defaultUser
                                                                            }
                                                                            className={classes.optionAvatarImage}
                                                                        />
                                                                    </Box>
                                                                    <Box
                                                                        className={classes.optionNameContainer}
                                                                    >{`${option.firstName} ${option.lastName}`}</Box>
                                                                    <Box>{`${
                                                                        option.mrn && `, #${option.mrn}`
                                                                    } `}</Box>
                                                                </Box>
                                                            );
                                                        } else {
                                                            return (
                                                                <Box className={classes.addOption}>
                                                                    Add Patient
                                                                </Box>
                                                            );
                                                        }
                                                    }}
                                                    renderInput={params => (
                                                        <TextValidator
                                                            {...params}
                                                            label='Patient *'
                                                            variant='outlined'
                                                        />
                                                    )}
                                                    validators={['required']}
                                                    errorMessages={['Patient is required']}
                                                />
                                            </Grid>
                                        )}

                                        {selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER && (
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <AutocompleteValidator
                                                    fullWidth
                                                    size='small'
                                                    options={caseStatus}
                                                    getOptionSelected={(option, value) =>
                                                        option.id === value.id
                                                    }
                                                    getOptionLabel={option => {
                                                        return (
                                                            option.description.charAt(0).toUpperCase() +
                                                            option.description.slice(1)
                                                        );
                                                    }}
                                                    renderInput={params => (
                                                        <TextValidator
                                                            {...params}
                                                            label='Status *'
                                                            variant='outlined'
                                                        />
                                                    )}
                                                    value={status}
                                                    onChange={(e, value) => setStatus(value)}
                                                    validators={['required']}
                                                    errorMessages={['Status is required']}
                                                />
                                            </Grid>
                                        )}

                                        {selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER && (
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <AutocompleteValidator
                                                    fullWidth
                                                    size='small'
                                                    options={caseStatus}
                                                    getOptionSelected={(option, value) =>
                                                        option.id === value.id
                                                    }
                                                    getOptionLabel={option => {
                                                        return (
                                                            option.description.charAt(0).toUpperCase() +
                                                            option.description.slice(1)
                                                        );
                                                    }}
                                                    renderInput={params => (
                                                        <TextValidator
                                                            {...params}
                                                            label='Status *'
                                                            variant='outlined'
                                                        />
                                                    )}
                                                    value={status}
                                                    onChange={(e, value) => setStatus(value)}
                                                    validators={['required']}
                                                    errorMessages={['Status is required']}
                                                />
                                            </Grid>
                                        )}

                                        {selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER && (
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePickerValidator
                                                        fullWidth
                                                        label='Injury Date *'
                                                        inputVariant='outlined'
                                                        size='small'
                                                        format='MM/dd/yyyy'
                                                        maxDate={new Date()}
                                                        value={injuryDate}
                                                        onChange={date => setInjuryDate(date)}
                                                        validators={['required']}
                                                        errorMessages={['Injury Date is required']}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        )}

                                        {selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER && (
                                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePickerValidator
                                                        fullWidth
                                                        label='Onset Date'
                                                        inputVariant='outlined'
                                                        size='small'
                                                        format='MM/dd/yyyy'
                                                        maxDate={new Date()}
                                                        value={onSetDate}
                                                        onChange={date => setOnSetDate(date)}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        )}

                                        {selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER && (
                                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePickerValidator
                                                        fullWidth
                                                        label='First Seen Date'
                                                        inputVariant='outlined'
                                                        size='small'
                                                        format='MM/dd/yyyy'
                                                        maxDate={new Date()}
                                                        value={firstSeenDate}
                                                        onChange={date => setFirstSeenDate(date)}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        )}

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <AutocompleteValidator
                                                fullWidth
                                                size='small'
                                                options={casePriorities}
                                                getOptionSelected={(option, value) =>
                                                    option.id === value.id
                                                }
                                                getOptionLabel={option => {
                                                    return (
                                                        option.description.charAt(0).toUpperCase() +
                                                        option.description.slice(1)
                                                    );
                                                }}
                                                renderInput={params => (
                                                    <TextValidator
                                                        {...params}
                                                        label='Urgency *'
                                                        variant='outlined'
                                                    />
                                                )}
                                                value={caseUrgency}
                                                onChange={(e, value) => setCaseUrgency(value)}
                                                validators={['required']}
                                                errorMessages={['Case urgency is required']}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Autocomplete
                                                fullWidth
                                                size='small'
                                                multiple
                                                options={caregivers ? caregivers.rows : []}
                                                value={selectedCaregivers}
                                                filterSelectedOptions
                                                onChange={(e, value) => {
                                                    setSelectedCaregivers([
                                                        ...fixedCaregiver,
                                                        ...value.filter(
                                                            option => fixedCaregiver.indexOf(option) === -1
                                                        ),
                                                    ]);
                                                }}
                                                getOptionLabel={option => {
                                                    return option.firstName + ' ' + option.lastName;
                                                }}
                                                getOptionSelected={(option, value) =>
                                                    option.userId === value.userId
                                                }
                                                renderOption={option => (
                                                    <Box className={classes.optionsContainer}>
                                                        <Box className={classes.optionAvatar}>
                                                            <Avatar alt='profile' src={option.imageUrl}/>
                                                        </Box>
                                                        <Box
                                                            className={classes.optionNameContainer}
                                                        >{`${option.firstName} ${option.lastName} `}</Box>
                                                    </Box>
                                                )}
                                                renderInput={params => (
                                                    <TextValidator
                                                        {...params}
                                                        label='Caregivers'
                                                        variant='outlined'
                                                    />
                                                )}
                                                renderTags={(tagValue, getTagProps) => {
                                                    return tagValue.map((option, index) => (
                                                        <CaregiverChip
                                                            key={index}
                                                            {...getTagProps({index})}
                                                            label={`${option.firstName} ${option.lastName}`}
                                                            url={option.imageUrl}
                                                            color={
                                                                option.userId === getUserInfo().id
                                                                    ? 'primary'
                                                                    : ''
                                                            }
                                                            deleteIcon={
                                                                option.userId === getUserInfo().id ? (
                                                                    <CheckIcon/>
                                                                ) : (
                                                                    <RemoveIcon/>
                                                                )
                                                            }
                                                        />
                                                    ));
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <TextValidator
                                                fullWidth
                                                label='Description *'
                                                size='small'
                                                variant='outlined'
                                                multiline
                                                rows={6}
                                                rowsMax={6}
                                                value={description}
                                                onChange={e => setDescription(e.target.value)}
                                                validators={['required']}
                                                errorMessages={['Description is required']}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box className={classes.addNoteHeadingContainer}>
                                    <Box>
                                        <Typography className={classes.addNoteLabel}>
                                            Notes
                                        </Typography>
                                    </Box>

                                    {!showNoteBox && (
                                        <Box>
                                            <IconButton
                                                color='primary'
                                                onClick={() => setShowNoteBox(true)}
                                            >
                                                <AddCircleIcon/>
                                            </IconButton>
                                        </Box>
                                    )}
                                </Box>

                                {showNoteBox && (
                                    <Box component={Paper} p={3} m={2}>
                                        <NoteForm
                                            handleNoteBoxClose={handleNoteBoxClose}
                                            note={note}
                                            setNote={setNote}
                                            noteImages={noteImages}
                                            noteDicomImages={noteDicomImages}
                                            handleRemoveDicom={handleRemoveDicom}
                                            setNoteImages={setNoteImages}
                                            handleNotes={handleNotes}
                                            showNoteErrorMessage={showNoteErrorMessage}
                                            setShowNoteErrorMessage={setShowNoteErrorMessage}
                                            handleMedicalUploadOpen={handleMedicalUploadOpen}
                                            handleMedicalUploadClose={handleMedicalUploadClose}
                                            openMedicalUpload={openMedicalUpload}
                                        />
                                    </Box>
                                )}

                                <Box>
                                    <Timeline>
                                        {noteItems.map((note, index) => (
                                            <NoteTimeline key={index} note={note}/>
                                        ))}
                                    </Timeline>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box className={classes.formBottomContainer}>
                        <Box component={Card} raised className={classes.buttonsContainer}>
                            <Box>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    disableElevation={true}
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    variant='contained'
                                    color='primary'
                                    disableElevation={true}
                                    type='submit'
                                    classes={{root: classes.savebutton}}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </ValidatorForm>

            <Box>
                <AddAthlete
                    openCreateAthleteModal={openCreateAthleteModal}
                    setOpenCreateAthleteModal={setOpenCreateAthleteModal}
                    setSelectedAthlete={setSelectedAthlete}
                />
            </Box>
        </>
    );
};

const mapStateToProps = state => {
    return {
        athletes: state.athletes.athletes,
        casePriorities: state.cases.casePriorities,
        caseStatus: state.cases.caseStatus,
        caregivers: state.cases.caregivers,
        selectedDept: state.sirenUsers.selectedDept,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addCase: data => {
            return dispatch(addCaseRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getAllAthletes: id => {
            return dispatch(getAthletesWithoutParamsRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getCasePriorities: data => {
            return dispatch(getCasePrioritiesRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getCaseStatus: data => {
            return dispatch(getCaseStatusRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getCaseStatusForDoctor: data => {
            return dispatch(getCaseStatusForDoctorRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getCaregiversOfDept: id => {
            return dispatch(getCaregiversOfDeptRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getSignedUrl: data => {
            return dispatch(getSignedUrlRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        uploadToS3: (url, file) => {
            return dispatch(uploadToS3Request(url, file)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getDicomUrl: () => {
            return dispatch(getDicomUrlRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        findDicomImage: data => {
            return dispatch(findDicomImageRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCase);
