import {
    ADD_ORGANISATION,
    GET_ORG_SUBTYPES,
    GET_ORG_TYPES,
    GET_ORGANISATION_BY_ID,
    GET_ORGANISATIONS,
    GET_ORGANISATIONS_WITHOUT_PARAMS,
    GET_ORGS_FOR_HC_ORG,
    GET_READ_RECEIPT_STATUS,
    SET_TAB_VALUE,
    TOGGLE_ORG_ACTIVATION,
    UPDATE_ORGANISATION,
} from './OrganisationsActions';

const initialState = {
    orgs: [],
    org: null,
    orgTypes: [],
    orgSubTypes: [],
    readReceiptStatus: [],
    orgsForHCOrg: [],
    tabValue: '1',
};

/**
 * reducer function to update state based on action
 * @param state: current state of the reducer
 * @param action: action to perform on the data
 * @returns {{update, updates: null}|{update: null, updates: null}|{update: null, updates: (null|*)}}
 * @constructor
 */
const OrganisationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TAB_VALUE:
            return {
                ...state,
                tabValue: action.tabValue,
            };

        case GET_ORGANISATIONS:
            return Object.assign({}, state, {
                orgs: action.orgs,
            });

        case GET_ORGANISATIONS_WITHOUT_PARAMS:
            return Object.assign({}, state, {
                orgs: action.orgs,
            });

        case ADD_ORGANISATION:
            return Object.assign({}, state, {
                org: action.org,
            });

        case GET_ORGANISATION_BY_ID:
            return Object.assign({}, state, {
                org: action.org,
            });

        case UPDATE_ORGANISATION:
            return Object.assign({}, state, {
                org: action.org,
            });

        case GET_ORG_TYPES:
            return Object.assign({}, state, {
                orgTypes: action.orgTypes,
            });

        case GET_ORG_SUBTYPES:
            return Object.assign({}, state, {
                orgSubTypes: action.orgSubTypes,
            });

        case GET_READ_RECEIPT_STATUS:
            return Object.assign({}, state, {
                readReceiptStatus: action.readReceiptStatus,
            });

        case GET_ORGS_FOR_HC_ORG:
            return Object.assign({}, state, {
                orgsForHCOrg: action.orgsForHCOrg,
            });

        case TOGGLE_ORG_ACTIVATION:
            return {
                ...state,
                org: {
                    ...state.org,
                    active: action.org.active,
                },
            };

        default:
            return state;
    }
};

export default OrganisationsReducer;
