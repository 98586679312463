import HttpRequest from '../../utilities/httpRequest';
import {acceptAgreement, changePassword, forgetPassword, setPassword, signIn, updateLoggedInUser,} from './AuthActions';
import {hideLoader, showLoader, showSnackbar,} from '../../utilities/UtilitiesActions';

/**
 * function to sign in
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const signInRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = '/loginMock';
        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(signIn(res.data.data));
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(!error.response.data.error){
                    dispatch(
                        showSnackbar('Invalid credentials or unauthorized user', 'error')
                    );
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function for forgot password
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const forgetPasswordRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = '/forgotpassword';
        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(forgetPassword(res.data.data));
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(error.response.data.error, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function set password for first time
 * @param id: id for api request
 * @param token: token for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const setPasswordRequest = (id, token, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `setpassword/${id}/${token}`;
        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(showSnackbar(res.data.data.message, 'success'));

                dispatch(setPassword(res.data.data));
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(error.response.data.error, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function for change password
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const changePasswordRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = '/changepassword';
        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(changePassword(res.data.data));
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Invalid current password', 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function for reset password
 * @param id: id for api request
 * @param token: token for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const resetPasswordRequest = (id, token, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `resetPassword/${id}/${token}`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(hideLoader());
                dispatch(showSnackbar(res.data.data.message, 'success'));
                return Promise.resolve(res.data.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(error.response.data.error, 'error'));
                return Promise.reject(error);
            });
    };
};

/**
 * function to get user by id
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getLoggedInUserByIdRequest = id => {
    return dispatch => {
        dispatch(showLoader());
        const url = `users/${id}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to update logged in user
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const updateLoggedInUserRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `users/${id}`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('User updated successfully', 'success'));
                dispatch(updateLoggedInUser(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(error.response.data.error, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to handle user agreement
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const acceptAgreementRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = '/acceptTermsAndConditions';
        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(acceptAgreement(res.data.data));
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Something went wrong', 'error'));
                return Promise.reject(error);
            }
        );
    };
};
