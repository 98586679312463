import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        container: {
            margin: '1.5rem',
        },
        labelContainer: {
            marginBottom: '10px',
        },
        headingLabel: {
            fontSize: '1.6rem',
        },
        profileImageContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0.5rem',
        },
        profileImageError: {
            height: '100px',
            width: '100px',
            borderRadius: '100%',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            textAlign: 'center',
        },
        profileImage: {
            height: '100px',
            width: '100px',
            borderRadius: '100%',
            '&:hover': {
                cursor: 'pointer',
            },
            border: '1px solid rgba(0, 0, 0, 0.2)',
        },
        imageErrorContainer: {
            marginTop: '5px',
        },
        imageError: {
            fontSize: '0.75rem',
            color: 'red',
        },
        formContainer: {
            marginTop: '20px',
        },
        saveButtonContainer: {
            width: '100%',
            textAlign: 'right',
        },
        editIcon: {
            position: 'absolute',
            bottom: '1px',
            right: '-5px',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            borderRadius: '50%',
            height: '35px',
            width: '35px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#fff',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        uploadContainer:{
            position: 'relative'
        }
    };
});
