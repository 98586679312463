import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import MaterialTable from 'material-table';
import {PAGE_SIZE, PAGE_SIZE_OPTIONS} from '../../../../utilities/constants';
import {useStyles} from './styles';
import AssignVendor from '../../../HealthcareOrganizations/components/AssignVendorForm/AssignVendorForm';
import ConfirmationDialog from '../../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import {removeAssignedVendorRequest} from '../../../HealthcareOrganizations/HealthcareOrganizationsApiActions';
import {getVendorsForHCOrgRequest} from '../../IntegrationVendorsApiActions';
import defaultProfile from '../../../../assets/group.png';
import {debounce} from '../../../../utilities/utilityFunctions';
import {Box, Button, FormControl, Icon, MenuItem, Select, Tooltip, Typography,} from '@material-ui/core';

/**
 * function to get message for remove dialog
 * @param vendor: vendor details
 * @param classes: classes from css
 * @returns {JSX.Element}
 * @constructor
 */
const RemoveDialogMessage = ({vendor, classes}) => {
    return (
        <>
            {vendor && (
                <Box>
                    Are you sure you want to remove the{' '}
                    <span className={classes.dialogMessageLabel}>{vendor.name}</span> from
                    vendor list?
                </Box>
            )}
        </>
    );
};

/**
 * function to render vendors list for healthcare organizations
 * @param getVendorsForHCOrg: function to get vendors for healthcare organizations
 * @param vendorsForHCOrg: list of vendors
 * @param removeAssignedVendor: function to remove assigned vendors
 * @param hcorgIdHistory: healthcare organization id
 * @param hcorgNameHistory: healthcare organization name
 * @returns {JSX.Element}
 * @constructor
 */
const VendorsForHCOrg = ({
                             getVendorsForHCOrg,
                             vendorsForHCOrg,
                             removeAssignedVendor,
                             hcorgIdHistory,
                             hcorgNameHistory,
                         }) => {
    const classes = useStyles();
    const params = useParams();
    const [paginationParams, setPaginationParams] = useState({
        page: 0,
        rowsPerPage: PAGE_SIZE,
        searchData: {
            name: '',
            active: '',
            desc: '',
        },
    });
    const [isFilterChange, setIsFilterChange] = useState(false);
    const [refreshList, setRefreshList] = useState(false);

    const [
        openRemoveConfirmationDialog,
        setOpenRemoveConfirmationDialog,
    ] = useState(false);
    const [openAssignVendorDialog, setOpenAssignVendorDialog] = useState(false);
    const [vendor, setVendor] = useState(null);
    const [vendorId, setVendorId] = useState('');

    /**
     * function to handle table filter search
     * @param filters: filters from filter input to search
     */
    const onTableFilterChange = filters => {
        let searchDataOnFilterChange = Object.assign({}, paginationParams);

        if (filters.length > 0) {
            let obj = {};
            filters.forEach(filter => {
                obj[filter.column.field] = filter.value;
            });

            Object.keys(searchDataOnFilterChange.searchData).forEach(function (key) {
                if (key !== 'active') {
                    if (obj[key]) {
                        searchDataOnFilterChange.searchData[key] = obj[key];
                    } else {
                        searchDataOnFilterChange.searchData[key] = '';
                    }
                }
            });

            filters.forEach(filter => {
                searchDataOnFilterChange.searchData[filter.column.field] = filter.value;
            });
        } else {
            searchDataOnFilterChange.searchData['name'] = '';
            searchDataOnFilterChange.searchData['desc'] = '';
        }

        setIsFilterChange(true);
        setPaginationParams(searchDataOnFilterChange);
    };

    /**
     * function to implement debounce
     * @type {(function(...[*]=): void)|*}
     */
    //eslint-disable-next-line
    const debounceOnFilterChange = React.useCallback(
        debounce(onTableFilterChange, 400),
        []
    );

    useEffect(() => {
        getVendorsForHCOrg(params.id, paginationParams, isFilterChange).then(
            res => {
                if (isFilterChange) {
                    setIsFilterChange(false);
                }
            }
        );
        //eslint-disable-next-line
    }, [params.id, getVendorsForHCOrg, paginationParams]);

    useEffect(() => {
        if(refreshList){
            getVendorsForHCOrg(params.id, paginationParams, isFilterChange).then(
                res => {
                    if (isFilterChange) {
                        setIsFilterChange(false);
                    }
                    setRefreshList(false);
                }
            );
        }
        //eslint-disable-next-line
    }, [params.id, getVendorsForHCOrg, paginationParams, refreshList]);

    /**
     * function to handle status change upon filter
     * @param value: selected status value
     */
    const handleStatusChange = value => {
        let data = Object.assign({}, paginationParams);
        data.searchData.active = value;

        setPaginationParams(data);
    };

    /**
     * function to remove organization on button click
     */
    const onRemove = () => {
        const data = {
            orgId: params.id,
        };

        removeAssignedVendor(vendor.VendorId, data).then(res => {
            setRefreshList(true);
            setOpenRemoveConfirmationDialog(false);
        });
    };

    const columns = [
        {
            field: 'name',
            title: 'Name',
            filtering: true,
            defaultFilter: paginationParams.searchData['name'],
            cellStyle: {
                width: '20%',
                minWidth: '20%',
            },
            headerStyle: {
                width: '20%',
                minWidth: '20%',
            },
            render: ({name, imageUrl, VendorId}) => {
                return (
                    <Box
                        className={classes.profileContainer}
                        component={Link}
                        to={{
                            pathname: `/healthcareOrgs/vendors/${VendorId}`,
                            state: {
                                hcorgIdHistory: hcorgIdHistory,
                                hcorgNameHistory: hcorgNameHistory,
                            },
                        }}
                    >
                        <img
                            src={imageUrl ? imageUrl : defaultProfile}
                            alt={VendorId}
                            className={classes.profileImage}
                        />
                        <Box className={classes.nameContainer}>
                            <Typography classes={{root: classes.name}}>{name}</Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'desc',
            title: 'Description',
            defaultFilter: paginationParams.searchData['desc'],
            filtering: true,
            cellStyle: {
                width: 'auto%',
            },
            headerStyle: {
                width: 'auto',
            },
        },
        {
            field: 'system',
            title: 'System',
            filtering: false,
            cellStyle: {
                textAlign: 'center',
                width: '20%',
            },
            headerStyle: {
                textAlign: 'center',
                width: '20%',
            },
            render: ({system}) => {
                return <>{system.toString()}</>;
            },
        },
        {
            field: 'activeStatus',
            title: 'Active',
            cellStyle: {
                textAlign: 'center',
                width: '10%',
            },
            headerStyle: {
                textAlign: 'center',
                width: '10%',
            },
            filtering: true,
            filterComponent: props => {
                return (
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                value={paginationParams.searchData.active}
                                onChange={e => handleStatusChange(e.target.value)}
                            >
                                <MenuItem value=''>All</MenuItem>
                                <MenuItem value='1'>Active</MenuItem>
                                <MenuItem value='0'>Blocked</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                );
            },
            render: ({active}) => {
                return (
                    <>
                        {active ? (
                            <Tooltip arrow placement='top' title='Active'>
                                <Icon className={classes.activeStatus}>
                                    check_circle_outline
                                </Icon>
                            </Tooltip>
                        ) : (
                            <Tooltip arrow placement='top' title='Blocked'>
                                <Icon className={classes.blockStatus}>block</Icon>
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
        {
            field: 'remove',
            title: 'Remove',
            cellStyle: {
                textAlign: 'center',
                width: '10%',
            },
            headerStyle: {
                textAlign: 'center',
                width: '10%',
            },
            filtering: false,
            render: props => {
                return (
                    <Box className={classes.actionIconsContainer}>
                        <Box>
                            <Tooltip arrow placement='top' title='Edit'>
                                <Icon
                                    className={classes.editIcon}
                                    onClick={() => {
                                        setOpenAssignVendorDialog(true);
                                        setVendorId(props.id);
                                    }}
                                >
                                    edit
                                </Icon>
                            </Tooltip>
                        </Box>

                        <Box>
                            <Tooltip arrow placement='top' title='Remove'>
                                <Icon
                                    className={classes.removeIcon}
                                    onClick={() => {
                                        setOpenRemoveConfirmationDialog(true);
                                        setVendor(props);
                                    }}
                                >
                                    highlight_off
                                </Icon>
                            </Tooltip>
                        </Box>
                    </Box>
                );
            },
        },
    ];

    return (
        <Box>
            <AssignVendor
                openAssignVendorDialog={openAssignVendorDialog}
                setOpenAssignVendorDialog={setOpenAssignVendorDialog}
                setRefreshList={setRefreshList}
                vendorId={vendorId}
                setVendorId={setVendorId}
            />

            <Box className={classes.addButtonContainer}>
                <Button
                    variant='contained'
                    color='primary'
                    startIcon={<Icon>add</Icon>}
                    disableElevation={true}
                    onClick={() => setOpenAssignVendorDialog(true)}
                    classes={{root: classes.addButton}}
                >
                    Vendor
                </Button>
            </Box>

            <Box>
                <MaterialTable
                    title=''
                    columns={columns}
                    data={vendorsForHCOrg.rows ? vendorsForHCOrg.rows : []}
                    options={{
                        search: false,
                        filtering: true,
                        sorting: false,
                        horizontalScroll: true,
                        pageSizeOptions: PAGE_SIZE_OPTIONS,
                        pageSize: paginationParams.rowsPerPage,
                        emptyRowsWhenPaging: false,
                    }}
                    pages={Math.ceil(
                        vendorsForHCOrg.count / paginationParams.rowsPerPage
                    )}
                    totalCount={vendorsForHCOrg.count}
                    page={paginationParams.page}
                    onChangeRowsPerPage={pageSize => {
                        let data = Object.assign({}, paginationParams);
                        data['rowsPerPage'] = pageSize;
                        setPaginationParams(data);
                    }}
                    onChangePage={page => {
                        let data = Object.assign({}, paginationParams);
                        data['page'] = page;
                        setPaginationParams(data);
                    }}
                    onFilterChange={(filters, filter) => {
                        debounceOnFilterChange(filters);
                    }}
                />
            </Box>

            {vendor && (
                <Box>
                    <ConfirmationDialog
                        title='Remove'
                        message={<RemoveDialogMessage vendor={vendor} classes={classes}/>}
                        open={openRemoveConfirmationDialog}
                        setOpen={setOpenRemoveConfirmationDialog}
                        onConfirm={onRemove}
                    />
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        vendorsForHCOrg: state.vendors.vendorsForHCOrg,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getVendorsForHCOrg: (vendorId, data, isHideLoader = false) => {
            return dispatch(
                getVendorsForHCOrgRequest(vendorId, data, isHideLoader)
            ).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        removeAssignedVendor: (vendorId, data) => {
            return dispatch(removeAssignedVendorRequest(vendorId, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorsForHCOrg);
