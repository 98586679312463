import {
    ADD_ADMIN,
    ADD_ADMIN_FROM_EXISTING_USER,
    GET_ADMINS_FOR_DEPT,
    GET_ADMINS_FOR_ORG,
    GET_USER_BY_EMAIL,
    REMOVE_ADMIN_FROM_DEPT,
    REMOVE_ADMIN_FROM_ORG,
} from './AdministratorsActions';

const initialState = {
    admins: [],
    admin: {},
    user: null,
};

/**
 * reducer function to update state based on action
 * @param state: current state of the reducer
 * @param action: action to perform on the data
 * @returns {({admin: {}, user: null, admins: *[]} & {user})|({admin: {}, user: null, admins: *[]} & {admins: ([]|*)})|({admin: {}, user: null, admins: *[]} & {admin: *})|{admin: {}, user: null, admins: *[]}}
 * @constructor
 */
const AdministratorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ADMIN:
            return Object.assign({}, state, {
                admin: action.admin,
            });

        case ADD_ADMIN_FROM_EXISTING_USER:
            return Object.assign({}, state, {
                admin: action.admin,
            });

        case GET_ADMINS_FOR_DEPT:
            return Object.assign({}, state, {
                admins: action.admins,
            });

        case GET_ADMINS_FOR_ORG:
            return Object.assign({}, state, {
                admins: action.admins,
            });

        case REMOVE_ADMIN_FROM_ORG:
            return Object.assign({}, state, {
                admin: action.admin,
            });

        case REMOVE_ADMIN_FROM_DEPT:
            return Object.assign({}, state, {
                admin: action.admin,
            });

        case GET_USER_BY_EMAIL:
            return Object.assign({}, state, {
                user: action.user,
            });

        default:
            return state;
    }
};

export default AdministratorsReducer;
