import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, NavLink, useParams, useRouteMatch} from 'react-router-dom';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import DepartmentPatients from '../../../Patients/DepartmentPatients/DepartmentPatients';
import DepartmentCaregivers from '../../../Caregivers/components/DepartmentCaregivers/DepartmentCaregivers';
import DepartmentAdministrators from '../../../Administrators/components/DepartmentAdmins/DepartmentAdmins';
import {handleTabChange} from '../../DepartmentsActions';
import ConfirmationDialog from '../../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import {useStyles} from './styles';
import defaultProfile from '../../../../assets/user.png';
import {getPatientsForDeptRequest} from '../../../Patients/PatientsApiActions';
import {PAGE_SIZE} from '../../../../utilities/constants';
import {getIsTeamList, getUserInfo} from '../../../../utilities/storage';
import ImageLoader from 'react-load-image';
import Preloader from '../../../../utilities/components/Preloader/Preloader';
import {getDeptByIdRequest, syncArmsRequest, toggleActivationRequest,} from '../../DepartmentsApiActions';
import {Box, Breadcrumbs, Button, Icon, Paper, Tab, Typography,} from '@material-ui/core';
import {ROLES} from '../../../../utilities/constants';

/**
 * function to get message for activation dialog
 * @param dept: dept details
 * @param classes: classes from css
 * @returns {JSX.Element}
 * @constructor
 */
const ToggleActivationDialogMessage = ({dept, classes}) => {
    return (
        <>
            {dept && (
                <Box>
                    Are you sure you want to {dept.active ? 'deactivate' : 'activate'} the{' '}
                    <span className={classes.dialogMessageLabel}>{dept.name}</span>?
                </Box>
            )}
        </>
    );
};

/**
 * function to render department profile
 * @param location: location from router
 * @param getDeptById: function to get department by id
 * @param dept: department details
 * @param toggleActivation: function to toggle department activation
 * @param handleTabChange: function to handle tab change
 * @param tabValue: tab value
 * @param syncArms: function for sync arms
 * @param getAllPatients: function to get all patients
 * @returns {JSX.Element}
 * @constructor
 */
const DepartmentProfile = ({
                               location,
                               getDeptById,
                               dept,
                               toggleActivation,
                               handleTabChange,
                               tabValue,
                               syncArms,
                               getAllPatients,
                           }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const params = useParams();
    const match = useRouteMatch();
    const [
        openToggleActivationConfirmationDialog,
        setOpenToggleActivationConfirmationDialog,
    ] = useState(false);
    const [openSyncConfirmationDialog, setOpenSyncConfirmationDialog] = useState(
        false
    );
    const currentUrlPath = match.path.split(':')[0];

    useEffect(() => {
        getDeptById(params.id).then(res => {
        });
    }, [params.id, getDeptById]);

    const breadCrumbDataForOrgDeptProfilePage = [
        {
            icon: 'business',
            name: 'Organizations',
            path: '/orgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/orgs/${location.state && location.state.orgIdHistory}`,
        },
        {
            name: `${dept ? `${dept.name}` : ''}`,
            path: `/orgs/departments/${params.id}`,
        },
    ];

    const breadCrumbDataForHcorgOrgDeptProfilePage = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: '/healthcareOrgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/${
                location.state && location.state.orgIdHistory
            }`,
        },
        {
            name: `${dept ? `${dept.name}` : ''}`,
            path: `/healthcareOrgs/orgs/departments/${params.id}`,
        },
    ];

    const breadCrumbDataForAdminDeptProfilePage = [
        {
            icon: 'apartment',
            name: 'Departments',
            path: '/departments',
        },
        {
            name: `${dept ? `${dept.name}` : ''}`,
            path: `/departments/${params.id}`,
        },
    ];

    /**
     * function to get breadcrumb data for current url
     */

    const getBreadcrumbData = (urlPath) => {
        switch(urlPath){
            case '/orgs/departments/':{
                return breadCrumbDataForOrgDeptProfilePage
            }
            case '/healthcareOrgs/orgs/departments/':{
               return breadCrumbDataForHcorgOrgDeptProfilePage
            }
            case '/departments/':{
                return breadCrumbDataForAdminDeptProfilePage
             }
            default: {
                return []
            }
        }
    }

    /**
     * function to handle tab change
     * @param e: event from button click
     * @param newValue: newValue from button click
     */
    const handleChange = (e, newValue) => {
        handleTabChange(newValue);
    };

    /**
     * function to toggle department activation
     */
    const onToggleActivation = () => {
        const data = {
            active: !dept.active,
        };

        toggleActivation(params.id, data).then(res => {
            setOpenToggleActivationConfirmationDialog(false);
            getAllPatients(params.id, {
                page: 0,
                rowsPerPage: PAGE_SIZE,
                searchData: {},
            }).then(res => {
            });
        });
    };

    /**
     * function to handle sync arms
     */
    const onSyncArms = () => {
        const data = {
            deptId: Number(params.id),
        };

        syncArms(data).then(res => {
            setOpenSyncConfirmationDialog(false);
            getDeptById(params.id).then(res => {
            });
            getAllPatients(params.id, {
                page: 0,
                rowsPerPage: PAGE_SIZE,
                searchData: {},
            }).then(res => {
            });
        });
    };

    return (
        <Box
            className={
                role === ROLES.SIRENMD_ADMIN &&
                getIsTeamList() === 'false'
                    ? ''
                    : classes.outerContainer
            }
        >
            <Box
                className={
                    role === ROLES.SIRENMD_ADMIN &&
                    getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainer
                        : getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainerForAdmins
                        : classes.breadcrumbsContainerForUsers
                }
                component={Paper}
                p={1}
            >
                <Breadcrumbs>
                    {getBreadcrumbData(currentUrlPath).map((crumb, index) => (
                        <Box
                            key={index}
                            component={NavLink}
                            exact
                            to={{
                                pathname: crumb.path,
                                state: {
                                    orgIdHistory: location.state && location.state.orgIdHistory,
                                    orgNameHistory:
                                        location.state && location.state.orgNameHistory,
                                    hcorgIdHistory:
                                        location.state && location.state.hcorgIdHistory,
                                    hcorgNameHistory:
                                        location.state && location.state.hcorgNameHistory,
                                },
                            }}
                            className={classes.breadcrumbItem}
                            activeClassName={classes.activeBreadcrumbItem}
                        >
                            {crumb.icon && (
                                <Icon className={classes.breadcrumpIcon}>{crumb.icon}</Icon>
                            )}
                            <Box className={classes.breadcrumpLabel}>{crumb.name}</Box>
                        </Box>
                    ))}
                </Breadcrumbs>
            </Box>

            <Box mt={3}>
                {dept && (
                    <>
                        <Box>
                            <Box component={Paper} className={classes.profileContainer}>
                                <Box className={classes.profileContentContainer}>
                                    <Box>
                                        <ImageLoader
                                            src={dept.imageUrl ? dept.imageUrl : defaultProfile}
                                        >
                                            <img
                                                alt='profile'
                                                className={`${classes.profileImage} ${
                                                    dept.imageUrl ? '' : classes.imageBorder
                                                }`}
                                            />
                                            <div className={classes.profileImageError}>{dept.id}</div>
                                            <Preloader
                                                className={`${classes.profileImage} ${
                                                    dept.imageUrl ? '' : classes.imageBorder
                                                }`}
                                            />
                                        </ImageLoader>
                                    </Box>
                                    <Box className={classes.profileTextContainer}>
                                        <Typography classes={{root: classes.nameText}}>
                                            {dept.name}
                                        </Typography>
                                        <Typography classes={{root: classes.descText}}>
                                            {dept.desc}
                                        </Typography>

                                        <Box className={classes.editDetailsButtonContainer}>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                disableElevation={true}
                                                startIcon={<Icon>edit</Icon>}
                                                component={Link}
                                                to={{
                                                    pathname: `${match.path.split(':')[0]}edit/${
                                                        params.id
                                                    }`,
                                                    state: {
                                                        orgIdHistory:
                                                            location.state && location.state.orgIdHistory
                                                                ? location.state.orgIdHistory
                                                                : dept.OrganizationId,
                                                        orgNameHistory:
                                                            location.state && location.state.orgNameHistory,
                                                        deptIdHistory: dept && dept.id,
                                                        deptNameHistory: dept && dept.name,
                                                        hcorgIdHistory:
                                                            location.state && location.state.hcorgIdHistory,
                                                        hcorgNameHistory:
                                                            location.state && location.state.hcorgNameHistory,
                                                        orgTypeIdHistory: dept.Organization && dept.Organization.typeId
                                                    },
                                                }}
                                                classes={{root: classes.profileButton}}
                                            >
                                                Edit Details
                                            </Button>
                                        </Box>

                                        <Box>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                disableElevation={true}
                                                startIcon={
                                                    dept.active === false ? (
                                                        <Icon>check_circle_outline</Icon>
                                                    ) : (
                                                        <Icon>block</Icon>
                                                    )
                                                }
                                                classes={{
                                                    root: `${classes.profileButton} ${
                                                        dept.active
                                                            ? classes.blockButton
                                                            : classes.activeButton
                                                    }`,
                                                }}
                                                onClick={() =>
                                                    setOpenToggleActivationConfirmationDialog(true)
                                                }
                                            >
                                                {dept.active === false ? 'Activate' : 'Deactivate'}
                                            </Button>
                                        </Box>

                                        {dept.armsId && (
                                            <Box className={classes.armsContainer}>
                                                <Box>
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        disableElevation={true}
                                                        startIcon={<Icon>sync</Icon>}
                                                        classes={{root: classes.profileButton}}
                                                        onClick={() => setOpenSyncConfirmationDialog(true)}
                                                    >
                                                        Sync ARMS Team Members
                                                    </Button>
                                                </Box>

                                                <Box className={classes.lastSyncLabel}>
                                                    {dept.syncStatus && `(${dept.syncStatus})`}
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>

                            <Box component={Paper} className={classes.tabsContainer}>
                                <TabContext value={tabValue}>
                                    <TabList
                                        onChange={handleChange}
                                        indicatorColor='primary'
                                        textColor='primary'
                                        variant='fullWidth'
                                    >
                                        <Tab label='Patients' value='1'/>
                                        <Tab label='Caregivers' value='2'/>
                                        {role === ROLES.SIRENMD_ADMIN ? (
                                            <Tab label='Administrators' value='3'/>
                                        ) : (
                                            <></>
                                        )}
                                    </TabList>
                                    <TabPanel
                                        value='1'
                                        classes={{root: classes.tabPanelContainer}}
                                    >
                                        <DepartmentPatients
                                            orgIdHistory={
                                                location.state && location.state.orgIdHistory
                                                    ? location.state.orgIdHistory
                                                    : dept.OrganizationId
                                            }
                                            orgNameHistory={
                                                location.state && location.state.orgNameHistory
                                            }
                                            deptIdHistory={dept.id}
                                            deptNameHistory={dept.name}
                                            hcorgIdHistory={
                                                location.state && location.state.hcorgIdHistory
                                            }
                                            hcorgNameHistory={
                                                location.state && location.state.hcorgNameHistory
                                            }
                                        />
                                    </TabPanel>

                                    <TabPanel
                                        value='2'
                                        classes={{root: classes.tabPanelContainer}}
                                    >
                                        <DepartmentCaregivers
                                            orgIdHistory={
                                                location.state
                                                    ? location.state.orgIdHistory
                                                    : dept.OrganizationId
                                            }
                                            orgNameHistory={
                                                location.state && location.state.orgNameHistory
                                            }
                                            deptIdHistory={dept.id}
                                            deptNameHistory={dept.name}
                                            hcorgIdHistory={
                                                location.state && location.state.hcorgIdHistory
                                            }
                                            hcorgNameHistory={
                                                location.state && location.state.hcorgNameHistory
                                            }
                                        />
                                    </TabPanel>
                                    <TabPanel
                                        value='3'
                                        classes={{root: classes.tabPanelContainer}}
                                    >
                                        <DepartmentAdministrators
                                            orgIdHistory={
                                                location.state && location.state.orgIdHistory
                                            }
                                            orgNameHistory={
                                                location.state && location.state.orgNameHistory
                                            }
                                            deptIdHistory={dept.id}
                                            deptNameHistory={dept.name}
                                            hcorgIdHistory={
                                                location.state && location.state.hcorgIdHistory
                                            }
                                            hcorgNameHistory={
                                                location.state && location.state.hcorgNameHistory
                                            }
                                        />
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </Box>
                    </>
                )}

                {dept && (
                    <Box>
                        <ConfirmationDialog
                            title={dept.active ? 'Deactivate' : 'Activate'}
                            message={
                                <ToggleActivationDialogMessage dept={dept} classes={classes}/>
                            }
                            open={openToggleActivationConfirmationDialog}
                            setOpen={setOpenToggleActivationConfirmationDialog}
                            onConfirm={onToggleActivation}
                        />
                    </Box>
                )}

                <Box>
                    <ConfirmationDialog
                        title='Sync ARMS Team Members'
                        message='Are you sure you want to sync ARMS team members?'
                        open={openSyncConfirmationDialog}
                        setOpen={setOpenSyncConfirmationDialog}
                        onConfirm={onSyncArms}
                    />
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        dept: state.departments.dept,
        tabValue: state.departments.tabValue,
    };
};

const mapDispatchToProp = dispatch => {
    return {
        getDeptById: id => {
            return dispatch(getDeptByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        handleTabChange: value => {
            return dispatch(handleTabChange(value));
        },
        toggleActivation: (deptId, data) => {
            return dispatch(toggleActivationRequest(deptId, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        syncArms: data => {
            return dispatch(syncArmsRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getAllPatients: (id, data) => {
            return dispatch(getPatientsForDeptRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProp)(DepartmentProfile);
