import React from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import Users from '../Users/components/UsersList/UsersList';
import ProfilePageFormAdminUsers from '../Users/components/AdminUsersProfile/AdminUsersProfile';
import EditProfileFormForAdminUsers from '../Users/components/EditUserFormForAdmins/EditUserFormForAdmins';
import Organizations from '../Organizations/components/OrganizationsList/OrganizationsList';
import AddOrganization from '../Organizations/components/OrganizationForm/OrganizationForm';
import OrganisationProfile from '../Organizations/components/OrganisationProfile/OrganisationProfile';
import DepartmentProfile from '../Departments/components/DepartmentProfile/DepartmentProfile';
import DepartmentForm from '../Departments/components/DepartmentForm/DepartmentForm';
import PatientProfile from '../Patients/PatientProfile/PatientProfile';
import PatientForm from '../Patients/PatientForm/PatientForm';
import AdminFormForDeptAdmin from '../Administrators/components/AdminFormForDeptAdmin/AdminFormForDeptAdmin';
import AdminFormForOrgAdmin from '../Administrators/components/AdminFormForOrgAdmin/AdminFormForOrgAdmin';
import HealthcareOrganizations
        from '../HealthcareOrganizations/components/HealthcareOrganizationsList/HealthcareOrganizationsList';
import AddHealthcareOrganization
        from '../HealthcareOrganizations/components/HealthcareOrganizationForm/HealthcareOrganizationForm';
import HealthcareOrganisationProfile
        from '../HealthcareOrganizations/components/HealthcareOrganizationProfile/HealthcareOrganisationProfile';
import VendorsList from '../IntegrationVendors/components/VendorsList/VendorsList';
import VendorsForm from '../IntegrationVendors/components/VendorsForm/VendorsForm';
import VendorsProfile from '../IntegrationVendors/components/VendorsProfile/VendorsProfile';
import CaregiverForm from '../Caregivers/components/CaregiverForm/CaregiverForm';
import DataUtilities from '../DataUtilities/components/dataUtilities/DataUtilities';
import TransactionMonitoring
        from '../TransactionMonitoring/components/transactionMonitoring/TransactionMonitoring';
import ProfilePageForUsers from '../Users/components/CaregiverProfile/CaregiverProfile';
import EditProfileFormForUsers
        from '../Users/components/EditProfileFormForCaregivers/EditProfileFormForCaregivers';
import PlayerStatus from '../PlayStatus/components/PlayStatusList/PlayStatusList';
import NewUpdates from '../NewUpdates/components/NewUpdatesList/NewUpdatesList';
import Cases from '../Cases/components/CasesList/CasesList';
import Case from '../Cases/components/CaseProfile/CaseProfile';
import AddCase from '../Cases/components/CaseForm/CaseForm';
import Athletes from '../Athletes/components/AthletesList/AthletesList';
import Athlete from '../Athletes/components/AthleteProfile/AthleteProfile';
import AnalyticsDashboard from '../AnalyticsDashboard/components/AnalyticsList/AnalyticsList';
import AdminDepts from '../Departments/components/AdminDepartments/AdminDepartments';
import {getAdmin, getIsTeamList, getUserInfo,} from '../../utilities/storage';
import {ROLES} from '../../utilities/constants';
import ErrorPage from '../../utilities/pages/ErrorPage/ErrorPage';

/**
 * function to render routes
 * @returns {JSX.Element}
 * @constructor
 */
const DashboardRoutes = ({selectedDept}) => {
    const role = getUserInfo().Roles[0].name;
    if (
        role === ROLES.SIRENMD_ADMIN &&
        getIsTeamList() === 'false'
    ) {
        return (
            <Switch>
                <Route exact path='/' component={() => <Redirect to='/orgs'/>}/>
                {/* profile routes */}
                <Route exact path='/profile' component={ProfilePageFormAdminUsers}/>
                <Route
                    exact
                    path='/profile/edit'
                    component={EditProfileFormForAdminUsers}
                />

                {/* users routes */}
                <Route exact path='/users' component={Users}/>
                <Route exact path='/users/:id' component={ProfilePageFormAdminUsers}/>
                <Route
                    exact
                    path='/users/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />

                {/* orgs routes */}
                <Route exact path='/orgs' component={Organizations}/>
                <Route exact path='/orgs/add' component={AddOrganization}/>
                <Route exact path='/orgs/addDepartment' component={DepartmentForm}/>
                <Route exact path='/orgs/addCaregiver' component={CaregiverForm}/>
                <Route exact path='/orgs/addAdmin' component={AdminFormForOrgAdmin}/>
                <Route exact path='/orgs/edit/:id' component={AddOrganization}/>
                <Route exact path='/orgs/:id' component={OrganisationProfile}/>

                {/* orgs caregivers routes  */}
                <Route
                    exact
                    path='/orgs/caregivers/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/orgs/caregivers/:id'
                    component={ProfilePageFormAdminUsers}
                />

                {/* orgs admins routes  */}
                <Route
                    exact
                    path='/orgs/admins/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/orgs/admins/:id'
                    component={ProfilePageFormAdminUsers}
                />

                {/* orgs depts routes  */}
                <Route
                    exact
                    path='/orgs/departments/addPatient'
                    component={PatientForm}
                />
                <Route
                    exact
                    path='/orgs/departments/addCaregiver'
                    component={CaregiverForm}
                />
                <Route
                    exact
                    path='/orgs/departments/addAdmin'
                    component={AdminFormForDeptAdmin}
                />
                <Route
                    exact
                    path='/orgs/departments/patients/edit/:id'
                    component={PatientForm}
                />
                <Route
                    exact
                    path='/orgs/departments/patients/:id'
                    component={PatientProfile}
                />

                <Route
                    exact
                    path='/orgs/departments/caregivers/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/orgs/departments/caregivers/:id'
                    component={ProfilePageFormAdminUsers}
                />
                <Route
                    exact
                    path='/orgs/departments/admins/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/orgs/departments/admins/:id'
                    component={ProfilePageFormAdminUsers}
                />
                <Route
                    exact
                    path='/orgs/departments/edit/:id'
                    component={DepartmentForm}
                />
                <Route
                    exact
                    path='/orgs/departments/:id'
                    component={DepartmentProfile}
                />

                {/* hcorgs routes */}
                <Route
                    exact
                    path='/healthcareOrgs'
                    component={HealthcareOrganizations}
                />
                <Route
                    exact
                    path='/healthcareOrgs/add'
                    component={AddHealthcareOrganization}
                />
                <Route
                    exact
                    path='/healthcareOrgs/edit/:id'
                    component={AddHealthcareOrganization}
                />
                <Route
                    exact
                    path='/healthcareOrgs/:id'
                    component={HealthcareOrganisationProfile}
                />

                {/* hcorgs vendors routes  */}
                <Route
                    exact
                    path='/healthcareOrgs/vendors/edit/:id'
                    component={VendorsForm}
                />
                <Route
                    exact
                    path='/healthcareOrgs/vendors/:id'
                    component={VendorsProfile}
                />

                {/* hcorgs caregivers routes  */}
                <Route
                    exact
                    path='/healthcareOrgs/caregivers/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/healthcareOrgs/caregivers/:id'
                    component={ProfilePageFormAdminUsers}
                />

                {/* hcorgs orgs routes  */}
                <Route
                    exact
                    path='/healthcareOrgs/orgs/addDepartment'
                    component={DepartmentForm}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/addCaregiver'
                    component={CaregiverForm}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/addAdmin'
                    component={AdminFormForOrgAdmin}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/edit/:id'
                    component={AddOrganization}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/:id'
                    component={OrganisationProfile}
                />

                {/* hcorgs orgs admin routes  */}
                <Route
                    exact
                    path='/healthcareOrgs/orgs/admins/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/admins/:id'
                    component={ProfilePageFormAdminUsers}
                />

                {/* hcorgs orgs caregivers routes  */}
                <Route
                    exact
                    path='/healthcareOrgs/orgs/caregivers/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/caregivers/:id'
                    component={ProfilePageFormAdminUsers}
                />

                {/* hcorgs orgs depts routes  */}
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/addPatient'
                    component={PatientForm}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/addCaregiver'
                    component={CaregiverForm}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/addAdmin'
                    component={AdminFormForDeptAdmin}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/patients/edit/:id'
                    component={PatientForm}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/patients/:id'
                    component={PatientProfile}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/caregivers/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/caregivers/:id'
                    component={ProfilePageFormAdminUsers}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/admins/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/admins/:id'
                    component={ProfilePageFormAdminUsers}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/edit/:id'
                    component={DepartmentForm}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/:id'
                    component={DepartmentProfile}
                />

                {/* vendors routes  */}
                <Route exact path='/vendors/edit/:id' component={VendorsForm}/>
                <Route exact path='/vendors/add' component={VendorsForm}/>
                <Route exact path='/vendors/:id' component={VendorsProfile}/>
                <Route exact path='/vendors' component={VendorsList}/>

                {/* data utilities routes  */}
                <Route exact path='/dataUtilities' component={DataUtilities}/>

                {/* trasaction monitoring routes  */}
                <Route
                    exact
                    path='/transactionMonitoring'
                    component={TransactionMonitoring}
                />

                <Route
                    component={() => (
                        <ErrorPage
                            code={403}
                            message='You do not have access to view this page.'
                        />
                    )}
                />
            </Switch>
        );
    } else if (
        role === ROLES.SIRENMD_ADMIN &&
        getIsTeamList() === 'true'
    ) {
        if(selectedDept){
            return(
                <Switch>
                <Route exact path='/' component={() => <Redirect to='/newUpdates'/>}/>
                {/* users routes  */}
                <Route exact path='/profile' component={ProfilePageForUsers}/>
                <Route exact path='/profile/edit' component={EditProfileFormForUsers}/>

                <Route exact path='/newUpdates' component={NewUpdates}/>
                <Route exact path='/playStatusTeamRoster' component={PlayerStatus}/>
                <Route exact path='/cases/addCase' component={AddCase}/>
                <Route exact path='/cases' component={Cases}/>
                <Route exact path='/cases/:id' component={Case}/>
                <Route exact path='/athletes/:id' component={Athlete}/>
                <Route exact path='/athletes' component={Athletes}/>
                <Route
                    exact
                    path='/analyticsDashboard'
                    component={AnalyticsDashboard}
                />

                {/* profile routes */}
                <Route
                    exact
                    path='/profile/:id'
                    component={ProfilePageFormAdminUsers}
                />
                <Route
                    exact
                    path='/profile/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />

                {/* users routes */}
                <Route exact path='/users' component={Users}/>
                <Route exact path='/users/:id' component={ProfilePageFormAdminUsers}/>
                <Route
                    exact
                    path='/users/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />

                {/* orgs routes */}
                <Route exact path='/orgs' component={Organizations}/>
                <Route exact path='/orgs/add' component={AddOrganization}/>
                <Route exact path='/orgs/addDepartment' component={DepartmentForm}/>
                <Route exact path='/orgs/addCaregiver' component={CaregiverForm}/>
                <Route exact path='/orgs/addAdmin' component={AdminFormForOrgAdmin}/>
                <Route exact path='/orgs/edit/:id' component={AddOrganization}/>
                <Route exact path='/orgs/:id' component={OrganisationProfile}/>

                {/* orgs caregivers routes  */}
                <Route
                    exact
                    path='/orgs/caregivers/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/orgs/caregivers/:id'
                    component={ProfilePageFormAdminUsers}
                />

                {/* orgs admins routes  */}
                <Route
                    exact
                    path='/orgs/admins/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/orgs/admins/:id'
                    component={ProfilePageFormAdminUsers}
                />

                {/* orgs depts routes  */}
                <Route
                    exact
                    path='/orgs/departments/addPatient'
                    component={PatientForm}
                />
                <Route
                    exact
                    path='/orgs/departments/addCaregiver'
                    component={CaregiverForm}
                />
                <Route
                    exact
                    path='/orgs/departments/addAdmin'
                    component={AdminFormForDeptAdmin}
                />
                <Route
                    exact
                    path='/orgs/departments/patients/edit/:id'
                    component={PatientForm}
                />
                <Route
                    exact
                    path='/orgs/departments/patients/:id'
                    component={PatientProfile}
                />

                <Route
                    exact
                    path='/orgs/departments/caregivers/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/orgs/departments/caregivers/:id'
                    component={ProfilePageFormAdminUsers}
                />
                <Route
                    exact
                    path='/orgs/departments/admins/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/orgs/departments/admins/:id'
                    component={ProfilePageFormAdminUsers}
                />
                <Route
                    exact
                    path='/orgs/departments/edit/:id'
                    component={DepartmentForm}
                />
                <Route
                    exact
                    path='/orgs/departments/:id'
                    component={DepartmentProfile}
                />

                {/* hcorgs routes */}
                <Route
                    exact
                    path='/healthcareOrgs'
                    component={HealthcareOrganizations}
                />
                <Route
                    exact
                    path='/healthcareOrgs/add'
                    component={AddHealthcareOrganization}
                />
                <Route
                    exact
                    path='/healthcareOrgs/edit/:id'
                    component={AddHealthcareOrganization}
                />
                <Route
                    exact
                    path='/healthcareOrgs/:id'
                    component={HealthcareOrganisationProfile}
                />

                {/* hcorgs vendors routes  */}
                <Route
                    exact
                    path='/healthcareOrgs/vendors/edit/:id'
                    component={VendorsForm}
                />
                <Route
                    exact
                    path='/healthcareOrgs/vendors/:id'
                    component={VendorsProfile}
                />

                {/* hcorgs caregivers routes  */}
                <Route
                    exact
                    path='/healthcareOrgs/caregivers/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/healthcareOrgs/caregivers/:id'
                    component={ProfilePageFormAdminUsers}
                />

                {/* hcorgs orgs routes  */}
                <Route
                    exact
                    path='/healthcareOrgs/orgs/addDepartment'
                    component={DepartmentForm}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/addCaregiver'
                    component={CaregiverForm}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/addAdmin'
                    component={AdminFormForOrgAdmin}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/edit/:id'
                    component={AddOrganization}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/:id'
                    component={OrganisationProfile}
                />

                {/* hcorgs orgs admin routes  */}
                <Route
                    exact
                    path='/healthcareOrgs/orgs/admins/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/admins/:id'
                    component={ProfilePageFormAdminUsers}
                />

                {/* hcorgs orgs caregivers routes  */}
                <Route
                    exact
                    path='/healthcareOrgs/orgs/caregivers/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/caregivers/:id'
                    component={ProfilePageFormAdminUsers}
                />

                {/* hcorgs orgs depts routes  */}
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/addPatient'
                    component={PatientForm}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/addCaregiver'
                    component={CaregiverForm}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/addAdmin'
                    component={AdminFormForDeptAdmin}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/patients/edit/:id'
                    component={PatientForm}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/patients/:id'
                    component={PatientProfile}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/caregivers/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/caregivers/:id'
                    component={ProfilePageFormAdminUsers}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/admins/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/admins/:id'
                    component={ProfilePageFormAdminUsers}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/edit/:id'
                    component={DepartmentForm}
                />
                <Route
                    exact
                    path='/healthcareOrgs/orgs/departments/:id'
                    component={DepartmentProfile}
                />

                {/* vendors routes  */}
                <Route exact path='/vendors/edit/:id' component={VendorsForm}/>
                <Route exact path='/vendors/add' component={VendorsForm}/>
                <Route exact path='/vendors/:id' component={VendorsProfile}/>
                <Route exact path='/vendors' component={VendorsList}/>

                {/* data utilities routes  */}
                <Route exact path='/dataUtilities' component={DataUtilities}/>

                {/* trasaction monitoring routes  */}
                <Route
                    exact
                    path='/transactionMonitoring'
                    component={TransactionMonitoring}
                />

                <Route
                    component={() => (
                        <ErrorPage
                            code={403}
                            message='You do not have access to view this page.'
                        />
                    )}
                />
            </Switch>
            )
        }else{
            return<></>
        }
    } else if (
        getIsTeamList() === 'true' &&
        getAdmin() &&
        getAdmin().isOrgAdmin
    ) {
        if(selectedDept){
            return (
                <Switch>
                    <Route exact path='/' component={() => <Redirect to='/newUpdates'/>}/>
                    {/* users routes  */}
                    <Route exact path='/profile' component={ProfilePageForUsers}/>
                    <Route exact path='/profile/edit' component={EditProfileFormForUsers}/>
    
                    <Route exact path='/newUpdates' component={NewUpdates}/>
                    <Route exact path='/playStatusTeamRoster' component={PlayerStatus}/>
                    <Route exact path='/cases/addCase' component={AddCase}/>
                    <Route exact path='/cases' component={Cases}/>
                    <Route exact path='/cases/:id' component={Case}/>
                    <Route exact path='/athletes/:id' component={Athlete}/>
                    <Route exact path='/athletes' component={Athletes}/>
                    <Route
                        exact
                        path='/analyticsDashboard'
                        component={AnalyticsDashboard}
                    />
    
                    {/* org admin routes  */}
                    <Route exact path='/orgs' component={Organizations}/>
                    <Route exact path='/orgs/add' component={() => (
                        <ErrorPage
                            code={403}
                            message='You do not have access to view this page.'
                        />
                    )}/>
                    <Route exact path='/users' component={Users}/>
                    <Route exact path='/users/:id' component={ProfilePageFormAdminUsers}/>
                    <Route
                        exact
                        path='/users/edit/:id'
                        component={EditProfileFormForAdminUsers}
                    />
                    <Route exact path='/dataUtilities' component={DataUtilities}/>
    
                    {/* nested access routes  */}
                    <Route exact path='/orgs/addDepartment' component={DepartmentForm}/>
                    <Route exact path='/orgs/addCaregiver' component={CaregiverForm}/>
                    <Route exact path='/orgs/edit/:id' component={AddOrganization}/>
                    <Route exact path='/orgs/:id' component={OrganisationProfile}/>
    
                    {/* orgs caregivers routes  */}
                    <Route
                        exact
                        path='/orgs/caregivers/edit/:id'
                        component={EditProfileFormForAdminUsers}
                    />
                    <Route
                        exact
                        path='/orgs/caregivers/:id'
                        component={ProfilePageFormAdminUsers}
                    />
    
                    {/* orgs depts routes  */}
                    <Route
                        exact
                        path='/orgs/departments/addPatient'
                        component={PatientForm}
                    />
                    <Route
                        exact
                        path='/orgs/departments/addCaregiver'
                        component={CaregiverForm}
                    />
                    <Route
                        exact
                        path='/orgs/departments/addAdmin'
                        component={AdminFormForDeptAdmin}
                    />
                    <Route
                        exact
                        path='/orgs/departments/patients/edit/:id'
                        component={PatientForm}
                    />
                    <Route
                        exact
                        path='/orgs/departments/patients/:id'
                        component={PatientProfile}
                    />
    
                    <Route
                        exact
                        path='/orgs/departments/caregivers/edit/:id'
                        component={EditProfileFormForAdminUsers}
                    />
                    <Route
                        exact
                        path='/orgs/departments/caregivers/:id'
                        component={ProfilePageFormAdminUsers}
                    />
                   
                    <Route
                        exact
                        path='/orgs/departments/edit/:id'
                        component={DepartmentForm}
                    />
                    <Route
                        exact
                        path='/orgs/departments/:id'
                        component={DepartmentProfile}
                    />
    
                    <Route
                        component={() => (
                            <ErrorPage
                                code={403}
                                message='You do not have access to view this page.'
                            />
                        )}
                    />
                </Switch>
            );
        }else{
            return <></>
        }
        
    } else if (
        getIsTeamList() === 'true' &&
        getAdmin() &&
        getAdmin().isDeptAdmin
    ) {
        if(selectedDept){
            return (
                <Switch>
                    <Route exact path='/' component={() => <Redirect to='/newUpdates'/>}/>
                    {/* users routes  */}
                    <Route exact path='/profile' component={ProfilePageForUsers}/>
                    <Route exact path='/profile/edit' component={EditProfileFormForUsers}/>
    
                    <Route exact path='/newUpdates' component={NewUpdates}/>
                    <Route exact path='/playStatusTeamRoster' component={PlayerStatus}/>
                    <Route exact path='/cases/addCase' component={AddCase}/>
                    <Route exact path='/cases' component={Cases}/>
                    <Route exact path='/cases/:id' component={Case}/>
                    <Route exact path='/athletes/:id' component={Athlete}/>
                    <Route exact path='/athletes' component={Athletes}/>
                    <Route
                        exact
                        path='/analyticsDashboard'
                        component={AnalyticsDashboard}
                    />
    
                    {/* dept admin routes  */}
                    <Route exact path='/departments' component={AdminDepts}/>
                    <Route exact path='/departments/add' component={() => (
                            <ErrorPage
                                code={403}
                                message='You do not have access to view this page.'
                            />
                    )}/>
                    <Route exact path='/users' component={Users}/>
                    <Route exact path='/users/:id' component={ProfilePageFormAdminUsers}/>
                    <Route
                        exact
                        path='/users/edit/:id'
                        component={EditProfileFormForAdminUsers}
                    />
                    <Route exact path='/dataUtilities' component={DataUtilities}/>
    
                    {/* nested routes for depts */}
                    <Route exact path='/departments/edit/:id' component={DepartmentForm}/>
                    <Route exact path='/departments/addPatient' component={PatientForm}/>
                    <Route
                        exact
                        path='/departments/addCaregiver'
                        component={CaregiverForm}
                    />
                    <Route
                        exact
                        path='/departments/patients/edit/:id'
                        component={PatientForm}
                    />
                    <Route
                        exact
                        path='/departments/patients/:id'
                        component={PatientProfile}
                    />
                    <Route
                        exact
                        path='/departments/caregivers/edit/:id'
                        component={EditProfileFormForAdminUsers}
                    />
                    <Route
                        exact
                        path='/departments/caregivers/:id'
                        component={ProfilePageFormAdminUsers}
                    />
                    <Route exact path='/departments/:id' component={DepartmentProfile}/>
    
                    <Route
                        component={() => (
                            <ErrorPage
                                code={403}
                                message='You do not have access to view this page.'
                            />
                        )}
                    />
                </Switch>
            );
        }else{
            return <></>
        }
        
    } else if (
        getIsTeamList() === 'true' &&
        getAdmin() &&
        getAdmin().isBothAdmin
    ) {
        if(selectedDept){
            return (
                <Switch>
                    <Route exact path='/' component={() => <Redirect to='/newUpdates'/>}/>
                    {/* users routes  */}
                    <Route exact path='/profile' component={ProfilePageForUsers}/>
                    <Route exact path='/profile/edit' component={EditProfileFormForUsers}/>
    
                    <Route exact path='/newUpdates' component={NewUpdates}/>
                    <Route exact path='/playStatusTeamRoster' component={PlayerStatus}/>
                    <Route exact path='/cases/addCase' component={AddCase}/>
                    <Route exact path='/cases' component={Cases}/>
                    <Route exact path='/cases/:id' component={Case}/>
                    <Route exact path='/athletes/:id' component={Athlete}/>
                    <Route exact path='/athletes' component={Athletes}/>
                    <Route
                        exact
                        path='/analyticsDashboard'
                        component={AnalyticsDashboard}
                    />
    
                    {/* both admins routes  */}
                    <Route exact path='/orgs' component={Organizations}/>
                    <Route exact path='/departments' component={AdminDepts}/>
                    <Route exact path='/users' component={Users}/>
                    <Route exact path='/users/:id' component={ProfilePageFormAdminUsers}/>
                    <Route
                        exact
                        path='/users/edit/:id'
                        component={EditProfileFormForAdminUsers}
                    />
                    <Route exact path='/dataUtilities' component={DataUtilities}/>
    
                    {/* nested access routes  */}
                    <Route exact path='/orgs/addDepartment' component={DepartmentForm}/>
                    <Route exact path='/orgs/addCaregiver' component={CaregiverForm}/>
                    <Route exact path='/orgs/edit/:id' component={AddOrganization}/>
                    <Route exact path='/orgs/:id' component={OrganisationProfile}/>
    
                    {/* orgs caregivers routes  */}
                    <Route
                        exact
                        path='/orgs/caregivers/edit/:id'
                        component={EditProfileFormForAdminUsers}
                    />
                    <Route
                        exact
                        path='/orgs/caregivers/:id'
                        component={ProfilePageFormAdminUsers}
                    />
    
                    {/* orgs depts routes  */}
                    <Route
                        exact
                        path='/orgs/departments/addPatient'
                        component={PatientForm}
                    />
                    <Route
                        exact
                        path='/orgs/departments/addCaregiver'
                        component={CaregiverForm}
                    />
                    <Route
                        exact
                        path='/orgs/departments/addAdmin'
                        component={AdminFormForDeptAdmin}
                    />
                    <Route
                        exact
                        path='/orgs/departments/patients/edit/:id'
                        component={PatientForm}
                    />
                    <Route
                        exact
                        path='/orgs/departments/patients/:id'
                        component={PatientProfile}
                    />
    
                    <Route
                        exact
                        path='/orgs/departments/caregivers/edit/:id'
                        component={EditProfileFormForAdminUsers}
                    />
                    <Route
                        exact
                        path='/orgs/departments/caregivers/:id'
                        component={ProfilePageFormAdminUsers}
                    />
                    <Route
                        exact
                        path='/orgs/departments/edit/:id'
                        component={DepartmentForm}
                    />
                    <Route
                        exact
                        path='/orgs/departments/:id'
                        component={DepartmentProfile}
                    />
    
                    {/* nested routes for depts */}
                    <Route exact path='/departments/edit/:id' component={DepartmentForm}/>
                    <Route exact path='/departments/addPatient' component={PatientForm}/>
                    <Route
                        exact
                        path='/departments/addCaregiver'
                        component={CaregiverForm}
                    />
                    <Route
                        exact
                        path='/departments/patients/edit/:id'
                        component={PatientForm}
                    />
                    <Route
                        exact
                        path='/departments/patients/:id'
                        component={PatientProfile}
                    />
                    <Route
                        exact
                        path='/departments/caregivers/edit/:id'
                        component={EditProfileFormForAdminUsers}
                    />
                    <Route
                        exact
                        path='/departments/caregivers/:id'
                        component={ProfilePageFormAdminUsers}
                    />
                    <Route exact path='/departments/:id' component={DepartmentProfile}/>
    
                    <Route
                        component={() => (
                            <ErrorPage
                                code={403}
                                message='You do not have access to view this page.'
                            />
                        )}
                    />
                </Switch>
            );
        }else{
            return <></>
        }
        
    } else if (
        getIsTeamList() === 'false' &&
        getAdmin() &&
        getAdmin().isOrgAdmin
    ) {
        return (
            <Switch>
                <Route exact path='/' component={() => <Redirect to='/orgs'/>}/>
                {/* org admin routes  */}
                <Route exact path='/profile' component={ProfilePageForUsers}/>
                <Route exact path='/profile/edit' component={EditProfileFormForUsers}/>

                <Route exact path='/orgs' component={Organizations}/>
                <Route exact path='/orgs/add' component={() => (
                        <ErrorPage
                            code={403}
                            message='You do not have access to view this page.'
                        />
                )}/>
                <Route exact path='/users' component={Users}/>
                <Route exact path='/users/:id' component={ProfilePageFormAdminUsers}/>
                <Route
                    exact
                    path='/users/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route exact path='/dataUtilities' component={DataUtilities}/>

                {/* nested access routes  */}
                <Route exact path='/orgs/addDepartment' component={DepartmentForm}/>
                <Route exact path='/orgs/addCaregiver' component={CaregiverForm}/>
                <Route exact path='/orgs/edit/:id' component={AddOrganization}/>
                <Route exact path='/orgs/:id' component={OrganisationProfile}/>

                {/* orgs caregivers routes  */}
                <Route
                    exact
                    path='/orgs/caregivers/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/orgs/caregivers/:id'
                    component={ProfilePageFormAdminUsers}
                />
                {/* orgs depts routes  */}
                <Route
                    exact
                    path='/orgs/departments/addPatient'
                    component={PatientForm}
                />
                <Route
                    exact
                    path='/orgs/departments/addCaregiver'
                    component={CaregiverForm}
                />
                <Route
                    exact
                    path='/orgs/departments/addAdmin'
                    component={AdminFormForDeptAdmin}
                />
                <Route
                    exact
                    path='/orgs/departments/patients/edit/:id'
                    component={PatientForm}
                />
                <Route
                    exact
                    path='/orgs/departments/patients/:id'
                    component={PatientProfile}
                />

                <Route
                    exact
                    path='/orgs/departments/caregivers/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/orgs/departments/caregivers/:id'
                    component={ProfilePageFormAdminUsers}
                />
                <Route
                    exact
                    path='/orgs/departments/edit/:id'
                    component={DepartmentForm}
                />
                <Route
                    exact
                    path='/orgs/departments/:id'
                    component={DepartmentProfile}
                />

                <Route
                    component={() => (
                        <ErrorPage
                            code={403}
                            message='You do not have access to view this page.'
                        />
                    )}
                />
            </Switch>
        );
    } else if (
        getIsTeamList() === 'false' &&
        getAdmin() &&
        getAdmin().isDeptAdmin
    ) {
        return (
            <Switch>
                <Route
                    exact
                    path='/'
                    component={() => <Redirect to='/departments'/>}
                />
                {/* dept admin routes  */}
                <Route exact path='/profile' component={ProfilePageForUsers}/>
                <Route exact path='/profile/edit' component={EditProfileFormForUsers}/>

                <Route exact path='/departments' component={AdminDepts}/>
                {/* nested routes for depts */}
                <Route exact path='/departments/add' component={() => (
                            <ErrorPage
                                code={403}
                                message='You do not have access to view this page.'
                            />
                )}/>
                <Route exact path='/departments/edit/:id' component={DepartmentForm}/>
                <Route exact path='/departments/addPatient' component={PatientForm}/>
                <Route
                    exact
                    path='/departments/addCaregiver'
                    component={CaregiverForm}
                />
                <Route
                    exact
                    path='/departments/patients/edit/:id'
                    component={PatientForm}
                />
                <Route
                    exact
                    path='/departments/patients/:id'
                    component={PatientProfile}
                />
                <Route
                    exact
                    path='/departments/caregivers/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/departments/caregivers/:id'
                    component={ProfilePageFormAdminUsers}
                />
                <Route exact path='/departments/:id' component={DepartmentProfile}/>

                <Route exact path='/users' component={Users}/>
                <Route exact path='/users/:id' component={ProfilePageFormAdminUsers}/>
                <Route
                    exact
                    path='/users/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route exact path='/dataUtilities' component={DataUtilities}/>

                <Route
                    component={() => (
                        <ErrorPage
                            code={403}
                            message='You do not have access to view this page.'
                        />
                    )}
                />
            </Switch>
        );
    } else if (
        getIsTeamList() === 'false' &&
        getAdmin() &&
        getAdmin().isBothAdmin
    ) {
        return (
            <Switch>
                <Route exact path='/' component={() => <Redirect to='/orgs'/>}/>
                {/* both admin routes  */}
                <Route exact path='/profile' component={ProfilePageForUsers}/>
                <Route exact path='/profile/edit' component={EditProfileFormForUsers}/>

                <Route exact path='/orgs' component={Organizations}/>
                <Route exact path='/departments' component={AdminDepts}/>
                <Route exact path='/users' component={Users}/>
                <Route exact path='/dataUtilities' component={DataUtilities}/>

                {/* nested access routes  */}
                <Route exact path='/orgs/addDepartment' component={DepartmentForm}/>
                <Route exact path='/orgs/addCaregiver' component={CaregiverForm}/>
                <Route exact path='/orgs/edit/:id' component={AddOrganization}/>
                <Route exact path='/orgs/:id' component={OrganisationProfile}/>

                {/* orgs caregivers routes  */}
                <Route
                    exact
                    path='/orgs/caregivers/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/orgs/caregivers/:id'
                    component={ProfilePageFormAdminUsers}
                />

                {/* orgs depts routes  */}
                <Route
                    exact
                    path='/orgs/departments/addPatient'
                    component={PatientForm}
                />
                <Route
                    exact
                    path='/orgs/departments/addCaregiver'
                    component={CaregiverForm}
                />
                <Route
                    exact
                    path='/orgs/departments/addAdmin'
                    component={AdminFormForDeptAdmin}
                />
                <Route
                    exact
                    path='/orgs/departments/patients/edit/:id'
                    component={PatientForm}
                />
                <Route
                    exact
                    path='/orgs/departments/patients/:id'
                    component={PatientProfile}
                />

                <Route
                    exact
                    path='/orgs/departments/caregivers/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/orgs/departments/caregivers/:id'
                    component={ProfilePageFormAdminUsers}
                />
                <Route
                    exact
                    path='/orgs/departments/edit/:id'
                    component={DepartmentForm}
                />
                <Route
                    exact
                    path='/orgs/departments/:id'
                    component={DepartmentProfile}
                />

                {/* nested routes for depts */}
                <Route exact path='/departments/edit/:id' component={DepartmentForm}/>
                <Route exact path='/departments/addPatient' component={PatientForm}/>
                <Route
                    exact
                    path='/departments/addCaregiver'
                    component={CaregiverForm}
                />
                <Route
                    exact
                    path='/departments/patients/edit/:id'
                    component={PatientForm}
                />
                <Route
                    exact
                    path='/departments/patients/:id'
                    component={PatientProfile}
                />
                <Route
                    exact
                    path='/departments/caregivers/edit/:id'
                    component={EditProfileFormForAdminUsers}
                />
                <Route
                    exact
                    path='/departments/caregivers/:id'
                    component={ProfilePageFormAdminUsers}
                />
                <Route exact path='/departments/:id' component={DepartmentProfile}/>

                <Route
                    component={() => (
                        <ErrorPage
                            code={403}
                            message='You do not have access to view this page.'
                        />
                    )}
                />
            </Switch>
        );
    } else if (role === ROLES.COACH) {
        if(selectedDept){
            return (
                <Switch>
                    <Route
                        exact
                        path='/'
                        component={() => <Redirect to='/playStatusTeamRoster'/>}
                    />
                    <Route exact path='/profile' component={ProfilePageForUsers}/>
                    <Route exact path='/profile/edit' component={EditProfileFormForUsers}/>
                    <Route exact path='/playStatusTeamRoster' component={PlayerStatus}/>
                    <Route
                        component={() => (
                            <ErrorPage
                                code={403}
                                message='You do not have access to view this page.'
                            />
                        )}
                    />
                </Switch>
            );
        }else{
            return <></>
        }
        
    }else if (role === ROLES.LIMITED_USER) {
        if(selectedDept){
            return (
                <Switch>
                    <Route exact path='/' component={() => <Redirect to='/newUpdates'/>}/>
                    <Route exact path='/profile' component={ProfilePageForUsers}/>
                    <Route exact path='/profile/edit' component={EditProfileFormForUsers}/>
                    
                    <Route exact path='/newUpdates' component={NewUpdates}/>
                    <Route exact path='/cases/addCase' component={() => (
                            <ErrorPage
                                code={403}
                                message='You do not have access to view this page.'
                            />
                        )}/>
                    <Route exact path='/cases' component={Cases}/>
                    <Route exact path='/cases/:id' component={Case}/>
                    <Route exact path='/athletes/:id' component={Athlete}/>
                    <Route exact path='/athletes' component={Athletes}/>
                    <Route
                        exact
                        path='/analyticsDashboard'
                        component={AnalyticsDashboard}
                    />
    
                    <Route
                        component={() => (
                            <ErrorPage
                                code={403}
                                message='You do not have access to view this page.'
                            />
                        )}
                    />
                </Switch>
            );
        }else{
            return <></>
        }
        
    } else {
        if(selectedDept){
            return (
                <Switch>
                    <Route exact path='/' component={() => <Redirect to='/newUpdates'/>}/>
                    <Route exact path='/profile' component={ProfilePageForUsers}/>
                    <Route exact path='/profile/edit' component={EditProfileFormForUsers}/>
    
                    <Route exact path='/newUpdates' component={NewUpdates}/>
                    <Route exact path='/playStatusTeamRoster' component={PlayerStatus}/>
                    <Route exact path='/cases/addCase' component={AddCase}/>
                    <Route exact path='/cases' component={Cases}/>
                    <Route exact path='/cases/:id' component={Case}/>
                    <Route exact path='/athletes/:id' component={Athlete}/>
                    <Route exact path='/athletes' component={Athletes}/>
                    <Route
                        exact
                        path='/analyticsDashboard'
                        component={AnalyticsDashboard}
                    />
    
                    <Route
                        component={() => (
                            <ErrorPage
                                code={403}
                                message='You do not have access to view this page.'
                            />
                        )}
                    />
                </Switch>
            );
        }else{
            return <></>
        }   
        
    }
};

const mapStateToProps = state => {
    return {
        selectedDept: state.sirenUsers.selectedDept,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardRoutes);
