import React, {useEffect, useState} from 'react';
import {useStyles} from './styles';
import {Link, NavLink, useParams, useRouteMatch} from 'react-router-dom';
import {connect} from 'react-redux';
import MaterialTable from 'material-table';
import AdminDialogForm from '../SirenmdAdminForm/SirenmdAdminForm';
import {PAGE_SIZE, PAGE_SIZE_OPTIONS} from '../../../../utilities/constants';
import ConfirmationDialog from '../../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import ChangePasswordDialog from '../../../Auth/components/ChangePasswordDialog/ChangePasswordDialog';
import {getIsTeamList, getUserInfo} from '../../../../utilities/storage';
import defaultProfile from '../../../../assets/group.png';
import defaultUser from '../../../../assets/user.png';
import ImageLoader from 'react-load-image';
import Preloader from '../../../../utilities/components/Preloader/Preloader';
import {Box, Breadcrumbs, Button, Icon, Paper, Tooltip, Typography,} from '@material-ui/core';
import {
    getAssignedOrgsRequest,
    getUserByIdRequest,
    resendInvitationRequest,
    toggleActivationRequest,
} from '../../UsersApiActions';
import {ROLES} from '../../../../utilities/constants';

/**
 * function to get message for activation dialog
 * @param user: user details
 * @param classes: classes from css
 * @returns {JSX.Element}
 * @constructor
 */
const ToggleActivationDialogMessage = ({user, classes}) => {
    return (
        <>
            {user && (
                <Box>
                    Are you sure you want to {(!user.enabled || user.isBanned) ? 'unblock' : 'block'}{' '}
                    <span className={classes.dialogMessageLabel}>
                        {user.firstName} {user.lastName}
                    </span>
                    ?
                </Box>
            )}
        </>
    );
};

const ChangeActivationButton = (enabled, isBanned, setOpenToggleActivationConfirmationDialog, classes) => {
    if(isBanned || !enabled){
        return(
            <Button
                variant='contained'
                color='primary'
                disableElevation={true}
                startIcon={
                        <Icon>check_circle_outline</Icon>
                }
                onClick={() =>
                    setOpenToggleActivationConfirmationDialog(true)
                }
                classes={{
                    root: `${classes.userProfileButton} ${classes.activeButton}`,
                }}
            >
                Unblock User
            </Button>
        )
    }else{
        return(
            <Button
                variant='contained'
                color='primary'
                disableElevation={true}
                startIcon={
                    <Icon>block</Icon>
                }
                onClick={() =>
                    setOpenToggleActivationConfirmationDialog(true)
                }
                classes={{
                    root: `${classes.userProfileButton} ${classes.blockButton}`,
                }}
            >
                Block User
            </Button>
        )
    }
    // return(
    //     <Box>
    //         <Button
    //             variant='contained'
    //             color='primary'
    //             disableElevation={true}
    //             startIcon={
    //                 enabled === false ? (
    //                     <Icon>check_circle_outline</Icon>
    //                 ) : (
    //                     <Icon>block</Icon>
    //                 )
    //             }
    //             onClick={() =>
    //                 setOpenToggleActivationConfirmationDialog(true)
    //             }
    //             classes={{
    //                 root: `${classes.userProfileButton} ${
    //                     enabled
    //                         ? classes.blockButton
    //                         : classes.activeButton
    //                 }`,
    //             }}
    //         >
    //             {enabled === false ? 'Unblock User' : 'Block User'}
    //         </Button>
    //     </Box>
    // )
}

/**
 * function to render user profile for admin user
 * @param location: location from router 
 * @param history: history from router
 * @param getUserById: function to get user by id
 * @param user: user details
 * @param toggleActivation: fuction to toggle activation 
 * @param resendInvitation: function to resend invitaion
 * @param getAssignedOrgs: function to get assigned organizations 
 * @param orgs: organizations list
 * @returns {JSX.Element}
 * @constructor
 */
const AdminUsersProfile = ({
                               location,
                               history,
                               getUserById,
                               user,
                               toggleActivation,
                               resendInvitation,
                               getAssignedOrgs,
                               orgs,
                           }) => {
    const [
        openToggleActivationConfirmationDialog,
        setOpenToggleActivationConfirmationDialog,
    ] = useState(false);
    const [
        openResetPasswordConfirmationDialog,
        setOpenResetPasswordConfirmationDialog,
    ] = useState(false);
    const [openUserFormDialog, setOpenUserFormDialog] = useState(false);
    const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(
        false
    );
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const params = useParams();
    const match = useRouteMatch();
    const currentUrlPath = match.path.split(':')[0];

    useEffect(() => {
        if (params.id) {
            getAssignedOrgs(params.id).then(res => {
            });
        } else {
            getAssignedOrgs(getUserInfo().id).then(res => {
            });
        }
    }, [params.id, getAssignedOrgs]);

    useEffect(() => {
        if (params.id) {
            getUserById(params.id).then(res => {
            });
        } else {
            getUserById(getUserInfo().id).then(res => {
            });
        }
    }, [params.id, getUserById]);

    /**
     * function to toggle user activation
     */
    const onToggleActivation = () => {
        let activationValue;
        if(user.isBanned || !user.enabled){
            activationValue = true;
        }else{
            activationValue = false;
        }
        const data = {
            note: 'note',
            enabled:  activationValue,
        };

        toggleActivation(params.id ? params.id : getUserInfo().id, data).then(
            res => {
                setOpenToggleActivationConfirmationDialog(false);
            }
        );
    };

    /**
     * function to handle reset password button click
     */
    const onResetPassword = () => {
        const data = {
            userId: params.id,
        };

        resendInvitation(data).then(res => {
            setOpenResetPasswordConfirmationDialog(false);
        });
    };

    const breadCrumbDataForProfilePage = [
        {
            icon: 'person',
            name: `${
                getUserInfo()
                    ? `${getUserInfo().firstName} ${getUserInfo().lastName}`
                    : ''
            }`,
            path: '/profile',
        },
    ];

    const breadCrumbDataForUsersProfilePage = [
        {
            icon: 'group',
            name: 'Users',
            path: '/users',
        },
        {
            name: `${user ? `${user.firstName} ${user.lastName}` : ''}`,
            path: `/users/${params.id}`,
        },
    ];

    const breadCrumbDataForOrgCaregiverProfilePage = [
        {
            icon: 'business',
            name: 'Organizations',
            path: '/orgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/orgs/${location.state && location.state.orgIdHistory}`,
        },
        {
            name: `${user ? `${user.firstName} ${user.lastName}` : ''}`,
            path: `/orgs/caregivers/${params.id}`,
        },
    ];

    const breadCrumbDataForOrgDeptCaregiverProfilePage = [
        {
            icon: 'business',
            name: 'Organizations',
            path: '/orgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/orgs/${location.state && location.state.orgIdHistory}`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/orgs/departments/${
                location.state && location.state.deptIdHistory
            }`,
        },
        {
            name: `${user ? `${user.firstName} ${user.lastName}` : ''}`,
            path: `/orgs/departments/caregivers/${params.id}`,
        },
    ];

    const breadCrumbDataForOrgAdminProfilePage = [
        {
            icon: 'business',
            name: 'Organizations',
            path: '/orgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/orgs/${location.state && location.state.orgIdHistory}`,
        },
        {
            name: `${user ? `${user.firstName} ${user.lastName}` : ''}`,
            path: `/orgs/admins/${params.id}`,
        },
    ];

    const breadCrumbDataForOrgDeptAdminProfilePage = [
        {
            icon: 'business',
            name: 'Organizations',
            path: '/orgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/orgs/${location.state && location.state.orgIdHistory}`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/orgs/departments/${
                location.state && location.state.deptIdHistory
            }`,
        },
        {
            name: `${user ? `${user.firstName} ${user.lastName}` : ''}`,
            path: `/orgs/departments/admins/${params.id}`,
        },
    ];

    const breadCrumbDataForHcorgCaregiverProfilePage = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: '/healthcareOrgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${user ? `${user.firstName} ${user.lastName}` : ''}`,
            path: `/healthcareOrgs/caregivers/${params.id}`,
        },
    ];

    const breadCrumbDataForHcorgOrgCaregiverProfilePage = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: '/healthcareOrgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/${
                location.state && location.state.orgIdHistory
            }`,
        },
        {
            name: `${user ? `${user.firstName} ${user.lastName}` : ''}`,
            path: `/healthcareOrgs/orgs/caregivers/${params.id}`,
        },
    ];

    const breadCrumbDataForHcorgOrgAdminProfilePage = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: '/healthcareOrgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/${
                location.state && location.state.orgIdHistory
            }`,
        },
        {
            name: `${user ? `${user.firstName} ${user.lastName}` : ''}`,
            path: `/healthcareOrgs/orgs/admins/${params.id}`,
        },
    ];

    const breadCrumbDataForHcorgOrgDeptCaregiverProfilePage = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: '/healthcareOrgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/${
                location.state && location.state.orgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/departments/${
                location.state && location.state.deptIdHistory
            }`,
        },
        {
            name: `${user ? `${user.firstName} ${user.lastName}` : ''}`,
            path: `/healthcareOrgs/orgs/departments/caregivers/${params.id}`,
        },
    ];

    const breadCrumbDataForHcorgOrgDeptAdminProfilePage = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: '/healthcareOrgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/${
                location.state && location.state.orgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/departments/${
                location.state && location.state.deptIdHistory
            }`,
        },
        {
            name: `${user ? `${user.firstName} ${user.lastName}` : ''}`,
            path: `/healthcareOrgs/orgs/departments/admins/${params.id}`,
        },
    ];

    const breadCrumbDataForAdminDeptCaregiverProfilePage = [
        {
            icon: 'apartment',
            name: 'Departments',
            path: '/departments',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/departments/${location.state && location.state.deptIdHistory}`,
        },
        {
            name: `${user ? `${user.firstName} ${user.lastName}` : ''}`,
            path: `/departments/caregivers/${params.id}`,
        },
    ];

    /**
     * function to get breadcrumb data for current url
     * @returns {[{path: string, icon: string, name: string}, {path: string, name: string}]|*[]|[{path: string, icon: string, name: string}, {path: string, name: string}, {path: string, name: string}]|[{path: string, icon: string, name: string}, {path: string, name: string}, {path: string, name: string}, {path: string, name: string}]|[{path: string, icon: string, name: string}, {path: string, name: string}, {path: string, name: string}, {path: string, name: string}, {path: string, name: string}]|[{path: string, icon: string, name: string}]}
     */
    const getBreadcrumbData = (urlPath) => {
        switch(urlPath){
            case '/users/':{
                return breadCrumbDataForUsersProfilePage
            }
            case '/orgs/caregivers/':{
               return breadCrumbDataForOrgCaregiverProfilePage
            }
            case '/orgs/admins/':{
                return breadCrumbDataForOrgAdminProfilePage
            }
            case '/orgs/departments/caregivers/':{
               return breadCrumbDataForOrgDeptCaregiverProfilePage
            }
            case '/orgs/departments/admins/':{
                return breadCrumbDataForOrgDeptAdminProfilePage
            }
            case '/healthcareOrgs/caregivers/':{
               return breadCrumbDataForHcorgCaregiverProfilePage
            }
            case '/healthcareOrgs/orgs/caregivers/':{
                return breadCrumbDataForHcorgOrgCaregiverProfilePage
            }
            case '/healthcareOrgs/orgs/admins/':{
               return breadCrumbDataForHcorgOrgAdminProfilePage
            }
            case '/healthcareOrgs/orgs/departments/caregivers/':{
                return breadCrumbDataForHcorgOrgDeptCaregiverProfilePage
            }
            case '/healthcareOrgs/orgs/departments/admins/':{
               return breadCrumbDataForHcorgOrgDeptAdminProfilePage
            }
            case '/departments/caregivers/':{
                return breadCrumbDataForAdminDeptCaregiverProfilePage
            }
            case '/profile':{
               return breadCrumbDataForProfilePage
            }
            default: {
                return []
            }
        }
    }

    const columns = [
        {
            field: 'name',
            title: 'Name',
            cellStyle: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            },
            render: ({name, imageUrl, id}) => {
                return (
                    <Box
                        className={classes.profileContainer}
                        component={Link}
                        to={`/orgs/${id}`}
                    >
                        <img
                            src={imageUrl ? imageUrl : defaultProfile}
                            alt='profile'
                            className={classes.profileImage}
                        />
                        <Box className={classes.profileNameContainer}>
                            <Typography className={classes.profileName}>{name}</Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'desc',
            title: 'Description',
            filtering: false,
            sorting: false,
            cellStyle: {
                width: 'auto',
            },
            headerStyle: {
                width: 'auto',
            },
        },
        {
            field: 'active',
            title: 'Active',
            filtering: false,
            sorting: false,
            cellStyle: {
                textAlign: 'center',
                width: '10%',
            },
            headerStyle: {
                textAlign: 'center',
                width: '10%',
            },

            render: ({active}) => {
                return (
                    <>
                        {active ? (
                            <Tooltip arrow placement='top' title='Active'>
                                <Icon className={`statusActive`}>check_circle_outline</Icon>
                            </Tooltip>
                        ) : (
                            <Tooltip placement='top' title='Blocked'>
                                <Icon className={`statusBlocked`}>block</Icon>
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <Box
            className={
                role === ROLES.SIRENMD_ADMIN &&
                getIsTeamList() === 'false'
                    ? ''
                    : classes.outerContainer
            }
        >
            <Box
                className={
                    role === ROLES.SIRENMD_ADMIN &&
                    getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainer
                        : getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainerForAdmins
                        : classes.breadcrumbsContainerForUsers
                }
                component={Paper}
                p={1}
            >
                <Breadcrumbs>
                    {getBreadcrumbData(currentUrlPath).map((crumb, index) => (
                        <Box
                            key={index}
                            component={NavLink}
                            exact
                            to={{
                                pathname: crumb.path,
                                state: {
                                    orgIdHistory: location.state && location.state.orgIdHistory,
                                    orgNameHistory:
                                        location.state && location.state.orgNameHistory,
                                    deptIdHistory: location.state && location.state.deptIdHistory,
                                    deptNameHistory:
                                        location.state && location.state.deptNameHistory,
                                    hcorgIdHistory:
                                        location.state && location.state.hcorgIdHistory,
                                    hcorgNameHistory:
                                        location.state && location.state.hcorgNameHistory,
                                },
                            }}
                            className={classes.breadcrumbItem}
                            activeClassName={classes.activeBreadcrumbItem}
                        >
                            {crumb.icon && (
                                <Icon className={classes.breadcrumpIcon}>{crumb.icon}</Icon>
                            )}
                            <Box className={classes.breadcrumpLabel}>{crumb.name}</Box>
                        </Box>
                    ))}
                </Breadcrumbs>
            </Box>

            <Box>
                <Box>
                    <AdminDialogForm
                        openUserFormDialog={openUserFormDialog}
                        setOpenUserFormDialog={setOpenUserFormDialog}
                        id={params.id ? params.id : getUserInfo().id}
                    />
                </Box>

                {user && (
                    <Box mt={3}>
                        <Box component={Paper} className={classes.userProfileContainer}>
                            <Box className={classes.userProfileContentContainer}>
                                <Box>
                                    <ImageLoader
                                        src={user.imageUrl ? user.imageUrl : defaultUser}
                                    >
                                        <img alt='profile' className={classes.userProfileImage}/>
                                        <div className={classes.userProfileImageError}>
                                            {user.id}
                                        </div>
                                        <Preloader className={classes.userProfileImage}/>
                                    </ImageLoader>
                                </Box>

                                <Box className={classes.userProfileTextContainer}>
                                    <Typography classes={{root: classes.userProfileName}}>
                                        {user.firstName} {user.lastName}
                                    </Typography>
                                    <Typography classes={{root: classes.userProfileEmail}}>
                                        {user.email}
                                    </Typography>
                                    <Typography classes={{root: classes.userProfilePhone}}>
                                        {user.phone}
                                    </Typography>

                                    <Box className={classes.userProfileRoleContainer}>
                                        <Icon className={classes.userProfileRoleIcon}>
                                            radio_button_unchecked
                                        </Icon>
                                        <Typography classes={{root: classes.userProfileRole}}>
                                            {user.Roles.length > 0 ? user.Roles[0].name : ''}
                                        </Typography>
                                    </Box>

                                    {user.Roles.length > 0 &&
                                    user.Roles[0].name === 'SirenMD Admin' ? (
                                        <Box>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                disableElevation={true}
                                                startIcon={<Icon>edit</Icon>}
                                                onClick={() => setOpenUserFormDialog(true)}
                                                classes={{root: classes.userProfileButton}}
                                            >
                                                Edit Details
                                            </Button>
                                        </Box>
                                    ) : (
                                        <Box>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                disableElevation={true}
                                                startIcon={<Icon>edit</Icon>}
                                                component={Link}
                                                to={{
                                                    pathname: `${match.path.split(':')[0]}edit/${
                                                        params.id
                                                    }`,
                                                    state: {
                                                        orgIdHistory:
                                                            location.state && location.state.orgIdHistory,
                                                        orgNameHistory:
                                                            location.state && location.state.orgNameHistory,
                                                        deptIdHistory:
                                                            location.state && location.state.deptIdHistory,
                                                        deptNameHistory:
                                                            location.state && location.state.deptNameHistory,
                                                        hcorgIdHistory:
                                                            location.state && location.state.hcorgIdHistory,
                                                        hcorgNameHistory:
                                                            location.state && location.state.hcorgNameHistory,
                                                    },
                                                }}
                                                classes={{root: classes.userProfileButton}}
                                            >
                                                Edit Details
                                            </Button>
                                        </Box>
                                    )}
                                    {Number(getUserInfo().id) !== user.id && (
                                        ChangeActivationButton(user.enabled, user.isBanned, setOpenToggleActivationConfirmationDialog, classes)
                                    )}
                                    <Box>
                                        {Number(getUserInfo().id) === user.id ? (
                                            <>
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    disableElevation={true}
                                                    startIcon={<Icon>vpn_key</Icon>}
                                                    onClick={() => setOpenChangePasswordDialog(true)}
                                                    classes={{root: classes.userProfileButton}}
                                                >
                                                    Change Password
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    disableElevation={true}
                                                    startIcon={<Icon>vpn_key</Icon>}
                                                    classes={{root: classes.userProfileButton}}
                                                    onClick={() =>
                                                        setOpenResetPasswordConfirmationDialog(true)
                                                    }
                                                >
                                                    Reset Password
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box>
                            <MaterialTable
                                title='Organizations'
                                columns={columns}
                                data={orgs.rows}
                                options={{
                                    search: false,
                                    filtering: true,
                                    sorting: false,
                                    horizontalScroll: true,
                                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                                    pageSize: PAGE_SIZE,
                                    emptyRowsWhenPaging: false,
                                }}
                                style={{marginTop: '1rem'}}
                            />
                        </Box>
                    </Box>
                )}

                {user && (
                    <Box>
                        <ConfirmationDialog
                            title={(!user.enabled || user.isBanned) ? 'Unblock User' : 'Block User'}
                            message={
                                <ToggleActivationDialogMessage user={user} classes={classes}/>
                            }
                            open={openToggleActivationConfirmationDialog}
                            setOpen={setOpenToggleActivationConfirmationDialog}
                            onConfirm={onToggleActivation}
                        />
                    </Box>
                )}

                <Box>
                    <ConfirmationDialog
                        title='Reset Password'
                        message='Are you sure this will send reset password email to the user?'
                        open={openResetPasswordConfirmationDialog}
                        setOpen={setOpenResetPasswordConfirmationDialog}
                        onConfirm={onResetPassword}
                    />
                </Box>

                <ChangePasswordDialog
                    openChangePasswordDialog={openChangePasswordDialog}
                    setOpenChangePasswordDialog={setOpenChangePasswordDialog}
                    history={history}
                />
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        loggedInUser: state.auth.loggedInUser,
        user: state.sirenUsers.user,
        orgs: state.sirenUsers.orgs,
    };
};

const mapDispatchToProp = dispatch => {
    return {
        getUserById: id => {
            return dispatch(getUserByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        toggleActivation: (id, data) => {
            return dispatch(toggleActivationRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        resendInvitation: data => {
            return dispatch(resendInvitationRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getAssignedOrgs: id => {
            return dispatch(getAssignedOrgsRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProp)(AdminUsersProfile);
