import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Box, Button, FormControlLabel, Paper, Switch, Typography,} from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import EditIcon from '@material-ui/icons/Edit';
import PasswordIcon from '@material-ui/icons/VpnKey';
import CircleIcon from '@material-ui/icons/RadioButtonUnchecked';
import {getUserByIdRequest, toggleEmailUpdateRequest,} from '../../UsersApiActions';
import ChangeTimezoneDialog from '../../../../utilities/components/TimezoneDialog/TimezoneDialog';
import ChangePasswordDialog from '../../../Auth/components/ChangePasswordDialog/ChangePasswordDialog';
import {useStyles} from './styles';
import {getUserInfo} from '../../../../utilities/storage';
import defaultUser from '../../../../assets/user.png';
import ImageLoader from 'react-load-image';
import Preloader from '../../../../utilities/components/Preloader/Preloader';

/**
 * function to render caregiver profile
 * @param history: history from router
 * @param getUserById: function to get user by id
 * @param user: user details
 * @param toggleEmailUpdate: fuction to toggle email updates setting
 * @returns {JSX.Element}
 * @constructor
 */
const UserProfile = ({history, getUserById, user, toggleEmailUpdate}) => {
    const [openTimezoneDialog, setOpenTimezoneDialog] = useState(false);
    const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(
        false
    );
    const classes = useStyles();

    useEffect(() => {
        getUserById(getUserInfo().id).then(res => {
        });
    }, [getUserById]);

    /**
     * function to handle email update setting
     */
    const handleEmailUpdates = () => {
        const data = {
            enabled: !user.allUpdates,
        };

        toggleEmailUpdate(getUserInfo().id, data).then(res => {});
    };

    return (
        <>
            {user && (
                <Box className={classes.container}>
                    <Box component={Paper} className={classes.userProfileContainer}>
                        <Box className={classes.userProfileContentContainer}>
                            <Box>
                                <ImageLoader src={user.imageUrl ? user.imageUrl : defaultUser}>
                                    <img alt={user.id} className={classes.userProfileImage}/>
                                    <div className={classes.userProfileImageError}>{user.id}</div>
                                    <Preloader className={classes.userProfileImage}/>
                                </ImageLoader>
                            </Box>

                            <Box className={classes.userProfileTextContainer}>
                                <Typography classes={{root: classes.userProfileName}}>
                                    {user.firstName} {user.lastName}
                                </Typography>
                                <Typography classes={{root: classes.userProfileEmail}}>
                                    {user.email}
                                </Typography>
                                <Typography classes={{root: classes.userProfilePhone}}>
                                    {user.phone}
                                </Typography>

                                <Box className={classes.userProfileRoleContainer}>
                                    <CircleIcon className={classes.userProfileRoleIcon}/>
                                    <Typography classes={{root: classes.userProfileRole}}>
                                        {user.Roles ? user.Roles[0].name : ''}
                                    </Typography>
                                </Box>

                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={user.allUpdates}
                                                onChange={handleEmailUpdates}
                                            />
                                        }
                                        label='Email Alert'
                                    />
                                </Box>

                                <Box>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        disableElevation={true}
                                        startIcon={<EditIcon/>}
                                        classes={{root: classes.userProfileButton}}
                                        component={Link}
                                        to={`/profile/edit`}
                                    >
                                        Edit Details
                                    </Button>
                                </Box>

                                <Box>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        disableElevation={true}
                                        startIcon={<PasswordIcon/>}
                                        onClick={() => setOpenChangePasswordDialog(true)}
                                        classes={{root: classes.userProfileButton}}
                                    >
                                        Change Password
                                    </Button>
                                </Box>

                                <Box>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        disableElevation={true}
                                        startIcon={<LanguageIcon/>}
                                        onClick={() => setOpenTimezoneDialog(true)}
                                        classes={{root: classes.userProfileButton}}
                                    >
                                        Change Timezone
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}

            <ChangeTimezoneDialog
                openTimezoneDialog={openTimezoneDialog}
                setOpenTimezoneDialog={setOpenTimezoneDialog}
            />

            <ChangePasswordDialog
                openChangePasswordDialog={openChangePasswordDialog}
                setOpenChangePasswordDialog={setOpenChangePasswordDialog}
                history={history}
            />
        </>
    );
};

const mapStateToProps = state => {
    return {
        user: state.sirenUsers.user,
    };
};

const mapDispatchToProp = dispatch => {
    return {
        getUserById: id => {
            return dispatch(getUserByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        toggleEmailUpdate: (id, data) => {
            return dispatch(toggleEmailUpdateRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProp)(UserProfile);
