import HttpRequest from '../../utilities/httpRequest';
import {hideLoader, showLoader, showSnackbar} from '../../utilities/UtilitiesActions';
import {
    createCaregiverBatch,
    createPatientBatch,
    deleteBatch,
    getCaregiverBatches,
    saveCaregiverBatch,
    savePatientBatch,
} from './DataUtilitiesActions';

/**
 * function to get caregiver batches
 * @param type: type for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getCaregiverBatchesRequest = type => {
    return dispatch => {
        dispatch(showLoader());

        const url = `dataUtility/listBatch?type=${type}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getCaregiverBatches(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to create caregiver batch
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const createCaregiverBatchRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = `dataUtility/addCaregiver-xls`;

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(createCaregiverBatch(res.data.data));
                dispatch(showSnackbar('File uploaded successfully.', 'success'));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to create patient batch
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const createPatientBatchRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = `dataUtility/addPatients-xls`;

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(createPatientBatch(res.data.data));
                dispatch(showSnackbar('File uploaded successfully.', 'success'));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to save caregiver batch
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const saveCaregiverBatchRequest = id => {
    return dispatch => {
        dispatch(showLoader());
        const url = `dataUtility/saveCaregivers/${id}`;

        return new HttpRequest(dispatch, url, 'post').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(saveCaregiverBatch(res.data.data));
                dispatch(showSnackbar('File processed successfully.', 'success'));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to save patient batch
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const savePatientBatchRequest = id => {
    return dispatch => {
        dispatch(showLoader());
        const url = `dataUtility/savePatients/${id}`;

        return new HttpRequest(dispatch, url, 'post').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(savePatientBatch(res.data.data));
                dispatch(showSnackbar('File processed successfully.', 'success'));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to delete batch
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const deleteBatchRequest = id => {
    return dispatch => {
        dispatch(showLoader());
        const url = `dataUtility/batch/${id}`;

        return new HttpRequest(dispatch, url, 'delete').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(deleteBatch(res.data.data));
                dispatch(showSnackbar('File removed successfully.', 'success'));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to download sample caregiver batch
 * @returns {function(*=): Promise<unknown>}
 */
export const downloadSampleCaregiverBatchRequest = () => {
    return dispatch => {
        dispatch(showLoader());
        const url = `dataUtility/uploadBatch/caregivers`;

        return new HttpRequest(dispatch, url, 'get').download().then(
            res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = 'caregivers.xlsx';
                document.body.appendChild(a);
                a.click();
                a.remove();
                dispatch(showSnackbar('Sample patients batch downloaded.', 'success'));
                dispatch(hideLoader());
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to download sample patient batch
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const downloadSamplePatientBatchRequest = () => {
    return dispatch => {
        dispatch(showLoader());
        const url = `dataUtility/uploadBatch/patients`;

        return new HttpRequest(dispatch, url, 'get').download().then(
            res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = 'patients.xlsx';
                document.body.appendChild(a);
                a.click();
                a.remove();
                dispatch(showSnackbar('Sample patients batch downloaded.', 'success'));
                dispatch(hideLoader());
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to download sample patient batch
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const downloadBatchStatusRequest = (id, type) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `dataUtility/batchSatus/${id}`;

        return new HttpRequest(dispatch, url, 'get').download().then(
            res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = `SirenMD_${type}_BATCH=${id}.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                dispatch(showSnackbar('Batch downloaded successfully', 'success'));
                dispatch(hideLoader());
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to download sample patient batch
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const updoCaregiverLastBatchRequest = (id) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `dataUtility/undoCaregivers/${id}`;

        return new HttpRequest(dispatch, url, 'get').download().then(
            res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = `SirenMD_Caregiver_Undo_BATCH=${id}.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                dispatch(showSnackbar('Undo successful and batch downloaded.', 'success'));
                dispatch(hideLoader());
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to download sample patient batch
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const updoPatientLastBatchRequest = (id) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `dataUtility/undoPatients/${id}`;

        return new HttpRequest(dispatch, url, 'get').download().then(
            res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = `SirenMD_Patient_Undo_BATCH=${id}.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                dispatch(showSnackbar('Undo successful and batch downloaded.', 'success'));
                dispatch(hideLoader());
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get signed url
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getSignedUrlRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = `dataUtility/signedUrl`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};

/**
 * function to upload file on signed url
 * @param url: url for api request
 * @param file: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const uploadToS3Request = (url, file) => {
    return dispatch => {
        dispatch(showLoader());
        return new HttpRequest(dispatch, url, 'put', file)
            .s3Upload()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};
