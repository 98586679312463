import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        timezoneContainer: {
            marginTop: '0.5rem',
        },
        actionsContainer: {
            padding: '16px 20px',
        },
    };
});
