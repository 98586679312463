import HttpRequest from '../../utilities/httpRequest';
import {
    addVendor,
    getAllSystems,
    getVendorById,
    getVendors,
    getVendorsForHCOrg,
    getVendorsWithoutParams,
    getVendorTypes,
    toggleActivation,
    updateVendor,
} from './IntegrationVendorsActions';
import {hideLoader, showLoader, showSnackbar,} from '../../utilities/UtilitiesActions';

/**
 * function to get  vendors
 * @param data: data for api request
 * @param isHideLoader: isHideLoader for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getVendorsRequest = (data, isHideLoader) => {
    let offset = data.page * data.rowsPerPage;
    let limit = data.rowsPerPage;
    let searchData = data.searchData ? data.searchData : '';

    return dispatch => {
        if (!isHideLoader) {
            dispatch(showLoader());
        }

        const url = `vendors?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
            searchData
        )}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(getVendors(res.data.data));

                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.resolve(res.data.data);
            },
            error => {
                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get  vendors for healthcare organization
 * @param  {String}   //id            //id for api request
 * @param  {Object}   //data          //data for api request
 * @param  {Boolean}  //isHideLoader  //isHideLoader for api request
 * @return {Promise}  //data          //data form api response
 */
/**
 * function to get  vendors for healthcare organization
 * @param id: id for api request
 * @param data: data for api request
 * @param isHideLoader: isHideLoader for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getVendorsForHCOrgRequest = (id, data, isHideLoader) => {
    let offset = data.page * data.rowsPerPage;
    let limit = data.rowsPerPage;
    let searchData = data.searchData ? data.searchData : '';

    return dispatch => {
        if (!isHideLoader) {
            dispatch(showLoader());
        }

        const url = `allVendors/${id}?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
            searchData
        )}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(getVendorsForHCOrg(res.data.data));

                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.resolve(res.data.data);
            },
            error => {
                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get  vendors without params
 * @returns {function(*=): Promise<unknown>}
 */
export const getVendorsWithoutParamsRequest = () => {
    return dispatch => {
        dispatch(showLoader());
        const url = `vendors`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(getVendorsWithoutParams(res.data.data));
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to add vendor
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const addVendorRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = 'vendors';

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Vendor created successfully', 'success'));

                dispatch(addVendor(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(`${error.response.data.error}`, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get vendor by id
 * @param id: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getVendorByIdRequest = id => {
    return dispatch => {
        dispatch(showLoader());

        const url = `vendors/${id}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getVendorById(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to update vendor
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const updateVendorRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());

        const url = `vendors/${id}`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Vendor updated successfully', 'success'));

                dispatch(updateVendor(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(`${error.response.data.error}`, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get vendors types
 * @returns {function(*=): Promise<unknown>}
 */
export const getVendorTypesRequest = () => {
    return dispatch => {
        dispatch(showLoader());

        const url = 'vendorsType';

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getVendorTypes(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to toggle vendor activation
 * @param orgId: orgId for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const toggleActivationRequest = (orgId, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `vendors/${orgId}/activeDeactive`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(toggleActivation(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(`${error.response.data.error}`, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get systems
 * @returns {function(*=): Promise<unknown>}
 */
export const getAllSystemsRequest = () => {
    return dispatch => {
        dispatch(showLoader());
        const url = `system`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(getAllSystems(res.data.data));
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get signed url
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getSignedUrlRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = `vendors/profilePic/signedURL`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};

/**
 * function to upload file on signed url
 * @param url: url for api request
 * @param file data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const uploadToS3Request = (url, file) => {
    return dispatch => {
        dispatch(showLoader());
        return new HttpRequest(dispatch, url, 'put', file)
            .s3Upload()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};
