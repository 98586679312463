import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    Tab,
    TextField,
    Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {getUserInfo} from '../../../../utilities/storage';
import {
    getAilingAthletesRequest,
    getChronicAthletesRequest,
    getHealthyAthletesRequest,
    getInjuredAthletesRequest,
    getRecoveringAthletesRequest,
    sendEnjuryReportRequest,
} from '../../AthletesApiActions';
import AthleteItem from './components/AthleteItem/AthleteItem';
import {ROLES, ORGANIZATION_TYPES, filtersList} from '../../../../utilities/constants'
import {useStyles} from './styles';

/**
 * function to render athletes
 * @param selectedDept: selected department
 * @param getInjuredAthletes: function to get injured athletes
 * @param injuredAthletes: injured athletes list
 * @param getRecoveringAthletes: function to get recovering athletes
 * @param recoveringAthletes: recovering athletes list
 * @param getHealthyAthletes: function to get healthy athletes
 * @param healthyAthletes: healthy athletes list
 * @param getAilingAthletes: function to get ailing athletes
 * @param ailingAthletes: ailing athletes list
 * @param getChronicAthletes: function to get chronic athletes
 * @param chronicAthletes: chronic athletes list
 * @param sendEnjuryReport: function to send enjury report
 * @returns {JSX.Element}
 * @constructor
 */
const Athletes = ({
                      selectedDept,
                      getInjuredAthletes,
                      injuredAthletes,
                      getRecoveringAthletes,
                      recoveringAthletes,
                      getHealthyAthletes,
                      healthyAthletes,
                      getAilingAthletes,
                      ailingAthletes,
                      getChronicAthletes,
                      chronicAthletes,
                      sendEnjuryReport,
                  }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;

    const [value, setValue] = useState('1');
    const [selectedSortBy, setSelectedSortBy] = useState(filtersList[0]);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        getRecoveringAthletes(
            selectedDept.team_id,
            selectedSortBy.value
        ).then(() => {
        });

        if (selectedDept && selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
            getHealthyAthletes(
                selectedDept.team_id,
                selectedSortBy.value
            ).then(() => {
            });
            getInjuredAthletes(
                selectedDept.team_id,
                selectedSortBy.value
            ).then(() => {
            });
        }

        if (selectedDept && selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
            getAilingAthletes(
                selectedDept.team_id,
                selectedSortBy.value
            ).then(() => {
            });
            getChronicAthletes(
                selectedDept.team_id,
                selectedSortBy.value
            ).then(() => {
            });
        }
        //eslint-disable-next-line
    }, [
        getInjuredAthletes,
        getRecoveringAthletes,
        getHealthyAthletes,
        getAilingAthletes,
        getChronicAthletes,
        selectedSortBy,
    ]);

    /**
     * function to handle tab change
     * @param e: event from button click
     * @param newValue: tab value
     */
    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    /**
     * function to close injury report dialog
     */
    const handleClose = () => {
        setOpenDialog(false);
    };

    /**
     * function to send injury report
     */
    const onSendEnjuryReport = () => {
        const data = {
            teamId: selectedDept.team_id,
        };

        sendEnjuryReport(data).then(() => {
            handleClose();
        });
    };

    /**
     * function to display tab lable one
     * @returns {String}
     */
    const labelForFirstTab = () => {
        if (selectedDept && selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
            return `Injured (${
                injuredAthletes && injuredAthletes.count ? injuredAthletes.count : '0'
            })`;
        }

        if (selectedDept && selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
            return `Ailing (${
                ailingAthletes && ailingAthletes.count ? ailingAthletes.count : '0'
            })`;
        }
    };

    /**
     * function to display tab lable two
     * @returns {String}
     */
    const labelForSecondTab = () => {
        if (selectedDept && selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
            return `Recovering (${
                recoveringAthletes && recoveringAthletes.count
                    ? recoveringAthletes.count
                    : '0'
            })`;
        }

        if (selectedDept && selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
            return `Chronic (${
                chronicAthletes && chronicAthletes.count ? chronicAthletes.count : '0'
            })`;
        }
    };

    /**
     * function to display tab lable three
     * @returns {String}
     */
    const labelForThirdTab = () => {
        if (selectedDept && selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
            return `Healthy (${
                healthyAthletes && healthyAthletes.count ? healthyAthletes.count : '0'
            })`;
        }

        if (selectedDept && selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
            return `Recovering (${
                recoveringAthletes && recoveringAthletes.count
                    ? recoveringAthletes.count
                    : '0'
            })`;
        }
    };

    /**
     * function to display tab panel one
     * @returns {JSX.Element}
     */
    const panelForFirstTab = () => {
        if (selectedDept && selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
            return (
                <Box className={classes.tabPanelContainer}>
                    {injuredAthletes && injuredAthletes.count === 0 ? (
                        <Box className={classes.noContentContainer}>
                            <Typography classes={{root: classes.noLabel}}>
                                No athletes available
                            </Typography>
                        </Box>
                    ) : (
                        <Grid container spacing={2}>
                            {injuredAthletes &&
                            injuredAthletes.rows &&
                            injuredAthletes.rows.map(caseItem => (
                                <AthleteItem key={caseItem.id} caseItem={caseItem}/>
                            ))}
                        </Grid>
                    )}
                </Box>
            );
        }

        if (selectedDept && selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
            return (
                <Box className={classes.tabPanelContainer}>
                    {ailingAthletes && ailingAthletes.count === 0 ? (
                        <Box className={classes.noContentContainer}>
                            <Typography classes={{root: classes.noLabel}}>
                                No patients available
                            </Typography>
                        </Box>
                    ) : (
                        <Grid container spacing={2}>
                            {ailingAthletes &&
                            ailingAthletes.rows &&
                            ailingAthletes.rows.map(caseItem => (
                                <AthleteItem key={caseItem.id} caseItem={caseItem}/>
                            ))}
                        </Grid>
                    )}
                </Box>
            );
        }
    };

    /**
     * function to display tab panel two
     * @returns {JSX.Element}
     */
    const panelForSecondTab = () => {
        if (selectedDept && selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
            return (
                <Box className={classes.tabPanelContainer}>
                    {recoveringAthletes && recoveringAthletes.count === 0 ? (
                        <Box className={classes.noContentContainer}>
                            <Typography classes={{root: classes.noLabel}}>
                                No athletes available
                            </Typography>
                        </Box>
                    ) : (
                        <Grid container spacing={2}>
                            {recoveringAthletes &&
                            recoveringAthletes.rows &&
                            recoveringAthletes.rows.map(caseItem => (
                                <AthleteItem key={caseItem.id} caseItem={caseItem}/>
                            ))}
                        </Grid>
                    )}
                </Box>
            );
        }

        if (selectedDept && selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
            return (
                <Box className={classes.tabPanelContainer}>
                    {chronicAthletes && chronicAthletes.count === 0 ? (
                        <Box className={classes.noContentContainer}>
                            <Typography classes={{root: classes.noLabel}}>
                                No patients available
                            </Typography>
                        </Box>
                    ) : (
                        <Grid container spacing={2}>
                            {chronicAthletes &&
                            chronicAthletes.rows &&
                            chronicAthletes.rows.map(caseItem => (
                                <AthleteItem key={caseItem.id} caseItem={caseItem}/>
                            ))}
                        </Grid>
                    )}
                </Box>
            );
        }
    };

    /**
     * function to display tab panel three
     * @returns {JSX.Element}
     */
    const panelForThirdTab = () => {
        if (selectedDept && selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
            return (
                <Box className={classes.tabPanelContainer}>
                    {healthyAthletes && healthyAthletes.count === 0 ? (
                        <Box className={classes.noContentContainer}>
                            <Typography classes={{root: classes.noLabel}}>
                                No athletes available
                            </Typography>
                        </Box>
                    ) : (
                        <Grid container spacing={2}>
                            {healthyAthletes &&
                            healthyAthletes.rows &&
                            healthyAthletes.rows.map(caseItem => (
                                <AthleteItem key={caseItem.id} caseItem={caseItem}/>
                            ))}
                        </Grid>
                    )}
                </Box>
            );
        }

        if (selectedDept && selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
            return (
                <Box className={classes.tabPanelContainer}>
                    {recoveringAthletes && recoveringAthletes.count === 0 ? (
                        <Box className={classes.noContentContainer}>
                            <Typography classes={{root: classes.noLabel}}>
                                No patients available
                            </Typography>
                        </Box>
                    ) : (
                        <Grid container spacing={2}>
                            {recoveringAthletes &&
                            recoveringAthletes.rows &&
                            recoveringAthletes.rows.map(caseItem => (
                                <AthleteItem key={caseItem.id} caseItem={caseItem}/>
                            ))}
                        </Grid>
                    )}
                </Box>
            );
        }
    };

    return (
        <Box style={{paddingBottom: '3rem'}}>
            <Box className={classes.athletesContainer}>
                <TabContext value={value}>
                    <Box className={classes.headerContainer}>
                        <TabList
                            component={Paper}
                            indicatorColor='primary'
                            textColor='primary'
                            value={value}
                            onChange={handleChange}
                            className={classes.tabsContainer}
                        >
                            <Tab label={labelForFirstTab()} value='1'/>
                            <Tab label={labelForSecondTab()} value='2'/>
                            <Tab label={labelForThirdTab()} value='3'/>
                        </TabList>

                        {selectedDept && selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER ? (
                            <Box className={classes.actionButtonsContainer}>
                                <Box className={classes.sortByContainer}>
                                    <Autocomplete
                                        size='small'
                                        disableClearable
                                        options={filtersList}
                                        getOptionLabel={option => option.desc}
                                        getOptionSelected={(option, value) =>
                                            option.id === value.id
                                        }
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label='Select Filter'
                                                variant='outlined'
                                            />
                                        )}
                                        value={selectedSortBy}
                                        onChange={(e, value) => setSelectedSortBy(value)}
                                    />
                                </Box>

                                {role !== ROLES.LIMITED_USER &&
                                selectedDept.OrganizationInjuryReport === true && (
                                    <Box className={classes.rendReportButtonContainer}>
                                        <Button
                                            color='primary'
                                            startIcon={<AssignmentIcon/>}
                                            onClick={() => setOpenDialog(true)}
                                            className={classes.rendReportButton}
                                            variant='outlined'
                                        >
                                            Send Injury Report
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <></>
                        )}

                        <Divider/>
                    </Box>

                    <Box>
                        <TabPanel value='1'>{panelForFirstTab()}</TabPanel>
                        <TabPanel value='2'>{panelForSecondTab()}</TabPanel>
                        <TabPanel value='3'>{panelForThirdTab()}</TabPanel>
                    </Box>
                </TabContext>

                <Box>
                    <Dialog open={openDialog} onClose={handleClose}>
                        <DialogTitle>SirenMD</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                You have requested a report that includes Personal Health
                                Information. Are you sure you want to continue?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className={classes.dialogActionsContainer}>
                            <Button
                                onClick={handleClose}
                                variant='outlined'
                                color='primary'
                                disableElevation={true}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={onSendEnjuryReport}
                                variant='contained'
                                color='primary'
                                disableElevation={true}
                            >
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        selectedDept: state.sirenUsers.selectedDept,
        injuredAthletes: state.athletes.injuredAthletes,
        recoveringAthletes: state.athletes.recoveringAthletes,
        healthyAthletes: state.athletes.healthyAthletes,
        ailingAthletes: state.athletes.ailingAthletes,
        chronicAthletes: state.athletes.chronicAthletes,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getInjuredAthletes: (id, sortBy) => {
            return dispatch(getInjuredAthletesRequest(id, sortBy)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getRecoveringAthletes: (id, sortBy) => {
            return dispatch(getRecoveringAthletesRequest(id, sortBy)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getHealthyAthletes: (id, sortBy) => {
            return dispatch(getHealthyAthletesRequest(id, sortBy)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getAilingAthletes: (id, sortBy) => {
            return dispatch(getAilingAthletesRequest(id, sortBy)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getChronicAthletes: (id, sortBy) => {
            return dispatch(getChronicAthletesRequest(id, sortBy)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        sendEnjuryReport: data => {
            return dispatch(sendEnjuryReportRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Athletes);
