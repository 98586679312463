const white = '#FFFFFF';
const black = '#000000';

const palette = {
    common: {
        black: black,
        white: white,
    },
    primary: {
        contrastText: white,
        dark: '#155682',
        main: '#1e7bbb',
        light: '#4b95c8',
    },
    secondary: {
        contrastText: white,
        dark: '#aa2e25',
        main: '#f44336',
        light: '#f6685e',
    },
};

export default palette;
