import {
    ADD_CAREGIVER,
    ADD_CAREGIVER_FROM_EXISTING_USER,
    GET_CAREGIVERS_FOR_DEPT,
    GET_CAREGIVERS_FOR_HC_ORG,
    GET_CAREGIVERS_FOR_ORG,
    GET_USER_BY_EMAIL,
    REMOVE_CAREGIVER_FROM_DEPT,
    TOGGLE_CAREGIVER_HEAD,
    TOGGLE_CAREGIVER_PRIVILEGES,
} from './CaregiverActions';

const initialState = {
    caregivers: [],
    caregiver: {},
    user: null,
};

/**
 * reducer function to update state based on action
 * @param state: current state of the reducer
 * @param action: action to perform on the data
 * @returns {({caregivers: *[], caregiver: {}, user: null} & {caregivers: ([]|null|*)})|({caregivers: *[], caregiver: {}, user: null} & {user})|({caregivers: *[], caregiver: {}, user: null} & {caregiver: *})|{caregivers: *[], caregiver: {}, user: null}}
 * @constructor
 */
const CaregiverReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CAREGIVER:
            return Object.assign({}, state, {
                caregiver: action.caregiver,
            });
        case ADD_CAREGIVER_FROM_EXISTING_USER:
            return Object.assign({}, state, {
                caregiver: action.caregiver,
            });

        case GET_CAREGIVERS_FOR_ORG:
            return Object.assign({}, state, {
                caregivers: action.caregivers,
            });

        case GET_CAREGIVERS_FOR_HC_ORG:
            return Object.assign({}, state, {
                caregivers: action.caregivers,
            });

        case GET_CAREGIVERS_FOR_DEPT:
            return Object.assign({}, state, {
                caregivers: action.caregivers,
            });

        case REMOVE_CAREGIVER_FROM_DEPT:
            return Object.assign({}, state, {
                caregiver: action.caregiver,
            });

        case TOGGLE_CAREGIVER_HEAD:
            return Object.assign({}, state, {
                caregiver: action.caregiver,
            });

        case TOGGLE_CAREGIVER_PRIVILEGES:
            // console.log('old', state.caregivers);
            // console.log('current', action.privilege);

            // const tempCaregivers = Object.assign({}, state.caregivers)
            // // {...state.caregivers};

            // tempCaregivers.rows.forEach(caregiver => {
            //    if(caregiver.userId === action.privilege.UserId){
            //         caregiver.clinicalPrivileges = action.privilege.clinicalPrivileges
            //    }
            // })

            // console.log('new', tempCaregivers);

            return {
                ...state
            }

        case GET_USER_BY_EMAIL:
            return Object.assign({}, state, {
                user: action.user,
            });

        default:
            return state;
    }
};

export default CaregiverReducer;
