import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        actionsContainer: {
            padding: '0',
            paddingRight: '1rem',
            paddingBottom: '1rem',
        },
    };
});
