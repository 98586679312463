import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        container: {
            paddingBottom: '4rem',
        },
        actionsContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0.6rem',
            paddingTop: '0.8rem',
            position: 'sticky',
            top: '78px',
            zIndex: '1000',
        },
        compareContainer: {
            width: '290px',
            marginLeft: '1rem',
            marginRight: '0.6rem',
        },
        timeContainer: {
            width: '160px',
        },
        profileContainer: {
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
        },
        profileImage: {
            height: '40px',
            width: '40px',
            borderRadius: '100%',
        },
        nameContainer: {
            marginLeft: '0.7rem',
        },
        summary: {
            fontSize: '14px',
        },
    };
});
