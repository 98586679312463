import React, {useRef, useState} from 'react';
import {Box} from '@material-ui/core';
import {getUserInfo, logoutUser} from '../../../utilities/storage';
import IdleTimer from 'react-idle-timer';
import AutoLogout from '../../../utilities/components/AutoLogout/AutoLogout';
import {useStyles} from './styles';
import Dashboard from '../DashboardLayout/DashboardLayout';

/**
 * function to render dashboard 
 * @param history: history from parent
 * @returns {JSX.Element}
 * @constructor
 */
const Home = ({history}) => {
    const classes = useStyles();
    const timerRef = useRef();
    const [openAutoLogout, setOpenAutoLogout] = useState(false);

    /**
     * function to return dashboard
     * @returns {JSX.Element}
     */
    const renderDashboard = () => {
        if (getUserInfo()) {
            return <Dashboard history={history}/>;
        }
    };

    /**
     * function to handle auto logout
     */
    const handleTimeout = () => {
        logoutUser();
    };

    /**
     * function to redirect to login screen
     */
    const redirectToLogin = () => {
        window.location.href = '/login';
        window.location.reload();
    };

    /**
     * function to get message on auto logout dialog
     * @returns {JSX.Element}
     */
    const Message = () => {
        return (
            <>
                <Box>
                    Sorry you are not authorized. Please{' '}
                    <span onClick={redirectToLogin} className={classes.loginText}>
            login
          </span>{' '}
                    again to continue.
                </Box>
            </>
        );
    };

    return (
        <>
            <IdleTimer
                ref={timerRef}
                timeout={1000 * 600}
                onIdle={() => {
                    setOpenAutoLogout(true);
                    handleTimeout();
                }}
            />

            <AutoLogout
                title='Unauthorized'
                message={<Message/>}
                open={openAutoLogout}
                setOpen={setOpenAutoLogout}
            />

            {renderDashboard()}
        </>
    );
};

export default Home;
