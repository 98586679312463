import HttpRequest from '../../utilities/httpRequest';
import {
    addHCOrganisation,
    assignCaregiver,
    assignOrg,
    assignVendor,
    getAssignedVendorById,
    getHCOrganisations,
    getHCOrganisationsWithoutParams,
    getHCOrgById,
    removeAssignedOrg,
    removeAssignedVendor,
    toggleActivation,
    updateAssignedVendor,
    updateHCOrg,
} from './HealthcareOrganizationsActions';
import {hideLoader, showLoader, showSnackbar,} from '../../utilities/UtilitiesActions';

/**
 * function to get healthcare organizations
 * @param data: data for api request
 * @param isHideLoader: isHideLoader for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getHCOrganisationsRequest = (data, isHideLoader) => {
    let offset = data.page * data.rowsPerPage;
    let limit = data.rowsPerPage;
    let searchData = data.searchData ? data.searchData : '';

    return dispatch => {
        if (!isHideLoader) {
            dispatch(showLoader());
        }

        const url = `healthcareorg?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
            searchData
        )}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                dispatch(getHCOrganisations(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get healthcare organizations without params
 * @returns {function(*=): Promise<unknown>}
 */
export const getHCOrganisationsWithoutParamsRequest = () => {
    return dispatch => {
        dispatch(showLoader());
        const url = 'healthcareorg';

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getHCOrganisationsWithoutParams(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to add healthcare organization
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const addHCOrganisationRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = 'healthcareorg';

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(
                    showSnackbar(
                        'Healthcare organization created successfully',
                        'success'
                    )
                );
                dispatch(addHCOrganisation(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(`${error.response.data.error}`, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get healthcare organization by id
 * @param id: id for the url
 * @returns {function(*=): Promise<unknown>}
 */
export const getHCOrgByIdRequest = id => {
    return dispatch => {
        dispatch(showLoader());
        const url = `healthcareorg/${id}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getHCOrgById(res.data.data));

                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to update healthcare organization
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const updateHCOrgRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `healthcareorg/${id}`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(
                    showSnackbar(
                        'Healthcare organization updated successfully',
                        'success'
                    )
                );
                dispatch(updateHCOrg(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(`${error.response.data.error}`, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to assign caregiver to a healthcare organization
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const assignCaregiverRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());

        const url = `healthcareorg/${id}/caregiver`;

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Caregiver assigned successfully', 'success'));

                dispatch(assignCaregiver(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(`${error.response.data.error}`, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to assign organization to a healthcare organization
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const assignOrgRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());

        const url = `addHealthcareToOrg/${id}`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Organization assigned successfully', 'success'));

                dispatch(assignOrg(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(error.response.data.error, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to assign vendor to a healthcare organization
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const assignVendorRequest = data => {
    return dispatch => {
        dispatch(showLoader());

        const url = `addVendor`;

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Vendor assigned successfully', 'success'));

                dispatch(assignVendor(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(error.response.data.error, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to update assign vendor
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const updateAssignedVendorRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `updateVendor/${id}`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Details updated successfully', 'success'));
                dispatch(updateAssignedVendor(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(`${error.response.data.error}`, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get assign vendor
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getAssignedVendorByIdRequest = id => {
    return dispatch => {
        dispatch(showLoader());
        const url = `getAssociation/${id}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getAssignedVendorById(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to remove assigned organization from a healthcare organization
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const removeAssignedOrgRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());

        const url = `removeHealthcareToOrg/${id}`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Organization removed successfully', 'success'));

                dispatch(removeAssignedOrg(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(`${error.response.data.error}`, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to remove assigned vendor from a healthcare organization
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const removeAssignedVendorRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());

        const url = `removeVendor/${id}`;

        return new HttpRequest(dispatch, url, 'delete', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Vendor removed successfully', 'success'));

                dispatch(removeAssignedVendor(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(`${error.response.data.error}`, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to toggle healthcare organization activation
 * @param orgId: orgId for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const toggleActivationRequest = (orgId, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `healthcareorg/${orgId}/activateOrDeactivateAccount`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(toggleActivation(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(`${error.response.data.error}`, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get signed url
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getSignedUrlRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = `healthcareorg/profilePic/signedURL`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};

/**
 * function to upload file on signed url
 * @param url: url for api request
 * @param file: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const uploadToS3Request = (url, file) => {
    return dispatch => {
        dispatch(showLoader());
        return new HttpRequest(dispatch, url, 'put', file)
            .s3Upload()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};
