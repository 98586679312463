import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Login from '../components/Auth/components/Login/Login';
import SetPassword from '../components/Auth/components/SetPassword/SetPassword';
import ForgetPassword from '../components/Auth/components/ForgetPassword/ForgetPassword';
import ResetPassword from '../components/Auth/components/ResetPassword/ResetPassword';
import Home from '../components/Dashboard/Home/Home';
import ErrorPage from '../utilities/pages/ErrorPage/ErrorPage';

/**
 * function to return app routes 
 * @returns {JSX.Element}
 * @constructor
 */
const AppRoutes = () => {
    return (
        <Router>
            <Switch>
                <Route exact path='/login' component={Login}/>
                <Route exact path='/setPassword/:id/:token' component={SetPassword}/>
                <Route exact path='/forgotPassword' component={ForgetPassword}/>
                <Route
                    exact
                    path='/resetPassword/:id/:token'
                    component={ResetPassword}
                />

                {/* common routes */}
                <PrivateRoute exact path='/' component={Home}/>

                {/* admin dashboard routes  */}
                <PrivateRoute exact path='/profile/:id' component={Home}/>
                <PrivateRoute exact path='/profile/edit/:id' component={Home}/>

                <PrivateRoute exact path='/users' component={Home}/>
                <PrivateRoute exact path='/users/:id' component={Home}/>
                <PrivateRoute exact path='/users/edit/:id' component={Home}/>

                <PrivateRoute exact path='/orgs' component={Home}/>
                <PrivateRoute exact path='/orgs/add' component={Home}/>
                <PrivateRoute exact path='/orgs/addDepartment' component={Home}/>
                <PrivateRoute exact path='/orgs/addCaregiver' component={Home}/>
                <PrivateRoute exact path='/orgs/addAdmin' component={Home}/>
                <PrivateRoute exact path='/orgs/:id' component={Home}/>
                <PrivateRoute exact path='/orgs/edit/:id' component={Home}/>

                <PrivateRoute
                    exact
                    path='/orgs/departments/addPatient'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/orgs/departments/addCaregiver'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/orgs/departments/addAdmin'
                    component={Home}
                />

                <PrivateRoute exact path='/orgs/departments/:id' component={Home}/>
                <PrivateRoute
                    exact
                    path='/orgs/departments/edit/:id'
                    component={Home}
                />
                <PrivateRoute exact path='/orgs/caregivers/edit/:id' component={Home}/>

                <PrivateRoute exact path='/orgs/caregivers/:id' component={Home}/>

                <PrivateRoute exact path='/orgs/admins/edit/:id' component={Home}/>

                <PrivateRoute exact path='/orgs/admins/:id' component={Home}/>

                <PrivateRoute
                    exact
                    path='/orgs/departments/patients/edit/:id'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/orgs/departments/patients/:id'
                    component={Home}
                />

                <PrivateRoute
                    exact
                    path='/orgs/departments/caregivers/edit/:id'
                    component={Home}
                />

                <PrivateRoute
                    exact
                    path='/orgs/departments/caregivers/:id'
                    component={Home}
                />

                <PrivateRoute
                    exact
                    path='/orgs/departments/admins/edit/:id'
                    component={Home}
                />

                <PrivateRoute
                    exact
                    path='/orgs/departments/admins/:id'
                    component={Home}
                />

                <PrivateRoute exact path='/healthcareOrgs' component={Home}/>

                <PrivateRoute exact path='/healthcareOrgs/add' component={Home}/>

                <PrivateRoute exact path='/healthcareOrgs/edit/:id' component={Home}/>

                <PrivateRoute exact path='/healthcareOrgs/:id' component={Home}/>
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/vendors/edit/:id'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/vendors/:id'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/addDepartment'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/addCaregiver'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/addAdmin'
                    component={Home}
                />

                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/admins/edit/:id'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/admins/:id'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/caregivers/edit/:id'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/caregivers/:id'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/edit/:id'
                    component={Home}
                />
                <PrivateRoute exact path='/healthcareOrgs/orgs/:id' component={Home}/>
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/departments/addPatient'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/departments/addCaregiver'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/departments/addAdmin'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/departments/patients/edit/:id'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/departments/patients/:id'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/departments/caregivers/edit/:id'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/departments/caregivers/:id'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/departments/admins/edit/:id'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/departments/admins/:id'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/departments/edit/:id'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/orgs/departments/:id'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/caregivers/edit/:id'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/healthcareOrgs/caregivers/:id'
                    component={Home}
                />

                <PrivateRoute exact path='/vendors/edit/:id' component={Home}/>
                <PrivateRoute exact path='/vendors/add' component={Home}/>
                <PrivateRoute exact path='/vendors/:id' component={Home}/>
                <PrivateRoute exact path='/vendors' component={Home}/>
                <PrivateRoute exact path='/dataUtilities' component={Home}/>
                <PrivateRoute exact path='/transactionMonitoring' component={Home}/>

                {/* user dashboard routes  */}
                <PrivateRoute exact path='/profile' component={Home}/>
                <PrivateRoute exact path='/profile/edit' component={Home}/>

                <PrivateRoute exact path='/newUpdates' component={Home}/>
                <PrivateRoute exact path='/playStatusTeamRoster' component={Home}/>

                <PrivateRoute exact path='/cases/addCase' component={Home}/>
                <PrivateRoute exact path='/cases' component={Home}/>
                <PrivateRoute exact path='/cases/:id' component={Home}/>

                <PrivateRoute exact path='/athletes/:id' component={Home}/>
                <PrivateRoute exact path='/athletes' component={Home}/>
                <PrivateRoute exact path='/injuryReport' component={Home}/>

                <PrivateRoute exact path='/analyticsDashboard' component={Home}/>

                <PrivateRoute exact path='/dataUtilities' component={Home}/>
                <PrivateRoute exact path='/departments' component={Home}/>
                <PrivateRoute exact path='/departments/edit/:id' component={Home}/>
                <PrivateRoute exact path='/departments/addPatient' component={Home}/>
                <PrivateRoute exact path='/departments/addCaregiver' component={Home}/>
                <PrivateRoute
                    exact
                    path='/departments/patients/edit/:id'
                    component={Home}
                />
                <PrivateRoute exact path='/departments/patients/:id' component={Home}/>
                <PrivateRoute
                    exact
                    path='/departments/caregivers/edit/:id'
                    component={Home}
                />
                <PrivateRoute
                    exact
                    path='/departments/caregivers/:id'
                    component={Home}
                />

                <PrivateRoute exact path='/departments/:id' component={Home}/>

                <Route
                    component={() => (
                        <ErrorPage code={404} message='Sorry! Page Not Found.'/>
                    )}
                />
            </Switch>
        </Router>
    );
};

export default AppRoutes;
