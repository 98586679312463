import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {NavLink, useParams, useRouteMatch} from 'react-router-dom';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    FormControl,
    FormControlLabel,
    Grid,
    Icon,
    Paper,
    Radio,
    Switch,
    Typography,
} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {AutocompleteValidator, RadioGroupValidator,} from '../../../../utilities/validators';
import ImageField from '../../../../utilities/components/ImageField/ImageField';
import {
    addOrganisationRequest,
    getOrgByIdRequest,
    getOrgSubTypesRequest,
    getOrgTypesRequest,
    getReadReceiptStatusRequest,
    getSignedUrlRequest,
    updateOrgRequest,
    uploadToS3Request,
} from '../../OrganisationsApiActions';
import UtilHelper from '../../../../utilities/validators/UtilHelper';
import {useStyles} from './styles';
import ConfirmationDialog from '../../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import {getIsTeamList, getUserInfo} from '../../../../utilities/storage';
import {dataURLtoFile} from '../../../../utilities/utilityFunctions';
import {ROLES, APP_URLS} from '../../../../utilities/constants';

/**
 * function to render organization form
 * @param location: location from router
 * @param history: history from router
 * @param getOrgTypes: function to get organization types
 * @param organizationTypeData: function to get organization sub types
 * @param getOrgSubTypes: organization types list
 * @param organizationSubtypeData: organization sub types list
 * @param getReadReceiptStatus: function to get read receipt status
 * @param readReceiptStatusData: read receipt status
 * @param addOrganisation: function to add organization
 * @param getOrgById: function to get organization by id
 * @param updateOrg: function to update existing organization
 * @param getSignedUrl: function to get signed url
 * @param uploadToS3: function to upload file on signed url
 * @returns {JSX.Element}
 * @constructor
 */
const OrganizationForm = ({
                              location,
                              history,
                              getOrgTypes,
                              organizationTypeData,
                              getOrgSubTypes,
                              organizationSubtypeData,
                              getReadReceiptStatus,
                              readReceiptStatusData,
                              addOrganisation,
                              getOrgById,
                              updateOrg,
                              getSignedUrl,
                              uploadToS3,
                          }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const params = useParams();
    const match = useRouteMatch();
    const currentUrlPath = match.path.split(':')[0];

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [organizationType, setOrganizationType] = useState(null);
    const [organizationSubtype, setOrganizationSubtype] = useState(null);
    const [readReceiptStatus, setReadReceiptStatus] = useState(null);
    const [reAuthentication, setReAuthentication] = useState(null);
    const [reAuthenticationTime, setReAuthenticationTime] = useState('');
    const [requireLogin, setRequireLogin] = useState('');
    const [promptForPINTouchID, setPromptForPINTouchID] = useState('');
    const [injuryReport, setInjuryReport] = useState(false);
    const [timeRequired, setTimeRequired] = useState(false);
    const [playStatusTeamRosterReport, setPlayStatusTeamRosterReport] = useState(
        false
    );
    const [enableSAML, setEnableSAML] = useState(false);
    const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);
    const [rowImageFile, setRowImageFile] = useState(null);
    const [cropper, setCropper] = useState(null);
    const [croppedImageFile, setCroppedImageFile] = useState(null);
    const [showImageEditor, setShowImageEditor] = useState(false);

    useEffect(() => {
        ValidatorForm.addValidationRule('isBlank', value =>
            UtilHelper.validateBlankSpace(value)
        );
        ValidatorForm.addValidationRule('isMaxLength', value =>
            UtilHelper.validateMaxLength(value)
        );
        return () => {
            ValidatorForm.removeValidationRule('isBlank');
            ValidatorForm.removeValidationRule('isMaxLength');
        };
    }, []);

    useEffect(() => {
        if(!params.id){
            getReadReceiptStatus().then(res => {
            });
        }
    }, [getReadReceiptStatus, params.id]);

    useEffect(() => {
        if(!params.id){
            getOrgTypes().then(res => {
            });
        }
    }, [getOrgTypes, params.id]);

    useEffect(() => {
        if (params.id) {
            
            getOrgById(params.id).then(res => {

                getReadReceiptStatus().then(resRss => {

                    getOrgTypes().then(resOrgType => {

                        const type = resOrgType.find(type => type.id === res.typeId);
                        const rrs = resRss.find(
                            rrs => rrs.id === res.readReceiptStatus
                        );

                        if (type && rrs) {

                            getOrgSubTypes(type.id).then(resSub => {
                                const subtype = resSub.find(
                                    subtype => subtype.id === res.subTypeId
                                );
        
                                setOrganizationType(type);
                                setOrganizationSubtype(subtype);
                                setReadReceiptStatus(rrs);

                                setCroppedImageFile(res.imageUrl);
                                setName(res.name);
                                setDescription(res.desc);
                                setPlayStatusTeamRosterReport(res.playStatusTeamRosterReport);
                                setInjuryReport(res.isInjuryReportEnable);
                                setTimeRequired(res.timeRequired);
                                setEnableSAML(res.isSso);
                                setRequireLogin(res.requireLogin.toString());
                                setPromptForPINTouchID(res.detectSecurity.toString());
                                if (res.reauthenticationType) {
                                    setReAuthentication(res.reauthenticationType.toString());
                                }
                                setReAuthenticationTime(res.reauthenticationValue ? res.reauthenticationValue : null);
                            });
                        }
                    });
                });
            });
        }
    }, [
        params.id,
        getOrgById,
        getOrgSubTypes,
        getReadReceiptStatus,
        getOrgTypes
    ]);

    const breadCrumbDataForAddOrgForm = [
        {
            icon: 'business',
            name: 'Organizations',
            path: APP_URLS.ORGANIZATIONS,
        },
        {
            name: `Add Organization`,
            path: APP_URLS.ORGANIZATIONS_ADD,
        },
    ];

    const breadCrumbDataForUpdateOrgForm = [
        {
            icon: 'business',
            name: 'Organizations',
            path: APP_URLS.ORGANIZATIONS,
        },
        {
            name: `Update Organization`,
            path: `${APP_URLS.ORGANIZATIONS_EDIT}/${params.id}`,
        },
    ];

    const breadCrumbDataForUpdateHcorgOrgForm = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: APP_URLS.HEALTHCARE_ORGANIZATIONS,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `${APP_URLS.HEALTHCARE_ORGANIZATIONS}/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `Update Oraganization`,
            path: `${APP_URLS.HEALTHCARE_ORGANIZATIONS_ORGS_EDIT}/${params.id}`,
        },
    ];

    /**
     * function to get breadcrumb data for current url
     */
    const getBreadcrumbData = (urlPath) => {
        switch(urlPath){
            case APP_URLS.ORGANIZATIONS_ADD: {
                return breadCrumbDataForAddOrgForm
            }
            case `${APP_URLS.ORGANIZATIONS_EDIT}/`: {
               return breadCrumbDataForUpdateOrgForm
            }
            case `${APP_URLS.HEALTHCARE_ORGANIZATIONS_ORGS_EDIT}/`:{
                return breadCrumbDataForUpdateHcorgOrgForm
             }
            default: {
                return []
            }
        }
    }

    /**
     * function to handle image crop
     */
    const onImageCrop = () => {
        const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL();
        const croppedImageFileObject = dataURLtoFile(
            croppedImageDataUrl,
            rowImageFile[0].file.name
        );

        const data = {
            dataURL: croppedImageDataUrl,
            file: croppedImageFileObject,
        };

        setCroppedImageFile(data);
        setShowImageEditor(false);
    };

    /**
     * function to add org
     * @param e: event object on form submission
     */
    const onAdd = e => {
        e.preventDefault();

        const data = {
            name: name,
            desc: description,
            typeId: organizationType.id,
            subTypeId: organizationSubtype.id,
            readReceiptStatus: readReceiptStatus.id,
            reauthenticationType: reAuthentication,
            reauthenticationValue: reAuthenticationTime,
            requireLogin: requireLogin === 'true',
            detectSecurity: promptForPINTouchID === 'true',
            isInjuryReportEnable: injuryReport,
            timeRequired: timeRequired,
            playStatusTeamRosterReport: playStatusTeamRosterReport,
            isSso: enableSAML,
        };

        if (croppedImageFile) {
            const imageData = {
                fileName: croppedImageFile.file.name,
                fileType: `.${croppedImageFile.file.name.split('.').pop()}`,
            };

            getSignedUrl(imageData).then(res => {
                uploadToS3(res.urls, croppedImageFile.file).then(uploadRes => {
                    data['imageUrl'] = res.path;
                    addOrganisation(data).then(res => {
                        history.push(`${APP_URLS.ORGANIZATIONS}/${res.id}`);
                    });
                });
            });
        } else {
            addOrganisation(data).then(res => {
                history.push(`${APP_URLS.ORGANIZATIONS}/${res.id}`);
            });
        }
    };

    /**
     * function to update org
     * @param e: event object on form submission
     */
    const onUpdate = e => {
        e.preventDefault();

        if (typeof croppedImageFile !== 'string') {
            const imageData = {
                fileName: croppedImageFile.file.name,
                fileType: `.${croppedImageFile.file.name.split('.').pop()}`,
            };

            getSignedUrl(imageData).then(res => {
                uploadToS3(res.urls, croppedImageFile.file).then(uploadRes => {
                    const data = {
                        imageUrl: res.path,
                        name: name,
                        desc: description,
                        typeId: organizationType.id,
                        subTypeId: organizationSubtype.id,
                        readReceiptStatus: readReceiptStatus.id,
                        reauthenticationType: reAuthentication,
                        reauthenticationValue: reAuthenticationTime,
                        requireLogin: requireLogin === 'true',
                        detectSecurity: promptForPINTouchID === 'true',
                        isInjuryReportEnable: injuryReport,
                        timeRequired: timeRequired,
                        playStatusTeamRosterReport: playStatusTeamRosterReport,
                        isSso: enableSAML,
                    };

                    updateOrg(params.id, data).then(res => {
                        history.goBack();
                    });
                });
            });
        } else {
            const data = {
                name: name,
                desc: description,
                typeId: organizationType.id,
                subTypeId: organizationSubtype.id,
                readReceiptStatus: readReceiptStatus.id,
                reauthenticationType: reAuthentication,
                reauthenticationValue: reAuthenticationTime,
                requireLogin: requireLogin === 'true',
                detectSecurity: promptForPINTouchID === 'true',
                isInjuryReportEnable: injuryReport,
                timeRequired: timeRequired,
                playStatusTeamRosterReport: playStatusTeamRosterReport,
                isSso: enableSAML,
            };

            updateOrg(params.id, data).then(res => {
                history.goBack();
            });
        }
    };

    /**
     * function to handle cancel add or update
     */
    const handleCancel = () => {
        history.goBack();
    };

    return (
        <Box
            className={
                role === ROLES.SIRENMD_ADMIN &&
                getIsTeamList() === 'false'
                    ? ''
                    : classes.outerContainer
            }
        >
            <Box
                className={
                    role === ROLES.SIRENMD_ADMIN &&
                    getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainer
                        : getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainerForAdmins
                        : classes.breadcrumbsContainerForUsers
                }
                component={Paper}
                p={1}
            >
                <Breadcrumbs>
                    {getBreadcrumbData(currentUrlPath).map((crumb, index) => (
                        <Box
                            key={index}
                            component={NavLink}
                            exact
                            to={{
                                pathname: crumb.path,
                                state: {
                                    orgIdHistory: location.state && location.state.orgIdHistory,
                                    orgNameHistory:
                                        location.state && location.state.orgNameHistory,
                                    hcorgIdHistory:
                                        location.state && location.state.hcorgIdHistory,
                                    hcorgNameHistory:
                                        location.state && location.state.hcorgNameHistory,
                                },
                            }}
                            className={classes.breadcrumbItem}
                            activeClassName={classes.activeBreadcrumbItem}
                        >
                            {crumb.icon && (
                                <Icon className={classes.breadcrumpIcon}>{crumb.icon}</Icon>
                            )}
                            <Box className={classes.breadcrumpLabel}>{crumb.name}</Box>
                        </Box>
                    ))}
                </Breadcrumbs>
            </Box>

            <Box mt={2}>
                <ValidatorForm onSubmit={params.id ? onUpdate : onAdd}>
                    <Box className={classes.addOragnizationContainer}>
                        <Box className={classes.addOragnizationLabelContainer}>
                            <Typography classes={{root: classes.addOragnizationLabel}}>
                                {params.id ? 'Update' : 'Create'} Organization
                            </Typography>
                        </Box>

                        <Box className={classes.addOragnizationFormContainer}>
                            <Box
                                component={Paper}
                                className={classes.addOragnizationFormInnerContainer}
                            >
                                <Box className={classes.formLabelOrganizationDetails}>
                                    <Typography variant='h6'>Organization Details</Typography>
                                </Box>
                                <Grid container spacing={2} justify='space-around'>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <Box>
                                            <ImageField
                                                rowImageFile={rowImageFile}
                                                setRowImageFile={setRowImageFile}
                                                showImageEditor={showImageEditor}
                                                setShowImageEditor={setShowImageEditor}
                                                setCropper={setCropper}
                                                croppedImageFile={croppedImageFile}
                                                onImageCrop={onImageCrop}
                                                options={{
                                                    shape: 'rect',
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextValidator
                                                    label='Name *'
                                                    variant='outlined'
                                                    fullWidth
                                                    size='small'
                                                    value={name}
                                                    onChange={e => setName(e.target.value)}
                                                    validators={['required', 'isBlank', 'isMaxLength']}
                                                    errorMessages={[
                                                        'Name is required',
                                                        'Please enter name',
                                                        'Maximum 100 letters are accepted.',
                                                    ]}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextValidator
                                                    label='Description *'
                                                    variant='outlined'
                                                    fullWidth
                                                    size='small'
                                                    value={description}
                                                    onChange={e => setDescription(e.target.value)}
                                                    validators={['required', 'isBlank', 'isMaxLength']}
                                                    errorMessages={[
                                                        'Description is required',
                                                        'Please enter description',
                                                        'Maximum 100 letters are accepted.',
                                                    ]}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <AutocompleteValidator
                                                    fullWidth
                                                    size='small'
                                                    value={organizationType}
                                                    onChange={(e, value) => {
                                                        setOrganizationType(value);
                                                        setOrganizationSubtype(null);
                                                        if (value) {
                                                            getOrgSubTypes(value.id).then(res => {
                                                            });
                                                        }
                                                    }}
                                                    options={
                                                        organizationTypeData ? organizationTypeData : []
                                                    }
                                                    getOptionLabel={option => option.description}
                                                    getOptionSelected={(option, value) =>
                                                        option.id === value.id
                                                    }
                                                    renderInput={params => (
                                                        <TextValidator
                                                            {...params}
                                                            label='Organization Type *'
                                                            variant='outlined'
                                                        />
                                                    )}
                                                    validators={['required']}
                                                    errorMessages={['Organization type is required']}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <AutocompleteValidator
                                                    fullWidth
                                                    size='small'
                                                    value={organizationSubtype}
                                                    onChange={(e, value) => {
                                                        setOrganizationSubtype(value);
                                                    }}
                                                    options={
                                                        organizationType ? organizationSubtypeData : []
                                                    }
                                                    noOptionsText={
                                                        organizationType
                                                            ? 'No options'
                                                            : 'Select Organization Type'
                                                    }
                                                    getOptionLabel={option => option.description}
                                                    getOptionSelected={(option, value) =>
                                                        option.id === value.id
                                                    }
                                                    renderInput={params => (
                                                        <TextValidator
                                                            {...params}
                                                            label='Organization Subtype *'
                                                            variant='outlined'
                                                        />
                                                    )}
                                                    validators={['required']}
                                                    errorMessages={['Organization subtype is required']}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <AutocompleteValidator
                                                    fullWidth
                                                    size='small'
                                                    value={readReceiptStatus}
                                                    onChange={(e, value) => {
                                                        setReadReceiptStatus(value);
                                                    }}
                                                    options={
                                                        readReceiptStatusData ? readReceiptStatusData : []
                                                    }
                                                    getOptionLabel={option => option.description}
                                                    getOptionSelected={(option, value) =>
                                                        option.id === value.id
                                                    }
                                                    renderInput={params => (
                                                        <TextValidator
                                                            {...params}
                                                            label='Read Receipt Status *'
                                                            variant='outlined'
                                                        />
                                                    )}
                                                    validators={['required']}
                                                    errorMessages={['Read receipt status is required']}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box
                                component={Paper}
                                className={classes.authenticationContainer}
                            >
                                <Box className={classes.formLabelAuthentication}>
                                    <Typography variant='h6'>Authentication</Typography>
                                </Box>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Box>
                                            <Typography variant='inherit'>
                                                Reauthentication
                                            </Typography>
                                        </Box>
                                        <Box className={classes.reauthenticationContainer}>
                                            <Box>
                                                <FormControl>
                                                    <RadioGroupValidator
                                                        row
                                                        value={reAuthentication}
                                                        onChange={e => setReAuthentication(e.target.value)}
                                                    >
                                                        <FormControlLabel
                                                            value='1'
                                                            control={<Radio color='primary'/>}
                                                            label='Hours'
                                                        />
                                                        <FormControlLabel
                                                            value='0'
                                                            control={<Radio color='primary'/>}
                                                            label='Days'
                                                        />
                                                    </RadioGroupValidator>
                                                </FormControl>
                                            </Box>

                                            {reAuthentication === '1' || reAuthentication === '0' ? (
                                                <Box>
                                                    <TextValidator
                                                        label={
                                                            reAuthentication === '1'
                                                                ? 'Hours *'
                                                                : reAuthentication === '0'
                                                                ? 'Days *'
                                                                : ''
                                                        }
                                                        variant='outlined'
                                                        fullWidth
                                                        size='small'
                                                        value={reAuthenticationTime}
                                                        onChange={e =>
                                                            setReAuthenticationTime(e.target.value)
                                                        }
                                                        validators={['required', 'matchRegexp:^[0-9]+$']}
                                                        errorMessages={[
                                                            ` ${
                                                                reAuthentication === '1'
                                                                    ? 'Hours'
                                                                    : reAuthentication === '0'
                                                                    ? 'Days'
                                                                    : ''
                                                            } is required`,
                                                            'Please enter number',
                                                        ]}
                                                    />
                                                </Box>
                                            ) : (
                                                <></>
                                            )}
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Box>
                                            <Typography variant='inherit'>Require Login *</Typography>
                                        </Box>
                                        <Box>
                                            <FormControl>
                                                <RadioGroupValidator
                                                    row
                                                    value={requireLogin}
                                                    onChange={e => setRequireLogin(e.target.value)}
                                                    validators={['required']}
                                                    errorMessages={['This field is required']}
                                                >
                                                    <FormControlLabel
                                                        value='true'
                                                        control={<Radio color='primary'/>}
                                                        label='No PIN/Touch ID Set for Application'
                                                    />
                                                    <FormControlLabel
                                                        value='false'
                                                        control={<Radio color='primary'/>}
                                                        label='Never'
                                                    />
                                                </RadioGroupValidator>
                                            </FormControl>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box>
                                            <Typography variant='inherit'>
                                                Prompt for PIN/Touch ID When Device Level Security
                                                Exists? *
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <FormControl>
                                                <RadioGroupValidator
                                                    row
                                                    value={promptForPINTouchID}
                                                    onChange={e => setPromptForPINTouchID(e.target.value)}
                                                    validators={['required']}
                                                    errorMessages={['This field is required']}
                                                >
                                                    <FormControlLabel
                                                        value='true'
                                                        control={<Radio color='primary'/>}
                                                        label='Yes'
                                                    />
                                                    <FormControlLabel
                                                        value='false'
                                                        control={<Radio color='primary'/>}
                                                        label='No'
                                                    />
                                                </RadioGroupValidator>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box
                                component={Paper}
                                className={classes.reportContainer}
                            >
                                <Box className={classes.formLabelReport}>
                                    <Typography variant='h6'>Report</Typography>
                                </Box>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Box className={classes.switchContainer}>
                                            <Box>
                                                <Typography variant='inherit'>
                                                    Injury Report?
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Switch
                                                    checked={injuryReport}
                                                    onChange={e => setInjuryReport(e.target.checked)}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Box className={classes.switchContainer}>
                                            <Box>
                                                <Typography variant='inherit'>
                                                    Time Required?
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Switch
                                                    checked={timeRequired}
                                                    onChange={e => setTimeRequired(e.target.checked)}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Box className={classes.switchContainer}>
                                            <Box>
                                                <Typography variant='inherit'>
                                                    Play Status Team Roster Report?
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Switch
                                                    checked={playStatusTeamRosterReport}
                                                    onChange={e =>
                                                        setPlayStatusTeamRosterReport(e.target.checked)
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Box className={classes.switchContainer}>
                                            <Box>
                                                <Typography variant='inherit'>
                                                    Enable SAML 2.0 SSO?
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Switch
                                                    checked={enableSAML}
                                                    onChange={e => setEnableSAML(e.target.checked)}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>

                    <Box className={classes.footerContainer}>
                        <Box
                            component={Card}
                            raised
                            className={classes.footerContainerInner}
                        >
                            <Box>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    disableElevation={true}
                                    onClick={() => setOpenCancelConfirmation(true)}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    variant='contained'
                                    color='primary'
                                    disableElevation={true}
                                    type='submit'
                                    className={classes.saveButton}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </ValidatorForm>
            </Box>

            <Box>
                <ConfirmationDialog
                    open={openCancelConfirmation}
                    setOpen={setOpenCancelConfirmation}
                    title='Cancel'
                    message='Are you sure you want to cancel?'
                    onConfirm={handleCancel}
                />
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        organizationTypeData: state.organisations.orgTypes,
        organizationSubtypeData: state.organisations.orgSubTypes,
        readReceiptStatusData: state.organisations.readReceiptStatus,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addOrganisation: data => {
            return dispatch(addOrganisationRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getOrgById: id => {
            return dispatch(getOrgByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateOrg: (id, data) => {
            return dispatch(updateOrgRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getOrgTypes: () => {
            return dispatch(getOrgTypesRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getOrgSubTypes: id => {
            return dispatch(getOrgSubTypesRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getReadReceiptStatus: () => {
            return dispatch(getReadReceiptStatusRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getSignedUrl: data => {
            return dispatch(getSignedUrlRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        uploadToS3: (url, file) => {
            return dispatch(uploadToS3Request(url, file)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationForm);
