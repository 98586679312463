import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider,} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import HelpModal from '../DicomHelpModal/DicomHelpModal';
import {useStyles} from './styles';

/**
 * function to render medical upload
 * @param openMedicalUpload: opens the dicom upload
 * @param handleMedicalUploadClose: function to close dicom upload
 * @param dicomUrl: dicom url
 * @returns {JSX.Element}
 * @constructor
 */
const MedicalUpload = ({
                           openMedicalUpload,
                           handleMedicalUploadClose,
                           dicomUrl,
                       }) => {
    const classes = useStyles();
    const [openHelp, setOpenHelp] = useState(false);

    /**
     * function to open dicom upload help dialog
     */
    const handleHelpOpen = () => {
        setOpenHelp(true);
    };

    /**
     * function to close dicom upload help dialog
     */
    const handleHelpClose = () => {
        setOpenHelp(false);
    };

    return (
        <>
            <Dialog
                maxWidth='md'
                fullWidth
                open={openMedicalUpload}
                disableBackdropClick
            >
                <DialogTitle>DICOM Upload</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Box>
                        <iframe
                            title='dicom'
                            src={dicomUrl ? dicomUrl.url : ''}
                            frameborder='1'
                            width='100%'
                            height='450px'
                        />
                    </Box>
                </DialogContent>

                <Divider/>
                <DialogActions classes={{root: classes.actionsContainer}}>
                    <Box className={classes.noteContainer}>
                        <Box>
                            <HelpIcon className={classes.helpIcon}/>
                        </Box>
                        <Box>
                            <span className={classes.helpLabel}>
                            Having trouble uploading?
                            </span>{' '}
                                        <span className={classes.help} onClick={handleHelpOpen}>
                            Click here for instructions.
                            </span>
                        </Box>
                    </Box>
                    <Box>
                        <Button
                            variant='contained'
                            onClick={handleMedicalUploadClose}
                            color='primary'
                        >
                            Close
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            <HelpModal open={openHelp} handleClose={handleHelpClose}/>
        </>
    );
};

const mapStateToProps = state => {
    return {
        selectedDept: state.sirenUsers.selectedDept,
        singleCase: state.cases.case,
        caseStatus: state.cases.caseStatus,
        caregiversList: state.cases.caregivers,
        casePriorities: state.cases.casePriorities,
        logItems: state.cases.logItems,
        dicomUrl: state.cases.dicomUrl,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicalUpload);
