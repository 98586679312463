import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        actionsContainer: {
            padding: '16px 20px',
            justifyContent: 'space-between',
        },
        helpLabel: {
            fontSize: '1rem',
            color: '#666',
            marginLeft: '5px',
        },
        noteContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        helpIcon: {
            color: '#666',
        },
        help: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
    };
});
