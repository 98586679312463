import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, NavLink, useParams, useRouteMatch} from 'react-router-dom';
import {Box, Breadcrumbs, Button, Icon, Paper, Typography,} from '@material-ui/core';
import {getPatientByIdRequest, syncArmsForPatientRequest, toggleActivationRequest,} from '../PatientsApiActions';
import ConfirmationDialog from '../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import {useStyles} from './styles';
import defaultUser from '../../../assets/user.png';
import {getIsTeamList, getUserInfo} from '../../../utilities/storage';
import ImageLoader from 'react-load-image';
import Preloader from '../../../utilities/components/Preloader/Preloader';
import {ROLES} from '../../../utilities/constants';

/**
 * function to get message for activation dialog
 * @param patient: patient details
 * @param classes: classes from css
 * @returns {JSX.Element}
 * @constructor
 */
const ToggleActivationDialogMessage = ({patient, classes}) => {
    return (
        <>
            {patient && (
                <Box>
                    Are you sure you want to {patient.active ? 'deactivate' : 'activate'}{' '}
                    the{' '}
                    <span className={classes.dialogMessageLabel}>
            {patient.firstName} {patient.lastName}
          </span>
                    ?
                </Box>
            )}
        </>
    );
};

/**
 * function to render organization profile
 * @param location: location from router
 * @param getPatientById: function to get patient by id
 * @param patient: patient details
 * @param toggleActivation: function to toggle patient activation
 * @param syncArmsForPatient: function to sync arms
 * @returns {JSX.Element}
 * @constructor
 */
const PatientProfile = ({
                            location,
                            getPatientById,
                            patient,
                            toggleActivation,
                            syncArmsForPatient,
                        }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const params = useParams();
    const match = useRouteMatch();
    const [
        openToggleActivationConfirmationDialog,
        setOpenToggleActivationConfirmationDialog,
    ] = useState(false);
    const [openSyncConfirmationDialog, setOpenSyncConfirmationDialog] = useState(
        false
    );
    const currentUrlPath = match.path.split(':')[0];

    useEffect(() => {
        getPatientById(params.id).then(res => {
        });
    }, [params.id, getPatientById]);

    const breadCrumbDataForOrgDeptPatientProfilePage = [
        {
            icon: 'business',
            name: 'Organizations',
            path: '/orgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/orgs/${location.state && location.state.orgIdHistory}`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/orgs/departments/${
                location.state && location.state.deptIdHistory
            }`,
        },
        {
            name: `${patient ? `${patient.firstName} ${patient.lastName}` : ''}`,
            path: `/orgs/departments/patients/${params.id}`,
        },
    ];

    const breadCrumbDataForHcorgOrgDeptPatientProfilePage = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: '/healthcareOrgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/${
                location.state && location.state.orgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/departments/${
                location.state && location.state.deptIdHistory
            }`,
        },
        {
            name: `${patient ? `${patient.firstName} ${patient.lastName}` : ''}`,
            path: `/healthcareOrgs/orgs/departments/patients/${params.id}`,
        },
    ];

    const breadCrumbDataForAdminDeptPatientProfilePage = [
        {
            icon: 'apartment',
            name: 'Departments',
            path: '/departments',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/departments/${location.state && location.state.deptIdHistory}`,
        },
        {
            name: `${patient ? `${patient.firstName} ${patient.lastName}` : ''}`,
            path: `/departments/patients/${params.id}`,
        },
    ];

    /**
     * function to get breadcrumb data for current url
     * @return {Array}
     */
    const getBreadcrumbData = (urlPath) => {
        switch(urlPath){
            case '/orgs/departments/patients/':{
                return breadCrumbDataForOrgDeptPatientProfilePage
            }
            case '/healthcareOrgs/orgs/departments/patients/':{
               return breadCrumbDataForHcorgOrgDeptPatientProfilePage
            }
            case '/departments/patients/':{
                return breadCrumbDataForAdminDeptPatientProfilePage
             }
            default: {
                return []
            }
        }
    }

    /**
     * function to toggle activation
     */
    const onToggleActivation = () => {
        const data = {
            active: !patient.active,
        };

        toggleActivation(params.id, data).then(res => {
            setOpenToggleActivationConfirmationDialog(false);
        });
    };

    /**
     * function to sync arms
     */
    const onSyncArms = () => {
        const data = {
            athleteId: Number(params.id),
        };

        syncArmsForPatient(data).then(res => {
            setOpenSyncConfirmationDialog(false);
        });
    };

    return (
        <Box
            className={
                role === ROLES.SIRENMD_ADMIN &&
                getIsTeamList() === 'false'
                    ? ''
                    : classes.outerContainer
            }
        >
            <Box
                className={
                    role === ROLES.SIRENMD_ADMIN &&
                    getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainer
                        : getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainerForAdmins
                        : classes.breadcrumbsContainerForUsers
                }
                component={Paper}
                p={1}
            >
                <Breadcrumbs>
                    {getBreadcrumbData(currentUrlPath).map((crumb, index) => (
                        <Box
                            key={index}
                            component={NavLink}
                            exact
                            to={{
                                pathname: crumb.path,
                                state: {
                                    orgIdHistory: location.state && location.state.orgIdHistory,
                                    orgNameHistory:
                                        location.state && location.state.orgNameHistory,
                                    deptIdHistory: location.state && location.state.deptIdHistory,
                                    deptNameHistory:
                                        location.state && location.state.deptNameHistory,
                                    hcorgIdHistory:
                                        location.state && location.state.hcorgIdHistory,
                                    hcorgNameHistory:
                                        location.state && location.state.hcorgNameHistory,
                                },
                            }}
                            className={classes.breadcrumbItem}
                            activeClassName={classes.activeBreadcrumbItem}
                        >
                            {crumb.icon && (
                                <Icon className={classes.breadcrumpIcon}>{crumb.icon}</Icon>
                            )}
                            <Box className={classes.breadcrumpLabel}>{crumb.name}</Box>
                        </Box>
                    ))}
                </Breadcrumbs>
            </Box>

            <Box mt={3}>
                {patient && (
                    <Box component={Paper} className={classes.profileContainer}>
                        <Box className={classes.profileContentContainer}>
                            <Box>
                                <ImageLoader
                                    src={patient.imageUrl ? patient.imageUrl : defaultUser}
                                >
                                    <img alt='profile' className={classes.profileImage}/>
                                    <div className={classes.profileImageError}>{patient.id}</div>
                                    <Preloader className={classes.profileImage}/>
                                </ImageLoader>
                            </Box>
                            <Box className={classes.profileTextContainer}>
                                <Typography className={classes.name}>
                                    {patient.firstName} {patient.lastName}
                                </Typography>

                                {patient.position && (
                                    <Box className={classes.userProfileRoleContainer}>
                                        <Icon className={classes.userProfileRoleIcon}>
                                            radio_button_unchecked
                                        </Icon>
                                        <Typography classes={{root: classes.userProfileRole}}>
                                            {patient.position}
                                        </Typography>
                                    </Box>
                                )}

                                <Box className={classes.jursyNumberContainer}>
                                    {patient.jerseyNum && (
                                        <Typography className={classes.jursyNumberLabel}>
                                            Jersey Number: #{patient.jerseyNum}
                                        </Typography>
                                    )}

                                    {patient.mrn && (
                                        <Typography className={classes.jursyNumberLabel}>
                                            Medical Record Number: #{patient.mrn}
                                        </Typography>
                                    )}
                                </Box>

                                <Box>
                                    <Button
                                        className={classes.profileButton}
                                        variant='contained'
                                        color='primary'
                                        disableElevation={true}
                                        startIcon={<Icon>edit</Icon>}
                                        component={Link}
                                        to={{
                                            pathname: `${match.path.split(':')[0]}edit/${params.id}`,
                                            state: {
                                                orgIdHistory:
                                                    location.state && location.state.orgIdHistory,
                                                orgNameHistory:
                                                    location.state && location.state.orgNameHistory,
                                                deptIdHistory:
                                                    location.state && location.state.deptIdHistory,
                                                deptNameHistory:
                                                    location.state && location.state.deptNameHistory,
                                                hcorgIdHistory:
                                                    location.state && location.state.hcorgIdHistory,
                                                hcorgNameHistory:
                                                    location.state && location.state.hcorgNameHistory,
                                            },
                                        }}
                                    >
                                        Edit Details
                                    </Button>
                                </Box>

                                <Box>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        disableElevation={true}
                                        startIcon={
                                            patient.active === false ? (
                                                <Icon>check_circle_outline</Icon>
                                            ) : (
                                                <Icon>block</Icon>
                                            )
                                        }
                                        onClick={() =>
                                            setOpenToggleActivationConfirmationDialog(true)
                                        }
                                        classes={{
                                            root: `${classes.profileButton} ${
                                                patient.active
                                                    ? classes.blockButton
                                                    : classes.activeButton
                                            }`,
                                        }}
                                    >
                                        {patient.active === false ? 'Activate' : 'Deactivate'}
                                    </Button>
                                </Box>

                                {patient.athleteRMSId && (
                                    <Box>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            disableElevation={true}
                                            startIcon={<Icon>sync</Icon>}
                                            classes={{root: classes.profileButton}}
                                            onClick={() => setOpenSyncConfirmationDialog(true)}
                                        >
                                            Sync to ARMS
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                )}

                {patient && (
                    <Box>
                        <ConfirmationDialog
                            title={patient.active ? 'Deactivate' : 'Activate'}
                            message={
                                <ToggleActivationDialogMessage
                                    patient={patient}
                                    classes={classes}
                                />
                            }
                            open={openToggleActivationConfirmationDialog}
                            setOpen={setOpenToggleActivationConfirmationDialog}
                            onConfirm={onToggleActivation}
                        />
                    </Box>
                )}

                <Box>
                    <ConfirmationDialog
                        title='Sync to ARMS'
                        message='Are you sure you want to sync to ARMS?'
                        open={openSyncConfirmationDialog}
                        setOpen={setOpenSyncConfirmationDialog}
                        onConfirm={onSyncArms}
                    />
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        patient: state.patients.patient,
        org: state.organisations.org,
        dept: state.departments.dept,
    };
};

const mapDispatchToProp = dispatch => {
    return {
        getPatientById: id => {
            return dispatch(getPatientByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        toggleActivation: (id, data) => {
            return dispatch(toggleActivationRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        syncArmsForPatient: data => {
            return dispatch(syncArmsForPatientRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProp)(PatientProfile);
