import {
    ADD_PATIENT,
    GET_PATIENT_BY_ID,
    GET_PATIENTS_FOR_DEPT,
    SYNC_ARMS_FOR_PATIENT,
    TOGGLE_PATIENT_ACTIVATION,
    UPDATE_PATIENT,
} from './PatientsActions';

import {CLEAR_STORE} from '../Departments/DepartmentsActions';

const initialState = {
    patients: [],
    patient: null,
    arms: null,
};

/**
 * reducer function to update state based on action
 * @param state: current state of the reducer
 * @param action: action to perform on the data
 * @returns {({patient: null, patients: *[], arms: null} & {patient: (null|*)})|({patient: null, patients: *[], arms: null} & {arms: (null|*)})|({patient: null, patients: *[], arms: null} & {patients: ([]|*)})|{patient: null, patients: *[], arms: null}|{patient: {active}, patients: *[], arms: null}}
 * @constructor
 */
const PatientsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PATIENTS_FOR_DEPT:
            return Object.assign({}, state, {
                patients: action.patients,
            });

        case ADD_PATIENT:
            return Object.assign({}, state, {
                patient: action.patient,
            });

        case GET_PATIENT_BY_ID:
            return Object.assign({}, state, {
                patient: action.patient,
            });

        case UPDATE_PATIENT:
            return Object.assign({}, state, {
                patient: action.patient,
            });

        case TOGGLE_PATIENT_ACTIVATION:
            return {
                ...state,
                patient: {
                    ...state.patient,
                    active: action.patient.active,
                },
            };

        case SYNC_ARMS_FOR_PATIENT:
            return Object.assign({}, state, {
                arms: action.arms,
            });

        case CLEAR_STORE:
            return Object.assign({}, initialState);

        default:
            return state;
    }
};

export default PatientsReducer;
