import React, {useState} from 'react';
import {connect} from 'react-redux';
import {NavLink, useRouteMatch} from 'react-router-dom';
import {Box, Breadcrumbs, Icon, Paper, Tab} from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import {useStyles} from './styles';
import {getIsTeamList, getUserInfo} from '../../../../utilities/storage';
import CaregiversTab from '../CaregiversTab/CaregiversTab';
import PatientsTab from '../PatientsTab/PatientsTab';
import {ROLES} from '../../../../utilities/constants'

/**
 * function to render data utilities
 * @param location: location from router
 * @returns {JSX.Element}
 * @constructor
 */
const DataUtilities = ({location}) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const match = useRouteMatch();
    const [tabValue, setTabValue] = useState('1');
    const currentUrlPath = match.path.split(':')[0];


    const breadCrumbDataForDataUtilities = [
        {
            icon: 'settings',
            name: 'Data Utilities',
            path: '/dataUtilities',
        },
    ];

    /**
     * function to get breadcrumb data for current url
     */
    const getBreadcrumbData = (urlPath) => {
        switch(urlPath){
            case '/dataUtilities': {
                return breadCrumbDataForDataUtilities
            }
            default: {
                return []
            }
        }
    }

    /**
     * function to handle tab change
     * @param e: event from button click
     * @param newValue: newValue from button click
     */
    const handleChange = (e, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box
            className={
                role === ROLES.SIRENMD_ADMIN &&
                getIsTeamList() === 'false'
                    ? ''
                    : classes.outerContainer
            }
        >
            <Box
                className={
                   role === ROLES.SIRENMD_ADMIN &&
                    getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainer
                        : getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainerForAdmins
                        : classes.breadcrumbsContainerForUsers
                }
                component={Paper}
                p={1}
            >
                <Breadcrumbs>
                    {getBreadcrumbData(currentUrlPath).map((crumb, index) => (
                        <Box
                            key={index}
                            component={NavLink}
                            exact
                            to={{
                                pathname: crumb.path,
                                state: {
                                    hcorgIdHistory:
                                        location.state && location.state.hcorgIdHistory,
                                    hcorgNameHistory:
                                        location.state && location.state.hcorgNameHistory,
                                },
                            }}
                            className={classes.breadcrumbItem}
                            activeClassName={classes.activeBreadcrumbItem}
                        >
                            {crumb.icon && (
                                <Icon className={classes.breadcrumpIcon}>{crumb.icon}</Icon>
                            )}
                            <Box className={classes.breadcrumpLabel}>{crumb.name}</Box>
                        </Box>
                    ))}
                </Breadcrumbs>
            </Box>

            <Box>
                <Box className={classes.tabsContainer}>
                    <TabContext value={tabValue}>
                        <TabList
                            component={Paper}
                            onChange={handleChange}
                            indicatorColor='primary'
                            textColor='primary'
                            variant='fullWidth'
                            classes={{root: classes.tabsInnerContainer}}
                        >
                            <Tab label='Caregivers' value='1'/>
                            <Tab label='Patients' value='2'/>
                        </TabList>

                        <TabPanel value='1' classes={{root: classes.tabPanelContainer}}>
                            <CaregiversTab/>
                        </TabPanel>

                        <TabPanel value='2' classes={{root: classes.tabPanelContainer}}>
                            <PatientsTab/>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        tabValue: state.organisations.tabValue,
    };
};

const mapDispatchToProp = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProp)(DataUtilities);
