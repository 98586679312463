import {GET_ATHLETES_FOR_DEPT} from './PlayStatusActions';

const initialState = {
    athletes: [],
};

/**
 * reducer function to update state based on action
 * @param state: current state of the reducer
 * @param action: action to perform on the data
 * @returns {{update, updates: null}|{update: null, updates: null}|{update: null, updates: (null|*)}}
 * @constructor
 */
const AthletesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ATHLETES_FOR_DEPT:
            return Object.assign({}, state, {
                athletes: action.athletes,
            });

        default:
            return state;
    }
};

export default AthletesReducer;
