import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        contentContainer: {
            marginTop: '0.5rem',
        },
        dropzoneContainer: {
            minHeight: '150px',
        },
        actionsContainer: {
            marginRight: '1rem',
            marginBottom: '0.5rem',
        },
        dropzoneText: {
            fontSize: '1.1rem',
            marginLeft: '10px',
            marginRight: '10px',
            color: 'rgba(0, 0, 0, 0.54)',
        },
    };
});
