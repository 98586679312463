import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import MaterialTable from 'material-table';
import {Box, Button, FormControl, Icon, MenuItem, Select, Tooltip, Typography,} from '@material-ui/core';
import {PAGE_SIZE, PAGE_SIZE_OPTIONS} from '../../../utilities/constants';
import {getPatientsForDeptRequest} from '../PatientsApiActions';
import {useStyles} from './styles';
import defaultUser from '../../../assets/user.png';
import {debounce} from '../../../utilities/utilityFunctions';
import ImageLoader from 'react-load-image';
import Preloader from '../../../utilities/components/Preloader/Preloader';

/**
 * function to render department patients list
 * @param getAllPatients: function to get patients of department
 * @param patients: patients list
 * @param orgIdHistory: organization id
 * @param orgNameHistory: organization name
 * @param deptIdHistory: department id
 * @param deptNameHistory: department name
 * @param hcorgIdHistory: healthcare organization id
 * @param hcorgNameHistory: healthcare organization name
 * @returns {JSX.Element}
 * @constructor
 */
const DepartmentPatients = ({
                                getAllPatients,
                                patients,
                                orgIdHistory,
                                orgNameHistory,
                                deptIdHistory,
                                deptNameHistory,
                                hcorgIdHistory,
                                hcorgNameHistory,
                            }) => {
    const classes = useStyles();
    const params = useParams();
    const match = useRouteMatch();
    const [paginationParams, setPaginationParams] = useState({
        page: 0,
        rowsPerPage: PAGE_SIZE,
        searchData: {
            name: '',
            active: '',
        },
    });
    const [isFilterChange, setIsFilterChange] = useState(false);

    /**
     * function to handle table filter search
     * @param filters: filters from filter input to search
     */
    const onTableFilterChange = filters => {
        let searchDataOnFilterChange = Object.assign({}, paginationParams);

        if (filters.length > 0) {
            let obj = {};
            filters.forEach(filter => {
                obj[filter.column.field] = filter.value;
            });

            Object.keys(searchDataOnFilterChange.searchData).forEach(function (key) {
                if (key !== 'active') {
                    if (obj[key]) {
                        searchDataOnFilterChange.searchData[key] = obj[key];
                    } else {
                        searchDataOnFilterChange.searchData[key] = '';
                    }
                }
            });

            filters.forEach(filter => {
                searchDataOnFilterChange.searchData[filter.column.field] = filter.value;
            });
        } else {
            searchDataOnFilterChange.searchData['name'] = '';
            searchDataOnFilterChange.searchData['email'] = '';
            searchDataOnFilterChange.searchData['phone'] = '';
        }

        setIsFilterChange(true);
        setPaginationParams(searchDataOnFilterChange);
    };

    /**
     * function to implement debounce
     * @type {(function(...[*]=): void)|*}
     */
    //eslint-disable-next-line
    const debounceOnFilterChange = React.useCallback(
        debounce(onTableFilterChange, 400),
        []
    );

    useEffect(() => {
        getAllPatients(params.id, paginationParams, isFilterChange).then(res => {
            if (isFilterChange) {
                setIsFilterChange(false);
            }
        });
        //eslint-disable-next-line
    }, [params.id, paginationParams, getAllPatients]);

    /**
     * function to handle status change upon filter
     * @param value: selected status value
     */
    const handleStatusChange = value => {
        let data = Object.assign({}, paginationParams);
        data.searchData.active = value;

        setPaginationParams(data);
    };

    const columns = [
        {
            field: 'name',
            title: 'Name',
            filtering: true,
            defaultFilter: paginationParams.searchData['name'],
            cellStyle: {
                width: '25%',
            },
            headerStyle: {
                width: '25%',
            },
            render: ({imageUrl, name, id}) => {
                return (
                    <Box
                        className={classes.profileContainer}
                        component={Link}
                        to={{
                            pathname: `${match.path.split(':')[0]}patients/${id}`,
                            state: {
                                orgIdHistory: orgIdHistory,
                                orgNameHistory: orgNameHistory,
                                deptIdHistory: deptIdHistory,
                                deptNameHistory: deptNameHistory,
                                hcorgIdHistory: hcorgIdHistory,
                                hcorgNameHistory: hcorgNameHistory,
                            },
                        }}
                    >
                        <Box className={classes.profileInnerContainer}>
                            <ImageLoader src={imageUrl ? imageUrl : defaultUser}>
                                <img alt={id} className={classes.profileImage}/>
                                <div className={classes.profileImageError}>{id}</div>
                                <Preloader alt={id} className={classes.profileImage}/>
                            </ImageLoader>
                        </Box>

                        <Box className={classes.nameContainer}>
                            <Typography classes={{root: classes.name}}>{name}</Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'comments',
            title: 'Details',
            filtering: false,
            cellStyle: {
                width: 'auto',
            },
            headerStyle: {
                width: 'auto',
            },
        },
        {
            field: 'syncStatus',
            title: 'Last Synced At',
            filtering: false,
            cellStyle: {
                width: '15%',
                textAlign: 'center',
            },
            headerStyle: {
                width: '15%',
                textAlign: 'center',
            },
        },
        {
            field: 'activeStatus',
            title: 'Active',
            cellStyle: {
                textAlign: 'center',
                width: '10%',
            },
            headerStyle: {
                textAlign: 'center',
                width: '10%',
            },
            filtering: true,
            filterComponent: props => {
                return (
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                value={paginationParams.searchData.active}
                                onChange={e => handleStatusChange(e.target.value)}
                            >
                                <MenuItem value=''>All</MenuItem>
                                <MenuItem value='1'>Active</MenuItem>
                                <MenuItem value='0'>Blocked</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                );
            },
            render: ({active}) => {
                return (
                    <>
                        {active ? (
                            <Tooltip arrow placement='top' title='Active'>
                                <Icon className={classes.statusActive}>
                                    check_circle_outline
                                </Icon>
                            </Tooltip>
                        ) : (
                            <Tooltip arrow placement='top' title='Blocked'>
                                <Icon className={classes.statusBlocked}>block</Icon>
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <Box>
            <Box className={classes.addButtonContainer}>
                <Button
                    variant='contained'
                    color='primary'
                    startIcon={<Icon>add</Icon>}
                    disableElevation={true}
                    component={Link}
                    to={{
                        pathname: `${match.path.split(':')[0]}addPatient`,
                        state: {
                            orgIdHistory: orgIdHistory,
                            orgNameHistory: orgNameHistory,
                            deptIdHistory: deptIdHistory,
                            deptNameHistory: deptNameHistory,
                            hcorgIdHistory: hcorgIdHistory,
                            hcorgNameHistory: hcorgNameHistory,
                        },
                    }}
                    classes={{root: classes.addButton}}
                >
                    Patient
                </Button>
            </Box>

            <Box>
                <MaterialTable
                    title=''
                    columns={columns}
                    data={patients && patients.rows}
                    options={{
                        search: false,
                        filtering: true,
                        sorting: false,
                        draggable: false,
                        horizontalScroll: true,
                        pageSizeOptions: PAGE_SIZE_OPTIONS,
                        pageSize: paginationParams.rowsPerPage,
                        emptyRowsWhenPaging: false,
                    }}
                    pages={Math.ceil(
                        patients && patients.count / paginationParams.rowsPerPage
                    )}
                    totalCount={patients && patients.count}
                    page={paginationParams.page}
                    onChangeRowsPerPage={pageSize => {
                        let data = Object.assign({}, paginationParams);
                        data['rowsPerPage'] = pageSize;
                        setPaginationParams(data);
                    }}
                    onChangePage={page => {
                        let data = Object.assign({}, paginationParams);
                        data['page'] = page;
                        setPaginationParams(data);
                    }}
                    onFilterChange={(filters, filter) => {
                        debounceOnFilterChange(filters);
                    }}
                />
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        patients: state.patients.patients,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllPatients: (id, data, isHideLoader = false) => {
            return dispatch(getPatientsForDeptRequest(id, data, isHideLoader)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentPatients);
