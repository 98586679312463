import {GET_CAREGIVER_BATCHES} from './DataUtilitiesActions';

const initialState = {
    batches: null,
    batch: null,
};

/**
 * reducer function to update state based on action
 * @param state: current state of the reducer
 * @param action: action to perform on the data
 * @returns {{batches: (null|*), batch: null}|{batches: null, batch: null}}
 * @constructor
 */
const DataUtilitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CAREGIVER_BATCHES:
            return {
                ...state,
                batches: action.batches,
            };

        default:
            return state;
    }
};

export default DataUtilitiesReducer;
