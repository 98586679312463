export const GET_CASES = 'GET_CASES';
export const GET_OPEN_CASES = 'GET_OPEN_CASES';
export const GET_CLOSED_CASES = 'GET_CLOSED_CASES';
export const GET_CASE_BY_ID = 'GET_CASE_BY_ID';
export const ADD_CASE = 'ADD_CASE';
export const ADD_LOGITEM = 'ADD_LOGITEM';
export const UPDATE_CASE = 'UPDATE_CASE';
export const UPDATE_CASE_CAREGIVERS = 'UPDATE_CASE_CAREGIVERS';
export const GET_CASE_PRIORITIES = 'GET_CASE_PRIORITIES';
export const GET_CASE_STATUS = 'GET_CASE_STATUS';
export const GET_CASE_STATUS_FOR_DOCTOR = 'GET_CASE_STATUS_FOR_DOCTOR';
export const GET_CAREGIVERS_OF_DEPT = 'GET_CAREGIVERS_OF_DEPT';
export const GET_LOGITEMS_BY_CASE_ID = 'GET_LOGITEMS_BY_CASE_ID';
export const GET_LOGITEM_READ_STATUS = 'GET_LOGITEM_READ_STATUS';
export const READ_LOGITEM = 'READ_LOGITEM';
export const GET_DICOM_URL = 'GET_DICOM_URL';
export const FIND_DICOM_IMAGE = 'FIND_DICOM_IMAGE';

/**
 * action creator function to get cases
 * @param cases: cases from api response data
 * @returns {{cases, type: string}}
 */
export const getCases = cases => {
    return {
        type: GET_CASES,
        cases: cases,
    };
};

/**
 * action creator function to get open cases
 * @param openCases: openCases from api response data
 * @returns {{openCases, type: string}}
 */
export const getOpenCases = openCases => {
    return {
        type: GET_OPEN_CASES,
        openCases: openCases,
    };
};

/**
 * action creator function to get closed cases
 * @param closedCases: closedCases from api response data
 * @returns {{closedCases, type: string}}
 */
export const getClosedCases = closedCases => {
    return {
        type: GET_CLOSED_CASES,
        closedCases: closedCases,
    };
};

/**
 * action creator function to get case by id
 * @param casee: casee from api response data
 * @returns {{case, type: string}}
 */
export const getCaseById = casee => {
    return {
        type: GET_CASE_BY_ID,
        case: casee,
    };
};

/**
 * action creator function to add case
 * @param casee: casee from api response data
 * @returns {{case, type: string}}
 */
export const addCase = casee => {
    return {
        type: ADD_CASE,
        case: casee,
    };
};

/**
 * action creator function to update case
 * @param casee: casee from api response data
 * @returns {{case, type: string}}
 */
export const updateCase = casee => {
    return {
        type: UPDATE_CASE,
        case: casee,
    };
};

/**
 * action creator function to update case caregivers
 * @param caregivers: caregivers from api response data
 * @returns {{caregivers, type: string}}
 */
export const updateCaseCaregivers = caregivers => {
    return {
        type: UPDATE_CASE_CAREGIVERS,
        caregivers: caregivers,
    };
};

/**
 * action creator function to get case priorities
 * @param casePriorities: casePriorities from api response data
 * @returns {{casePriorities, type: string}}
 */
export const getCasePriorities = casePriorities => {
    return {
        type: GET_CASE_PRIORITIES,
        casePriorities: casePriorities,
    };
};

/**
 * action creator function to get case status
 * @param caseStatus: caseStatus from api response data
 * @returns {{caseStatus, type: string}}
 */
export const getCaseStatus = caseStatus => {
    return {
        type: GET_CASE_STATUS,
        caseStatus: caseStatus,
    };
};

/**
 * action creator function to get case status for healthcare provider organization
 * @param caseStatus: caseStatus from api response data
 * @returns {{caseStatus, type: string}}
 */
export const getCaseStatusForDoctor = caseStatus => {
    return {
        type: GET_CASE_STATUS_FOR_DOCTOR,
        caseStatus: caseStatus,
    };
};

/**
 * action creator function to get caregivers of department
 * @param caregivers: caregivers from api response data
 * @returns {{caregivers, type: string}}
 */
export const getCaregiversOfDept = caregivers => {
    return {
        type: GET_CAREGIVERS_OF_DEPT,
        caregivers: caregivers,
    };
};

/**
 * action creator function to get log items by case id
 * @param logItems: logItems from api response data
 * @returns {{logItems, type: string}}
 */
export const getLogItemsByCaseId = logItems => {
    return {
        type: GET_LOGITEMS_BY_CASE_ID,
        logItems: logItems,
    };
};

/**
 * action creator function to add log item
 * @param logItem: logItem from api response data
 * @returns {{logItem, type: string}}
 */
export const addLogItem = logItem => {
    return {
        type: ADD_LOGITEM,
        logItem: logItem,
    };
};

/**
 * action creator function to get log item read status
 * @param readStatus: readStatus from api response data
 * @returns {{readStatus, type: string}}
 */
export const getLogItemReadStatus = readStatus => {
    return {
        type: GET_LOGITEM_READ_STATUS,
        readStatus: readStatus,
    };
};

/**
 * action creator function to read logitem
 * @param readStatus: readStatus from api response data
 * @returns {{readStatus, type: string}}
 */
export const readLogitem = readLogitem => {
    return {
        type: READ_LOGITEM,
        readLogitem: readLogitem,
    };
};

/**
 * action creator function to get dicom url
 * @param dicomUrl: dicomUrl from api response data
 * @returns {{dicomUrl, type: string}}
 */
export const getDicomUrl = dicomUrl => {
    return {
        type: GET_DICOM_URL,
        dicomUrl: dicomUrl,
    };
};

/**
 * action creator function to find dicom image
 * @param dicomImage: dicomImage from api response data
 * @returns {{dicomImage, type: string}}
 */
export const findDicomImage = dicomImage => {
    return {
        type: FIND_DICOM_IMAGE,
        dicomImage: dicomImage,
    };
};
