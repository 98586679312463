import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        imagePreviewContainer: {
            position: 'relative',
            display: 'inline-block',
            marginRight: '10px',
        },
        imagePreviewInnerContainer: {
            position: 'absolute',
            top: '-25px',
            left: '125px',
        },
        imagePreview: {
            height: '150px',
            width: '150px',
            objectFit: 'contain',
            marginBottom: '0.5rem',
        },
        formContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1rem',
        },
        formInnerContainer: {
            display: 'flex',
        },
        errorText: {
            fontSize: '0.75rem',
            color: 'red',
            marginTop: '5px',
            marginLeft: '4px',
        },
        medicalImageButton: {
            marginLeft: '10px',
        },
        noteCancelButton: {
            marginRight: '0.5rem',
        },
        error:{
            color: 'red', 
            margin: '5px 10px'
        }
    };
});
