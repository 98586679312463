import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
} from '@material-ui/core';
import {useStyles} from './styles';

/**
 * function to render user agreement dialog
 * @param open: opens the dialog
 * @param onAgree: function to handle agree buttom click
 * @returns {JSX.Element}
 * @constructor
 */
const UserAgreement = ({open, onAgree}) => {
    const classes = useStyles();

    return (
        <Dialog maxWidth='sm' fullWidth open={open} disableBackdropClick>
            <DialogTitle>User Agreement</DialogTitle>

            <Divider/>

            <DialogContent>
                <DialogContentText>
                    <p className={classes.upperText}>
                        These terms and conditions are an agreement between you and Teleport
                        Med, LLC dba SirenMD
                    </p>
                    <p>
                        (“SirenMD” or “we”) governing your use of the SirenMD website, your
                        use of the SirenMD application through the SirenMD website and/or a
                        mobile device, together with any updates and upgrades to the
                        application, any Internet-based services related to the application,
                        and any related documentation provided by SirenMD (collectively, the
                        “Application”). BY CLICKING ON THE “AGREE” BUTTON AND/OR USING THE
                        APPLICATION, YOU INDICATE YOUR AGREEMENT WITH ALL OF THESE TERMS AND
                        CONDITIONS, INCLUDING THE BUSINESS ASSOCIATE AGREEMENT.IF YOU DO NOT
                        AGREE TO ALL OF THESE TERMS AND CONDITIONS OF USE, DO NOT PROCEED
                        AND DELETE THIS APPLICATION.
                    </p>
                    <p>
                        End User License. Subject to these terms and conditions, SirenMD
                        grants you a personal, limited, non-exclusive, non-transferable,
                        non-sublicensable license to install and use the Application on a
                        single mobile device owned and controlled by you or through the
                        SirenMD website. Limitations. SirenMD reserves all rights not
                        expressly granted herein. Additionally, you may not:
                    </p>
                    <p>
                        Modify, adapt, translate or create derivative works based upon the
                        Application; Reverse engineer, decompile, disassemble or otherwise
                        attempt to discover the source code of the Application except to the
                        extent expressly permitted to reverse engineer or decompile under
                        applicable law; Remove or alter any proprietary notices, labels or
                        marks from the Application; Use the Application on an application
                        service provider basis, a timeshared basis, as a service bureau or
                        outsourcing basis; or Use the Application for any illegal purpose.
                    </p>
                    <p>
                        Application Updates. The Application may be changed at any time at
                        SirenMD’s sole discretion without notice to you. For example,
                        SirenMD may make changes to its website at any time and/or offer
                        automatic or manual updates or upgrades to the Application from time
                        to time, but in no event shall SirenMD be required to provide to any
                        such updates or upgrades. Assistive Nature Only. The contents of the
                        Application, including the SirenMD website, (“Content”) and the
                        Application are assistive in nature. The Content and Application are
                        not intended in any manner to provide medical advice or
                        consultations, and do not provide medical advice or consultations.
                        Instead, the Content and Application establish a structure which
                        you, the health care professional (“User”), can utilize to
                        communicate to others (your “Contacts”) information you deem
                        relevant for your own patients. The Content and Application do not
                        convey health care advice and are not medical consultations.
                        Compliance with HIPAA and the Privacy and Security Rules. You agree
                        to comply with any and all applicable laws relating to patient
                        privacy, including the Health Insurance Portability and
                        Accountability Act of 1996 (“HIPAA”), and its regulations, as
                        amended by the Health Information Technology for Economic and
                        Clinical Health Act of the American Recovery and Reinvestment Act of
                        2009, Pub. L. No. 111-5, Title XIII (2009) (the “HITECH Act”; any
                        reference herein to HIPAA shall include the HITECH Act amendments
                        and any other amendments), relating to your use of the
                        Application.To the extent SirenMD is considered a “Business
                        Associate” and you are considered a “Covered Entity” under HIPAA,
                        the following terms are applicable.
                    </p>
                    <p>
                        Recital: WHEREAS, in consideration of the mutual promises and
                        covenants set forth below in this italicized provision (“Services
                        Agreement”), Covered Entity and Business Associate agree as follows
                        with regards to all Protected Health Information (“PHI”) disclosed
                        by Covered Entity to Business Associate in order to comply with
                        HIPAA:
                    </p>
                    <p>Definitions</p>
                    <p>
                        General. Capitalized terms used, but not otherwise defined, in this
                        Services Agreement shall have the meanings set forth in under the
                        HIPAA Rules, including but not limited to 45 C.F.R. §§ 160.103,
                        164.103, 164.304, 164.401 and 164.501, as currently drafted and as
                        subsequently updated, or revised. Breach Notification Rule. “Breach
                        Notification Rule” shall mean the Notification in the Case of Breach
                        of Unsecured Protected Health Information Rule as set forth in 45
                        C.F.R. Part 164, Subpart D. EDI Rule. “EDI Rule” shall mean the
                        Standards for Electronic Transactions as set forth at 45 C.F.R. §§
                        162.100 et seq. HIPAA Rules. “HIPAA Rules” shall mean the EDI,
                        Privacy, Security, Breach Notification, and Enforcement Rules at 45
                        C.F.R. Parts 160, 162 and 164. Privacy Rule. “Privacy Rule” shall
                        mean the Standards for Privacy of Individually Identifiable Health
                        Information at 45 CFR Part 160 and Part 164, Subparts A and E.
                        Security Standards. “Security Standards” means the Security
                        Standards for Protection of PHI at 45 C.F.R. Part 164, Subpart C.
                    </p>
                    <p>Obligations and Activities of Business Associate</p>
                    <p>
                        Use or Disclosure of Information. Business Associate agrees not to
                        use or further disclose PHI received from, or created for or on
                        behalf of, Covered Entity other than to perform the services
                        described in the Services Agreement, and as expressly permitted or
                        required by this Services Agreement or as Required By Law. Business
                        Associate shall not use, disclose, release, reveal, show, sell,
                        rent, lease, loan, publish or otherwise grant access to PHI in any
                        manner that is prohibited by law or regulation, or in any manner
                        that would be a violation of any law or regulation if it were to
                        have been done by Covered Entity. Mitigation. Business Associate
                        agrees to mitigate, to the extent reasonably practicable, any
                        harmful effect that is known to Business Associate of a use or
                        disclosure of PHI by Business Associate in violation of this
                        Services Agreement; provided, however, nothing in this Services
                        Agreement or these Terms and Conditions shall require Business
                        Associate to indemnify Covered Entity for any losses of any kind
                        incurred by Covered Entity resulting from an improper use or
                        disclosure of PHI. Safeguards. Business Associate shall use
                        appropriate administrative, technical and physical safeguards to
                        protect the confidentiality of PHI received from Covered Entity to
                        prevent the use or disclosure of PHI other than as provided for in
                        this Agreement. Reporting.
                    </p>
                    <p>
                        Business Associate agrees to report to Covered Entity any use or
                        disclosure of PHI in violation of the applicable HIPAA Rules or this
                        Agreement of which we become aware, including, without limitation,
                        any impermissible or improper use, disclosure, Security Incident or
                        Breach without unreasonable delay, but in any event no later than
                        thirty (30) days of discovery of such use or disclosure. In the
                        event of any such impermissible or improper use, disclosure,
                        Security Incident, Breach, or other action as described above,
                        Business Associate shall report the surrounding circumstances to
                        Covered Entity, and in the case of any Breach, the names of each
                        individual whose Unsecured PHI has been, or is reasonably believed
                        by Business Associate to have been, accessed, acquired, or disclosed
                        as a result of such Breach and any other available information
                        needed by Covered Entity to enable it to comply with its
                        notification obligations under the Breach Rule. Business Associate
                        agrees that if Covered Entity determines or has a reasonable belief
                        that Business Associate may have used, made a disclosure of or
                        permitted access to PHI in a way that is not authorized by these
                        Terms and Conditions, then Covered Entity may in its sole discretion
                        require Business Associate to: (a) promptly investigate and provide
                        a written report to Covered Entity of Business Associate’s
                        determination regarding any alleged or actual unauthorized
                        disclosure, access or use; (b) cease such practices immediately; and
                        © destroy all PHI. Business Associate shall assist Covered Entity in
                        developing and implementing a Breach response pursuant to HIPAA and
                        any other State or Federal law. If Business Associate is responsible
                        for the Breach, Business Associate shall assist Covered Entity in
                        implementing a Breach response.
                    </p>
                    <p>
                        Subcontractors and Agents. Business Associate shall ensure that any
                        agent or Subcontractor to whom it provides PHI agrees to
                        substantially the same or similar restrictions and conditions that
                        apply to Business Associate under this Services Agreement with
                        respect to such PHI in its possession in accordance with HIPAA
                        Access. Business Associate agrees to provide access, when requested
                        by Covered Entity, to PHI in such Designated Record Set in order to
                        comply with the requirements under 45 C.F.R. § 164.524. Such access
                        shall be provided by Business Associate in the time and manner
                        reasonably requested by Covered Entity or the Individual. Amendment.
                        Business Associate may modify these privacy-related terms and
                        conditions as may be required by law or regulations. The parties
                        recognize that the Secretary may issue further amendments to the
                        HIPAA Rules pursuant to the Secretary’s authority under law. Audit
                        and Inspection. Business Associate agrees to make its internal
                        practices, books, and records, including policies and procedures
                        relating to the use and disclosure of PHI, available to Covered
                        Entity or the Secretary or his or her designee for the limited
                        purposes of the Secretary determining Covered Entity’s compliance
                        with HIPAA, as requested by the Secretary or by Covered Entity, if
                        necessary for Covered Entity to respond to a request by the
                        Secretary. Documentation of Disclosures.Business Associate agrees to
                        document such disclosures of PHI and any information related to such
                        disclosures as would be required for Covered Entity to respond to a
                        request by an Individual for an accounting of disclosures of PHI by
                        Business Associate in accordance with 45 C.F.R. § 164.528.
                        Accounting. Upon request from Covered Entity or an Individual,
                        Business Associate agrees to provide information collected in
                        accordance with this Section to permit Covered Entity to respond to
                        a request by an Individual for an accounting of disclosures of PHI
                        by Business Associate in accordance with 45 C.F.R. § 164.528
                        Compliance with Privacy Rule. To the extent that Business Associate
                        is performing an obligation of Covered Entity under the Privacy
                        Rule, comply with the requirements of the Privacy Rule that apply to
                        Covered Entity in the performance of such obligation. Other Laws.
                        Business Associate understands that Covered Entity is subject to
                        State and Federal laws in addition to HIPAA governing the privacy
                        and security of PHI. Business Associate agrees to abide by all such
                        laws, whether or not fully articulated herein, and to keep the PHI
                        in the same manner and subject to the same standards as is required
                        of Covered Entity.
                    </p>
                    <p>Permitted Uses and Disclosures by Business Associate</p>
                    <p>
                        Services. Subject to the provisions of Section IV below, and except
                        as otherwise limited in this Services Agreement, Business Associate
                        may use or disclose PHI to perform functions, activities, or
                        services for, or on behalf of, Covered Entity or Business Associate
                        if such use or disclosure of PHI would not violate HIPAA or the
                        HIPAA Rules. Minimum Necessary. Business Associate shall utilize a
                        Limited Data Set, if practicable, for all uses, disclosures or
                        requests of PHI. Otherwise it shall limit the use, disclosure, or
                        request of PHI, to the “minimum necessary,” to accomplish the
                        intended purpose of such use, disclosure, or request, in accordance
                        with 45 CFR § 164.514(d) and as interpreted by HHS as guidance.
                        Business Activities. Except as otherwise limited in this Services
                        Agreement, Business Associate may use PHI for its proper management
                        and administration of Business Associate or to meet its legal
                        responsibilities.
                    </p>
                    <p>Obligations of Covered Entity</p>
                    <p>
                        Restrictions. To the extent that such limitations may affect
                        Business Associate’s use or disclosure of PHI, Covered Entity shall
                        notify Business Associate of (i) any limitations in Covered Entity’s
                        notice of privacy practices that Covered Entity produces in
                        accordance with 45 C.F.R. 164.520, as well as any changes to that
                        notice, (ii) any changes in, or revocation of, permission by
                        Individual to use or disclose PHI, and (iii) any restriction to the
                        use or disclosure of PHI that Covered Entity has agreed to in
                        accordance with 45 C.F.R. 164.522. If Covered Entity is a business
                        associate, Covered Entity shall notify Business Associate of any of
                        the events in subparagraph (i)-(iii) herein as applied to the
                        Covered Entity that provided the PHI that has been disclosed to
                        Business Associate. Requests. Covered Entity shall not request
                        Business Associate to use or disclose PHI in any manner that would
                        not be permissible under HIPAA if done by Covered Entity.
                    </p>
                    <p>Term and Termination</p>
                    <p>
                        Term. This Services Agreement shall be effective as of the date of
                        agreement to these Terms and Conditions and shall continue unless or
                        until the services are terminated with the provisions of Section V
                        (B) or the Services Agreement between the parties terminates.
                        Termination for Cause. Upon Covered Entity’s knowledge of a material
                        breach by Business Associate, Covered Entity shall either (i)
                        provide an opportunity for Business Associate to cure the breach or
                        end the violation and, if Business Associate does not cure the
                        breach or end the violation within the cure period specified in the
                        provision or if none is specified, then within ten (10) days,
                        terminate this Agreement and the Services Agreement; (ii)
                        immediately terminate this Agreement and the Services Agreement if
                        cure is not possible; or (iii) if neither termination nor cure are
                        feasible, Covered Entity shall report the violation to the
                        Secretary. Effect of Termination. Upon termination of this provision
                        or the Terms and Conditions for any reason, Business Associate shall
                        destroy all PHI received from Covered Entity. Business Associate
                        shall retain no copies of the PHI in any form.
                    </p>
                    <p>Miscellaneous</p>
                    <p>
                        Compliance with EDI Rule and Other Aspects of the HIPAA Rules. To
                        the extent applicable, Business Associate agrees that it will comply
                        with all applicable standards under the EDI Rule. Amendments. No
                        amendment to this Services Agreement shall be effective unless it is
                        in writing and signed and dated by the parties hereto or as required
                        by law or regulations. The parties recognize that the Secretary may
                        issue further amendments to the HIPAA Rules pursuant to the
                        Secretary’s authority under law. Interpretation. Construction of
                        this BAA provision shall be resolved in favor of a meaning that
                        permits both parties to comply with applicable law protecting the
                        privacy, security and confidentiality of PHI, including but not
                        limited to HIPAA and the HIPAA Rules. To the extent that any
                        provisions of this provision conflict with the provisions of any
                        other agreement or understanding between the parties, this provision
                        shall control. Waiver. No failure to exercise and no delay in
                        exercising any right, remedy or power hereunder shall operate as a
                        waiver thereof, nor shall any single or partial exercise of any
                        right, remedy or power hereunder preclude any other or further
                        exercise thereof or the exercise of any other right, remedy or power
                        provided herein or by law or in equity. No Third Party
                        Beneficiaries. Nothing express or implied in this Services Agreement
                        is intended or shall be deemed to confer upon any person other than
                        Covered Entity, Business Associate, and their respective successors
                        and assigns, as permitted pursuant to the Agreement, any rights,
                        obligations, remedies or liabilities. Assignment. No assignment of
                        this Services Agreement or the rights and obligations hereunder
                        shall be valid without the specific written consent of both parties
                        hereto. Binding Effect. This Services Agreement provision shall be
                        binding upon the parties hereto and their respective heirs,
                        executors, administrators, successors and permitted assigns.
                    </p>
                    <p>
                        DISCLAIMER OF WARRANTY. THE APPLICATION IS PROVIDED AND LICENSED TO
                        YOU “AS-IS,” WITH ALL FAULTS. TO THE EXTENT PERMITTED BY LAW,
                        SIRENMD DOES NOT WARRANT THAT THE APPLICATION WILL MEET YOUR
                        REQUIREMENTS OR WILL BE COMPATIBLE WITH YOUR MOBILE DEVICE OR THAT
                        OPERATION OF THE APPLICATION WILL BE UNINTERRUPTED OR ERROR-FREE.
                        YOU ASSUME ALL RISKS ASSOCIATED WITH YOUR USE, YOUR CONTACTS’ USE,
                        AND THE PERFORMANCE OF THE APPLICATION. EXCEPT TO THE EXTENT ANY
                        WARRANTY CANNOT OR MAY NOT BE EXCLUDED OR LIMITED BY LAW APPLICABLE
                        TO YOU IN YOUR JURISDICTION, SIRENMD MAKES NO WARRANTIES (EXPRESS OR
                        IMPLIED WHETHER BY STATUTE, COMMON LAW, CUSTOM, USAGE OR OTHERWISE)
                        AS TO ANY MATTER, INCLUDING WITHOUT LIMITATION, NON-INFRINGEMENT OF
                        THIRD PARTY RIGHTS, MERCHANTABILITY, INTEGRATION, SATISFACTORY
                        QUALITY, OR FITNESS FOR ANY PARTICULAR PURPOSE. LIMITATIONS ON
                        LIABILITY.
                    </p>
                    <p>
                        SIRENMD IS NOT LIABLE TO YOU OR YOUR CONTACTS FOR ANY LOSSES OR
                        DAMAGES ARISING FROM OR ASSOCIATED WITH YOUR USE OR MISUSE OF THE
                        CONTENT AND/OR APPLICATION, OR THE USE OR MISUSE OF THE CONTENT
                        AND/OR APPLICATION BY YOUR CONTACTS, NOR IS IT LIABLE IF YOU OR YOUR
                        CONTACTS IMPROPERLY SHARE INFORMATION USING THE CONTENT AND/OR
                        APPLICATION. IN NO EVENT SHALL SIRENMD BE LIABLE FOR ANY DAMAGES
                        (INCLUDING, WITHOUT LIMITATION, INCIDENTAL AND CONSEQUENTIAL
                        DAMAGES, PERSONAL INJURY/WRONGFUL DEATH) RESULTING FROM THE USE OF
                        THE CONTENT AND/OR APPLICATION, WHETHER BASED ON WARRANTY, CONTRACT,
                        TORT, OR ANY OTHER LEGAL THEORY. FURTHERMORE, SIRENMD IS NOT LIABLE
                        FOR ANY PERSONAL INJURY, INCLUDING DEATH, CAUSED BY YOUR USE OR
                        MISUSE OF THE APPLICATION AND/OR CONTENT, OR THE USE OR MISUSE BY
                        YOUR CONTACTS OF THE APPLICATION AND/OR CONTENT. Any claims arising
                        in connection with your use of the Content and Application must be
                        brought within one (1) year of the date of the event giving rise to
                        such action occurred. The exclusive remedy is return of the purchase
                        price for the Application. Remedies under these terms and conditions
                        are exclusive and are limited to those expressly provided for in
                        these terms and conditions.
                    </p>
                    <p>
                        Indemnification. You are liable for and agree to defend, indemnify
                        and hold harmless SirenMD and its officers, directors, employees and
                        agents against all claims and causes of action, as well as related
                        losses, liabilities, judgments, awards, settlements, damages,
                        expenses and costs (including reasonable attorney’s fees and related
                        expenses) that directly or indirectly relate to your use or misuse
                        of the Application or Content or violation of any of these terms and
                        conditions. Termination. Any failure by you to comply with these
                        terms and conditions will result in automatic and immediate
                        termination of your license. You may also terminate your license at
                        any time by uninstalling or destroying all copies of the Application
                        in your possession, custody or control. SirenMD may also suspend or
                        terminate your license, including access to the Application, at any
                        time at its sole discretion with or without notice to you. If your
                        license is terminated, you must immediately cease all use of the
                        Application, including uninstalling and destroying all copies of the
                        Application in your possession, custody or control. Export Controls.
                        The Application is subject to U.S. export laws and regulations. You
                        will comply with all applicable export controls, and will not use or
                        otherwise export or re-export the Application except as permitted by
                        the laws and regulations of the United States and any other
                        jurisdiction(s) in which the Application was obtained. By
                        downloading or using the Application, you represent and warrant that
                        you are not located in a country that is subject to a U.S. trade or
                        economic sanctions or designated by the U.S. government as a
                        “terrorist supporting” country, and that you are not listed on any
                        U.S. government list of prohibited or restricted parties. Amendment.
                        SirenMD may amend these terms and conditions at its sole discretion
                        by posting revised or additional terms on the SirenMD website or
                        within the Application. If you continue to use the Application after
                        any amendments are made, your continued use means that you accepted
                        the amendments. Severability. In the event that any portion of these
                        terms and conditions is terminated or adjudged to be void or
                        unenforceable, that portion shall be severed and the remainder of
                        the terms and conditions shall continue and shall be effective and
                        enforceable. Governing Law. The laws of the United States and the
                        State of Florida, excluding its conflicts of law rules govern these
                        terms and conditions. These terms and conditions are not governed by
                        the United Nations Convention on Contracts for the International
                        Sale of Goods, the application of which is expressly excluded.
                        Entire Agreement. These terms and conditions, including any
                        documents incorporated herein by reference, constitute the entire
                        agreement between you and SirenMD with respect to the Application
                        and there are no written or oral representations, understandings or
                        agreements that are not fully expressed herein. Open Source Notices.
                        The Application uses JSON API (Creative Commons license), Java
                        (Binary Code License), Spring Web Flow (Apache License), Apache
                        Mavin (Apache License), Logback API (EPL and GNU LGPL license), and
                        Common file upload API (Apache License) software.
                    </p>
                    <p className={classes.bottomText}>
                        BY CLICKING ON THE “AGREE” BUTTON AND/OR USING THE APPLICATION, YOU
                        INDICATE YOUR AGREEMENT WITH ALL OF THESE TERMS AND CONDITIONS,
                        INCLUDING THE BUSINESS ASSOCIATE AGREEMENT. IF YOU DO NOT AGREE TO
                        ALL OF THESE TERMS AND CONDITIONS OF USE, DO NOT PROCEED AND DELETE
                        THIS APPLICATION.
                    </p>
                </DialogContentText>
            </DialogContent>

            <DialogActions classes={{root: classes.actionsContainer}}>
                <Button
                    disableElevation
                    variant='contained'
                    onClick={onAgree}
                    color='primary'
                >
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserAgreement;
