import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import MaterialTable from 'material-table';
import {Box, Typography} from '@material-ui/core';
import {PAGE_SIZE, PAGE_SIZE_OPTIONS} from '../../../../utilities/constants';
import {getAthletesForDeptRequest} from '../../PlayStatusApiActions';
import {useStyles} from './styles';
import defaultUser from '../../../../assets/user.png';
import {debounce} from '../../../../utilities/utilityFunctions';
import ImageLoader from 'react-load-image';
import Preloader from '../../../../utilities/components/Preloader/Preloader';

/**
 * function to render team roster
 * @param getAthletesForDept: function to get athletes of department
 * @param athletes: athletes list
 * @param selectedDept: selected department
 * @returns {JSX.Element}
 * @constructor
 */
const PlayerStatus = ({getAthletesForDept, athletes, selectedDept}) => {
    const classes = useStyles();
    const [paginationParams, setPaginationParams] = useState({
        page: 0,
        rowsPerPage: PAGE_SIZE,
        searchData: {
            name: '',
        },
    });
    const [isFilterChange, setIsFilterChange] = useState(false);

    /**
     * function to handle table filter search
     * @param filters: filters from filter input to search
     */
    const onTableFilterChange = filters => {
        let searchDataOnFilterChange = Object.assign({}, paginationParams);

        if (filters.length > 0) {
            let obj = {};
            filters.forEach(filter => {
                obj[filter.column.field] = filter.value;
            });

            Object.keys(searchDataOnFilterChange.searchData).forEach(function (key) {
                if (key !== 'active') {
                    if (obj[key]) {
                        searchDataOnFilterChange.searchData[key] = obj[key];
                    } else {
                        searchDataOnFilterChange.searchData[key] = '';
                    }
                }
            });

            filters.forEach(filter => {
                searchDataOnFilterChange.searchData[filter.column.field] = filter.value;
            });
        } else {
            searchDataOnFilterChange.searchData['name'] = '';
            searchDataOnFilterChange.searchData['email'] = '';
            searchDataOnFilterChange.searchData['phone'] = '';
        }

        setIsFilterChange(true);
        setPaginationParams(searchDataOnFilterChange);
    };

    /**
     * function to implement debounce
     * @type {(function(...[*]=): void)|*}
     */
    //eslint-disable-next-line
    const debounceOnFilterChange = React.useCallback(
        debounce(onTableFilterChange, 400),
        []
    );

    useEffect(() => {
        if (selectedDept) {
            getAthletesForDept(
                selectedDept.team_id,
                paginationParams,
                isFilterChange
            ).then(res => {
                if (isFilterChange) {
                    setIsFilterChange(false);
                }
            });
        }
        //eslint-disable-next-line
    }, [getAthletesForDept, selectedDept, paginationParams]);

    const columns = [
        {
            field: 'name',
            title: 'Player',
            filtering: true,
            defaultFilter: paginationParams.searchData['name'],
            cellStyle: {
                width: '60%',
            },
            headerStyle: {
                width: '60%',
            },
            render: ({imageUrl, name, id}) => {
                return (
                    <Box className={classes.profileContainer}>
                        <Box className={classes.profileInnerContainer}>
                            <ImageLoader src={imageUrl ? imageUrl : defaultUser}>
                                <img alt={id} className={classes.profileImage}/>
                                <div className={classes.profileImageError}>{id}</div>
                                <Preloader alt={id} className={classes.profileImage}/>
                            </ImageLoader>
                        </Box>

                        <Box className={classes.nameContainer}>
                            <Typography classes={{root: classes.name}}>{name}</Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'playingStatus',
            title: 'Status',
            filtering: false,
            cellStyle: {
                width: '30%',
            },
            headerStyle: {
                width: '30%',
            },
            render: ({playingStatus}) => {
                if (playingStatus === true) {
                    return (
                        <Box className={classes.statusPlaying}>
                            <Typography classes={{root: classes.statusLabel}}>
                                Playing
                            </Typography>
                        </Box>
                    );
                }

                if (playingStatus === false) {
                    return (
                        <Box className={classes.statusNotPlaying}>
                            <Typography classes={{root: classes.statusLabel}}>
                                Not Playing
                            </Typography>
                        </Box>
                    );
                }
            },
        },
    ];

    return (
        <Box className={classes.container}>
            <Box className={classes.tableCotainer}>
                <MaterialTable
                    columns={columns}
                    data={athletes && athletes.rows ? athletes.rows : []}
                    options={{
                        toolbar: false,
                        search: false,
                        filtering: true,
                        sorting: false,
                        draggable: false,
                        horizontalScroll: true,
                        pageSizeOptions: PAGE_SIZE_OPTIONS,
                        pageSize: paginationParams.rowsPerPage,
                        emptyRowsWhenPaging: false,
                        headerStyle: {fontWeight: 'bold'},
                    }}
                    pages={Math.ceil(
                        athletes && athletes.count / paginationParams.rowsPerPage
                    )}
                    totalCount={athletes && athletes.count}
                    page={paginationParams.page}
                    onChangeRowsPerPage={pageSize => {
                        let data = Object.assign({}, paginationParams);
                        data['rowsPerPage'] = pageSize;
                        setPaginationParams(data);
                    }}
                    onChangePage={page => {
                        let data = Object.assign({}, paginationParams);
                        data['page'] = page;
                        setPaginationParams(data);
                    }}
                    onFilterChange={(filters, filter) => {
                        debounceOnFilterChange(filters);
                    }}
                    style={{width: '600px', margin: 'auto'}}
                />
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        selectedDept: state.sirenUsers.selectedDept,
        athletes: state.teamRoster.athletes,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAthletesForDept: (id, data, isHideLoader = false) => {
            return dispatch(getAthletesForDeptRequest(id, data, isHideLoader)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerStatus);
