import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        contentConatiner: {
            paddingTop: '1rem',
        },
        actionsContainer: {
            padding: '10px 22px',
            paddingBottom: '1rem',
        },
    };
});
