import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {useStyles} from './styles';

/**
 * function to render password input dialog
 * @param title: title of the dialog
 * @param message: message in the dialog
 * @param password: password value
 * @param setPassword: function to set password
 * @param open: opens the dialog
 * @param handleClose: function to handle closing of dialog
 * @param handleSubmit: function to handle dialog submit
 * @returns {JSX.Element}
 * @constructor
 */
const PasswordInput = ({
                           title,
                           message,
                           password,
                           setPassword,
                           open,
                           handleClose,
                           handleSubmit,
                       }) => {
    const classes = useStyles();

    return (
        <Dialog maxWidth='sm' fullWidth open={open} disableBackdropClick>
            <ValidatorForm onSubmit={handleSubmit}>
                <DialogTitle classes={{root: classes.titleContainer}}>
                    {title}
                </DialogTitle>

                <Divider/>

                <DialogContentText classes={{root: classes.messageText}}>
                    {message}
                </DialogContentText>

                <DialogContent classes={{root: classes.contentContainer}}>
                    <TextValidator
                        variant='outlined'
                        fullWidth
                        size='small'
                        label='Password'
                        type='password'
                        validators={['required']}
                        errorMessages={['Password is required']}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </DialogContent>

                <DialogActions classes={{root: classes.actionsContainer}}>
                    <Button
                        disableElevation
                        variant='outlined'
                        size='small'
                        onClick={handleClose}
                        color='primary'
                    >
                        Cancel
                    </Button>
                    <Button
                        disableElevation
                        variant='contained'
                        size='small'
                        type='submit'
                        color='primary'
                    >
                        Submit
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
};

export default PasswordInput;
