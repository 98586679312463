import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        noContentContainer: {
            textAlign: 'center',
            marginTop: '5rem',
            width: '100%',
        },
        noLabel: {
            fontSize: '1.5rem',
            opacity: '0.2',
        },
        tabPanel: {
            paddingTop: '0rem',
        },
        tabListContainer: {
            marginTop: '2rem',
        },
        container: {
            paddingBottom: '4rem',
        },
        actionsContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0.6rem',
            position: 'sticky',
            top: '78px',
            zIndex: '1000',
            paddingRight: '1rem',
            paddingLeft: '1rem',
        },
        addCaseButton: {
            textTransform: 'capitalize',
        },
        reportButton: {
            textTransform: 'capitalize',
            marginLeft: '0.5rem',
        },
        profileContainer: {
            display: 'flex',
            paddingLeft: '1rem',
        },
        profileImage: {
            height: '80px',
            width: '80px',
            borderRadius: '100%',
            border: '2px solid #ddd',
            padding: '5px',
        },
        critical: {
            height: '80px',
            width: '80px',
            borderRadius: '100%',
            border: '2px solid red',
            padding: '5px',
        },
        profileInfoContainer: {
            marginLeft: '30px',
        },
        caseInfoContainer: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '8px',
            justifyContent: 'space-between',
        },
        name: {
            fontSize: '1.5rem',
        },
        caseNumberConatiner: {
            marginLeft: '3.1rem',
        },
        caseNumber: {
            fontSize: '21px',
            color: '#9b9b9b',
        },
        editBoxContainer: {
            marginBottom: '15px',
        },
        editBoxButtonContainer: {
            display: 'flex',
            marginTop: '5px',
        },
        editBoxSaveButton: {
            marginLeft: '0.5rem',
        },
        descLabel: {
            fontSize: '15px',
            fontweight: '700',
        },
        descText: {
            fontSize: '15px',
        },
        descEditButton: {
            marginTop: '15px',
        },
        createdByContainer: {
            marginTop: '15px',
            marginBottom: '15px',
        },
        createdByText: {
            fontSize: '15px',
            fontStyle: 'italic',
        },
        optionsContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        optionAvatar: {
            marginRight: '12px',
        },
        optionText: {
            marginRight: '5px',
        },
        topButtonContainer: {
            position: 'absolute',
            top: '1rem',
            left: '1rem',
            right: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
        },
        backButton: {
            textTransform: 'capitalize',
        },
        buttonsInnerContainer: {
            display: 'flex',
        },
        selectContainer: {
            width: '150px',
        },
        criticalContainer: {
            marginLeft: '15px',
        },
        topDivider: {
            position: 'absolute',
            top: '4rem',
            width: '100%',
        },
        timelineDivider: {
            marginTop: '15px',
            marginBottom: '20px',
        },
        sortButtonsContainer: {
            position: 'absolute',
            right: '1rem',
            top: '0px',
        },
        sortButton: {
            width: '70px',
        },
        addNoteHeadingContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '1.2rem',
            marginLeft: '1rem',
            marginBottom: '0.5rem',
        },
        addNoteContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        addNoteLabel: {
            marginRight: '0.5rem',
            fontSize: '1.2rem',
            fontWeight: '400',
            marginLeft: '0.2rem',
        },
        toggleButtonsContainer: {
            marginRight: '1rem',
        },
        toggleBase: {
            color: 'red!important',
        },
        toggleTrack: {
            backgroundColor: 'red!important',
        },
        toggleThumb: {
            color: 'red!important',
        }
    };
});
