import React, {useState} from 'react';
import {connect} from 'react-redux';
import ModalImage from 'react-modal-image';
import {Avatar, Box, Paper, Typography} from '@material-ui/core';
import VisibilitySensor from 'react-visibility-sensor-v2';
import {TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator,} from '@material-ui/lab';
import Tippy from '@tippyjs/react';
import ReadByLog from '../ReadStatus/ReadStatus';
import {useStyles} from './styles';
import {readLogItemRequest} from '../../../../CasesApiAction';
import dicomThumb from '../../../../../../assets/dicom.jpg';
import DicomViewer from '../DicomViewer/DicomViewer';

/**
 * function to render note item
 * @param note: note item details
 * @param readLogItem: function to read logitem
 * @param caseId: case id
 * @param sortCategory: sort category
 * @returns {JSX.Element}
 * @constructor
 */
const NoteTimeline = ({note, readLogItem, caseId, sortCategory}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openDicomViewer, setOpenDicomViewer] = useState(false);
    const [dicomUrl, setDicomUrl] = useState('');

    /**
     * function to read logitem
     * @param noteId: note id
     * @param isRead: boolean for is read
     * @param isVisible: boolean for is visible
     */
    const handleLogitmeRead = (noteId, isRead, isVisible) => {
        if (isVisible && !isRead) {
            const data = {
                logIds: [noteId],
                caseId: caseId,
            };
            readLogItem(data).then(res => {
            });
        }
    };

    /**
     * function to get read status color code
     * @returns {String}
     */
    const getReadStatusColorCode = () => {
        const readPercentage = (note.readUsers / note.totalUsers) * 100;
        if (readPercentage <= 50) {
            return 'red';
        } else if (readPercentage > 50 && readPercentage < 100) {
            return 'orange';
        } else if (readPercentage === 100) {
            return 'green';
        }
    };

    return (
        <>
            <TimelineItem
                classes={{
                    missingOppositeContent: classes.missingOppositeContent,
                }}
            >
                <TimelineSeparator>
                    <TimelineDot classes={{root: classes.timelineDot}}>
                        <Tippy content={`${note.User.firstname} ${note.User.lastname}`}>
                            <Avatar
                                src={note.User.imageUrl}
                                className={classes.timelineAvatar}
                            />
                        </Tippy>
                    </TimelineDot>
                    <TimelineConnector/>
                </TimelineSeparator>
                
                <TimelineContent classes={{root: classes.timelineContent}}>
                    <Box
                        component={Paper}
                        className={classes.timelineContentInnerContainer}
                    >
                        <Box>
                            <Typography classes={{root: classes.name}}>
                                {note.User.firstname} {note.User.lastname}
                            </Typography>
                        </Box>

                        <VisibilitySensor
                            onChange={isVisible => {
                                if (note.read_receipt_status === 2) {
                                    handleLogitmeRead(note.id, note.isCurrentUserRead, isVisible);
                                } else {

                                }
                            }}
                        >
                            <Box>
                                <Box>
                                    <Typography classes={{root: classes.description}}>
                                        {note.text}
                                    </Typography>
                                </Box>

                                {note.dicomUrls.url &&
                                note.dicomUrls.url.map((item, index) => {
                                    return (
                                        <Box key={index} className={classes.timeplineImage}>
                                            <img
                                                onClick={() => {
                                                    if (item) {
                                                        setDicomUrl(item.redirect_url);
                                                    } else {
                                                        setDicomUrl('');
                                                    }
                                                    setOpenDicomViewer(true);
                                                }}
                                                className={classes.dicomImage}
                                                src={dicomThumb}
                                                alt='dicom'
                                            />
                                        </Box>
                                    );
                                })}

                                {note.mediaUrls.map((noteImage, index) => (
                                    <Box key={index} className={classes.timeplineImage}>
                                        <ModalImage
                                            className={classes.modalImage}
                                            small={noteImage.url}
                                            medium={noteImage.url}
                                            large={noteImage.url}
                                            hideDownload={true}
                                            hideZoom={true}
                                            showRotate={true}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </VisibilitySensor>

                        <Box className={classes.timelineFooter}>
                            {note.read_receipt_status !== 1 && (
                                <Box className={classes.readStatusContainer}>
                                    <Typography className={classes.readBy}>
                                        {note.totalUsers === 0 && (
                                            <span className={classes.green}>
                        No active caregiver to read
                      </span>
                                        )}

                                        {note.totalUsers > 0 ? (
                                            <span
                                                className={`${classes.readByClick} ${
                                                    getReadStatusColorCode() === 'green'
                                                        ? classes.green
                                                        : getReadStatusColorCode() === 'red'
                                                        ? classes.red
                                                        : getReadStatusColorCode() === 'orange'
                                                            ? classes.orange
                                                            : ''
                                                } `}
                                                onClick={() => setOpen(true)}
                                            >
                        Read by {note.readUsers} of {note.totalUsers} caregivers
                      </span>
                                        ) : (
                                            <></>
                                        )}
                                    </Typography>
                                </Box>
                            )}

                            <Box className={classes.createdAtContainer}>
                                <Typography className={classes.nameDate}>
                                    {note.createdAt}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </TimelineContent>
            </TimelineItem>

            {open && (
                <ReadByLog
                    open={open}
                    setOpen={setOpen}
                    noteId={note.id}
                    createdBy={`${note.User.firstname} ${note.User.lastname}`}
                    time={note.createdAt}
                    caseId={caseId}
                    sortCategory={sortCategory}
                />
            )}

            <DicomViewer
                open={openDicomViewer}
                setOpen={setOpenDicomViewer}
                dicomUrl={dicomUrl}
            />
        </>
    );
};

const mapStateToProps = () => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        readLogItem: data => {
            return dispatch(readLogItemRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteTimeline);
