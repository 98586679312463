import axios from 'axios';
import {getAccessToken} from './storage';

/**
 * class to handle http request
 */
class HttpRequest {
    constructor(dispatch, url, method = 'get', data = {}, headers = {}, options) {
        this.dispatch = dispatch;
        this.url = url;
        this.method = method;
        this.data = data;
        this.headers = headers;
        this.options = options;
    }

    /**
     * method to handle basic ('Content-Type': 'application/json') http request
     * @returns {Promise<unknown>}
     */
    send = () => {
        let headers = {...this.headers, 'Content-Type': 'application/json'};
        const token = getAccessToken();

        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        return new Promise((resolve, reject) => {
            axios({
                baseURL: process.env.REACT_APP_SERVER_API_URL,
                url: this.url,
                method: this.method,
                headers: headers,
                data: JSON.stringify(this.data),
            })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    };

    /**
     * method to handle s3 upload ('Content-Type': 'multipart/form-data') http request
     * @returns {Promise<unknown>}
     */
    s3Upload = () => {
        let headers = {...this.headers, 'Content-Type': 'multipart/form-data'};

        return new Promise((resolve, reject) => {
            axios({
                withCredentials: true,
                url: this.url,
                method: this.method,
                headers: headers,
                data: this.data,
            })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    };

    /**
     * method to handle download ('Content-Type': 'application/json') http request
     * @returns {Promise<unknown>}
     */
    download = () => {
        let headers = { ...this.headers, 'Content-Type': 'application/json' };

        const token = getAccessToken();

        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
    
        return new Promise((resolve, reject) => {
          axios({
            baseURL: process.env.REACT_APP_SERVER_API_URL,
            url: this.url,
            method: this.method,
            headers: headers,
            data: JSON.stringify(this.data),
            responseType: 'blob',
          })
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        });
      };
}

export default HttpRequest;
