import React from 'react';
import {connect} from 'react-redux';
import {Backdrop, CircularProgress} from '@material-ui/core';
import {useStyles} from './styles';

/**
 * function to render app loader
 * @param showLoader: boolean to show loader 
 * @returns {JSX.Element}
 * @constructor
 */
const AppLoader = ({showLoader}) => {
    const classes = useStyles();
    return (
        <Backdrop open={showLoader} className={classes.container}>
            <CircularProgress color='inherit'/>
        </Backdrop>
    );
};

const mapStateToProps = state => {
    return {
        showLoader: state.utilities.showLoader,
    };
};

export default connect(mapStateToProps)(AppLoader);
