import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        container: {
            paddingBottom: '3rem',
        },
        tableCotainer: {
            margin: '1.5rem',
        },
        statusPlaying: {
            backgroundColor: 'green',
            color: 'white',
            textAlign: 'center',
            padding: '5px 0px',
        },
        statusNotPlaying: {
            backgroundColor: 'red',
            color: 'white',
            textAlign: 'center',
            padding: '5px 0px',
        },
        statusLabel: {
            fontSize: '14px',
        },
        profileContainer: {
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
        },
        profileInnerContainer: {
            height: '40px',
            width: '40px',
        },
        profileImageError: {
            height: '40px',
            width: '40px',
            borderRadius: '100%',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            textAlign: 'center',
        },
        profileImage: {
            height: '40px',
            width: '40px',
            borderRadius: '100%',
            border: '1px solid rgba(0, 0, 0, 0.2)',
        },
        nameContainer: {
            marginLeft: '0.5rem',
        },
        name: {
            fontSize: '1rem',
        },
    };
});
