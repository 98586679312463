import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        closeButton: {
            marginTop: '5px',
            marginBottom: '-7px',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        contentContainer: {
            marginBottom: '1rem',
        },
        upperTextContainer: {
            marginBottom: '1.5rem',
            marginTop: '0.5rem',
        },
        caregiverItemContainer: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '0.8rem',
        },
        profileImage: {
            height: '50px',
            width: '50px',
            borderRadius: '100%',
            marginRight: '0.5rem',
            border: '1px solid rgba(0, 0, 0, 0.2)',
        },
        name: {
            fontSize: '1.1rem',
            fontWeight: 'bold',
            marginRight: '0.5rem',
        },
        green: {
            color: 'green',
        },
        red: {
            color: 'red',
        },
    };
});
