export const SIGN_IN = 'SIGN_IN';
export const UPDATE_LOGGED_IN_USER = 'UPDATE_LOGGED_IN_USER';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const SET_PASSWORD = 'SET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const ACCEPT_AGREEMENT = 'ACCEPT_AGREEMENT';

/**
 * action creator function to sign in
 * @param user: user from api response data
 * @returns {{type: string, user}}: type and user to dispatch to reducer
 */
export const signIn = user => {
    return {
        type: SIGN_IN,
        user: user,
    };
};

/**
 * action creator function for forgot password
 * @returns {{type: string}}: type to dispatch to reducer
 */
export const forgetPassword = () => {
    return {
        type: FORGET_PASSWORD,
    };
};

/**
 * action creator function to set password on first time
 * @returns {{type: string}}: type to dispatch to reducer
 */
export const setPassword = () => {
    return {
        type: SET_PASSWORD,
    };
};

/**
 * action creator function for change password
 * @returns {{type: string}}: type to dispatch to reducer
 */
export const changePassword = () => {
    return {
        type: CHANGE_PASSWORD,
    };
};

/**
 * action creator function update logged in user
 * @param user: user from api response data
 * @returns {{type: string, user}}
 */
export const updateLoggedInUser = user => {
    return {
        type: UPDATE_LOGGED_IN_USER,
        user: user,
    };
};

/**
 * action creator function to accept agreement
 * @param agreement: agreement from api response data
 * @returns {{agreement, type: string}}
 */
export const acceptAgreement = agreement => {
    return {
        type: ACCEPT_AGREEMENT,
        agreement: agreement,
    };
};
