import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import MaterialTable from 'material-table';
import {PAGE_SIZE, PAGE_SIZE_OPTIONS} from '../../../../utilities/constants';
import {getCaregiversForOrgRequest} from '../../CaregiverApiActions';
import {useStyles} from './styles';
import {getDeptsForOrgWithoutParamsRequest} from '../../../Departments/DepartmentsApiActions';
import defaultUser from '../../../../assets/user.png';
import {debounce} from '../../../../utilities/utilityFunctions';
import ImageLoader from 'react-load-image';
import Preloader from '../../../../utilities/components/Preloader/Preloader';
import {Box, Button, FormControl, Icon, MenuItem, Select, Typography,} from '@material-ui/core';
import ActiveStatusIcon from '../../../../utilities/components/ActiveStatusIcon/ActiveStatusIcon';

/**
 * function to render organization caregivers list
 * @param getCaregiversForOrg: function to get caregivers of organization
 * @param caregivers: caregivers list
 * @param getDeptsForOrgWithoutParams: function to get departments of organization
 * @param depts: departments list
 * @param orgIdHistory: organization id
 * @param orgNameHistory: organization name
 * @param hcorgIdHistory: healthcare organization id
 * @param hcorgNameHistory: healthcare organization name
 * @returns {JSX.Element}
 * @constructor
 */
const OrganizationCaregivers = ({
                                    getCaregiversForOrg,
                                    caregivers,
                                    getDeptsForOrgWithoutParams,
                                    depts,
                                    orgIdHistory,
                                    orgNameHistory,
                                    hcorgIdHistory,
                                    hcorgNameHistory,
                                }) => {
    const classes = useStyles();
    const params = useParams();
    const match = useRouteMatch();
    const [paginationParams, setPaginationParams] = useState({
        page: 0,
        rowsPerPage: PAGE_SIZE,
        searchData: {
            name: '',
            email: '',
            phone: '',
            active: '',
        },
    });
    const [isFilterChange, setIsFilterChange] = useState(false);

    /**
     * function to handle table filter search
     * @param filters: filters from filter input to search
     */
    const onTableFilterChange = filters => {
        let searchDataOnFilterChange = Object.assign({}, paginationParams);

        if (filters.length > 0) {
            let obj = {};
            filters.forEach(filter => {
                obj[filter.column.field] = filter.value;
            });

            Object.keys(searchDataOnFilterChange.searchData).forEach(function (key) {
                if (key !== 'active') {
                    if (obj[key]) {
                        searchDataOnFilterChange.searchData[key] = obj[key];
                    } else {
                        searchDataOnFilterChange.searchData[key] = '';
                    }
                }
            });

            filters.forEach(filter => {
                searchDataOnFilterChange.searchData[filter.column.field] = filter.value;
            });
        } else {
            searchDataOnFilterChange.searchData['name'] = '';
            searchDataOnFilterChange.searchData['email'] = '';
            searchDataOnFilterChange.searchData['phone'] = '';
        }

        setIsFilterChange(true);
        setPaginationParams(searchDataOnFilterChange);
    };

    /**
     * function to implement debounce
     * @type {(function(...[*]=): void)|*}
     */
    //eslint-disable-next-line
    const debounceOnFilterChange = React.useCallback(
        debounce(onTableFilterChange, 400),
        []
    );

    useEffect(() => {
        getDeptsForOrgWithoutParams(params.id).then(res => {
        });
    }, [params.id, getDeptsForOrgWithoutParams]);

    useEffect(() => {
        getCaregiversForOrg(params.id, paginationParams, isFilterChange).then(
            res => {
                if (isFilterChange) {
                    setIsFilterChange(false);
                }
            }
        );
        //eslint-disable-next-line
    }, [getCaregiversForOrg, params.id, paginationParams]);

    /**
     * function to handle status change upon filter
     * @param value: selected status value
     */
    const handleStatusChange = value => {
        let data = Object.assign({}, paginationParams);
        data.searchData.active = value;

        setPaginationParams(data);
    };

    const columns = [
        {
            field: 'name',
            title: 'Name',
            filtering: true,
            defaultFilter: paginationParams.searchData['name'],
            cellStyle: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            },
            render: ({imageUrl, name, userId}) => {
                return (
                    <Box
                        className={classes.profileContainer}
                        component={Link}
                        to={{
                            pathname: `${match.path.split(':')[0]}caregivers/${userId}`,
                            state: {
                                orgIdHistory: orgIdHistory,
                                orgNameHistory: orgNameHistory,
                                hcorgIdHistory: hcorgIdHistory,
                                hcorgNameHistory: hcorgNameHistory,
                            },
                        }}
                    >
                        <Box className={classes.profileInnerContainer}>
                            <ImageLoader src={imageUrl ? imageUrl : defaultUser}>
                                <img alt={userId} className={classes.profileImage}/>
                                <div className={classes.profileImageError}>{userId}</div>
                                <Preloader alt={userId} className={classes.profileImage}/>
                            </ImageLoader>
                        </Box>

                        <Box className={classes.nameContainer}>
                            <Typography classes={{root: classes.name}}>{name}</Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'email',
            title: 'Email',
            filtering: true,
            defaultFilter: paginationParams.searchData['email'],
            cellStyle: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            },
        },
        {
            field: 'phone',
            title: 'Phone',
            filtering: true,
            defaultFilter: paginationParams.searchData['phone'],
            cellStyle: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            },
        },
        {
            field: 'role',
            title: 'Role/Department',
            filtering: false,
            cellStyle: {
                width: '15%',
            },
            headerStyle: {
                width: '15%',
            },
            render: ({role, teams}) => {
                return (
                    <>
                        <div className={classes.roleLabel}>{role}</div>
                        {teams &&
                        teams.map((team, index) => (
                            <div key={index} className={classes.deptLabel}>
                                {team}
                            </div>
                        ))}
                    </>
                );
            },
        },
        {
            field: 'enabled',
            title: 'Active Status',
            filtering: true,
            filterComponent: () => {
                return (
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                value={paginationParams.searchData.active}
                                onChange={e => handleStatusChange(e.target.value)}
                            >
                                <MenuItem value=''>All</MenuItem>
                                <MenuItem value='1'>Active</MenuItem>
                                <MenuItem value='0'>Blocked</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                );
            },
            cellStyle: {
                width: '10%',
                textAlign: 'center',
            },
            headerStyle: {
                width: '10%',
                textAlign: 'center',
            },
            render: ({enabled, isBanned}) => {
                return (
                    <ActiveStatusIcon enabled={enabled} isBanned={isBanned} isLabel={false} />
                );
            },
        },
    ];

    return (
        <Box>
            <Box className={classes.addButtonContainer}>
                <Button
                    disabled={depts.count === 0}
                    variant='contained'
                    color='primary'
                    startIcon={<Icon>add</Icon>}
                    disableElevation={true}
                    component={Link}
                    to={{
                        pathname: `${match.path.split(':')[0]}addCaregiver`,
                        state: {
                            orgIdHistory: orgIdHistory,
                            orgNameHistory: orgNameHistory,
                            hcorgIdHistory: hcorgIdHistory,
                            hcorgNameHistory: hcorgNameHistory,
                        },
                    }}
                    classes={{root: classes.addButton}}
                >
                    Caregiver
                </Button>
            </Box>

            <MaterialTable
                title=''
                columns={columns}
                data={caregivers.rows}
                options={{
                    search: false,
                    filtering: true,
                    sorting: false,
                    draggable: false,
                    horizontalScroll: true,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    pageSize: paginationParams.rowsPerPage,
                    emptyRowsWhenPaging: false,
                }}
                pages={Math.ceil(caregivers.count / paginationParams.rowsPerPage)}
                totalCount={caregivers.count}
                page={paginationParams.page}
                onChangeRowsPerPage={pageSize => {
                    let data = Object.assign({}, paginationParams);
                    data['rowsPerPage'] = pageSize;
                    setPaginationParams(data);
                }}
                onChangePage={page => {
                    let data = Object.assign({}, paginationParams);
                    data['page'] = page;
                    setPaginationParams(data);
                }}
                onFilterChange={(filters, filter) => {
                    debounceOnFilterChange(filters);
                }}
            />
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        caregivers: state.caregivers.caregivers,
        depts: state.departments.depts,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCaregiversForOrg: (orgId, data, isHideLoader = false) => {
            return dispatch(
                getCaregiversForOrgRequest(orgId, data, isHideLoader)
            ).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getDeptsForOrgWithoutParams: orgId => {
            return dispatch(getDeptsForOrgWithoutParamsRequest(orgId)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganizationCaregivers);
