import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        inputContainer: {
            marginBottom: '0.5rem',
        },
        actionsContainer: {
            marginRight: '1rem',
            marginBottom: '0.5rem',
        },
        addButton:{
            marginLeft: '0.5rem!important'
        }
    };
});
