import HttpRequest from '../../utilities/httpRequest';
import {
    addSirenUser,
    getAssignedOrgs,
    getRoles,
    getSirenUsers,
    getSirenUsersWithoutParams,
    getStates,
    getTimezones,
    getUserById,
    getUserDepts,
    getUsersForAdmin,
    refreshToken,
    toggleActivation,
    toggleEmailUpdate,
    updateUser,
} from './UsersActions';

import {hideLoader, showLoader, showSnackbar,} from '../../utilities/UtilitiesActions';

/**
 * function to get users for SirenMD admin users
 * @param data: data for api request
 * @param isHideLoader: isHideLoader for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getSirenUsersRequest = (data, isHideLoader) => {
    let offset = data.page * data.rowsPerPage;
    let limit = data.rowsPerPage;
    let searchData = data.searchData ? data.searchData : '';

    return dispatch => {
        if (!isHideLoader) {
            dispatch(showLoader());
        }

        const url = `users?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
            searchData
        )}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                let resData = res.data.data;
                resData.rows = resData.rows.map(user => {
                    user.name = `${user.firstName} ${user.lastName}`;
                    return user;
                });
                dispatch(getSirenUsers(resData));

                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.resolve(resData);
            },
            error => {
                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get users for admin users
 * @param data: data for api request
 * @param isHideLoader: isHideLoader for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getUsersForAdminRequest = (data, isHideLoader) => {
    let offset = data.page * data.rowsPerPage;
    let limit = data.rowsPerPage;
    let searchData = data.searchData ? data.searchData : '';

    return dispatch => {
        if (!isHideLoader) {
            dispatch(showLoader());
        }

        const url = `users/admin?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
            searchData
        )}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                let resData = res.data.data;
                resData.rows = resData.rows.map(user => {
                    user.name = `${user.firstName} ${user.lastName}`;
                    return user;
                });

                dispatch(getUsersForAdmin(resData));

                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.resolve(resData);
            },
            error => {
                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get users without params
 * @returns {function(*=): Promise<unknown>}
 */
export const getSirenUsersWithoutParamsRequest = () => {
    return dispatch => {
        dispatch(showLoader());
        const url = `users`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(getSirenUsersWithoutParams(res.data.data));
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to add user
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const addSirenUserRequest = data => {
    return dispatch => {
        dispatch(showLoader());

        const url = 'users';

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('SirenMD Admin Added', 'success'));

                dispatch(addSirenUser(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to update user
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const updateUserRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `users/${id}`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('User updated successfully.', 'success'));
                dispatch(updateUser(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get user by id
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getUserByIdRequest = id => {
    return dispatch => {
        dispatch(showLoader());
        const url = `users/${id}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getUserById(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get roles
 * @returns {function(*=): Promise<unknown>}
 */
export const getRolesRequest = () => {
    return dispatch => {
        dispatch(showLoader());
        const url = `auth/roles`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getRoles(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to toggle user activation
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const toggleActivationRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `users/${id}/activateOrDeactivateAccount`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(toggleActivation(res.data.data));
                dispatch(showSnackbar('Active status updated successfully.', 'success'));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get user departments
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getUserDeptsRequest = id => {
    return dispatch => {
        dispatch(showLoader());
        const url = `users/singleUser/${id}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getUserDepts(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get states
 * @returns {function(*=): Promise<unknown>}
 */
export const getStatesRequest = () => {
    return dispatch => {
        dispatch(showLoader());

        const url = `state`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getStates(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get timezones
 * @returns {function(*=): Promise<unknown>}
 */
export const getTimezonesRequest = () => {
    return dispatch => {
        dispatch(showLoader());

        const url = `timezone`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getTimezones(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to resend invitation
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const resendInvitationRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = `resendInvitation`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(showSnackbar('Reset password email sent.', 'success'));
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            })
            .catch(error => {
                dispatch(showSnackbar('Reset password email sent error.', 'error'));
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};

/**
 * function to get assigned organizations
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getAssignedOrgsRequest = id => {
    return dispatch => {
        dispatch(showLoader());
        const url = `users/org/${id}`;
        return new HttpRequest(dispatch, url, 'get')
            .send()
            .then(res => {
                dispatch(getAssignedOrgs(res.data.data));
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};

/**
 * function to toggle email updates setting
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const toggleEmailUpdateRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `users/${id}/enableDisableEmail`;
        return new HttpRequest(dispatch, url, 'patch', data)
            .send()
            .then(res => {
                dispatch(showSnackbar('Status changed', 'success'));
                dispatch(toggleEmailUpdate(res.data.data));
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            })
            .catch(error => {
                dispatch(showSnackbar('Status changed error.', 'error'));
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};

/**
 * function to refresh token
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const refreshTokenRequest = data => {
    return dispatch => {
        const url = `refreshToken`;

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(refreshToken(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get signed url
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getSignedUrlRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = `users/profilePic/signedURL`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};

/**
 * function to upload file on signed url
 * @param url: url for api request
 * @param file: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const uploadToS3Request = (url, file) => {
    return dispatch => {
        dispatch(showLoader());
        return new HttpRequest(dispatch, url, 'put', file)
            .s3Upload()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};
