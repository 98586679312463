import React from 'react';
import {connect} from 'react-redux';
import {Avatar, Box, Paper, Typography} from '@material-ui/core';
import {readUpdateRequest} from '../../../../NewUpdatesApiActions';
import {getUserDeptsRequest} from '../../../../../Users/UsersApiActions';
import {setSelectedDept} from '../../../../../Users/UsersActions';
import {getUserInfo, setDepartment} from '../../../../../../utilities/storage';
import {useStyles} from './styles';
import {TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator,} from '@material-ui/lab';
import {getCaseByIdRequest} from '../../../../../Cases/CasesApiAction';
import {showSnackbar} from '../../../../../../utilities/UtilitiesActions';

/**
 * function to render update item
 * @param history: history from router
 * @param update: updates details
 * @param readUpdate: function to read update
 * @param selectedDept: selected department
 * @param getUserDepts: function to get user departments
 * @param setSelectedDept: function to change selected department
 * @param refreshUpdateList: function to refresh update list
 * @returns {JSX.Element}
 * @constructor
 */
const UpdateItem = ({
                        history,
                        update,
                        readUpdate,
                        selectedDept,
                        getUserDepts,
                        setSelectedDept,
                        refreshUpdateList,
                        getCaseById,
                        openSnackbar
                    }) => {
    const classes = useStyles();

    const handleCaseUpdateItemClick = () => {
        getCaseById(update.caseId).then(res => {
            if (selectedDept.team_id === update.TeamId) {
                history.push(`/cases/${update.caseId}`);
    
                readUpdate(update.id).then(res => {
                    refreshUpdateList();
                });
            } else if (update.TeamId) {
                getUserDepts(getUserInfo().id).then(res => {
                    const dept = res.teams.find(dept => dept.team_id === update.TeamId);
    
                    setSelectedDept(dept);
                    setDepartment(dept);
    
                    history.push(`/cases/${update.caseId}`);
    
                    readUpdate(update.id).then(res => {
                        refreshUpdateList();
                    });
                });
            } else {
                history.push(`/cases/${update.caseId}`);
                readUpdate(update.id).then(res => {
                    refreshUpdateList();
                });
            }
        }, error =>{
            readUpdate(update.id).then(res => {
                openSnackbar(error.response.data.error, 'error');
                refreshUpdateList();
            });
            
        })
    };

    /**
     * function to handle click on update items other that case updates
     */
    const handleOtherUpdateItemClick = () => {
        readUpdate(update.id).then(res => {
            refreshUpdateList();
        });
    };

    return (
        <Box
            className={classes.itemContainer}
            onClick={
                update.caseId ? handleCaseUpdateItemClick : handleOtherUpdateItemClick
            }
        >
            <TimelineItem
                classes={{
                    missingOppositeContent: classes.missingOppositeContent,
                }}
            >
                <TimelineSeparator>
                    <TimelineDot
                        className={
                            update.priority === 1
                                ? classes.timelineDotCritical
                                : classes.timelineDot
                        }
                    >
                        <Avatar src={update.userImage} className={classes.timelineAvatar}/>
                    </TimelineDot>

                    <TimelineConnector/>
                </TimelineSeparator>

                <TimelineContent className={classes.timelineContent}>
                    <Box
                        component={Paper}
                        className={
                            update.priority === 1
                                ? classes.timelineContentInnerContainerCritical
                                : classes.timelineContentInnerContainer
                        }
                    >
                        <Typography className={classes.description}>
                            {update.message}
                        </Typography>

                        <Typography className={classes.nameDate}>
                            {update.userFirstName} {update.userLastName}, {update.createdAt}
                        </Typography>

                        <Typography
                            className={
                                update.priority === 1
                                    ? classes.athleteDescCritical
                                    : classes.athleteDesc
                            }
                        >
                            {update.athleteFirstName && update.athleteFirstName}{' '}
                            {update.athleteLastName && update.athleteLastName}
                            {update.athletejerseyNum && `, #${update.athletejerseyNum}`}
                        </Typography>

                        {update.mediaUrl &&
                        update.mediaUrl.map((media, index) => (
                            <Box key={index} className={classes.timeplineImage}>
                                <img
                                    className={classes.modalImage}
                                    src={media.url}
                                    alt='media'
                                />
                            </Box>
                        ))}

                        <Typography className={classes.description}>
                            {update.logItem && `${update.logItem.text}`}
                        </Typography>

                        
                    </Box>
                </TimelineContent>
            </TimelineItem>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        depts: state.sirenUsers.depts.teams,
        selectedDept: state.sirenUsers.selectedDept,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        readUpdate: id => {
            return dispatch(readUpdateRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getUserDepts: id => {
            return dispatch(getUserDeptsRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        setSelectedDept: data => {
            return dispatch(setSelectedDept(data));
        },
        getCaseById: id => {
            return dispatch(getCaseByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        openSnackbar: (message, type) => {
            dispatch(showSnackbar(message, type));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateItem);
