import React from 'react';
import {ValidatorComponent} from 'react-material-ui-form-validator';
import {DatePicker} from '@material-ui/pickers';
import {Box} from '@material-ui/core';

/**
 * class to render date picker validator
 * @returns {JSX.Element}
 */
class DatePickerValidator extends ValidatorComponent {
    renderValidatorComponent() {
        const {
            helperText,
            errorMessages,
            validators,
            validatorListener,
            ...rest
        } = this.props;

        const {isValid} = this.state;

        return (
            <Box>
                <DatePicker
                    {...rest}
                    error={!isValid}
                    helperText={(!isValid && this.getErrorMessage()) || helperText}
                />
            </Box>
        );
    }
}

export default DatePickerValidator;
