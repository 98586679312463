import {
    ADD_DEPARTMENT,
    CLEAR_STORE,
    GET_DEPARTMENT_BY_ID,
    GET_DEPARTMENTS,
    GET_DEPARTMENTS_FOR_ADMIN,
    GET_DEPARTMENTS_FOR_ORG_WITHOUT_PARAMS,
    GET_DEPT_TYPES,
    GET_DEPTS_FOR_USER,
    SET_TAB_VALUE,
    SYNC_ARMS,
    TOGGLE_DEPT_ACTIVATION,
    UPDATE_DEPARTMENT,
} from './DepartmentsActions';

const initialState = {
    depts: [],
    dept: null,
    deptTypes: [],
    tabValue: '1',
    arms: null,
};

/**
 * reducer function to update state based on action
 * @param state: current state of the reducer
 * @param action: action to perform on the data
 * @returns {({deptTypes: *[], tabValue: string, arms: null, dept: null, depts: *[]} & {arms: (null|*)})|{deptTypes: *[], tabValue: string, arms: null, dept: {active}, depts: *[]}|{deptTypes: *[], tabValue: string, arms: null, dept: null, depts: *[]}|{deptTypes: *[], tabValue: (string|*), arms: null, dept: null, depts: *[]}|({deptTypes: *[], tabValue: string, arms: null, dept: null, depts: *[]} & {deptTypes: ([]|*)})|({deptTypes: *[], tabValue: string, arms: null, dept: null, depts: *[]} & {depts})|({deptTypes: *[], tabValue: string, arms: null, dept: null, depts: *[]} & {dept: (null|*)})}
 * @constructor
 */
const DepartmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TAB_VALUE:
            return {
                ...state,
                tabValue: action.tabValue,
            };

        case GET_DEPARTMENTS:
            return Object.assign({}, state, {
                depts: action.depts,
            });

        case GET_DEPARTMENTS_FOR_ADMIN:
            return Object.assign({}, state, {
                depts: action.depts,
            });

        case GET_DEPARTMENTS_FOR_ORG_WITHOUT_PARAMS:
            return Object.assign({}, state, {
                depts: action.depts,
            });

        case ADD_DEPARTMENT:
            return Object.assign({}, state, {
                dept: action.dept,
            });

        case GET_DEPARTMENT_BY_ID:
            return Object.assign({}, state, {
                dept: action.dept,
            });

        case UPDATE_DEPARTMENT:
            return Object.assign({}, state, {
                dept: action.dept,
            });

        case GET_DEPT_TYPES:
            return Object.assign({}, state, {
                deptTypes: action.deptTypes,
            });

        case TOGGLE_DEPT_ACTIVATION:
            return {
                ...state,
                dept: {
                    ...state.dept,
                    active: action.dept.active,
                },
            };

        case GET_DEPTS_FOR_USER:
            return Object.assign({}, state, {
                depts: action.depts,
            });

        case SYNC_ARMS:
            return Object.assign({}, state, {
                arms: action.arms,
            });

        case CLEAR_STORE:
            return Object.assign({}, initialState);

        default:
            return state;
    }
};

export default DepartmentsReducer;
