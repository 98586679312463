import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {useStyles} from './styles';
import {Box, Button, Grid, Paper, Switch, Tab, Typography,} from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddIcon from '@material-ui/icons/Add';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import CaseItem from './components/CaseItem/CaseItem';
import InjuryReport from './components/InjuryReportDialog/InjuryReportDialog';
import {
    getAthleteByIdRequest,
    getCasesRequest,
    getClosedCasesRequest,
    getOpenCasesRequest,
    togglePlayingStatusRequest,
} from '../../AthletesApiActions';
import {getUserInfo} from '../../../../utilities/storage';
import {ROLES, ORGANIZATION_TYPES, APP_URLS} from '../../../../utilities/constants'
import defaultUser from '../../../../assets/user.png';

/**
 * function to render athlete profile
 * @param history: history from router
 * @param selectedDept: selected department
 * @param getAthleteById: function to get athlete by id
 * @param athlete: athlete details
 * @param getCases: function to get cases
 * @param cases: cases list
 * @param getOpenCases: function to get open cases
 * @param openCases: open cases list
 * @param getClosedCases: function to get closed cases
 * @param closedCases: closed cases list
 * @param togglePlayingStatus: function to toggle playing status
 * @returns {JSX.Element}
 * @constructor
 */
const Athlete = ({
                     history,
                     selectedDept,
                     getAthleteById,
                     athlete,
                     getCases,
                     cases,
                     getOpenCases,
                     openCases,
                     getClosedCases,
                     closedCases,
                     togglePlayingStatus,
                 }) => {
    const params = useParams();
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const [value, setValue] = React.useState('1');
    const [openInjuryReportForm, setOpenInjuryReportForm] = useState(false);

    useEffect(() => {
        getAthleteById(params.id).then(() => {
        });
    }, [getAthleteById, params.id]);

    useEffect(() => {
        getCases(selectedDept.team_id, params.id).then(() => {
        });
        getOpenCases(selectedDept.team_id, params.id).then(() => {
        });
        getClosedCases(selectedDept.team_id, params.id).then(() => {
        });
    }, [getCases, getOpenCases, getClosedCases, selectedDept.team_id, params.id]);

    /**
     * function to handle tab change
     * @param e: event object
     * @param newValue: value of the tab
     */
    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    /**
     * function to handle playing status change
     */
    const handlePlayingStatusChange = () => {
        const data = {
            playingStatus: !athlete.playingStatus,
        };
        togglePlayingStatus(params.id, data).then(() => {
        });
    };

    /**
     * function to handle go back
     */
    const onBack = () => {
        history.goBack();
    };

    return (
        <Box style={{paddingBottom: '3rem'}}>
            <Box component={Paper} className={classes.actionsContainer}>
                <Box>
                    <Button
                        startIcon={<ArrowBackIosIcon/>}
                        className={classes.backButton}
                        variant='outlined'
                        color='primary'
                        disableElevation
                        onClick={onBack}
                    >
                        Back
                    </Button>
                </Box>

                <Box>
                    {role !== ROLES.LIMITED_USER ? (
                        <Button
                            variant='contained'
                            color='primary'
                            startIcon={<AddIcon/>}
                            disableElevation
                            className={classes.addCaseButton}
                            component={Link}
                            to={`${APP_URLS.CASES_ADD}?athleteID=${params.id}`}
                        >
                            Add Case
                        </Button>
                    ) : (
                        <></>
                    )}

                    {role !== ROLES.LIMITED_USER &&
                    selectedDept &&
                    selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER &&
                    selectedDept.OrganizationInjuryReport === true && (
                        <Button
                            color='primary'
                            startIcon={<AssignmentIcon/>}
                            onClick={() => setOpenInjuryReportForm(true)}
                            className={classes.reportButton}
                            variant='outlined'
                            disableElevation
                        >
                            Injury Report
                        </Button>
                    )}
                </Box>
            </Box>

            {athlete && (
                <TabContext value={value}>
                    <Box component={Paper} p={3} m={2} pb={0} pl={0}>
                        <Box className={classes.profileContainer}>
                            <Box>
                                <img
                                    alt='profile'
                                    src={athlete.imageUrl ? athlete.imageUrl : defaultUser}
                                    className={classes.profileImage}
                                />
                            </Box>
                            <Box className={classes.profileInfoContainer}>
                                <Typography className={classes.name}>
                                    {`${athlete.firstName} ${athlete.lastName}`}{' '}
                                    {athlete.jerseyNum && `, #${athlete.jerseyNum}`}{' '}
                                    {athlete.mrn && `, #${athlete.mrn}`}
                                </Typography>
                                {selectedDept && selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER ? (
                                    <Box>
                                        <Grid container alignItems='center'>
                                            <Grid item>Not Playing</Grid>
                                            <Grid item>
                                                <Switch
                                                    disabled={role === ROLES.LIMITED_USER ? true : false} 
                                                    color='secondary'
                                                    checked={athlete.playingStatus}
                                                    onChange={handlePlayingStatusChange}
                                                    classes={{
                                                        switchBase: classes.toggleBase,
                                                        track: classes.toggleTrack,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>Playing</Grid>
                                        </Grid>
                                    </Box>
                                ) : (
                                    <></>
                                )}
                            </Box>
                        </Box>

                        <Box mt={2}>
                            <TabList
                                indicatorColor='primary'
                                textColor='primary'
                                onChange={handleChange}
                            >
                                <Tab
                                    label={`Open (${openCases.count ? openCases.count : '0'})`}
                                    value='1'
                                />
                                <Tab
                                    label={`Closed (${
                                        closedCases.count ? closedCases.count : '0'
                                    })`}
                                    value='2'
                                />
                                {selectedDept.headDoctor === true ? (
                                    <Tab
                                        label={`All (${cases.count ? cases.count : '0'})`}
                                        value='3'
                                    />
                                ) : (
                                    <></>
                                )}
                            </TabList>
                        </Box>
                    </Box>

                    <Box>
                        <TabPanel value='1' className={classes.tabPanel}>
                            {openCases && openCases.count === 0 ? (
                                <Box className={classes.noContentContainer}>
                                    <Typography classes={{root: classes.noLabel}}>
                                        No cases available
                                    </Typography>
                                </Box>
                            ) : (
                                <Grid container spacing={2}>
                                    {openCases.rows &&
                                    openCases.rows.map(caseItem => (
                                        <CaseItem
                                            key={caseItem.id}
                                            caseItem={caseItem}
                                            history={history}
                                        />
                                    ))}
                                </Grid>
                            )}
                        </TabPanel>
                        <TabPanel value='2' className={classes.tabPanel}>
                            {closedCases && closedCases.count === 0 ? (
                                <Box className={classes.noContentContainer}>
                                    <Typography classes={{root: classes.noLabel}}>
                                        No cases available
                                    </Typography>
                                </Box>
                            ) : (
                                <Grid container spacing={2}>
                                    {closedCases.rows &&
                                    closedCases.rows.map(caseItem => (
                                        <CaseItem
                                            key={caseItem.id}
                                            caseItem={caseItem}
                                            history={history}
                                        />
                                    ))}
                                </Grid>
                            )}
                        </TabPanel>
                        <TabPanel value='3' className={classes.tabPanel}>
                            {cases && cases.count === 0 ? (
                                <Box className={classes.noContentContainer}>
                                    <Typography classes={{root: classes.noLabel}}>
                                        No cases available
                                    </Typography>
                                </Box>
                            ) : (
                                <Grid container spacing={2}>
                                    {cases.rows &&
                                    cases.rows.map(caseItem => (
                                        <CaseItem
                                            key={caseItem.id}
                                            caseItem={caseItem}
                                            history={history}
                                        />
                                    ))}
                                </Grid>
                            )}
                        </TabPanel>
                    </Box>
                </TabContext>
            )}

            {openInjuryReportForm && (
                <InjuryReport
                    openInjuryReportForm={openInjuryReportForm}
                    setOpenInjuryReportForm={setOpenInjuryReportForm}
                />
            )}
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        selectedDept: state.sirenUsers.selectedDept,
        athlete: state.athletes.athlete,
        cases: state.cases.cases,
        openCases: state.cases.openCases,
        closedCases: state.cases.closedCases,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAthleteById: id => {
            return dispatch(getAthleteByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getCases: (teamId, patientId) => {
            return dispatch(getCasesRequest(teamId, patientId)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getOpenCases: (teamId, patientId) => {
            return dispatch(getOpenCasesRequest(teamId, patientId)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getClosedCases: (teamId, patientId) => {
            return dispatch(getClosedCasesRequest(teamId, patientId)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        togglePlayingStatus: (id, data) => {
            return dispatch(togglePlayingStatusRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Athlete);
