import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid,} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {AutocompleteValidator} from '../../../../utilities/validators';
import {getOrganisationsWithoutParamsRequest} from '../../../Organizations/OrganisationsApiActions';
import {assignOrgRequest} from '../../HealthcareOrganizationsApiActions';
import {useStyles} from './styles';

/**
 * function to render assign organization form
 * @param openAssignOrganizationDialog: opens assign organization dialog
 * @param setOpenAssignOrganizationDialog: function to change open state
 * @param getAllOrgsWithoutParams: function to get get organization without params
 * @param orgsData: organizations list
 * @param assignOrg: function to assign organization
 * @param setRefreshList: function to set refresh list boolean
 * @returns {JSX.Element}
 * @constructor
 */
const AssignOrganizationForm = ({
                                    openAssignOrganizationDialog,
                                    setOpenAssignOrganizationDialog,
                                    getAllOrgsWithoutParams,
                                    orgsData,
                                    assignOrg,
                                    setRefreshList
                                }) => {
    const classes = useStyles();
    const params = useParams();
    const [organization, setOrganization] = useState(null);

    useEffect(() => {
        if(openAssignOrganizationDialog){
            getAllOrgsWithoutParams().then(res => {
            });
        }
    }, [getAllOrgsWithoutParams, openAssignOrganizationDialog]);

    /**
     * function to assign organization to healthcare organization on button click
     * @param e: event from button click
     */
    const onSubmit = e => {
        e.preventDefault();

        const data = {
            orgId: organization.id,
        };
        assignOrg(params.id, data).then(res => {
            setRefreshList(true);
            handleAssignOrganizationDialogClose();
        });
    };

    /**
     * function to close assign organisation dialog on button click
     */
    const handleAssignOrganizationDialogClose = () => {
        setOpenAssignOrganizationDialog(false);
        setOrganization(null);
    };

    return (
        <Box>
            <Dialog
                fullWidth
                open={openAssignOrganizationDialog}
                onClose={handleAssignOrganizationDialogClose}
            >
                <DialogTitle>Assign Organization</DialogTitle>
                <Divider/>

                <ValidatorForm onSubmit={onSubmit}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box className={classes.inputContainer}>
                                    <AutocompleteValidator
                                        size='small'
                                        value={organization}
                                        onChange={(e, value) => {
                                            setOrganization(value);
                                        }}
                                        options={orgsData && orgsData.rows ? orgsData.rows : []}
                                        getOptionLabel={option => option.name}
                                        getOptionSelected={(option, value) =>
                                            option.id === value.id
                                        }
                                        renderInput={params => (
                                            <TextValidator
                                                {...params}
                                                label='Organization'
                                                variant='outlined'
                                            />
                                        )}
                                        validators={['required']}
                                        errorMessages={['Organization is required']}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions classes={{root: classes.actionsContainer}}>
                        <Button
                            onClick={handleAssignOrganizationDialogClose}
                            variant='outlined'
                            color='primary'
                        >
                            Cancel
                        </Button>

                        <Button
                            type='submit'
                            color='primary'
                            variant='contained'
                            className={classes.addButton}
                        >
                            Add
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        orgsData: state.organisations.orgs,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllOrgsWithoutParams: data => {
            return dispatch(getOrganisationsWithoutParamsRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },

        assignOrg: (id, data) => {
            return dispatch(assignOrgRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssignOrganizationForm);
