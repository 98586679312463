import React from 'react';
import spinner from '../../../assets/spinner.gif';

/**
 * function to render preloader
 * @param props: props
 * @returns {JSX.Element}
 * @constructor
 */
const Preloader = props => {
    return <img alt={props.alt} src={spinner} {...props} />;
};

export default Preloader;
