import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import ImageUploading from 'react-images-uploading';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {PhoneInputValidator} from '../../../../utilities/validators';
import UtilHelper from '../../../../utilities/validators/UtilHelper';
import {getUserInfo, setUserInfo} from '../../../../utilities/storage';
import {useStyles} from './styles';
import {updateLoggedInUserRequest} from '../../../Auth/AuthApiActions';
import defaultUser from '../../../../assets/user.png';
import ImageLoader from 'react-load-image';
import Preloader from '../../../../utilities/components/Preloader/Preloader';
import {Box, Button, Divider, Grid, Icon, Paper, Tooltip, Typography,} from '@material-ui/core';
import {getSignedUrlRequest, getUserByIdRequest, updateUserRequest, uploadToS3Request,} from '../../UsersApiActions';

/**
 * function to render caregiver profile
 * @param history: history from router
 * @param getUserById: function to get user by id
 * @param updateUser: function to update user
 * @param updateLoggedInUser: fuction to update logged in user
 * @param getSignedUrl: fuction to get signed url
 * @param uploadToS3: fuction to upload file on signed url
 * @returns {JSX.Element}
 * @constructor
 */
const EditProfileFormForCaregivers = ({
                  history,
                  getUserById,
                  updateUser,
                  updateLoggedInUser,
                  getSignedUrl,
                  uploadToS3,
              }) => {
    const classes = useStyles();
    const [profileImage, setProfileImage] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isLoggedInUser, setIsLoggedInUser] = useState(false);

    useEffect(() => {
        ValidatorForm.addValidationRule('isValidMobile', value =>
            UtilHelper.validateMobile(value)
        );

        return () => {
            ValidatorForm.removeValidationRule('isValidMobile');
        };
    }, []);

    useEffect(() => {
        getUserById(getUserInfo().id).then(res => {
            setFirstName(res.firstName);
            setLastName(res.lastName);
            setEmail(res.email);
            setPhone(res.phone);
            setProfileImage(res.imageUrl);
            if (res.id === getUserInfo().id) {
                setIsLoggedInUser(true);
            }
        });
    }, [getUserById]);

    /**
     * function to handle edit existing user for caregiver profile
     * @param e: event object on form submission
     */
    const onEdit = e => {
        e.preventDefault();

        if (typeof profileImage !== 'string') {
            const imageData = {
                fileName: profileImage[0].file.name,
                fileType: `.${profileImage[0].file.name.split('.').pop()}`,
            };

            getSignedUrl(imageData).then(res => {
                uploadToS3(res.urls, profileImage[0].file).then(uploadRes => {
                    const data = {
                        firstName: firstName,
                        lastName: lastName,
                        phone: phone,
                        email: email,
                        imageUrl: res.path,
                    };

                    if (isLoggedInUser) {
                        updateLoggedInUser(getUserInfo().id, data).then(res => {
                            setUserInfo(res);
                            history.goBack();
                        });
                    } else {
                        updateUser(getUserInfo().id, data).then(res => {
                            history.goBack();
                        });
                    }
                });
            });
        } else {
            const data = {
                firstName: firstName,
                lastName: lastName,
                phone: phone,
                email: email,
            };

            if (isLoggedInUser) {
                updateLoggedInUser(getUserInfo().id, data).then(res => {
                    setUserInfo(res);
                    history.goBack();
                });
            } else {
                updateUser(getUserInfo().id, data).then(res => {
                    history.goBack();
                });
            }
        }
    };

    return (
        <Box className={classes.container}>
            <Box component={Paper} p={4}>
                <Box>
                    <Box className={classes.labelContainer}>
                        <Typography classes={{root: classes.headingLabel}}>
                            Edit Profile
                        </Typography>
                    </Box>
                    <Divider/>

                    <Box className={classes.formContainer}>
                        <ValidatorForm onSubmit={onEdit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <ImageUploading
                                        acceptType={['jpg', 'jpeg', 'webp', 'png']}
                                        maxFileSize={3000000}
                                        value={profileImage}
                                        onChange={img => {
                                            setProfileImage(img);
                                        }}
                                    >
                                        {({onImageUpload, dragProps, errors}) => (
                                            <Box>
                                                <Box className={classes.profileImageContainer}>
                                                    <Box onClick={onImageUpload} {...dragProps}>
                                                        <Box className={classes.uploadContainer}>
                                                            <Box>
                                                                <Tooltip
                                                                    placement='top'
                                                                    title='Update Photo'
                                                                    arrow
                                                                >
                                                                    <Box>
                                                                        <ImageLoader
                                                                            src={
                                                                                !profileImage
                                                                                    ? defaultUser
                                                                                    : typeof profileImage !== 'string'
                                                                                    ? profileImage[0].dataURL
                                                                                    : profileImage
                                                                            }
                                                                        >
                                                                            <img
                                                                                alt='profile'
                                                                                className={classes.profileImage}
                                                                            />
                                                                            <div
                                                                                className={classes.profileImageError}
                                                                            >
                                                                                err
                                                                            </div>
                                                                            <Preloader
                                                                                className={classes.profileImage}
                                                                            />
                                                                        </ImageLoader>
                                                                    </Box>

                                                                    {/* <img
                                    src={
                                      !profileImage
                                        ? defaultUser
                                        : typeof profileImage !== 'string'
                                        ? profileImage[0].dataURL
                                        : profileImage
                                    }
                                    alt='profile'
                                    className={classes.profileImage}
                                  /> */}
                                                                </Tooltip>
                                                            </Box>

                                                            <Box className={classes.editIcon}>
                                                                <Icon color='primary'>edit</Icon>
                                                            </Box>
                                                        </Box>
                                                    </Box>

                                                    {errors && (
                                                        <Box className={classes.imageErrorContainer}>
                                                            {errors.acceptType && (
                                                                <Typography
                                                                    classes={{root: classes.imageError}}
                                                                >
                                                                    Please select image file
                                                                </Typography>
                                                            )}

                                                            {errors.maxFileSize && (
                                                                <Typography
                                                                    classes={{root: classes.imageError}}
                                                                >
                                                                    Selected image file size exceed max file size
                                                                    (3MB)
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                        )}
                                    </ImageUploading>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextValidator
                                        label='First Name'
                                        variant='outlined'
                                        fullWidth
                                        size='small'
                                        value={firstName}
                                        onChange={e => setFirstName(e.target.value)}
                                        validators={['required']}
                                        errorMessages={['Fisrt Name is required']}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextValidator
                                        label='Last Name'
                                        variant='outlined'
                                        fullWidth
                                        size='small'
                                        value={lastName}
                                        onChange={e => setLastName(e.target.value)}
                                        validators={['required']}
                                        errorMessages={['Last Name is required']}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextValidator
                                        disabled
                                        label='Email'
                                        variant='outlined'
                                        fullWidth
                                        size='small'
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        validators={['required', 'isEmail']}
                                        errorMessages={['Email is required', 'Email is not valid']}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <PhoneInputValidator
                                        autoFormat={false}
                                        variant='outlined'
                                        size='small'
                                        label='Phone'
                                        fullWidth
                                        defaultCountry='us'
                                        countryCodeEditable={false}
                                        value={phone}
                                        onChange={(phone, data) => {
                                            let phoneNumber = '+' + phone.replace(/[^0-9]+/g, '');
                                            setPhone(phoneNumber);
                                        }}
                                        validators={['required', 'isValidMobile']}
                                        errorMessages={[
                                            'Phone is required',
                                            'Please enter valid phone',
                                        ]}
                                    />
                                </Grid>

                                <Grid item>
                                    <Button
                                        type='submit'
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                        disableElevation={true}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        getUserById: id => {
            return dispatch(getUserByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateUser: (id, data) => {
            return dispatch(updateUserRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateLoggedInUser: (id, data) => {
            return dispatch(updateLoggedInUserRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getSignedUrl: data => {
            return dispatch(getSignedUrlRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        uploadToS3: (url, file) => {
            return dispatch(uploadToS3Request(url, file)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileFormForCaregivers);
