import {
    ADD_HC_ORGANISATION,
    ASSIGN_CAREGIVER,
    ASSIGN_ORG,
    ASSIGN_VENDOR,
    GET_ASSIGNED_VENDOR_BY_ID,
    GET_HC_ORGANISATION_BY_ID,
    GET_HC_ORGANISATIONS,
    GET_HC_ORGANISATIONS_WITHOUT_PARAMS,
    REMOVE_ASSIGNED_ORG,
    REMOVE_ASSIGNED_VENDOR,
    SET_TAB_VALUE,
    TOGGLE_HCORG_ACTIVATION,
    UPDATE_ASSIGNED_VENDOR,
    UPDATE_HC_ORGANISATION,
} from './HealthcareOrganizationsActions';

const initialState = {
    hcorgs: [],
    hcorg: null,
    org: {},
    caregiver: {},
    vendor: null,
    tabValue: '1',
};

/**
 * reducer function to update state based on action
 * @param state: current state of the reducer
 * @param action: action to perform on the data
 * @returns {{hcorg: null, hcorgs: *[], org: {}, caregiver: {}, tabValue: (string|*), vendor: null}|({hcorg: null, hcorgs: *[], org: {}, caregiver: {}, tabValue: string, vendor: null} & {vendor})|({hcorg: null, hcorgs: *[], org: {}, caregiver: {}, tabValue: string, vendor: null} & {org})|{hcorg: null, hcorgs: *[], org: {}, caregiver: {}, tabValue: string, vendor: null}|({hcorg: null, hcorgs: *[], org: {}, caregiver: {}, tabValue: string, vendor: null} & {hcorgs: ([]|*)})|{hcorg: {active}, hcorgs: *[], org: {}, caregiver: {}, tabValue: string, vendor: null}|({hcorg: null, hcorgs: *[], org: {}, caregiver: {}, tabValue: string, vendor: null} & {hcorg: (null|*)})|({hcorg: null, hcorgs: *[], org: {}, caregiver: {}, tabValue: string, vendor: null} & {caregiver: *})}
 * @constructor
 */
const HealthcareOrganisationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TAB_VALUE:
            return {
                ...state,
                tabValue: action.tabValue,
            };
        case GET_HC_ORGANISATIONS:
            return Object.assign({}, state, {
                hcorgs: action.hcorgs,
            });

        case GET_HC_ORGANISATIONS_WITHOUT_PARAMS:
            return Object.assign({}, state, {
                hcorgs: action.hcorgs,
            });

        case ADD_HC_ORGANISATION:
            return Object.assign({}, state, {
                hcorg: action.hcorg,
            });

        case GET_HC_ORGANISATION_BY_ID:
            return Object.assign({}, state, {
                hcorg: action.hcorg,
            });

        case UPDATE_HC_ORGANISATION:
            return Object.assign({}, state, {
                hcorg: action.hcorg,
            });

        case ASSIGN_CAREGIVER:
            return Object.assign({}, state, {
                caregiver: action.caregiver,
            });

        case ASSIGN_ORG:
            return Object.assign({}, state, {
                org: action.org,
            });

        case ASSIGN_VENDOR:
            return Object.assign({}, state, {
                vendor: action.vendor,
            });

        case REMOVE_ASSIGNED_ORG:
            return Object.assign({}, state, {
                org: action.org,
            });

        case REMOVE_ASSIGNED_VENDOR:
            return Object.assign({}, state, {
                vendor: action.vendor,
            });

        case UPDATE_ASSIGNED_VENDOR:
            return Object.assign({}, state, {
                vendor: action.vendor,
            });

        case GET_ASSIGNED_VENDOR_BY_ID:
            return Object.assign({}, state, {
                vendor: action.vendor,
            });

        case TOGGLE_HCORG_ACTIVATION:
            return {
                ...state,
                hcorg: {
                    ...state.hcorg,
                    active: action.hcorg.active,
                },
            };

        default:
            return state;
    }
};

export default HealthcareOrganisationsReducer;
