import React from 'react';
import {Box, Dialog, DialogContent, DialogTitle, Divider, Icon, IconButton,} from '@material-ui/core';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {useStyles} from './styles';

/**
 * function to render image editor
 * @param rowImageFile: row image file
 * @param showImageEditor: boolean to show image editor
 * @param setShowImageEditor: function to set show image editor
 * @param onImageCrop: function to handle image crop
 * @param setCropper: function to set cropper
 * @param options: options
 * @returns {JSX.Element}
 * @constructor
 */
const ImageEditor = ({
                         rowImageFile,
                         showImageEditor,
                         setShowImageEditor,
                         onImageCrop,
                         setCropper,
                         options,
                     }) => {
    const classes = useStyles();
    return (
        <Dialog maxWidth='sm' disableBackdropClick fullWidth open={showImageEditor}>
            <DialogTitle>
                <Box className={classes.cropperActionButtonContainer}>
                    <Box>Set Image</Box>
                    <Box>
                        <IconButton size='small' onClick={() => setShowImageEditor(false)}>
                            <Icon className={classes.close}>close_icon</Icon>
                        </IconButton>
                        <IconButton
                            size='small'
                            style={{marginLeft: '0.3rem'}}
                            onClick={onImageCrop}
                        >
                            <Icon className={classes.done}>done_icon</Icon>
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <Divider/>
            <DialogContent style={{padding: '0', overflow: 'hidden'}}>
                <Box
                    className={
                        options.shape === 'circle' ? classes.circleCropperContainer : ''
                    }
                >
                    <Cropper
                        initialAspectRatio={
                            options.shape === 'rect' ? 2 : options.shape === 'circle' ? 1 : 1
                        }
                        viewMode={1}
                        src={rowImageFile && rowImageFile[0].dataURL}
                        onInitialized={instance => {
                            setCropper(instance);
                        }}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ImageEditor;
