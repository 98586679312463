import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        outerContainer:{
            paddingBottom: '3rem'
        },
        noContentContainer: {
            textAlign: 'center',
            marginTop: '8rem',
        },
        noLabel: {
            fontSize: '1.5rem',
            opacity: '0.2',
        },
        casesContainer: {
            position: 'relative',
        },
        addCaseButton: {
            textTransform: 'capitalize',
        },
        headerContainer: {
            position: 'fixed',
            width: '100%',
            zIndex: '1',
        },
        tabsContainer: {
            paddingTop: '15px',
        },
        actionButtonsContainer: {
            position: 'fixed',
            marginTop: '-50px',
            right: '10px',
            display: 'flex',
            alignItems: 'center',
        },
        tabPanelContainer: {
            marginTop: '60px',
        },
    };
});
