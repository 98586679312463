import React from 'react';
import {connect} from 'react-redux';
import {Box, Dialog, DialogContent, DialogTitle, Divider,} from '@material-ui/core';
import {useStyles} from './styles';
import CloseIcon from '@material-ui/icons/Close';

/**
 * function to render dicom viewer
 * @param open: boolean to open the dialog
 * @param setOpen: function to set dicom viewer open 
 * @param dicomUrl: dicom url
 * @returns {JSX.Element}
 * @constructor
 */
const DicomViewer = ({open, setOpen, dicomUrl}) => {
    const classes = useStyles();

    /**
     * function to close dicom viewer
     */
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog maxWidth='xl' fullWidth open={open}>
                <DialogTitle classes={{root: classes.header}}>
                    <CloseIcon onClick={handleClose} className={classes.icon}/>
                </DialogTitle>

                <Divider/>

                <DialogContent>
                    <Box>
                        <iframe
                            title='dicom'
                            src={dicomUrl}
                            frameBorder='1'
                            width='100%'
                            height='600px'
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DicomViewer);
