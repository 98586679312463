import HttpRequest from '../../utilities/httpRequest';
import {getAthletesForDept} from './PlayStatusActions';
import {hideLoader, showLoader} from '../../utilities/UtilitiesActions';

/**
 * function to get  athletes of a department
 * @param id: id for api request
 * @param data: data for api request
 * @param isHideLoader: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getAthletesForDeptRequest = (id, data, isHideLoader) => {
    let offset = data.page * data.rowsPerPage;
    let limit = data.rowsPerPage;
    let searchData = data.searchData ? data.searchData : '';

    return dispatch => {
        if (!isHideLoader) {
            dispatch(showLoader());
        }

        const url = `department/${id}/athletics?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
            searchData
        )}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                let resData = res.data.data;
                resData.rows = resData.rows.map(user => {
                    user.name = `${user.firstName} ${user.lastName}`;
                    return user;
                });

                dispatch(getAthletesForDept(resData));

                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.resolve(resData);
            },
            error => {
                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.reject(error);
            }
        );
    };
};
