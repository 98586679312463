import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import clsx from 'clsx';
import {Box, Card, CssBaseline, Toolbar} from '@material-ui/core';
import Routes from '../routes';
import AppDrawer from './components/AppDrawer/AppDrawer';
import {useStyles} from './styles';
import {getDepartment, getIsTeamList, getUserInfo, setDepartment,} from '../../../utilities/storage';
import AppFooter from './components/AppFooter/AppFooter';
import {setSelectedDept} from '../../Users/UsersActions';
import AppHeader from './components/AppHeader/AppHeader';
import DepartmentsDialog from './components/DepartmentsDialog/DepartmentsDialog';
import {getUserDeptsRequest} from '../../Users/UsersApiActions';
import {ROLES} from '../../../utilities/constants';

/**
 * function to render dashboard
 * @param history: history from router
 * @param setSelectedDept: function to set selected department
 * @param getUserDepts: function to get user departments
 * @returns {JSX.Element}
 * @constructor
 */
const Dashboard = ({history, setSelectedDept, getUserDepts, selectedDept}) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const [open, setOpen] = useState(true);
    const [openChooseDepartmentDialog, setOpenChooseDepartmentDialog] = useState(
        false
    );
    const [dept, setDept] = useState(null);

    useEffect(() => {
        if (!getDepartment() && getIsTeamList() === 'true') {
            getUserDepts(getUserInfo().id).then(res => {
                if (res.teams.length === 1) {
                    setOpenChooseDepartmentDialog(false);
                    setSelectedDept(res.teams[0]);
                    setDepartment(res.teams[0]);
                } else {
                    setOpenChooseDepartmentDialog(true);
                }
            });
        }
    }, [getUserDepts, setSelectedDept]);

    useEffect(() => {
        if (getDepartment()) {
            getUserDepts(getUserInfo().id).then(res => {
                const updatedDept = res.teams.find(team => team.team_id === getDepartment().team_id)
                setSelectedDept(updatedDept);
                setDepartment(updatedDept);
            });
        }
    }, [getUserDepts, setSelectedDept]);

    /**
     * function to toggle drawer on button click
     */
    const handleDrawerOpenClose = () => {
        setOpen(!open);
    };

    /**
     * function to handle choose department dialog submit  
     */
    const onDialogSubmit = () => {
        setSelectedDept(dept);
        setDepartment(dept);
        setOpenChooseDepartmentDialog(false);
    };

    return (
        <Router>
            <Box>
                <CssBaseline/>

                {getIsTeamList() === 'true' && (
                    <>
                        <AppHeader open={open} history={history}/>

                        <DepartmentsDialog
                            dept={dept}
                            setDept={setDept}
                            open={openChooseDepartmentDialog}
                            onSubmit={onDialogSubmit}
                        />
                    </>
                )}

                <Box>
                    <AppDrawer
                        open={open}
                        handleDrawerOpenClose={handleDrawerOpenClose}
                        history={history}
                    />

                    <Box
                        className={
                            role === ROLES.SIRENMD_ADMIN &&
                            getIsTeamList() === 'false'
                                ? clsx(classes.content, open && classes.contentShift)
                                : clsx(classes.userContent, open && classes.userContentShift)
                        }
                    >
                        {getIsTeamList() === 'true' && <Toolbar/>}
                        <Routes history={history}/>
                    </Box>

                    {getIsTeamList() === 'true' && (
                        <Box
                            component={Card}
                            raised
                            className={clsx(classes.footer, open && classes.footerShift)}
                        >
                            <AppFooter/>
                        </Box>
                    )}
                </Box>
            </Box>
        </Router>
    );
};

const mapStateToProps = state => {
    return {
        selectedDept: state.sirenUsers.selectedDept,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSelectedDept: data => {
            return dispatch(setSelectedDept(data));
        },
        getUserDepts: id => {
            return dispatch(getUserDeptsRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
