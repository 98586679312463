import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {useStyles} from './styles';
import {connect} from 'react-redux';
import {Link, NavLink} from 'react-router-dom';
import {PAGE_SIZE, PAGE_SIZE_OPTIONS} from '../../../../utilities/constants';
import {getIsTeamList, getUserInfo} from '../../../../utilities/storage';
import {debounce} from '../../../../utilities/utilityFunctions';
import defaultUser from '../../../../assets/user.png';
import UserFormDialog from '../SirenmdAdminForm/SirenmdAdminForm';
import {
    Box,
    Breadcrumbs,
    Button,
    FormControl,
    Icon,
    MenuItem,
    Paper,
    Select,
    Typography,
} from '@material-ui/core';
import {getRolesRequest, getSirenUsersRequest, getUsersForAdminRequest,} from '../../UsersApiActions';
import ImageLoader from 'react-load-image';
import Preloader from '../../../../utilities/components/Preloader/Preloader';
import {ROLES} from '../../../../utilities/constants';
import ActiveStatusIcon from '../../../../utilities/components/ActiveStatusIcon/ActiveStatusIcon';

/**
 * function to render users list
 * @param getAllRoles: function to get roles
 * @param roles: roles list
 * @param getAllUsers: fuction to get users
 * @param getUsersForAdmin: fuction to get users for admin
 * @param users: users list
 * @returns {JSX.Element}
 * @constructor
 */
const Users = ({
                   getAllRoles,
                   roles,
                   getAllUsers,
                   getUsersForAdmin,
                   users,
               }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const [paginationParams, setPaginationParams] = useState({
        page: 0,
        rowsPerPage: PAGE_SIZE,
        searchData: {
            name: '',
            email: '',
            phone: '',
            role: '',
            enabled: '',
        },
    });
    const [openUserFormDialog, setOpenUserFormDialog] = useState(false);
    const [isFilterChange, setIsFilterChange] = useState(false);
    const [refreshList, setRefreshList] = useState(false);

    /**
     * function to handle table filter search
     * @param filters: filters from filter input to search
     */
    const onTableFilterChange = filters => {
        let searchDataOnFilterChange = Object.assign({}, paginationParams);

        if (filters.length > 0) {
            let obj = {};
            filters.forEach(filter => {
                obj[filter.column.field] = filter.value;
            });

            Object.keys(searchDataOnFilterChange.searchData).forEach(function (key) {
                if (key !== 'role' || key !== 'enabled') {
                    if (obj[key]) {
                        searchDataOnFilterChange.searchData[key] = obj[key];
                    } else {
                        searchDataOnFilterChange.searchData[key] = '';
                    }
                }
            });

            filters.forEach(filter => {
                searchDataOnFilterChange.searchData[filter.column.field] = filter.value;
            });
        } else {
            searchDataOnFilterChange.searchData['name'] = '';
            searchDataOnFilterChange.searchData['email'] = '';
            searchDataOnFilterChange.searchData['phone'] = '';
        }

        setIsFilterChange(true);
        setPaginationParams(searchDataOnFilterChange);
    };

    /**
     * function to implement debounce
     * @type {(function(...[*]=): void)|*}
     */
    //eslint-disable-next-line
    const debounceOnFilterChange = React.useCallback(
        debounce(onTableFilterChange, 400),
        []
    );

    useEffect(() => {
        if (role === ROLES.SIRENMD_ADMIN) {
                getAllUsers(paginationParams, isFilterChange).then(res => {
                    if (isFilterChange) {
                        setIsFilterChange(false);
                    }
                });
        } else {
            getUsersForAdmin(paginationParams, isFilterChange).then(res => {
                if (isFilterChange) {
                    setIsFilterChange(false);
                }
            });
        }
        //eslint-disable-next-line
    }, [paginationParams, getAllUsers, getUsersForAdmin]);

    useEffect(() => {
        if(refreshList){
            if (role === ROLES.SIRENMD_ADMIN) {
                getAllUsers(paginationParams, isFilterChange).then(res => {
                    if (isFilterChange) {
                        setIsFilterChange(false);
                    }
                    setRefreshList(false);
                });
            }
        }
    //eslint-disable-next-line
    }, [paginationParams, getAllUsers, refreshList])

    useEffect(() => {
        getAllRoles().then(res => {
        });
    }, [getAllRoles]);

    /**
     * function to handle role change upon filter
     * @param value: selected role value
     */
    const handleRoleChange = value => {
        let data = Object.assign({}, paginationParams);
        data.searchData.role = value;

        setPaginationParams(data);
    };

    /**
     * function to handle status change upon filter
     * @param value: selected status value
     */
    const handleStatusChange = value => {
        let data = Object.assign({}, paginationParams);
        data.searchData.enabled = value;

        setPaginationParams(data);
    };

    const columns = [
        {
            field: 'name',
            title: 'Name',
            filtering: true,
            defaultFilter: paginationParams.searchData['name'],
            cellStyle: {
                width: '25%',
            },
            headerStyle: {
                width: '25%',
            },
            render: ({imageUrl, name, id}) => {
                return (
                    <Box
                        className={classes.profileContainer}
                        component={Link}
                        to={`users/${id}`}
                    >
                        <Box className={classes.profileInnerContainer}>
                            <ImageLoader src={imageUrl ? imageUrl : defaultUser}>
                                <img alt={id} className={classes.profileImage}/>
                                <div className={classes.profileImageError}>{id}</div>
                                <Preloader alt={id} className={classes.profileImage}/>
                            </ImageLoader>
                        </Box>

                        <Box className={classes.nameContainer}>
                            <Typography classes={{root: classes.name}}>{name}</Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'email',
            title: 'Email',
            filtering: true,
            defaultFilter: paginationParams.searchData['email'],
            cellStyle: {
                width: '25%',
            },
            headerStyle: {
                width: '25%',
            },
        },
        {
            field: 'phone',
            title: 'Phone',
            filtering: true,
            defaultFilter: paginationParams.searchData['phone'],
            cellStyle: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            },
        },
        {
            field: 'Roles',
            title: 'Role',
            filtering: true,
            filterComponent: props => {
                return (
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                value={paginationParams.searchData.role}
                                onChange={e => handleRoleChange(e.target.value)}
                            >
                                <MenuItem value=''>All</MenuItem>
                                {roles &&
                                roles.map(role => (
                                    <MenuItem key={role.id} value={role.name}>
                                        {role.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                );
            },
            cellStyle: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            },
            render: ({Roles}) => {
                return (
                    <>
                        <p>{Roles}</p>
                    </>
                );
            },
        },
        {
            field: 'enabled',
            title: 'Active Status',
            filtering: true,
            filterComponent: () => {
                return (
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                value={paginationParams.searchData.enabled}
                                onChange={e => handleStatusChange(e.target.value)}
                            >
                                <MenuItem value=''>All</MenuItem>
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Blocked</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                );
            },
            cellStyle: {
                cellStyle: {
                    width: '10%',
                },
                textAlign: 'center',
            },
            headerStyle: {
                cellStyle: {
                    width: '10%',
                },
                textAlign: 'center',
            },
            render: ({enabled, isBanned}) => {
                return (
                    <ActiveStatusIcon enabled={enabled} isBanned={isBanned} isLabel={true}  />
                );
            },
        },
    ];

    return (
        <Box
            className={
               role === ROLES.SIRENMD_ADMIN &&
                getIsTeamList() === 'false'
                    ? ''
                    : classes.outerContainer
            }
        >
            <Box
                className={
                    role === ROLES.SIRENMD_ADMIN &&
                    getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainer
                        : getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainerForAdmins
                        : classes.breadcrumbsContainerForUsers
                }
                component={Paper}
                p={1}
            >
                <Breadcrumbs>
                    <Box
                        component={NavLink}
                        exact
                        to='/users'
                        className={classes.breadcrumbItem}
                        activeClassName={classes.activeBreadcrumbItem}
                    >
                        <Icon className={classes.breadcrumpIcon}>group</Icon>

                        <Box className={classes.breadcrumpLabel}>Users</Box>
                    </Box>
                </Breadcrumbs>
            </Box>

            <Box>
                <Box>
                    <UserFormDialog
                        setRefreshList={setRefreshList}
                        openUserFormDialog={openUserFormDialog}
                        setOpenUserFormDialog={setOpenUserFormDialog}
                    />
                </Box>

                {users && (
                    <>
                        {role === ROLES.SIRENMD_ADMIN && (
                            <Box className={classes.addButtonContainer}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => setOpenUserFormDialog(true)}
                                    startIcon={<Icon>add</Icon>}
                                    disableElevation={true}
                                    classes={{root: classes.addButton}}
                                >
                                    SirenMD Admin
                                </Button>
                            </Box>
                        )}

                        <Box mt={3}>
                            <MaterialTable
                                title='Users'
                                columns={columns}
                                data={users.rows}
                                options={{
                                    search: false,
                                    filtering: true,
                                    sorting: false,
                                    horizontalScroll: true,
                                    draggable: false,
                                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                                    pageSize: paginationParams.rowsPerPage,
                                    emptyRowsWhenPaging: false,
                                }}
                                pages={Math.ceil(users.count / paginationParams.rowsPerPage)}
                                totalCount={users.count}
                                page={paginationParams.page}
                                onChangeRowsPerPage={pageSize => {
                                    let data = Object.assign({}, paginationParams);
                                    data['rowsPerPage'] = pageSize;
                                    setPaginationParams(data);
                                }}
                                onChangePage={page => {
                                    let data = Object.assign({}, paginationParams);
                                    data['page'] = page;
                                    setPaginationParams(data);
                                }}
                                onFilterChange={filters => {
                                    debounceOnFilterChange(filters);
                                }}
                            />
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        users: state.sirenUsers.users,
        roles: state.sirenUsers.roles,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllUsers: (data, isHideLoader = false) => {
            return dispatch(getSirenUsersRequest(data, isHideLoader)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getUsersForAdmin: (data, isHideLoader = false) => {
            return dispatch(getUsersForAdminRequest(data, isHideLoader)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getAllRoles: () => {
            return dispatch(getRolesRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
