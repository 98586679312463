import {GET_ANALYTICS} from './AnalyticsActions';

const initialState = {
    analytics: [],
};

/**
 * reducer function to update state based on action
 * @param state: current state of the reducer
 * @param action: action to perform on the data
 * @returns {{analytics: *[]}|({analytics: *[]} & {analytics: ([]|*)})}
 * @constructor
 */
const AnalyticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ANALYTICS:
            return Object.assign({}, state, {
                analytics: action.analytics,
            });

        default:
            return state;
    }
};

export default AnalyticsReducer;
