export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const GET_DEPARTMENTS_FOR_ADMIN = 'GET_DEPARTMENTS_FOR_ADMIN';
export const GET_DEPARTMENTS_FOR_ORG_WITHOUT_PARAMS =
    'GET_DEPARTMENTS_FOR_ORG_WITHOUT_PARAMS';
export const ADD_DEPARTMENT = 'ADD_DEPARTMENT';
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';
export const GET_DEPARTMENT_BY_ID = 'GET_DEPARTMENT_BY_ID';
export const GET_DEPT_TYPES = 'GET_DEPT_TYPES';
export const TOGGLE_DEPT_ACTIVATION = 'TOGGLE_DEPT_ACTIVATION';
export const GET_DEPTS_FOR_USER = 'GET_DEPTS_FOR_USER';
export const SET_TAB_VALUE = 'SET_TAB_VALUE';
export const SYNC_ARMS = 'SYNC_ARMS';
export const CLEAR_STORE = 'CLEAR_STORE';

/**
 * action creator function to handle tab change
 * @param tabValue: tabValue from api response data
 * @returns {{tabValue, type: string}}
 */
export const handleTabChange = tabValue => {
    return {
        type: SET_TAB_VALUE,
        tabValue: tabValue,
    };
};

/**
 * action creator function to get departments
 * @param depts: depts from api response data
 * @returns {{type: string, depts}}
 */
export const getDepts = depts => {
    return {
        type: GET_DEPARTMENTS,
        depts: depts,
    };
};

/**
 * action creator function to get departments without params
 * @param depts: depts from api response data
 * @returns {{type: string, depts}}
 */
export const getDeptsForOrgWithoutParams = depts => {
    return {
        type: GET_DEPARTMENTS_FOR_ORG_WITHOUT_PARAMS,
        depts: depts,
    };
};

/**
 * action creator function to add department
 * @param dept: dept from api response data
 * @returns {{dept, type: string}}
 */
export const addDept = dept => {
    return {
        type: ADD_DEPARTMENT,
        dept: dept,
    };
};

/**
 * action creator function to get department by id
 * @param dept: dept from api response data
 * @returns {{dept, type: string}}
 */
export const getDeptById = dept => {
    return {
        type: GET_DEPARTMENT_BY_ID,
        dept: dept,
    };
};

/**
 * action creator function to update department
 * @param dept: dept from api response data
 * @returns {{dept, type: string}}
 */
export const updateDept = dept => {
    return {
        type: UPDATE_DEPARTMENT,
        dept: dept,
    };
};

/**
 * action creator function to get department types
 * @param deptTypes: deptTypes from api response data
 * @returns {{deptTypes, type: string}}
 */
export const getDeptTypes = deptTypes => {
    return {
        type: GET_DEPT_TYPES,
        deptTypes: deptTypes,
    };
};

/**
 * action creator function to toggle department activation
 * @param dept: dept from api response data
 * @returns {{dept, type: string}}
 */
export const toggleActivation = dept => {
    return {
        type: TOGGLE_DEPT_ACTIVATION,
        dept: dept,
    };
};

/**
 * action creator function to get departments of a user
 * @param depts: depts from api response data
 * @returns {{type: string, depts}}
 */
export const getDeptsForUser = depts => {
    return {
        type: GET_DEPTS_FOR_USER,
        depts: depts,
    };
};

/**
 * action creator function to handle sync arms
 * @param arms: arms from api response data
 * @returns {{arms, type: string}}
 */
export const syncArms = arms => {
    return {
        type: SYNC_ARMS,
        arms: arms,
    };
};

/**
 * action creator function to get departments of an admin user
 * @param depts: depts from api response data
 * @returns {{type: string, depts}}
 */
export const getDeptsForAdmin = depts => {
    return {
        type: GET_DEPARTMENTS_FOR_ADMIN,
        depts: depts,
    };
};

/**
 * action creator function to clear the redux store
 * @returns {{type: string}}
 */
export const clearStore = () => {
    return {
        type: CLEAR_STORE,
    };
};
