import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        scrollLoaderContainer: {
            position: 'static !important',
            height: '100px !important',
            textAlign: 'center',
        },
        circularProgressAppLoader: {
            marginTop: '20px !important',
        },
    };
});
