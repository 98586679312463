import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Box, Button, Tooltip, Icon} from '@material-ui/core';
import MaterialTable from 'material-table';
import {useStyles} from './styles';
import BatchUploader from '../PatientBatchUploader/PatientBatchUploader';
import PasswordInput from '../PasswordInput/PasswordInput';
import {deleteBatchRequest, getCaregiverBatchesRequest, savePatientBatchRequest, downloadSamplePatientBatchRequest, downloadBatchStatusRequest, updoPatientLastBatchRequest} from '../../DataUtilitiesApiActions';
import {signInRequest} from '../../../Auth/AuthApiActions';
import {getUserInfo} from '../../../../utilities/storage';

/**
 * function to render patient tab
 * @param getCaregiverBatches: function to get patient batches
 * @param batches: batches list
 * @param savePatientBatch: function to save patient batch
 * @param deleteBatch: function to delete batch
 * @param signIn: function to sign in
 * @returns {JSX.Element}
 * @constructor
 */
const PatientsTab = ({
                         getCaregiverBatches,
                         batches,
                         savePatientBatch,
                         deleteBatch,
                         downloadSamplePatientBatch,
                         downloadBatchStatus,
                         updoPatientLastBatch,
                         signIn,
                     }) => {
    const classes = useStyles();
    const [openBatchUpload, setOpenBatchUpload] = useState(false);
    const [openConfirmationInput, setOpenConfirmationInput] = useState(false);
    const [currentBatch, setCurrentBatch] = useState(null);
    const [processType, setProcessType] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        getCaregiverBatches('PATIENT').then(res => {
        });
    }, [getCaregiverBatches]);

    /**
     * function to handle save batch
     */
    const handleSaveBatch = () => {
        savePatientBatch(currentBatch.id).then(res => {
            getCaregiverBatches('PATIENT').then(res => {
            });
            handleConfirmationInputColse();
        });
    };

    /**
     * function to handle undo batch
     */
    const handleUndoBatch = () => {
        updoPatientLastBatch(currentBatch.id).then(res => {
          getCaregiverBatches('PATIENT').then(res => {});
          handleConfirmationInputColse();
        });
    };

    /**
     * function to download batch status
     */
    const onBatchStatusDownload = (props) => {
        downloadBatchStatus(props.id, 'Patient').then(() => {})
    };

    /**
     * function to handle delete batch
     */
    const handleDeleteBatch = () => {
        deleteBatch(currentBatch.id).then(res => {
            getCaregiverBatches('PATIENT').then(res => {
            });
            handleConfirmationInputColse();
        });
    };

    /**
     * function to handle click in icon
     * @param batch: selected batch
     * @param type: type of operation
     */
    const handleClick = (batch, type) => {
        setProcessType(type);
        setCurrentBatch(batch);
        setOpenConfirmationInput(true);
    };

    /**
     * function to handle password confirmation dialog close
     */
    const handleConfirmationInputColse = () => {
        setOpenConfirmationInput(false);
        setProcessType('');
        setCurrentBatch(null);
        setPassword('');
    };

    /**
     * function to handle password confirmation dialog submit
     */
    const handleConfirmationInputSubmit = () => {
        if (processType === 'SAVE') {
            const data = {
                username: getUserInfo().email,
                password: password,
            };
            signIn(data).then(res => {
                handleSaveBatch();
            });
        } else if (processType === 'UNDO') {
            const data = {
                username: getUserInfo().email,
                password: password,
            };
            signIn(data).then(res => {
                handleUndoBatch();
            });
        } else if (processType === 'REMOVE') {
            const data = {
                username: getUserInfo().email,
                password: password,
            };
            signIn(data).then(res => {
                handleDeleteBatch();
            });
        } else {
            handleConfirmationInputColse();
        }
    };

    /**
     * function to handle upload button click
     */
    const onUploadClick = () => {
        setOpenBatchUpload(true);
    };

    const handleDownloadSampleBatch = () => {
        downloadSamplePatientBatch().then(() => {})
    }

    const columns = [
        {
            field: 'user',
            title: 'Created By',
            cellStyle: {
                width: '15%',
            },
            headerStyle: {},
        },
        {
            field: 'createdAt',
            title: 'Created At',
            cellStyle: {
                width: '15%',
            },
            headerStyle: {},
        },
        {
            field: 'totalRows',
            title: 'Total Rows',
            cellStyle: {
                width: '10%',
            },
            headerStyle: {},
        },
        {
            field: 'totalInvalidRows',
            title: 'Total Invalid Rows',
            cellStyle: {
                width: '13%',
            },
            headerStyle: {},
        },
        {
            field: 'batchLength',
            title: 'Batch Length',
            cellStyle: {
                width: '10%',
            },
            headerStyle: {},
        },
        {
            field: 'status',
            title: 'Status',
            cellStyle: {
                width: '15%',
            },
            headerStyle: {},
        },
        {
            field: 'action',
            title: 'Actions',
            cellStyle: {
                width: '10%',
                textAlign: 'right',
            },
            headerStyle: {
                textAlign: 'center',
            },
            render: props => {
                return (
                    <>
            <span>
              {props.status === 'NOT PROCESSED' && (
                  <Tooltip arrow placement='top' title='Process'>
                      <Icon
                          color='primary'
                          className={classes.actionIcon}
                          onClick={() => handleClick(props, 'SAVE')}
                      >save</Icon>
                  </Tooltip>
              )}
                {props.status === 'PROCESSED' && (
                    <>
                    {
                        props.tableData.id === 0 && <Tooltip arrow placement='top' title='Undo'>
                        <Icon
                            className={classes.actionIcon}
                            onClick={() => handleClick(props, 'UNDO')}
                        >undo</Icon>
                        </Tooltip>
                    }

                    <Tooltip arrow placement='top' title='Download'>
                        <Icon
                            color='primary'
                            className={classes.actionIcon}
                            onClick={() => onBatchStatusDownload(props)}
                        >get_app</Icon>
                    </Tooltip>
                </>
                )}
            </span>
                        <span>
              <Tooltip arrow placement='top' title='Remove'>
                <Icon
                    className={`${classes.actionIcon} ${classes.removeIcon}`}
                    onClick={() => handleClick(props, 'REMOVE')}
                >highlight_off</Icon>
              </Tooltip>
            </span>
                    </>
                );
            },
        },
    ];

    return (
        <Box>
            <BatchUploader open={openBatchUpload} setOpen={setOpenBatchUpload}/>

            <PasswordInput
                title={
                    processType === 'SAVE'
                        ? 'Process Batch'
                        : processType === 'UNDO'
                        ? 'Undo Batch'
                        : processType === 'REMOVE'
                            ? 'Remove Batch'
                            : ''
                }
                message={
                    processType === 'SAVE'
                        ? 'Please enter your password to process this batch'
                        : processType === 'UNDO'
                        ? 'Please enter your password to undo this batch'
                        : processType === 'REMOVE'
                            ? 'Please enter your password to remove this batch'
                            : ''
                }
                open={openConfirmationInput}
                handleClose={handleConfirmationInputColse}
                handleSubmit={handleConfirmationInputSubmit}
                password={password}
                setPassword={setPassword}
            />

            <Box className={classes.addButtonContainer}>
                <Box className={classes.buttonsConatiner}>
                <Button
                        variant='contained'
                        color='primary'
                        onClick={handleDownloadSampleBatch}
                        startIcon={<Icon>cloud_download</Icon>}
                        disableElevation={true}
                        classes={{root: classes.addButton}}
                    >
                        Download Upload Template
                </Button>

                <Button
                    variant='contained'
                    color='primary'
                    onClick={onUploadClick}
                    startIcon={<Icon>cloud_upload</Icon>}
                    disableElevation={true}
                    classes={{root: classes.uploadButton}}
                >
                    Upload Batch
                </Button>
                </Box>
            </Box>

            <Box>
                <MaterialTable
                    title='Patient Batches'
                    columns={columns}
                    data={batches ? batches : []}
                    options={{
                        paging: false,
                        search: false,
                        filtering: false,
                        sorting: false,
                        draggable: false,
                        horizontalScroll: true,
                        emptyRowsWhenPaging: false,
                    }}
                />
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        batches: state.dataUtilities.batches,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCaregiverBatches: type => {
            return dispatch(getCaregiverBatchesRequest(type)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        savePatientBatch: id => {
            return dispatch(savePatientBatchRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        deleteBatch: id => {
            return dispatch(deleteBatchRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        downloadSamplePatientBatch: () => {
            return dispatch(downloadSamplePatientBatchRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        downloadBatchStatus: (id, type) => {
            return dispatch(downloadBatchStatusRequest(id, type)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updoPatientLastBatch: (id) => {
            return dispatch(updoPatientLastBatchRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        signIn: data => {
            return dispatch(signInRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientsTab);
