import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        errorText: {
            color: 'red',
            marginLeft: '12px',
        },
        systemLabel: {
            marginBottom: '0.3rem',
        },
        actionsContainer: {
            marginRight: '1rem',
            marginBottom: '0.5rem',
        },
        addButton: {
            marginLeft: '0.5rem',
        },
    };
});
