import React, {useEffect, useState} from 'react';
import {useStyles} from './styles';
import {connect} from 'react-redux';
import {NavLink, useParams, useRouteMatch} from 'react-router-dom';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import UtilHelper from '../../../../utilities/validators/UtilHelper';
import ConfirmationDialog from '../../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import ImageField from '../../../../utilities/components/ImageField/ImageField';
import {getIsTeamList, getUserInfo} from '../../../../utilities/storage';
import {dataURLtoFile} from '../../../../utilities/utilityFunctions';
import {AutocompleteValidator, PhoneInputValidator,} from '../../../../utilities/validators';
import {Box, Breadcrumbs, Button, Card, Grid, Icon, Paper, Typography,} from '@material-ui/core';
import {
    getRolesRequest,
    getSignedUrlRequest,
    getTimezonesRequest,
    getUserByIdRequest,
    updateUserRequest,
    uploadToS3Request,
} from '../../UsersApiActions';
import {ROLES} from '../../../../utilities/constants';

/**
 * function to render caregiver profile
 * @param history: history from router
 * @param location: history from router
 * @param getRoles: function to get roles
 * @param rolesData: roles list
 * @param getTimezones: fuction to get timezones
 * @param timezones: timezones list
 * @param getUserById: fuction to get user by id
 * @param updateUser: fuction to update user
 * @param getSignedUrl: fuction to get signed url
 * @param uploadToS3: fuction to upload file on signed url
 * @returns {JSX.Element}
 * @constructor
 */
const EditProfileFormForAdminUsers = ({
                                          history,
                                          location,
                                          getRoles,
                                          rolesData,
                                          getTimezones,
                                          timezones,
                                          getUserById,
                                          updateUser,
                                          getSignedUrl,
                                          uploadToS3,
                                      }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const params = useParams();
    const match = useRouteMatch();

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [selectedRole, setSelectedRole] = useState(null);
    const [npi, setNpi] = useState('');
    const [timezone, setTimezone] = useState(null);
    const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);
    const [rowImageFile, setRowImageFile] = useState(null);
    const [cropper, setCropper] = useState(null);
    const [croppedImageFile, setCroppedImageFile] = useState(null);
    const [showImageEditor, setShowImageEditor] = useState(false);
    const currentUrlPath = match.path.split(':')[0];

    useEffect(() => {
        getRoles().then(res => {
        });
    }, [getRoles]);

    useEffect(() => {
        getUserById(params.id).then(res => {
            setCroppedImageFile(res.imageUrl);
            setFirstname(res.firstName);
            setLastname(res.lastName);
            setEmail(res.email);
            setPhone(res.phone);
            setTimezone(res.Timezone);
            setSelectedRole(res.Roles[0]);
            setNpi(res.npi);
        });
    }, [params.id, getUserById]);

    useEffect(() => {
        ValidatorForm.addValidationRule('isBlank', value =>
            UtilHelper.validateBlankSpace(value)
        );
        ValidatorForm.addValidationRule('isValidMobile', value =>
            UtilHelper.validateMobile(value)
        );
        ValidatorForm.addValidationRule('isValidNPI', value =>
            UtilHelper.validateNpi(value)
        );
        ValidatorForm.addValidationRule('isMaxLength', value =>
            UtilHelper.validateMaxLength(value)
        );

        return () => {
            ValidatorForm.removeValidationRule('isBlank');
            ValidatorForm.removeValidationRule('isValidMobile');
            ValidatorForm.removeValidationRule('isValidNPI');
            ValidatorForm.removeValidationRule('isMaxLength');
        };
    }, []);

    useEffect(() => {
        getTimezones().then(res => {
        });
    }, [getTimezones]);

    const breadCrumbDataForEditUserForm = [
        {
            icon: 'group',
            name: 'Users',
            path: '/users',
        },
        {
            name: `Update User`,
            path: `/users/edit/${params.id}`,
        },
    ];

    const breadCrumbDataForUpdateOrgCaregiverForm = [
        {
            icon: 'business',
            name: 'Organizations',
            path: '/orgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/orgs/${location.state && location.state.orgIdHistory}`,
        },
        {
            name: `Update Caregiver`,
            path: `/orgs/caregivers/edit/${params.id}`,
        },
    ];

    const breadCrumbDataForUpdateOrgAdminForm = [
        {
            icon: 'business',
            name: 'Organizations',
            path: '/orgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/orgs/${location.state && location.state.orgIdHistory}`,
        },
        {
            name: `Update Administrator`,
            path: `/orgs/admins/edit/${params.id}`,
        },
    ];

    const breadCrumbDataForUpdateOrgDeptCaregiverForm = [
        {
            icon: 'business',
            name: 'Organizations',
            path: '/orgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/orgs/${location.state && location.state.orgIdHistory}`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/orgs/departments/${
                location.state && location.state.deptIdHistory
            }`,
        },
        {
            name: `Update Caregiver`,
            path: `/orgs/departments/caregivers/edit/${params.id}`,
        },
    ];

    const breadCrumbDataForUpdateOrgDeptAdminForm = [
        {
            icon: 'business',
            name: 'Organizations',
            path: '/orgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/orgs/${location.state && location.state.orgIdHistory}`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/orgs/departments/${
                location.state && location.state.deptIdHistory
            }`,
        },
        {
            name: `Update Administrator`,
            path: `/orgs/departments/admins/edit/${params.id}`,
        },
    ];

    const breadCrumbDataForUpdateHcorgCaregiverForm = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: '/healthcareOrgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `Update Caregiver`,
            path: `/healthcareOrgs/caregivers/edit/${params.id}`,
        },
    ];

    const breadCrumbDataForUpdateHcorgOrgCaregiverForm = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: '/healthcareOrgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/${
                location.state && location.state.orgIdHistory
            }`,
        },
        {
            name: `Update Caregiver`,
            path: `/healthcareOrgs/orgs/caregivers/edit/${params.id}`,
        },
    ];

    const breadCrumbDataForUpdateHcorgOrgAdminForm = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: '/healthcareOrgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/${
                location.state && location.state.orgIdHistory
            }`,
        },
        {
            name: `Update Administrator`,
            path: `/healthcareOrgs/orgs/admins/edit/${params.id}`,
        },
    ];

    const breadCrumbDataForUpdateHcorgOrgDeptCaregiverForm = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: '/healthcareOrgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/${
                location.state && location.state.orgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/departments/${
                location.state && location.state.deptIdHistory
            }`,
        },
        {
            name: `Update Caregiver`,
            path: `/healthcareOrgs/orgs/departments/caregivers/edit/${params.id}`,
        },
    ];

    const breadCrumbDataForUpdateHcorgOrgDeptAdminsForm = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: '/healthcareOrgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/${
                location.state && location.state.orgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/departments/${
                location.state && location.state.deptIdHistory
            }`,
        },
        {
            name: `Update Administrator`,
            path: `/healthcareOrgs/orgs/departments/admins/edit/${params.id}`,
        },
    ];

    const breadCrumbDataForUpdateAdminDeptCaregiverForm = [
        {
            icon: 'apartment',
            name: 'Departments',
            path: '/departments',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/departments/${location.state && location.state.deptIdHistory}`,
        },
        {
            name: `Update Caregiver`,
            path: `/departments/caregivers/edit/${params.id}`,
        },
    ];

    /**
     * function to get breadcrumb data for current url
     * @returns {[{path: string, icon: string, name: string}, {path: string, name: string}, {path: string, name: string}, {path: string, name: string}, {path: string, name: string}]|[{path: string, icon: string, name: string}, {path: string, name: string}]|[{path: string, icon: string, name: string}, {path: string, name: string}, {path: string, name: string}]|[{path: string, icon: string, name: string}, {path: string, name: string}, {path: string, name: string}, {path: string, name: string}]|*[]}
     */
    const getBreadcrumbData = (urlPath) => {
        switch(urlPath){
            case '/orgs/caregivers/edit/':{
                return breadCrumbDataForUpdateOrgCaregiverForm
            }
            case '/users/edit/':{
               return breadCrumbDataForEditUserForm
            }
            case '/orgs/admins/edit/':{
                return breadCrumbDataForUpdateOrgAdminForm
            }
            case '/orgs/departments/caregivers/edit/':{
               return breadCrumbDataForUpdateOrgDeptCaregiverForm
            }
            case '/orgs/departments/admins/edit/':{
                return breadCrumbDataForUpdateOrgDeptAdminForm
            }
            case '/healthcareOrgs/caregivers/edit/':{
               return breadCrumbDataForUpdateHcorgCaregiverForm
            }
            case '/healthcareOrgs/orgs/caregivers/edit/':{
                return breadCrumbDataForUpdateHcorgOrgCaregiverForm
            }
            case '/healthcareOrgs/orgs/admins/edit/':{
               return breadCrumbDataForUpdateHcorgOrgAdminForm
            }
            case '/healthcareOrgs/orgs/departments/caregivers/edit/':{
                return breadCrumbDataForUpdateHcorgOrgDeptCaregiverForm
            }
            case '/healthcareOrgs/orgs/departments/admins/edit/':{
               return breadCrumbDataForUpdateHcorgOrgDeptAdminsForm
            }
            case '/departments/caregivers/edit/':{
                return breadCrumbDataForUpdateAdminDeptCaregiverForm
            }
            default: {
                return []
            }
        }
    }

    /**
     * function to handle image crop
     */
    const onImageCrop = () => {
        const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL();
        const croppedImageFileObject = dataURLtoFile(
            croppedImageDataUrl,
            rowImageFile[0].file.name
        );

        const data = {
            dataURL: croppedImageDataUrl,
            file: croppedImageFileObject,
        };

        setCroppedImageFile(data);
        setShowImageEditor(false);
    };

    /**
     * function to handle update existing user
     * @param e: event object on form submission
     */
    const onUpdate = e => {
        e.preventDefault();

        if (typeof croppedImageFile !== 'string') {
            const imageData = {
                fileName: croppedImageFile.file.name,
                fileType: `.${croppedImageFile.file.name.split('.').pop()}`,
            };

            getSignedUrl(imageData).then(res => {
                uploadToS3(res.urls, croppedImageFile.file).then(uploadRes => {
                    const data = {
                        imageUrl: res.path,
                        email: email,
                        phone: phone,
                        firstName: firstname,
                        lastName: lastname,
                        roles: [selectedRole.name],
                        npi: npi,
                        TimezoneId: timezone.id,
                    };

                    updateUser(params.id, data).then(res => {
                        history.goBack();
                    });
                });
            });
        } else {
            const data = {
                email: email,
                phone: phone,
                firstName: firstname,
                lastName: lastname,
                roles: [selectedRole.name],
                npi: npi,
                TimezoneId: timezone.id,
            };

            updateUser(params.id, data).then(res => {
                history.goBack();
            });
        }
    };

    /**
     * function to handle cancel add or update
     */
    const handleCancel = () => {
        history.goBack();
    };

    return (
        <Box
            className={
                role === ROLES.SIRENMD_ADMIN &&
                getIsTeamList() === 'false'
                    ? ''
                    : classes.outerContainer
            }
        >
            <Box
                className={
                    role === ROLES.SIRENMD_ADMIN &&
                    getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainer
                        : getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainerForAdmins
                        : classes.breadcrumbsContainerForUsers
                }
                component={Paper}
                p={1}
            >
                <Breadcrumbs>
                    {getBreadcrumbData(currentUrlPath).map((crumb, index) => (
                        <Box
                            key={index}
                            component={NavLink}
                            exact
                            to={{
                                pathname: crumb.path,
                                state: {
                                    orgIdHistory: location.state && location.state.orgIdHistory,
                                    orgNameHistory:
                                        location.state && location.state.orgNameHistory,
                                    deptIdHistory: location.state && location.state.deptIdHistory,
                                    deptNameHistory:
                                        location.state && location.state.deptNameHistory,
                                    hcorgIdHistory:
                                        location.state && location.state.hcorgIdHistory,
                                    hcorgNameHistory:
                                        location.state && location.state.hcorgNameHistory,
                                },
                            }}
                            className={classes.breadcrumbItem}
                            activeClassName={classes.activeBreadcrumbItem}
                        >
                            {crumb.icon && (
                                <Icon className={classes.breadcrumpIcon}>{crumb.icon}</Icon>
                            )}
                            <Box className={classes.breadcrumpLabel}>{crumb.name}</Box>
                        </Box>
                    ))}
                </Breadcrumbs>
            </Box>

            <Box>
                <ValidatorForm autoComplete='off' onSubmit={onUpdate}>
                    <Box className={classes.outerFormContainer}>
                        <Box className={classes.formLabelContainer}>
                            <Typography className={classes.formLabel}>Update User</Typography>
                        </Box>

                        <Box component={Paper} className={classes.mainFormContainer}>
                            <Grid container spacing={2} justify='space-around'>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <Box>
                                        <ImageField
                                            rowImageFile={rowImageFile}
                                            setRowImageFile={setRowImageFile}
                                            showImageEditor={showImageEditor}
                                            setShowImageEditor={setShowImageEditor}
                                            setCropper={setCropper}
                                            croppedImageFile={croppedImageFile}
                                            onImageCrop={onImageCrop}
                                            options={{
                                                shape: 'circle',
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextValidator
                                                label='Email'
                                                variant='outlined'
                                                fullWidth
                                                size='small'
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                                validators={['required', 'isEmail']}
                                                errorMessages={['Email is required', 'Invalid email']}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <PhoneInputValidator
                                                autoFormat={false}
                                                variant='outlined'
                                                size='small'
                                                label='Phone'
                                                fullWidth
                                                defaultCountry='us'
                                                countryCodeEditable={false}
                                                value={phone}
                                                onChange={(phone, data) => {
                                                    let phoneNumber = '+' + phone.replace(/[^0-9]+/g, '');
                                                    setPhone(phoneNumber);
                                                }}
                                                validators={['required', 'isValidMobile']}
                                                errorMessages={[
                                                    'Phone is required',
                                                    'Please enter valid phone',
                                                ]}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextValidator
                                                label='First Name'
                                                variant='outlined'
                                                fullWidth
                                                size='small'
                                                value={firstname}
                                                onChange={e => setFirstname(e.target.value)}
                                                validators={['required', 'isBlank', 'isMaxLength']}
                                                errorMessages={[
                                                    'Firstname is required',
                                                    'Please enter first name',
                                                    'Maximum 100 letters are accepted.',
                                                ]}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextValidator
                                                label='Last Name'
                                                variant='outlined'
                                                fullWidth
                                                size='small'
                                                value={lastname}
                                                onChange={e => setLastname(e.target.value)}
                                                validators={['required', 'isBlank', 'isMaxLength']}
                                                errorMessages={[
                                                    'Last Name is required',
                                                    'Please enter last name',
                                                    'Maximum 100 letters are accepted.',
                                                ]}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <AutocompleteValidator
                                                fullWidth
                                                size='small'
                                                value={selectedRole}
                                                onChange={(e, value) => {
                                                    setSelectedRole(value);
                                                }}
                                                options={rolesData}
                                                getOptionLabel={option => option.name}
                                                getOptionSelected={(option, value) => {
                                                    if ((option, value)) {
                                                        return option.id === value.id;
                                                    } else {
                                                        return true;
                                                    }
                                                }}
                                                renderInput={params => (
                                                    <TextValidator
                                                        {...params}
                                                        label='Role'
                                                        variant='outlined'
                                                    />
                                                )}
                                                validators={['required']}
                                                errorMessages={['Role is required']}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextValidator
                                                label='NPI'
                                                variant='outlined'
                                                fullWidth
                                                size='small'
                                                value={npi}
                                                onChange={e => setNpi(e.target.value)}
                                                validators={['isBlank', 'isValidNPI']}
                                                errorMessages={[
                                                    'Please enter NPI',
                                                    'Please enter valid NPI',
                                                ]}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <AutocompleteValidator
                                                fullWidth
                                                size='small'
                                                value={timezone}
                                                onChange={(e, value) => {
                                                    setTimezone(value);
                                                }}
                                                options={timezones}
                                                getOptionLabel={option => {
                                                    return (
                                                        option.description.charAt(0).toUpperCase() +
                                                        option.description.slice(1)
                                                    );
                                                }}
                                                getOptionSelected={(option, value) =>
                                                    option.id === value.id
                                                }
                                                renderInput={params => (
                                                    <TextValidator
                                                        {...params}
                                                        label='Select Timezone'
                                                        variant='outlined'
                                                    />
                                                )}
                                                validators={['required']}
                                                errorMessages={['Timezone is required']}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box className={classes.formFooterOuterContainer}>
                            <Box
                                component={Card}
                                raised
                                className={classes.formFooterContainer}
                            >
                                <Box>
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        disableElevation={true}
                                        onClick={() => setOpenCancelConfirmation(true)}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        variant='contained'
                                        color='primary'
                                        disableElevation={true}
                                        type='submit'
                                        className={classes.saveButton}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </ValidatorForm>

                <Box>
                    <ConfirmationDialog
                        open={openCancelConfirmation}
                        setOpen={setOpenCancelConfirmation}
                        title='Cancel'
                        message='Are you sure you want to cancel?'
                        onConfirm={handleCancel}
                    />
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        departmentData: state.departments.depts,
        rolesData: state.sirenUsers.roles,
        timezones: state.sirenUsers.timezones,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getRoles: () => {
            return dispatch(getRolesRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getUserById: id => {
            return dispatch(getUserByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateUser: (id, data) => {
            return dispatch(updateUserRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getSignedUrl: data => {
            return dispatch(getSignedUrlRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        uploadToS3: (url, file) => {
            return dispatch(uploadToS3Request(url, file)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getTimezones: () => {
            return dispatch(getTimezonesRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditProfileFormForAdminUsers);
