import React, {useEffect, useState} from 'react';
import {useStyles} from './styles';
import {connect} from 'react-redux';
import {useParams, useRouteMatch} from 'react-router-dom';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {getUserInfo, setUserInfo} from '../../../../utilities/storage';
import ImageField from '../../../../utilities/components/ImageField/ImageField';
import {dataURLtoFile} from '../../../../utilities/utilityFunctions';
import UtilHelper from '../../../../utilities/validators/UtilHelper';
import {updateLoggedInUserRequest} from '../../../Auth/AuthApiActions';
import {AutocompleteValidator, PhoneInputValidator,} from '../../../../utilities/validators';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid,} from '@material-ui/core';
import {
    addSirenUserRequest,
    getSignedUrlRequest,
    getTimezonesRequest,
    getUserByIdRequest,
    updateUserRequest,
    uploadToS3Request,
} from '../../UsersApiActions';

/**
 * function to render caregiver profile
 * @param openUserFormDialog: boolean to open dialog 
 * @param setOpenUserFormDialog: function to set open state 
 * @param getTimezones: fuction to get timezones
 * @param timezones: timezones list
 * @param id: id
 * @param addSirenUser: function to add siren user 
 * @param getUserById: fuction to get user by id
 * @param updateUser: fuction to update user
 * @param updateLoggedInUser: fuction to update logged in user
 * @param getSignedUrl: fuction to get signed url
 * @param uploadToS3: fuction to upload file on signed url
 * @returns {JSX.Element}
 * @constructor
 */
const AdminDialogForm = ({
                             openUserFormDialog,
                             setOpenUserFormDialog,
                             getTimezones,
                             timezones,
                             id,
                             addSirenUser,
                             getUserById,
                             updateUser,
                             updateLoggedInUser,
                             setRefreshList,
                             getSignedUrl,
                             uploadToS3,
                         }) => {
    const classes = useStyles();
    const params = useParams();
    const match = useRouteMatch();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [timezone, setTimezone] = useState(null);
    const [rowImageFile, setRowImageFile] = useState(null);
    const [cropper, setCropper] = useState(null);
    const [croppedImageFile, setCroppedImageFile] = useState(null);
    const [showImageEditor, setShowImageEditor] = useState(false);
    const [isLoggedInUser, setIsLoggedInUser] = useState(false);

    useEffect(() => {
        ValidatorForm.addValidationRule('isBlank', value =>
            UtilHelper.validateBlankSpace(value)
        );
        ValidatorForm.addValidationRule('isValidMobile', value =>
            UtilHelper.validateMobile(value)
        );
        ValidatorForm.addValidationRule('isMaxLength', value =>
            UtilHelper.validateMaxLength(value)
        );

        return () => {
            ValidatorForm.removeValidationRule('isBlank');
            ValidatorForm.removeValidationRule('isValidMobile');
            ValidatorForm.removeValidationRule('isMaxLength');
        };
    }, []);

    useEffect(() => {
        if(openUserFormDialog){
            getTimezones().then(res => {});
        }
        
    }, [getTimezones, openUserFormDialog]);

    useEffect(() => {
        if (params.id) {
            getUserById(params.id).then(res => {
                setFirstName(res.firstName);
                setLastName(res.lastName);
                setEmail(res.email);
                setPhone(res.phone);
                setCroppedImageFile(res.imageUrl);
                setTimezone(res.Timezone);
                if (res.id === getUserInfo().id) {
                    setIsLoggedInUser(true);
                }
            });
        } else if (match.path !== '/users') {
            getUserById(getUserInfo().id).then(res => {
                setFirstName(res.firstName);
                setLastName(res.lastName);
                setEmail(res.email);
                setPhone(res.phone);
                setCroppedImageFile(res.imageUrl);
                setTimezone(res.Timezone);
                if (res.id === getUserInfo().id) {
                    setIsLoggedInUser(true);
                }
            });
        } else {

        }
    }, [getUserById, params.id, openUserFormDialog, match.path]);

    /**
     * function to handle image crop
     */
    const onImageCrop = () => {
        const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL();
        const croppedImageFileObject = dataURLtoFile(
            croppedImageDataUrl,
            rowImageFile[0].file.name
        );

        const data = {
            dataURL: croppedImageDataUrl,
            file: croppedImageFileObject,
        };

        setCroppedImageFile(data);
        setShowImageEditor(false);
    };

    /**
     * function to handle add new user
     * @param e: event object on form submission
     */
    const onAddUser = e => {
        e.preventDefault();

        const data = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            TimezoneId: timezone.id,
            roles: ['SirenMD Admin'],
        };

        if (croppedImageFile) {
            const imageData = {
                fileName: croppedImageFile.file.name,
                fileType: `.${croppedImageFile.file.name.split('.').pop()}`,
            };

            getSignedUrl(imageData).then(res => {
                uploadToS3(res.urls, croppedImageFile.file).then(uploadRes => {
                    data['imageUrl'] = res.path;

                    addSirenUser(data).then(res => {
                        setRefreshList(true);
                        handleUserFormDialogClose();
                    });
                });
            });
        } else {
            addSirenUser(data).then(res => {
                setRefreshList(true);
                handleUserFormDialogClose();
            });
        }
    };

    /**
     * function to handle update existing user
     * @param e: event object on form submission
     */
    const onUpdateUser = e => {
        e.preventDefault();

        if (typeof croppedImageFile !== 'string') {
            const imageData = {
                fileName: croppedImageFile.file.name,
                fileType: `.${croppedImageFile.file.name.split('.').pop()}`,
            };

            getSignedUrl(imageData).then(res => {
                uploadToS3(res.urls, croppedImageFile.file).then(uploadRes => {
                    const data = {
                        imageUrl: res.path,
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        phone: phone,
                        TimezoneId: timezone.id,
                        roles: ['SirenMD Admin'],
                    };

                    if (isLoggedInUser) {
                        updateLoggedInUser(getUserInfo().id, data).then(res => {
                            setUserInfo(res);
                            handleUserFormDialogClose();
                        });
                    } else {
                        updateUser(params.id, data).then(res => {
                            handleUserFormDialogClose();
                        });
                    }
                });
            });
        } else {
            const data = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone,
                TimezoneId: timezone.id,
                roles: ['SirenMD Admin'],
            };

            if (isLoggedInUser) {
                updateLoggedInUser(getUserInfo().id, data).then(res => {
                    setUserInfo(res);
                    handleUserFormDialogClose();
                });
            } else {
                updateUser(params.id, data).then(res => {
                    handleUserFormDialogClose();
                });
            }
        }
    };

    /**
     * function to handle form dialog close
     */
    const handleUserFormDialogClose = () => {
        setOpenUserFormDialog(false);

        if (!params.id) {
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhone('');
            setCroppedImageFile(null);
            setTimezone(null);
        }
    };

    return (
        <Box>
            <Dialog maxWidth='md' disableBackdropClick open={openUserFormDialog}>
                <DialogTitle>{id ? 'Update' : 'Create'} SirenMD Admin</DialogTitle>

                <Divider/>

                <ValidatorForm onSubmit={id ? onUpdateUser : onAddUser}>
                    <DialogContent classes={{root: classes.contentContainer}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <Box>
                                    <ImageField
                                        rowImageFile={rowImageFile}
                                        setRowImageFile={setRowImageFile}
                                        showImageEditor={showImageEditor}
                                        setShowImageEditor={setShowImageEditor}
                                        setCropper={setCropper}
                                        croppedImageFile={croppedImageFile}
                                        onImageCrop={onImageCrop}
                                        options={{
                                            shape: 'circle',
                                        }}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='First Name *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={firstName}
                                            onChange={e => setFirstName(e.target.value)}
                                            validators={['required', 'isBlank', 'isMaxLength']}
                                            errorMessages={[
                                                'First name is required',
                                                'Please enter first name',
                                                'Maximum 100 letters are accepted.',
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='Last Name *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={lastName}
                                            onChange={e => setLastName(e.target.value)}
                                            validators={['required', 'isBlank', 'isMaxLength']}
                                            errorMessages={[
                                                'Last name is required',
                                                'Please enter last name',
                                                'Maximum 100 letters are accepted.',
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='Email *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            validators={['required', 'isEmail']}
                                            errorMessages={[
                                                'Email is required',
                                                'Email is not valid',
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <PhoneInputValidator
                                            autoFormat={false}
                                            variant='outlined'
                                            size='small'
                                            label='Phone *'
                                            fullWidth
                                            defaultCountry='us'
                                            countryCodeEditable={false}
                                            value={phone}
                                            onChange={(phone, data) => {
                                                let phoneNumber = '+' + phone.replace(/[^0-9]+/g, '');
                                                setPhone(phoneNumber);
                                            }}
                                            validators={['required', 'isValidMobile']}
                                            errorMessages={[
                                                'Phone is required',
                                                'Please enter valid phone',
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <AutocompleteValidator
                                            fullWidth
                                            size='small'
                                            value={timezone}
                                            onChange={(e, value) => {
                                                setTimezone(value);
                                            }}
                                            options={timezones}
                                            getOptionLabel={option => {
                                                return (
                                                    option.description.charAt(0).toUpperCase() +
                                                    option.description.slice(1)
                                                );
                                            }}
                                            getOptionSelected={(option, value) =>
                                                option.id === value.id
                                            }
                                            renderInput={params => (
                                                <TextValidator
                                                    {...params}
                                                    label='Select Timezone *'
                                                    variant='outlined'
                                                />
                                            )}
                                            validators={['required']}
                                            errorMessages={['Timezone is required']}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions classes={{root: classes.actionButtonsContainer}}>
                        <Button
                            onClick={handleUserFormDialogClose}
                            variant='outlined'
                            color='primary'
                        >
                            Cancel
                        </Button>

                        <Button type='submit' color='primary' variant='contained'>
                            {id ? 'Update' : 'Add'}
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        timezones: state.sirenUsers.timezones,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUserById: id => {
            return dispatch(getUserByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },

        addSirenUser: data => {
            return dispatch(addSirenUserRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateUser: (id, data) => {
            return dispatch(updateUserRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateLoggedInUser: (id, data) => {
            return dispatch(updateLoggedInUserRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getSignedUrl: data => {
            return dispatch(getSignedUrlRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        uploadToS3: (url, file) => {
            return dispatch(uploadToS3Request(url, file)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getTimezones: () => {
            return dispatch(getTimezonesRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDialogForm);
