import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, NavLink, useParams, useRouteMatch} from 'react-router-dom';
import {Box, Breadcrumbs, Button, Icon, Paper, Tab, Typography,} from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import {getOrgByIdRequest, toggleActivationRequest,} from '../../OrganisationsApiActions';
import {handleTabChange} from '../../OrganisationsActions';
import OrganizationDepartments from '../../../Departments/components/OrganizationDepartments/OrganizationDepartments';
import OrganizationCaregivers from '../../../Caregivers/components/OrganizationCaregivers/OrganizationCaregivers';
import OrganizationAdministrators
    from '../../../Administrators/components/OrganizationAdmins/OrganizationAdminis';
import ConfirmationDialog from '../../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import {getDeptsRequest} from '../../../Departments/DepartmentsApiActions';
import {PAGE_SIZE} from '../../../../utilities/constants';
import {useStyles} from './styles';
import defaultProfile from '../../../../assets/group.png';
import {getIsTeamList, getUserInfo} from '../../../../utilities/storage';
import ImageLoader from 'react-load-image';
import Preloader from '../../../../utilities/components/Preloader/Preloader';
import {ROLES, APP_URLS} from '../../../../utilities/constants'

/**
 * function to get message for activation dialog
 * @param org: org details
 * @param classes: classes from css
 * @returns {JSX.Element}
 * @constructor
 */
const ToggleActivationDialogMessage = ({org, classes}) => {
    return (
        <>
            {org && (
                <Box>
                    Are you sure you want to {org.active ? 'deactivate' : 'activate'} the{' '}
                    <span className={classes.dialogMessageLabel}>{org.name}</span>?
                </Box>
            )}
        </>
    );
};

/**
 * function to render organization profile
 * @param history: history from router
 * @param location: location from router
 * @param getOrgById: function to get organization by id
 * @param org: organizations details
 * @param handleTabChange: function to handle tab change
 * @param tabValue: tab value
 * @param toggleActivation: function to toggle organization activation
 * @param getAllDepts: function to get departments
 * @returns {JSX.Element}
 * @constructor
 */
const OrganisationProfile = ({
                                 history,
                                 location,
                                 getOrgById,
                                 org,
                                 handleTabChange,
                                 tabValue,
                                 toggleActivation,
                                 getAllDepts,
                             }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const params = useParams();
    const match = useRouteMatch();
    const [
        openToggleActivationConfirmationDialog,
        setOpenToggleActivationConfirmationDialog,
    ] = useState(false);
    const currentUrlPath = match.path.split(':')[0];

    useEffect(() => {
        getOrgById(params.id).then(res => {
        });
    }, [params.id, getOrgById]);

    const breadCrumbDataForOrgProfilePage = [
        {
            icon: 'business',
            name: 'Organizations',
            path: APP_URLS.ORGANIZATIONS,
        },
        {
            name: `${org ? `${org.name}` : ''}`,
            path: `${APP_URLS.ORGANIZATIONS}/${params.id}`,
        },
    ];

    const breadCrumbDataForHcorgOrgProfilePage = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: APP_URLS.HEALTHCARE_ORGANIZATIONS,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `${APP_URLS.HEALTHCARE_ORGANIZATIONS}/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${org ? `${org.name}` : ''}`,
            path: `${APP_URLS.HEALTHCARE_ORGANIZATIONS_ORGS}/${params.id}`,
        },
    ];

    /**
     * function to get breadcrumb data for current url
     */
    const getBreadcrumbData = (urlPath) => {
        switch(urlPath){
            case `${APP_URLS.HEALTHCARE_ORGANIZATIONS_ORGS}/`:{
                return breadCrumbDataForHcorgOrgProfilePage
            }
            case `${APP_URLS.ORGANIZATIONS}/`:{
               return breadCrumbDataForOrgProfilePage
            }
            default: {
                return []
            }
        }
    }

    /**
     * function to handle tab change
     * @param e: event from button click
     * @param newValue: updated value
     */
    const handleChange = (e, newValue) => {
        handleTabChange(newValue);
    };

    /**
     * function to toggle activation
     */
    const onToggleActivation = () => {
        const data = {
            active: !org.active,
        };

        toggleActivation(params.id, data).then(res => {
            setOpenToggleActivationConfirmationDialog(false);
            getAllDepts(params.id, {
                page: 0,
                rowsPerPage: PAGE_SIZE,
                searchData: {},
            }).then(() => {
            });
        });
    };

    return (
        <Box
            className={
                role === ROLES.SIRENMD_ADMIN &&
                getIsTeamList() === 'false'
                    ? ''
                    : classes.outerContainer
            }
        >
            <Box
                className={
                    role === ROLES.SIRENMD_ADMIN &&
                    getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainer
                        : getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainerForAdmins
                        : classes.breadcrumbsContainerForUsers
                }
                component={Paper}
                p={1}
            >
                <Breadcrumbs>
                    {getBreadcrumbData(currentUrlPath).map((crumb, index) => (
                        <Box
                            key={index}
                            component={NavLink}
                            exact
                            to={{
                                pathname: crumb.path,
                                state: {
                                    hcorgIdHistory:
                                        location.state && location.state.hcorgIdHistory,
                                    hcorgNameHistory:
                                        location.state && location.state.hcorgNameHistory,
                                },
                            }}
                            className={classes.breadcrumbItem}
                            activeClassName={classes.activeBreadcrumbItem}
                        >
                            {crumb.icon && (
                                <Icon className={classes.breadcrumpIcon}>{crumb.icon}</Icon>
                            )}
                            <Box className={classes.breadcrumpLabel}>{crumb.name}</Box>
                        </Box>
                    ))}
                </Breadcrumbs>
            </Box>

            <Box mt={3}>
                {org && (
                    <>
                        <Box>
                            <Box component={Paper} className={classes.profileContainer}>
                                <Box className={classes.profileContentContainer}>
                                    <Box>
                                        <ImageLoader
                                            src={org.imageUrl ? org.imageUrl : defaultProfile}
                                        >
                                            <img
                                                alt='profile'
                                                className={`${classes.profileImage} ${
                                                    org.imageUrl ? '' : classes.imageBorder
                                                }`}
                                            />
                                            <div className={classes.profileImageError}>{org.id}</div>
                                            <Preloader
                                                className={`${classes.profileImage} ${
                                                    org.imageUrl ? '' : classes.imageBorder
                                                }`}
                                            />
                                        </ImageLoader>
                                    </Box>
                                    <Box className={classes.profileTextContainer}>
                                        <Typography
                                            classes={{
                                                root: classes.orgNameText,
                                            }}
                                        >
                                            {org.name}
                                        </Typography>
                                        <Typography
                                            classes={{
                                                root: classes.orgDescText,
                                            }}
                                        >
                                            {org.desc}
                                        </Typography>

                                        <Box className={classes.editDetailsButtonContainer}>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                disableElevation={true}
                                                startIcon={<Icon>edit</Icon>}
                                                component={Link}
                                                to={{
                                                    pathname: `${match.path.split(':')[0]}edit/${
                                                        params.id
                                                    }`,
                                                    state: {
                                                        hcorgIdHistory:
                                                            location.state && location.state.hcorgIdHistory,
                                                        hcorgNameHistory:
                                                            location.state && location.state.hcorgNameHistory,
                                                    },
                                                }}
                                                classes={{root: classes.profileButton}}
                                            >
                                                Edit Details
                                            </Button>
                                        </Box>

                                        <Box>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                disableElevation={true}
                                                startIcon={
                                                    org.active === false ? (
                                                        <Icon>check_circle_outline</Icon>
                                                    ) : (
                                                        <Icon>block</Icon>
                                                    )
                                                }
                                                onClick={() =>
                                                    setOpenToggleActivationConfirmationDialog(true)
                                                }
                                                classes={{
                                                    root: `${classes.profileButton} ${
                                                        org.active
                                                            ? classes.blockButton
                                                            : classes.activeButton
                                                    }`,
                                                }}
                                            >
                                                {org.active === false ? 'Activate' : 'Deactivate'}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box component={Paper} className={classes.tabsContainer}>
                                <TabContext value={tabValue}>
                                    <TabList
                                        onChange={handleChange}
                                        indicatorColor='primary'
                                        textColor='primary'
                                        variant='fullWidth'
                                    >
                                        <Tab label='Departments' value='1'/>
                                        <Tab label='Caregivers' value='2'/>
                                        <Tab label='Administrators' value='3'/>
                                    </TabList>
                                    <TabPanel
                                        value='1'
                                        classes={{root: classes.tabPanelContainer}}
                                    >
                                        <OrganizationDepartments
                                            history={history}
                                            orgIdHistory={org.id}
                                            orgNameHistory={org.name}
                                            hcorgNameHistory={
                                                location.state && location.state.hcorgNameHistory
                                            }
                                            hcorgIdHistory={
                                                location.state && location.state.hcorgIdHistory
                                            }
                                            orgTypeIdHistory={org.typeId}
                                        />
                                    </TabPanel>
                                    <TabPanel
                                        value='2'
                                        classes={{root: classes.tabPanelContainer}}
                                    >
                                        <OrganizationCaregivers
                                            orgIdHistory={org.id}
                                            orgNameHistory={org.name}
                                            hcorgNameHistory={
                                                location.state && location.state.hcorgNameHistory
                                            }
                                            hcorgIdHistory={
                                                location.state && location.state.hcorgIdHistory
                                            }
                                        />
                                    </TabPanel>
                                    <TabPanel
                                        value='3'
                                        classes={{root: classes.tabPanelContainer}}
                                    >
                                        <OrganizationAdministrators
                                            history={history}
                                            orgIdHistory={org.id}
                                            orgNameHistory={org.name}
                                            hcorgNameHistory={
                                                location.state && location.state.hcorgNameHistory
                                            }
                                            hcorgIdHistory={
                                                location.state && location.state.hcorgIdHistory
                                            }
                                        />
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </Box>
                    </>
                )}

                {org && (
                    <Box>
                        <ConfirmationDialog
                            title={org.active ? 'Deactivate' : 'Activate'}
                            message={
                                <ToggleActivationDialogMessage org={org} classes={classes}/>
                            }
                            open={openToggleActivationConfirmationDialog}
                            setOpen={setOpenToggleActivationConfirmationDialog}
                            onConfirm={onToggleActivation}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        org: state.organisations.org,
        tabValue: state.organisations.tabValue,
    };
};

const mapDispatchToProp = dispatch => {
    return {
        getOrgById: id => {
            return dispatch(getOrgByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        handleTabChange: value => {
            return dispatch(handleTabChange(value));
        },
        toggleActivation: (orgId, data) => {
            return dispatch(toggleActivationRequest(orgId, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getAllDepts: (orgId, data) => {
            return dispatch(getDeptsRequest(orgId, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProp)(OrganisationProfile);
