import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {getAllSystemsRequest, getVendorsWithoutParamsRequest} from '../../../IntegrationVendors/IntegrationVendorsApiActions';
import {
    assignVendorRequest,
    getAssignedVendorByIdRequest,
    updateAssignedVendorRequest,
} from '../../HealthcareOrganizationsApiActions';
import UtilHelper from '../../../../utilities/validators/UtilHelper';
import {useStyles} from './styles';

/**
 * function to render assign vendor form
 * @param openAssignVendorDialog: opens assign vendor dialog
 * @param setOpenAssignVendorDialog: function to change open state
 * @param getVendorsWithoutParams: function to get vendor without params
 * @param vendorsData: vendors list
 * @param getAllSystems: function to get get systems
 * @param systems: systems list
 * @param assignVendor: function to assign vendor
 * @param vendorId: vendor id
 * @param setVendorId: function to set vendor id
 * @param getAssignedVendorById: function to get assigned vendor by id
 * @param updateAssignedVendor: function to update assigned vendor
 * @param setRefreshList: function to set refresh list boolean
 * @returns {JSX.Element}
 * @constructor
 */
const AssignVendorForm = ({
                                openAssignVendorDialog,
                                setOpenAssignVendorDialog,
                                getVendorsWithoutParams,
                                vendorsData,
                                getAllSystems,
                                systems,
                                assignVendor,
                                vendorId,
                                setVendorId,
                                getAssignedVendorById,
                                updateAssignedVendor,
                                setRefreshList
                            }) => {
    const classes = useStyles();
    const params = useParams();
    const [vendor, setVendor] = useState(null);
    const [transactionEndpoint, setTransactionEndpoint] = useState('');
    const [apiSecret, setApiSecret] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [EHR, setEHR] = useState(false);
    const [LIS, setLIS] = useState(false);
    const [RIS, setRIS] = useState(false);
    const [showVendorError, setShowVendorError] = useState(false);
    const [showSystemError, setShowSystemError] = useState(false);

    useEffect(() => {
        ValidatorForm.addValidationRule('isBlank', value =>
            UtilHelper.validateBlankSpace(value)
        );
        return () => {
            ValidatorForm.removeValidationRule('isBlank');
        };
    }, []);

    useEffect(() => {
        if(openAssignVendorDialog){
            getVendorsWithoutParams().then(res => {
            });
        }
    }, [getVendorsWithoutParams, openAssignVendorDialog]);

    useEffect(() => {
        if(openAssignVendorDialog){
            getAllSystems().then(() => {
            });
        }
    }, [getAllSystems, openAssignVendorDialog]);

    useEffect(() => {
        if (vendorId) {
            getAssignedVendorById(vendorId).then(res => {
                setApiKey(res.apiKey);
                setApiSecret(res.apiSecret);
                setTransactionEndpoint(res.endpoint);
                setVendor(res.Vendor);

                const system = res.system;

                const isEHR = system.find(item => item === 'EHR');
                const isLIS = system.find(item => item === 'LIS');
                const isRIS = system.find(item => item === 'RIS');

                if (isEHR) {
                    setEHR(true);
                }

                if (isLIS) {
                    setLIS(true);
                }

                if (isRIS) {
                    setRIS(true);
                }
            });
        }
    }, [getAssignedVendorById, vendorId]);

    /**
     * function to assign organization to healthcare organization on button click
     * @param e: event from button click
     */
    const onSubmit = e => {
        e.preventDefault();

        if (EHR === false && LIS === false && RIS === false) {
            setShowSystemError(true);

            if (vendor === null) {
                setShowVendorError(true);
            }
        } else {
            const systems = [EHR && 'EHR', LIS && 'LIS', RIS && 'RIS'];
            const selectedSystem = systems.filter(
                system => typeof system === 'string'
            );

            const data = {
                HealthcareorgId: Number(params.id),
                VendorId: vendor.id,
                system: selectedSystem,
                endpoint: transactionEndpoint,
                apiSecret: apiSecret,
                apiKey: apiKey,
            };

            assignVendor(data).then(res => {
                setRefreshList(true);
                handleAssignVendorDialogClose();
            });
        }
    };

    /**
     * function to update assigned vendor
     * @param e: event from button click
     */
    const onUpdate = e => {
        e.preventDefault();

        if (EHR === false && LIS === false && RIS === false) {
            setShowSystemError(true);

            if (vendor === null) {
                setShowVendorError(true);
            }
        } else {
            const systems = [EHR && 'EHR', LIS && 'LIS', RIS && 'RIS'];
            const selectedSystem = systems.filter(
                system => typeof system === 'string'
            );

            const data = {
                VendorId: vendor.id,
                endpoint: transactionEndpoint,
                apiSecret: apiSecret,
                apiKey: apiSecret,
                system: selectedSystem,
            };

            updateAssignedVendor(vendorId, data).then(res => {
                setRefreshList(true);
                handleAssignVendorDialogClose();
            });
        }
    };

    /**
     * function to close assign vendor dialog on button click
     */
    const handleAssignVendorDialogClose = () => {
        setOpenAssignVendorDialog(false);
        setVendor(null);
        setShowVendorError(false);
        setShowSystemError(false);
        setTransactionEndpoint('');
        setApiSecret('');
        setApiKey('');
        setEHR(false);
        setLIS(false);
        setRIS(false);
        setVendorId('');
    };

    return (
        <Box>
            <Dialog
                fullWidth
                open={openAssignVendorDialog}
                onClose={handleAssignVendorDialogClose}
            >
                <DialogTitle>{vendorId ? 'Edit Details' : 'Assign Vendor'}</DialogTitle>
                <Divider/>

                <ValidatorForm onSubmit={vendorId ? onUpdate : onSubmit}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box>
                                    <Autocomplete
                                        size='small'
                                        value={vendor}
                                        onChange={(e, value) => {
                                            setVendor(value);
                                            setShowVendorError(false);
                                        }}
                                        options={vendorsData ? vendorsData : []}
                                        getOptionLabel={option => option.name}
                                        getOptionSelected={(option, value) =>
                                            option.id === value.id
                                        }
                                        renderInput={params => (
                                            <TextValidator
                                                {...params}
                                                label='Vendor *'
                                                variant='outlined'
                                            />
                                        )}
                                    />

                                    {showVendorError && (
                                        <FormHelperText className={classes.errorText}>
                                            Vendor is required
                                        </FormHelperText>
                                    )}
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextValidator
                                    label='Transaction Endpoint'
                                    variant='outlined'
                                    fullWidth
                                    size='small'
                                    value={transactionEndpoint}
                                    onChange={e => setTransactionEndpoint(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextValidator
                                    label='API Secret'
                                    variant='outlined'
                                    fullWidth
                                    size='small'
                                    value={apiSecret}
                                    onChange={e => setApiSecret(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextValidator
                                    label='API Key'
                                    variant='outlined'
                                    fullWidth
                                    size='small'
                                    value={apiKey}
                                    onChange={e => setApiKey(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl>
                                    <FormLabel className={classes.systemLabel}>
                                        System *
                                    </FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={EHR}
                                                    onChange={e => {
                                                        setEHR(e.target.checked);
                                                        setShowSystemError(false);
                                                    }}
                                                />
                                            }
                                            label='EHR for Electronic Health Records System'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={LIS}
                                                    onChange={e => {
                                                        setLIS(e.target.checked);
                                                        setShowSystemError(false);
                                                    }}
                                                />
                                            }
                                            label='LIS for Laboratory Information System'
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={RIS}
                                                    onChange={e => {
                                                        setRIS(e.target.checked);
                                                        setShowSystemError(false);
                                                    }}
                                                />
                                            }
                                            label='RIS for Radiology Information System'
                                        />
                                    </FormGroup>
                                    {showSystemError && (
                                        <FormHelperText className={classes.errorText}>
                                            System is required
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions classes={{root: classes.actionsContainer}}>
                        <Button
                            onClick={handleAssignVendorDialogClose}
                            variant='outlined'
                            color='primary'
                        >
                            Cancel
                        </Button>

                        <Button
                            type='submit'
                            color='primary'
                            variant='contained'
                            className={classes.addButton}
                        >
                            {vendorId ? 'Save' : 'Add'}
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        vendorsData: state.vendors.vendors,
        systems: state.vendors.systems,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getVendorsWithoutParams: data => {
            return dispatch(getVendorsWithoutParamsRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        assignVendor: data => {
            return dispatch(assignVendorRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateAssignedVendor: (id, data) => {
            return dispatch(updateAssignedVendorRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getAssignedVendorById: id => {
            return dispatch(getAssignedVendorByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getAllSystems: () => {
            return dispatch(getAllSystemsRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignVendorForm);
