import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        outerContainer: {
            paddingTop: '1rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            paddingBottom: '1rem',
        },
        breadcrumbsContainer: {
            position: 'sticky',
            zIndex: '1000',
            top: '0',
            marginTop: '-16px',
            marginLeft: '-25px',
            marginRight: '-25px',
        },
        breadcrumbsContainerForUsers: {
            position: 'sticky',
            zIndex: '1000',
            top: '75px',
            marginTop: '-16px',
            marginLeft: '-15px',
            marginRight: '-25px',
        },
        breadcrumbsContainerForAdmins: {
            position: 'sticky',
            zIndex: '1000',
            top: '0px',
            marginTop: '-28px',
            marginLeft: '-15px',
            marginRight: '-25px',
        },
        breadcrumbItem: {
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: theme.palette.text.primary,
            padding: '4px 0px',
        },
        activeBreadcrumbItem: {
            color: theme.palette.primary.main,
        },
        breadcrumpIcon: {
            marginRight: '5px',
            fontSize: '18px',
        },
        breadcrumpLabel: {
            fontSize: '14px',
        },
        circleContainer: {
            height: '153px',
            width: '153px',
            margin: 'auto',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
        },
        circleImage: {
            borderRadius: '50%',
            objectFit: 'contain',
        },
        addCaregiverContainer: {
            marginTop: '8px',
            marginBottom: '1rem',
        },
        addCaregiverLabelContainer: {
            marginBottom: '10px',
        },
        addCaregiverLabel: {
            fontSize: '1.6rem',
        },
        addCaregiverFormContainer: {
            paddingTop: '2rem',
            paddingBottom: '2rem',
            paddingLeft: '1rem',
            paddingRight: '1.5rem',
        },
        switchContainer: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '0.1rem',
        },
        footerContainer: {
            zIndex: '1000',
            position: 'fixed',
            bottom: '0',
            width: '100%',
            left: '0',
        },
        footerContainerInner: {
            height: '60px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingRight: '1.5rem',
        },
        saveButton: {
            marginLeft: '1rem',
        },
    };
});
