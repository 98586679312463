import HttpRequest from '../../utilities/httpRequest';
import {
    addPatient,
    getPatientById,
    getPatientsForDept,
    syncArmsForPatient,
    toggleActivation,
    updatePatient,
} from './PatientsActions';
import {hideLoader, showLoader, showSnackbar,} from '../../utilities/UtilitiesActions';

/**
 * function to get  patients of a department
 * @param id: data for api request
 * @param data: data for api request
 * @param isHideLoader: isHideLoader for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getPatientsForDeptRequest = (id, data, isHideLoader) => {
    let offset = data.page * data.rowsPerPage;
    let limit = data.rowsPerPage;
    let searchData = data.searchData ? data.searchData : '';

    return dispatch => {
        if (!isHideLoader) {
            dispatch(showLoader());
        }

        const url = `department/${id}/athletics?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
            searchData
        )}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                let resData = res.data.data;
                resData.rows = resData.rows.map(user => {
                    user.name = `${user.firstName} ${user.lastName}`;
                    return user;
                });

                dispatch(getPatientsForDept(resData));

                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.resolve(resData);
            },
            error => {
                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to add patient
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const addPatientRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = 'athletes';

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Patient created successfully', 'success'));

                dispatch(addPatient(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get patient by id
 * @param id: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getPatientByIdRequest = id => {
    return dispatch => {
        dispatch(showLoader());

        const url = `athletes/${id}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getPatientById(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to update patient
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const updatePatientRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());

        const url = `athletes/${id}`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Patient updated successfully', 'success'));

                dispatch(updatePatient(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(
                    showSnackbar('Patient updated error, please try again', 'error')
                );
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to toggle patient activation
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const toggleActivationRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `athletes/${id}/activeDeactive`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(toggleActivation(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to sync arms
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const syncArmsForPatientRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = `arms/syncAthleteARMS`;

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Synced to ARMS successfully', 'success'));
                dispatch(syncArmsForPatient(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get signed url
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getSignedUrlRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = `athletes/profilePic/signedURL`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};

/**
 * function to upload file on signed url
 * @param url: url for api request
 * @param file: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const uploadToS3Request = (url, file) => {
    return dispatch => {
        dispatch(showLoader());
        return new HttpRequest(dispatch, url, 'put', file)
            .s3Upload()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};
