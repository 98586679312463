import {makeStyles} from '@material-ui/core';

const drawerWidth = 260;

export const useStyles = makeStyles(theme => {
    return {
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            borderBottom: '1px solid #cbcbcb',
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        toolbar: {
            height: '77px',
        },
        profileMenuContainer: {
            right: '0px',
            display: 'flex',
            marginTop: '0px',
            zIndex: '10000',
            position: 'absolute',
        },
        teamListContainer: {
            backgroundColor: 'white',
            minWidth: '260px',
        },
        teamListItem: {
            textAlign: 'center',
            padding: '1rem',
        },
        playStatusButtonContainer: {
            marginTop: '0.5rem',
        },
        teamButton: {
            textTransform: 'capitalize',
        },
        actionsContainer: {
            padding: '16px 20px',
        },
        deptSelectContainer: {
            marginTop: '0.5rem',
        },
        optionsContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        optionsAvatar: {
            marginRight: '12px',
        },
        optionAvatarImage: {
            height: '35px',
            width: '35px',
            borderRadius: '100%',
            marginTop: '5px',
        },
        optionsText: {
            marginRight: '5px',
        },
        headerDeptContaier:{
            height: '77px!important'
        },
        deptLabel:{
            marginRight: '2.6rem!important'
        }
    };
});
