import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import MaterialTable from 'material-table';
import {PAGE_SIZE, PAGE_SIZE_OPTIONS} from '../../../../utilities/constants';
import {
    getCaregiversForDeptRequest,
    removeCaregiverFromDeptRequest,
    toggleCaregiverHeadRequest
} from '../../CaregiverApiActions';
import ConfirmationDialog from '../../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import {useStyles} from './styles';
import defaultUser from '../../../../assets/user.png';
import {debounce} from '../../../../utilities/utilityFunctions';
import ImageLoader from 'react-load-image';
import Preloader from '../../../../utilities/components/Preloader/Preloader';
import {Box, Button, FormControl, Icon, MenuItem, Select, Switch, Tooltip, Typography,} from '@material-ui/core';
import ActiveStatusIcon from '../../../../utilities/components/ActiveStatusIcon/ActiveStatusIcon';
/**
 * function to get message for remove dialog
 * @param caregiver: caregiver details
 * @param classes: classes from css
 * @returns {JSX.Element}
 * @constructor
 */
const RemoveDialogMessage = ({caregiver, classes}) => {
    return (
        <>
            {caregiver && (
                <Box>
                    Are you sure you want to remove the{' '}
                    <span className={classes.dialogMessageLabel}>
            {caregiver.firstName} {caregiver.lastName}
          </span>{' '}
                    from caregiver list?
                </Box>
            )}
        </>
    );
};

/**
 * function to render department caregivers list
 * @param getCaregiversForDept: function to get caregivers of department
 * @param caregivers: caregivers list
 * @param removeCaregiver: function to remove caregiver
 * @param toggleHead: function to toggle head of caregiver
 * @param orgIdHistory: organization id
 * @param orgNameHistory: organization name
 * @param deptIdHistory: department id
 * @param deptNameHistory: department name
 * @param hcorgIdHistory: healthcare organization id
 * @param hcorgNameHistory: healthcare organization name
 * @returns {JSX.Element}
 * @constructor
 */
const DepartmentCaregivers = ({
                                  getCaregiversForDept,
                                  caregivers,
                                  removeCaregiver,
                                  toggleHead,
                                  orgIdHistory,
                                  orgNameHistory,
                                  deptIdHistory,
                                  deptNameHistory,
                                  hcorgIdHistory,
                                  hcorgNameHistory,
                              }) => {
    const classes = useStyles();
    const params = useParams();
    const match = useRouteMatch();
    const [
        openRemoveConfirmationDialog,
        setOpenRemoveConfirmationDialog,
    ] = useState(false);
    const [caregiver, setCaregiver] = useState(null);
    const [paginationParams, setPaginationParams] = useState({
        page: 0,
        rowsPerPage: PAGE_SIZE,
        searchData: {
            name: '',
            email: '',
            phone: '',
            active: '',
        },
    });
    const [isFilterChange, setIsFilterChange] = useState(false);

    /**
     * function to handle table filter search
     * @param filters: filters from filter input to search
     */
    const onTableFilterChange = filters => {
        let searchDataOnFilterChange = Object.assign({}, paginationParams);

        if (filters.length > 0) {
            let obj = {};
            filters.forEach(filter => {
                obj[filter.column.field] = filter.value;
            });

            Object.keys(searchDataOnFilterChange.searchData).forEach(function (key) {
                if (key !== 'active') {
                    if (obj[key]) {
                        searchDataOnFilterChange.searchData[key] = obj[key];
                    } else {
                        searchDataOnFilterChange.searchData[key] = '';
                    }
                }
            });

            filters.forEach(filter => {
                searchDataOnFilterChange.searchData[filter.column.field] = filter.value;
            });
        } else {
            searchDataOnFilterChange.searchData['name'] = '';
            searchDataOnFilterChange.searchData['email'] = '';
            searchDataOnFilterChange.searchData['phone'] = '';
        }

        setIsFilterChange(true);
        setPaginationParams(searchDataOnFilterChange);
    };

    /**
     * function to implement debounce
     * @type {(function(...[*]=): void)|*}
     */
    //eslint-disable-next-line
    const debounceOnFilterChange = React.useCallback(
        debounce(onTableFilterChange, 400),
        []
    );

    useEffect(() => {
        getCaregiversForDept(params.id, paginationParams, isFilterChange).then(
            res => {
                if (isFilterChange) {
                    setIsFilterChange(false);
                }
            }
        );
        //eslint-disable-next-line
    }, [params.id, paginationParams, getCaregiversForDept]);

    /**
     * function to handle status change upon filter
     * @param value: selected status value
     */
    const handleStatusChange = value => {
        let data = Object.assign({}, paginationParams);
        data.searchData.active = value;

        setPaginationParams(data);
    };

    /**
     * function to toggle change for head on switch change
     * @param props: caregiver details
     */
    const handleChange = props => {
        const data = {
            teamId: Number(params.id),
            headDoctor: !props.headDoctor,
        };

        toggleHead(props.userId, data).then(res => {
            getCaregiversForDept(params.id, paginationParams).then(res => {
            });
        });
    };

    /**
     * function to remove caregiver on button click
     */
    const onRemove = () => {
        const data = {
            caregiverId: caregiver.userId,
        };
        removeCaregiver(params.id, data).then(res => {
            setOpenRemoveConfirmationDialog(false);
            getCaregiversForDept(params.id, paginationParams).then(res => {
            });
        });
    };

    const columns = [
        {
            field: 'name',
            title: 'Name',
            filtering: true,
            defaultFilter: paginationParams.searchData['name'],
            cellStyle: {
                width: '40%',
            },
            headerStyle: {
                width: '40%',
            },
            render: ({imageUrl, name, userId}) => {
                return (
                    <Box
                        className={classes.profileContainer}
                        component={Link}
                        to={{
                            pathname: `${match.path.split(':')[0]}caregivers/${userId}`,
                            state: {
                                orgIdHistory: orgIdHistory,
                                orgNameHistory: orgNameHistory,
                                deptIdHistory: deptIdHistory,
                                deptNameHistory: deptNameHistory,
                                hcorgIdHistory: hcorgIdHistory,
                                hcorgNameHistory: hcorgNameHistory,
                            },
                        }}
                    >
                        <Box className={classes.profileInnerContainer}>
                            <ImageLoader src={imageUrl ? imageUrl : defaultUser}>
                                <img alt={userId} className={classes.profileImage}/>
                                <div className={classes.profileImageError}>{userId}</div>
                                <Preloader alt={userId} className={classes.profileImage}/>
                            </ImageLoader>
                        </Box>

                        <Box className={classes.nameContainer}>
                            <Typography className={classes.name}>{name}</Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'email',
            title: 'Email',
            filtering: true,
            defaultFilter: paginationParams.searchData['email'],
            cellStyle: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            },
        },
        {
            field: 'phone',
            title: 'Phone',
            filtering: true,
            defaultFilter: paginationParams.searchData['phone'],
            cellStyle: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            },
        },
        {
            field: 'role',
            title: 'Role',
            filtering: false,

            cellStyle: {
                width: 'auto',
            },
            headerStyle: {
                width: 'auto',
            },
        },
        {
            field: 'headDoctor',
            title: 'Head Doctor / Head Nurse / Head Athletic Trainer',
            filtering: false,

            cellStyle: {
                textAlign: 'center',
                width: '15%',
            },
            headerStyle: {
                textAlign: 'center',
                width: '15%',
            },
            render: props => {
                return (
                    <>
                        {props.role !== 'Healthcare Professional' &&
                        props.role !== 'Limited User' && (
                            <Switch
                                checked={props.headDoctor === true}
                                onClick={() => handleChange(props)}
                            />
                        )}
                    </>
                );
            },
        },
        {
            field: 'casesCount',
            title: 'Cases',
            filtering: false,
            cellStyle: {
                textAlign: 'center',
                width: '10%',
            },
            headerStyle: {
                textAlign: 'center',
                width: '10%',
            },
        },
        {
            field: 'enabled',
            title: 'Active Status',
            cellStyle: {
                width: '10%',
                textAlign: 'center',
            },
            headerStyle: {
                width: '10%',
                textAlign: 'center',
            },
            filtering: true,
            filterComponent: () => {
                return (
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                value={paginationParams.searchData.active}
                                onChange={e => handleStatusChange(e.target.value)}
                            >
                                <MenuItem value=''>All</MenuItem>
                                <MenuItem value='1'>Active</MenuItem>
                                <MenuItem value='0'>Blocked</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                );
            },
            render: ({enabled, isBanned}) => {
                return (
                    <ActiveStatusIcon enabled={enabled} isBanned={isBanned} isLabel={false} />
                );
            },
        },
        {
            field: 'delete',
            title: 'Remove',
            cellStyle: {
                width: '10%',
                textAlign: 'center',
            },
            headerStyle: {
                width: '10%',
                textAlign: 'center',
            },
            filtering: false,
            render: props => {
                return (
                    <>
                        <Tooltip arrow placement='top' title='Remove'>
                            <Icon
                                className={classes.removeIcon}
                                onClick={() => {
                                    setOpenRemoveConfirmationDialog(true);
                                    setCaregiver(props);
                                }}
                            >
                                highlight_off
                            </Icon>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <Box>
            <Box className={classes.addButtonContainer}>
                <Button
                    variant='contained'
                    color='primary'
                    startIcon={<Icon>add</Icon>}
                    disableElevation={true}
                    component={Link}
                    to={{
                        pathname: `${match.path.split(':')[0]}addCaregiver`,
                        state: {
                            orgIdHistory: orgIdHistory,
                            orgNameHistory: orgNameHistory,
                            deptIdHistory: deptIdHistory,
                            deptNameHistory: deptNameHistory,
                            hcorgIdHistory: hcorgIdHistory,
                            hcorgNameHistory: hcorgNameHistory,
                        },
                    }}
                    classes={{root: classes.addButton}}
                >
                    Caregiver
                </Button>
            </Box>

            <MaterialTable
                title={''}
                columns={columns}
                data={caregivers.rows}
                options={{
                    search: false,
                    filtering: true,
                    sorting: false,
                    draggable: false,
                    horizontalScroll: true,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    pageSize: paginationParams.rowsPerPage,
                    emptyRowsWhenPaging: false,
                }}
                pages={Math.ceil(caregivers.count / paginationParams.rowsPerPage)}
                totalCount={caregivers.count}
                page={paginationParams.page}
                onChangeRowsPerPage={pageSize => {
                    let data = Object.assign({}, paginationParams);
                    data['rowsPerPage'] = pageSize;
                    setPaginationParams(data);
                }}
                onChangePage={page => {
                    let data = Object.assign({}, paginationParams);
                    data['page'] = page;
                    setPaginationParams(data);
                }}
                onFilterChange={filters => {
                    debounceOnFilterChange(filters);
                }}
            />

            {openRemoveConfirmationDialog && caregiver && (
                <Box>
                    <ConfirmationDialog
                        title='Remove'
                        message={
                            <RemoveDialogMessage caregiver={caregiver} classes={classes}/>
                        }
                        open={openRemoveConfirmationDialog}
                        setOpen={setOpenRemoveConfirmationDialog}
                        onConfirm={onRemove}
                    />
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        caregivers: state.caregivers.caregivers,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCaregiversForDept: (deptId, data, isHideLoader = false) => {
            return dispatch(
                getCaregiversForDeptRequest(deptId, data, isHideLoader)
            ).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        removeCaregiver: (deptId, data) => {
            return dispatch(removeCaregiverFromDeptRequest(deptId, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        toggleHead: (caregiverId, data) => {
            return dispatch(toggleCaregiverHeadRequest(caregiverId, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DepartmentCaregivers);
