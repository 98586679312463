import HttpRequest from '../../utilities/httpRequest';

import {
    addAthlete,
    getAilingAthletes,
    getAthleteById,
    getAthletesWithoutParams,
    getCases,
    getChronicAthletes,
    getClosedCases,
    getHealthyAthletes,
    getInjuredAthletes,
    getOpenCases,
    getRecoveringAthletes,
    sendEnjuryReport,
    togglePlayingStatus,
    getBodyLocations,
    updateInjuryReport
} from './AthletesActions';

import {hideLoader, showLoader, showSnackbar,} from '../../utilities/UtilitiesActions';
/**
 * function to get athletes without params
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getAthletesWithoutParamsRequest = id => {
    const searchData= {
        active: true,
    }

    return dispatch => {
        dispatch(showLoader());
        const url = `department/${id}/athletics?searchData=${JSON.stringify(searchData)}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getAthletesWithoutParams(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get injured athletes 
 * @param id: id for api request
 * @param sortBy: sortBy for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getInjuredAthletesRequest = (id, sortBy) => {
    const data = {status: 'INJURED', selectVal: sortBy};
    return dispatch => {
        dispatch(showLoader());
        const url = `department/${id}/athletics?searchData=${JSON.stringify(data)}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getInjuredAthletes(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get recovering athletes 
 * @param id: id for api request
 * @param sortBy: sortBy for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getRecoveringAthletesRequest = (id, sortBy) => {
    const data = {status: 'RECOVERING', selectVal: sortBy};

    return dispatch => {
        dispatch(showLoader());
        const url = `department/${id}/athletics?searchData=${JSON.stringify(data)}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getRecoveringAthletes(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get healthy athletes 
 * @param id: id for api request
 * @param sortBy: sortBy for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getHealthyAthletesRequest = (id, sortBy) => {
    const data = {status: 'HEALTHY', selectVal: sortBy};
    return dispatch => {
        dispatch(showLoader());
        const url = `department/${id}/athletics?searchData=${JSON.stringify(data)}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getHealthyAthletes(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get ailing athletes 
 * @param id: id for api request
 * @param sortBy: sortBy for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getAilingAthletesRequest = (id, sortBy) => {
    const data = {status: 'AILING', selectVal: sortBy};
    return dispatch => {
        dispatch(showLoader());
        const url = `department/${id}/athletics?searchData=${JSON.stringify(data)}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getAilingAthletes(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get chronic athletes 
 * @param id: id for api request
 * @param sortBy: sortBy for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getChronicAthletesRequest = (id, sortBy) => {
    const data = {status: 'CHRONIC', selectVal: sortBy};
    return dispatch => {
        dispatch(showLoader());
        const url = `department/${id}/athletics?searchData=${JSON.stringify(data)}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getChronicAthletes(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to add athlete
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const addAthleteRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = 'athletes';

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('New user added successfully', 'success'));

                dispatch(addAthlete(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(
                    showSnackbar(
                        'New user not added successfully, try again later',
                        'error'
                    )
                );
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get athlete by id
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getAthleteByIdRequest = id => {
    return dispatch => {
        dispatch(showLoader());
        const url = `athletes/${id}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getAthleteById(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get cases
 * @param teamId: teamId for api request
 * @param patientId: patientId for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getCasesRequest = (teamId, patientId) => {
    const data = {
        teamId: teamId,
        status: 'ALL',
        patientId: patientId,
    };
    return dispatch => {
        dispatch(showLoader());
        const url = `patientCase?searchData=${JSON.stringify(data)}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getCases(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get open cases
 * @param teamId: teamId for api request
 * @param patientId: patientId for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getOpenCasesRequest = (teamId, patientId) => {
    const data = {
        teamId: teamId,
        status: 'OPEN',
        patientId: patientId,
    };
    return dispatch => {
        dispatch(showLoader());
        const url = `patientCase?searchData=${JSON.stringify(data)}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getOpenCases(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get closed cases
 * @param teamId: teamId for api request
 * @param patientId: patientId for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getClosedCasesRequest = (teamId, patientId) => {
    const data = {
        teamId: teamId,
        status: 'CLOSED',
        patientId: patientId,
    };
    return dispatch => {
        dispatch(showLoader());
        const url = `patientCase?searchData=${JSON.stringify(data)}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getClosedCases(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to toggle playing status
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const togglePlayingStatusRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `athletes/${id}/enableOrDisablePlayingStatus`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(
                    showSnackbar('Playing status changed successfully', 'success')
                );
                dispatch(togglePlayingStatus(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to send enjury report
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const sendEnjuryReportRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = 'athlete/generateReport';

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Injury report send successfully', 'success'));
                dispatch(sendEnjuryReport(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(error.response.data.error, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get body locations
 * @returns {function(*=): Promise<unknown>}
 */
export const getBodyLocationsRequest = () => {
    return dispatch => {
        dispatch(showLoader());
        const url = `athlete/bodypart`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(getBodyLocations(res.data.data));
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to update injury report
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const updateInjuryReportRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `athletes/${id}/report`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Injury report updated.', 'success'));
                dispatch(updateInjuryReport(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get signed url
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getSignedUrlRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = `athletes/profilePic/signedURL`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};

/**
 * function to upload to signed url
 * @param url: url for api request
 * @param file: file for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const uploadToS3Request = (url, file) => {
    return dispatch => {
        dispatch(showLoader());
        return new HttpRequest(dispatch, url, 'put', file)
            .s3Upload()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};

