import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import MaterialTable from 'material-table';
import {Box, Button, Tooltip, Typography} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/HighlightOff';
import AddIcon from '@material-ui/icons/Add';
import {PAGE_SIZE, PAGE_SIZE_OPTIONS} from '../../../../utilities/constants';
import AssignOrganization
    from '../../../HealthcareOrganizations/components/AssignOrganizationForm/AssignOrganizationForm';
import {getOrgsForHCOrgRequest} from '../../OrganisationsApiActions';
import {removeAssignedOrgRequest} from '../../../HealthcareOrganizations/HealthcareOrganizationsApiActions';
import ConfirmationDialog from '../../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import {useStyles} from './styles';
import defaultProfile from '../../../../assets/group.png';
import {debounce} from '../../../../utilities/utilityFunctions';

/**
 * function to get message for remove dialog
 * @param org: org details
 * @param classes: classes from css
 * @returns {JSX.Element}
 * @constructor
 */
const RemoveDialogMessage = ({org, classes}) => {
    return (
        <>
            {org && (
                <Box>
                    Are you sure you want to remove the{' '}
                    <span className={classes.dialogMessageLabel}>{org.name}</span> from
                    organization list?
                </Box>
            )}
        </>
    );
};

/**
 * function to render organizations of a healthcare organization
 * @param getAllOrgsForHCOrg: function to get organizations of a healthcare organization
 * @param orgsForHCOrg: organizations list
 * @param removeAssignedOrg: function to remove assigned organization
 * @param hcorgIdHistory: healthcare organization id
 * @param hcorgNameHistory: healthcare organization name
 * @returns {JSX.Element}
 * @constructor
 */
const OrnanizationsForHCOrg = ({
                                   getAllOrgsForHCOrg,
                                   orgsForHCOrg,
                                   removeAssignedOrg,
                                   hcorgIdHistory,
                                   hcorgNameHistory,
                               }) => {
    const classes = useStyles();
    const params = useParams();
    const match = useRouteMatch();
    const [
        openRemoveConfirmationDialog,
        setOpenRemoveConfirmationDialog,
    ] = useState(false);

    const [
        openAssignOrganizationDialog,
        setOpenAssignOrganizationDialog,
    ] = useState(false);
    const [org, setOrg] = useState(null);
    const [paginationParams, setPaginationParams] = useState({
        page: 0,
        rowsPerPage: PAGE_SIZE,
        searchData: {
            name: '',
            desc: '',
        },
    });
    const [isFilterChange, setIsFilterChange] = useState(false);
    const [refreshList, setRefreshList] = useState(false);

    /**
     * function to handle table filter search
     * @param filters: filters from filter input to search
     */
    const onTableFilterChange = filters => {
        let searchDataOnFilterChange = Object.assign({}, paginationParams);

        if (filters.length > 0) {
            let obj = {};
            filters.forEach(filter => {
                obj[filter.column.field] = filter.value;
            });

            Object.keys(searchDataOnFilterChange.searchData).forEach(function (key) {
                if (key !== 'active') {
                    if (obj[key]) {
                        searchDataOnFilterChange.searchData[key] = obj[key];
                    } else {
                        searchDataOnFilterChange.searchData[key] = '';
                    }
                }
            });

            filters.forEach(filter => {
                searchDataOnFilterChange.searchData[filter.column.field] = filter.value;
            });
        } else {
            searchDataOnFilterChange.searchData['name'] = '';
            searchDataOnFilterChange.searchData['desc'] = '';
        }

        setIsFilterChange(true);
        setPaginationParams(searchDataOnFilterChange);
    };

    /**
     * function to implement debounce
     * @type {(function(...[*]=): void)|*}
     */
    //eslint-disable-next-line
    const debounceOnFilterChange = React.useCallback(
        debounce(onTableFilterChange, 400),
        []
    );

    useEffect(() => {
            getAllOrgsForHCOrg(params.id, paginationParams, isFilterChange).then(
                res => {
                    if (isFilterChange) {
                        setIsFilterChange(false);
                    }
                }
            );
        //eslint-disable-next-line
    }, [
        params.id,
        getAllOrgsForHCOrg,
        paginationParams,
    ]);

    useEffect(() => {
        if(refreshList){
            getAllOrgsForHCOrg(params.id, paginationParams, isFilterChange).then(
                res => {
                    if (isFilterChange) {
                        setIsFilterChange(false);
                    }
                    setRefreshList(false);
                }
            );
        }
        //eslint-disable-next-line
    }, [
        params.id,
        getAllOrgsForHCOrg,
        paginationParams,
        refreshList
    ]);

    /**
     * function to remove organization on button click
     */
    const onRemove = () => {
        const data = {
            orgId: org.id,
        };
        removeAssignedOrg(params.id, data).then(res => {
            setRefreshList(true);
            setOpenRemoveConfirmationDialog(false);
        });
    };

    const columns = [
        {
            field: 'name',
            title: 'Name',
            filtering: true,
            defaultFilter: paginationParams.searchData['name'],
            cellStyle: {
                width: '20%',
                minWidth: '20%',
            },
            headerStyle: {
                width: '20%',
                minWidth: '20%',
            },
            render: ({imageUrl, name, id}) => {
                return (
                    <Box
                        className={classes.profileContainer}
                        component={Link}
                        to={{
                            pathname: `${match.path.split(':')[0]}orgs/${id}`,
                            state: {
                                hcorgIdHistory: hcorgIdHistory,
                                hcorgNameHistory: hcorgNameHistory,
                            },
                        }}
                    >
                        <img
                            src={imageUrl ? imageUrl : defaultProfile}
                            alt='profile'
                            className={classes.profileImage}
                        />
                        <Box className={classes.nameContainer}>
                            <Typography classes={{root: classes.name}}>{name}</Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'desc',
            title: 'Description',
            defaultFilter: paginationParams.searchData['desc'],
            filtering: true,
            cellStyle: {
                width: 'auto',
            },
            headerStyle: {
                width: 'auto',
            },
        },
        {
            field: 'remove',
            title: 'Remove',
            cellStyle: {
                textAlign: 'center',
                width: '10%',
            },
            headerStyle: {
                textAlign: 'center',
                width: '10%',
            },
            filtering: false,
            render: props => {
                return (
                    <Tooltip arrow placement='top' title='Remove'>
                        <ClearIcon
                            className={classes.removeIcon}
                            onClick={() => {
                                setOpenRemoveConfirmationDialog(true);
                                setOrg(props);
                            }}
                        />
                    </Tooltip>
                );
            },
        },
    ];

    return (
        <Box>
            <AssignOrganization
                openAssignOrganizationDialog={openAssignOrganizationDialog}
                setOpenAssignOrganizationDialog={setOpenAssignOrganizationDialog}
                setRefreshList={setRefreshList}
            />

            <Box className={classes.addButtonContainer}>
                <Button
                    variant='contained'
                    color='primary'
                    startIcon={<AddIcon/>}
                    disableElevation={true}
                    onClick={() => setOpenAssignOrganizationDialog(true)}
                    classes={{root: classes.addButton}}
                >
                    Organization
                </Button>
            </Box>

            <Box>
                <MaterialTable
                    title=''
                    columns={columns}
                    data={orgsForHCOrg.rows}
                    options={{
                        search: false,
                        filtering: true,
                        sorting: false,
                        horizontalScroll: true,
                        pageSizeOptions: PAGE_SIZE_OPTIONS,
                        pageSize: paginationParams.rowsPerPage,
                        emptyRowsWhenPaging: false,
                    }}
                    pages={Math.ceil(orgsForHCOrg.count / paginationParams.rowsPerPage)}
                    totalCount={orgsForHCOrg.count}
                    page={paginationParams.page}
                    onChangeRowsPerPage={pageSize => {
                        let data = Object.assign({}, paginationParams);
                        data['rowsPerPage'] = pageSize;
                        setPaginationParams(data);
                    }}
                    onChangePage={page => {
                        let data = Object.assign({}, paginationParams);
                        data['page'] = page;
                        setPaginationParams(data);
                    }}
                    onFilterChange={(filters, filter) => {
                        debounceOnFilterChange(filters);
                    }}
                />
            </Box>

            {org && (
                <Box>
                    <ConfirmationDialog
                        title='Remove'
                        message={<RemoveDialogMessage org={org} classes={classes}/>}
                        open={openRemoveConfirmationDialog}
                        setOpen={setOpenRemoveConfirmationDialog}
                        onConfirm={onRemove}
                    />
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        orgsForHCOrg: state.organisations.orgsForHCOrg,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllOrgsForHCOrg: (orgId, data, isHideLoader = false) => {
            return dispatch(getOrgsForHCOrgRequest(orgId, data, isHideLoader)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        removeAssignedOrg: (orgId, data) => {
            return dispatch(removeAssignedOrgRequest(orgId, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrnanizationsForHCOrg);
