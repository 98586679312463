import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Collapse,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Toolbar,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {getUserInfo, setAccessToken, setDepartment,} from '../../../../../utilities/storage';
import {setSelectedDept} from '../../../../Users/UsersActions';
import {refreshTokenRequest} from '../../../../Users/UsersApiActions';
import DepartmentsDialog from '../DepartmentsDialog/DepartmentsDialog';
import {ROLES, ORGANIZATION_TYPES} from '../../../../../utilities/constants';
import {useStyles} from './styles';

/**
 * function to render app header
 * @param history: history from router
 * @param open: boolean to open drawer
 * @param selectedDept: selected department
 * @param setSelectedDept: function to set selected department
 * @param depts: deptartments list
 * @param refreshToken: function to refresh token
 * @returns {JSX.Element}
 * @constructor
 */
const AppHeader = ({
                       history,
                       open,
                       selectedDept,
                       setSelectedDept,
                       depts,
                       refreshToken,
                   }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const [openTeam, setOpenTeam] = useState(false);
    const [openChooseDepartmentDialog, setOpenChooseDepartmentDialog] = useState(
        false
    );
    const [dept, setDept] = useState(null);

    useEffect(() => {
        if (selectedDept) {
            setDept(selectedDept);
        }
    }, [selectedDept]);

    /**
     * function to handle choose department dialog submit
     */
    const onDialogSubmit = () => {
        setSelectedDept(dept);
        setDepartment(dept);
        setOpenChooseDepartmentDialog(false);
        
        const data = {
            orgId: selectedDept && selectedDept.Organization_id,
        };

        refreshToken(data).then(res => {
            setAccessToken(res.access_token);
            
            if (role === ROLES.COACH) {
                history.push('/playStatusTeamRoster');
                window.location.reload();
            } else {
                history.push('/newUpdates');
                window.location.reload();
            }
        });
    };

    /**
     * function to handle choose department dialog close
     */
    const onDialogClose = () => {
        setOpenChooseDepartmentDialog(false);
        if (selectedDept) {
            setDept(selectedDept);
        }
    };

    return (
        <Box>
            <AppBar
                position='fixed'
                className={clsx(classes.appBar, open && classes.appBarShift)}
                color='inherit'
                elevation={0}
            >
                <Toolbar className={classes.toolbar} />

                <Box className={classes.profileMenuContainer}>
                    <Box>
                        <List className={classes.teamListContainer} disablePadding>
                            {selectedDept && (
                                <ListItem
                                    button
                                    onClick={() => {
                                        if (
                                            (depts && depts.length !== 1) ||
                                            (selectedDept &&
                                                selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER &&
                                                selectedDept.OrganizationPSTeamRoster === true &&
                                                role !== ROLES.COACH &&
                                                role !== ROLES.LIMITED_USER)
                                        ) {
                                            setOpenTeam(!openTeam);
                                        }
                                    }}
                                    className={classes.headerDeptContaier}
                                >
                                    <ListItemAvatar>
                                        <Avatar alt='profile' src={selectedDept.team_imageUrl}/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        className={classes.deptLabel}
                                        primary={selectedDept.Organization_name}
                                        secondary={selectedDept.team_name}
                                    />
                                    {(depts && depts.length !== 1) ||
                                    (selectedDept &&
                                        selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER &&
                                        selectedDept.OrganizationPSTeamRoster === true &&
                                        role !== ROLES.COACH &&
                                        role !== ROLES.LIMITED_USER) ? (
                                        <>{openTeam ? <ExpandLess/> : <ExpandMore/>}</>
                                    ) : (
                                        <></>
                                    )}
                                </ListItem>
                            )}

                            <Box>
                                <Collapse in={openTeam} timeout='auto' unmountOnExit>
                                    <List disablePadding>
                                        <Box className={classes.teamListItem}>
                                            {depts && depts.length !== 1 && (
                                                <Box>
                                                    <Button
                                                        fullWidth
                                                        disableElevation
                                                        size='small'
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={e => {
                                                            setOpenChooseDepartmentDialog(true);
                                                            setOpenTeam(false);
                                                        }}
                                                        className={classes.teamButton}
                                                    >
                                                        Change{' '}
                                                        {getUserInfo() &&
                                                        role === ROLES.COACH
                                                            ? 'Team'
                                                            : 'Department OR Location'}
                                                    </Button>
                                                </Box>
                                            )}

                                            {selectedDept &&
                                            selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER &&
                                            selectedDept.OrganizationPSTeamRoster === true &&
                                            role !== ROLES.COACH &&
                                            role !== ROLES.LIMITED_USER && (
                                                <Box
                                                    className={
                                                        depts && depts.length !== 1
                                                            ? classes.playStatusButtonContainer
                                                            : ''
                                                    }
                                                >
                                                    <Button
                                                        fullWidth
                                                        disableElevation
                                                        size='small'
                                                        variant='contained'
                                                        color='primary'
                                                        component={Link}
                                                        to='/playStatusTeamRoster'
                                                        onClick={e => setOpenTeam(false)}
                                                        className={classes.teamButton}
                                                    >
                                                        Play Status Team Roster
                                                    </Button>
                                                </Box>
                                            )}
                                        </Box>
                                    </List>
                                </Collapse>
                            </Box>
                        </List>
                    </Box>
                </Box>
            </AppBar>

            {openChooseDepartmentDialog && (
                <DepartmentsDialog
                    dept={dept}
                    setDept={setDept}
                    open={openChooseDepartmentDialog}
                    onSubmit={onDialogSubmit}
                    onCancel={onDialogClose}
                />
            )}
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        selectedDept: state.sirenUsers.selectedDept,
        depts: state.sirenUsers.depts.teams,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSelectedDept: data => {
            return dispatch(setSelectedDept(data));
        },
        refreshToken: data => {
            return dispatch(refreshTokenRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
