import {HIDE_LOADER, HIDE_SNACK_BAR, SHOW_LOADER, SHOW_SNACK_BAR,} from './UtilitiesActions';

const initialState = {
    showLoader: false,
    showSnackbar: false,
    snackbarVariant: '',
    snackbarMessage: '',
};

/**
 * reducer function to update state based on action
 * @param state: current state of the reducer
 * @param action: action to perform on the data
 * @returns {{update, updates: null}|{update: null, updates: null}|{update: null, updates: (null|*)}}
 * @constructor
 */
const UtilitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LOADER:
            return Object.assign({}, state, {
                showLoader: action.showLoader,
            });

        case HIDE_LOADER: {
            return Object.assign({}, state, {
                showLoader: action.showLoader,
            });
        }

        case HIDE_SNACK_BAR:
            return Object.assign({}, state, {
                showSnackbar: false,
            });

        case SHOW_SNACK_BAR: {
            return Object.assign({}, state, {
                showSnackbar: true,
                snackbarMessage: action.snackbarMessage,
                snackbarVariant: action.snackbarVariant,
            });
        }

        default:
            return state;
    }
};

export default UtilitiesReducer;
