import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        missingOppositeContent: {
            '&:before': {
                content: '""!important',
                flex: '0!important',
                padding: '0px!important',
            },
        },
        timelineDot: {
            backgroundColor: 'white',
            margin: '0px',
            padding: '0',
        },
        timelineAvatar: {
            height: '50px',
            width: '50px',
        },
        timelineContent: {
            padding: '0rem',
            paddingBottom: '1rem',
            marginLeft: '1rem',
            marginTop: '0.2rem',
        },
        timelineContentInnerContainer: {
            padding: '1rem',
        },
        dateContainer: {
            textAlign: 'right',
        },
        name: {
            fontSize: '12px',
            color: '#6f6f6f',
            marginBottom: '0.3rem',
            marginTop: '-0.3rem',
        },
        nameDate: {
            fontSize: '12px',
            color: '#6f6f6f',
        },
        description: {
            fontSize: '15px',
        },
        timeplineImage: {
            display: 'inline-block',
        },
        modalImage: {
            height: '100px',
            width: '100px',
            marginTop: '10px',
            marginRight: '10px',
            marginLeft: '5px',
            display: 'inline-block',
            objectFit: 'contain',
        },
    };
});
