import {GET_UPDATES, READ_UPDATE} from './NewUpdatesActions';

const initialState = {
    updates: null,
    update: null,
};

/**
 * reducer function to update state based on action
 * @param state: current state of the reducer
 * @param action: action to perform on the data
 * @returns {{update, updates: null}|{update: null, updates: null}|{update: null, updates: (null|*)}}
 * @constructor
 */
const UpdateReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_UPDATES:
            return {
                ...state,
                updates: action.updates,
            };

        case READ_UPDATE:
            return {
                ...state,
                update: action.update,
            };

        default:
            return state;
    }
};

export default UpdateReducer;
