import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {NavLink, useParams, useRouteMatch} from 'react-router-dom';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {AutocompleteValidator} from '../../../../utilities/validators';
import UtilHelper from '../../../../utilities/validators/UtilHelper';
import {getHCOrganisationsWithoutParamsRequest} from '../../../HealthcareOrganizations/HealthcareOrganizationsApiActions';
import {useStyles} from './styles';
import ConfirmationDialog from '../../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import ImageField from '../../../../utilities/components/ImageField/ImageField';
import {getIsTeamList, getUserInfo} from '../../../../utilities/storage';
import {dataURLtoFile} from '../../../../utilities/utilityFunctions';
import {
    addDeptRequest,
    getDeptByIdRequest,
    getDeptTypesRequest,
    getSignedUrlRequest,
    updateDeptRequest,
    uploadToS3Request,
} from '../../DepartmentsApiActions';
import {Box, Breadcrumbs, Button, Card, Grid, Icon, Paper, Typography,} from '@material-ui/core';
import {ROLES} from '../../../../utilities/constants';

/**
 * function to render department form
 * @param location: location from router
 * @param history: history from router
 * @param getDeptTypes: function to get department types
 * @param departmentTypeData: department types list
 * @param getAllHCOrgsWithoutParams: function to get healthcare organizations
 * @param hcorgsData: healthcare organization list
 * @param addDept: function to add department
 * @param getDeptById: function to get department by id
 * @param updateDept: function to update department
 * @param getSignedUrl: function to get signed url
 * @param uploadToS3: function to upload file on signed url
 * @returns {JSX.Element}
 * @constructor
 */
const DepartmentForm = ({
                            location,
                            history,
                            getDeptTypes,
                            departmentTypeData,
                            getAllHCOrgsWithoutParams,
                            hcorgsData,
                            addDept,
                            getDeptById,
                            updateDept,
                            getSignedUrl,
                            uploadToS3,
                        }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const params = useParams();
    const match = useRouteMatch();
    const [orgID, setOrgId] = useState(location.state.orgIdHistory);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [armsTeamId, setArmsTeamId] = useState('');
    const [departmentType, setDepartmentType] = useState(null);
    const [healthcareOrganization, setHealthcareOrganization] = useState(null);
    const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);
    const [rowImageFile, setRowImageFile] = useState(null);
    const [cropper, setCropper] = useState(null);
    const [croppedImageFile, setCroppedImageFile] = useState(null);
    const [showImageEditor, setShowImageEditor] = useState(false);
    const currentUrlPath = match.path.split(':')[0];

    useEffect(() => {
        ValidatorForm.addValidationRule('isValidMobile', value =>
            UtilHelper.validateMobile(value)
        );
        ValidatorForm.addValidationRule('isBlank', value =>
            UtilHelper.validateBlankSpace(value)
        );
        ValidatorForm.addValidationRule('isMaxLength', value =>
            UtilHelper.validateMaxLength(value)
        );

        return () => {
            ValidatorForm.removeValidationRule('isValidMobile');
            ValidatorForm.removeValidationRule('isBlank');
            ValidatorForm.removeValidationRule('isMaxLength');
        };
    }, []);

    useEffect(() => {
        if(!params.id){
            getDeptTypes().then(res => {
            });
        }
    }, [getDeptTypes, params.id]);

    useEffect(() => {
        if(!params.id){
            getAllHCOrgsWithoutParams().then(res => {
            });
        }
    }, [getAllHCOrgsWithoutParams, params.id]);

    useEffect(() => {
        if (params.id) {
            getDeptById(params.id).then(res => {

                getDeptTypes().then(resDeptTypes => {

                    getAllHCOrgsWithoutParams().then(resHcOrgs => {

                        const type = resDeptTypes.find(
                            type => type.id === res.DepartmentTypeId
                        );

                        const hcorg = resHcOrgs.rows.find(
                            hcorg => hcorg.id === res.HealthcareorgId
                        );

                        if (type) {
                            setDepartmentType(type);
                        }
    
                        if (hcorg) {
                            setHealthcareOrganization(hcorg);
                        }

                        setCroppedImageFile(res.imageUrl);
                        setOrgId(res.OrganizationId);
                        setName(res.name);
                        setDescription(res.desc);
                        setArmsTeamId(res.armsId);
                    });
                });
            });
        }
    }, [params.id, getDeptById, getDeptTypes, getAllHCOrgsWithoutParams]);

    const breadCrumbDataForAddOrgDeptForm = [
        {
            icon: 'business',
            name: 'Organizations',
            path: '/orgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/orgs/${location.state && location.state.orgIdHistory}`,
        },
        {
            name: `Add Department`,
            path: `/orgs/addDepartment`,
        },
    ];

    const breadCrumbDataForUpdateOrgDeptForm = [
        {
            icon: 'business',
            name: 'Organizations',
            path: '/orgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/orgs/${location.state && location.state.orgIdHistory}`,
        },
        {
            name: `Update Department`,
            path: `/orgs/departments/edit/${params.id}`,
        },
    ];

    const breadCrumbDataForAddHcorgOrgDeptForm = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: '/healthcareOrgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/${
                location.state && location.state.orgIdHistory
            }`,
        },
        {
            name: `Add Department`,
            path: `/healthcareOrgs/orgs/addDepartment`,
        },
    ];

    const breadCrumbDataForUpdateHcorgOrgDeptForm = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: '/healthcareOrgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/${
                location.state && location.state.orgIdHistory
            }`,
        },
        {
            name: `Update Department`,
            path: `/healthcareOrgs/orgs/departments/edit/${params.id}`,
        },
    ];

    const breadCrumbDataForUpdateAdminDeptForm = [
        {
            icon: 'apartment',
            name: 'Departments',
            path: '/departments',
        },
        {
            name: `Update Department`,
            path: `/departments/edit/${params.id}`,
        },
    ];

    /**
     * function to get breadcrumb data for current url
     */

    const getBreadcrumbData = (urlPath) => {
        switch(urlPath){
            case '/orgs/addDepartment':{
                return breadCrumbDataForAddOrgDeptForm
            }
            case '/orgs/departments/edit/':{
               return breadCrumbDataForUpdateOrgDeptForm
            }
            case '/healthcareOrgs/orgs/addDepartment':{
                return breadCrumbDataForAddHcorgOrgDeptForm
            }
            case '/healthcareOrgs/orgs/departments/edit/':{
                return breadCrumbDataForUpdateHcorgOrgDeptForm
            }
            case '/departments/edit/':{
               return breadCrumbDataForUpdateAdminDeptForm
            }
            default: {
                return []
            }
        }
    }

    /**
     * function to handle image crop
     */
    const onImageCrop = () => {
        const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL();
        const croppedImageFileObject = dataURLtoFile(
            croppedImageDataUrl,
            rowImageFile[0].file.name
        );

        const data = {
            dataURL: croppedImageDataUrl,
            file: croppedImageFileObject,
        };

        setCroppedImageFile(data);
        setShowImageEditor(false);
    };

    /**
     * function to add department on button click
     * @param e: event from button click
     */
    const onAdd = e => {
        e.preventDefault();

        const data = {
            OrganizationId: orgID,
            name: name,
            desc: description,
            armsId: armsTeamId,
        };

        if (healthcareOrganization) {
            data['HealthcareorgId'] = healthcareOrganization.id;
        }

        if(location.state && location.state.orgTypeIdHistory === 1){
            data['DepartmentTypeId'] = departmentType.id;
        }

        if (croppedImageFile) {
            const imageData = {
                fileName: croppedImageFile.file.name,
                fileType: `.${croppedImageFile.file.name.split('.').pop()}`,
            };

            getSignedUrl(imageData).then(res => {
                uploadToS3(res.urls, croppedImageFile.file).then(uploadRes => {
                    data['imageUrl'] = res.path;
                    addDept(data).then(res => {
                        history.push({
                            pathname: `${match.path.slice(
                                0,
                                match.path.lastIndexOf('/')
                            )}/departments/${res.id}`,
                            state: {
                                orgIdHistory: location.state.orgIdHistory,
                                orgNameHistory: location.state.orgNameHistory,
                                hcorgIdHistory: location.state.hcorgIdHistory,
                                hcorgNameHistory: location.state.hcorgNameHistory,
                            },
                        });
                    });
                });
            });
        } else {
            addDept(data).then(res => {
                history.push({
                    pathname: `${match.path.slice(
                        0,
                        match.path.lastIndexOf('/')
                    )}/departments/${res.id}`,
                    state: {
                        orgIdHistory: location.state.orgIdHistory,
                        orgNameHistory: location.state.orgNameHistory,
                        hcorgIdHistory: location.state.hcorgIdHistory,
                        hcorgNameHistory: location.state.hcorgNameHistory,
                    },
                });
            });
        }
    };

    /**
     * function to update department on button click
     * @param e: event from button click
     */
    const onUpdate = e => {
        e.preventDefault();

        if (typeof croppedImageFile !== 'string') {
            const imageData = {
                fileName: croppedImageFile.file.name,
                fileType: `.${croppedImageFile.file.name.split('.').pop()}`,
            };

            getSignedUrl(imageData).then(res => {
                uploadToS3(res.urls, croppedImageFile.file).then(uploadRes => {
                    const data = {
                        OrganizationId: orgID,
                        imageUrl: res.path,
                        name: name,
                        desc: description,
                        armsId: armsTeamId,
                        HealthcareorgId:
                            healthcareOrganization && healthcareOrganization.id,
                    };

                    if(location.state && location.state.orgTypeIdHistory === 1){
                        data['DepartmentTypeId'] = departmentType.id;
                    }

                    updateDept(params.id, data).then(res => {
                        history.goBack();
                    });
                });
            });
        } else {
            const data = {
                OrganizationId: orgID,
                name: name,
                desc: description,
                armsId: armsTeamId,
                HealthcareorgId: healthcareOrganization && healthcareOrganization.id,
            };

            if(location.state && location.state.orgTypeIdHistory === 1){
                data['DepartmentTypeId'] = departmentType.id;
            }

            updateDept(params.id, data).then(res => {
                history.goBack();
            });
        }
    };

    /**
     * function to handle cancel add or update
     */
    const handleCancel = () => {
        history.goBack();
    };

    return (
        <Box
            className={
                role === ROLES.SIRENMD_ADMIN &&
                getIsTeamList() === 'false'
                    ? ''
                    : classes.outerContainer
            }
        >
            <Box
                className={
                    role === ROLES.SIRENMD_ADMIN &&
                    getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainer
                        : getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainerForAdmins
                        : classes.breadcrumbsContainerForUsers
                }
                component={Paper}
                p={1}
            >
                <Breadcrumbs>
                    {getBreadcrumbData(currentUrlPath).map((crumb, index) => (
                        <Box
                            key={index}
                            component={NavLink}
                            exact
                            to={{
                                pathname: crumb.path,
                                state: {
                                    orgIdHistory: location.state && location.state.orgIdHistory,
                                    orgNameHistory:
                                        location.state && location.state.orgNameHistory,
                                    hcorgIdHistory:
                                        location.state && location.state.hcorgIdHistory,
                                    hcorgNameHistory:
                                        location.state && location.state.hcorgNameHistory,
                                    orgTypeIdHistory: location.state && location.state.orgTypeIdHistory,
                                },
                            }}
                            className={classes.breadcrumbItem}
                            activeClassName={classes.activeBreadcrumbItem}
                        >
                            {crumb.icon && (
                                <Icon className={classes.breadcrumpIcon}>{crumb.icon}</Icon>
                            )}
                            <Box className={classes.breadcrumpLabel}>{crumb.name}</Box>
                        </Box>
                    ))}
                </Breadcrumbs>
            </Box>

            <Box mt={2}>
                <ValidatorForm
                    noValidate
                    autoComplete='off'
                    onSubmit={params.id ? onUpdate : onAdd}
                >
                    <Box className={classes.addDepartmentContainer}>
                        <Box className={classes.addDepartmentLabelContainer}>
                            <Typography className={classes.addDepartmentLabel}>
                                {params.id ? 'Update' : 'Create'} Department
                            </Typography>
                        </Box>

                        <Box className={classes.addDepartmentFormContainer}>
                            <Box
                                component={Paper}
                                className={classes.addDepartmentFormInnerContainer}
                            >
                                <Grid container spacing={2} justify='space-around'>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <Box>
                                            <ImageField
                                                rowImageFile={rowImageFile}
                                                setRowImageFile={setRowImageFile}
                                                showImageEditor={showImageEditor}
                                                setShowImageEditor={setShowImageEditor}
                                                setCropper={setCropper}
                                                croppedImageFile={croppedImageFile}
                                                onImageCrop={onImageCrop}
                                                options={{
                                                    shape: 'rect',
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextValidator
                                                    label='Name *'
                                                    variant='outlined'
                                                    fullWidth
                                                    size='small'
                                                    value={name}
                                                    onChange={e => setName(e.target.value)}
                                                    validators={['required', 'isBlank', 'isMaxLength']}
                                                    errorMessages={[
                                                        'Name is required',
                                                        'Please enter name',
                                                        'Maximum 100 letters are accepted.',
                                                    ]}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextValidator
                                                    label='Description *'
                                                    variant='outlined'
                                                    fullWidth
                                                    size='small'
                                                    value={description}
                                                    onChange={e => setDescription(e.target.value)}
                                                    validators={['required', 'isBlank', 'isMaxLength']}
                                                    errorMessages={[
                                                        'Description is required',
                                                        'Please enter description',
                                                        'Maximum 100 letters are accepted.',
                                                    ]}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextValidator
                                                    label='ARMS Team ID'
                                                    variant='outlined'
                                                    fullWidth
                                                    size='small'
                                                    value={armsTeamId}
                                                    onChange={e => setArmsTeamId(e.target.value)}
                                                />
                                            </Grid>

                                            {location.state && location.state.orgTypeIdHistory === 1 && (
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <AutocompleteValidator
                                                        fullWidth
                                                        size='small'
                                                        value={departmentType}
                                                        onChange={(e, value) => {
                                                            setDepartmentType(value);
                                                        }}
                                                        options={
                                                            departmentTypeData ? departmentTypeData : []
                                                        }
                                                        getOptionLabel={option => option.description}
                                                        getOptionSelected={(option, value) =>
                                                            option.id === value.id
                                                        }
                                                        renderInput={params => (
                                                            <TextValidator
                                                                {...params}
                                                                label='Department Type *'
                                                                variant='outlined'
                                                            />
                                                        )}
                                                        validators={['required']}
                                                        errorMessages={['Department type is required']}
                                                    />
                                                </Grid>
                                            )}

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <AutocompleteValidator
                                                    fullWidth
                                                    size='small'
                                                    value={healthcareOrganization}
                                                    onChange={(e, value) => {
                                                        setHealthcareOrganization(value);
                                                    }}
                                                    options={hcorgsData.rows ? hcorgsData.rows : []}
                                                    getOptionLabel={option => option.name}
                                                    getOptionSelected={(option, value) =>
                                                        option.id === value.id
                                                    }
                                                    renderInput={params => (
                                                        <TextValidator
                                                            {...params}
                                                            label='Healthcare Organization'
                                                            variant='outlined'
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>

                    <Box className={classes.footerContainer}>
                        <Box
                            component={Card}
                            raised
                            className={classes.footerContainerInner}
                        >
                            <Box>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    disableElevation={true}
                                    onClick={() => setOpenCancelConfirmation(true)}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    variant='contained'
                                    color='primary'
                                    disableElevation={true}
                                    type='submit'
                                    className={classes.saveButton}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </ValidatorForm>
            </Box>

            <Box>
                <ConfirmationDialog
                    open={openCancelConfirmation}
                    setOpen={setOpenCancelConfirmation}
                    title='Cancel'
                    message='Are you sure you want to cancel?'
                    onConfirm={handleCancel}
                />
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        departmentTypeData: state.departments.deptTypes,
        hcorgsData: state.hcorganisations.hcorgs,
        org: state.organisations.org,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getDeptTypes: () => {
            return dispatch(getDeptTypesRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getAllHCOrgsWithoutParams: () => {
            return dispatch(getHCOrganisationsWithoutParamsRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        addDept: data => {
            return dispatch(addDeptRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getDeptById: id => {
            return dispatch(getDeptByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateDept: (id, data) => {
            return dispatch(updateDeptRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getSignedUrl: data => {
            return dispatch(getSignedUrlRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        uploadToS3: (url, file) => {
            return dispatch(uploadToS3Request(url, file)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentForm);
