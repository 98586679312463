import {getDepartment, getUserInfo} from '../../utilities/storage';

import {
    ADD_SIREN_USER,
    GET_ASSIGNED_ORGS,
    GET_ROLES,
    GET_SIREN_USERS,
    GET_SIREN_USERS_WITHOUT_PARAMS,
    GET_STATES,
    GET_TIMEZONES,
    GET_USER_BY_ID,
    GET_USER_DEPTS,
    GET_USERS_FOR_ADMIN,
    REFRESH_TOKEN,
    SET_SELECTED_DEPT,
    TOGGLE_USER_ACTIVATION,
    UPDATE_USER,
    TOGGLE_EMAIL_UPDATE
} from './UsersActions';

const initialState = {
    loggedInUser: getUserInfo() ? getUserInfo() : null,
    users: [],
    user: null,
    roles: [],
    depts: [],
    selectedDept: getDepartment() ? getDepartment() : null,
    states: [],
    timezones: [],
    orgs: [],
    token: null,
};


/**
 * reducer function to update state based on action
 * @param state: current state of the reducer
 * @param action: action to perform on the data
 * @returns {{update, updates: null}|{update: null, updates: null}|{update: null, updates: (null|*)}}
 * @constructor
 */
const SirenUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SIREN_USERS:
            return Object.assign({}, state, {
                users: action.users,
            });

        case GET_USERS_FOR_ADMIN:
            return Object.assign({}, state, {
                users: action.users,
            });

        case GET_SIREN_USERS_WITHOUT_PARAMS:
            return Object.assign({}, state, {
                users: action.users,
            });

        case ADD_SIREN_USER:
            return {
                ...state,
            };

        case GET_USER_BY_ID:
            return Object.assign({}, state, {
                user: action.user,
            });

        case UPDATE_USER: {
            return Object.assign({}, state, {
                user: action.user,
            });
        }

        case GET_ROLES:
            return Object.assign({}, state, {
                roles: action.roles,
            });

        case TOGGLE_USER_ACTIVATION:
            return {
                ...state,
                user: {
                    ...state.user,
                    enabled: action.user.enabled,
                    isBanned: action.user.isBanned
                },
            };
        case GET_USER_DEPTS:
            return Object.assign({}, state, {
                depts: action.depts,
            });

        case SET_SELECTED_DEPT:
            return Object.assign({}, state, {
                selectedDept: action.selectedDept,
            });

        case GET_STATES:
            return Object.assign({}, state, {
                states: action.states,
            });

        case GET_TIMEZONES:
            return Object.assign({}, state, {
                timezones: action.timezones,
            });

        case GET_ASSIGNED_ORGS:
            return Object.assign({}, state, {
                orgs: action.orgs,
            });

        case REFRESH_TOKEN:
            return Object.assign({}, state, {
                token: action.token,
            });

        case TOGGLE_EMAIL_UPDATE:
            console.log(action.emailUpdate);
            return {
                ...state,
                 user: {
                    ...state.user,
                    allUpdates: action.emailUpdate.allUpdates,
                },
            };

        default:
            return state;
    }
};

export default SirenUsersReducer;
