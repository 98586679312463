import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import clsx from 'clsx';
import PersonIcon from '@material-ui/icons/Person';
import {NavLink} from 'react-router-dom';
import {Avatar, Badge, Box, Button, Drawer, IconButton, List, ListItem, Tooltip, Typography,} from '@material-ui/core';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../../../../../assets/logo.png';
import ConfirmationDialog from '../../../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import {getAdmin, getDepartment, getIsTeamList, getUserInfo, logoutUser,} from '../../../../../utilities/storage';
import {useStyles} from './styles';
import {getLoggedInUserByIdRequest} from '../../../../Auth/AuthApiActions';
import {
    bothAdminMenu,
    coachMenu,
    deptAdminMenu,
    orgAdminMenu,
    superAdminMenu,
    userMenu,
    ROLES,
    ORGANIZATION_TYPES
} from '../../../../../utilities/constants';

/**
 * function to render app drawer
 * @param history: history from router
 * @param open: boolean to open drawer
 * @param handleDrawerOpenClose: function to open or close drawer
 * @param getUserById: function to get user by id
 * @param user: user details
 * @param selectedDept: selected departments
 * @param updates: updates list
 * @returns {JSX.Element}
 * @constructor
 */
const AppDrawer = ({
                       history,
                       open,
                       handleDrawerOpenClose,
                       getUserById,
                       user,
                       selectedDept,
                       updates,
                   }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;

    const [
        openLogoutConfirmationDialog,
        setOpenLogoutConfirmationDialog,
    ] = useState(false);

    useEffect(() => {
        getUserById(getUserInfo().id).then(res => {
        });
    }, [getUserById]);

    /**
     * function to logout on button click
     */
    const onLogout = () => {
        logoutUser();
        history.push('/login');
        window.location.reload();
    };

    return (
        <Drawer
            variant='permanent'
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <Box className={classes.drawerContentContainer}>
                <Box className={classes.drawerInnerContentContainer}>
                    <Box>
                        <Box className={classes.toolbar}>
                            <IconButton
                                color='inherit'
                                aria-label='open drawer'
                                onClick={handleDrawerOpenClose}
                                edge='start'
                            >
                                <MenuIcon/>
                            </IconButton>
                            {open && <img src={logo} className={classes.logo} alt='logo'/>}
                        </Box>

                        {role === ROLES.SIRENMD_ADMIN && user && (
                            <Box className={classes.userProfileOuterContainer}>
                                <ListItem
                                    button
                                    classes={{root: classes.userProfileContainer}}
                                >
                                    <Button
                                        classes={{label: classes.buttonLabel}}
                                        className={classes.profileButton}
                                        component={NavLink}
                                        to={`/profile`}
                                        activeClassName={classes.active}
                                    >
                                        <Box className={classes.userAvatarContainer}>
                                            <Avatar src={user.imageUrl}/>
                                        </Box>
                                        <Box className={classes.userProfileDetails}>
                                            <Typography
                                                className={clsx(classes.name, !open && classes.hide)}
                                                variant='h2'
                                            >
                                                {user.firstName} {user.lastName}
                                            </Typography>
                                            <Typography
                                                className={clsx(classes.role, !open && classes.hide)}
                                            >
                                                {user.Roles[0].name}
                                            </Typography>
                                        </Box>
                                    </Button>
                                </ListItem>
                            </Box>
                        )}

                        {role !== ROLES.SIRENMD_ADMIN && user && (
                            <Box className={classes.userProfileOuterContainer}>
                                <ListItem
                                    disabled={
                                        getIsTeamList() &&
                                        getIsTeamList() === 'true' &&
                                        !getDepartment()
                                    }
                                    classes={{root: classes.userProfileContainer}}
                                    component={NavLink}
                                    to={`/profile`}
                                    activeClassName={classes.active}
                                    button
                                >
                                    <Button
                                        classes={{label: classes.buttonLabel}}
                                        className={classes.profileButton}
                                    >
                                        <Box className={classes.userAvatarContainer}>
                                            <Avatar src={user.imageUrl}/>
                                        </Box>
                                        <Box className={classes.userProfileDetails}>
                                            <Typography
                                                className={clsx(classes.name, !open && classes.hide)}
                                                variant='h2'
                                            >
                                                {user.firstName} {user.lastName}
                                            </Typography>

                                            <Typography
                                                className={clsx(classes.role, !open && classes.hide)}
                                            >
                                                {selectedDept && selectedDept.headDoctor && 'Head'}{' '}
                                                {user.Roles[0].name}
                                            </Typography>
                                        </Box>
                                    </Button>
                                </ListItem>
                            </Box>
                        )}

                        <Box
                            className={
                                role === ROLES.SIRENMD_ADMIN &&
                                getIsTeamList() === 'true'
                                    ? classes.menuHeight
                                    : ''
                            }
                        >
                            <List disablePadding>
                                {role === ROLES.SIRENMD_ADMIN &&
                                getIsTeamList() === 'false' &&
                                superAdminMenu.map((menu, index) => {
                                    return (
                                        <ListItem
                                            key={index}
                                            className={classes.item}
                                            disableGutters
                                        >
                                            <Button
                                                activeClassName={classes.active}
                                                to={menu['path']}
                                                className={classes.button}
                                                component={NavLink}
                                            >
                                                <Tooltip title={menu['name']} placement='right'>
                                                    <Box className={classes.icon}>{menu['icon']}</Box>
                                                </Tooltip>
                                                <Typography variant='body1'>
                                                    {open ? `${menu['name']}` : ''}
                                                </Typography>
                                            </Button>
                                        </ListItem>
                                    );
                                })}

                                {role !== ROLES.COACH &&
                                getIsTeamList() === 'true' &&
                                userMenu.map((menu, index) => {
                                    return (
                                        <ListItem
                                            disabled={!getDepartment()}
                                            key={index}
                                            className={classes.item}
                                            disableGutters
                                            component={NavLink}
                                            to={menu['path']}
                                            activeClassName={classes.active}
                                            button
                                        >
                                            <Button className={classes.button}>
                                                {index !== 2 && (
                                                    <Tooltip title={menu['name']} placement='right'>
                                                        <Box className={classes.icon}>
                                                            {menu['isBadge'] ? (
                                                                <Badge
                                                                    badgeContent={
                                                                        updates
                                                                            ? updates.count
                                                                            : selectedDept
                                                                            ? selectedDept.notifications
                                                                            : 0
                                                                    }
                                                                    color='secondary'
                                                                    showZero={false}
                                                                >
                                                                    {menu['icon']}
                                                                </Badge>
                                                            ) : (
                                                                menu['icon']
                                                            )}
                                                        </Box>
                                                    </Tooltip>
                                                )}
                                                {index === 2 && (
                                                    <Tooltip title={selectedDept &&
                                                        selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER ? 'Patients' : 'Athletes'} placement='right'>
                                                        <Box className={classes.icon}>
                                                            {selectedDept &&
                                                            selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER && (
                                                                <PersonIcon/>
                                                            )}

                                                            {selectedDept &&
                                                            selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER &&
                                                            menu['icon']}

                                                            {!selectedDept && menu['icon']}
                                                        </Box>
                                                    </Tooltip>
                                                )}
                                                {index !== 2 && (
                                                    <Typography variant='body1'>
                                                        {open ? `${menu['name']}` : ''}
                                                    </Typography>
                                                )}
                                                {index === 2 && !selectedDept && (
                                                    <Typography variant='body1'>
                                                        {open ? `Athlete` : ''}
                                                    </Typography>
                                                )}
                                                {index === 2 &&
                                                selectedDept &&
                                                selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER && (
                                                    <Typography variant='body1'>
                                                        {open ? `Patients` : ''}
                                                    </Typography>
                                                )}
                                                {index === 2 &&
                                                selectedDept &&
                                                selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER && (
                                                    <Typography variant='body1'>
                                                        {open ? `Athletes` : ''}
                                                    </Typography>
                                                )}
                                            </Button>
                                        </ListItem>
                                    );
                                })}

                                {role === ROLES.COACH &&
                                coachMenu.map((menu, index) => {
                                    return (
                                        <ListItem
                                            disabled={
                                                getIsTeamList() &&
                                                getIsTeamList() === 'true' &&
                                                !getDepartment()
                                            }
                                            key={index}
                                            className={classes.item}
                                            disableGutters
                                            component={NavLink}
                                            to={menu['path']}
                                            activeClassName={classes.active}
                                            button
                                        >
                                            <Button className={classes.button}>
                                                <Tooltip title={menu['name']} placement='right'>
                                                    <Box className={classes.icon}>{menu['icon']}</Box>
                                                </Tooltip>
                                                <Typography variant='body1'>
                                                    {open ? `${menu['name']}` : ''}
                                                </Typography>
                                            </Button>
                                        </ListItem>
                                    );
                                })}

                                {((getAdmin() && getIsTeamList() === 'true') ||
                                    (role === ROLES.SIRENMD_ADMIN &&
                                        getIsTeamList() === 'true')) && (
                                    <Box className={classes.submenuHeading}>
                                        <Typography variant='subtitle2'>
                                            {open ? 'Administration' : ''}
                                        </Typography>
                                    </Box>
                                )}

                                {role !== ROLES.SIRENMD_ADMIN &&
                                role !== ROLES.COACH &&
                                getAdmin() &&
                                getAdmin().isOrgAdmin === true &&
                                orgAdminMenu.map((menu, index) => {
                                    return (
                                        <ListItem
                                            disabled={
                                                getIsTeamList() &&
                                                getIsTeamList() === 'true' &&
                                                !getDepartment()
                                            }
                                            key={index}
                                            className={classes.item}
                                            disableGutters
                                            component={NavLink}
                                            to={menu['path']}
                                            activeClassName={classes.active}
                                            button
                                        >
                                            <Button className={classes.button}>
                                                <Tooltip title={menu['name']} placement='right'>
                                                    <Box className={classes.icon}>{menu['icon']}</Box>
                                                </Tooltip>
                                                <Typography variant='body1'>
                                                    {open ? `${menu['name']}` : ''}
                                                </Typography>
                                            </Button>
                                        </ListItem>
                                    );
                                })}

                                {role !== ROLES.SIRENMD_ADMIN &&
                                role !== ROLES.COACH &&
                                getAdmin() &&
                                getAdmin().isDeptAdmin === true &&
                                deptAdminMenu.map((menu, index) => {
                                    return (
                                        <ListItem
                                            disabled={
                                                getIsTeamList() &&
                                                getIsTeamList() === 'true' &&
                                                !getDepartment()
                                            }
                                            key={index}
                                            className={classes.item}
                                            disableGutters
                                            component={NavLink}
                                            to={menu['path']}
                                            activeClassName={classes.active}
                                            button
                                        >
                                            <Button className={classes.button}>
                                                <Tooltip title={menu['name']} placement='right'>
                                                    <Box className={classes.icon}>{menu['icon']}</Box>
                                                </Tooltip>
                                                <Typography variant='body1'>
                                                    {open ? `${menu['name']}` : ''}
                                                </Typography>
                                            </Button>
                                        </ListItem>
                                    );
                                })}

                                {role !== ROLES.SIRENMD_ADMIN &&
                                role !== ROLES.COACH &&
                                getAdmin() &&
                                getAdmin().isBothAdmin === true &&
                                bothAdminMenu.map((menu, index) => {
                                    return (
                                        <ListItem
                                            disabled={
                                                getIsTeamList() &&
                                                getIsTeamList() === 'true' &&
                                                !getDepartment()
                                            }
                                            key={index}
                                            className={classes.item}
                                            disableGutters
                                            component={NavLink}
                                            to={menu['path']}
                                            activeClassName={classes.active}
                                            button
                                        >
                                            <Button className={classes.button}>
                                                <Tooltip title={menu['name']} placement='right'>
                                                    <Box className={classes.icon}>{menu['icon']}</Box>
                                                </Tooltip>
                                                <Typography variant='body1'>
                                                    {open ? `${menu['name']}` : ''}
                                                </Typography>
                                            </Button>
                                        </ListItem>
                                    );
                                })}

                                {role === ROLES.SIRENMD_ADMIN &&
                                getIsTeamList() === 'true' &&
                                superAdminMenu.map((menu, index) => {
                                    return (
                                        <ListItem
                                            key={index}
                                            className={classes.item}
                                            disableGutters
                                            disabled={
                                                getIsTeamList() &&
                                                getIsTeamList() === 'true' &&
                                                !getDepartment()
                                            }
                                            component={NavLink}
                                            to={menu['path']}
                                            activeClassName={classes.active}
                                            button
                                        >
                                            <Button className={classes.button}>
                                                <Tooltip title={menu['name']} placement='right'>
                                                    <Box className={classes.icon}>{menu['icon']}</Box>
                                                </Tooltip>
                                                <Typography variant='body1'>
                                                    {open ? `${menu['name']}` : ''}
                                                </Typography>
                                            </Button>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Box>
                    </Box>

                    <Box
                        className={classes.logoutButtonContainer}
                    >
                        <ListItem className={classes.item} button disableGutters>
                            <Button
                            classes={{root: clsx({
                                [classes.logoutButtonOpen]: open,
                                [classes.logoutButtonClose]: !open,
                            })}}
                                onClick={() => setOpenLogoutConfirmationDialog(true)}
                            >
                                <Box className={classes.icon}>
                                    <Tooltip title='Logout' placement='right'>
                                        <ExitToAppRoundedIcon/>
                                    </Tooltip>
                                </Box>
                                <Typography
                                    variant='body1'
                                    className={clsx(!open && classes.hide)}
                                >
                                    Logout
                                </Typography>
                            </Button>
                        </ListItem>
                    </Box>
                </Box>
            </Box>

            <Box>
                <ConfirmationDialog
                    title='Logout'
                    message='Are you sure you want to logout?'
                    open={openLogoutConfirmationDialog}
                    setOpen={setOpenLogoutConfirmationDialog}
                    onConfirm={onLogout}
                />
            </Box>
        </Drawer>
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.loggedInUser,
        selectedDept: state.sirenUsers.selectedDept,
        updates: state.newUpdates.updates,
    };
};

const mapDispatchToProp = dispatch => {
    return {
        getUserById: id => {
            return dispatch(getLoggedInUserByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProp)(AppDrawer);
