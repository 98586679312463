import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid,} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {AutocompleteValidator} from '../../../../../../utilities/validators';
import {
    getAthleteByIdRequest,
    getBodyLocationsRequest,
    updateInjuryReportRequest
} from '../../../../AthletesApiActions';
import {useStyles} from './styles';
import {playingStatusList} from '../../../../../../utilities/constants';

/**
 * function to render injury report dialog
 * @param openInjuryReportForm: opens injury report dialog
 * @param setOpenInjuryReportForm: function to change open state
 * @param getBodyLocations: function to get body locations
 * @param bodyLocations: body locations list
 * @param updateInjuryReport: function to update injury report
 * @param getAthleteById: function to get athlete by id
 * @returns {JSX.Element}
 * @constructor
 */
const InjuryReport = ({
                          openInjuryReportForm,
                          setOpenInjuryReportForm,
                          getBodyLocations,
                          bodyLocations,
                          updateInjuryReport,
                          getAthleteById,
                      }) => {
    const classes = useStyles();
    const params = useParams();
    const [playingStatus, setPlayingStatus] = useState(null);
    const [bodyLocation, setBodyLocation] = useState(null);
    const [comments, setComments] = useState('');



    useEffect(() => {
        getBodyLocations().then(() => {
        });
    }, [getBodyLocations]);

    useEffect(() => {
        getAthleteById(params.id).then(res => {
            if (bodyLocations) {
                const selectedBodyLocation = bodyLocations.rows.find(
                    bodyLocation => bodyLocation.id === res.bodyPart
                );
                setBodyLocation(selectedBodyLocation);
            }

            if (res.playingStatus) {
                setPlayingStatus(playingStatusList[0]);
            }else{
                setPlayingStatus(playingStatusList[1]);
            }

            setComments(res.comments);
        });
        //eslint-disable-next-line
    }, [getAthleteById, params.id, bodyLocations]);

    /**
     * function to handle injury report close
     */
    const handleInjuryReportFormClose = () => {
        setOpenInjuryReportForm(false);
        setPlayingStatus(null);
        setBodyLocation(null);
        setComments('');
    };

    /**
     * function to handle injury report submit
     */
    const onSubmit = () => {
        const data = {
            playingStatus: playingStatus.id,
            comments: comments,
            bodyPart: bodyLocation.id,
        };

        updateInjuryReport(params.id, data).then(() => {
            setPlayingStatus(null);
            setBodyLocation(null);
            setComments('');
            setOpenInjuryReportForm(false);

            getAthleteById(params.id).then(() => {
            });
        });
    };

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={openInjuryReportForm}
            onClose={handleInjuryReportFormClose}
        >
            <ValidatorForm onSubmit={onSubmit}>
                <DialogTitle>Injury Report</DialogTitle>
                <Divider/>

                <DialogContent classes={{root: classes.contentConatiner}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <AutocompleteValidator
                                disableClearable
                                fullWidth
                                size='small'
                                options={playingStatusList}
                                value={playingStatus}
                                onChange={(e, value) => setPlayingStatus(value)}
                                getOptionLabel={option => option.desc}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={params => (
                                    <TextValidator
                                        {...params}
                                        label='Playing Status'
                                        variant='outlined'
                                    />
                                )}
                                validators={['required']}
                                errorMessages={['Playing status is required']}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <AutocompleteValidator
                                disableClearable
                                fullWidth
                                size='small'
                                options={
                                    bodyLocations && bodyLocations.rows ? bodyLocations.rows : []
                                }
                                value={bodyLocation}
                                onChange={(e, value) => setBodyLocation(value)}
                                getOptionLabel={option => option.bodypart}
                                getOptionSelected={(option, value) => {
                                    if (option && value) {
                                        return option.id === value.id;
                                    } else {
                                        return true;
                                    }
                                }}
                                renderInput={params => (
                                    <TextValidator
                                        {...params}
                                        label='Body Location'
                                        variant='outlined'
                                    />
                                )}
                                validators={['required']}
                                errorMessages={['Body location is required']}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextValidator
                                fullWidth
                                label='Comments'
                                size='small'
                                variant='outlined'
                                multiline
                                rows={6}
                                rowsMax={6}
                                value={comments}
                                onChange={e => setComments(e.target.value)}
                                validators={['required']}
                                errorMessages={['Comments are required']}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions classes={{root: classes.actionsContainer}}>
                    <Button
                        onClick={handleInjuryReportFormClose}
                        variant='outlined'
                        color='primary'
                    >
                        Cancel
                    </Button>
                    <Button type='submit' variant='contained' color='primary'>
                        Save
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
};

const mapStateToProps = state => {
    return {
        bodyLocations: state.athletes.bodyLocations,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getBodyLocations: () => {
            return dispatch(getBodyLocationsRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateInjuryReport: (athleteId, data) => {
            return dispatch(updateInjuryReportRequest(athleteId, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getAthleteById: id => {
            return dispatch(getAthleteByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InjuryReport);
