import HttpRequest from '../../utilities/httpRequest';
import {
    addCaregiver,
    addCaregiverFromExistingUser,
    getCaregiversForDept,
    getCaregiversForHCOrg,
    getCaregiversForOrg,
    getUserByEmail,
    removeCaregiverFromDept,
    toggleCaregiverHead,
    toggleCaregiverPrivileges,
} from './CaregiverActions';
import {hideLoader, showLoader, showSnackbar,} from '../../utilities/UtilitiesActions';

/**
 * function to add caregiver
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const addCaregiverRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = 'department/caregiver';

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Caregiver created successfully', 'success'));

                dispatch(addCaregiver(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(`${error.response.data.error}`, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to add caregiver form existing user
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const addCaregiverFromExistingUserRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = 'department/careGiverAlreadyExist';

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Caregiver created successfully', 'success'));
                dispatch(addCaregiverFromExistingUser(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(`${error.response.data.error}`, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get caregivers of a organization
 * @param id: id for api request
 * @param data: data for api request
 * @param isHideLoader: isHideLoader for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getCaregiversForOrgRequest = (id, data, isHideLoader) => {
    let offset = data.page * data.rowsPerPage;
    let limit = data.rowsPerPage;
    let searchData = data.searchData ? data.searchData : '';

    return dispatch => {
        if (!isHideLoader) {
            dispatch(showLoader());
        }

        const url = `department/org/${id}/caregiver?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
            searchData
        )}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                let resData = res.data.data;
                resData.rows = resData.rows.map(user => {
                    user.name = `${user.firstName} ${user.lastName}`;
                    return user;
                });

                dispatch(getCaregiversForOrg(resData));

                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.resolve(resData);
            },
            error => {
                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get caregivers of a healthcare organization
 * @param id: id for api request
 * @param data: data for api request
 * @param isHideLoader: isHideLoader for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getCaregiversForHCOrgRequest = (id, data, isHideLoader) => {
    let offset = data.page * data.rowsPerPage;
    let limit = data.rowsPerPage;
    let searchData = data.searchData ? data.searchData : '';

    return dispatch => {
        if (!isHideLoader) {
            dispatch(showLoader());
        }

        const url = `healthcareorg/${id}/caregiver?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
            searchData
        )}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                let resData = res.data.data;
                resData.rows = resData.rows.map(user => {
                    user.name = `${user.firstName} ${user.lastName}`;
                    return user;
                });

                dispatch(getCaregiversForHCOrg(resData));

                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.resolve(resData);
            },
            error => {
                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get caregivers of a department
 * @param id: id for api request
 * @param data: data for api request
 * @param isHideLoader: isHideLoader for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getCaregiversForDeptRequest = (id, data, isHideLoader) => {
    let offset = data.page * data.rowsPerPage;
    let limit = data.rowsPerPage;
    let searchData = data.searchData ? data.searchData : '';

    return dispatch => {
        if (!isHideLoader) {
            dispatch(showLoader());
        }

        const url = `department/${id}/caregiver?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
            searchData
        )}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                let resData = res.data.data;
                resData.rows = resData.rows.map(user => {
                    user.name = `${user.firstName} ${user.lastName}`;
                    return user;
                });

                dispatch(getCaregiversForDept(resData));

                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.resolve(resData);
            },
            error => {
                dispatch(hideLoader());

                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to remove caregiver from department
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const removeCaregiverFromDeptRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());

        const url = `department/removeCaregiverToDept/${id}`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Caregiver removed successfully', 'success'));

                dispatch(removeCaregiverFromDept(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(
                    showSnackbar('Caregiver not removed, try again later', 'error')
                );
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to toggle caregiver head
 * @param caregiverId: caregiverId for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const toggleCaregiverHeadRequest = (caregiverId, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `teamCaregiver/${caregiverId}/enabledOrDisabledHeadDoctor`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Successfully changed the status', 'success'));
                dispatch(toggleCaregiverHead(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to toggle caregiver clinical privileges
 * @param caregiverId: caregiverId for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const toggleCaregiverPrivilegesRequest = (caregiverId, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `healthcareOrgCaregiver/${caregiverId}/enabledOrDisabledClinicalPrevilige`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                res.data.data.clinicalPrivileges &&
                dispatch(showSnackbar('Clinical privileges enabled.', 'success'));
                !res.data.data.clinicalPrivileges &&
                dispatch(showSnackbar('Clinical privileges disabled.', 'success'));

                dispatch(toggleCaregiverPrivileges(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Error, try again', 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get user by email
 * @param email: email for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getUserByEmailRequest = email => {
    return dispatch => {
        dispatch(showLoader());
        const url = `users/${email}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());

                dispatch(getUserByEmail(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());

                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get signed url
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getSignedUrlRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = `users/profilePic/signedURL`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};

/**
 * function to upload file on signed url
 * @param url: url for api request
 * @param file: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const uploadToS3Request = (url, file) => {
    return dispatch => {
        dispatch(showLoader());
        return new HttpRequest(dispatch, url, 'put', file)
            .s3Upload()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};
