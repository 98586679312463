import React from 'react';
import {Link} from 'react-router-dom';
import {Box, Grid, Paper, Typography} from '@material-ui/core';
import {useStyles} from './styles';
import defaultUser from '../../../../../../assets/user.png';
import ImageLoader from 'react-load-image';
import Preloader from '../../../../../../utilities/components/Preloader/Preloader';
import {APP_URLS} from '../../../../../../utilities/constants';

/**
 * function to render athlete items
 * @param caseItem: case item details
 * @returns {JSX.Element}
 * @constructor
 */
const AthleteItem = ({caseItem}) => {
    const classes = useStyles();

    return (
        <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
            <Box component={Paper} className={classes.outerContainer}>
                <Box
                    className={classes.mainAthleteContainer}
                    component={Link}
                    to={`${APP_URLS.ATHLETES}/${caseItem.id}`}
                >
                    <Box className={classes.profileImageContainer}>
                        <Box className={classes.profileInnerContainer}>
                            <ImageLoader
                                src={caseItem.imageUrl ? caseItem.imageUrl : defaultUser}
                            >
                                <img alt={caseItem.id} className={classes.profileImage}/>
                                <div className={classes.profileImageError}>{caseItem.id}</div>
                                <Preloader alt={caseItem.id} className={classes.profileImage}/>
                            </ImageLoader>
                        </Box>
                    </Box>
                    <Box>
                        <Typography className={classes.name}>
                            {`${caseItem.firstName} ${caseItem.lastName}`}
                        </Typography>
                    </Box>

                    {caseItem.jerseyNum && (
                        <Box className={classes.headerLabel}>
                            {caseItem.jerseyNum ? `Jersey no: ${caseItem.jerseyNum}` : ''}
                        </Box>
                    )}
                </Box>
            </Box>
        </Grid>
    );
};

export default AthleteItem;
