import React, {useEffect, useState} from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {connect} from 'react-redux';
import clsx from 'clsx';
import {useParams} from 'react-router-dom';
import RemoveIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Chip,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import {Autocomplete, Timeline, ToggleButton, ToggleButtonGroup,} from '@material-ui/lab';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import NoteForm from '../NoteForm/NoteForm';
import NoteTimeline from './components/NoteItem/NoteItem';
import {getUserInfo} from '../../../../utilities/storage';
import {
    addLogItemRequest,
    findDicomImageRequest,
    getCaregiversOfDeptRequest,
    getCaseByIdRequest,
    getCaseStatusForDoctorRequest,
    getCaseStatusRequest,
    getDicomUrlRequest,
    getLogItemsByCaseIdRequest,
    getSignedUrlRequest,
    updateCaseCaregiversRequest,
    updateCaseRequest,
    uploadToS3Request,
} from '../../CasesApiAction';
import {useStyles} from './styles';
import defaultUser from '../../../../assets/user.png';
import CaseCloseConfirmationDialog from './components/CaseCloseConfirmationDialog/CaseCloseConfirmationDialog';
import MessageDialog from '../../../../utilities/components/MessageDialog/MessageDialog';
import {ROLES, ORGANIZATION_TYPES} from '../../../../utilities/constants';


/**
 * function to render case form
 * @param history: history from router
 * @param selectedDept: selected department
 * @param getCaseById: function to get case by id
 * @param singleCase: case details
 * @param updateCase: function to update case
 * @param getCaseStatus: function to get case status
 * @param getCaseStatusForDoctor: function to get case status for health provider organization departments
 * @param caseStatus: case status list
 * @param getCaregiversOfDept: function to get caregivers of departments
 * @param getLogItemsByCaseId: function to get log items by case id
 * @param logItems: log items list
 * @param addLogItem: function to add log item
 * @param updateCaseCaregivers: function to update case caregivers
 * @param getDicomUrl: function to get dicom url
 * @param findDicomImage: function to find dicom image
 * @param getSignedUrl: function to get signed url
 * @param uploadToS3: function to upload file on signed url
 * @returns {JSX.Element}
 * @constructor
 */
const Case = ({
                  history,
                  selectedDept,
                  getCaseById,
                  singleCase,
                  updateCase,
                  getCaseStatus,
                  getCaseStatusForDoctor,
                  caseStatus,
                  getCaregiversOfDept,
                  getLogItemsByCaseId,
                  logItems,
                  addLogItem,
                  updateCaseCaregivers,
                  getDicomUrl,
                  findDicomImage,
                  getSignedUrl,
                  uploadToS3,
                  
                  
              }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const params = useParams();
    const [status, setStatus] = useState(null);
    const [isCritical, setIsCritical] = useState(false);
    const [caregivers, setCaregivers] = useState([]);
    const [fixedCaregiver, setFixedCaregiver] = useState([]);
    const [description, setDescription] = useState('');
    const [showEditDescriptionBox, setShowEditDescriptionBox] = useState(false);
    const [showNoteBox, setShowNoteBox] = useState(false);
    const [note, setNote] = useState('');
    const [sortCategory, setSortCategory] = useState('all');
    const [noteImages, setNoteImages] = useState([]);
    const [currentDicomMediaId, setCurrentDicomMediaId] = useState(null);
    const [noteDicomImages, setNoteDicomImages] = useState([]);
    const [showNoteErrorMessage, setShowNoteErrorMessage] = useState('');
    const [openMedicalUpload, setOpenMedicalUpload] = useState(false);
    const [caregiversDataList, setCaregiversDataList] = useState(null);
    const [openCloseCaseConfirmation, setOpenCloseCaseConfirmation] = useState(
        false
    );
    const [closeState, setCloseState] = useState(null);
    const [openCloseCaseMessage, setOpenCloseCaseMessage] = useState(false);
    const [openCaregiverList, setOpenCaregiverList] = useState(false);
    const [editableCaregivers, setEditableCaregivers] = useState([]);

    useEffect(() => {
        if (noteDicomImages.length > 0) {
            setShowNoteErrorMessage('');
        }
    }, [noteDicomImages]);

    useEffect(() => {
        getCaseById(params.id).then(res => {
            if (selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
                getCaseStatusForDoctor().then(resStatus => {
                    const currentStatus = resStatus.find(
                        status => status.id === res.status
                    );
                    setStatus(currentStatus);
                });
            } else {
                getCaseStatus().then(resStatus => {
                    const currentStatus = resStatus.find(
                        status => status.id === res.status
                    );
                    setStatus(currentStatus);
                });
            }

            if (res.priority === 0) {
                setIsCritical(false);
            } else if (res.priority) {
                setIsCritical(true);
            }

            setDescription(res.desc);

            const filterdCaregivers = res.Users.filter(user => {
                return (
                    user.userId === getUserInfo().id ||
                    user.userId === res.createdByUser.userId
                );
            });

            setFixedCaregiver(filterdCaregivers);
            setCaregivers(res.Users);

            const loggedInCaregiver = res.Users.find(
                caregiver => caregiver.userId === getUserInfo().id
            );

            getCaregiversOfDept(selectedDept.team_id).then(careRes => {
                if (!loggedInCaregiver) {
                    const caregiversWithoutLoggedInUser = careRes.rows.filter(
                        caregiver => caregiver.userId !== getUserInfo().id
                    );

                    setCaregiversDataList(caregiversWithoutLoggedInUser);
                } else {
                    setCaregiversDataList(careRes.rows);
                }
            });
        });
        //eslint-disable-next-line
    }, [params.id, getCaseById, getCaregiversOfDept, getCaseStatusForDoctor, getCaseStatus]);

    useEffect(() => {
        getLogItemsByCaseId(params.id, sortCategory).then(res => {});
    }, [getLogItemsByCaseId, params.id, sortCategory ]);

    /**
     * function to handle add note
     */
    const handleNotes = async () => {
        if (
            noteImages.length === 0 &&
            note.length === 0 &&
            noteDicomImages.length === 0
        ) {
            setShowNoteErrorMessage(
                'Please enter note or select image or medical image'
            );
        }

        if (noteImages.length !== 0) {
            const data = {
                text: note,
                mediaUrls: [],
                mediaId: noteDicomImages,
            };

            try {
                for (let index = 0; index < noteImages.length; index++) {
                    const image = noteImages[index];

                    const imageData = {
                        fileName: image.file.name,
                        fileType: `.${image.file.name.split('.').pop()}`,
                    };

                    const urlRes = await getSignedUrl(imageData);

                    if (urlRes) {
                        const uploadRes = await uploadToS3(urlRes.urls, image.file);

                        if (uploadRes) {
                            data.mediaUrls.push(urlRes.path);
                        }
                    }
                }
            } catch (error) {
            }

            addLogItem(params.id, data).then(res => {
                getLogItemsByCaseId(params.id, sortCategory).then(res => {
                });
                setShowNoteBox(false);
                setNoteImages([]);
                setNoteDicomImages([]);
                setNote('');
                setSortCategory('all');
            });
        } else {
            if (note.length !== 0 || noteDicomImages.length !== 0) {
                const data = {
                    text: note,
                    mediaId: noteDicomImages,
                };
                addLogItem(params.id, data).then(res => {
                    getLogItemsByCaseId(params.id, sortCategory).then(res => {
                    });
                    setShowNoteBox(false);
                    setNoteImages([]);
                    setNoteDicomImages([]);
                    setNote('');
                    setSortCategory('all');
                });
            }
        }
    };

    /**
     * function to handle closing of note form box
     */
    const handleNoteBoxClose = () => {
        setShowNoteBox(false);
        setNote('');
        setNoteImages([]);
        setNoteDicomImages([]);
        setShowNoteErrorMessage('');
    };

    /**
     * function to render caregiver chip
     * @param props: caregiver details
     * @returns {JSX.Element}
     * @constructor
     */
    const CaregiverChip = props => {
        return (
            <Chip avatar={<Avatar alt='profile' src={props.url}/>} {...props} />
        );
    };

    /**
     * function to handle edit description
     * @param e: e from button click
     */
    const onDescriptionEdit = e => {
        e.preventDefault();
        const data = {
            desc: description,
        };

        updateCase(params.id, data).then(res => {
            setShowEditDescriptionBox(false);
        });
    };

    /**
     * function to handle case status change
     * @param e: e from button click
     * @param value: selected case status 
     */
    const onStatusChange = (e, value) => {
        if (
            value.description === 'Closed' &&
            singleCase &&
            singleCase.priority === 1
        ) {
            setCloseState(value);
            setOpenCloseCaseConfirmation(true);
        } else {
            setStatus(value);

            const data = {
                status: value.id,
            };
            updateCase(params.id, data).then(res => {
            });
        }
    };

    /**
     * function to handle closing of case
     */
    const handleCloseCase = () => {
        const data = {
            status: closeState.id,
        };
        updateCase(params.id, data).then(res => {
            setStatus(closeState);

            if (res.priority === 0) {
                setIsCritical(false);
            } else if (res.priority) {
                setIsCritical(true);
            }

            setOpenCloseCaseConfirmation(false);
        });
    };

    /**
     * function to close confirmation dialog for closing a urgent close
     */
    const handleConfirmationClose = () => {
        setOpenCloseCaseConfirmation(false);
    };

    /**
     * function to handle case priority change
     * @param e: e from button click
     */
    const onPriorityChange = e => {
        if (status && status.id === 5) {
            setOpenCloseCaseMessage(true);
        } else {
            if (e.target.checked) {
                const data = {
                    priority: '1',
                };

                updateCase(params.id, data).then(res => {
                });
            }
            if (!e.target.checked) {
                const data = {
                    priority: '0',
                };

                updateCase(params.id, data).then(res => {
                });
            }
            setIsCritical(e.target.checked);
        }
    };

    /**
     * function to handle caregivers list change
     */
    const handleCaregiversList = () => {
        const data = {
            caregiverId: editableCaregivers.map(caregiver => caregiver.userId),
        };

        updateCaseCaregivers(params.id, data).then(res => {
            getLogItemsByCaseId(params.id, sortCategory).then(res => {
                getCaseById(params.id).then(res => {
                    setCaregivers(res.Users);
                });
                setOpenCaregiverList(false);
            });
        });
    };

    /**
     * function to handle go back
     */
    const onBack = () => {
        history.goBack();
    };

    /**
     * function to handle dicom upload dialog open
     */
    const handleMedicalUploadOpen = () => {
        getDicomUrl().then(res => {
            setCurrentDicomMediaId(res.mediaId);
            setOpenMedicalUpload(true);
        });
    };

    /**
     * function to handle dicom upload dialog close
     */
    const handleMedicalUploadClose = () => {
        const data = {
            mediaId: currentDicomMediaId,
        };
        findDicomImage(data).then(res => {
            if (res.status === 404) {
                setOpenMedicalUpload(false);
            } else {
                setOpenMedicalUpload(false);
                setNoteDicomImages(pre => [...pre, res.mediaId]);
            }
        });
    };

    /**
     * function to handle dicom remove
     * @param index: index of dicom
     */
    const handleRemoveDicom = index => {
        const tempDicom = [...noteDicomImages];
        tempDicom.splice(index, 1);

        setNoteDicomImages(tempDicom);
    };

    return (
        <>
            <Box className={classes.container}>
                <Box component={Paper} className={classes.actionsContainer}>
                    <Box>
                        <Button
                            startIcon={<ArrowBackIosIcon/>}
                            className={classes.backButton}
                            variant='outlined'
                            color='primary'
                            disableElevation
                            onClick={onBack}
                        >
                            Back
                        </Button>
                    </Box>

                    {role !== ROLES.LIMITED_USER ? (
                        <Box className={classes.buttonsInnerContainer}>
                            <Box className={classes.criticalContainer}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color='primary'
                                            checked={isCritical}
                                            onChange={onPriorityChange}
                                        />
                                    }
                                    label='Urgent'
                                    labelPlacement='end'
                                />
                            </Box>

                            <Box className={classes.selectContainer}>
                                <Autocomplete
                                    disableClearable
                                    fullWidth
                                    size='small'
                                    options={caseStatus ? caseStatus : []}
                                    getOptionSelected={(option, value) => {
                                        if (option && value) {
                                            return option.id === value.id;
                                        } else {
                                            return true;
                                        }
                                    }}
                                    getOptionLabel={option => {
                                        return (
                                            option.description.charAt(0).toUpperCase() +
                                            option.description.slice(1)
                                        );
                                    }}
                                    value={status}
                                    onChange={onStatusChange}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label='Case Status'
                                            variant='outlined'
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                    ) : (
                        <></>
                    )}
                </Box>

                {singleCase && (
                    <>
                        <Box mt={2} component={Paper} p={3} m={2}>
                            <Box className={classes.profileContainer}>
                                <Box>
                                    <img
                                        alt='profile'
                                        src={
                                            singleCase.Athlete && singleCase.Athlete.imageUrl
                                                ? singleCase.Athlete.imageUrl
                                                : defaultUser
                                        }
                                        className={clsx(
                                            classes.profileImage,
                                            isCritical && classes.critical
                                        )}
                                    />
                                </Box>
                                <Box className={classes.profileInfoContainer}>
                                    <Box className={classes.caseInfoContainer}>
                                        <Box>
                                            {singleCase.Athlete && (
                                                <Typography
                                                    variant='h3'
                                                    className={clsx(
                                                        classes.name,
                                                        isCritical && classes.criticalName
                                                    )}
                                                >
                                                    {`${singleCase.Athlete.firstname} ${singleCase.Athlete.lastname}`}{' '}
                                                    {singleCase.Athlete.jerseyNum &&
                                                    `, #${singleCase.Athlete.jerseyNum}`}{' '}
                                                    {role === ROLES.LIMITED_USER && (
                                                        <span className={classes.text}>
                              {status &&
                              `(${
                                  status.description.charAt(0).toUpperCase() +
                                  status.description.slice(1)
                              }${
                                  isCritical === true ? ', Urgent' : ', Normal'
                              })`}
                            </span>
                                                    )}
                                                </Typography>
                                            )}
                                        </Box>
                                        <Box className={classes.caseNumberConatiner}>
                                            <Typography className={classes.caseNumber}>
                                                Case #{singleCase.id}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className={classes.createdByContainer}>
                                        {singleCase.createdByUser && (
                                            <Typography className={classes.createdByText}>
                                                {`Created by ${singleCase.createdByUser.firstName} ${singleCase.createdByUser.lastName}, ${singleCase.createdAt}`}
                                            </Typography>
                                        )}
                                    </Box>
                                    {showEditDescriptionBox ? (
                                        <Box className={classes.editBoxContainer}>
                                            <ValidatorForm
                                                onSubmit={onDescriptionEdit}
                                                className={classes.formContainer}
                                            >
                                                <TextValidator
                                                    fullWidth
                                                    label='Description'
                                                    size='small'
                                                    variant='outlined'
                                                    multiline
                                                    rows={5}
                                                    rowsMax={5}
                                                    value={description}
                                                    onChange={e => setDescription(e.target.value)}
                                                    validators={['required']}
                                                    errorMessages={['Description is required']}
                                                />

                                                <Box className={classes.editBoxButtonContainer}>
                                                    <Box>
                                                        <Button
                                                            variant='outlined'
                                                            color='primary'
                                                            size='small'
                                                            disableElevation={true}
                                                            onClick={() => {
                                                                setShowEditDescriptionBox(false);
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Box>
                                                    <Box className={classes.editBoxSaveButton}>
                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            size='small'
                                                            disableElevation={true}
                                                            type='submit'
                                                        >
                                                            Save
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </ValidatorForm>
                                        </Box>
                                    ) : (
                                        <Box>
                                            <Box className={classes.descContainer}>
                                                <Box
                                                    mb={
                                                        role !== ROLES.LIMITED_USER
                                                            ? 0
                                                            : 1
                                                    }
                                                >
                                                    <Typography className={classes.descLabel}>
                                                        Description
                                                    </Typography>
                                                </Box>
                                                {role !== ROLES.LIMITED_USER ? (
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => {
                                                            setShowEditDescriptionBox(true);
                                                        }}
                                                    >
                                                        <EditIcon/>
                                                    </IconButton>
                                                ) : (
                                                    <></>
                                                )}
                                            </Box>
                                            <Box>
                                                <Typography className={classes.descText}>
                                                    {singleCase.desc}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}

                                    {!openCaregiverList ? (
                                        <Box>
                                            <Box className={classes.descContainer}>
                                                <Box
                                                    mb={
                                                        role !== ROLES.LIMITED_USER
                                                            ? 0
                                                            : 1
                                                    }
                                                >
                                                    <Typography className={classes.descLabel}>
                                                        Caregivers
                                                    </Typography>
                                                </Box>
                                                {role !== ROLES.LIMITED_USER ? (
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => {
                                                            if (status && status.id === 5) {
                                                                setOpenCloseCaseMessage(true);
                                                            } else {
                                                                setOpenCaregiverList(true);
                                                                setEditableCaregivers([...caregivers]);
                                                            }
                                                        }}
                                                    >
                                                        <EditIcon/>
                                                    </IconButton>
                                                ) : (
                                                    <></>
                                                )}
                                            </Box>

                                            {caregivers &&
                                            caregivers.map((option, index) => (
                                                <CaregiverChip
                                                    key={index}
                                                    variant='outlined'
                                                    label={`${option.firstName} ${option.lastName}`}
                                                    url={option.imageUrl}
                                                    color={
                                                        option.userId ===
                                                        singleCase.createdByUser.userId ||
                                                        option.userId === getUserInfo().id
                                                            ? 'primary'
                                                            : ''
                                                    }
                                                    deleteIcon={
                                                        option.userId ===
                                                        singleCase.createdByUser.userId ||
                                                        option.userId === getUserInfo().id ? (
                                                            <CheckIcon/>
                                                        ) : (
                                                            <RemoveIcon/>
                                                        )
                                                    }
                                                    className={classes.caregiverChip}
                                                />
                                            ))}
                                        </Box>
                                    ) : (
                                        <Box className={classes.caregiversListContainer}>
                                            <Grid container alignItems='center' spacing={2}>
                                                <Grid item xm={12} sm={12} md={10} lg={10} xl={10}>
                                                    <Autocomplete
                                                        popupIcon={null}
                                                        disableClearable
                                                        filterSelectedOptions
                                                        fullWidth
                                                        size='small'
                                                        multiple
                                                        options={
                                                            caregiversDataList ? caregiversDataList : []
                                                        }
                                                        value={[...new Map(editableCaregivers.map((x) => [x['userId'], x])).values()]}
                                                        onChange={(e, value) => {
                                                            setEditableCaregivers([
                                                                ...fixedCaregiver,
                                                                ...value.filter(
                                                                    option =>
                                                                        fixedCaregiver.indexOf(option) === -1
                                                                ),
                                                            ]);
                                                        }}
                                                        getOptionLabel={option => {
                                                            return option.firstName + ' ' + option.lastName;
                                                        }}
                                                        getOptionSelected={(option, value) =>
                                                            option.userId === value.userId
                                                        }
                                                        renderOption={option => (
                                                            <Box className={classes.optionsContainer}>
                                                                <Box className={classes.optionAvatar}>
                                                                    <Avatar alt='profile' src={option.imageUrl}/>
                                                                </Box>
                                                                <Box
                                                                    className={classes.optionText}
                                                                >{`${option.firstName} ${option.lastName}`}</Box>
                                                            </Box>
                                                        )}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                label='Caregivers'
                                                                variant='outlined'
                                                            />
                                                        )}
                                                        renderTags={(tagValue, getTagProps) => {
                                                            return tagValue.map((option, index) => (
                                                                <CaregiverChip
                                                                    key={index}
                                                                    variant='outlined'
                                                                    {...getTagProps({index})}
                                                                    label={`${option.firstName} ${option.lastName}`}
                                                                    url={option.imageUrl}
                                                                    color={
                                                                        option.userId ===
                                                                        singleCase.createdByUser.userId ||
                                                                        option.userId === getUserInfo().id
                                                                            ? 'primary'
                                                                            : 'default'
                                                                    }
                                                                    deleteIcon={
                                                                        option.userId ===
                                                                        singleCase.createdByUser.userId ||
                                                                        option.userId === getUserInfo().id ? (
                                                                            <CheckIcon/>
                                                                        ) : (
                                                                            <RemoveIcon/>
                                                                        )
                                                                    }
                                                                />
                                                            ));
                                                        }}
                                                        classes={{inputRoot: classes.input}}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                                                    <Button
                                                        size='small'
                                                        variant='outlined'
                                                        color='primary'
                                                        disableElevation
                                                        onClick={() => setOpenCaregiverList(false)}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid xs={12} sm={12} md={1} lg={1} xl={1}>
                                                    <Button
                                                        size='small'
                                                        variant='contained'
                                                        color='primary'
                                                        disableElevation
                                                        onClick={handleCaregiversList}
                                                    >
                                                        Update
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}

                                    {selectedDept &&
                                    selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER &&
                                    selectedDept.HealthcareOrgname && (
                                        <>
                                            <Box pt={2}>
                                                <Typography classes={{root: classes.hcLabel}}>
                                                    Healthcare Organisation:
                                                </Typography>
                                                <Typography>
                                                    {selectedDept.HealthcareOrgname}
                                                </Typography>
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.addNoteHeadingContainer}>
                            <Box className={classes.addNoteContainer}>
                                <Box>
                                    <Typography className={classes.addNoteLabel}>
                                        Notes
                                    </Typography>
                                </Box>
                                {!showNoteBox && (
                                    <Box className={classes.buttonLebelContainer}>
                                        <Box>
                                            <IconButton
                                                color='primary'
                                                onClick={() => {
                                                    if (status && status.id === 5) {
                                                        setOpenCloseCaseMessage(true);
                                                    } else {
                                                        setShowNoteBox(true);
                                                    }
                                                }}
                                            >
                                                <AddCircleIcon/>
                                            </IconButton>
                                        </Box>
                                        <Box component={Paper} p={1}>
                      <span className={classes.addNoteInfo}>
                        Tap "+" to add a note, photo or medical image
                      </span>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                            <Box className={classes.toggleButtonsContainer}>
                                <ToggleButtonGroup
                                    value={sortCategory}
                                    onChange={(e, value) => setSortCategory(value)}
                                    exclusive
                                    size='small'
                                >
                                    <ToggleButton
                                        className={classes.sortButton}
                                        classes={{selected: classes.selected}}
                                        value='all'
                                    >
                                        All
                                    </ToggleButton>
                                    <ToggleButton
                                        className={classes.sortButton}
                                        classes={{selected: classes.selected}}
                                        value='media'
                                    >
                                        Images
                                    </ToggleButton>
                                    <ToggleButton
                                        className={classes.sortButton}
                                        classes={{selected: classes.selected}}
                                        value='dicom'
                                    >
                                        Medical
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                        </Box>
                        {showNoteBox && (
                            <Box component={Paper} p={3} m={2}>
                                <NoteForm
                                    handleNoteBoxClose={handleNoteBoxClose}
                                    note={note}
                                    setNote={setNote}
                                    noteImages={noteImages}
                                    noteDicomImages={noteDicomImages}
                                    handleRemoveDicom={handleRemoveDicom}
                                    setNoteImages={setNoteImages}
                                    handleNotes={handleNotes}
                                    showNoteErrorMessage={showNoteErrorMessage}
                                    setShowNoteErrorMessage={setShowNoteErrorMessage}
                                    handleMedicalUploadOpen={handleMedicalUploadOpen}
                                    handleMedicalUploadClose={handleMedicalUploadClose}
                                    openMedicalUpload={openMedicalUpload}
                                />
                            </Box>
                        )}

                        {logItems &&
                        logItems.rows &&
                        logItems.rows.length === 0 &&
                        !showNoteBox ? (
                            <Box className={classes.noContentContainer}>
                                <Typography classes={{root: classes.noLabel}}>
                                    Currently no notes are available
                                </Typography>
                            </Box>
                        ) : (
                            <Box>
                                <Timeline>
                                    {logItems.rows &&
                                    logItems.rows.map((note, index) => {
                                        return (
                                            <NoteTimeline
                                                key={`${note.id}${note.isCurrentUserRead}`}
                                                note={note}
                                                caseId={Number(params.id)}
                                                sortCategory={sortCategory}
                                            />
                                        );
                                    })}
                                </Timeline>
                            </Box>
                        )}
                    </>
                )}
            </Box>

            <CaseCloseConfirmationDialog
                title='Close Case'
                message='You are about to close an urgent case.'
                open={openCloseCaseConfirmation}
                onCancel={handleConfirmationClose}
                onConfirm={handleCloseCase}
            />

            <MessageDialog
                title='Sorry!'
                message='Please re-open case to access.'
                open={openCloseCaseMessage}
                setOpen={setOpenCloseCaseMessage}
            />
        </>
    );
};

const mapStateToProps = state => {
    return {
        selectedDept: state.sirenUsers.selectedDept,
        singleCase: state.cases.case,
        caseStatus: state.cases.caseStatus,
        caregiversList: state.cases.caregivers,
        casePriorities: state.cases.casePriorities,
        logItems: state.cases.logItems,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCaseById: id => {
            return dispatch(getCaseByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateCase: (id, data) => {
            return dispatch(updateCaseRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateCaseCaregivers: (caseId, data) => {
            return dispatch(updateCaseCaregiversRequest(caseId, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getCaseStatus: data => {
            return dispatch(getCaseStatusRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getCaseStatusForDoctor: data => {
            return dispatch(getCaseStatusForDoctorRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getCaregiversOfDept: id => {
            return dispatch(getCaregiversOfDeptRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getLogItemsByCaseId: (id, type) => {
            return dispatch(getLogItemsByCaseIdRequest(id, type)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        addLogItem: (caseId, data) => {
            return dispatch(addLogItemRequest(caseId, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getSignedUrl: data => {
            return dispatch(getSignedUrlRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        uploadToS3: (url, file) => {
            return dispatch(uploadToS3Request(url, file)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getDicomUrl: () => {
            return dispatch(getDicomUrlRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        findDicomImage: data => {
            return dispatch(findDicomImageRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Case);
