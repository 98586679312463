import React from 'react';
import {Box, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Slide,} from '@material-ui/core';
import {useStyles} from './styles';

/**
 * function to render auto logout dialog
 * @param title: title of the dialog
 * @param message: message on the dialog
 * @param open: boolean to open the dialog
 * @returns {JSX.Element}
 * @constructor
 */
const AutoLogout = ({title, message, open}) => {
    const classes = useStyles()
    
    return (
        <Dialog
            maxWidth='sm'
            disableBackdropClick
            fullWidth
            open={open}
            TransitionComponent={Slide}>
            <DialogTitle>{title}</DialogTitle>

            <Divider/>

            <DialogContent classes={{root: classes.content}}>
                <DialogContentText component={Box}>{message}</DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default AutoLogout;
