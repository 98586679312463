import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import MaterialTable from 'material-table';
import {Box, Button, FormControl, MenuItem, Select, Tooltip, Typography,} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/HighlightOff';
import AddIcon from '@material-ui/icons/Add';
import {PAGE_SIZE, PAGE_SIZE_OPTIONS} from '../../../../utilities/constants';
import {getAdminsForOrgRequest, removeAdminFromOrgRequest,} from '../../AdministratorsApiActions';
import ConfirmationDialog from '../../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import {useStyles} from './styles';
import defaultUser from '../../../../assets/user.png';
import {getUserInfo} from '../../../../utilities/storage';
import {debounce} from '../../../../utilities/utilityFunctions';
import ImageLoader from 'react-load-image';
import Preloader from '../../../../utilities/components/Preloader/Preloader';
import {ROLES} from '../../../../utilities/constants'
import ActiveStatusIcon from '../../../../utilities/components/ActiveStatusIcon/ActiveStatusIcon';

/**
 * function to get message for remove dialog
 * @param admin: admin details
 * @param classes: classes from css
 * @returns {JSX.Element}
 * @constructor
 */
const RemoveDialogMessage = ({admin, classes}) => {
    return (
        <>
            {admin && (
                <Box>
                    Are you sure you want to remove the{' '}
                    <span className={classes.dialogMessageLabel}>
            {admin.firstName} {admin.lastName}
          </span>{' '}
                    from admin list?
                </Box>
            )}
        </>
    );
};

/**
 * function to render department admins list
 * @param getAdminsForOrg: function to get admins of organization
 * @param admins: admins list
 * @param removeAdminFromOrg: function to remove admin
 * @param orgIdHistory: organization id
 * @param orgNameHistory: organization name
 * @param hcorgIdHistory: healthcare organization id
 * @param hcorgNameHistory: healthcare organization name
 * @returns {JSX.Element}
 * @constructor
 */
const Administrators = ({
                            history,
                            getAdminsForOrg,
                            admins,
                            removeAdminFromOrg,
                            orgIdHistory,
                            orgNameHistory,
                            hcorgIdHistory,
                            hcorgNameHistory,
                        }) => {
    const classes = useStyles();
    const params = useParams();
    const match = useRouteMatch();
    const role = getUserInfo().Roles[0].name;
    const [paginationParams, setPaginationParams] = useState({
        page: 0,
        rowsPerPage: PAGE_SIZE,
        searchData: {
            name: '',
            email: '',
            phone: '',
            active: '',
        },
    });
    const [
        openRemoveConfirmationDialog,
        setOpenRemoveConfirmationDialog,
    ] = useState(false);
    const [admin, setAdmin] = useState(null);
    const [isFilterChange, setIsFilterChange] = useState(false);

    /**
     * function to handle table filter search
     * @param filters: filters from filter input to search
     */
    const onTableFilterChange = filters => {
        let searchDataOnFilterChange = Object.assign({}, paginationParams);

        if (filters.length > 0) {
            let obj = {};
            filters.forEach(filter => {
                obj[filter.column.field] = filter.value;
            });

            Object.keys(searchDataOnFilterChange.searchData).forEach(function (key) {
                if (key !== 'active') {
                    if (obj[key]) {
                        searchDataOnFilterChange.searchData[key] = obj[key];
                    } else {
                        searchDataOnFilterChange.searchData[key] = '';
                    }
                }
            });

            filters.forEach(filter => {
                searchDataOnFilterChange.searchData[filter.column.field] = filter.value;
            });
        } else {
            searchDataOnFilterChange.searchData['name'] = '';
            searchDataOnFilterChange.searchData['email'] = '';
            searchDataOnFilterChange.searchData['phone'] = '';
        }

        setIsFilterChange(true);
        setPaginationParams(searchDataOnFilterChange);
    };

    /**
     * function to implement debounce
     * @type {(function(...[*]=): void)|*}
     */
    //eslint-disable-next-line
    const debounceOnFilterChange = React.useCallback(
        debounce(onTableFilterChange, 400),
        []
    );

    useEffect(() => {
        getAdminsForOrg(params.id, paginationParams, isFilterChange).then(() => {
            if (isFilterChange) {
                setIsFilterChange(false);
            }
        });
        //eslint-disable-next-line
    }, [getAdminsForOrg, params.id, paginationParams]);

    /**
     * function to handle status change upon filter
     * @param value: selected status value
     */
    const handleStatusChange = value => {
        let data = Object.assign({}, paginationParams);
        data.searchData.active = value;

        setPaginationParams(data);
    };

    /**
     * function to remove admin on button click
     */
    const onRemove = () => {
        const data = {
            adminId: admin.userId,
        };
        removeAdminFromOrg(params.id, data).then(() => {
            setOpenRemoveConfirmationDialog(false);
            getAdminsForOrg(params.id, paginationParams).then(() => {
            });
        });
    };

    const handleProfileRoute = userId => {
        if(role === ROLES.SIRENMD_ADMIN){
            history.push({
                pathname: `${match.path.split(':')[0]}admins/${userId}`,
                state: {
                    orgIdHistory: orgIdHistory,
                    orgNameHistory: orgNameHistory,
                    hcorgIdHistory: hcorgIdHistory,
                    hcorgNameHistory: hcorgNameHistory,
                },
            });
        }
    };

    const columns = [
        {
            field: 'name',
            title: 'Name',
            filtering: true,
            defaultFilter: paginationParams.searchData['name'],

            cellStyle: {
                width: '25%',
            },
            headerStyle: {
                width: '25%',
            },
            render: ({imageUrl, name, userId}) => {
                return (
                    <Box
                        className={`${classes.profileContainer} ${role === ROLES.SIRENMD_ADMIN ? classes.cursorPointer : ''}`}
                        onClick={() => handleProfileRoute(userId)}
                    >
                        <Box className={classes.profileInnerContainer}>
                            <ImageLoader src={imageUrl ? imageUrl : defaultUser}>
                                <img alt={userId} className={classes.profileImage}/>
                                <div className={classes.profileImageError}>{userId}</div>
                                <Preloader alt={userId} className={classes.profileImage}/>
                            </ImageLoader>
                        </Box>

                        <Box className={classes.nameContainer}>
                            <Typography classes={{root: classes.name}}>{name}</Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'email',
            title: 'Email',
            filtering: true,
            defaultFilter: paginationParams.searchData['email'],
            cellStyle: {
                width: '25%',
            },
            headerStyle: {
                width: '25%',
            },
        },
        {
            field: 'phone',
            title: 'Phone',
            filtering: true,
            defaultFilter: paginationParams.searchData['phone'],
            cellStyle: {
                width: '25%',
            },
            headerStyle: {
                width: '25%',
            },
        },
        {
            field: 'enabled',
            title: 'Active Status',
            cellStyle: {
                width: '12%',
                textAlign: 'center',
            },
            headerStyle: {
                width: '12%',
                textAlign: 'center',
            },
            filtering: true,
            filterComponent: () => {
                return (
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                value={paginationParams.searchData.active}
                                onChange={e => handleStatusChange(e.target.value)}
                            >
                                <MenuItem value=''>All</MenuItem>
                                <MenuItem value='1'>Active</MenuItem>
                                <MenuItem value='0'>Blocked</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                );
            },
            render: ({enabled, isBanned}) => {
                return (
                    <ActiveStatusIcon enabled={enabled} isBanned={isBanned} isLabel={false}  />
                );
            },
        },
    ];

    if (role === ROLES.SIRENMD_ADMIN) {
        const removeColumn = {
            field: 'delete',
            title: 'Remove',
            cellStyle: {
                width: '10%',
                textAlign: 'center',
            },
            headerStyle: {
                width: '10%',
                textAlign: 'center',
            },
            filtering: false,
            sorting: false,
            render: props => {
                return (
                    <>
                        <Tooltip arrow placement='top' title='Remove'>
                            <ClearIcon
                                className={classes.removeIcon}
                                onClick={() => {
                                    setOpenRemoveConfirmationDialog(true);
                                    setAdmin(props);
                                }}
                            />
                        </Tooltip>
                    </>
                );
            },
        };

        columns.push(removeColumn);
    }

    return (
        <Box>
            {role === ROLES.SIRENMD_ADMIN && (
                <Box className={classes.addButtonContainer}>
                    <Button
                        variant='contained'
                        color='primary'
                        startIcon={<AddIcon/>}
                        disableElevation={true}
                        component={Link}
                        to={{
                            pathname: `${match.path.split(':')[0]}addAdmin`,
                            state: {
                                orgIdHistory: orgIdHistory,
                                orgNameHistory: orgNameHistory,
                                hcorgIdHistory: hcorgIdHistory,
                                hcorgNameHistory: hcorgNameHistory,
                            },
                        }}
                        classes={{root: classes.addButton}}
                    >
                        Administrator
                    </Button>
                </Box>
            )}

            <MaterialTable
                title=''
                columns={columns}
                data={admins.rows}
                options={{
                    search: false,
                    filtering: true,
                    sorting: true,
                    draggable: false,
                    horizontalScroll: true,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    pageSize: paginationParams.rowsPerPage,
                    emptyRowsWhenPaging: false,
                }}
                pages={Math.ceil(admins.count / paginationParams.rowsPerPage)}
                totalCount={admins.count}
                page={paginationParams.page}
                onChangeRowsPerPage={pageSize => {
                    let data = Object.assign({}, paginationParams);
                    data['rowsPerPage'] = pageSize;
                    setPaginationParams(data);
                }}
                onChangePage={page => {
                    let data = Object.assign({}, paginationParams);
                    data['page'] = page;
                    setPaginationParams(data);
                }}
                onFilterChange={filters => {
                    debounceOnFilterChange(filters);
                }}
            />

            {openRemoveConfirmationDialog && admin && (
                <Box>
                    <ConfirmationDialog
                        title='Remove'
                        message={<RemoveDialogMessage admin={admin} classes={classes}/>}
                        open={openRemoveConfirmationDialog}
                        setOpen={setOpenRemoveConfirmationDialog}
                        onConfirm={onRemove}
                    />
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        admins: state.administrators.admins,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAdminsForOrg: (orgId, data, isHideLoader = false) => {
            return dispatch(getAdminsForOrgRequest(orgId, data, isHideLoader)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        removeAdminFromOrg: (orgId, data) => {
            return dispatch(removeAdminFromOrgRequest(orgId, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Administrators);
