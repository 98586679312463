import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Slide,
} from '@material-ui/core';
import {useStyles} from './styles';

/**
 * function to render message dialog
 * @param title: title of the dialog
 * @param message: message on the dialog
 * @param open: boolean to open the dialog
 * @param setOpen: function to set open state of the dialog
 * @returns {JSX.Element}
 * @constructor
 */
const MessageDialog = ({title, message, open, setOpen}) => {
    const classes = useStyles();
     
    /**
     * function to close message dialog
     */
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            maxWidth='sm'
            disableBackdropClick
            fullWidth
            open={open}
            TransitionComponent={Slide}
        >
            <DialogTitle>{title}</DialogTitle>

            <Divider/>

            <DialogContent classes={{root: classes.content}}>
                <DialogContentText component={Box}>{message}</DialogContentText>
            </DialogContent>
            <DialogActions classes={{root: classes.actionsContainer}}>
                <Button variant='contained' color='primary' onClick={handleClose}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MessageDialog;
