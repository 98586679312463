import React from 'react';
import ImageUploading from 'react-images-uploading';
import {Box, Button, FormHelperText, IconButton, TextField, Typography,} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ImageIcon from '@material-ui/icons/Image';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import MedicalUpload from '../DicomUpload/DicomUpload';
import dicomThumb from '../../../../assets/dicom.jpg';
import {IMAGE_FILE_TYPES} from '../../../../utilities/constants'
import {useStyles} from './styles';

/**
 * function to render note form
 * @param handleNoteBoxClose: function to close note form
 * @param note: note text
 * @param setNote: function to set note text
 * @param handleNotes: function to handle note submit
 * @param noteImages: note images
 * @param noteDicomImages: dicom images
 * @param handleRemoveDicom: function to remove dicom images
 * @param setNoteImages: function to set note images
 * @param showNoteErrorMessage: note error
 * @param setShowNoteErrorMessage: function to set note error
 * @param handleMedicalUploadOpen: function to open dicom upload dialog
 * @param handleMedicalUploadClose: function to close dicom upload dialog
 * @param openMedicalUpload: opens dicom upload dialog
 * @returns {JSX.Element}
 * @constructor
 */
const NoteForm = ({
                      handleNoteBoxClose,
                      note,
                      setNote,
                      handleNotes,
                      noteImages,
                      noteDicomImages,
                      handleRemoveDicom,
                      setNoteImages,
                      showNoteErrorMessage,
                      setShowNoteErrorMessage,
                      handleMedicalUploadOpen,
                      handleMedicalUploadClose,
                      openMedicalUpload,
                  }) => {
    const classes = useStyles();

    return (
        <Box>
            <ImageUploading
                acceptType={IMAGE_FILE_TYPES}
                multiple
                value={noteImages}
                onChange={imgs => {
                    setNoteImages(imgs);
                    setShowNoteErrorMessage('');
                }}
                dataURLKey='data_url'
            >
                {({imageList, onImageUpload, onImageRemove, errors}) => (
                    <>
                        <Box>
                            {noteDicomImages.map((item, index) => (
                                <Box key={index} className={classes.imagePreviewContainer}>
                                    <Box className={classes.imagePreviewInnerContainer}>
                                        <IconButton
                                            onClick={() => {
                                                handleRemoveDicom(index);
                                            }}
                                        >
                                            <CancelIcon/>
                                        </IconButton>
                                    </Box>
                                    <Box>
                                        <img
                                            src={dicomThumb}
                                            alt='dicom'
                                            className={classes.imagePreview}
                                        />
                                    </Box>
                                </Box>
                            ))}

                            {imageList.map((image, index) => (
                                <Box key={index} className={classes.imagePreviewContainer}>
                                    <Box className={classes.imagePreviewInnerContainer}>
                                        <IconButton onClick={() => onImageRemove(index)}>
                                            <CancelIcon/>
                                        </IconButton>
                                    </Box>
                                    <Box>
                                        <img
                                            src={image.data_url}
                                            alt='note'
                                            className={classes.imagePreview}
                                        />
                                    </Box>
                                </Box>
                            ))}

                            <Box>
                                <TextField
                                    fullWidth
                                    label='Note'
                                    size='small'
                                    variant='outlined'
                                    multiline
                                    rows={6}
                                    rowsMax={6}
                                    value={note}
                                    onChange={e => {
                                        setNote(e.target.value);
                                        setShowNoteErrorMessage('');
                                    }}
                                />
                            </Box>

                            <Box>
                                {showNoteErrorMessage && (
                                    <FormHelperText className={classes.error}>
                                        {showNoteErrorMessage}
                                    </FormHelperText>
                                )}
                            </Box>

                            <Box className={classes.formContainer}>
                                <Box className={classes.formInnerContainer}>
                                    <Box>
                                        <Box>
                                            <Button
                                                variant='outlined'
                                                color='primary'
                                                startIcon={<ImageIcon/>}
                                                onClick={onImageUpload}
                                            >
                                                Add Image
                                            </Button>
                                        </Box>

                                        {errors && (
                                            <Box>
                                                {errors.acceptType && (
                                                    <Typography classes={{root: classes.errorText}}>
                                                        Selected image file type is not supported
                                                    </Typography>
                                                )}
                                            </Box>
                                        )}
                                    </Box>

                                    <Box>
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            startIcon={<LocalHospitalIcon/>}
                                            classes={{root: classes.medicalImageButton}}
                                            onClick={handleMedicalUploadOpen}
                                        >
                                            Upload Medical Image
                                        </Button>
                                    </Box>
                                </Box>

                                <Box>
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        disableElevation={true}
                                        onClick={handleNoteBoxClose}
                                        className={classes.noteCancelButton}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        variant='contained'
                                        color='primary'
                                        disableElevation={true}
                                        onClick={handleNotes}
                                    >
                                        Add Note
                                    </Button>
                                </Box>
                            </Box>
                        </Box>

                        <Box>
                            <MedicalUpload
                                openMedicalUpload={openMedicalUpload}
                                handleMedicalUploadClose={handleMedicalUploadClose}
                            />
                        </Box>
                    </>
                )}
            </ImageUploading>
        </Box>
    );
};

export default NoteForm;
