import {combineReducers} from 'redux';

import utilities from './utilities/UtilitiesReducer';
import sirenUsers from './components/Users/UsersReducer';
import auth from './components/Auth/AuthReducer';
import organisations from './components/Organizations/OrganisationsReducer';
import hcorganisations from './components/HealthcareOrganizations/HealthcareOrganizationsReducer';
import vendors from './components/IntegrationVendors/IntegrationVendorsReducer';
import departments from './components/Departments/DepartmentsReducer';
import caregivers from './components/Caregivers/CaregiverReducer';
import administrators from './components/Administrators/AdministratorsReducer';
import patients from './components/Patients/PatientsReducer';
import athletes from './components/Athletes/AthletesReducer';
import cases from './components/Cases/CasesReducer';
import teamRoster from './components/PlayStatus/PlayStatusReducer';
import analytics from './components/AnalyticsDashboard/AnalyticsRedurer';
import newUpdates from './components/NewUpdates/NewUpdatesReducer';
import dataUtilities from './components/DataUtilities/DataUtilitiesReducer';

export default combineReducers({
    utilities,
    auth,
    sirenUsers,
    organisations,
    hcorganisations,
    vendors,
    departments,
    caregivers,
    administrators,
    patients,
    athletes,
    cases,
    teamRoster,
    analytics,
    newUpdates,
    dataUtilities,
});
