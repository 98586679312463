import {makeStyles} from '@material-ui/core';

const drawerWidth = 240;

export const useStyles = makeStyles(theme => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: '60px',
        paddingTop: '1rem',
        backgroundColor: 'rgba(50,50,50,0.05)',
        minHeight: '100vh',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
        paddingLeft: '45px',
    },
    userContent: {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: '60px',
        marginTop: '0.7rem',
        paddingLeft: '0rem',
        backgroundColor: 'rgba(50,50,50,0.05)',
        minHeight: '95vh',
    },
    userContentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
        paddingLeft: '20px',
    },
    footer: {
        zIndex: '100',
        transition:
            'margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',

        backgroundColor: '#ddd',
        color: '#505050',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1rem',
        position: 'fixed',
        bottom: '0px',
        width: '100%',
        paddingLeft: '70px',
        height: '52px',
    },
    footerShift: {
        zIndex: '100',
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition:
            'margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms, width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        paddingLeft: '35px',
    },
}));
