import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        outerContainer: {
            paddingTop: '1rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            paddingBottom: '3.5rem',
        },
        userProfileContainer: {
            padding: '2rem',
        },
        userProfileContentContainer: {
            display: 'flex',
        },
        userProfileImageError: {
            borderRadius: '100%',
            height: '120px',
            objectFit: 'contain',
            width: '120px',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            textAlign: 'center',
        },
        userProfileImage: {
            borderRadius: '100%',
            height: '120px',
            objectFit: 'contain',
            width: '120px',
            border: '1px solid rgba(0, 0, 0, 0.2)',
        },
        userProfileTextContainer: {
            marginLeft: '2.5rem',
        },
        userProfileName: {
            fontSize: '1.7rem',
        },
        userProfileEmail: {
            fontSize: '0.9rem',
        },
        userProfilePhone: {
            fontSize: '0.9rem',
            marginTop: '0.2rem',
        },
        userProfileRoleContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        userProfileRoleIcon: {
            fontSize: '1.2rem',
            color: 'blue',
        },
        userProfileRole: {
            fontSize: '1rem',
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
            marginLeft: '0.3rem',
        },
        userProfileButton: {
            marginTop: '0.5rem',
            textTransform: 'capitalize',
            width: '200px',
            justifyContent: 'flex-start',
        },
        profileContainer: {
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
        },
        profileImage: {
            height: '40px',
            width: '40px',
            borderRadius: '100%',
        },
        profileNameContainer: {
            marginLeft: '0.5rem',
        },
        profileName: {
            fontSize: '1rem',
        },
        dialogMessageLabel: {
            fontWeight: 'bold',
        },
        activeButton: {
            backgroundColor: '#4caf50',
            '&:hover': {
                backgroundColor: '#357a38',
            },
        },
        blockButton: {
            backgroundColor: '#f44336',
            '&:hover': {
                backgroundColor: '#aa2e25',
            },
        },
        breadcrumbsContainer: {
            position: 'sticky',
            zIndex: '1000',
            top: '0',
            marginTop: '-16px',
            marginLeft: '-25px',
            marginRight: '-25px',
        },
        breadcrumbsContainerForUsers: {
            position: 'sticky',
            zIndex: '1000',
            top: '75px',
            marginTop: '-16px',
            marginLeft: '-15px',
            marginRight: '-25px',
        },
        breadcrumbsContainerForAdmins: {
            position: 'sticky',
            zIndex: '1000',
            top: '1px',
            marginTop: '-28px',
            marginLeft: '-15px',
            marginRight: '-25px',
        },
        breadcrumbItem: {
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: theme.palette.text.primary,
            padding: '4px 0px',
        },
        activeBreadcrumbItem: {
            color: theme.palette.primary.main,
        },
        breadcrumpIcon: {
            marginRight: '5px',
            fontSize: '18px',
        },
        breadcrumpLabel: {
            fontSize: '14px',
        },
    };
});
