import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Box, Paper, TextField, Typography} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {useStyles} from './styles';
import MaterialTable from 'material-table';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import CallIcon from '@material-ui/icons/Call';
import CommentIcon from '@material-ui/icons/Comment';
import {getAnalyticsRequest} from '../../AnalyticsApiActions';
import {getUserInfo} from '../../../../utilities/storage';
import {timePeriodList} from '../../../../utilities/constants';

/**
 * function to render analytics dashboard
 * @param selectedDept: selected department
 * @param getAnalytics: function to get analytics data
 * @param analytics: analytics data
 * @returns {JSX.Element}
 * @constructor
 */
const AnalyticDashboard = ({selectedDept, getAnalytics, analytics}) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;

    const compareWithList = [
        {
            id: 1,
            description: 'Compare Me',
        },
        {
            id: 2,
            description: 'All SirenMD Users',
        },
        {
            id: 3,
            description: `All SirenMD ${role}`,
        },
        {
            id: 4,
            description: `All ${selectedDept.team_name} ${
                role
            }`,
        },
        {
            id: 5,
            description: `All ${selectedDept.team_name} Users`,
        },
        {
            id: 6,
            description: `All ${selectedDept.Organization_name} Users`,
        },
        {
            id: 7,
            description: 'All Users in My Organizations',
        },
    ];

    const [timePeriod, setTimePeriod] = useState(timePeriodList[0]);
    const [compareWith, setCompareWith] = useState(compareWithList[0]);

    useEffect(() => {
        const data = {
            orgId: selectedDept.Organization_id,
            teamId: selectedDept.team_id,
            time_period: timePeriod.id,
            compare_with: compareWith.id,
        };
        getAnalytics(data).then(() => {});
        //eslint-disable-next-line
    }, [getAnalytics, timePeriod.id, compareWith.id]);

    const getAnalyticsListIcon = (description) => {
        switch(description){
            case 'Total Cases Created':
                return <FolderOpenIcon/>
            case 'Total Phone/Video Calls':
                return <CallIcon/>
            case 'Total Comments Posted':
                return <CommentIcon/>
            default:
                return <></>
        }
    } 

    const columns = [
        {
            field: 'description',
            title: 'Summary',
            filtering: true,
            cellStyle: {
                width: '30%',
            },
            headerStyle: {
                width: '30%',
            },
            render: ({description}) => {
                return (
                    <Box className={classes.profileContainer}>
                        {getAnalyticsListIcon(description)}

                        <Box className={classes.nameContainer}>
                            <Typography classes={{root: classes.summary}}>
                                {description}
                            </Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'MyStats',
            title: 'My Status',
            cellStyle: {
                textAlign: 'center',
            },
            headerStyle: {
                textAlign: 'center',
            },
        },
        {
            field: 'compareWith',
            title: 'Comparable',
            cellStyle: {
                textAlign: 'center',
            },
            headerStyle: {
                textAlign: 'center',
            },
        },
    ];

    return (
        <Box className={classes.container}>
            <Box>
                <Box component={Paper} className={classes.actionsContainer}>
                    <Box className={classes.timeContainer}>
                        <Autocomplete
                            disableClearable
                            fullWidth
                            size='small'
                            options={timePeriodList}
                            value={timePeriod}
                            onChange={(e, value) => setTimePeriod(value)}
                            getOptionLabel={option => {
                                return (
                                    option.description.charAt(0).toUpperCase() +
                                    option.description.slice(1)
                                );
                            }}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={params => (
                                <TextField {...params} label='Time Period' variant='outlined'/>
                            )}
                        />
                    </Box>

                    <Box className={classes.compareContainer}>
                        <Autocomplete
                            disableClearable
                            fullWidth
                            size='small'
                            options={compareWithList}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={option => {
                                return (
                                    option.description.charAt(0).toUpperCase() +
                                    option.description.slice(1)
                                );
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label='Compare With'
                                    variant='outlined'
                                />
                            )}
                            value={compareWith}
                            onChange={(e, value) => setCompareWith(value)}
                        />
                    </Box>
                </Box>
            </Box>

            <Box m={3}>
                <MaterialTable
                    title=''
                    columns={columns}
                    data={analytics}
                    options={{
                        draggable: false,
                        toolbar: false,
                        search: false,
                        filtering: false,
                        sorting: false,
                        horizontalScroll: true,
                        emptyRowsWhenPaging: false,
                        paging: false,
                        headerStyle: {fontWeight: 'bold'},
                    }}
                    style={{width: '70%', margin: 'auto'}}
                />
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        selectedDept: state.sirenUsers.selectedDept,
        analytics: state.analytics.analytics,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAnalytics: (userId, data) => {
            return dispatch(getAnalyticsRequest(userId, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticDashboard);
