const MuiSwitch = {
    colorSecondary: {
        '&$checked': {
            color: 'green!important',

            '& + $track': {
                backgroundColor: 'green!important',
            },
        },
        '&$disabled': {
            color: 'green',

            '& + $track': {
                backgroundColor: 'green',
                opacity: '0.5'
            },
        },
    },
};

export default MuiSwitch;
