export const GET_UPDATES = 'GET_UPDATES';
export const READ_UPDATE = 'READ_UPDATE';

/**
 * action creator function to get new updates
 * @param updates: updates from api response data
 * @returns {{type: string, updates}}
 */
export const getUpdates = updates => {
    return {
        type: GET_UPDATES,
        updates: updates,
    };
};

/**
 * action creator function to read update
 * @param update: update from api response data
 * @returns {{update, type: string}}
 */
export const readUpdate = update => {
    return {
        type: READ_UPDATE,
        update: update,
    };
};
