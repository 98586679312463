import React from 'react';

const TransactionMonitoring = () => {
    return (
        <div>
            <h1>Transaction Monitoring</h1>
        </div>
    );
};

export default TransactionMonitoring;
