import React from 'react';
import {Box, Typography} from '@material-ui/core';
import ImageUploading from 'react-images-uploading';
import ImageEditor from './components/ImageEditor/ImageEditor';
import {useStyles} from './styles';

/**
 * function to render image uploader
 * @param rowImageFile: row image file
 * @param setRowImageFile: function to set row image file
 * @param showImageEditor: boolean to show image editor
 * @param setShowImageEditor: function to set show image editor
 * @param setCropper: function to set cropper
 * @param croppedImageFile: cropped image file
 * @param onImageCrop: function to handle image crop
 * @param options: options
 * @returns {JSX.Element}
 * @constructor
 */
const ImageField = ({
                        rowImageFile,
                        setRowImageFile,
                        showImageEditor,
                        setShowImageEditor,
                        setCropper,
                        croppedImageFile,
                        onImageCrop,
                        options,
                    }) => {
    const classes = useStyles();

    return (
        <>
            <ImageUploading
                value={rowImageFile}
                acceptType={['jpg', 'jpeg', 'webp', 'png']}
                maxFileSize={3000000}
                onChange={images => {
                    setRowImageFile(images);
                    setShowImageEditor(true);
                }}
            >
                {({onImageUpload, isDragging, dragProps, errors}) => (
                    <>
                        <Box
                            onClick={onImageUpload}
                            {...dragProps}
                            style={
                                isDragging
                                    ? {
                                        borderColor: 'red',
                                    }
                                    : undefined
                            }
                            className={
                                options.shape === 'rect'
                                    ? classes.rectContainer
                                    : options.shape === 'circle'
                                    ? classes.circleContainer
                                    : ''
                            }
                        >
                            {!croppedImageFile && (
                                <Box style={{textAlign: 'center', padding: '1rem'}}>
                                    <Typography variant='subtitle1'>
                                        Drag image or click here
                                    </Typography>
                                </Box>
                            )}

                            {croppedImageFile && (
                                <img
                                    src={
                                        typeof croppedImageFile === 'string'
                                            ? croppedImageFile
                                            : croppedImageFile.dataURL
                                    }
                                    alt='profile'
                                    height='100%'
                                    width='100%'
                                    className={
                                        options.shape === 'rect'
                                            ? classes.rectImage
                                            : options.shape === 'circle'
                                            ? classes.circleImage
                                            : ''
                                    }
                                />
                            )}
                        </Box>

                        {errors && (
                            <div>
                                {errors.acceptType && (
                                    <span
                                        style={{
                                            fontSize: '0.75rem',
                                            color: 'red',
                                            marginLeft: '10px',
                                            marginTop: '5px',
                                            display: 'block',
                                        }}
                                    >
                    Please select image file
                  </span>
                                )}
                                {errors.maxFileSize && (
                                    <span
                                        style={{
                                            fontSize: '0.75rem',
                                            color: 'red',
                                            marginLeft: '10px',
                                            marginTop: '5px',
                                            display: 'block',
                                        }}
                                    >
                    Selected file size exceed maxFileSize (3MB)
                  </span>
                                )}
                            </div>
                        )}
                    </>
                )}
            </ImageUploading>

            <ImageEditor
                rowImageFile={rowImageFile}
                showImageEditor={showImageEditor}
                setShowImageEditor={setShowImageEditor}
                setCropper={setCropper}
                onImageCrop={onImageCrop}
                options={options}
            />
        </>
    );
};

export default ImageField;
