import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        contentContainer: {
            padding: '0.8rem',
            height: '120px',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        contentCard: {
            textDecoration: 'none',
            color: 'rgba(0, 0, 0, 0.87)',
        },
        profileContainer: {
            position: 'relative',
            borderRadius: '100%',
            overflow: 'Hidden',
            marginRight: '20px',
        },
        profileImage: {
            height: '80px',
            width: '80px',
            borderRadius: '100%',
            padding: '4px',
            border: '2px solid rgb(221, 221, 221)',
            verticalAlign: 'sub',
        },
        critical: {
            border: '2px solid red',
        },
        jursyNumberContainer: {
            position: 'absolute',
            top: '-2px',
            left: '5px',
            height: '75px',
            width: '70px',
            borderRadius: '100%',
            overflow: 'hidden',
        },
        jursyNumber: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            textAlign: 'center',
            position: 'absolute',
            width: '100%',
            bottom: '0',
            lineHeight: '19px',
            height: '17px',
            color: 'white',
            fontSize: '14px',
            fontWeight: 'bold',
        },
        name: {
            fontSize: '14px',
            fontWeight: '450',
        },
        nameCritical: {
            color: 'red',
        },
        description: {
            fontSize: '15px',
            fontWeight: '450',
        },
        createdContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        createdUserContainer: {
            marginRight: '5px',
        },
        createdBy: {
            fontSize: '11px',
        },
        date: {
            fontSize: '11px',
        },
        caseText: {
            fontSize: '14px',
        },
        caseTextCritical: {
            color: 'red',
        },
        descText: {
            fontSize: '16px',
            fontWeight: '450',
            margin: '5px 0px',
        },
        createdByText: {
            fontSize: '11px',
        },
        caregiverListContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        caregiversAvatarContainer: {
            display: 'flex',
        },
        createdByAvatar: {
            height: '28px',
            width: '28px',
            marginLeft: '5px',
            backgroundColor: '#0288d1',
        },
        avatar: {
            height: '28px',
            width: '28px',
            marginLeft: '5px',
            backgroundColor: '#fff',
            border: '1px solid #0288d1',
        },
        bottomContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        lockIcon: {
            color: '#637381',
        },
        createdByAvatarContent: {
            fontSize: '0.8rem',
            color: '#fff',
        },
        caregiverAvatarContent: {
            fontSize: '0.8rem',
            color: '#0288d1',
        },
        cardFooterCreatedBy: {
            display: 'block',
        },
        cardFooterUpdatedOn: {
            marginTop: '3px',
        },
        cardFooter: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
        },
    };
});
