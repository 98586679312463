import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    loginText: {
        color: 'blue',
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
