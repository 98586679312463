import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        header: {
            textAlign: 'right',
            marginBottom: '-10px',
        },
        icon: {
            cursor: 'pointer',
        },
    };
});
