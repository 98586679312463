import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        itemContainer: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
        missingOppositeContent: {
            '&:before': {
                content: '""!important',
                flex: '0!important',
                padding: '0px!important',
            },
        },
        timelineDot: {
            backgroundColor: 'white',
            margin: '0px',
            padding: '0',
        },
        timelineDotCritical: {
            backgroundColor: 'red',
            margin: '0px',
            padding: '0',
        },
        timelineAvatar: {
            height: '50px',
            width: '50px',
        },
        timelineContent: {
            paddingBottom: '1rem',
        },
        timelineContentInnerContainer: {
            padding: '1rem',
        },
        timelineContentInnerContainerCritical: {
            padding: '1rem',
            border: '1px solid red',
        },
        nameDate: {
            fontSize: '13px',
            color: '#6f6f6f',
        },
        description: {
            fontSize: '15px',
        },
        readBy: {
            fontSize: '12px',
            color: 'red',
            marginTop: '10px',
        },
        timelineFooter: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        timeplineImage: {
            display: 'inline-block',
        },
        modalImage: {
            height: '100px',
            width: '100px',
            marginTop: '5px',
            marginRight: '10px',
            marginLeft: '5px',
            display: 'inline-block',
            objectFit: 'contain',
        },
        athleteDesc: {
            fontSize: '12px',
            marginTop: '6px',
        },
        athleteDescCritical: {
            fontSize: '12px',
            marginTop: '6px',
            color: 'red',
        },
    };
});
