import React from 'react';
import {Box} from '@material-ui/core';
import {useStyles} from './styles';

/**
 * function to render error page
 * @param code: error code 
 * @param message: error message
 * @returns {JSX.Element}
 * @constructor
 */
const ErrorPage = ({code, message}) => {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Box className={classes.messageContainer}>
                <Box className={classes.messageBox}>
                    <Box className={classes.errorCode}>{code}</Box>
                    <Box className={classes.errorDesc}>{message}</Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ErrorPage;
