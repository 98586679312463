export const GET_HC_ORGANISATIONS = 'GET_HC_ORGANISATIONS';
export const GET_HC_ORGANISATIONS_WITHOUT_PARAMS =
    'GET_HC_ORGANISATIONS_WITHOUT_PARAMS';
export const ADD_HC_ORGANISATION = 'ADD_HC_ORGANISATION';
export const UPDATE_HC_ORGANISATION = 'UPDATE_HC_ORGANISATION';
export const GET_HC_ORGANISATION_BY_ID = 'GET_HC_ORGANISATION_BY_ID';
export const ASSIGN_VENDOR = 'ASSIGN_VENDOR';
export const ASSIGN_CAREGIVER = 'ASSIGN_CAREGIVER';
export const ASSIGN_ORG = 'ASSIGN_ORG';
export const UPDATE_ASSIGNED_VENDOR = 'UPDATE_ASSIGNED_VENDOR';
export const GET_ASSIGNED_VENDOR_BY_ID = 'GET_ASSIGNED_VENDOR_BY_ID';
export const REMOVE_ASSIGNED_ORG = 'REMOVE_ASSIGNED_ORG';
export const REMOVE_ASSIGNED_VENDOR = 'REMOVE_ASSIGNED_VENDOR';
export const TOGGLE_HCORG_ACTIVATION = 'TOGGLE_HCORG_ACTIVATION';
export const SET_TAB_VALUE = 'SET_TAB_VALUE';

/**
 * action creator function to handle tab change
 * @param tabValue: tabValue from api response data
 * @returns {{tabValue, type: string}}
 */
export const handleTabChange = tabValue => {
    return {
        type: SET_TAB_VALUE,
        tabValue: tabValue,
    };
};

/**
 * action creator function to get healthcare organizations
 * @param hcorgs: healthcare organizations from api response data
 * @returns {{hcorgs, type: string}}
 */
export const getHCOrganisations = hcorgs => {
    return {
        type: GET_HC_ORGANISATIONS,
        hcorgs: hcorgs,
    };
};

/**
 * action creator function to get healthcare organisations without params
 * @param hcorgs: healthcare organizations from api response data
 * @returns {{hcorgs, type: string}}
 */
export const getHCOrganisationsWithoutParams = hcorgs => {
    return {
        type: GET_HC_ORGANISATIONS_WITHOUT_PARAMS,
        hcorgs: hcorgs,
    };
};

/**
 * action creator function to add healthcare organization
 * @param hcorg: healthcare organisation
 * @returns {{hcorg, type: string}}
 */
export const addHCOrganisation = hcorg => {
    return {
        type: ADD_HC_ORGANISATION,
        hcorg: hcorg,
    };
};

/**
 * action creator function to get healthcare organization by id
 * @param hcorg: healthcare organisation
 * @returns {{hcorg, type: string}}
 */
export const getHCOrgById = hcorg => {
    return {
        type: GET_HC_ORGANISATION_BY_ID,
        hcorg: hcorg,
    };
};


/**
 * action creator function to update healthcare organization
 * @param hcorg: healthcare organisation to update
 * @returns {{hcorg, type: string}}
 */
export const updateHCOrg = hcorg => {
    return {
        type: UPDATE_HC_ORGANISATION,
        hcorg: hcorg,
    };
};

/**
 * action creator function to assign caregiver to a healthcare organization
 * @param caregiver: add caregiver to org
 * @returns {{caregiver, type: string}}
 */
export const assignCaregiver = caregiver => {
    return {
        type: ASSIGN_CAREGIVER,
        caregiver: caregiver,
    };
};

/**
 * action creator function to assign organization to a healthcare organization
 * @param org: organization to be associated to healthcare organization
 * @returns {{org, type: string}}
 */
export const assignOrg = org => {
    return {
        type: ASSIGN_ORG,
        org: org,
    };
};

/**
 * action creator function to assign vendor to a healthcare organization
 * @param vendor: vendor to be associated to healthcare organization
 * @returns {{vendor, type: string}}
 */
export const assignVendor = vendor => {
    return {
        type: ASSIGN_VENDOR,
        vendor: vendor,
    };
};

/**
 * action creator function to update assign vendor
 * @param vendor: to be updated to healthcare organization
 * @returns {{vendor, type: string}}
 */
export const updateAssignedVendor = vendor => {
    return {
        type: UPDATE_ASSIGNED_VENDOR,
        vendor: vendor,
    };
};

/**
 * action creator function to get assign vendor by id
 * @param vendor: associate vendor to healthcare organization
 * @returns {{vendor, type: string}}
 */
export const getAssignedVendorById = vendor => {
    return {
        type: GET_ASSIGNED_VENDOR_BY_ID,
        vendor: vendor,
    };
};

/**
 * action creator function to remove assigned organization from a healthcare organization
 * @param org: remove organization from healthcare organization
 * @returns {{org, type: string}}
 */
export const removeAssignedOrg = org => {
    return {
        type: REMOVE_ASSIGNED_ORG,
        org: org,
    };
};

/**
 * action creator function to remove assigned vendor from a healthcare organization
 * @param vendor: vendor to be removed
 * @returns {{vendor, type: string}}
 */
export const removeAssignedVendor = vendor => {
    return {
        type: REMOVE_ASSIGNED_VENDOR,
        vendor: vendor,
    };
};

/**
 * action creator function to toggle healthcare organization activation
 * @param hcorg: healthcare organization activation
 * @returns {{hcorg, type: string}}
 */
export const toggleActivation = hcorg => {
    return {
        type: TOGGLE_HCORG_ACTIVATION,
        hcorg: hcorg,
    };
};
