import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Slide,
} from '@material-ui/core';
import {useStyles} from './styles';

/**
 * function to render confirmation dialog with ok button
 * @param title: title on dialog
 * @param message: message on dialog
 * @param open: opens the dialog
 * @param onOk: function to handle ok click
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmationDialog = ({title, message, open, onOk}) => {
    const classes = useStyles();

    return (
        <Dialog
            maxWidth='sm'
            disableBackdropClick
            fullWidth
            open={open}
            TransitionComponent={Slide}
        >
            <DialogTitle>{title}</DialogTitle>

            <Divider/>

            <DialogContent classes={{root: classes.content}}>
                <DialogContentText component={Box}>{message}</DialogContentText>
            </DialogContent>

            <DialogActions classes={{root: classes.actionsContainer}}>
                <Button variant='contained' color='primary' onClick={onOk}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
