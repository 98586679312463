import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {NavLink, useRouteMatch} from 'react-router-dom';
import {Box, Breadcrumbs, Button, Card, Grid, Icon, Paper, Switch, Typography,} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {
    AutocompleteMultipltValidator,
    AutocompleteValidator,
    PhoneInputValidator,
} from '../../../../utilities/validators';
import UtilHelper from '../../../../utilities/validators/UtilHelper';
import {
    addCaregiverFromExistingUserRequest,
    addCaregiverRequest,
    getSignedUrlRequest,
    getUserByEmailRequest,
    uploadToS3Request,
} from '../../CaregiverApiActions';
import {getDeptsForOrgWithoutParamsRequest} from '../../../Departments/DepartmentsApiActions';
import {getRolesRequest, getTimezonesRequest} from '../../../Users/UsersApiActions';
import {useStyles} from './styles';
import ConfirmationDialog from '../../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import {getOrgByIdRequest} from '../../../Organizations/OrganisationsApiActions';
import ImageField from '../../../../utilities/components/ImageField/ImageField';
import {getIsTeamList, getUserInfo} from '../../../../utilities/storage';
import defaultUser from '../../../../assets/user.png';
import {dataURLtoFile} from '../../../../utilities/utilityFunctions';
import {ROLES, APP_URLS} from '../../../../utilities/constants';

/**
 * function to render caregiver form
 * @param location: location from router
 * @param history: history from router
 * @param getDeptsForOrgWithoutParams: function to get departments
 * @param departmentData: departments list
 * @param getRoles: function to get roles
 * @param rolesData: roles list
 * @param getTimezones: function to get timezones
 * @param timezones: timezones list
 * @param getUserByEmail: function to get user by email
 * @param addCaregiver: function to add caregiver
 * @param addCaregiverFromExistingUser: function to add caregiver from existing user
 * @param getOrgById: function to get organization by id
 * @param org: organization details
 * @param getSignedUrl: function to get signed url
 * @param uploadToS3: function to upload file on signed url
 * @returns {JSX.Element}
 * @constructor
 */
const CaregiverForm = ({
                           location,
                           history,
                           getDeptsForOrgWithoutParams,
                           departmentData,
                           getRoles,
                           rolesData,
                           getTimezones,
                           timezones,
                           getUserByEmail,
                           addCaregiver,
                           addCaregiverFromExistingUser,
                           getOrgById,
                           org,
                           getSignedUrl,
                           uploadToS3,
                       }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const match = useRouteMatch();
    const orgID = location.state.orgIdHistory;
    const departmentID = location.state.deptIdHistory;

    const [department, setDepartment] = useState(null);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [selectedRole, setSelectedRole] = useState(null);
    const [npi, setNpi] = useState('');
    const [timezone, setTimezone] = useState(null);
    const [head, setHead] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [userid, setUserid] = useState('');
    const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);
    const [rowImageFile, setRowImageFile] = useState(null);
    const [cropper, setCropper] = useState(null);
    const [croppedImageFile, setCroppedImageFile] = useState(null);
    const [showImageEditor, setShowImageEditor] = useState(false);
    const coachRole = rolesData && rolesData.find(role => role.name === ROLES.COACH);
    const filteredRolesData = rolesData.filter(
        role => role.name !== ROLES.SIRENMD_ADMIN
    );
    const currentUrlPath = match.path.split(':')[0];

    useEffect(() => {
        getOrgById(location.state.orgIdHistory).then(() => {
        });
    }, [getOrgById, location.state.orgIdHistory]);

    useEffect(() => {
        getDeptsForOrgWithoutParams(orgID).then(() => {
        });
    }, [orgID, getDeptsForOrgWithoutParams]);

    useEffect(() => {
        getRoles().then(() => {
        });
    }, [getRoles]);

    useEffect(() => {
        ValidatorForm.addValidationRule('isBlank', value =>
            UtilHelper.validateBlankSpace(value)
        );
        ValidatorForm.addValidationRule('isValidMobile', value =>
            UtilHelper.validateMobile(value)
        );
        ValidatorForm.addValidationRule('isValidNPI', value =>
            UtilHelper.validateNpi(value)
        );
        ValidatorForm.addValidationRule('isMaxLength', value =>
            UtilHelper.validateMaxLength(value)
        );

        return () => {
            ValidatorForm.removeValidationRule('isBlank');
            ValidatorForm.removeValidationRule('isValidMobile');
            ValidatorForm.removeValidationRule('isValidNPI');
            ValidatorForm.removeValidationRule('isMaxLength');
        };
    }, []);

    useEffect(() => {
        getTimezones().then(() => {
        });
    }, [getTimezones]);

    const breadCrumbDataForAddOrgCaregiverForm = [
        {
            icon: 'business',
            name: 'Organizations',
            path: APP_URLS.ORGANIZATIONS,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `${APP_URLS.ORGANIZATIONS}/${location.state && location.state.orgIdHistory}`,
        },
        {
            name: `Add Caregiver`,
            path: APP_URLS.ORGANIZATIONS_ADD_CAREGIVER,
        },
    ];

    const breadCrumbDataForAddOrgDeptCaregiverForm = [
        {
            icon: 'business',
            name: 'Organizations',
            path: APP_URLS.ORGANIZATIONS,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `${APP_URLS.ORGANIZATIONS}/${location.state && location.state.orgIdHistory}`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `${APP_URLS.ORGANIZATIONS_DEPTS}/${
                location.state && location.state.deptIdHistory
            }`,
        },
        {
            name: `Add Caregiver`,
            path: APP_URLS.ORGANIZATIONS_DEPTS_ADD_CAREGIVER,
        },
    ];

    const breadCrumbDataForAddHcorgOrgCaregiverForm = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: APP_URLS.HEALTHCARE_ORGANIZATIONS,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `${APP_URLS.HEALTHCARE_ORGANIZATIONS}/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `${APP_URLS.HEALTHCARE_ORGANIZATIONS_ORGS}/${
                location.state && location.state.orgIdHistory
            }`,
        },
        {
            name: `Add Caregiver`,
            path: APP_URLS.HEALTHCARE_ORGANIZATIONS_ORGS_ADD_CAREGIVER,
        },
    ];

    const breadCrumbDataForAddHcorgOrgDeptCaregiverForm = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: APP_URLS.HEALTHCARE_ORGANIZATIONS,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `${APP_URLS.HEALTHCARE_ORGANIZATIONS}/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `${APP_URLS.HEALTHCARE_ORGANIZATIONS_ORGS}/${
                location.state && location.state.orgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `${APP_URLS.HEALTHCARE_ORGANIZATIONS_ORGS_DEPTS}/${
                location.state && location.state.deptIdHistory
            }`,
        },
        {
            name: `Add Caregiver`,
            path: APP_URLS.HEALTHCARE_ORGANIZATIONS_ORGS_DEPTS_ADD_CAREGIVER,
        },
    ];

    const breadCrumbDataForAddAdminDeptCaregiverForm = [
        {
            icon: 'apartment',
            name: 'Departments',
            path: APP_URLS.DEPARTMENTS,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `${APP_URLS.DEPARTMENTS}/${location.state && location.state.deptIdHistory}`,
        },
        {
            name: `Add Caregiver`,
            path: APP_URLS.DEPARTMENTS_ADD_CAREGIVER,
        },
    ];

    /**
     * function to get breadcrumb data for current url
     * @returns {[{path: string, icon: string, name: string}, {path: string, name: string}, {path: string, name: string}, {path: string, name: string}, {path: string, name: string}]|[{path: string, icon: string, name: string}, {path: string, name: string}, {path: string, name: string}]|[{path: string, icon: string, name: string}, {path: string, name: string}, {path: string, name: string}, {path: string, name: string}]|*[]}
     */

     const getBreadcrumbData = (urlPath) => {
         switch(urlPath){
             case APP_URLS.ORGANIZATIONS_ADD_CAREGIVER: {
                 return breadCrumbDataForAddOrgCaregiverForm
             }
             case APP_URLS.ORGANIZATIONS_DEPTS_ADD_CAREGIVER: {
                return breadCrumbDataForAddOrgDeptCaregiverForm
             }
             case APP_URLS.HEALTHCARE_ORGANIZATIONS_ORGS_ADD_CAREGIVER: {
                return breadCrumbDataForAddHcorgOrgCaregiverForm
            }
            case APP_URLS.HEALTHCARE_ORGANIZATIONS_ORGS_DEPTS_ADD_CAREGIVER: {
                return breadCrumbDataForAddHcorgOrgDeptCaregiverForm
            }
            case APP_URLS.DEPARTMENTS_ADD_CAREGIVER: {
                return breadCrumbDataForAddAdminDeptCaregiverForm
            }
             default: {
                 return []
             }
         }
     }

    /**
     * function to handle search by email upon email input
     */
    const handleSearchUserByEmail = () => {
        if (email) {
            getUserByEmail(email).then(
                res => {
                    setUserid(res.id);
                    setCroppedImageFile(res.imageUrl ? res.imageUrl : defaultUser);
                    setFirstname(res.firstName);
                    setLastname(res.lastName);
                    setPhone(res.phone);
                    setSelectedRole(res.Roles[0]);
                    setIsDisabled(true);
                },
                () => {
                        setCroppedImageFile(null);
                        setFirstname('');
                        setLastname('');
                        setPhone('');
                        setSelectedRole(null);
                        setIsDisabled(false);
                }
            );
        } else {
            setCroppedImageFile(null);
            setFirstname('');
            setLastname('');
            setPhone('');
            setSelectedRole(null);
            setIsDisabled(false);
        }
    };

    /**
     * function to handle image crop
     */
    const onImageCrop = () => {
        const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL();
        const croppedImageFileObject = dataURLtoFile(
            croppedImageDataUrl,
            rowImageFile[0].file.name
        );

        const data = {
            dataURL: croppedImageDataUrl,
            file: croppedImageFileObject,
        };

        setCroppedImageFile(data);
        setShowImageEditor(false);
    };

    /**
     * function to add caregiver on button click
     * @param e: event from button click
     */
    const onAdd = e => {
        e.preventDefault();

        const deptIds = [];
        department && department.map(dept => deptIds.push(dept.id));

        const data = {
            departmentId: departmentID ? [departmentID] : deptIds,
            headDoctor: head,
            user: {
                email: email,
                phone: phone,
                firstName: firstname,
                lastName: lastname,
                roles: [selectedRole.name],
                npi: npi,
                TimezoneId: timezone.id,
            },
        };

        if (croppedImageFile) {
            const imageData = {
                fileName: croppedImageFile.file.name,
                fileType: `.${croppedImageFile.file.name.split('.').pop()}`,
            };

            getSignedUrl(imageData).then(res => {
                uploadToS3(res.urls, croppedImageFile.file).then(() => {
                    data['user']['imageUrl'] = res.path;
                    addCaregiver(data).then(() => {
                        history.goBack();
                    });
                });
            });
        } else {
            addCaregiver(data).then(() => {
                history.goBack();
            });
        }
    };

    /**
     * unction to add existing user as caregiver
     * @param e: event from button click
     */
    const onUserAdd = e => {
        e.preventDefault();

        const deptIds = [];
        department && department.map(dept => deptIds.push(dept.id));

        const data = {
            departmentId: departmentID ? [departmentID] : deptIds,
            headDoctor: head,
            userId: userid,
        };

        addCaregiverFromExistingUser(data).then(() => {
            history.goBack();
        });
    };

    /**
     * function to handle cancel add
     */
    const handleCancel = () => {
        history.goBack();
    };

    return (
        <Box
            className={
                role === ROLES.SIRENMD_ADMIN &&
                getIsTeamList() === 'false'
                    ? ''
                    : classes.outerContainer
            }
        >
            <Box
                className={
                    role === ROLES.SIRENMD_ADMIN &&
                    getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainer
                        : getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainerForAdmins
                        : classes.breadcrumbsContainerForUsers
                }
                component={Paper}
                p={1}
            >
                <Breadcrumbs>
                    {getBreadcrumbData(currentUrlPath).map((crumb, index) => (
                        <Box
                            key={index}
                            component={NavLink}
                            exact
                            to={{
                                pathname: crumb.path,
                                state: {
                                    orgIdHistory: location.state && location.state.orgIdHistory,
                                    orgNameHistory:
                                        location.state && location.state.orgNameHistory,
                                    deptIdHistory: location.state && location.state.deptIdHistory,
                                    deptNameHistory:
                                        location.state && location.state.deptNameHistory,
                                    hcorgIdHistory:
                                        location.state && location.state.hcorgIdHistory,
                                    hcorgNameHistory:
                                        location.state && location.state.hcorgNameHistory,
                                },
                            }}
                            className={classes.breadcrumbItem}
                            activeClassName={classes.activeBreadcrumbItem}
                        >
                            {crumb.icon && (
                                <Icon className={classes.breadcrumpIcon}>{crumb.icon}</Icon>
                            )}
                            <Box className={classes.breadcrumpLabel}>{crumb.name}</Box>
                        </Box>
                    ))}
                </Breadcrumbs>
            </Box>

            <Box mt={2}>
                <ValidatorForm onSubmit={userid ? onUserAdd : onAdd}>
                    <Box>
                        <Box className={classes.addCaregiverContainer}>
                            <Box className={classes.addCaregiverLabelContainer}>
                                <Typography className={classes.addCaregiverLabel}>
                                    Create Caregiver
                                </Typography>
                            </Box>
                        </Box>

                        <Box
                            component={Paper}
                            className={classes.addCaregiverFormContainer}
                        >
                            <Grid container spacing={2} justify='space-around'>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    {isDisabled ? (
                                        <Box>
                                            <img
                                                src={croppedImageFile}
                                                alt='profile'
                                                className={`${classes.circleContainer} ${classes.circleImage}`}
                                            />
                                        </Box>
                                    ) : (
                                        <Box>
                                            <ImageField
                                                rowImageFile={rowImageFile}
                                                setRowImageFile={setRowImageFile}
                                                showImageEditor={showImageEditor}
                                                setShowImageEditor={setShowImageEditor}
                                                setCropper={setCropper}
                                                croppedImageFile={croppedImageFile}
                                                onImageCrop={onImageCrop}
                                                options={{
                                                    shape: 'circle',
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextValidator
                                                label='Email *'
                                                variant='outlined'
                                                fullWidth
                                                size='small'
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                                onBlur={handleSearchUserByEmail}
                                                validators={['required', 'isEmail']}
                                                errorMessages={['Email is required', 'Invalid email']}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <PhoneInputValidator
                                                disabled={isDisabled}
                                                autoFormat={false}
                                                variant='outlined'
                                                size='small'
                                                label='Phone *'
                                                fullWidth
                                                defaultCountry='us'
                                                countryCodeEditable={false}
                                                value={phone}
                                                onChange={(phone) => {
                                                    let phoneNumber = '+' + phone.replace(/[^0-9]+/g, '');
                                                    setPhone(phoneNumber);
                                                }}
                                                validators={['required', 'isValidMobile']}
                                                errorMessages={[
                                                    'Phone is required',
                                                    'Please enter valid phone',
                                                ]}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextValidator
                                                disabled={isDisabled}
                                                label='First Name *'
                                                variant='outlined'
                                                fullWidth
                                                size='small'
                                                value={firstname}
                                                onChange={e => setFirstname(e.target.value)}
                                                validators={['required', 'isBlank', 'isMaxLength']}
                                                errorMessages={[
                                                    'First name is required',
                                                    'Please enter first name',
                                                    'Maximum 100 letters are accepted.',
                                                ]}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextValidator
                                                disabled={isDisabled}
                                                label='Last Name *'
                                                variant='outlined'
                                                fullWidth
                                                size='small'
                                                value={lastname}
                                                onChange={e => setLastname(e.target.value)}
                                                validators={['required', 'isBlank', 'isMaxLength']}
                                                errorMessages={[
                                                    'Last name is required',
                                                    'Please enter last name',
                                                    'Maximum 100 letters are accepted.',
                                                ]}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <AutocompleteValidator
                                                disabled={isDisabled}
                                                fullWidth
                                                size='small'
                                                value={selectedRole}
                                                onChange={(e, value) => {
                                                    setSelectedRole(value);
                                                }}
                                                options={filteredRolesData}
                                                getOptionLabel={option => option.name}
                                                getOptionSelected={(option, value) => {
                                                    if (option && value) {
                                                        return option.id === value.id;
                                                    } else {
                                                        return true;
                                                    }
                                                }}
                                                getOptionDisabled={option => {
                                                    if (org && org.typeId === 1) {
                                                        return option === coachRole;
                                                    }
                                                    return false;
                                                }}
                                                renderInput={params => (
                                                    <TextValidator
                                                        {...params}
                                                        label='Role *'
                                                        variant='outlined'
                                                    />
                                                )}
                                                validators={['required']}
                                                errorMessages={['Role is required']}
                                            />
                                        </Grid>

                                        {!isDisabled && (
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextValidator
                                                    label='NPI'
                                                    variant='outlined'
                                                    fullWidth
                                                    size='small'
                                                    value={npi}
                                                    onChange={e => setNpi(e.target.value)}
                                                    validators={['isValidNPI']}
                                                    errorMessages={['Please enter valid NPI']}
                                                />
                                            </Grid>
                                        )}

                                        {!isDisabled && (
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <AutocompleteValidator
                                                    fullWidth
                                                    size='small'
                                                    value={timezone}
                                                    onChange={(e, value) => {
                                                        setTimezone(value);
                                                    }}
                                                    options={timezones}
                                                    getOptionLabel={option => {
                                                        return (
                                                            option.description.charAt(0).toUpperCase() +
                                                            option.description.slice(1)
                                                        );
                                                    }}
                                                    getOptionSelected={(option, value) =>
                                                        option.id === value.id
                                                    }
                                                    renderInput={params => (
                                                        <TextValidator
                                                            {...params}
                                                            label='Timezone *'
                                                            variant='outlined'
                                                        />
                                                    )}
                                                    validators={['required']}
                                                    errorMessages={['Timezone is required']}
                                                />
                                            </Grid>
                                        )}

                                        {!departmentID && (
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <AutocompleteMultipltValidator
                                                    multiple
                                                    filterSelectedOptions
                                                    fullWidth
                                                    size='small'
                                                    value={department}
                                                    onChange={(e, value) => {
                                                        setDepartment(value);
                                                    }}
                                                    options={
                                                        departmentData.rows ? departmentData.rows : []
                                                    }
                                                    getOptionLabel={option => option.name}
                                                    getOptionSelected={(option, value) =>
                                                        option.id === value.id
                                                    }
                                                    renderInput={params => (
                                                        <TextValidator
                                                            {...params}
                                                            label='Department *'
                                                            variant='outlined'
                                                        />
                                                    )}
                                                    validators={['required']}
                                                    errorMessages={['Department is required']}
                                                />
                                            </Grid>
                                        )}

                                        {selectedRole &&
                                        selectedRole.name !== 'Limited User' &&
                                        selectedRole.name !== 'Healthcare Professional' ? (
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Box className={classes.switchContainer}>
                                                    <Box>
                                                        <Typography variant='inherit'>
                                                            Head Doctor / Head Nurse / Head Athletic Trainer
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Switch
                                                            color='primary'
                                                            checked={head}
                                                            onChange={e => setHead(e.target.checked)}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box className={classes.footerContainer}>
                            <Box
                                component={Card}
                                raised
                                className={classes.footerContainerInner}
                            >
                                <Box>
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        disableElevation={true}
                                        onClick={() => setOpenCancelConfirmation(true)}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        variant='contained'
                                        color='primary'
                                        disableElevation={true}
                                        type='submit'
                                        className={classes.saveButton}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </ValidatorForm>
            </Box>

            <Box>
                <ConfirmationDialog
                    open={openCancelConfirmation}
                    setOpen={setOpenCancelConfirmation}
                    title='Cancel'
                    message='Are you sure you want to cancel?'
                    onConfirm={handleCancel}
                />
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        departmentData: state.departments.depts,
        rolesData: state.sirenUsers.roles,
        timezones: state.sirenUsers.timezones,
        org: state.organisations.org,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addCaregiver: data => {
            return dispatch(addCaregiverRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        addCaregiverFromExistingUser: data => {
            return dispatch(addCaregiverFromExistingUserRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getDeptsForOrgWithoutParams: orgId => {
            return dispatch(getDeptsForOrgWithoutParamsRequest(orgId)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },

        getRoles: () => {
            return dispatch(getRolesRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getSignedUrl: data => {
            return dispatch(getSignedUrlRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        uploadToS3: (url, file) => {
            return dispatch(uploadToS3Request(url, file)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getUserByEmail: email => {
            return dispatch(getUserByEmailRequest(email)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getTimezones: () => {
            return dispatch(getTimezonesRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getOrgById: id => {
            return dispatch(getOrgByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CaregiverForm);
