import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid,} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import UtilHelper from '../../../../utilities/validators/UtilHelper';
import {changePasswordRequest} from '../../AuthApiActions';
import {logoutUser} from '../../../../utilities/storage';
import {useStyles} from './styles';
import ChangePasswordMessage from '../../../../utilities/components/ChangePasswordMessageDialog/ChangePasswordMessageDialog';
import {APP_URLS} from '../../../../utilities/constants';

/**
 * function to render change password dialog
 * @param openChangePasswordDialog: opens the dialog
 * @param setOpenChangePasswordDialog: function to change open state
 * @param changePassword: function to change password
 * @returns {JSX.Element}
 * @constructor
 */
const ChangePasswordDialog = ({
                                  openChangePasswordDialog,
                                  setOpenChangePasswordDialog,
                                  changePassword,
                              }) => {
    const classes = useStyles();
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [openMessageDialog, setOpenMessageDialog] = useState(false);

    useEffect(() => {
        ValidatorForm.addValidationRule('isValidPassword', value =>
            UtilHelper.validatePassword(value)
        );
        ValidatorForm.addValidationRule('isPasswordMatch', value =>
            UtilHelper.matchPassword(value, password)
        );

        return () => {
            ValidatorForm.removeValidationRule('isValidPassword');
            ValidatorForm.removeValidationRule('isPasswordMatch');
        };
    }, [password]);

    /**
     * function to change password on button click
     * @param e: event from button click
     */
    const handleChangePassword = e => {
        e.preventDefault();

        const data = {
            currentPassword: oldPassword,
            newPassword: password,
        };

        changePassword(data).then(() => {
            handleClose();
            setOpenMessageDialog(true);
        });
    };

    /**
     * function to handle ok button click on confirmation dialog
     */
    const handleOkClick = () => {
        logoutUser();
        window.location.href = APP_URLS.LOGIN;
    };

    /**
     * function to clear form and close dialog
     */
    const handleClose = () => {
        setOldPassword('');
        setPassword('');
        setConfirmPassword('');
        setOpenChangePasswordDialog(false);
    };

    return (
        <>
            <ChangePasswordMessage
                open={openMessageDialog}
                setOpen={setOpenMessageDialog}
                title='Password Changed'
                message='Please login again with new password to continue.'
                onOk={handleOkClick}
            />

            <Dialog fullWidth open={openChangePasswordDialog} onClose={handleClose}>
                <ValidatorForm onSubmit={handleChangePassword}>
                    <DialogTitle>Change Password</DialogTitle>

                    <Divider/>

                    <DialogContent classes={{root: classes.contentContainer}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextValidator
                                    variant='outlined'
                                    fullWidth
                                    size='small'
                                    name='password'
                                    label='Old Password'
                                    type='password'
                                    validators={['required']}
                                    errorMessages={['Old Password is required']}
                                    value={oldPassword}
                                    onChange={e => setOldPassword(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextValidator
                                    variant='outlined'
                                    fullWidth
                                    size='small'
                                    name='password'
                                    label='New Password'
                                    type='password'
                                    validators={['required', 'isValidPassword']}
                                    errorMessages={[
                                        'Password is required',
                                        'Password should have minimum 1 uppercase, 1 lowercase, 1 numeric, 1 special characters',
                                    ]}
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextValidator
                                    variant='outlined'
                                    fullWidth
                                    size='small'
                                    name='password'
                                    label='Confirm New Password'
                                    type='password'
                                    validators={['isPasswordMatch']}
                                    errorMessages={['Passwords does not match ']}
                                    value={confirmPassword}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions classes={{root: classes.actionsContainer}}>
                        <Button onClick={handleClose} variant='outlined' color='primary'>
                            Cancel
                        </Button>
                        <Button type='submit' variant='contained' color='primary'>
                            Save
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        </>
    );
};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProp = dispatch => {
    return {
        changePassword: data => {
            return dispatch(changePasswordRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProp
)(ChangePasswordDialog);
