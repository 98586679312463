import React from 'react';
import momentTimezone from 'moment-timezone';
import {Avatar, Box, Paper, Typography} from '@material-ui/core';
import {TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator,} from '@material-ui/lab';
import Tippy from '@tippyjs/react';
import {useStyles} from './styles';
import {getUserInfo} from '../../../../../../utilities/storage';
import dicomThumb from '../../../../../../assets/dicom.jpg';

momentTimezone.updateLocale('en', {
    calendar: {
        lastDay: '[Yesterday at] LT',
        sameDay: '[Today at] LT',
        sameElse: 'MMM DD, YYYY [at] LT',
    },
});

/**
 * function to render note item
 * @param note: note item details
 * @returns {JSX.Element}
 * @constructor
 */
const NoteTimeline = ({note}) => {
    const classes = useStyles();

    return (
        <TimelineItem
            classes={{
                missingOppositeContent: classes.missingOppositeContent,
            }}
        >
            <TimelineSeparator>
                <TimelineDot classes={{root: classes.timelineDot}}>
                    <Tippy
                        content={`${getUserInfo().firstName} ${getUserInfo().lastName}`}
                    >
                        <Avatar
                            src={getUserInfo().imageUrl}
                            className={classes.timelineAvatar}
                        />
                    </Tippy>
                </TimelineDot>
                <TimelineConnector/>
            </TimelineSeparator>
            
            <TimelineContent classes={{root: classes.timelineContent}}>
                <Box
                    component={Paper}
                    className={classes.timelineContentInnerContainer}
                >
                    <Box>
                        <Typography classes={{root: classes.name}}>
                            {getUserInfo().firstName} {getUserInfo().lastName}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography classes={{root: classes.description}}>
                            {note.noteText}
                        </Typography>
                    </Box>
                    {note.mediaId.map((item, index) => (
                        <Box key={index} className={classes.timeplineImage}>
                            <img
                                className={classes.modalImage}
                                src={dicomThumb}
                                alt='dicom'
                            />
                        </Box>
                    ))}

                    {note.noteImages.map((noteImage, index) => (
                        <Box key={index} className={classes.timeplineImage}>
                            <img
                                className={classes.modalImage}
                                src={noteImage.data_url}
                                alt='note'
                            />
                        </Box>
                    ))}

                    <Box className={classes.dateContainer}>
                        <Typography className={classes.nameDate}>
                            {momentTimezone(new Date())
                                .tz(getUserInfo().Timezone.name)
                                .format('MM/DD/YYYY hh:mm a')}
                        </Typography>
                    </Box>
                </Box>
            </TimelineContent>
        </TimelineItem>
    );
};

export default NoteTimeline;
