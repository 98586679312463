export const ADD_ADMIN = 'ADD_ADMIN';
export const ADD_ADMIN_FROM_EXISTING_USER = 'ADD_ADMIN_FROM_EXISTING_USER';
export const GET_ADMINS_FOR_DEPT = 'GET_ADMINS_FOR_DEPT';
export const GET_ADMINS_FOR_ORG = 'GET_ADMINS_FOR_ORG';
export const REMOVE_ADMIN_FROM_ORG = 'REMOVE_ADMIN_FROM_ORG';
export const REMOVE_ADMIN_FROM_DEPT = 'REMOVE_ADMIN_FROM_DEPT';
export const GET_USER_BY_EMAIL = 'GET_USER_BY_EMAIL';

/**
 * action creator function to add admin
 * @param admin: admin from api response data
 * @returns {{admin, type: string}}
 */
export const addAdmin = admin => {
    return {
        type: ADD_ADMIN,
        admin: admin,
    };
};

/**
 * action creator function to add admin from existing user
 * @param admin: admin from api response data
 * @returns {{admin, type: string}}
 */
export const addAdminFromExistingUser = admin => {
    return {
        type: ADD_ADMIN_FROM_EXISTING_USER,
        admin: admin,
    };
};

/**
 * action creator function to get admins of department
 * @param admins: admins from api response data
 * @returns {{type: string, admins}}
 */
export const getAdminsForDept = admins => {
    return {
        type: GET_ADMINS_FOR_DEPT,
        admins: admins,
    };
};

/**
 * action creator function to get admins of organization
 * @param admins: admins from api response data
 * @returns {{type: string, admins}}
 */
export const getAdminsForOrg = admins => {
    return {
        type: GET_ADMINS_FOR_ORG,
        admins: admins,
    };
};

/**
 * action creator function to remove admin from organization
 * @param admin: admin from api response data
 * @returns {{admin, type: string}}
 */
export const removeAdminFromOrg = admin => {
    return {
        type: REMOVE_ADMIN_FROM_ORG,
        admin: admin,
    };
};

/**
 * action creator function to remove admin from department
 * @param admin: admin from api response data
 * @returns {{admin, type: string}}
 */
export const removeAdminFromDept = admin => {
    return {
        type: REMOVE_ADMIN_FROM_DEPT,
        admin: admin,
    };
};

/**
 * action creator function to get user by email
 * @param user: user from api response data
 * @returns {{type: string, user}}
 */
export const getUserByEmail = user => {
    return {
        type: GET_USER_BY_EMAIL,
        user: user,
    };
};
