/**
 * function to handle debounce
 * @param func: function for debounce
 * @param wait: wait time for debounce 
 * @returns {Function}
 */
export const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
        const context = this;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
};

/**
 * function to conver dataURL to File
 * @param dataurl: dataurl
 * @param filename: filename
 * @returns {File}
 */
export const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], filename, {type: mime});
};

/**
 * function to sort json array
 * @param array: array
 * @param key: key
 * @returns {Array}
 */
export const sortJsonArray = (array, key) => {
    return array.sort(function (a, b) {
        if (a[key] < b[key]) {
            return -1;
        }
        if (a[key] > b[key]) {
            return 1;
        }
        return 0;
    });
};

/**
 * function to sort json array by time
 * @param array: array
 * @param key: key
 * @returns {Array}
 */
export const sortJsonArrayByTime = (array, key) => {
    array.sort((a, b) => Date.parse(b[key]) - Date.parse(a[key]));
};
