import React from 'react';
import {Link} from 'react-router-dom';
import {Box, Typography} from '@material-ui/core';
import {useStyles} from './styles';

/**
 * function to render app footer
 * @returns {JSX.Element}
 * @constructor
 */
const AppFooter = () => {
    const classes = useStyles();
    return (
        <>
            <Box className={classes.termsContainer}>
                <Box>
                    <Typography
                        component={Link}
                        to='/'
                        classes={{root: classes.termsText}}
                    >
                        Terms & Conditions
                    </Typography>
                </Box>
                <Box className={classes.contactContainer}>
                    <Typography
                        component={Link}
                        to='/'
                        classes={{root: classes.contactText}}
                    >
                        Contact
                    </Typography>
                </Box>
            </Box>
            <Box>
                <Typography classes={{root: classes.yearText}}>
                    &copy; {new Date().getFullYear()} Teleport Med, LLC
                </Typography>
            </Box>
        </>
    );
};

export default AppFooter;
