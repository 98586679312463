export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const HIDE_SNACK_BAR = 'HIDE_SNACK_BAR';
export const SHOW_SNACK_BAR = 'SHOW_SNACK_BAR';

/**
 * action creator function to show loader
 * @returns {{showLoader, type: string}}
 */
export const showLoader = () => {
    return {
        type: SHOW_LOADER,
        showLoader: true,
    };
};

/**
 * action creator function to hide loader
 * @returns {{showLoader, type: string}}
 */
export const hideLoader = () => {
    return {
        type: HIDE_LOADER,
        showLoader: false,
    };
};

/**
 * action creator function to show snackbar
 * @param snackbarMessage: snackbar message
 * @param snackbarVariant: snackbar variant
 * @returns {{snackbarMessage, snackbarVariant, type: string}}
 */
export const showSnackbar = (snackbarMessage, snackbarVariant) => {
    return {
        type: SHOW_SNACK_BAR,
        snackbarMessage,
        snackbarVariant,
    };
};

/**
 * action creator function to clear snackbar
 * @returns {{type: string}}
 */
export const clearSnackbar = () => {
    return {
        type: HIDE_SNACK_BAR,
    };
};
