import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        container: {
            zIndex: '1000000',
            color: 'white',
        },
    };
});
