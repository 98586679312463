import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider,} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {AutocompleteValidator} from '../../validators';
import {getTimezonesRequest, updateUserRequest} from '../../../components/Users/UsersApiActions';
import {getUserInfo, setUserInfo} from '../../storage';
import {useStyles} from './styles';

/**
 * function to render timezone dialog
 * @param openTimezoneDialog: boolean to open timezone dialog
 * @param setOpenTimezoneDialog: function to set timezone dialog
 * @param getTimezones: function to get timezones
 * @param timezones: timezones list
 * @param updateUser: function to update user
 * @returns {JSX.Element}
 * @constructor
 */
const TimezoneDialog = ({
                                  openTimezoneDialog,
                                  setOpenTimezoneDialog,
                                  getTimezones,
                                  timezones,
                                  updateUser,
                              }) => {
    const classes = useStyles();
    const [timezone, setTimezone] = useState(getUserInfo().Timezone);

    useEffect(() => {
        if(openTimezoneDialog){
            getTimezones().then(res => {});
        }
    }, [getTimezones, openTimezoneDialog]);


    /**
     * function to close timezone dialog
     */
    const handleClose = () => {
        setOpenTimezoneDialog(false);
        setTimezone(getUserInfo().Timezone);
    };

    /**
     * function to handle timezone submit
     */
    const onSubmit = () => {
        const data = {
            TimezoneId: timezone.id,
        };

        updateUser(getUserInfo().id, data).then(res => {
            setUserInfo(res);
            setOpenTimezoneDialog(false);
        });
    };

    return (
        <Dialog fullWidth open={openTimezoneDialog} onClose={handleClose}>
            <ValidatorForm onSubmit={onSubmit}>
                <DialogTitle>Timezone</DialogTitle>

                <Divider/>

                <DialogContent>
                    <Box className={classes.timezoneContainer}>
                        <AutocompleteValidator
                            fullWidth
                            size='small'
                            value={timezone}
                            onChange={(e, value) => {
                                setTimezone(value);
                            }}
                            options={timezones}
                            getOptionLabel={option => {
                                return (
                                    option.description.charAt(0).toUpperCase() +
                                    option.description.slice(1)
                                );
                            }}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={params => (
                                <TextValidator
                                    {...params}
                                    label='Select Timezone'
                                    variant='outlined'
                                />
                            )}
                            validators={['required']}
                            errorMessages={['Timezone is required']}
                        />
                    </Box>
                </DialogContent>
                
                <DialogActions classes={{root: classes.actionsContainer}}>
                    <Button onClick={handleClose} variant='outlined' color='primary'>
                        Cancel
                    </Button>
                    <Button type='submit' variant='contained' color='primary'>
                        Save
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
};

const mapStateToProps = state => {
    return {
        timezones: state.sirenUsers.timezones,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTimezones: () => {
            return dispatch(getTimezonesRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateUser: (id, data) => {
            return dispatch(updateUserRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TimezoneDialog);
