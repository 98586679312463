import {makeStyles} from '@material-ui/core';

const drawerWidth = 260;

export const useStyles = makeStyles(theme => {
    return {
        drawerContentContainer: {
            overflowX: 'hidden',
            height: '100%',
        },
        drawerInnerContentContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
        },
        name: {
            fontSize: '1rem',
            fontWeight: '500',
            whiteSpace: 'normal!important',
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: '61px',

            [theme.breakpoints.up('sm')]: {
                width: '61px',
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginLeft: '16px',
            height: '70px',
            marginBottom: '1rem',
        },
        item: {
            display: 'flex',
            paddingTop: 0,
            paddingBottom: 0,
        },
        button: {
            padding: '10px 16px',
            justifyContent: 'flex-start',
            textTransform: 'none',
            letterSpacing: 0,
            width: '100%',
            borderRadius: '0px',
        },
        profileButton: {
            padding: '10px',
            justifyContent: 'flex-start',
            textTransform: 'none',
            letterSpacing: 0,
            width: '100%',
            borderRadius: '0px',
        },
        buttonLabel: {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },
        icon: {
            color: '#637381',
            width: 24,
            height: 28,
            display: 'flex',
            alignItems: 'center',
            marginRight: '14px',
            fill: '#637381',
        },
        active: {
            color: '#1162fe',
            borderRight: '3px solid #1162fe',
            backgroundColor: 'rgba(17, 98, 254, 0.1)',
            '& $icon': {
                color: '#1162fe',
            },
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        role: {
            marginTop: '4px',
            fontSize: '0.8rem',
        },
        logo: {
            height: '55px',
            marginLeft: '0%',
        },
        userProfileOuterContainer: {
            paddingBottom: '1rem',
            marginTop: '-13px',
        },
        userProfileContainer: {
            marginTop: '20px',
            padding: '0',
            minHeight: '36px',
        },
        userAvatarContainer: {
            display: 'inline-block',
            verticalAlign: 'top',
        },
        userProfileDetails: {
            display: 'inline-block',
            marginLeft: '10px',
            textAlign: 'left',
        },

        logoutButtonContainer: {
            paddingBottom: '0.1rem',
            display: 'block',
            position: 'fixed',
            bottom: '0',
            backgroundColor: '#fff',
        },

        logoutButtonOpen: {
            padding: '10px 16px',
            paddingRight: '0px',
            justifyContent: 'flex-start',
            textTransform: 'none',
            letterSpacing: 0,
            borderRadius: '0px',
            width: '259px',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },

        logoutButtonClose: {
            padding: '10px 16px',
            paddingRight: '0px',
            justifyContent: 'flex-start',
            textTransform: 'none',
            letterSpacing: 0,
            borderRadius: '0px',
            overflowX: 'hidden',
            minWidth: '59px',

            [theme.breakpoints.up('sm')]: {
                minWidth: '59px',
            },

            transition: theme.transitions.create('min-width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        submenuHeading: {
            paddingTop: '1rem',
            padding: '0.5rem 1.2rem',
        },
        menuHeight: {
            height: '590px',
        },
    };
});
