import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        container: {
            width: '100%',
            height: '94vh',
            marginTop: '0.3rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fafafa',
        },
        messageContainer: {
            padding: '2rem',
        },
        messageBox: {
            display: 'flex',
            alignItems: 'center',
            width: '80%',
            margin: 'auto',
        },
        errorCode: {
            fontSize: '8rem',
            fontWeight: 'normal',
            borderRight: '2px dashed #c2c2c2',
            paddingRight: '1rem',
            color: '#c2c2c2',
        },
        errorDesc: {
            fontSize: '1.7rem',
            paddingLeft: '1rem',
            fontWeight: '200',
        },
    };
});
