import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        outerContainer: {
            paddingTop: '1rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            paddingBottom: '1rem',
        },
        container: {
            marginBottom: '4rem',
        },
        labelContainer: {
            marginBottom: '10px',
        },
        label: {
            fontSize: '1.6rem',
        },
        formContainer: {
            marginTop: '20px',
        },
        formInnerContainer: {
            paddingTop: '0.5rem',
            paddingBottom: '2rem',
            paddingLeft: '1rem',
            paddingRight: '1.5rem',
        },
        formPatientDetailsLabel: {
            marginTop: '20px',
            marginBottom: '15px',
        },
        dropzoneContainer: {
            minHeight: '150px',
        },
        dropzoneText: {
            fontSize: '1.2rem',
            marginTop: '1rem',
            marginBottom: '0.5rem',
            marginLeft: '0.5rem',
            marginRight: '0.5rem',
        },
        imagePreviewCancelButtonContainer: {
            textAlign: 'right',
            margin: '-24px',
        },
        imagePreviewConatiner: {
            display: 'block',
            height: '200px',
            width: '100%',
        },
        imagePreview: {
            height: '100%',
            width: '100%',
        },
        genderContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        genderInnerContainer: {
            marginLeft: '0.5rem',
            marginRight: '1rem',
        },
        genderLabel: {
            fontSize: '1rem',
        },
        vipContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        vipInnerContainer: {
            marginLeft: '0.5rem',
            marginRight: '1rem',
        },
        vipLabel: {
            fontSize: '1rem',
        },
        addInfoContainer: {
            paddingTop: '1px',
            paddingBottom: '2rem',
            paddingLeft: '1rem',
            marginTop: '1rem',
        },
        addInfoLabel: {
            marginTop: '30px',
            marginBottom: '15px',
        },
        addInfoContentContainer: {
            width: '98%',
            margin: 'auto',
            paddingRight: '0.4rem',
        },
        otherInfoContainer: {
            paddingTop: '1px',
            paddingBottom: '2rem',
            paddingLeft: '1rem',
            marginTop: '1rem',
        },
        otherInfoLabel: {
            marginTop: '30px',
            marginBottom: '15px',
        },
        otherInfoContentContainer: {
            width: '98%',
            margin: 'auto',
            paddingRight: '0.4rem',
        },
        footerActionsButtonsContainer: {
            zIndex: '1000',
            position: 'fixed',
            bottom: '0',
            width: '100%',
            left: '0',
        },
        footerActionsButtonsInnerContainer: {
            height: '60px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingRight: '1.5rem',
        },
        saveButton: {
            marginLeft: '1rem',
        },
        breadcrumbsContainer: {
            position: 'sticky',
            zIndex: '1000',
            top: '0',
            marginTop: '-16px',
            marginLeft: '-25px',
            marginRight: '-25px',
        },
        breadcrumbsContainerForUsers: {
            position: 'sticky',
            zIndex: '1000',
            top: '75px',
            marginTop: '-16px',
            marginLeft: '-15px',
            marginRight: '-25px',
        },
        breadcrumbsContainerForAdmins: {
            position: 'sticky',
            zIndex: '1000',
            top: '0px',
            marginTop: '-28px',
            marginLeft: '-15px',
            marginRight: '-25px',
        },
        breadcrumbItem: {
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: theme.palette.text.primary,
            padding: '4px 0px',
        },
        activeBreadcrumbItem: {
            color: theme.palette.primary.main,
        },
        breadcrumpIcon: {
            marginRight: '5px',
            fontSize: '18px',
        },
        breadcrumpLabel: {
            fontSize: '14px',
        },
    };
});
