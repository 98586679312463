export const GET_VENDORS = 'GET_VENDORS';
export const GET_VENDORS_FOR_HC_ORG = 'GET_VENDORS_FOR_HC_ORG';
export const GET_VENDORS_WITHOUT_PARAMS = 'GET_VENDORS_WITHOUT_PARAMS';
export const ADD_VENDOR = 'ADD_VENDOR';
export const UPDATE_VENDOR = 'UPDATE_VENDOR';
export const GET_VENDOR_BY_ID = 'GET_VENDOR_BY_ID';
export const GET_VENDOR_TYPES = 'GET_VENDOR_TYPES';
export const TOGGLE_VENDOR_ACTIVATION = 'TOGGLE_VENDOR_ACTIVATION';
export const GET_ALL_SYSTEMS = 'GET_ALL_SYSTEMS';

/**
 * action creator function to get vendors
 * @param vendors: vendors from api response data
 * @returns {{type: string, vendors}}
 */
export const getVendors = vendors => {
    return {
        type: GET_VENDORS,
        vendors: vendors,
    };
};

/**
 * action creator function to get vendors for healthcare organization
 * @param vendorsForHCOrg
 * @returns {{type: string, vendorsForHCOrg}}
 */
export const getVendorsForHCOrg = vendorsForHCOrg => {
    return {
        type: GET_VENDORS_FOR_HC_ORG,
        vendorsForHCOrg: vendorsForHCOrg,
    };
};

/**
 * action creator function to get vendors without params
 * @param vendors: vendors from api response data
 * @returns {{type: string, vendors}}
 */
export const getVendorsWithoutParams = vendors => {
    return {
        type: GET_VENDORS_WITHOUT_PARAMS,
        vendors: vendors,
    };
};

/**
 * action creator function to add vendor
 * @param vendor: vendor from api response data
 * @returns {{vendor, type: string}}
 */
export const addVendor = vendor => {
    return {
        type: ADD_VENDOR,
        vendor: vendor,
    };
};

/**
 * action creator function to get vendor by id
 * @param vendor: vendor from api response data
 * @returns {{vendor, type: string}}
 */
export const getVendorById = vendor => {
    return {
        type: GET_VENDOR_BY_ID,
        vendor: vendor,
    };
};

/**
 * action creator function to update vendor
 * @param vendor: vendor from api response data
 * @returns {{vendor, type: string}}
 */
export const updateVendor = vendor => {
    return {
        type: UPDATE_VENDOR,
        vendor: vendor,
    };
};

/**
 * action creator function to get vendor types
 * @param vendorTypes: vendorTypes from api response data
 * @returns {{vendorTypes, type: string}}
 */
export const getVendorTypes = vendorTypes => {
    return {
        type: GET_VENDOR_TYPES,
        vendorTypes: vendorTypes,
    };
};

/**
 * action creator function to toggle vendor activation
 * @param vendor: vendor from api response data
 * @returns {{vendor, type: string}}
 */
export const toggleActivation = vendor => {
    return {
        type: TOGGLE_VENDOR_ACTIVATION,
        vendor: vendor,
    };
};

/**
 * action creator function to get systems
 * @param systems: systems from api response data
 * @returns {{systems, type: string}}
 */
export const getAllSystems = systems => {
    return {
        type: GET_ALL_SYSTEMS,
        systems: systems,
    };
};
