export const GET_SIREN_USERS = 'GET_SIREN_USERS';
export const GET_USERS_FOR_ADMIN = 'GET_USERS_FOR_ADMIN';
export const GET_SIREN_USERS_WITHOUT_PARAMS = 'GET_SIREN_USERS_WITHOUT_PARAMS';
export const ADD_SIREN_USER = 'ADD_SIREN_USER';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_ROLES = 'GET_ROLES';
export const TOGGLE_USER_ACTIVATION = 'TOGGLE_USER_ACTIVATION';
export const GET_USER_DEPTS = 'GET_USER_DEPTS';
export const SET_SELECTED_DEPT = 'SET_SELECTED_DEPT';
export const GET_STATES = 'GET_STATES';
export const GET_TIMEZONES = 'GET_TIMEZONES';
export const GET_ASSIGNED_ORGS = 'GET_ASSIGNED_ORGS';
export const TOGGLE_EMAIL_UPDATE = 'TOGGLE_EMAIL_UPDATE';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

/**
 * action creator function to get users for SirenMD admin users
 * @param users: users from api response data
 * @returns {{type: string, users}}
 */
export const getSirenUsers = users => {
    users.rows = users.rows.map(user => {
        if (user.Roles.length > 0) {
            user.Roles = user.Roles[0].name;
        } else {
            user.Roles = '';
        }
        return user;
    });

    return {
        type: GET_SIREN_USERS,
        users: users,
    };
};

/**
 * action creator function to get users for admin users
 * @param users: users from api response data
 * @returns {{type: string, users}}
 */
export const getUsersForAdmin = users => {
    users.rows = users.rows.map(user => {
        user.Roles = user.Roles[0].name;
        return user;
    });

    return {
        type: GET_USERS_FOR_ADMIN,
        users: users,
    };
};

/**
 * action creator function to get users without params
 * @param users: users from api response data
 * @returns {{type: string, users}}
 */
export const getSirenUsersWithoutParams = users => {
    return {
        type: GET_SIREN_USERS_WITHOUT_PARAMS,
        users: users,
    };
};

/**
 * action creator function to add user
 * @param user: user from api response data
 * @returns {{type: string, user}}
 */
export const addSirenUser = user => {
    return {
        type: ADD_SIREN_USER,
        user: user,
    };
};

/**
 * action creator function to get user by id
 * @param user: user from api response data
 * @returns {{type: string, user}}
 */
export const getUserById = user => {
    return {
        type: GET_USER_BY_ID,
        user: user,
    };
};

/**
 * action creator function to update user
 * @param user: user from api response data
 * @returns {{type: string, user}}
 */
export const updateUser = user => {
    return {
        type: UPDATE_USER,
        user: user,
    };
};

/**
 * action creator function to get roles
 * @param roles: roles from api response data
 * @returns {{roles, type: string}}
 */
export const getRoles = roles => {
    return {
        type: GET_ROLES,
        roles: roles,
    };
};

/**
 * action creator function to toggle user activation
 * @param user: user from api response data
 * @returns {{type: string, user}}
 */
export const toggleActivation = user => {
    return {
        type: TOGGLE_USER_ACTIVATION,
        user: user,
    };
};

/**
 * action creator function to get user departments
 * @param depts: depts from api response data
 * @returns {{type: string, depts}}
 */
export const getUserDepts = depts => {
    return {
        type: GET_USER_DEPTS,
        depts: depts,
    };
};

/**
 * action creator function to set department
 * @param selectedDept: selectedDept from api response data
 * @returns {{selectedDept, type: string}}
 */
export const setSelectedDept = selectedDept => {
    return {
        type: SET_SELECTED_DEPT,
        selectedDept: selectedDept,
    };
};

/**
 * action creator function to get states
 * @param states: states from api response data
 * @returns {{type: string, states}}
 */
export const getStates = states => {
    return {
        type: GET_STATES,
        states: states,
    };
};

/**
 * action creator function to get timezones
 * @param timezones: timezones from api response data
 * @returns {{timezones, type: string}}
 */
export const getTimezones = timezones => {
    return {
        type: GET_TIMEZONES,
        timezones: timezones,
    };
};

/**
 * action creator function to get assigned organizations
 * @param orgs: orgs from api response data
 * @returns {{orgs, type: string}}
 */
export const getAssignedOrgs = orgs => {
    return {
        type: GET_ASSIGNED_ORGS,
        orgs: orgs,
    };
};

/**
 * action creator function to toggle email update setting
 * @param emailUpdate: emailUpdate from api response data
 * @returns {{emailUpdate, type: string}}
 */
export const toggleEmailUpdate = emailUpdate => {
    return {
        type: TOGGLE_EMAIL_UPDATE,
        emailUpdate: emailUpdate,
    };
};

/**
 * action creator function to refresh token
 * @param token: token from api response data
 * @returns {{type: string, token}}
 */
export const refreshToken = token => {
    return {
        type: REFRESH_TOKEN,
        token: token,
    };
};
