import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        container: {
            paddingBottom: '4rem',
        },
        actionsContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0.6rem',
            paddingTop: '0.8rem',
            position: 'sticky',
            top: '78px',
            zIndex: '1000',
            paddingRight: '1.3rem',
        },
        profileContainer: {
            display: 'flex',
        },
        profileImage: {
            height: '80px',
            width: '80px',
            borderRadius: '100%',
            border: '2px solid #ddd',
            padding: '5px',
        },
        critical: {
            height: '80px',
            width: '80px',
            borderRadius: '100%',
            border: '2px solid red',
            padding: '5px',
        },
        profileInfoContainer: {
            marginLeft: '30px',
            width: '100%',
        },
        caseInfoContainer: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '8px',
            justifyContent: 'space-between',
        },
        name: {
            fontSize: '21px',
        },
        criticalName: {
            color: 'red',
        },
        caseNumberConatiner: {
            marginLeft: '3.1rem',
        },
        caseNumber: {
            fontSize: '21px',
            color: '#9b9b9b',
        },
        editBoxContainer: {
            marginTop: '1rem',
            marginBottom: '15px',
            width: '100%',
        },
        editBoxButtonContainer: {
            display: 'flex',
            marginTop: '8px',
            justifyContent: 'flex-end',
        },
        editBoxSaveButton: {
            marginLeft: '0.5rem',
        },
        descContainer: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '0.7rem',
        },
        descLabel: {
            color: '#727272',
            fontSize: '1rem',
            fontweight: 'bold',
        },
        descText: {
            fontSize: '15px',
            marginTop: '-0.5rem',
        },
        descEditButton: {
            marginTop: '15px',
        },
        createdByContainer: {
            margin: '0.5rem 0rem',
        },
        createdByText: {
            fontSize: '12px',
            fontStyle: 'italic',
            color: '#6f6f6f',
        },
        optionsContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        optionAvatar: {
            marginRight: '12px',
        },
        optionText: {
            marginRight: '5px',
        },
        topButtonContainer: {
            position: 'absolute',
            top: '1rem',
            left: '1rem',
            right: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
        },
        backButton: {
            textTransform: 'capitalize',
            marginLeft: '6px',
        },
        buttonsInnerContainer: {
            display: 'flex',
            marginRight: '-5px',
        },
        selectContainer: {
            width: '150px',
        },
        criticalContainer: {
            marginRight: '0px',
        },
        topDivider: {
            position: 'absolute',
            top: '4rem',
            width: '100%',
        },
        timelineDivider: {
            marginTop: '15px',
            marginBottom: '20px',
        },
        sortButtonsContainer: {
            position: 'absolute',
            right: '1rem',
            top: '0px',
        },
        sortButton: {
            width: '70px',
        },
        addNoteHeadingContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '1.2rem',
            marginLeft: '1rem',
            marginBottom: '0.5rem',
        },
        addNoteContainer: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '-5px',
        },
        addNoteLabel: {
            fontSize: '1.2rem',
            fontWeight: '400',
            marginLeft: '0.2rem',
        },
        toggleButtonsContainer: {
            marginRight: '1rem',
        },
        caregiversListContainer: {
            marginTop: '1.5rem',
        },
        input: {
            paddingTop: '10px!important',
            paddingBottom: '10px!important',
        },
        hcLabel: {
            fontSize: '12px',
            marginBottom: '0.5rem',
            color: '#727272',
        },
        addNoteInfo: {
            fontWeight: 'bold',
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.6)',
        },
        buttonLebelContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        noContentContainer: {
            textAlign: 'center',
            marginTop: '3rem',
        },
        noLabel: {
            fontSize: '1.5rem',
            opacity: '0.2',
        },
        selected: {
            color: 'white !important',
            backgroundColor: `${theme.palette.primary.main} !important`,
        },
        text:{
            color: '#000'
        },
        formContainer:{
            width: '100%'
        },
        caregiverChip:{
            marginRight: '5px', 
            marginBottom: '5px'
        }
    };
});
