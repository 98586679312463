import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
} from '@material-ui/core';
import {useStyles} from './styles';

/**
 * function to render confirmation dialog
 * @param title: title of the dialog
 * @param message: message on the dialog
 * @param open: boolean to open the dialog
 * @param setOpen: function to set open state of the dialog
 * @param onConfirm: function to handle on confirm click
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmationDialog = ({title, message, open, setOpen, onConfirm}) => {
    const classes = useStyles();

    /**
     * function to close the dialog
     */
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={open}
            TransitionComponent={Slide}
            disableBackdropClick
        >
            <DialogTitle>{title}</DialogTitle>

            <DialogContent>
                <DialogContentText component={Box}>{message}</DialogContentText>
            </DialogContent>

            <DialogActions classes={{root: classes.actionsContainer}}>
                <Button variant='outlined' color='primary' onClick={handleClose}>
                    No
                </Button>
                <Button variant='contained' color='primary' onClick={onConfirm}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
