import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
} from '@material-ui/core';
import {useStyles} from './styles';

/**
 * function to render case close confirmation dialog
 * @param title: titile of the dialog 
 * @param message: message on the dialog
 * @param open: boolean to open the dialog
 * @param onCancel: function to close dialog
 * @param onConfirm: function to handle confirm click 
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmationDialog = ({title, message, open, onCancel, onConfirm}) => {
    const classes = useStyles();

    return (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={open}
            disableBackdropClick
            TransitionComponent={Slide}
        >
            <DialogTitle>{title}</DialogTitle>

            <DialogContent>
                <DialogContentText component={Box}>{message}</DialogContentText>
            </DialogContent>

            <DialogActions classes={{root: classes.actionsContainer}}>
                <Button variant='outlined' color='primary' onClick={onCancel}>
                    Cancel
                </Button>
                
                <Button variant='contained' color='primary' onClick={onConfirm}>
                    Mark Normal & Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
