import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {NavLink, useRouteMatch} from 'react-router-dom';
import {Box, Breadcrumbs, Button, Card, Grid, Paper, Typography, Icon} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {PhoneInputValidator} from '../../../../utilities/validators';
import UtilHelper from '../../../../utilities/validators/UtilHelper';
import {
  addAdminFromExistingUserRequest,
  addAdminRequest,
  getSignedUrlRequest,
  getUserByEmailRequest,
  uploadToS3Request,
} from '../../AdministratorsApiActions';
import {useStyles} from './styles';
import ConfirmationDialog from '../../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import ImageField from '../../../../utilities/components/ImageField/ImageField';
import {getIsTeamList, getUserInfo} from '../../../../utilities/storage';
import defaultUser from '../../../../assets/user.png';
import {dataURLtoFile} from '../../../../utilities/utilityFunctions';
import {APP_URLS, ROLES} from '../../../../utilities/constants';


/**
 * function to render admin form
 * @param location: location from router
 * @param history: history from router
 * @param getUserByEmail: function to get user by email
 * @param addAdmin: function to add admin
 * @param addAdminFromExistingUser: function to add admin from existing user
 * @param getSignedUrl: function to get signed url
 * @param uploadToS3: function to upload file on signed url
 * @returns {JSX.Element}
 * @constructor
 */
const AdminFormForDeptAdmin = ({
                                   location,
                                   history,
                                   getUserByEmail,
                                   addAdmin,
                                   addAdminFromExistingUser,
                                   getSignedUrl,
                                   uploadToS3,
                               }) => {
    const classes = useStyles();
    const match = useRouteMatch();
    const role = getUserInfo().Roles[0].name;
    const isTeamList = getIsTeamList() ? getIsTeamList() === 'true' ? true : false : ''; 

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [userid, setUserid] = useState('');
    const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);
    const [rowImageFile, setRowImageFile] = useState(null);
    const [cropper, setCropper] = useState(null);
    const [croppedImageFile, setCroppedImageFile] = useState(null);
    const [showImageEditor, setShowImageEditor] = useState(false);
    const currentUrlPath = match.path.split(':')[0];

    useEffect(() => {
        ValidatorForm.addValidationRule('isBlank', value =>
            UtilHelper.validateBlankSpace(value)
        );
        ValidatorForm.addValidationRule('isValidMobile', value =>
            UtilHelper.validateMobile(value)
        );
        ValidatorForm.addValidationRule('isMaxLength', value =>
            UtilHelper.validateMaxLength(value)
        );

        return () => {
            ValidatorForm.removeValidationRule('isBlank');
            ValidatorForm.removeValidationRule('isValidMobile');
            ValidatorForm.removeValidationRule('isMaxLength');
        };
    }, []);

    const breadCrumbDataForAddOrgDeptAdminForm = [
        {
            icon: 'business',
            name: 'Organizations',
            path: APP_URLS.ORGANIZATIONS,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `${APP_URLS.ORGANIZATIONS}/${location.state && location.state.orgIdHistory}`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `${APP_URLS.ORGANIZATIONS_DEPTS}/${
                location.state && location.state.deptIdHistory
            }`,
        },
        {
            name: `Add Administrator`,
            path: APP_URLS.ORGANIZATIONS_DEPTS_ADD_ADMIN,
        },
    ];

    const breadCrumbDataForAddHcorgOrgDeptAdminForm = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: APP_URLS.HEALTHCARE_ORGANIZATIONS,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `${APP_URLS.HEALTHCARE_ORGANIZATIONS}/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `${APP_URLS.HEALTHCARE_ORGANIZATIONS_ORGS}/${
                location.state && location.state.orgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `${APP_URLS.HEALTHCARE_ORGANIZATIONS_ORGS_DEPTS}/${
                location.state && location.state.deptIdHistory
            }`,
        },
        {
            name: `Add Administrator`,
            path: APP_URLS.HEALTHCARE_ORGANIZATIONS_ORGS_DEPTS_ADD_ADMIN,
        },
    ];

    /**
     * function to get breadcrumb data for current url
     * @returns {[{path: string, name: string}, {path: string, name: string}, {path: string, name: string}, {path: string, name: string}, {path: string, name: string}]|*[]|[{path: string, name: string}, {path: string, name: string}, {path: string, name: string}, {path: string, name: string}]}
     */

    const getBreadcrumbData = (urlPath) => {
        switch(urlPath){
            case APP_URLS.ORGANIZATIONS_DEPTS_ADD_ADMIN:
                return breadCrumbDataForAddOrgDeptAdminForm
            
            case APP_URLS.HEALTHCARE_ORGANIZATIONS_ORGS_DEPTS_ADD_ADMIN:
               return breadCrumbDataForAddHcorgOrgDeptAdminForm
            
            default: 
                return []
            
        }
    }

    /**
     * function to handle search by email upon email input
     */
    const handleSearchUserByEmail = () => {
        if (email) {
            getUserByEmail(email).then(
                res => {
                    setUserid(res.id);
                    setCroppedImageFile(res.imageUrl ? res.imageUrl : defaultUser);
                    setFirstname(res.firstName);
                    setLastname(res.lastName);
                    setPhone(res.phone);
                    setIsDisabled(true);
                },
                () => {
                        setCroppedImageFile(null);
                        setFirstname('');
                        setLastname('');
                        setPhone('');
                        setIsDisabled(false);
                }
            );
        } else {
            setCroppedImageFile(null);
            setFirstname('');
            setLastname('');
            setPhone('');
            setIsDisabled(false);
        }
    };

    /**
     * function to handle image crop
     */
    const onImageCrop = () => {
        const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL();
        const croppedImageFileObject = dataURLtoFile(
            croppedImageDataUrl,
            rowImageFile[0].file.name
        );

        const data = {
            dataURL: croppedImageDataUrl,
            file: croppedImageFileObject,
        };

        setCroppedImageFile(data);
        setShowImageEditor(false);
    };

    /**
     * function to add admin on button click
     * @param e: event from button click
     */
    const onAdd = e => {
        e.preventDefault();

        const data = {
            departmentId: location.state.deptIdHistory,
            user: {
                email: email,
                phone: phone,
                firstName: firstname,
                lastName: lastname,
                roles: [ROLES.LIMITED_USER],
            },
        };

        if (croppedImageFile) {
            const imageData = {
                fileName: croppedImageFile.file.name,
                fileType: `.${croppedImageFile.file.name.split('.').pop()}`,
            };

            getSignedUrl(imageData).then(res => {
                uploadToS3(res.urls, croppedImageFile.file).then(() => {
                    data['imageUrl'] = res.path;

                    addAdmin(data).then(() => {
                        history.goBack();
                    });
                });
            });
        } else {
            addAdmin(data).then(() => {
                history.goBack();
            });
        }
    };

    /**
     * function to add existing user as admin
     * @param e: event from button click
     */
    const onUserAdd = e => {
        e.preventDefault();

        const data = {
            departmentId: location.state.deptIdHistory,
            headDoctor: true,
            userId: userid,
        };

        addAdminFromExistingUser(data).then(() => {
            history.goBack();
        });
    };

    /**
     * function to handle cancel add
     */
    const handleCancel = () => {
        history.goBack();
    };

    return (
        <Box
            className={
                role === ROLES.SIRENMD_ADMIN &&
                !isTeamList
                    ? ''
                    : classes.outerContainer
            }
        >
            <Box
                className={
                    role === ROLES.SIRENMD_ADMIN &&
                    !isTeamList
                        ? classes.breadcrumbsContainer
                        : !isTeamList
                        ? classes.breadcrumbsContainerForAdmins
                        : classes.breadcrumbsContainerForUsers
                }
                component={Paper}
                p={1}
            >
                <Breadcrumbs>
                    {getBreadcrumbData(currentUrlPath).map((crumb, index) => (
                        <Box
                            key={index}
                            component={NavLink}
                            exact
                            to={{
                                pathname: crumb.path,
                                state: {
                                    orgIdHistory: location.state && location.state.orgIdHistory,
                                    orgNameHistory:
                                        location.state && location.state.orgNameHistory,
                                    deptIdHistory: location.state && location.state.deptIdHistory,
                                    deptNameHistory:
                                        location.state && location.state.deptNameHistory,
                                    hcorgIdHistory:
                                        location.state && location.state.hcorgIdHistory,
                                    hcorgNameHistory:
                                        location.state && location.state.hcorgNameHistory,
                                },
                            }}
                            className={classes.breadcrumbItem}
                            activeClassName={classes.activeBreadcrumbItem}
                        >
                            {crumb.icon && (
                                <Icon className={classes.breadcrumpIcon}>{crumb.icon}</Icon>
                            )}
                            <Box className={classes.breadcrumpLabel}>{crumb.name}</Box>
                        </Box>
                    ))}
                </Breadcrumbs>
            </Box>

            <Box mt={2}>
                <ValidatorForm onSubmit={userid ? onUserAdd : onAdd}>
                    <Box className={classes.container}>
                        <Box className={classes.labelContainer}>
                            <Typography classes={{root: classes.label}}>
                                Create Administrator
                            </Typography>
                        </Box>
                    </Box>

                    <Box component={Paper} className={classes.formContainer}>
                        <Grid container spacing={2} justify='space-around'>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                {isDisabled ? (
                                    <Box>
                                        <img
                                            src={croppedImageFile}
                                            alt='profile'
                                            className={`${classes.circleContainer} ${classes.circleImage}`}
                                        />
                                    </Box>
                                ) : (
                                    <Box>
                                        <ImageField
                                            rowImageFile={rowImageFile}
                                            setRowImageFile={setRowImageFile}
                                            showImageEditor={showImageEditor}
                                            setShowImageEditor={setShowImageEditor}
                                            setCropper={setCropper}
                                            croppedImageFile={croppedImageFile}
                                            onImageCrop={onImageCrop}
                                            options={{
                                                shape: 'circle',
                                            }}
                                        />
                                    </Box>
                                )}
                            </Grid>

                            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='Email *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            onBlur={handleSearchUserByEmail}
                                            validators={['required', 'isEmail']}
                                            errorMessages={[
                                                'Email is required',
                                                'Please enter valid email',
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <PhoneInputValidator
                                            disabled={isDisabled}
                                            autoFormat={false}
                                            variant='outlined'
                                            size='small'
                                            label='Phone *'
                                            fullWidth
                                            defaultCountry='us'
                                            countryCodeEditable={false}
                                            value={phone}
                                            onChange={(phone) => {
                                                let phoneNumber = '+' + phone.replace(/[^0-9]+/g, '');
                                                setPhone(phoneNumber);
                                            }}
                                            validators={['required', 'isValidMobile']}
                                            errorMessages={[
                                                'Phone is required',
                                                'Please enter valid phone',
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            disabled={isDisabled}
                                            label='First Name *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={firstname}
                                            onChange={e => setFirstname(e.target.value)}
                                            validators={['required', 'isBlank', 'isMaxLength']}
                                            errorMessages={[
                                                'First name is required',
                                                'Please enter first name',
                                                'Maximum 100 letters are accepted.',
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            disabled={isDisabled}
                                            label='Last Name *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={lastname}
                                            onChange={e => setLastname(e.target.value)}
                                            validators={['required', 'isBlank', 'isMaxLength']}
                                            errorMessages={[
                                                'Last name is required',
                                                'Please enter last name',
                                                'Maximum 100 letters are accepted.',
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className={classes.formBottomContainer}>
                        <Box component={Card} raised className={classes.buttonsContainer}>
                            <Box>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    disableElevation={true}
                                    onClick={() => setOpenCancelConfirmation(true)}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    variant='contained'
                                    color='primary'
                                    disableElevation={true}
                                    type='submit'
                                    classes={{root: classes.saveButton}}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </ValidatorForm>
            </Box>

            <Box>
                <ConfirmationDialog
                    open={openCancelConfirmation}
                    setOpen={setOpenCancelConfirmation}
                    title='Cancel'
                    message='Are you sure you want to cancel?'
                    onConfirm={handleCancel}
                />
            </Box>
        </Box>
    );
};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        addAdmin: data => {
            return dispatch(addAdminRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        addAdminFromExistingUser: data => {
            return dispatch(addAdminFromExistingUserRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getSignedUrl: data => {
            return dispatch(getSignedUrlRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        uploadToS3: (url, file) => {
            return dispatch(uploadToS3Request(url, file)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getUserByEmail: email => {
            return dispatch(getUserByEmailRequest(email)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminFormForDeptAdmin);
