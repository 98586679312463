import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {AutocompleteValidator} from '../../../../../utilities/validators';
import {getDepartment, getUserInfo,} from '../../../../../utilities/storage';
import {getUserDeptsRequest} from '../../../../Users/UsersApiActions';
import {ROLES} from '../../../../../utilities/constants'
import {useStyles} from './styles';

/**
 * function to render choose department dialog
 * @param open: boolean to open choose department dialog
 * @param dept: department 
 * @param setDept: function to set department
 * @param getUserDepts: function to set selected department
 * @param depts: deptartments list
 * @param selectedDept: selected deptartment
 * @param onSubmit: function to handle submit
 * @param onCancel: function to handle cancel
 * @returns {JSX.Element}
 * @constructor
 */
const DepartmentsDialog = ({
                               open,
                               dept,
                               setDept,
                               getUserDepts,
                               depts,
                               selectedDept,
                               onSubmit,
                               onCancel,
                           }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;

    useEffect(() => {
        if (open) {
            getUserDepts(getUserInfo().id).then(res => {
                if (!getDepartment()) {
                    setDept(res.teams[0]);
                }
            });
        }
    }, [getUserDepts, open, setDept]);
    return (
        <Dialog fullWidth open={open} disableBackdropClick>
            <ValidatorForm onSubmit={onSubmit}>
                <DialogTitle>
                    Choose{' '}
                    {getUserInfo() && role === ROLES.COACH
                        ? 'Team'
                        : 'Department OR Location'}
                </DialogTitle>

                <Divider/>
                <DialogContent>
                    <Box className={classes.deptSelectContainer}>
                        <AutocompleteValidator
                            disableClearable
                            fullWidth
                            size='small'
                            options={depts ? depts : []}
                            getOptionSelected={(option, value) =>
                                option.team_id === value.team_id
                            }
                            getOptionLabel={option => option.team_name}
                            renderInput={params => (
                                <TextValidator
                                    {...params}
                                    label={
                                        getUserInfo() && role === ROLES.COACH
                                            ? 'Team'
                                            : 'Department or Location'
                                    }
                                    variant='outlined'
                                />
                            )}
                            renderOption={option => (
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar src={option.team_imageUrl}/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={option.Organization_name}
                                            secondary={
                                                role !== ROLES.COACH
                                                    ? `${option.team_name} (${option.notifications})`
                                                    : `${option.team_name}`
                                            }
                                        />
                                    </ListItem>
                                </List>
                            )}
                            value={dept}
                            onChange={(e, value) => setDept(value)}
                            validators={['required']}
                            errorMessages={['Department is required']}
                        />
                    </Box>
                </DialogContent>
                <DialogActions className={classes.actionsContainer}>
                    {selectedDept && (
                        <Button
                            variant='outlined'
                            color='primary'
                            disableElevation
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                    )}

                    <Button
                        variant='contained'
                        type='submit'
                        color='primary'
                        disableElevation
                    >
                        Save
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
};

const mapStateToProps = state => {
    return {
        depts: state.sirenUsers.depts.teams,
        selectedDept: state.sirenUsers.selectedDept,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUserDepts: id => {
            return dispatch(getUserDeptsRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentsDialog);
