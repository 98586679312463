import React from 'react';
import {connect} from 'react-redux';
import {Snackbar, ThemeProvider} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import AppRoutes from './routes/AppRoutes';
import theme from './theme';
import AppLoader from './utilities/components/AppLoader/AppLoader';
import {clearSnackbar} from './utilities/UtilitiesActions';
import 'tippy.js/dist/tippy.css';
import './App.css';

/**
 * function to render app
 * @param clearSnackbar: function to clear snackbar 
 * @param showSnackbar: boolean to open snackbar
 * @param snackbarVariant: snackbar variant
 * @param snackbarMessage: snackbar message
 * @returns {JSX.Element}
 * @constructor
 */
const App = ({
                 clearSnackbar,
                 showSnackbar,
                 snackbarVariant,
                 snackbarMessage,
             }) => {

    /**
     * function to close shackbar
     */            
    const handleCloseSnackbar = () => {
        clearSnackbar();
    };

    return (
        <ThemeProvider theme={theme}>
            <AppLoader/>
            <Snackbar
                open={showSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                style={{zIndex: '10000000'}}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarVariant}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <AppRoutes/>
        </ThemeProvider>
    );
};

const mapStateToProps = state => {
    return {
        showSnackbar: state.utilities.showSnackbar,
        snackbarVariant: state.utilities.snackbarVariant,
        snackbarMessage: state.utilities.snackbarMessage,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        clearSnackbar: () => {
            dispatch(clearSnackbar());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
