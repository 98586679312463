import React, {useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {Box, Button, Container, CssBaseline, Grid, Typography,} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {forgetPasswordRequest} from '../../AuthApiActions';
import logo from '../../../../assets/logo.png';
import {getAdmin, getIsTeamList, getUserInfo, isLoggedIn,} from '../../../../utilities/storage';
import './ForgetPassword.css';
import MessageDialog from '../../../../utilities/components/ForgotPasswordMessageDialog/ForgotPasswordMessageDialog';
import {ROLES, APP_URLS} from '../../../../utilities/constants'

/**
 * function to render forgot password form
 * @param history: history from router
 * @param forgetPassword: function to handle forgot password
 * @returns {JSX.Element}
 * @constructor
 */
const ForgotPassword = ({history, forgetPassword}) => {
    const role = getUserInfo() ? getUserInfo().Roles[0].name : '';
    const isTeamList = getIsTeamList() ? getIsTeamList() === 'true' ? true : false : '';  
    const [email, setEmail] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');

    /**
     * function to handle forgot password on button click
     * @param e: event from button click
     */
    const onSubmit = e => {
        e.preventDefault();

        const data = {
            email: email,
        };

        forgetPassword(data).then(res => {
            setMessage(res.message);
            setShowMessage(true);
        });
    };

    /**
     * function to handle ok click on confirmation dialog
     */
    const onOk = () => {
        history.push(APP_URLS.LOGIN);
    };

    if (isLoggedIn()) {
        if (role === ROLES.SIRENMD_ADMIN) {
            return <Redirect to={APP_URLS.ORGANIZATIONS} />;
        } else if (role === ROLES.COACH) {
            return <Redirect to={APP_URLS.PLAY_STATUS_TEAM_ROSTER} />;
        } else if (!isTeamList && getAdmin().isOrgAdmin === true) {
            return <Redirect to={APP_URLS.ORGANIZATIONS} />;
        } else if (!isTeamList && getAdmin().isDeptAdmin === true) {
            return <Redirect to={APP_URLS.DEPARTMENTS} />;
        } else if (!isTeamList && getAdmin().isBothAdmin === true) {
            return <Redirect to={APP_URLS.ORGANIZATIONS} />;
        } else if (isTeamList) {
            return <Redirect to={APP_URLS.NEW_UPDATES} />;
        }
    }

    return (
        <>
            <MessageDialog
                open={showMessage}
                title='Note'
                message={message}
                onOk={onOk}
            />

            <Container component='main' maxWidth='xs'>
                <CssBaseline/>

                <Box className='forget-password-form-container'>
                    <img src={logo} alt='logo'/>
                    <Typography
                        component='h1'
                        variant='h5'
                        style={{marginTop: '1.5rem'}}
                    >
                        Forgot Password
                    </Typography>

                    <ValidatorForm
                        noValidate
                        className='forget-password-form'
                        onSubmit={onSubmit}
                    >
                        <TextValidator
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            label='Email'
                            validators={['required', 'isEmail']}
                            errorMessages={['Email is required', 'Email is not valid']}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />

                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className='forget-password-button'
                        >
                            Send Password
                        </Button>

                        <Grid container>
                            <Grid item xs>
                                <Typography
                                    color='primary'
                                    component={Link}
                                    to='/'
                                    className='forget-password-sign-in'
                                >
                                    Sign In?
                                </Typography>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </Box>
            </Container>
        </>
    );
};

const mapStateToProps = state => {
    return {
        loggedInUser: state.auth.loggedInUser,
    };
};

const mapDispatchToProp = dispatch => {
    return {
        forgetPassword: data => {
            return dispatch(forgetPasswordRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProp)(ForgotPassword);
