import {isValidPhoneNumber} from 'react-phone-number-input';

/**
 * class to provide utility functions
 */
class UtilHelper {
    static validateBlankSpace = value => {
        if (!Array.isArray(value)) {
            return !(value && value.trim().length === 0);
        } else {
            return true;
        }
    };

    static matchPassword = (value, password) => {
        if (value !== password) {
            return false;
        }
        return true;
    };

    static validatePassword = value => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;

        if (value && !regex.test(value)) {
            return false;
        } else {
            return true;
        }
    };

    static validateMobile = (value) => {        
        if (isValidPhoneNumber(value)) {
            return true;
        }else if(value === '') {
            return true;
        } else {
            return false;
        }
    };

    static validateNpi = value => {
        const regex = /^\d{10}$/;

        if (value && !regex.test(value)) {
            return false;
        } else {
            return true;
        }
    };

    static validateZpiCode = value => {
        const regex = /^[A-Za-z]{1,2}[0-9]{1,2}[0-9A-Za-z ]*$|^\d{5,6}$|^\d{5}-\d{4}$/;

        if (value && !regex.test(value)) {
            return false;
        } else {
            return true;
        }
    };

    static validateMaxLength = value => {
        const regex = /^.{0,100}$/;

        if (value && !regex.test(value)) {
            return false;
        } else {
            return true;
        }
    };

    static validateSocialSecurityNumber = value => {
        const valueWithoutMask = value.replace(/-|_/g, '');
        if (valueWithoutMask.length === 0) {
            return true;
        }
        if (valueWithoutMask.length === 9) {
            return true;
        } else {
            return false;
        }
    };

    static vaidateExelFile = files => {
        if (files.length === 0) {
            return true;
        } else {
            if (files[0].name.split('.').pop() === 'xlsx') {
                return true;
            } else {
                return false;
            }
        }
    };
}

export default UtilHelper;
