import HttpRequest from '../../utilities/httpRequest';
import {hideLoader, showLoader, showSnackbar,} from '../../utilities/UtilitiesActions';
import {
    addCase,
    addLogItem,
    findDicomImage,
    getCaregiversOfDept,
    getCaseById,
    getCasePriorities,
    getCases,
    getCaseStatus,
    getCaseStatusForDoctor,
    getClosedCases,
    getDicomUrl,
    getLogItemReadStatus,
    getLogItemsByCaseId,
    getOpenCases,
    readLogitem,
    updateCase,
    updateCaseCaregivers,
} from './CasesActions';

/**
 * function to get cases
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getCasesRequest = id => {
    const data = {
        teamId: id,
        status: 'ALL',
        patientId: '',
    };
    return dispatch => {
        dispatch(showLoader());
        const url = `case?searchData=${JSON.stringify(data)}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getCases(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get open cases
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getOpenCasesRequest = id => {
    const data = {
        teamId: id,
        status: 'OPEN',
        patientId: '',
    };
    return dispatch => {
        dispatch(showLoader());
        const url = `case?searchData=${JSON.stringify(data)}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getOpenCases(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get closed cases
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getClosedCasesRequest = id => {
    const data = {
        teamId: id,
        status: 'CLOSED',
        patientId: '',
    };
    return dispatch => {
        dispatch(showLoader());
        const url = `case?searchData=${JSON.stringify(data)}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getClosedCases(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get case by id
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getCaseByIdRequest = id => {
    return dispatch => {
        dispatch(showLoader());
        const url = `case/${id}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getCaseById(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to add case
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const addCaseRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = 'case';

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Case created successfully', 'success'));

                dispatch(addCase(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to update case
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const updateCaseRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `case/${id}`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Case updated successfully', 'success'));
                dispatch(updateCase(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to update case caregivers
 * @param caseId: caseId for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const updateCaseCaregiversRequest = (caseId, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `addCaregiverToCase/${caseId}`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Caregiver list updated.', 'success'));

                dispatch(updateCaseCaregivers(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get case priorities
 * @returns {function(*=): Promise<unknown>}
 */
export const getCasePrioritiesRequest = () => {
    return dispatch => {
        dispatch(showLoader());
        const url = `casePriority`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());

                dispatch(getCasePriorities(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get case status
 * @returns {function(*=): Promise<unknown>}
 */
export const getCaseStatusRequest = () => {
    return dispatch => {
        dispatch(showLoader());
        const url = `caseStatus`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getCaseStatus(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get case status for healthcare provider organizations
 * @returns {function(*=): Promise<unknown>}
 */
export const getCaseStatusForDoctorRequest = () => {
    return dispatch => {
        dispatch(showLoader());
        const url = `docCaseStatus`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getCaseStatusForDoctor(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get caregivers of departments
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getCaregiversOfDeptRequest = (id) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `department/${id}/caregiver`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getCaregiversOfDept(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get log items by case id
 * @param id: id for api request
 * @param type: type for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getLogItemsByCaseIdRequest = (id, type) => {
    return dispatch => {
        dispatch(showLoader());
        const searchType = {
            type: type,
        };
        const url = `case/${id}/logitem?searchData=${JSON.stringify(searchType)}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getLogItemsByCaseId(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to add log item
 * @param caseId: caseId for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const addLogItemRequest = (caseId, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `logitem/${caseId}`;

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(addLogItem(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                if(error.response.data.error){
                    dispatch(showSnackbar(error.response.data.error, 'error'));
                }
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get log item read status
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getLogItemReadStatusRequest = id => {
    return dispatch => {
        dispatch(showLoader());
        const url = `logitem/${id}/readStatus`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getLogItemReadStatus(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to read log item
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const readLogItemRequest = data => {
    return dispatch => {
        dispatch(showLoader());

        const url = `readLogs`;

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(readLogitem(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get dicom url
 * @returns {function(*=): Promise<unknown>}
 */
export const getDicomUrlRequest = () => {
    return dispatch => {
        dispatch(showLoader());

        const url = `getDicomeUrl`;

        return new HttpRequest(dispatch, url, 'post').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getDicomUrl(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to find dicom image
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const findDicomImageRequest = data => {
    return dispatch => {
        dispatch(showLoader());

        const url = `findDicomUrl`;

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(findDicomImage(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};


/**
 * function to get signed url
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getSignedUrlRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = `athletes/profilePic/signedURL`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};

/**
 * function to upload file to signed url
 * @param url: url for api request
 * @param file: file for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const uploadToS3Request = (url, file) => {
    return dispatch => {
        dispatch(showLoader());
        return new HttpRequest(dispatch, url, 'put', file)
            .s3Upload()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};
