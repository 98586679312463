import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        container: {
            marginBottom: '4rem',
        },
        titleContainer: {
            marginTop: '1rem',
            marginLeft: '1rem',
            marginBottom: '-0.5rem',
        },
        title: {
            fontSize: '1.6rem',
        },
        optionsContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        optionAvatar: {
            marginRight: '12px',
        },
        optionAvatarImage: {
            height: '35px',
            width: '35px',
            borderRadius: '100%',
            marginTop: '5px',
            border: '1px solid rgba(0, 0, 0, 0.2)',
        },
        optionNameContainer: {
            marginRight: '5px',
        },
        addNoteHeadingContainer: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '1.2rem',
            marginLeft: '1rem',
            marginBottom: '0.5rem',
        },
        addNoteLabel: {
            fontSize: '1.2rem',
            fontWeight: '400',
        },
        formBottomContainer: {
            zIndex: '1000',
            position: 'fixed',
            bottom: '0',
            width: '100%',
            left: '0',
        },
        buttonsContainer: {
            height: '60px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingRight: '1.5rem',
        },
        savebutton: {
            marginLeft: '1rem',
        },
        addOption: {
            width: '100%',
            textAlign: 'center',
            color: '#0288d1',
        },
    };
});
