import HttpRequest from '../../utilities/httpRequest';
import {
    addAdmin,
    addAdminFromExistingUser,
    getAdminsForDept,
    getAdminsForOrg,
    getUserByEmail,
    removeAdminFromDept,
    removeAdminFromOrg,
} from './AdministratorsActions';
import {hideLoader, showLoader, showSnackbar,} from '../../utilities/UtilitiesActions';

/**
 * function to add admin
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const addAdminRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = 'department/admin';

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Admin Added', 'success'));

                dispatch(addAdmin(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(
                    showSnackbar(
                        'Admin with the given username or email or phone already exists',
                        'error'
                    )
                );
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to add admin from an existing user
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const addAdminFromExistingUserRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = 'department/adminAlreadyExist';

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('User added successfully', 'success'));
                dispatch(addAdminFromExistingUser(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar(`${error.response.data.error}`, 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get admins of a department
 * @param id: id for api request
 * @param data: data for api request
 * @param isHideLoader: isHideLoader for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getAdminsForDeptRequest = (id, data, isHideLoader) => {
    let offset = data.page * data.rowsPerPage;
    let limit = data.rowsPerPage;
    let searchData = data.searchData ? data.searchData : '';

    return dispatch => {
        if (!isHideLoader) {
            dispatch(showLoader());
        }

        const url = `department/${id}/admin?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
            searchData
        )}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                let resData = res.data.data;
                resData.rows = resData.rows.map(user => {
                    user.name = `${user.firstName} ${user.lastName}`;
                    return user;
                });

                dispatch(getAdminsForDept(resData));

                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.resolve(resData);
            },
            error => {
                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get admins of a organization
 * @param id: id for api request
 * @param data: data for api request
 * @param isHideLoader: isHideLoader for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getAdminsForOrgRequest = (id, data, isHideLoader) => {
    let offset = data.page * data.rowsPerPage;
    let limit = data.rowsPerPage;
    let searchData = data.searchData ? data.searchData : '';

    return dispatch => {
        if (!isHideLoader) {
            dispatch(showLoader());
        }

        const url = `department/org/${id}/admin?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
            searchData
        )}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                let resData = res.data.data;
                resData.rows = resData.rows.map(user => {
                    user.name = `${user.firstName} ${user.lastName}`;
                    return user;
                });

                dispatch(getAdminsForOrg(resData));

                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.resolve(resData);
            },
            error => {
                if (!isHideLoader) {
                    dispatch(hideLoader());
                }

                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to remove admin from organization
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const removeAdminFromOrgRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `org/removeAdminToOrg/${id}`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Admin removed successfully', 'success'));

                dispatch(removeAdminFromOrg(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Admin not removed, try again later', 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to remove admin from department
 * @param id: id for api request
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const removeAdminFromDeptRequest = (id, data) => {
    return dispatch => {
        dispatch(showLoader());
        const url = `department/removeAdminToDept/${id}`;

        return new HttpRequest(dispatch, url, 'patch', data).send().then(
            res => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Admin removed successfully', 'success'));

                dispatch(removeAdminFromDept(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                dispatch(showSnackbar('Admin not removed, try again later', 'error'));
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get user by email
 * @param email: email for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getUserByEmailRequest = email => {
    return dispatch => {
        dispatch(showLoader());
        const url = `users/${email}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getUserByEmail(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to get signed url
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getSignedUrlRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = `users/profilePic/signedURL`;
        return new HttpRequest(dispatch, url, 'post', data)
            .send()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};

/**
 * function to upload file on signed url
 * @param url: url for api request
 * @param file: file to be uploaded
 * @returns {function(*=): Promise<unknown>}
 */
export const uploadToS3Request = (url, file) => {
    return dispatch => {
        dispatch(showLoader());
        return new HttpRequest(dispatch, url, 'put', file)
            .s3Upload()
            .then(res => {
                dispatch(hideLoader());
                return Promise.resolve(res.data);
            })
            .catch(error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            });
    };
};
