import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Box, Dialog, DialogContent, DialogContentText, DialogTitle, Divider,} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {getLogItemReadStatusRequest, getLogItemsByCaseIdRequest,} from '../../../../CasesApiAction';
import {useStyles} from './styles';
import defaultUser from '../../../../../../assets/user.png';

/**
 * function to render read status dialog
 * @param open: boolean to open read status dialog
 * @param setOpen: function to set open state
 * @param createdBy: created by user
 * @param time: created time
 * @param noteId: note id
 * @param getLogItemReadStatus: function to get log item read status
 * @param readStatus: read status list
 * @param caseId: case id
 * @param getLogItemsByCaseId: function to get log items by case id
 * @param sortCategory: sort category
 * @returns {JSX.Element}
 * @constructor
 */
const ReadByLog = ({
                       open,
                       setOpen,
                       createdBy,
                       time,
                       noteId,
                       getLogItemReadStatus,
                       readStatus,
                       caseId,
                       getLogItemsByCaseId,
                       sortCategory,
                   }) => {
    const classes = useStyles();

    useEffect(() => {
        getLogItemReadStatus(noteId).then(res => {
            getLogItemsByCaseId(caseId, sortCategory).then(res => {
            });
        });
    }, [getLogItemReadStatus, getLogItemsByCaseId, sortCategory, caseId, noteId]);

    /**
     * function to close read status dialog 
     */
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Dialog fullWidth open={open} disableBackdropClick>
            <DialogTitle>
                <Box className={classes.titleContainer}>
                    <Box>Read Status</Box>
                    <Box className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon/>
                    </Box>
                </Box>
            </DialogTitle>

            <Divider/>

            <DialogContent>
                <Box className={classes.contentContainer}>
                    <Box className={classes.upperTextContainer}>
                        <DialogContentText>
                            Read Status of item Created By {createdBy} {time}
                        </DialogContentText>
                    </Box>

                    {readStatus &&
                    readStatus.map((item, index) => (
                        <Box key={index} className={classes.caregiverItemContainer}>
                            <Box>
                                <img
                                    src={item.imageUrl ? item.imageUrl : defaultUser}
                                    alt='profile'
                                    className={classes.profileImage}
                                />
                            </Box>
                            <Box>
                  <span className={classes.name}>
                    {item.firstName} {item.lastName}
                  </span>{' '}
                                <span className={classes.desc}>
                    <span
                        className={
                            item.readTime === 'Unread' ? classes.red : classes.green
                        }
                    >
                      {item.readTime}
                    </span>{' '}
                                    <span className={classes.red}>
                      {item.readStatus && `(${item.readStatus})`}
                    </span>
                  </span>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

const mapStateToProps = state => {
    return {
        readStatus: state.cases.readStatus,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getLogItemReadStatus: id => {
            return dispatch(getLogItemReadStatusRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getLogItemsByCaseId: (id, type) => {
            return dispatch(getLogItemsByCaseIdRequest(id, type)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReadByLog);
