import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        termsContainer: {
            display: 'flex',
        },
        termsText: {
            textTransform: 'uppercase',
            fontSize: '15px',
            textDecoration: 'none',
            color: '#505050',
        },
        contactContainer: {
            marginLeft: '1rem',
        },
        contactText: {
            textTransform: 'uppercase',
            fontSize: '15px',
            textDecoration: 'none',
            color: '#505050',
        },
        yearText: {
            fontSize: '15px',
        },
    };
});
