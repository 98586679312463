export const GET_ANALYTICS = 'GET_ANALYTICS';

/**
 * action creator function to get analytics data
 * @param analytics: analytics from api response data
 * @returns {{analytics, type: string}}
 */
export const getAnalytics = analytics => {
    return {
        type: GET_ANALYTICS,
        analytics: analytics,
    };
};
