import React from 'react'
import {Tooltip, Box, Icon} from '@material-ui/core';
import {useStyles} from './styles';

/**
 * function to render users list
 * @param enabled: boolean for enabled 
 * @param isBanned: boolean for isBanned
 * @param isLabel: boolean to show the label or not
 * @returns {JSX.Element}
 * @constructor
 */
const ActiveStatusIcon = ({enabled, isBanned, isLabel}) => {
    const classes = useStyles();
    if(isBanned){
        return(
            <Tooltip arrow placement='top' title='Blocked'>
                <Box>
                    <Icon className={`statusBlocked`}>block</Icon>
                    {
                        isLabel && <div className={classes.label}>Invalid Login Attempts</div>
                    }
                    
                </Box>
            </Tooltip>
        )
    }else if(!enabled){
        return(
            <Tooltip arrow placement='top' title='Blocked'>
                <Box>
                    <Icon className={`statusBlocked`}>block</Icon>
                    {
                        isLabel && <div className={classes.label}>Banned By Admin</div>
                    }
                    
                </Box>
            </Tooltip>
        )
    }else{
        return(
            <Tooltip arrow placement='top' title='Active'>
                 <Icon className={`statusActive`}>check_circle_outline</Icon>
            </Tooltip>
        )
    }
}

export default ActiveStatusIcon
