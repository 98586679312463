import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        container: {
            margin: '1.5rem',
        },
        userProfileContainer: {
            padding: '2rem',
        },
        userProfileContentContainer: {
            display: 'flex',
        },
        userProfileImageError: {
            borderRadius: '100%',
            height: '120px',
            objectFit: 'contain',
            width: '120px',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            textAlign: 'center',
        },
        userProfileImage: {
            borderRadius: '100%',
            height: '120px',
            width: '120px',
            border: '1px solid rgba(0, 0, 0, 0.2)',
        },
        userProfileTextContainer: {
            marginLeft: '2.5rem',
        },
        userProfileName: {
            fontSize: '1.7rem',
        },
        userProfileEmail: {
            fontSize: '0.9rem',
        },
        userProfilePhone: {
            fontSize: '0.9rem',
            marginTop: '0.2rem',
        },
        userProfileRoleContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        userProfileRoleIcon: {
            fontSize: '1.2rem',
            color: 'blue',
        },
        userProfileRole: {
            fontSize: '1rem',
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
            marginLeft: '0.3rem',
        },
        userProfileButton: {
            marginTop: '0.5rem',
            textTransform: 'capitalize',
            width: '200px',
            justifyContent: 'flex-start',
        },
    };
});
