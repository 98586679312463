export const GET_ATHLETES_WITHOUT_PARAMS = 'GET_ATHLETES_WITHOUT_PARAMS';
export const ADD_ATHLETE = 'ADD_ATHLETE';
export const GET_ATHLETE_BY_ID = 'GET_ATHLETE_BY_ID';
export const GET_CASES = 'GET_CASES';
export const GET_OPEN_CASES = 'GET_OPEN_CASES';
export const GET_CLOSED_CASES = 'GET_CLOSED_CASES';
export const TOGGLE_PLAYING_STATUS = 'TOGGLE_PLAYING_STATUS';
export const GET_INJURED_ATHLETES = 'GET_INJURED_ATHLETES';
export const GET_RECOVERING_ATHLETES = 'GET_RECOVERING_ATHLETES';
export const GET_HEALTHY_ATHLETES = 'GET_HEALTHY_ATHLETES';
export const GET_AILING_ATHLETES = 'GET_AILING_ATHLETES';
export const GET_CHRONIC_ATHLETES = 'GET_CHRONIC_ATHLETES';
export const SEND_ENJURY_REPORT = 'SEND_ENJURY_REPORT';
export const GET_BODY_LOCATIONS = 'GET_BODY_LOCATIONS';
export const UPDATE_INJURY_REPORT = 'UPDATE_INJURY_REPORT';

/**
 * action creator function to get athletes without params
 * @param athletes: athletes from api response data
 * @returns {{athletes, type: string}}
 */
export const getAthletesWithoutParams = athletes => {
    return {
        type: GET_ATHLETES_WITHOUT_PARAMS,
        athletes: athletes,
    };
};

/**
 * action creator function to add athlete
 * @param athlete: athlete from api response data
 * @returns {{athlete, type: string}}
 */
export const addAthlete = athlete => {
    return {
        type: ADD_ATHLETE,
        athlete: athlete,
    };
};

/**
 * action creator function to get athlete by id
 * @param athlete: athlete from api response data
 * @returns {{athlete, type: string}}
 */
export const getAthleteById = athlete => {
    return {
        type: GET_ATHLETE_BY_ID,
        athlete: athlete,
    };
};

/**
 * action creator function to get cases
 * @param cases: cases from api response data
 * @returns {{cases, type: string}}
 */
export const getCases = cases => {
    return {
        type: GET_CASES,
        cases: cases,
    };
};

/**
 * action creator function to get open cases
 * @param openCases: openCases from api response data
 * @returns {{openCases, type: string}}
 */
export const getOpenCases = openCases => {
    return {
        type: GET_OPEN_CASES,
        openCases: openCases,
    };
};

/**
 * action creator function to get closed cases
 * @param closedCases: closedCases from api response data
 * @returns {{closedCases, type: string}}
 */
export const getClosedCases = closedCases => {
    return {
        type: GET_CLOSED_CASES,
        closedCases: closedCases,
    };
};

/**
 * action creator function to toggle playing status
 * @param athlete: athlete from api response data
 * @returns {{athlete, type: string}}
 */
export const togglePlayingStatus = athlete => {
    return {
        type: TOGGLE_PLAYING_STATUS,
        athlete: athlete,
    };
};

/**
 * action creator function to get injured athletes 
 * @param injuredAthletes: injuredAthletes from api response data
 * @returns {{injuredAthletes, type: string}}
 */
export const getInjuredAthletes = injuredAthletes => {
    return {
        type: GET_INJURED_ATHLETES,
        injuredAthletes: injuredAthletes,
    };
};

/**
 * action creator function to get recovering athletes
 * @param recoveringAthletes: recoveringAthletes from api response data
 * @returns {{recoveringAthletes, type: string}}
 */
export const getRecoveringAthletes = recoveringAthletes => {
    return {
        type: GET_RECOVERING_ATHLETES,
        recoveringAthletes: recoveringAthletes,
    };
};

/**
 * action creator function to get healthy athletes
 * @param healthyAthletes: healthyAthletes from api response data
 * @returns {{healthyAthletes, type: string}}
 */
export const getHealthyAthletes = healthyAthletes => {
    return {
        type: GET_HEALTHY_ATHLETES,
        healthyAthletes: healthyAthletes,
    };
};

/**
 * action creator function to get ailing athletes
 * @param ailingAthletes: ailingAthletes from api response data
 * @returns {{ailingAthletes, type: string}}
 */
export const getAilingAthletes = ailingAthletes => {
    return {
        type: GET_AILING_ATHLETES,
        ailingAthletes: ailingAthletes,
    };
};

/**
 * action creator function to get chronic athletes
 * @param chronicAthletes: chronicAthletes from api response data
 * @returns {{chronicAthletes, type: string}}
 */
export const getChronicAthletes = chronicAthletes => {
    return {
        type: GET_CHRONIC_ATHLETES,
        chronicAthletes: chronicAthletes,
    };
};

/**
 * action creator function to send enjury report
 * @param report: report from api response data
 * @returns {{report, type: string}}
 */
export const sendEnjuryReport = report => {
    return {
        type: SEND_ENJURY_REPORT,
        report: report,
    };
};

/**
 * action creator function to get body locations
 * @param bodyLocations: bodyLocations from api response data
 * @returns {{type: string, bodyLocations}}
 */
export const getBodyLocations = bodyLocations => {
    return {
        type: GET_BODY_LOCATIONS,
        bodyLocations: bodyLocations,
    };
};

/**
 * action creator function to update injury report
 * @param injuryReport: injuryReport from api response data
 * @returns {{injuryReport, type: string}}
 */
export const updateInjuryReport = injuryReport => {
    return {
        type: GET_BODY_LOCATIONS,
        injuryReport: injuryReport,
    };
};

