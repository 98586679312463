import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        container: {
            marginTop: '0.5rem',
            marginLeft: '5px',
        },
        uploadContainer: {
            marginTop: '1rem',
            marginLeft: '5px',
        },
        text: {
            fontSize: '0.9rem',
        },
        textMain: {
            fontSize: '0.9rem',
            fontWeight: 'bold',
        },
        dropzoneContainer: {
            minHeight: '150px',
        },
        addButtonContainer: {
            marginTop: '10px',
            marginBottom: '10px',
            textAlign: 'right',
        },
        buttonsConatiner:{
            display: 'flex', 
            justifyContent: 'flex-end', 
            position: 'absolute', 
            right: '35px', 
            top: '8.2rem', 
            zIndex: '1'
        },
        addButton: {
            textTransform: 'capitalize',
        },
        uploadButton:{
            textTransform: 'capitalize',
            marginLeft: '10px'
        },
        removeIcon: {
            color: 'red',
        },
        actionIcon: {
            marginLeft: '5px',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    };
});
