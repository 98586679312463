import React from 'react';
import {ValidatorComponent} from 'react-material-ui-form-validator';
import {Box, RadioGroup, withStyles} from '@material-ui/core';
import {styles} from './styles';

/**
 * class to render radio group validator
 * @returns {JSX.Element}
 */
class RadioGroupValidator extends ValidatorComponent {
    renderValidatorComponent() {
        const {
            value,
            label,
            errorMessages,
            validators,
            validatorListener,
            ...rest
        } = this.props;

        return (
            <Box>
                <RadioGroup value={value} {...rest} />
                {this.errorText()}
            </Box>
        );
    }

    errorText() {
        const {isValid} = this.state;
        const {classes} = this.props;

        if (isValid) {
            return null;
        }

        return <Box className={classes.text}>{this.getErrorMessage()}</Box>;
    }
}

export default withStyles(styles)(RadioGroupValidator);
