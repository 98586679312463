import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Box, Button, Divider, Grid, Paper, Tab, Typography,} from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import AddIcon from '@material-ui/icons/Add';
import CaseItem from './components/CaseItem/CaseItem';
import {getUserInfo} from '../../../../utilities/storage';
import {getCasesRequest, getClosedCasesRequest, getOpenCasesRequest,} from '../../CasesApiAction';
import {ROLES} from '../../../../utilities/constants';
import {useStyles} from './styles';

/**
 * function to render case form
 * @param selectedDept: selected department
 * @param getCases: function to get cases
 * @param cases: cases list
 * @param getOpenCases: function to get open cases
 * @param openCases: open cases list
 * @param getClosedCases: function to get closed cases
 * @param closedCases: closed cases list
 * @returns {JSX.Element}
 * @constructor
 */
const Cases = ({
                   selectedDept,
                   getCases,
                   cases,
                   getOpenCases,
                   openCases,
                   getClosedCases,
                   closedCases,
               }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const [value, setValue] = React.useState('1');

    /**
     * function to handle tab change
     * @param e: event from button click
     * @param newValue: tab value
     */
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (selectedDept.headDoctor === true) {
            getCases(selectedDept.team_id).then(res => {
            });
        }
        getOpenCases(selectedDept.team_id).then(res => {
        });
        getClosedCases(selectedDept.team_id).then(res => {
        });
        //eslint-disable-next-line
    }, [getCases, getOpenCases, getClosedCases]);

    return (
        <Box className={classes.outerContainer}>
            <Box className={classes.casesContainer}>
                <TabContext value={value}>
                    <Box className={classes.headerContainer}>
                        <TabList
                            component={Paper}
                            indicatorColor='primary'
                            textColor='primary'
                            value={value}
                            onChange={handleChange}
                            className={classes.tabsContainer}
                        >
                            <Tab
                                label={`Open (${openCases.count ? openCases.count : '0'})`}
                                value='1'
                            />
                            <Tab
                                label={`Closed (${
                                    closedCases.count ? closedCases.count : '0'
                                })`}
                                value='2'
                            />

                            {selectedDept.headDoctor === true ? (
                                <Tab
                                    label={`All (${cases.count ? cases.count : '0'})`}
                                    value='3'
                                />
                            ) : (
                                <></>
                            )}
                        </TabList>

                        {role !== ROLES.LIMITED_USER ? (
                            <Box className={classes.actionButtonsContainer}>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    startIcon={<AddIcon/>}
                                    disableElevation={true}
                                    className={classes.addCaseButton}
                                    component={Link}
                                    to='/cases/addCase'
                                >
                                    Add Case
                                </Button>
                            </Box>
                        ) : (
                            <></>
                        )}
                        <Divider/>
                    </Box>

                    <Box>
                        <TabPanel value='1'>
                            <Box className={classes.tabPanelContainer}>
                                {openCases && openCases.count === 0 ? (
                                    <Box className={classes.noContentContainer}>
                                        <Typography classes={{root: classes.noLabel}}>
                                            No cases available
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Grid container spacing={2}>
                                        {openCases.rows &&
                                        openCases.rows.map(caseItem => (
                                            <CaseItem key={caseItem.id} caseItem={caseItem}/>
                                        ))}
                                    </Grid>
                                )}
                            </Box>
                        </TabPanel>

                        <TabPanel value='2'>
                            <Box className={classes.tabPanelContainer}>
                                {closedCases && closedCases.count === 0 ? (
                                    <Box className={classes.noContentContainer}>
                                        <Typography classes={{root: classes.noLabel}}>
                                            No cases available
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Grid container spacing={2}>
                                        {closedCases.rows &&
                                        closedCases.rows.map(caseItem => (
                                            <CaseItem key={caseItem.id} caseItem={caseItem}/>
                                        ))}
                                    </Grid>
                                )}
                            </Box>
                        </TabPanel>
                        <TabPanel value='3'>
                            <Box className={classes.tabPanelContainer}>
                                {cases && cases.count === 0 ? (
                                    <Box className={classes.noContentContainer}>
                                        <Typography classes={{root: classes.noLabel}}>
                                            No cases available
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Grid container spacing={2}>
                                        {cases.rows &&
                                        cases.rows.map(caseItem => (
                                            <CaseItem key={caseItem.id} caseItem={caseItem}/>
                                        ))}
                                    </Grid>
                                )}
                            </Box>
                        </TabPanel>
                    </Box>
                </TabContext>
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        selectedDept: state.sirenUsers.selectedDept,
        cases: state.cases.cases,
        openCases: state.cases.openCases,
        closedCases: state.cases.closedCases,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCases: id => {
            return dispatch(getCasesRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getOpenCases: id => {
            return dispatch(getOpenCasesRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getClosedCases: id => {
            return dispatch(getClosedCasesRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cases);
