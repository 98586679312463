import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        outerContainer: {
            paddingTop: '1rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            paddingBottom: '4.5rem',
        },
        profileContainer: {
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
        },
        profileInnerContainer: {
            height: '40px',
            width: '40px',
        },
        profileImageError: {
            height: '40px',
            width: '40px',
            borderRadius: '100%',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            textAlign: 'center',
        },
        profileImage: {
            height: '40px',
            width: '40px',
            borderRadius: '100%',
            border: '1px solid rgba(0, 0, 0, 0.2)',
        },
        nameContainer: {
            marginLeft: '0.5rem',
        },
        name: {
            fontSize: '1rem',
        },
        statusActive: {
            color: 'green',
        },
        statusBlocked: {
            color: 'red',
        },
        tableContainer: {},
        addButtonContainer: {
            marginTop: '10px',
            marginBottom: '10px',
            textAlign: 'right',
        },
        addButton: {
            position: 'absolute',
            marginTop: '1rem',
            zIndex: '1',
            textTransform: 'capitalize',
            marginLeft: '-14.5rem',
        },
        breadcrumbsContainer: {
            position: 'sticky',
            zIndex: '1000',
            top: '0',
            marginTop: '-16px',
            marginLeft: '-25px',
            marginRight: '-25px',
        },
        breadcrumbsContainerForUsers: {
            position: 'sticky',
            zIndex: '1000',
            top: '75px',
            marginTop: '-16px',
            marginLeft: '-15px',
            marginRight: '-25px',
        },
        breadcrumbsContainerForAdmins: {
            position: 'sticky',
            zIndex: '1000',
            top: '0px',
            marginTop: '-28px',
            marginLeft: '-15px',
            marginRight: '-25px',
        },
        breadcrumbItem: {
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: theme.palette.text.primary,
            padding: '4px 0px',
        },
        activeBreadcrumbItem: {
            color: theme.palette.primary.main,
        },
        breadcrumpIcon: {
            marginRight: '5px',
            fontSize: '18px',
        },
        breadcrumpLabel: {
            fontSize: '14px',
        },
    };
});
