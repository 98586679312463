export const GET_PATIENTS_FOR_DEPT = 'GET_PATIENTS_FOR_DEPT';
export const ADD_PATIENT = 'ADD_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const GET_PATIENT_BY_ID = 'GET_PATIENT_BY_ID';
export const TOGGLE_PATIENT_ACTIVATION = 'TOGGLE_PATIENT_ACTIVATION';
export const SYNC_ARMS_FOR_PATIENT = 'SYNC_ARMS_FOR_PATIENT';

/**
 * action creator function to get patients of a department
 * @param patients: patients from api response data
 * @returns {{patients, type: string}}
 */
export const getPatientsForDept = patients => {
    return {
        type: GET_PATIENTS_FOR_DEPT,
        patients: patients,
    };
};

/**
 * action creator function to add patient
 * @param patient: patient from api response data
 * @returns {{patient, type: string}}
 */
export const addPatient = patient => {
    return {
        type: ADD_PATIENT,
        patient: patient,
    };
};

/**
 * action creator function to update patient
 * @param patient: patient from api response data
 * @returns {{patient, type: string}}
 */
export const updatePatient = patient => {
    return {
        type: UPDATE_PATIENT,
        patient: patient,
    };
};

/**
 * action creator function to get patient by id
 * @param patient: patient from api response data
 * @returns {{patient, type: string}}
 */
export const getPatientById = patient => {
    return {
        type: GET_PATIENT_BY_ID,
        patient: patient,
    };
};

/**
 * action creator function to toggle patient activation
 * @param patient: patient from api response data
 * @returns {{patient, type: string}}
 */
export const toggleActivation = patient => {
    return {
        type: TOGGLE_PATIENT_ACTIVATION,
        patient: patient,
    };
};

/**
 * action creator function to sync arms
 * @param arms: arms from api response data
 * @returns {{arms, type: string}}
 */
export const syncArmsForPatient = arms => {
    return {
        type: SYNC_ARMS_FOR_PATIENT,
        arms: arms,
    };
};
