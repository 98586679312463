import React, {useEffect, useState} from 'react';
import {Redirect, useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import {Box, Button, Container, CssBaseline, Typography,} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {setPasswordRequest} from '../../AuthApiActions';
import logo from '../../../../assets/logo.png';
import {getAdmin, getIsTeamList, getUserInfo, isLoggedIn,} from '../../../../utilities/storage';
import UtilHelper from '../../../../utilities/validators/UtilHelper';
import {ROLES, APP_URLS} from '../../../../utilities/constants'
import './SetPassword.css';

/**
 * function to render set password form
 * @param history: history from router
 * @param setPasswordFirstTime: function to set password
 * @returns {JSX.Element}
 * @constructor
 */
const SetPassword = ({history, setPasswordFirstTime}) => {
    const params = useParams();
    const role = getUserInfo() ? getUserInfo().Roles[0].name : '';
    const isTeamList = getIsTeamList() ? getIsTeamList() === 'true' ? true : false : '';
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        ValidatorForm.addValidationRule('isValidPassword', value =>
            UtilHelper.validatePassword(value)
        );
        ValidatorForm.addValidationRule('isPasswordMatch', value =>
            UtilHelper.matchPassword(value, password)
        );

        return () => {
            ValidatorForm.removeValidationRule('isValidPassword');
            ValidatorForm.removeValidationRule('isPasswordMatch');
        };
    }, [password]);

    /**
     * function to handle forgot password on button click
     * @param e: event from button click
     */
    const onSubmit = e => {
        e.preventDefault();

        const data = {
            password: password,
        };

        setPasswordFirstTime(params.id, params.token, data).then(() => {
            history.push(APP_URLS.LOGIN);
        });
    };

    if (isLoggedIn()) {
        if (role === ROLES.SIRENMD_ADMIN) {
            return <Redirect to={APP_URLS.ORGANIZATIONS} />;
        } else if (role === ROLES.COACH) {
            return <Redirect to={APP_URLS.PLAY_STATUS_TEAM_ROSTER} />;
        } else if (!isTeamList && getAdmin().isOrgAdmin === true) {
            return <Redirect to={APP_URLS.ORGANIZATIONS} />;
        } else if (!isTeamList && getAdmin().isDeptAdmin === true) {
            return <Redirect to={APP_URLS.DEPARTMENTS} />;
        } else if (!isTeamList && getAdmin().isBothAdmin === true) {
            return <Redirect to={APP_URLS.ORGANIZATIONS} />;
        } else if (isTeamList === 'true') {
            return <Redirect to={APP_URLS.NEW_UPDATES} />;
        }
    }

    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline/>

            <Box className='forget-password-form-container'>
                <img src={logo} alt='logo'/>
                <Typography component='h1' variant='h5' style={{marginTop: '1.5rem'}}>
                    Set Password
                </Typography>

                <ValidatorForm
                    noValidate
                    className='forget-password-form'
                    onSubmit={onSubmit}
                >
                    <TextValidator
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='password'
                        label='Password'
                        type='password'
                        validators={['required', 'isValidPassword']}
                        errorMessages={[
                            'Password is required',
                            'Password should have minimum 1 uppercase, 1 lowercase, 1 numeric, 1 special characters',
                        ]}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />

                    <TextValidator
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        name='password'
                        label='Confirm Password'
                        type='password'
                        validators={['isPasswordMatch']}
                        errorMessages={['Passwords does not match ']}
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />

                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        className='forget-password-button'
                    >
                        Set Password
                    </Button>
                </ValidatorForm>
            </Box>
        </Container>
    );
};

const mapStateToProps = state => {
    return {
        loggedInUser: state.auth.loggedInUser,
    };
};

const mapDispatchToProp = dispatch => {
    return {
        setPasswordFirstTime: (id, token, data) => {
            return dispatch(setPasswordRequest(id, token, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProp)(SetPassword);
