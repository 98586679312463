import React from 'react';
import {Link} from 'react-router-dom';
import {Box, Grid, Paper, Typography} from '@material-ui/core';
import {useStyles} from './styles';
import defaultUser from '../../../../../../assets/user.png';
import ImageLoader from 'react-load-image';
import Preloader from '../../../../../../utilities/components/Preloader/Preloader';
import {APP_URLS} from '../../../../../../utilities/constants';

/**
 * function to render case item
 * @param caseItem: case item details
 * @returns {JSX.Element}
 * @constructor
 */
const CaseItem = ({caseItem}) => {
    const classes = useStyles();
    return (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box component={Paper} className={classes.contentContainer}>
                <Box
                    component={Link}
                    to={`${APP_URLS.CASES}/${caseItem.id}`}
                    className={classes.contentCardUpper}
                >
                    <Box className={classes.careHeader}>
                        <Box className={classes.headerLabel}>
                            {caseItem.jerseyNum ? `Jersey no: ${caseItem.jerseyNum}` : ''}
                        </Box>
                        <Box className={classes.headerLabel}>Case no: {caseItem.id}</Box>
                    </Box>
                    
                    <Box className={classes.contentCard}>
                        <Box className={classes.profileOuterContainer}>
                            <Box className={classes.profileContainer}>
                                <Box className={classes.profileInnerContainer}>
                                    <ImageLoader
                                        src={caseItem.imageUrl ? caseItem.imageUrl : defaultUser}
                                    >
                                        <img
                                            alt={caseItem.id}
                                            className={`${classes.profileImage} ${
                                                caseItem.priority === 1 ? classes.critical : ''
                                            }`}
                                        />
                                        <div
                                            className={`${classes.profileImageError} ${
                                                caseItem.priority === 1 ? classes.critical : ''
                                            }`}
                                        >
                                            {caseItem.id}
                                        </div>
                                        <Preloader
                                            alt={caseItem.id}
                                            className={`${classes.profileImage} ${
                                                caseItem.priority === 1 ? classes.critical : ''
                                            }`}
                                        />
                                    </ImageLoader>
                                </Box>
                            </Box>
                        </Box>

                        <Box className={classes.infoContainer}>
                            <Typography
                                classes={{
                                    root: `${classes.name} ${
                                        caseItem.priority === 1 ? classes.nameCritical : ''
                                    }`,
                                }}
                            >
                                {`${caseItem.firstName} ${caseItem.lastName}`}
                            </Typography>
                            <Typography noWrap classes={{root: classes.description}}>
                                {caseItem.desc}
                            </Typography>
                            <Box className={classes.createdContainer}>
                                <Box className={classes.createdUserContainer}>
                                    <Typography classes={{root: classes.createdBy}}>
                                        {`${caseItem.updatedByFirstName} ${caseItem.updatedByLastName}`}
                                        , updated: {caseItem.updatedAt}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
};

export default CaseItem;
