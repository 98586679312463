import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        outerContainer: {
            padding: '0.8rem',
            height: '145px',
        },
        mainAthleteContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textDecoration: 'none',
            color: 'rgba(0, 0, 0, 0.87)',
        },
        profileImageContainer: {
            position: 'relative',
            borderRadius: '100%',
            overflow: 'hidden',
            marginRight: '0px',
        },
        profileImageError: {
            height: '80px',
            width: '80px',
            borderRadius: '100%',
            padding: '4px',
            border: '2px solid rgb(221, 221, 221)',
            verticalAlign: 'sub',
            textAlign: 'center',
        },
        profileImage: {
            height: '80px',
            width: '80px',
            borderRadius: '100%',
            padding: '4px',
            border: '2px solid rgb(221, 221, 221)',
            verticalAlign: 'sub',
        },
        profileTextContainer: {
            position: 'absolute',
            top: '-2px',
            left: '5px',
            height: '75px',
            width: '70px',
            borderRadius: '100%',
            overflow: 'hidden',
        },
        profileText: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            textAlign: 'center',
            position: 'absolute',
            width: '100%',
            bottom: '0',
            lineHeight: '19px',
            height: '17px',
            color: 'white',
            fontSize: '14px',
            fontWeight: 'bold',
        },
        name: {
            fontSize: '14px',
            fontWeight: '450',
        },
        headerLabel: {
            color: '#727272',
        },
    };
});
