import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        profileContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        profileImage: {
            width: '100px',
            borderRadius: '100%',
            border: '1px solid rgba(0, 0, 0, 0.2)',
        },
        detailsContainer: {
            textAlign: 'center',
            marginTop: '0.5rem',
        },
        roleLabel: {
            fontSize: '0.8rem',
        },
        toggleContainer: {
            marginLeft: '0.2rem',
            display: 'flex',
            alignItems: 'center',
            marginTop: '-0.2rem',
        },
        actionsContainer: {
            marginRight: '1rem',
            marginBottom: '0.5rem',
        },
    };
});
