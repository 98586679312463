import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider,} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import {useStyles} from './styles';

/**
 * function to render dicom help modal
 * @param open: opens the dialog
 * @param handleClose: function to close the dialog
 * @returns {JSX.Element}
 * @constructor
 */
const DicomHelpModal = ({open, handleClose}) => {
    const classes = useStyles();

    return (
        <Dialog maxWidth='md' fullWidth open={open} disableBackdropClick>
            <DialogTitle>DICOM Upload Help</DialogTitle>

            <Divider/>

            <DialogContent>
                <Box>
                    <iframe
                        title='dicom'
                        src='../static/chrome.html'
                        frameborder='1'
                        width='100%'
                        height='450px'
                    />
                </Box>
            </DialogContent>

            <Divider/>

            <DialogActions classes={{root: classes.actionsContainer}}>
                <Box className={classes.noteContainer}>
                    <Box>
                        <HelpIcon className={classes.helpIcon}/>
                    </Box>
                    <Box>
                        <span className={classes.helpLabel}>Having trouble uploading?</span>{' '}
                        <span className={classes.help}>Click here for instructions.</span>
                    </Box>
                </Box>
                <Box>
                    <Button variant='contained' onClick={handleClose} color='primary'>
                        Close
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default DicomHelpModal;
