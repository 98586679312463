import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {NavLink, useParams, useRouteMatch} from 'react-router-dom';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import UtilHelper from '../../../../utilities/validators/UtilHelper';
import {getStatesRequest} from '../../../Users/UsersApiActions';
import ConfirmationDialog from '../../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import {useStyles} from './styels';
import ImageField from '../../../../utilities/components/ImageField/ImageField';
import {dataURLtoFile} from '../../../../utilities/utilityFunctions';
import {getIsTeamList, getUserInfo} from '../../../../utilities/storage';
import {Box, Breadcrumbs, Button, Card, Grid, Icon, Paper, Typography,} from '@material-ui/core';
import {
    addVendorRequest,
    getSignedUrlRequest,
    getVendorByIdRequest,
    getVendorTypesRequest,
    updateVendorRequest,
    uploadToS3Request,
} from '../../IntegrationVendorsApiActions';
import {AutocompleteValidator, PhoneInputValidator,} from '../../../../utilities/validators';
import {ROLES} from '../../../../utilities/constants';

/**
 * function to render vendors form
 * @param location: location from router
 * @param history: history from router
 * @param getVendorTypes: function to get vendor types
 * @param vendorTypesData: vendor types list
 * @param addVendor: function to add vendor
 * @param getVendorById: function to get vendor
 * @param updateVendor: function to update vendor
 * @param getSignedUrl: function to get signed url
 * @param uploadToS3: function to upload file on signed url
 * @param getStates: function to get states
 * @param states: status list
 * @returns {JSX.Element}
 * @constructor
 */
const VendorsForm = ({
                         location,
                         history,
                         getVendorTypes,
                         vendorTypesData,
                         addVendor,
                         getVendorById,
                         updateVendor,
                         getSignedUrl,
                         uploadToS3,
                         getStates,
                         states,
                     }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const params = useParams();
    const match = useRouteMatch();

    const [name, setName] = useState('');
    const [type, setType] = useState(null);
    const [currentVersion, setCurrentVersion] = useState('');
    const [transactionEndpoint, setTransactionEndpoint] = useState('');
    const [retriesBeforeTimeout, setRetriesBeforeTimeout] = useState('');
    const [retriesGapInSeconds, setRetriesGapInSeconds] = useState('');
    const [
        transactionAttemptsBeforeFailure,
        setTransactionAttemptsBeforeFailure,
    ] = useState('');
    const [description, setDescription] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [selectedState, setSelectedState] = useState(null);
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [apiSecret, setApiSecret] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);
    const [rowImageFile, setRowImageFile] = useState(null);
    const [cropper, setCropper] = useState(null);
    const [croppedImageFile, setCroppedImageFile] = useState(null);
    const [showImageEditor, setShowImageEditor] = useState(false);
    const currentUrlPath = match.path.split(':')[0];

    useEffect(() => {
        ValidatorForm.addValidationRule('isBlank', value =>
            UtilHelper.validateBlankSpace(value)
        );
        ValidatorForm.addValidationRule('isValidMobile', value =>
            UtilHelper.validateMobile(value)
        );
        ValidatorForm.addValidationRule('isMaxLength', value =>
            UtilHelper.validateMaxLength(value)
        );
        ValidatorForm.addValidationRule('isZipCode', value =>
            UtilHelper.validateZpiCode(value)
        );

        return () => {
            ValidatorForm.removeValidationRule('isBlank');
            ValidatorForm.removeValidationRule('isValidMobile');
            ValidatorForm.removeValidationRule('isMaxLength');
            ValidatorForm.removeValidationRule('isZipCode');
        };
    }, []);

    useEffect(() => {
        if(!params.id){
            getVendorTypes().then(res => {
            });
        }
    }, [getVendorTypes, params.id]);

    useEffect(() => {
        getStates().then(res => {
        });
    }, [getStates]);

    useEffect(() => {
        if (params.id) {
            getVendorById(params.id).then(res => {

                getVendorTypes().then(resTypes => {
                    const type = resTypes.find(type => type.id === res.type);

                    if (type) {
                        setType(type);
                    }

                    setCroppedImageFile(res.imageUrl);
                    setName(res.name);
                    setDescription(res.desc);
                    setEmail(res.email);
                    setPhone(res.phone);
                    setAddressLine1(res.addressLine1);
                    setAddressLine2(res.addressLine2);
                    setSelectedState(res.State);
                    setCity(res.city);
                    setZipCode(res.zipCode);
                    setApiKey(res.apiKey);
                    setApiSecret(res.apiSecret);
                    setCurrentVersion(res.currentVer);
                    setTransactionEndpoint(res.endpoint);
                    setRetriesBeforeTimeout(res.retriesTimeout);
                    setRetriesGapInSeconds(res.retriesGap);
                    setTransactionAttemptsBeforeFailure(res.transactionAttempts);
                });

                
            });
        }
    }, [params.id, getVendorById, getVendorTypes]);

    const breadCrumbDataForAddVendorForm = [
        {
            icon: 'assignment_ind',
            name: 'Integration Vendors',
            path: '/vendors',
        },
        {
            name: `Add Integration Vendor`,
            path: `/vendors/add`,
        },
    ];

    const breadCrumbDataForUpdateVendorForm = [
        {
            icon: 'assignment_ind',
            name: 'Integration Vendors',
            path: '/vendors',
        },
        {
            name: `Update Integration Vendor`,
            path: `/vendors/edit/${params.id}`,
        },
    ];

    const breadCrumbDataForUpdateHcorgVendorForm = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: '/healthcareOrgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `Update Integration Vendor`,
            path: `/healthcareOrgs/vendors/edit/${params.id}`,
        },
    ];

    /**
     * function to get breadcrumb data for current url
     * @return {Array}
     */

    const getBreadcrumbData = (urlPath) => {
        switch(urlPath){
            case '/healthcareOrgs/vendors/edit/':{
                return breadCrumbDataForUpdateHcorgVendorForm
            }
            case '/vendors/edit/':{
               return breadCrumbDataForUpdateVendorForm
            }
            case '/vendors/add':{
                return breadCrumbDataForAddVendorForm
             }
            default: {
                return []
            }
        }
    }

    /**
     * function to handle image crop
     */
    const onImageCrop = () => {
        const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL();
        const croppedImageFileObject = dataURLtoFile(
            croppedImageDataUrl,
            rowImageFile[0].file.name
        );

        const data = {
            dataURL: croppedImageDataUrl,
            file: croppedImageFileObject,
        };

        setCroppedImageFile(data);
        setShowImageEditor(false);
    };

    /**
     * function to add new vendor
     * @param e: event object on form submission
     */
    const onAdd = e => {
        e.preventDefault();

        const data = {
            name: name,
            desc: description,
            type: type.id,
            currentVer: currentVersion,
            endpoint: transactionEndpoint,
            retriesTimeout: retriesBeforeTimeout,
            retriesGap: retriesGapInSeconds,
            transactionAttempts: transactionAttemptsBeforeFailure,
            apiSecret: apiSecret,
            apiKey: apiKey,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            zipCode: zipCode,
            email: email,
            phone: phone,
            StateId: selectedState.id,
        };

        if (croppedImageFile) {
            const imageData = {
                fileName: croppedImageFile.file.name,
                fileType: `.${croppedImageFile.file.name.split('.').pop()}`,
            };

            getSignedUrl(imageData).then(res => {
                uploadToS3(res.urls, croppedImageFile.file).then(uploadRes => {
                    data['imageUrl'] = res.path;
                    addVendor(data).then(res => {
                        history.push(`/vendors/${res.id}`);
                    });
                });
            });
        } else {
            addVendor(data).then(res => {
                history.push(`/vendors/${res.id}`);
            });
        }
    };

    /**
     * function to update existing vendor
     * @param e: event object on form submission
     */
    const onUpdate = e => {
        e.preventDefault();

        if (typeof croppedImageFile !== 'string') {
            const imageData = {
                fileName: croppedImageFile.file.name,
                fileType: `.${croppedImageFile.file.name.split('.').pop()}`,
            };

            getSignedUrl(imageData).then(res => {
                uploadToS3(res.urls, croppedImageFile.file).then(uploadRes => {
                    const data = {
                        name: name,
                        desc: description,
                        imageUrl: res.path,
                        type: type.id,
                        currentVer: currentVersion,
                        endpoint: transactionEndpoint,
                        retriesTimeout: retriesBeforeTimeout,
                        retriesGap: retriesGapInSeconds,
                        transactionAttempts: transactionAttemptsBeforeFailure,
                        apiSecret: apiSecret,
                        apiKey: apiKey,
                        addressLine1: addressLine1,
                        addressLine2: addressLine2,
                        city: city,
                        zipCode: zipCode,
                        email: email,
                        phone: phone,
                        StateId: selectedState.id,
                    };

                    updateVendor(params.id, data).then(res => {
                        history.goBack();
                    });
                });
            });
        } else {
            const data = {
                name: name,
                desc: description,
                type: type.id,
                currentVer: currentVersion,
                endpoint: transactionEndpoint,
                retriesTimeout: retriesBeforeTimeout,
                retriesGap: retriesGapInSeconds,
                transactionAttempts: transactionAttemptsBeforeFailure,
                apiSecret: apiSecret,
                apiKey: apiKey,
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                zipCode: zipCode,
                email: email,
                phone: phone,
                StateId: selectedState.id,
            };

            updateVendor(params.id, data).then(res => {
                history.goBack();
            });
        }
    };

    /**
     * function to handle cancel add or update
     */
    const handleCancel = () => {
        history.goBack();
    };

    return (
        <Box
            className={
                role === ROLES.SIRENMD_ADMIN &&
                getIsTeamList() === 'false'
                    ? ''
                    : classes.outerContainer
            }
        >
            <Box
                className={
                    role === ROLES.SIRENMD_ADMIN &&
                    getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainer
                        : getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainerForAdmins
                        : classes.breadcrumbsContainerForUsers
                }
                component={Paper}
                p={1}
            >
                <Breadcrumbs>
                    {getBreadcrumbData(currentUrlPath).map((crumb, index) => (
                        <Box
                            key={index}
                            component={NavLink}
                            exact
                            to={{
                                pathname: crumb.path,
                                state: {
                                    hcorgIdHistory:
                                        location.state && location.state.hcorgIdHistory,
                                    hcorgNameHistory:
                                        location.state && location.state.hcorgNameHistory,
                                },
                            }}
                            className={classes.breadcrumbItem}
                            activeClassName={classes.activeBreadcrumbItem}
                        >
                            {crumb.icon && (
                                <Icon className={classes.breadcrumpIcon}>{crumb.icon}</Icon>
                            )}
                            <Box className={classes.breadcrumpLabel}>{crumb.name}</Box>
                        </Box>
                    ))}
                </Breadcrumbs>
            </Box>

            <Box mt={2}>
                <ValidatorForm onSubmit={params.id ? onUpdate : onAdd}>
                    <Box className={classes.addVendorContainer}>
                        <Box className={classes.addVendorLabelContainer}>
                            <Typography className={classes.addVendorLabel}>
                                {params.id ? 'Update' : 'Create'} Integration Vendor
                            </Typography>
                        </Box>

                        <Box className={classes.addVendorFormContainer}>
                            <Box
                                component={Paper}
                                className={classes.addVendorFormInnerContainer}
                            >
                                <Box className={classes.formLabelVendorDetails}>
                                    <Typography variant='h6'>Vendor Information</Typography>
                                </Box>

                                <Grid container spacing={2} justify='space-around'>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <Box>
                                            <ImageField
                                                rowImageFile={rowImageFile}
                                                setRowImageFile={setRowImageFile}
                                                showImageEditor={showImageEditor}
                                                setShowImageEditor={setShowImageEditor}
                                                setCropper={setCropper}
                                                croppedImageFile={croppedImageFile}
                                                onImageCrop={onImageCrop}
                                                options={{
                                                    shape: 'rect',
                                                }}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextValidator
                                                    label='Name *'
                                                    variant='outlined'
                                                    fullWidth
                                                    size='small'
                                                    value={name}
                                                    onChange={e => setName(e.target.value)}
                                                    validators={['required', 'isBlank', 'isMaxLength']}
                                                    errorMessages={[
                                                        'Name is required',
                                                        'Please enter name',
                                                        'Maximum 100 letters are accepted.',
                                                    ]}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextValidator
                                                    label='Description *'
                                                    variant='outlined'
                                                    fullWidth
                                                    size='small'
                                                    value={description}
                                                    onChange={e => setDescription(e.target.value)}
                                                    validators={['required', 'isBlank', 'isMaxLength']}
                                                    errorMessages={[
                                                        'Description is required',
                                                        'please enter description',
                                                        'Maximum 100 letters are accepted.',
                                                    ]}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextValidator
                                                    label='Email *'
                                                    variant='outlined'
                                                    fullWidth
                                                    size='small'
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                    validators={['required', 'isEmail']}
                                                    errorMessages={['Email is required', 'Invalid email']}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <PhoneInputValidator
                                                    autoFormat={false}
                                                    variant='outlined'
                                                    size='small'
                                                    label='Phone *'
                                                    fullWidth
                                                    defaultCountry='us'
                                                    countryCodeEditable={false}
                                                    value={phone}
                                                    onChange={(phone, data) => {
                                                        let phoneNumber =
                                                            '+' + phone.replace(/[^0-9]+/g, '');
                                                        setPhone(phoneNumber);
                                                    }}
                                                    validators={['required', 'isValidMobile']}
                                                    errorMessages={[
                                                        'Phone is required',
                                                        'Please enter valid phone',
                                                    ]}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <AutocompleteValidator
                                                    fullWidth
                                                    size='small'
                                                    value={type}
                                                    onChange={(e, value) => {
                                                        setType(value);
                                                    }}
                                                    options={vendorTypesData ? vendorTypesData : []}
                                                    getOptionLabel={option =>
                                                        `${option.description
                                                            .charAt(0)
                                                            .toUpperCase()}${option.description.slice(1)}`
                                                    }
                                                    getOptionSelected={(option, value) =>
                                                        option.id === value.id
                                                    }
                                                    renderInput={params => (
                                                        <TextValidator
                                                            {...params}
                                                            label='Type *'
                                                            variant='outlined'
                                                        />
                                                    )}
                                                    validators={['required']}
                                                    errorMessages={['Type is required']}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box component={Paper} className={classes.sectionsContainer}>
                                <Box className={classes.formLabelVendorDetails}>
                                    <Typography variant='h6'>Address Information</Typography>
                                </Box>

                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.addFormContainer}
                                >
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='Address Line 1 *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={addressLine1}
                                            onChange={e => setAddressLine1(e.target.value)}
                                            validators={['required', 'isBlank']}
                                            errorMessages={[
                                                'Address Line 1 is required',
                                                'Please enter address line 1',
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='Address Line 2 *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={addressLine2}
                                            onChange={e => setAddressLine2(e.target.value)}
                                            validators={['required', 'isBlank']}
                                            errorMessages={[
                                                'Address Line 2 is required',
                                                'Please enter address line 2',
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='City *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={city}
                                            onChange={e => setCity(e.target.value)}
                                            validators={['required', 'isBlank']}
                                            errorMessages={['City is required', 'Please enter city']}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <AutocompleteValidator
                                            fullWidth
                                            size='small'
                                            value={selectedState}
                                            onChange={(e, value) => {
                                                setSelectedState(value);
                                            }}
                                            options={states}
                                            getOptionLabel={option => {
                                                return `${option.initial} - ${option.desc
                                                    .charAt(0)
                                                    .toUpperCase()}${option.desc.slice(1)}`;
                                            }}
                                            getOptionSelected={(option, value) =>
                                                option.id === value.id
                                            }
                                            renderInput={params => (
                                                <TextValidator
                                                    {...params}
                                                    label='Select State *'
                                                    variant='outlined'
                                                />
                                            )}
                                            validators={['required']}
                                            errorMessages={['State is required']}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='Zip Code *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={zipCode}
                                            onChange={e => setZipCode(e.target.value)}
                                            validators={['required', 'isBlank', 'isZipCode']}
                                            errorMessages={[
                                                'Zip Code is required',
                                                'Please enter zip code',
                                                'Please enter valid zip code',
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box component={Paper} className={classes.sectionsContainer}>
                                <Box className={classes.formLabelVendorDetails}>
                                    <Typography variant='h6'>Authentication</Typography>
                                </Box>

                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.addFormContainer}
                                >
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='API Secret *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={apiSecret}
                                            onChange={e => setApiSecret(e.target.value)}
                                            validators={['required', 'isBlank']}
                                            errorMessages={[
                                                'API Secret is required',
                                                'Please enter API secret',
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='API Key *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={apiKey}
                                            onChange={e => setApiKey(e.target.value)}
                                            validators={['required', 'isBlank']}
                                            errorMessages={[
                                                'API Key is required',
                                                'Please enter API key',
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box component={Paper} className={classes.sectionsContainer}>
                                <Box className={classes.formLabelVendorDetails}>
                                    <Typography variant='h6'>Other Information</Typography>
                                </Box>

                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.addFormContainer}
                                >
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='Current Version *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={currentVersion}
                                            onChange={e => setCurrentVersion(e.target.value)}
                                            validators={['required', 'isBlank']}
                                            errorMessages={[
                                                'Current version is required',
                                                'Please enter current version',
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='Transaction Endpoint'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={transactionEndpoint}
                                            onChange={e => setTransactionEndpoint(e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='Retries Before Timeout *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={retriesBeforeTimeout}
                                            onChange={e => setRetriesBeforeTimeout(e.target.value)}
                                            validators={['required', 'isBlank']}
                                            errorMessages={[
                                                'Retries Before Timeout is required',
                                                'Please enter retries before timeout',
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='Retries Gap In Seconds *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={retriesGapInSeconds}
                                            onChange={e => setRetriesGapInSeconds(e.target.value)}
                                            validators={['required', 'isBlank']}
                                            errorMessages={[
                                                'Retries Gap in Seconds is required',
                                                'Please enter retries gap in seconds',
                                            ]}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='Transaction Attempts Before Failure *'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={transactionAttemptsBeforeFailure}
                                            onChange={e =>
                                                setTransactionAttemptsBeforeFailure(e.target.value)
                                            }
                                            validators={['required', 'isBlank']}
                                            errorMessages={[
                                                'Transaction Attempts Before Failure is required',
                                                'Please enter transaction attempts before failure',
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>

                        <Box className={classes.footerContainer}>
                            <Box
                                component={Card}
                                raised
                                className={classes.footerContainerInner}
                            >
                                <Box>
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        disableElevation={true}
                                        onClick={() => setOpenCancelConfirmation(true)}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        variant='contained'
                                        color='primary'
                                        disableElevation={true}
                                        type='submit'
                                        className={classes.saveButton}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </ValidatorForm>

                <Box>
                    <ConfirmationDialog
                        open={openCancelConfirmation}
                        setOpen={setOpenCancelConfirmation}
                        title='Cancel'
                        message='Are you sure you want to cancel?'
                        onConfirm={handleCancel}
                    />
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        vendorTypesData: state.vendors.vendorTypes,
        states: state.sirenUsers.states,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addVendor: data => {
            return dispatch(addVendorRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getVendorById: id => {
            return dispatch(getVendorByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateVendor: (id, data) => {
            return dispatch(updateVendorRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getVendorTypes: () => {
            return dispatch(getVendorTypesRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },

        getSignedUrl: data => {
            return dispatch(getSignedUrlRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        uploadToS3: (url, file) => {
            return dispatch(uploadToS3Request(url, file)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getStates: () => {
            return dispatch(getStatesRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorsForm);
