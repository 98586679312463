export const GET_CAREGIVERS_FOR_ORG = 'GET_CAREGIVERS_FOR_ORG';
export const GET_CAREGIVERS_FOR_HC_ORG = 'GET_CAREGIVERS_FOR_HC_ORG';
export const GET_CAREGIVERS_FOR_DEPT = 'GET_CAREGIVERS_FOR_DEPT';
export const ADD_CAREGIVER = 'ADD_CAREGIVER';
export const ADD_CAREGIVER_FROM_EXISTING_USER =
    'ADD_CAREGIVER_FROM_EXISTING_USER';
export const REMOVE_CAREGIVER_FROM_DEPT = 'REMOVE_CAREGIVER_FROM_DEPT';
export const TOGGLE_CAREGIVER_HEAD = 'TOGGLE_CAREGIVER_HEAD';
export const TOGGLE_CAREGIVER_PRIVILEGES = 'TOGGLE_CAREGIVER_PRIVILEGES';
export const GET_USER_BY_EMAIL = 'GET_USER_BY_EMAIL';

/**
 * action creator function to add caregiver
 * @param caregiver: caregiver from api response data
 * @returns {{caregiver, type: string}}
 */
export const addCaregiver = caregiver => {
    return {
        type: ADD_CAREGIVER,
        caregiver: caregiver,
    };
};

/**
 * action creator function to add caregiver from existing user
 * @param caregiver: caregiver from api response data
 * @returns {{caregiver, type: string}}
 */
export const addCaregiverFromExistingUser = caregiver => {
    return {
        type: ADD_CAREGIVER_FROM_EXISTING_USER,
        caregiver: caregiver,
    };
};

/**
 * action creator function to get caregivers for a organization
 * @param caregivers: caregivers from api response data
 * @returns {{caregivers, type: string}}
 */
export const getCaregiversForOrg = caregivers => {
    return {
        type: GET_CAREGIVERS_FOR_ORG,
        caregivers: caregivers,
    };
};

/**
 * action creator function to get caregivers for a healthcare organization
 * @param caregivers: caregivers from api response data
 * @returns {{caregivers, type: string}}
 */
export const getCaregiversForHCOrg = caregivers => {
    return {
        type: GET_CAREGIVERS_FOR_HC_ORG,
        caregivers: caregivers,
    };
};

/**
 * action creator function to get caregivers for a department
 * @param caregivers: caregivers from api response data
 * @returns {{caregivers, type: string}}
 */
export const getCaregiversForDept = caregivers => {
    return {
        type: GET_CAREGIVERS_FOR_DEPT,
        caregivers: caregivers,
    };
};

/**
 * action creator function to remove caregiver from a department
 * @param caregiver: caregiver to be removed
 * @returns {{caregiver, type: string}}
 */
export const removeCaregiverFromDept = caregiver => {
    return {
        type: REMOVE_CAREGIVER_FROM_DEPT,
        caregiver: caregiver,
    };
};

/**
 * action creator function to toggle caregiver head
 * @param caregiver: caregiver
 * @returns {{caregiver, type: string}}
 */
export const toggleCaregiverHead = caregiver => {
    return {
        type: TOGGLE_CAREGIVER_HEAD,
        caregiver: caregiver,
    };
};

/**
 * action creator function to toggle caregiver clinical privileges
 * @param caregiver
 * @returns {{caregiver, type: string}}
 */
export const toggleCaregiverPrivileges = privilege => {
    return {
        type: TOGGLE_CAREGIVER_PRIVILEGES,
        privilege: privilege,
    };
};

/**
 * action creator function to get user by email
 * @param user
 * @returns {{type: string, user}}
 */
export const getUserByEmail = user => {
    return {
        type: GET_USER_BY_EMAIL,
        user: user,
    };
};
