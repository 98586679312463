import React from 'react';
import {CircularProgress} from '@material-ui/core';
import {useStyles} from './styles';

/**
 * function to render loader on scroll 
 * @param
 * @returns {JSX.Element}
 * @constructor
 */
const ScrollLoader = () => {
    const classes = useStyles();
    return (
        <div className={classes.scrollLoaderContainer}>
            <CircularProgress
                color='inherit'
                className={classes.circularProgressAppLoader}
            />
        </div>
    )
}

export default ScrollLoader;
