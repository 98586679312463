import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        profileContainer: {
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
        },
        profileInnerContainer: {
            height: '40px',
            width: '40px',
        },
        profileImageError: {
            height: '40px',
            width: '40px',
            borderRadius: '100%',
            border: '1px solid rgba(0, 0, 0, 0.2)',
            textAlign: 'center',
        },
        profileImage: {
            height: '40px',
            width: '40px',
            borderRadius: '100%',
            border: '1px solid rgba(0, 0, 0, 0.2)',
        },
        nameContainer: {
            marginLeft: '0.5rem',
        },
        name: {
            fontSize: '1rem',
        },
        roleLabel: {
            marginBottom: '3px',
        },
        deptLabel: {
            fontWeight: '500',
        },
        activeStatus: {
            color: 'green',
        },
        blockStatus: {
            color: 'red',
        },
        tableContainer: {},
        addButtonContainer: {
            marginTop: '10px',
            marginBottom: '10px',
            textAlign: 'right',
        },
        addButton: {
            position: 'absolute',
            marginLeft: '-8.5rem',
            marginTop: '1rem',
            zIndex: '1',
            textTransform: 'capitalize',
        },
    };
});
