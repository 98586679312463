import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        avatarUploadContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0.5rem',
        },
        avatarUploadInnerContainer: {
            position: 'relative',
        },
        uploadAvatar: {
            height: '100px',
            width: '100px',
        },
        errorContainer: {
            marginTop: '5px',
        },
        error: {
            fontSize: '0.75rem',
            color: 'red',
        },
        radioContainer: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '5px',
        },
        radioTextContainer: {
            marginRight: '10px',
        },
        actionsContainer: {
            marginRight: '1rem',
            marginBottom: '0.5rem',
        },
        editIcon: {
            position: 'absolute',
            bottom: '1px',
            right: '-5px',
            border: '1px solid rgba(0, 0, 0, 0.5)',
            borderRadius: '50%',
            height: '35px',
            width: '35px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#fff',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    };
});
