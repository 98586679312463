import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Switch,
  Typography,
} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {AutocompleteValidator} from '../../../../utilities/validators';
import {getSirenUsersWithoutParamsRequest} from '../../../Users/UsersApiActions';
import {assignCaregiverRequest} from '../../HealthcareOrganizationsApiActions';
import defaultUser from '../../../../assets/user.png';
import {useStyles} from './styles';

/**
 * function to render assign caregiver form
 * @param openAssignCaregiverDialog: opens assign caregiver dialog
 * @param setOpenAssignCaregiverDialog: function to change open state
 * @param getAllUsersWithoutParams: function to get get users without params
 * @param usersData: users list
 * @param assignCaregiver: function to assign caregiver
 * @param setRefreshList: function to set refresh list boolean
 * @returns {JSX.Element}
 * @constructor
 */
const AssignCaregiverForm = ({
                                 openAssignCaregiverDialog,
                                 setOpenAssignCaregiverDialog,
                                 getAllUsersWithoutParams,
                                 usersData,
                                 assignCaregiver,
                                 setRefreshList
                             }) => {
    const classes = useStyles();
    const params = useParams();
    const [user, setUser] = useState(null);
    const [clinicalPrivileges, setClinicalPrivileges] = useState(false);

    useEffect(() => {
        if(openAssignCaregiverDialog){
            getAllUsersWithoutParams().then(res => {
            });
        }
    }, [getAllUsersWithoutParams, openAssignCaregiverDialog]);

    /**
     * function to assign caregiver to healthcare organization on button click
     * @param e: event from button click
     */
    const onSubmit = e => {
        e.preventDefault();

        const data = {
            headDoctor: true,
            userId: user.id,
            clinicalPrivileges: clinicalPrivileges,
        };

        assignCaregiver(params.id, data).then(res => {
            setRefreshList(true);
            handleAssignCaregiverDialogClose();
        });
    };

    /**
     * function to close assign organisation dialog on button click
     */
    const handleAssignCaregiverDialogClose = () => {
        setOpenAssignCaregiverDialog(false);
        setUser(null);
        setClinicalPrivileges(false);
    };

    return (
        <Box>
            <Dialog
                fullWidth
                open={openAssignCaregiverDialog}
                onClose={handleAssignCaregiverDialogClose}
            >
                <DialogTitle>Assign Caregiver</DialogTitle>
                <Divider/>

                <ValidatorForm onSubmit={onSubmit}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            {user && (
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box>
                                        <Box className={classes.profileContainer}>
                                            <img
                                                src={user.imageUrl ? user.imageUrl : defaultUser}
                                                alt='profile'
                                                className={classes.profileImage}
                                            />
                                            <Box className={classes.detailsContainer}>
                                                <Typography>
                                                    {user.firstName} {user.lastName}
                                                </Typography>
                                                <Typography className={classes.roleLabel}>
                                                    {user.Roles[0].name}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <AutocompleteValidator
                                    size='small'
                                    value={user}
                                    onChange={(e, value) => {
                                        setUser(value);
                                    }}
                                    options={usersData && usersData.rows ? usersData.rows : []}
                                    getOptionLabel={option => option.email}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    renderInput={params => (
                                        <TextValidator
                                            {...params}
                                            label='User (search by email)'
                                            variant='outlined'
                                        />
                                    )}
                                    validators={['required']}
                                    errorMessages={['user is required']}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box className={classes.toggleContainer}>
                                    <Box>
                                        <Typography variant='inherit'>
                                            Clinical Privileges
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Switch
                                            color='secondary'
                                            checked={clinicalPrivileges}
                                            onChange={e => setClinicalPrivileges(e.target.checked)}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions classes={{root: classes.actionsContainer}}>
                        <Button
                            onClick={handleAssignCaregiverDialogClose}
                            variant='outlined'
                            color='primary'
                        >
                            Cancel
                        </Button>

                        <Button type='submit' color='primary' variant='contained'>
                            Add
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        usersData: state.sirenUsers.users,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllUsersWithoutParams: data => {
            return dispatch(getSirenUsersWithoutParamsRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },

        assignCaregiver: (id, data) => {
            return dispatch(assignCaregiverRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssignCaregiverForm);
