import React, {useEffect, useState} from 'react';
import {Link, NavLink, useRouteMatch} from 'react-router-dom';
import {connect} from 'react-redux';
import MaterialTable from 'material-table';
import {getDeptsForAdminRequest} from '../../DepartmentsApiActions';
import {PAGE_SIZE, PAGE_SIZE_OPTIONS} from '../../../../utilities/constants';
import defaultProfile from '../../../../assets/group.png';
import {getIsTeamList, getUserInfo} from '../../../../utilities/storage';
import {useStyles} from './styles';
import {debounce} from '../../../../utilities/utilityFunctions';
import ImageLoader from 'react-load-image';
import Preloader from '../../../../utilities/components/Preloader/Preloader';
import {Box, Breadcrumbs, FormControl, Icon, MenuItem, Paper, Select, Tooltip, Typography,} from '@material-ui/core';
import {ROLES} from '../../../../utilities/constants';

/**
 * function to render admin departments
 * @param getDeptsForAdmin: function to get departments of an admin
 * @param depts: departments list
 * @returns {JSX.Element}
 * @constructor
 */
const AdminDepartments = ({getDeptsForAdmin, depts}) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const match = useRouteMatch();
    const [paginationParams, setPaginationParams] = useState({
        page: 0,
        rowsPerPage: PAGE_SIZE,
        searchData: {
            name: '',
            active: '',
            desc: '',
        },
    });
    const [isFilterChange, setIsFilterChange] = useState(false);

    /**
     * function to handle table filter search
     * @param filters: filters from filter input to search
     */
    const onTableFilterChange = filters => {
        let searchDataOnFilterChange = Object.assign({}, paginationParams);

        if (filters.length > 0) {
            let obj = {};
            filters.forEach(filter => {
                obj[filter.column.field] = filter.value;
            });

            Object.keys(searchDataOnFilterChange.searchData).forEach(function (key) {
                if (key !== 'active') {
                    if (obj[key]) {
                        searchDataOnFilterChange.searchData[key] = obj[key];
                    } else {
                        searchDataOnFilterChange.searchData[key] = '';
                    }
                }
            });

            filters.forEach(filter => {
                searchDataOnFilterChange.searchData[filter.column.field] = filter.value;
            });
        } else {
            searchDataOnFilterChange.searchData['name'] = '';
            searchDataOnFilterChange.searchData['desc'] = '';
        }

        setIsFilterChange(true);
        setPaginationParams(searchDataOnFilterChange);
    };

    /**
     * function to implement debounce
     * @type {(function(...[*]=): void)|*}
     */
    //eslint-disable-next-line
    const debounceOnFilterChange = React.useCallback(
        debounce(onTableFilterChange, 400),
        []
    );

    useEffect(() => {
        getDeptsForAdmin(paginationParams, isFilterChange).then(res => {
            if (isFilterChange) {
                setIsFilterChange(false);
            }
        });
        //eslint-disable-next-line
    }, [getDeptsForAdmin, paginationParams]);

    /**
     * function to handle status change upon filter
     * @param value: selected status value
     */
    const handleStatusChange = value => {
        let data = Object.assign({}, paginationParams);
        data.searchData.active = value;

        setPaginationParams(data);
    };

    const columns = [
        {
            field: 'name',
            title: 'Name',
            filtering: true,
            defaultFilter: paginationParams.searchData['name'],
            cellStyle: {
                width: '25%',
            },
            headerStyle: {
                width: '25%',
            },
            render: ({imageUrl, name, id}) => {
                return (
                    <Box
                        className={classes.profileContainer}
                        component={Link}
                        to={{
                            pathname: `${match.path.split(':')[0]}/${id}`,
                        }}
                    >
                        <Box className={classes.profileInnerContainer}>
                            <ImageLoader src={imageUrl ? imageUrl : defaultProfile}>
                                <img alt={id} className={classes.profileImage}/>
                                <div className={classes.profileImageError}>{id}</div>
                                <Preloader alt={id} className={classes.profileImage}/>
                            </ImageLoader>
                        </Box>

                        <Box className={classes.nameContainer}>
                            <Typography className={classes.name}>{name}</Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'desc',
            title: 'Description',
            filtering: true,
            defaultFilter: paginationParams.searchData['desc'],
            cellStyle: {
                width: '40%',
            },
            headerStyle: {
                width: '40%',
            },
        },
        {
            field: 'patientCount',
            title: 'Patients',
            filtering: false,
            cellStyle: {
                textAlign: 'center',
                width: '10%',
            },
            headerStyle: {
                textAlign: 'center',
                width: '10%',
            },
            render: ({patientCount}) => {
                return <>{patientCount ? patientCount : '0'}</>;
            },
        },
        {
            field: 'active',
            title: 'Active Status',
            cellStyle: {
                width: '10%',
                textAlign: 'center',
            },
            headerStyle: {
                width: '10%',
                textAlign: 'center',
            },
            filtering: true,
            filterComponent: props => {
                return (
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                value={paginationParams.searchData.active}
                                onChange={e => handleStatusChange(e.target.value)}
                            >
                                <MenuItem value=''>All</MenuItem>
                                <MenuItem value='1'>Active</MenuItem>
                                <MenuItem value='0'>Blocked</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                );
            },
            render: ({active}) => {
                return (
                    <>
                        {active ? (
                            <Tooltip arrow placement='top' title='Active'>
                                <Icon className={classes.statusActive}>
                                    check_circle_outline
                                </Icon>
                            </Tooltip>
                        ) : (
                            <Tooltip arrow placement='top' title='Blocked'>
                                <Icon className={classes.statusBlocked}>block</Icon>
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <Box
            className={
                role !== ROLES.SIRENMD_ADMIN
                    ? classes.outerContainer
                    : ''
            }
        >
            <Box
                className={
                    role === ROLES.SIRENMD_ADMIN
                        ? classes.breadcrumbsContainer
                        : getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainerForAdmins
                        : classes.breadcrumbsContainerForUsers
                }
                component={Paper}
                p={1}
            >
                <Breadcrumbs>
                    <Box
                        component={NavLink}
                        exact
                        to='/departments'
                        className={classes.breadcrumbItem}
                        activeClassName={classes.activeBreadcrumbItem}
                    >
                        <Icon className={classes.breadcrumpIcon}>apartment</Icon>

                        <Box className={classes.breadcrumpLabel}>Departments</Box>
                    </Box>
                </Breadcrumbs>
            </Box>

            <Box mt={3}>
                <Box>
                    <MaterialTable
                        title='Departments'
                        columns={columns}
                        data={depts.rows}
                        options={{
                            search: false,
                            filtering: true,
                            sorting: false,
                            horizontalScroll: true,
                            draggable: false,
                            pageSizeOptions: PAGE_SIZE_OPTIONS,
                            pageSize: paginationParams.rowsPerPage,
                            emptyRowsWhenPaging: false,
                        }}
                        pages={Math.ceil(depts.count / paginationParams.rowsPerPage)}
                        totalCount={depts.count}
                        page={paginationParams.page}
                        onChangeRowsPerPage={pageSize => {
                            let data = Object.assign({}, paginationParams);
                            data['rowsPerPage'] = pageSize;
                            setPaginationParams(data);
                        }}
                        onChangePage={page => {
                            let data = Object.assign({}, paginationParams);
                            data['page'] = page;
                            setPaginationParams(data);
                        }}
                        onFilterChange={filters => {
                            debounceOnFilterChange(filters);
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        depts: state.departments.depts,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getDeptsForAdmin: (data, isHideLoader = false) => {
            return dispatch(getDeptsForAdminRequest(data, isHideLoader)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDepartments);
