import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {isLoggedIn} from '../utilities/storage';

/**
 * function to return private route
 * @param component: component to render as private
 * @param rest: rest of the props
 * @returns {JSX.Element}
 * @constructor
 */
const PrivateRoute = ({component: Component, ...rest}) => {
    return (
            <Route
                {...rest}
                exact
                render={props =>
                    !isLoggedIn() ? <Redirect to='/login'/> : <Component {...props} />
                }
            />
    );
};

export default PrivateRoute;
