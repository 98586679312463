import {
    ACCEPT_AGREEMENT,
    CHANGE_PASSWORD,
    FORGET_PASSWORD,
    SET_PASSWORD,
    SIGN_IN,
    UPDATE_LOGGED_IN_USER,
} from './AuthActions';
import {getUserInfo} from '../../utilities/storage';

const initialState = {
    loggedInUser: getUserInfo() ? getUserInfo() : null,
    agreement: null,
};

/**
 * reducer function to update state based on action
 * @param state: current state of the reducer
 * @param action: action to perform on the data
 * @returns {({agreement: null, loggedInUser: (*|null)} & {loggedInUser})|{agreement: null, loggedInUser: (*|null)}|({agreement: null, loggedInUser: (*|null)} & {agreement: (null|*)})|{loggedInUser}}
 * @constructor
 */
const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_IN:
            return {
                loggedInUser: action.user.user,
            };

        case FORGET_PASSWORD:
            return {
                ...state,
            };

        case SET_PASSWORD:
            return {
                ...state,
            };

        case CHANGE_PASSWORD:
            return {
                ...state,
            };

        case UPDATE_LOGGED_IN_USER: {
            return Object.assign({}, state, {
                loggedInUser: action.user,
            });
        }

        case ACCEPT_AGREEMENT: {
            return Object.assign({}, state, {
                agreement: action.agreement,
            });
        }
        default:
            return state;
    }
};

export default AuthReducer;
