import HttpRequest from '../../utilities/httpRequest';
import {hideLoader, showLoader} from '../../utilities/UtilitiesActions';
import {getUpdates, readUpdate} from './NewUpdatesActions';

/**
 * function to get get updates
 * @param id: id for api request
 * @param offset: offset for api request
 * @param limit: limit for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getUpdatesRequest = (id, offset, limit) => {
    const searchData = {
        teamId: `${id}`,
    };
    return dispatch => {
        dispatch(showLoader());
        const url = `caseNotification?offset=${offset}&limit=${limit}&searchData=${JSON.stringify(
            searchData
        )}`;

        return new HttpRequest(dispatch, url, 'get').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(getUpdates(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};

/**
 * function to read update
 * @param id: id for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const readUpdateRequest = id => {
    return dispatch => {
        dispatch(showLoader());
        const url = `case/notification/${id}`;

        return new HttpRequest(dispatch, url, 'patch').send().then(
            res => {
                dispatch(hideLoader());
                dispatch(readUpdate(res.data.data));
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};
