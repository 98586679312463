export const GET_ATHLETES_FOR_DEPT = 'GET_ATHLETES_FOR_DEPT';

/**
 * action creator function to get athletes of a department
 * @param athletes: athletes from api response data
 * @returns {{athletes, type: string}}
 */
export const getAthletesForDept = athletes => {
    return {
        type: GET_ATHLETES_FOR_DEPT,
        athletes: athletes,
    };
};
