import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        container: {
            marginTop: '0.5rem',
            marginLeft: '5px',
        },
        uploadContainer: {
            marginTop: '1rem',
            marginLeft: '5px',
        },
        text: {
            fontSize: '0.9rem',
        },
        textMain: {
            fontSize: '0.9rem',
            fontWeight: 'bold',
        },
        dropzoneContainer: {
            minHeight: '150px',
        },
        actionsContainer: {
            padding: '0',
            paddingRight: '1rem',
            paddingBottom: '1rem',
        },
        contentContainer: {
            padding: '0',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            paddingBottom: '1rem',
            paddingTop: '0.7rem',
        },
        titleContainer: {
            padding: '0',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            paddingTop: '1rem',
            paddingBottom: '1rem',
        },
        messageText: {
            paddingTop: '10px',
            paddingLeft: '15px',
            marginBottom: '5px',
        },
    };
});
