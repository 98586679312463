import {
    ADD_VENDOR,
    GET_ALL_SYSTEMS,
    GET_VENDOR_BY_ID,
    GET_VENDOR_TYPES,
    GET_VENDORS,
    GET_VENDORS_FOR_HC_ORG,
    GET_VENDORS_WITHOUT_PARAMS,
    TOGGLE_VENDOR_ACTIVATION,
    UPDATE_VENDOR,
} from './IntegrationVendorsActions';

const initialState = {
    vendors: [],
    vendor: null,
    vendorTypes: null,
    vendorsForHCOrg: [],
    systems: null,
};

/**
 * reducer function to update state based on action
 * @param state: current state of the reducer
 * @param action: action to perform on the data
 * @returns {({vendorTypes: null, systems: null, vendor: null, vendors: *[], vendorsForHCOrg: *[]} & {systems: (null|*)})|({vendorTypes: null, systems: null, vendor: null, vendors: *[], vendorsForHCOrg: *[]} & {vendorsForHCOrg: ([]|*)})|({vendorTypes: null, systems: null, vendor: null, vendors: *[], vendorsForHCOrg: *[]} & {vendor})|{vendorTypes: null, systems: null, vendor: {active}, vendors: *[], vendorsForHCOrg: *[]}|({vendorTypes: null, systems: null, vendor: null, vendors: *[], vendorsForHCOrg: *[]} & {vendors: ([]|*)})|({vendorTypes: null, systems: null, vendor: null, vendors: *[], vendorsForHCOrg: *[]} & {vendorTypes: (null|*)})|{vendorTypes: null, systems: null, vendor: null, vendors: *[], vendorsForHCOrg: *[]}}
 * @constructor
 */
const OrganisationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_VENDORS:
            return Object.assign({}, state, {
                vendors: action.vendors,
            });

        case GET_VENDORS_FOR_HC_ORG:
            return Object.assign({}, state, {
                vendorsForHCOrg: action.vendorsForHCOrg,
            });

        case GET_VENDORS_WITHOUT_PARAMS:
            return Object.assign({}, state, {
                vendors: action.vendors,
            });

        case ADD_VENDOR:
            return Object.assign({}, state, {
                vendor: action.vendor,
            });

        case GET_VENDOR_BY_ID:
            return Object.assign({}, state, {
                vendor: action.vendor,
            });

        case UPDATE_VENDOR:
            return Object.assign({}, state, {
                vendor: action.vendor,
            });

        case GET_VENDOR_TYPES:
            return Object.assign({}, state, {
                vendorTypes: action.vendorTypes,
            });

        case TOGGLE_VENDOR_ACTIVATION:
            return {
                ...state,
                vendor: {
                    ...state.vendor,
                    active: action.vendor.active,
                },
            };
        case GET_ALL_SYSTEMS:
            return Object.assign({}, state, {
                systems: action.systems,
            });

        default:
            return state;
    }
};

export default OrganisationsReducer;
