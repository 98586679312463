import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, NavLink} from 'react-router-dom';
import MaterialTable from 'material-table';
import {
    Box,
    Breadcrumbs,
    Button,
    FormControl,
    Icon,
    MenuItem,
    Paper,
    Select,
    Tooltip,
    Typography,
} from '@material-ui/core';
import {PAGE_SIZE, PAGE_SIZE_OPTIONS} from '../../../../utilities/constants';
import {getHCOrganisationsRequest} from '../../HealthcareOrganizationsApiActions';
import {useStyles} from './styles';
import defaultProfile from '../../../../assets/group.png';
import {debounce} from '../../../../utilities/utilityFunctions';
import {getIsTeamList, getUserInfo} from '../../../../utilities/storage';
import ImageLoader from 'react-load-image';
import Preloader from '../../../../utilities/components/Preloader/Preloader';
import {ROLES} from '../../../../utilities/constants';

/**
 * function to render organizations of a healthcare organization
 * @param getAllHCOrgs: function to get healthcare organizations
 * @param hcorgs: healthcare organizations list
 * @returns {JSX.Element}
 * @constructor
 */
const HealthcareOrganisations = ({getAllHCOrgs, hcorgs}) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const [paginationParams, setPaginationParams] = useState({
        page: 0,
        rowsPerPage: PAGE_SIZE,
        searchData: {
            name: '',
            desc: '',
            active: '',
        },
    });
    const [isFilterChange, setIsFilterChange] = useState(false);

    /**
     * function to handle table filter search
     * @param filters: filters from filter input to search
     */
    const onTableFilterChange = filters => {
        let searchDataOnFilterChange = Object.assign({}, paginationParams);

        if (filters.length > 0) {
            let obj = {};
            filters.forEach(filter => {
                obj[filter.column.field] = filter.value;
            });

            Object.keys(searchDataOnFilterChange.searchData).forEach(function (key) {
                if (key !== 'active') {
                    if (obj[key]) {
                        searchDataOnFilterChange.searchData[key] = obj[key];
                    } else {
                        searchDataOnFilterChange.searchData[key] = '';
                    }
                }
            });

            filters.forEach(filter => {
                searchDataOnFilterChange.searchData[filter.column.field] = filter.value;
            });
        } else {
            searchDataOnFilterChange.searchData['name'] = '';
            searchDataOnFilterChange.searchData['desc'] = '';
        }

        setIsFilterChange(true);
        setPaginationParams(searchDataOnFilterChange);
    };

    /**
     * function to implement debounce
     * @type {(function(...[*]=): void)|*}
     */
    //eslint-disable-next-line
    const debounceOnFilterChange = React.useCallback(
        debounce(onTableFilterChange, 400),
        []
    );

    useEffect(() => {
        getAllHCOrgs(paginationParams, isFilterChange).then(res => {
            if (isFilterChange) {
                setIsFilterChange(false);
            }
        });
        //eslint-disable-next-line
    }, [paginationParams, getAllHCOrgs]);

    /**
     * function to handle status change upon filter
     * @param value: selected status value
     */
    const handleStatusChange = value => {
        let data = Object.assign({}, paginationParams);
        data.searchData.active = value;

        setPaginationParams(data);
    };

    const columns = [
        {
            field: 'name',
            title: 'Name',
            filtering: true,
            defaultFilter: paginationParams.searchData['name'],
            cellStyle: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            },
            render: ({imageUrl, name, id}) => {
                return (
                    <Box
                        className={classes.profileContainer}
                        component={Link}
                        to={`/healthcareOrgs/${id}`}
                    >
                        <Box className={classes.profileInnerContainer}>
                            <ImageLoader src={imageUrl ? imageUrl : defaultProfile}>
                                <img alt={id} className={classes.profileImage}/>
                                <div className={classes.profileImageError}>{id}</div>
                                <Preloader alt={id} className={classes.profileImage}/>
                            </ImageLoader>
                        </Box>

                        <Box className={classes.nameContainer}>
                            <Typography classes={{root: classes.name}}>{name}</Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'desc',
            title: 'Description',
            filtering: true,
            defaultFilter: paginationParams.searchData['desc'],
            cellStyle: {
                width: '25%',
            },
            headerStyle: {
                width: '25%',
            },
        },
        {
            field: 'npi',
            title: 'NPI Number',
            filtering: false,
            cellStyle: {
                width: '10%',
            },
            headerStyle: {
                width: '10%',
            },
        },
        {
            field: 'totalvendors',
            title: 'Integration Vendors',
            filtering: false,
            cellStyle: {
                width: '13%',
                textAlign: 'center',
            },
            headerStyle: {
                width: '13%',
                textAlign: 'center',
            },
            render: ({totalvendors}) => {
                return <>{totalvendors ? totalvendors : 0}</>;
            },
        },
        {
            field: 'totalCaregivers',
            title: 'Caregivers',
            filtering: false,
            cellStyle: {
                width: '5%',
                textAlign: 'center',
            },
            headerStyle: {
                width: '5%',
                textAlign: 'center',
            },
            render: ({totalCaregivers}) => {
                return <>{totalCaregivers ? totalCaregivers : 0}</>;
            },
        },
        {
            field: 'totalAthelets',
            title: 'Patients',
            filtering: false,
            cellStyle: {
                width: '5%',
                textAlign: 'center',
            },
            headerStyle: {
                width: '5%',
                textAlign: 'center',
            },
            render: ({totalAthelets}) => {
                return <>{totalAthelets ? totalAthelets : 0}</>;
            },
        },
        {
            field: 'active',
            title: 'Active',
            cellStyle: {
                width: '10%',
                textAlign: 'center',
            },
            headerStyle: {
                width: '10%',
                textAlign: 'center',
            },
            filtering: true,
            filterComponent: props => {
                return (
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                value={paginationParams.searchData.active}
                                onChange={e => handleStatusChange(e.target.value)}
                            >
                                <MenuItem value=''>All</MenuItem>
                                <MenuItem value='1'>Active</MenuItem>
                                <MenuItem value='0'>Blocked</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                );
            },
            render: ({active}) => {
                return (
                    <>
                        {active ? (
                            <Tooltip arrow placement='top' title='Active'>
                                <Icon className={classes.statusActive}>
                                    check_circle_outline
                                </Icon>
                            </Tooltip>
                        ) : (
                            <Tooltip arrow placement='top' title='Blocked'>
                                <Icon className={classes.statusBlocked}>block</Icon>
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <Box
            className={
                role === ROLES.SIRENMD_ADMIN &&
                getIsTeamList() === 'false'
                    ? ''
                    : classes.outerContainer
            }
        >
            <Box
                className={
                    role === ROLES.SIRENMD_ADMIN &&
                    getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainer
                        : getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainerForAdmins
                        : classes.breadcrumbsContainerForUsers
                }
                component={Paper}
                p={1}
            >
                <Breadcrumbs>
                    <Box
                        component={NavLink}
                        exact
                        to='/healthcareOrgs'
                        className={classes.breadcrumbItem}
                        activeClassName={classes.activeBreadcrumbItem}
                    >
                        <Icon className={classes.breadcrumpIcon}>local_hospital</Icon>

                        <Box className={classes.breadcrumpLabel}>
                            Healthcare Organizations
                        </Box>
                    </Box>
                </Breadcrumbs>
            </Box>

            <Box mt={3}>
                <Box className={classes.addButtonContainer}>
                    <Button
                        variant='contained'
                        color='primary'
                        startIcon={<Icon>add</Icon>}
                        disableElevation={true}
                        component={Link}
                        to='/healthcareOrgs/add'
                        classes={{root: classes.addButton}}
                    >
                        Healthcare Organization
                    </Button>
                </Box>

                <Box>
                    <MaterialTable
                        title='Healthcare Organizations'
                        columns={columns}
                        data={hcorgs && hcorgs.rows}
                        options={{
                            search: false,
                            filtering: true,
                            sorting: false,
                            horizontalScroll: true,
                            draggable: false,
                            pageSizeOptions: PAGE_SIZE_OPTIONS,
                            pageSize: paginationParams.rowsPerPage,
                            emptyRowsWhenPaging: false,
                        }}
                        pages={Math.ceil(
                            hcorgs && hcorgs.count / paginationParams.rowsPerPage
                        )}
                        totalCount={hcorgs && hcorgs.count}
                        page={paginationParams.page}
                        onChangeRowsPerPage={pageSize => {
                            let data = Object.assign({}, paginationParams);
                            data['rowsPerPage'] = pageSize;
                            setPaginationParams(data);
                        }}
                        onChangePage={page => {
                            let data = Object.assign({}, paginationParams);
                            data['page'] = page;
                            setPaginationParams(data);
                        }}
                        onFilterChange={(filters, filter) => {
                            debounceOnFilterChange(filters);
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        hcorgs: state.hcorganisations.hcorgs,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllHCOrgs: (data, isHideLoader = false) => {
            return dispatch(getHCOrganisationsRequest(data, isHideLoader)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HealthcareOrganisations);
