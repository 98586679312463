import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import InputMask from 'react-input-mask';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {NavLink, useParams, useRouteMatch} from 'react-router-dom';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    FormControl,
    FormControlLabel,
    Grid,
    Icon,
    Paper,
    Radio,
    Typography
} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {AutocompleteValidator, RadioGroupValidator} from '../../../utilities/validators';
import UtilHelper from '../../../utilities/validators/UtilHelper';
import {
    addPatientRequest,
    getPatientByIdRequest,
    getSignedUrlRequest,
    updatePatientRequest,
    uploadToS3Request,
} from '../PatientsApiActions';
import {getStatesRequest} from '../../Users/UsersApiActions';
import {useStyles} from './styles';
import ConfirmationDialog from '../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import {getOrgByIdRequest} from '../../Organizations/OrganisationsApiActions';
import ImageField from '../../../utilities/components/ImageField/ImageField';
import {getIsTeamList, getUserInfo} from '../../../utilities/storage';
import {dataURLtoFile} from '../../../utilities/utilityFunctions';
import { ROLES } from '../../../utilities/constants';

/**
 * function to render patient form
 * @param location: location from router
 * @param history: history from router
 * @param getStates: function to get states
 * @param states: states list
 * @param getOrgById: function to get organization by id
 * @param org: organization details
 * @param addPatient: function to add patient
 * @param getPatientById: function to get patient by id
 * @param updatePatient: function to update patient
 * @param getSignedUrl: function to get signed url
 * @param uploadToS3: function to upload file on signed url
 * @returns {JSX.Element}
 * @constructor
 */
const PatientForm = ({
                         location,
                         history,
                         getStates,
                         states,
                         getOrgById,
                         org,
                         addPatient,
                         getPatientById,
                         updatePatient,
                         getSignedUrl,
                         uploadToS3,
                     }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const params = useParams();
    const match = useRouteMatch();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [aliasFirstName, setAliasFirstName] = useState('');
    const [aliasLastName, setAliasLastName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [socialSecurityNumber, setSocialSecurityNumber] = useState('');
    const [gender, setGender] = useState('');
    const [vip, setVip] = useState('No');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [selectedState, setSelectedState] = useState(null);
    const [zipCode, setZipCode] = useState('');
    const [athleteRmsPlayerId, setAthleteRmsPlayerId] = useState('');
    const [position, setPosition] = useState('');
    const [jursyNumber, setJursyNumber] = useState('');
    const [mrn, setMrn] = useState('');
    const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);
    const [rowImageFile, setRowImageFile] = useState(null);
    const [cropper, setCropper] = useState(null);
    const [croppedImageFile, setCroppedImageFile] = useState(null);
    const [showImageEditor, setShowImageEditor] = useState(false);
    const currentUrlPath = match.path.split(':')[0];

    useEffect(() => {
        ValidatorForm.addValidationRule('isBlank', value =>
            UtilHelper.validateBlankSpace(value)
        );

        ValidatorForm.addValidationRule('isZipCode', value =>
            UtilHelper.validateZpiCode(value)
        );

        ValidatorForm.addValidationRule('isValidSSN', value =>
            UtilHelper.validateSocialSecurityNumber(value)
        );

        return () => {
            ValidatorForm.removeValidationRule('isBlank');
            ValidatorForm.removeValidationRule('isZipCode');
            ValidatorForm.removeValidationRule('isValidSSN');
        };
    }, []);

    useEffect(() => {
        if (location) {
            getOrgById(location.state.orgIdHistory).then(res => {
            });
        }
    }, [getOrgById, location]);

    useEffect(() => {
        getStates().then(res => {
        });
    }, [getStates]);

    useEffect(() => {
        if (params.id) {
            getPatientById(params.id).then(res => {
                setCroppedImageFile(res.imageUrl);
                setFirstName(res.firstName);
                setLastName(res.lastName);
                setAliasFirstName(res.aliasFirstName);
                setAliasLastName(res.aliasLastName);
                setDateOfBirth(res.dob ? new Date(res.dob) : null);
                setGender(res.gender);
                setSocialSecurityNumber(res.socialSecurityNumber);
                setVip(res.vip);
                setAddressLine1(res.addressLine1);
                setAddressLine2(res.addressLine2);
                setCity(res.city);
                setSelectedState(null);
                setZipCode(res.zipCode);
                setAthleteRmsPlayerId(res.athleteRMSId);
                setPosition(res.position);
                setJursyNumber(res.jerseyNum);
                setMrn(res.mrn);
                setSelectedState(res.State);
            });
        }
    }, [params.id, getPatientById]);

    const breadCrumbDataForAddOrgDeptPatientForm = [
        {
            icon: 'business',
            name: 'Organizations',
            path: '/orgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/orgs/${location.state && location.state.orgIdHistory}`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/orgs/departments/${
                location.state && location.state.deptIdHistory
            }`,
        },
        {
            name: `Add Patient`,
            path: `/orgs/departments/addPatient`,
        },
    ];

    const breadCrumbDataForUpdateOrgDeptPatientForm = [
        {
            icon: 'business',
            name: 'Organizations',
            path: '/orgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/orgs/${location.state && location.state.orgIdHistory}`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/orgs/departments/${
                location.state && location.state.deptIdHistory
            }`,
        },
        {
            name: `Update Patient`,
            path: `/orgs/departments/patients/edit/${params.id}`,
        },
    ];

    const breadCrumbDataForAddHcorgOrgDeptPatientForm = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: '/healthcareOrgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/${
                location.state && location.state.orgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/departments/${
                location.state && location.state.deptIdHistory
            }`,
        },
        {
            name: `Add Patient`,
            path: `/healthcareOrgs/orgs/departments/addPatient`,
        },
    ];

    const breadCrumbDataForUpdateHcorgOrgDeptPatientForm = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: '/healthcareOrgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.orgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/${
                location.state && location.state.orgIdHistory
            }`,
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/orgs/departments/${
                location.state && location.state.deptIdHistory
            }`,
        },
        {
            name: `Update Patient`,
            path: `/healthcareOrgs/orgs/departments/patients/edit/${params.id}`,
        },
    ];

    const breadCrumbDataForAddAdminDeptPatientForm = [
        {
            icon: 'apartment',
            name: 'Departments',
            path: '/departments',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/departments/${location.state && location.state.deptIdHistory}`,
        },
        {
            name: `Add Patient`,
            path: `/departments/addPatient`,
        },
    ];

    const breadCrumbDataForUpdateAdminDeptPatientForm = [
        {
            icon: 'apartment',
            name: 'Departments',
            path: '/departments',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.deptNameHistory}`
                    : ''
            }`,
            path: `/departments/${location.state && location.state.deptIdHistory}`,
        },
        {
            name: `Update Patient`,
            path: `/departments/patients/edit/${params.id}`,
        },
    ];

    /**
     * function to get breadcrumb data for current url
     * @returns {[{path: string, icon: string, name: string}, {path: string, name: string}, {path: string, name: string}, {path: string, name: string}, {path: string, name: string}]|[{path: string, icon: string, name: string}, {path: string, name: string}, {path: string, name: string}]|[{path: string, icon: string, name: string}, {path: string, name: string}, {path: string, name: string}, {path: string, name: string}]|*[]}
     */
    const getBreadcrumbData = (urlPath) => {
        switch(urlPath){
            case '/orgs/departments/addPatient':{
                return breadCrumbDataForAddOrgDeptPatientForm
            }
            case '/orgs/departments/patients/edit/':{
               return breadCrumbDataForUpdateOrgDeptPatientForm
            }
            case '/healthcareOrgs/orgs/departments/addPatient':{
                return breadCrumbDataForAddHcorgOrgDeptPatientForm
            }
            case '/healthcareOrgs/orgs/departments/patients/edit/':{
               return breadCrumbDataForUpdateHcorgOrgDeptPatientForm
            }
            case '/departments/addPatient':{
                return breadCrumbDataForAddAdminDeptPatientForm
            }
            case '/departments/patients/edit/':{
               return breadCrumbDataForUpdateAdminDeptPatientForm
            }
            default: {
                return []
            }
        }
    }

    /**
     * function to handle image crop
     */
    const onImageCrop = () => {
        const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL();
        const croppedImageFileObject = dataURLtoFile(
            croppedImageDataUrl,
            rowImageFile[0].file.name
        );

        const data = {
            dataURL: croppedImageDataUrl,
            file: croppedImageFileObject,
        };

        setCroppedImageFile(data);
        setShowImageEditor(false);
    };

    /**
     * function to add patient on button click
     * @param e: event from button click
     */
    const onAdd = e => {
        e.preventDefault();

        const data = {
            firstName: firstName,
            lastName: lastName,
            dob: dateOfBirth ? moment(dateOfBirth).format('MM/DD/YYYY') : '',
            gender: gender,
            socialSecurityNumber: socialSecurityNumber,
            vip: vip,
            aliasFirstName: aliasFirstName,
            aliasLastName: aliasLastName,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            TeamId: location.state.deptIdHistory,
            city: city,
            StateId: selectedState && selectedState.id,
            zipCode: zipCode,
        };

        if (org.typeId === 1) {
            data['mrn'] = mrn;
        }

        if (org.typeId !== 1) {
            data['jerseyNum'] = jursyNumber ? jursyNumber : null;
            data['position'] = position;
            data['athleteRMSId'] = athleteRmsPlayerId;
        }

        if (croppedImageFile) {
            const imageData = {
                fileName: croppedImageFile.file.name,
                fileType: `.${croppedImageFile.file.name.split('.').pop()}`,
            };

            getSignedUrl(imageData).then(res => {
                uploadToS3(res.urls, croppedImageFile.file).then(uploadRes => {
                    data['imageUrl'] = res.path;
                    addPatient(data).then(res => {
                        history.goBack();
                    });
                });
            });
        } else {
            addPatient(data).then(res => {
                history.goBack();
            });
        }
    };

    /**
     * function to update patient on button click
     * @param e: event from button click
     */
    const onUpdate = e => {
        e.preventDefault();

        if (typeof croppedImageFile !== 'string') {
            const imageData = {
                fileName: croppedImageFile.file.name,
                fileType: `.${croppedImageFile.file.name.split('.').pop()}`,
            };

            getSignedUrl(imageData).then(res => {
                uploadToS3(res.urls, croppedImageFile.file).then(uploadRes => {
                    const data = {
                        firstName: firstName,
                        lastName: lastName,
                        imageUrl: res.path,
                        dob: dateOfBirth ? dateOfBirth : '',
                        gender: gender,
                        socialSecurityNumber: socialSecurityNumber,
                        vip: vip,
                        aliasFirstName: aliasFirstName,
                        aliasLastName: aliasLastName,
                        addressLine1: addressLine1,
                        addressLine2: addressLine2,
                        city: city,
                        StateId: selectedState && selectedState.id,
                        zipCode: zipCode,
                    };

                    if (org.typeId === 1) {
                        data['mrn'] = mrn;
                    }

                    if (org.typeId !== 1) {
                        data['jerseyNum'] = jursyNumber ? jursyNumber : null;
                        data['position'] = position;
                        data['athleteRMSId'] = athleteRmsPlayerId;
                    }

                    updatePatient(params.id, data).then(res => {
                        history.goBack();
                    });
                });
            });
        } else {
            const data = {
                firstName: firstName,
                lastName: lastName,
                dob: dateOfBirth ? dateOfBirth : '',
                gender: gender,
                socialSecurityNumber: socialSecurityNumber,
                vip: vip,
                aliasFirstName: aliasFirstName,
                aliasLastName: aliasLastName,
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                StateId: selectedState && selectedState.id,
                zipCode: zipCode,
            };

            if (org.typeId === 1) {
                data['mrn'] = mrn;
            }

            if (org.typeId !== 1) {
                data['jerseyNum'] = jursyNumber ? jursyNumber : null;
                data['position'] = position;
                data['athleteRMSId'] = athleteRmsPlayerId;
            }

            updatePatient(params.id, data).then(res => {
                history.goBack();
            });
        }
    };

    /**
     * function to handle cancel add or update
     */
    const handleCancel = () => {
        history.goBack();
    };

    return (
        <Box
            className={
                role === ROLES.SIRENMD_ADMIN &&
                getIsTeamList() === 'false'
                    ? ''
                    : classes.outerContainer
            }
        >
            <Box
                className={
                    role === ROLES.SIRENMD_ADMIN &&
                    getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainer
                        : getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainerForAdmins
                        : classes.breadcrumbsContainerForUsers
                }
                component={Paper}
                p={1}
            >
                <Breadcrumbs>
                    {getBreadcrumbData(currentUrlPath).map((crumb, index) => (
                        <Box
                            key={index}
                            component={NavLink}
                            exact
                            to={{
                                pathname: crumb.path,
                                state: {
                                    orgIdHistory: location.state && location.state.orgIdHistory,
                                    orgNameHistory:
                                        location.state && location.state.orgNameHistory,
                                    deptIdHistory: location.state && location.state.deptIdHistory,
                                    deptNameHistory:
                                        location.state && location.state.deptNameHistory,
                                    hcorgIdHistory:
                                        location.state && location.state.hcorgIdHistory,
                                    hcorgNameHistory:
                                        location.state && location.state.hcorgNameHistory,
                                },
                            }}
                            className={classes.breadcrumbItem}
                            activeClassName={classes.activeBreadcrumbItem}
                        >
                            {crumb.icon && (
                                <Icon className={classes.breadcrumpIcon}>{crumb.icon}</Icon>
                            )}
                            <Box className={classes.breadcrumpLabel}>{crumb.name}</Box>
                        </Box>
                    ))}
                </Breadcrumbs>
            </Box>

            <Box mt={2}>
                <ValidatorForm
                    noValidate
                    autoComplete='off'
                    onSubmit={params.id ? onUpdate : onAdd}
                >
                    <Box className={classes.container}>
                        <Box className={classes.labelContainer}>
                            <Typography className={classes.label}>
                                {params.id ? 'Update' : 'Create'} Patient
                            </Typography>
                        </Box>

                        <Box className={classes.formContainer}>
                            <Box component={Paper} className={classes.formInnerContainer}>
                                <Box className={classes.formPatientDetailsLabel}>
                                    <Typography variant='h6'>Patient Details</Typography>
                                </Box>
                                <Grid container spacing={2} justify='space-around'>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <Box>
                                            <ImageField
                                                rowImageFile={rowImageFile}
                                                setRowImageFile={setRowImageFile}
                                                showImageEditor={showImageEditor}
                                                setShowImageEditor={setShowImageEditor}
                                                setCropper={setCropper}
                                                croppedImageFile={croppedImageFile}
                                                onImageCrop={onImageCrop}
                                                options={{
                                                    shape: 'circle',
                                                }}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextValidator
                                                    label='First Name *'
                                                    variant='outlined'
                                                    fullWidth
                                                    size='small'
                                                    value={firstName}
                                                    onChange={e => setFirstName(e.target.value)}
                                                    validators={['required', 'isBlank']}
                                                    errorMessages={[
                                                        'First name is required',
                                                        'Please enter firstname',
                                                    ]}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextValidator
                                                    label='Last Name *'
                                                    variant='outlined'
                                                    fullWidth
                                                    size='small'
                                                    value={lastName}
                                                    onChange={e => setLastName(e.target.value)}
                                                    validators={['required', 'isBlank']}
                                                    errorMessages={[
                                                        'Last name is required',
                                                        'Please enter lastname',
                                                    ]}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <DatePicker
                                                        className='custom-date-picker'
                                                        placeholderText='Date of Birth'
                                                        dateFormat="MM/dd/yyyy"
                                                        maxDate={new Date()}
                                                        popperPlacement="bottom"
                                                        selected={dateOfBirth} onChange={date => {
                                                            setDateOfBirth(date)
                                                        }}
                                                    />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Box className={classes.genderContainer}>
                                                    <Box className={classes.genderInnerContainer}>
                                                        <Typography
                                                            variant='inherit'
                                                            className={classes.genderLabel}
                                                        >
                                                            Gender * :
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <FormControl>
                                                            <RadioGroupValidator
                                                                row
                                                                value={gender}
                                                                onChange={e => setGender(e.target.value)}
                                                                validators={['required']}
                                                                errorMessages={['Gender is required']}
                                                            >
                                                                <FormControlLabel
                                                                    value='Male'
                                                                    control={<Radio color='primary'/>}
                                                                    label='Male'
                                                                />
                                                                <FormControlLabel
                                                                    value='Female'
                                                                    control={<Radio color='primary'/>}
                                                                    label='Female'
                                                                />
                                                            </RadioGroupValidator>
                                                        </FormControl>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <InputMask
                                                    mask='999-99-9999'
                                                    value={socialSecurityNumber}
                                                    onChange={e =>
                                                        setSocialSecurityNumber(e.target.value)
                                                    }
                                                    disabled={false}
                                                    maskChar='_'
                                                >
                                                    {() => (
                                                        <TextValidator
                                                            label='Social Security Number'
                                                            variant='outlined'
                                                            fullWidth
                                                            size='small'
                                                            validators={['isValidSSN']}
                                                            errorMessages={[
                                                                'Please enter valid social security number',
                                                            ]}
                                                        />
                                                    )}
                                                </InputMask>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Box className={classes.vipContainer}>
                                                    <Box className={classes.vipInnerContainer}>
                                                        <Typography
                                                            variant='inherit'
                                                            className={classes.vipLabel}
                                                        >
                                                            VIP :
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <FormControl>
                                                            <RadioGroupValidator
                                                                row
                                                                value={vip}
                                                                onChange={e => setVip(e.target.value)}
                                                                validators={['required']}
                                                                errorMessages={['This field is required']}
                                                            >
                                                                <FormControlLabel
                                                                    value='Yes'
                                                                    control={<Radio color='primary'/>}
                                                                    label='Yes'
                                                                />
                                                                <FormControlLabel
                                                                    value='No'
                                                                    control={<Radio color='primary'/>}
                                                                    label='No'
                                                                />
                                                            </RadioGroupValidator>
                                                        </FormControl>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            {vip === 'Yes' ? (
                                                <>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <TextValidator
                                                            label='Alias First Name'
                                                            variant='outlined'
                                                            fullWidth
                                                            size='small'
                                                            value={aliasFirstName}
                                                            onChange={e => setAliasFirstName(e.target.value)}
                                                            validators={['required', 'isBlank']}
                                                            errorMessages={[
                                                                'Alias firstname is required',
                                                                'Please enter alias firstname',
                                                            ]}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <TextValidator
                                                            label='Alias Last Name'
                                                            variant='outlined'
                                                            fullWidth
                                                            size='small'
                                                            value={aliasLastName}
                                                            onChange={e => setAliasLastName(e.target.value)}
                                                            validators={['required', 'isBlank']}
                                                            errorMessages={[
                                                                'Alias lastname is required',
                                                                'Please enter alias lastname',
                                                            ]}
                                                        />
                                                    </Grid>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box component={Paper} className={classes.addInfoContainer}>
                                <Box className={classes.addInfoLabel}>
                                    <Typography variant='h6'>Address Information</Typography>
                                </Box>

                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.addInfoContentContainer}
                                >
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='Address Line 1'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={addressLine1}
                                            onChange={e => setAddressLine1(e.target.value)}
                                            validators={['isBlank']}
                                            errorMessages={['Please enter address line 1']}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='Address Line 2'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={addressLine2}
                                            onChange={e => setAddressLine2(e.target.value)}
                                            validators={['isBlank']}
                                            errorMessages={['Please enter address line 2']}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='City'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={city}
                                            onChange={e => setCity(e.target.value)}
                                            validators={['isBlank']}
                                            errorMessages={['Please enter city']}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <AutocompleteValidator
                                            fullWidth
                                            size='small'
                                            value={selectedState}
                                            onChange={(e, value) => {
                                                setSelectedState(value);
                                            }}
                                            options={states}
                                            getOptionLabel={option => {
                                                return `${option.initial} - ${option.desc
                                                    .charAt(0)
                                                    .toUpperCase()}${option.desc.slice(1)}`;
                                            }}
                                            getOptionSelected={(option, value) =>
                                                option.id === value.id
                                            }
                                            renderInput={params => (
                                                <TextValidator
                                                    {...params}
                                                    label='Select State'
                                                    variant='outlined'
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextValidator
                                            label='Zip Code'
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            value={zipCode}
                                            onChange={e => setZipCode(e.target.value)}
                                            validators={['isZipCode']}
                                            errorMessages={['Please enter valid zip code']}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box component={Paper} className={classes.otherInfoContainer}>
                                <Box className={classes.otherInfoLabel}>
                                    <Typography variant='h6'>Other Information</Typography>
                                </Box>

                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.otherInfoContentContainer}
                                >
                                    {org && org.typeId === 1 && (
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <TextValidator
                                                label='Medical Record Number'
                                                variant='outlined'
                                                fullWidth
                                                size='small'
                                                value={mrn}
                                                onChange={e => setMrn(e.target.value)}
                                            />
                                        </Grid>
                                    )}

                                    {org && org.typeId !== 1 && (
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextValidator
                                                label='Athlete RMS Player ID'
                                                variant='outlined'
                                                fullWidth
                                                size='small'
                                                value={athleteRmsPlayerId}
                                                onChange={e => setAthleteRmsPlayerId(e.target.value)}
                                            />
                                        </Grid>
                                    )}

                                    {org && org.typeId !== 1 && (
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextValidator
                                                label='Position'
                                                variant='outlined'
                                                fullWidth
                                                size='small'
                                                value={position}
                                                onChange={e => setPosition(e.target.value)}
                                            />
                                        </Grid>
                                    )}

                                    {org && org.typeId !== 1 && (
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <TextValidator
                                                label='Jersey Number'
                                                variant='outlined'
                                                fullWidth
                                                size='small'
                                                value={jursyNumber}
                                                onChange={e => setJursyNumber(e.target.value)}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        </Box>
                    </Box>

                    <Box className={classes.footerActionsButtonsContainer}>
                        <Box
                            component={Card}
                            raised
                            className={classes.footerActionsButtonsInnerContainer}
                        >
                            <Box>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    disableElevation={true}
                                    onClick={() => setOpenCancelConfirmation(true)}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    variant='contained'
                                    color='primary'
                                    disableElevation={true}
                                    type='submit'
                                    className={classes.saveButton}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </ValidatorForm>
            </Box>

            <Box>
                <ConfirmationDialog
                    open={openCancelConfirmation}
                    setOpen={setOpenCancelConfirmation}
                    title='Cancel'
                    message='Are you sure you want to cancel?'
                    onConfirm={handleCancel}
                />
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        states: state.sirenUsers.states,
        org: state.organisations.org,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addPatient: data => {
            return dispatch(addPatientRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getPatientById: id => {
            return dispatch(getPatientByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updatePatient: (id, data) => {
            return dispatch(updatePatientRequest(id, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getSignedUrl: data => {
            return dispatch(getSignedUrlRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        uploadToS3: (url, file) => {
            return dispatch(uploadToS3Request(url, file)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getStates: () => {
            return dispatch(getStatesRequest()).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getOrgById: id => {
            return dispatch(getOrgByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientForm);
