export const GET_CAREGIVER_BATCHES = 'GET_CAREGIVER_BATCHES';
export const CREATE_CAREGIVER_BATCH = 'CREATE_CAREGIVER_BATCH';
export const CREATE_PATIENT_BATCH = 'CREATE_PATIENT_BATCH';
export const SAVE_CAREGIVER_BATCH = 'SAVE_CAREGIVER_BATCH';
export const SAVE_PATIENT_BATCH = 'SAVE_PATIENT_BATCH';
export const DELETE_BATCH = 'DELETE_BATCH';

/**
 * action creator function to get caregiver batches
 * @param batches: batches from api response data
 * @returns {{batches, type: string}}
 */
export const getCaregiverBatches = batches => {
    return {
        type: GET_CAREGIVER_BATCHES,
        batches: batches,
    };
};

/**
 * action creator function to create caregiver batch
 * @param batch: batch from api response data
 * @returns {{batch, type: string}}
 */
export const createCaregiverBatch = batch => {
    return {
        type: CREATE_CAREGIVER_BATCH,
        batch: batch,
    };
};

/**
 * action creator function to create patient batch
 * @param batch: batch from api response data
 * @returns {{batch, type: string}}
 */
export const createPatientBatch = batch => {
    return {
        type: CREATE_PATIENT_BATCH,
        batch: batch,
    };
};

/**
 * action creator function to save caregiver batch
 * @param batch: batch from api response data
 * @returns {{batch, type: string}}
 */
export const saveCaregiverBatch = batch => {
    return {
        type: SAVE_CAREGIVER_BATCH,
        batch: batch,
    };
};

/**
 * action creator function to save patient batch
 * @param batch: batch from api response data
 * @returns {{batch, type: string}}
 */
export const savePatientBatch = batch => {
    return {
        type: SAVE_PATIENT_BATCH,
        batch: batch,
    };
};

/**
 * action creator function to delete batch
 * @param batch: batch from api response data
 * @returns {{batch, type: string}}
 */
export const deleteBatch = batch => {
    return {
        type: DELETE_BATCH,
        batch: batch,
    };
};
