import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import MaterialTable from 'material-table';
import {Box, Button, FormControl, Icon, MenuItem, Select, Tooltip, Typography,} from '@material-ui/core';
import {PAGE_SIZE, PAGE_SIZE_OPTIONS} from '../../../../utilities/constants';
import {getDeptsRequest} from '../../DepartmentsApiActions';
import {useStyles} from './styles';
import defaultProfile from '../../../../assets/group.png';
import {clearStore} from '../../DepartmentsActions';
import {debounce} from '../../../../utilities/utilityFunctions';
import ImageLoader from 'react-load-image';
import Preloader from '../../../../utilities/components/Preloader/Preloader';

/**
 * function to render organization departments
 * @param history: history from router
 * @param getAllDepts: function to get departments
 * @param depts: departments list
 * @param orgIdHistory: organization id
 * @param orgNameHistory: organization name
 * @param hcorgNameHistory: healthcare organization name
 * @param hcorgIdHistory: healthcare organization id
 * @param clearValue: function to clear previous values
 * @returns {JSX.Element}
 * @constructor
 */
const OrganizationDepartments = ({
                                     history,
                                     getAllDepts,
                                     depts,
                                     orgIdHistory,
                                     orgNameHistory,
                                     hcorgNameHistory,
                                     hcorgIdHistory,
                                     clearValue,
                                     orgTypeIdHistory
                                 }) => {
    const classes = useStyles();
    const params = useParams();
    const match = useRouteMatch();
    const [paginationParams, setPaginationParams] = useState({
        page: 0,
        rowsPerPage: PAGE_SIZE,
        searchData: {
            name: '',
            active: '',
            desc: '',
        },
    });
    const [isFilterChange, setIsFilterChange] = useState(false);

    /**
     * function to handle table filter search
     * @param filters: filters from filter input to search
     */
    const onTableFilterChange = filters => {
        let searchDataOnFilterChange = Object.assign({}, paginationParams);

        if (filters.length > 0) {
            let obj = {};
            filters.forEach(filter => {
                obj[filter.column.field] = filter.value;
            });

            Object.keys(searchDataOnFilterChange.searchData).forEach(function (key) {
                if (key !== 'active') {
                    if (obj[key]) {
                        searchDataOnFilterChange.searchData[key] = obj[key];
                    } else {
                        searchDataOnFilterChange.searchData[key] = '';
                    }
                }
            });

            filters.forEach(filter => {
                searchDataOnFilterChange.searchData[filter.column.field] = filter.value;
            });
        } else {
            searchDataOnFilterChange.searchData['name'] = '';
            searchDataOnFilterChange.searchData['desc'] = '';
        }

        setIsFilterChange(true);
        setPaginationParams(searchDataOnFilterChange);
    };

    /**
     * function to implement debounce
     * @type {(function(...[*]=): void)|*}
     */
    //eslint-disable-next-line
    const debounceOnFilterChange = React.useCallback(
        debounce(onTableFilterChange, 400),
        []
    );

    useEffect(() => {
        getAllDepts(params.id, paginationParams, isFilterChange).then(res => {
            if (isFilterChange) {
                setIsFilterChange(false);
            }
        });
        //eslint-disable-next-line
    }, [getAllDepts, paginationParams, params.id]);

    /**
     * function to handle status change upon filter
     * @param value: selected status value
     */
    const handleStatusChange = value => {
        let data = Object.assign({}, paginationParams);
        data.searchData.active = value;

        setPaginationParams(data);
    };

    /**
     * function to handle profile click
     * @param id: id of the department
     */
    const handleProfileRoute = id => {
        clearValue();

        history.push({
            pathname: `${match.path.split(':')[0]}departments/${id}`,
            state: {
                orgIdHistory: orgIdHistory,
                orgNameHistory: orgNameHistory,
                hcorgNameHistory: hcorgNameHistory,
                hcorgIdHistory: hcorgIdHistory,
                orgTypeIdHistory: orgTypeIdHistory
            },
        });
    };

    const columns = [
        {
            field: 'name',
            title: 'Name',
            filtering: true,
            defaultFilter: paginationParams.searchData['name'],
            cellStyle: {
                width: '25%',
            },
            headerStyle: {
                width: '25%',
            },
            render: ({imageUrl, name, id}) => {
                return (
                    <Box
                        className={classes.profileContainer}
                        onClick={() => handleProfileRoute(id)}
                    >
                        <Box className={classes.profileInnerContainer}>
                            <ImageLoader src={imageUrl ? imageUrl : defaultProfile}>
                                <img alt={id} className={classes.profileImage}/>
                                <div className={classes.profileImageError}>{id}</div>
                                <Preloader alt={id} className={classes.profileImage}/>
                            </ImageLoader>
                        </Box>

                        <Box className={classes.nameContainer}>
                            <Typography className={classes.name}>{name}</Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'desc',
            title: 'Description',
            filtering: true,
            defaultFilter: paginationParams.searchData['desc'],
            cellStyle: {
                width: '40%',
            },
            headerStyle: {
                width: '40%',
            },
        },
        {
            field: 'patientCount',
            title: 'Patients',
            filtering: false,
            cellStyle: {
                textAlign: 'center',
                width: '10%',
            },
            headerStyle: {
                textAlign: 'center',
                width: '10%',
            },
            render: ({patientCount}) => {
                return <>{patientCount ? patientCount : '0'}</>;
            },
        },
        {
            field: 'active',
            title: 'Active Status',
            cellStyle: {
                width: '10%',
                textAlign: 'center',
            },
            headerStyle: {
                width: '10%',
                textAlign: 'center',
            },
            filtering: true,
            filterComponent: props => {
                return (
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                value={paginationParams.searchData.active}
                                onChange={e => handleStatusChange(e.target.value)}
                            >
                                <MenuItem value=''>All</MenuItem>
                                <MenuItem value='1'>Active</MenuItem>
                                <MenuItem value='0'>Blocked</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                );
            },
            render: ({active}) => {
                return (
                    <>
                        {active ? (
                            <Tooltip arrow placement='top' title='Active'>
                                <Icon className={classes.statusActive}>
                                    check_circle_outline
                                </Icon>
                            </Tooltip>
                        ) : (
                            <Tooltip arrow placement='top' title='Blocked'>
                                <Icon className={classes.statusBlocked}>block</Icon>
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <Box>
            <Box className={classes.addButtonContainer}>
                <Button
                    variant='contained'
                    color='primary'
                    startIcon={<Icon>add</Icon>}
                    disableElevation={true}
                    component={Link}
                    to={{
                        pathname: `${match.path.split(':')[0]}addDepartment`,
                        state: {
                            orgIdHistory: orgIdHistory,
                            orgNameHistory: orgNameHistory,
                            hcorgIdHistory: hcorgIdHistory,
                            hcorgNameHistory: hcorgNameHistory,
                            orgTypeIdHistory: orgTypeIdHistory
                        },
                    }}
                    className={classes.addButton}
                >
                    Department
                </Button>
            </Box>

            <MaterialTable
                title=''
                columns={columns}
                data={depts.rows}
                options={{
                    search: false,
                    filtering: true,
                    sorting: false,
                    draggable: false,
                    horizontalScroll: true,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    pageSize: paginationParams.rowsPerPage,
                    emptyRowsWhenPaging: false,
                }}
                pages={Math.ceil(depts.count / paginationParams.rowsPerPage)}
                totalCount={depts.count}
                page={paginationParams.page}
                onChangeRowsPerPage={pageSize => {
                    let data = Object.assign({}, paginationParams);
                    data['rowsPerPage'] = pageSize;
                    setPaginationParams(data);
                }}
                onChangePage={page => {
                    let data = Object.assign({}, paginationParams);
                    data['page'] = page;
                    setPaginationParams(data);
                }}
                onFilterChange={filters => {
                    // onTableFilterChange(filters);
                    debounceOnFilterChange(filters);
                }}
            />
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        depts: state.departments.depts,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllDepts: (orgId, data, isHideLoader = false) => {
            return dispatch(getDeptsRequest(orgId, data, isHideLoader)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        clearValue: () => {
            return dispatch(clearStore());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganizationDepartments);
