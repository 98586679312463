import React, {useState} from 'react';
import {connect} from 'react-redux';
import clsx from 'clsx';
import {Avatar, Box, Grid, Paper, Typography} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import {useStyles} from './styles';
import {getUserInfo} from '../../../../../../utilities/storage';
import MessageDialog from '../../../../../../utilities/components/MessageDialog/MessageDialog';
import {APP_URLS} from '../../../../../../utilities/constants';

/**
 * function to render case items
 * @param history: history from router
 * @param selectedDept: selected department
 * @param caseItem: case item details
 * @returns {JSX.Element}
 * @constructor
 */
const CaseItem = ({history, selectedDept, caseItem}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const isCaregiver = caseItem.Caregivers.find(
        caregiver => caregiver.id === getUserInfo().id
    );

    const caregiversList = caseItem.Caregivers.filter(
        caregiver => caregiver.id !== caseItem.createdById
    );

    /**
     * function to handle case item click
     */
    const handleCaseItemClick = () => {
        if (selectedDept && !selectedDept.headDoctor && !isCaregiver) {
            setOpen(true);
        } else {
            history.push(`${APP_URLS.CASES}/${caseItem.id}`);
        }
    };

    return (
        <>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box
                    component={Paper}
                    className={classes.contentContainer}
                    onClick={handleCaseItemClick}
                >
                    <Box className={classes.contentCard}>
                        <Box className={classes.caregiverListContainer}>
                            <Box>
                                <Typography
                                    className={clsx(
                                        classes.caseText,
                                        caseItem.priority === 1 ? classes.caseTextCritical : ''
                                    )}
                                >
                                    Case #{caseItem.id}
                                </Typography>
                            </Box>
                            <Box className={classes.caregiversAvatarContainer}>
                                <Box>
                                    <Avatar className={classes.createdByAvatar}>
                                        <span className={classes.createdByAvatarContent}>
                                            {caseItem.createdByFirstName.charAt(0).toUpperCase()}
                                            {caseItem.createdByLastName.charAt(0).toUpperCase()}
                                        </span>
                                    </Avatar>
                                </Box>
                                {caregiversList.map((item, index) => (
                                    <Box key={index}>
                                        <Avatar className={classes.avatar}>
                                            <span className={classes.caregiverAvatarContent}>
                                                {item.firstname.charAt(0).toUpperCase()}
                                                {item.lastname.charAt(0).toUpperCase()}
                                            </span>
                                        </Avatar>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <Box>
                            <Typography noWrap className={classes.descText}>
                                {caseItem.desc}
                            </Typography>
                        </Box>
                        <Box className={classes.cardFooter}>
                            <Box>
                                <Box className={classes.cardFooterCreatedBy}>
                                    <Typography className={classes.createdByText}>
                                        Created by:{' '}
                                        {`${caseItem.createdByFirstName} ${caseItem.createdByLastName}`}
                                    </Typography>
                                </Box>

                                <Box className={classes.cardFooterUpdatedOn}>
                                    <Typography className={classes.createdByText}>
                                        Updated on: {caseItem.updatedAt}
                                    </Typography>
                                </Box>
                            </Box>

                            <Box>
                                {selectedDept && !selectedDept.headDoctor && !isCaregiver && (
                                    <LockIcon className={classes.lockIcon}/>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>

            <MessageDialog
                open={open}
                setOpen={setOpen}
                title='Sorry!'
                message='You do not have permission to view this case. Contact a case caregiver to request permission.'
            />
        </>
    );
};

const mapStateToProps = state => {
    return {
        selectedDept: state.sirenUsers.selectedDept,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseItem);
