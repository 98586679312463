import HttpRequest from '../../utilities/httpRequest';
import {getAnalytics} from './AnalyticsActions';
import {hideLoader, showLoader} from '../../utilities/UtilitiesActions';

/**
 * function to get analytics data
 * @param data: data for api request
 * @returns {function(*=): Promise<unknown>}
 */
export const getAnalyticsRequest = data => {
    return dispatch => {
        dispatch(showLoader());
        const url = `case/totalCasesCreated`;

        return new HttpRequest(dispatch, url, 'post', data).send().then(
            res => {
                dispatch(getAnalytics(res.data.data));
                dispatch(hideLoader());
                return Promise.resolve(res.data.data);
            },
            error => {
                dispatch(hideLoader());
                return Promise.reject(error);
            }
        );
    };
};
