import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import MaterialTable from 'material-table';
import {PAGE_SIZE, PAGE_SIZE_OPTIONS} from '../../../../utilities/constants';
import AssignCaregiver from '../../../HealthcareOrganizations/components/AssignCaregiverForm/AssignCaregiverForm';
import {useStyles} from './styles';
import defaultUser from '../../../../assets/user.png';
import {debounce} from '../../../../utilities/utilityFunctions';
import ImageLoader from 'react-load-image';
import Preloader from '../../../../utilities/components/Preloader/Preloader';
import {Box, Button, FormControl, Icon, MenuItem, Select, Switch, Typography} from '@material-ui/core';
import {getCaregiversForHCOrgRequest, toggleCaregiverPrivilegesRequest,} from '../../CaregiverApiActions';
import ActiveStatusIcon from '../../../../utilities/components/ActiveStatusIcon/ActiveStatusIcon';

/**
 * function to render healthcare caregivers list
 * @param getCaregiversForHCOrg: function to get caregivers of healthcare organization
 * @param caregivers: caregivers list
 * @param togglePrivileges: function to toggle caregiver clinical privileges
 * @param hcorgIdHistory: healthcare organization id
 * @param hcorgNameHistory: healthcare organization name
 * @returns {JSX.Element}
 * @constructor
 */
const HealthcareCaregivers = ({
                                  getCaregiversForHCOrg,
                                  caregivers,
                                  togglePrivileges,
                                  hcorgIdHistory,
                                  hcorgNameHistory,
                              }) => {
    const classes = useStyles();
    const params = useParams();
    const match = useRouteMatch();
    const [paginationParams, setPaginationParams] = useState({
        page: 0,
        rowsPerPage: PAGE_SIZE,
        searchData: {
            name: '',
            email: '',
            phone: '',
            active: '',
        },
    });
    const [isFilterChange, setIsFilterChange] = useState(false);
    const [refreshList, setRefreshList] = useState(false);
    const [openAssignCaregiverDialog, setOpenAssignCaregiverDialog] = useState(
        false
    );

    /**
     * function to handle table filter search
     * @param filters: filters from filter input to search
     */
    const onTableFilterChange = filters => {
        let searchDataOnFilterChange = Object.assign({}, paginationParams);

        if (filters.length > 0) {
            let obj = {};
            filters.forEach(filter => {
                obj[filter.column.field] = filter.value;
            });

            Object.keys(searchDataOnFilterChange.searchData).forEach(function (key) {
                if (key !== 'active') {
                    if (obj[key]) {
                        searchDataOnFilterChange.searchData[key] = obj[key];
                    } else {
                        searchDataOnFilterChange.searchData[key] = '';
                    }
                }
            });

            filters.forEach(filter => {
                searchDataOnFilterChange.searchData[filter.column.field] = filter.value;
            });
        } else {
            searchDataOnFilterChange.searchData['name'] = '';
            searchDataOnFilterChange.searchData['email'] = '';
            searchDataOnFilterChange.searchData['phone'] = '';
        }

        setIsFilterChange(true);
        setPaginationParams(searchDataOnFilterChange);
    };

    /**
     * function to implement debounce
     * @type {(function(...[*]=): void)|*}
     */
    //eslint-disable-next-line
    const debounceOnFilterChange = React.useCallback(
        debounce(onTableFilterChange, 400),
        []
    );

    useEffect(() => {
        getCaregiversForHCOrg(params.id, paginationParams, isFilterChange).then(
            res => {
                if (isFilterChange) {
                    setIsFilterChange(false);
                }
            }
        );
        //eslint-disable-next-line
    }, [
        getCaregiversForHCOrg,
        paginationParams,
        params.id
    ]);

    useEffect(() => {
        if(refreshList){
            getCaregiversForHCOrg(params.id, paginationParams, isFilterChange).then(
                res => {
                    if (isFilterChange) {
                        setIsFilterChange(false);
                    }
                    setRefreshList(false)
                }
            );
        }
        //eslint-disable-next-line
    }, [
        getCaregiversForHCOrg,
        paginationParams,
        params.id,
        refreshList
    ]);

    /**
     * function to handle status change upon filter
     * @param value: selected status value
     */
    const handleStatusChange = value => {
        let data = Object.assign({}, paginationParams);
        data.searchData.active = value;

        setPaginationParams(data);
    };

    /**
     * function to toggle change for clinical privileges on switch change
     * @param props: caregiver details
     */
    const handleChange = props => {
        const data = {
            clinicalPrivileges: !props.clinicalPrivileges,
        };

        togglePrivileges(props.id, data).then(res => {
            getCaregiversForHCOrg(params.id, paginationParams).then(res => {
            });
        });
    };

    const columns = [
        {
            field: 'name',
            title: 'Name',
            filtering: true,
            defaultFilter: paginationParams.searchData['name'],
            cellStyle: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            },
            render: ({imageUrl, name, userId}) => {
                return (
                    <Box
                        className={classes.profileContainer}
                        component={Link}
                        to={{
                            pathname: `${match.path.split(':')[0]}caregivers/${userId}`,
                            state: {
                                hcorgIdHistory: hcorgIdHistory,
                                hcorgNameHistory: hcorgNameHistory,
                            },
                        }}
                    >
                        <Box className={classes.profileInnerContainer}>
                            <ImageLoader src={imageUrl ? imageUrl : defaultUser}>
                                <img alt={userId} className={classes.profileImage}/>
                                <div className={classes.profileImageError}>{userId}</div>
                                <Preloader alt={userId} className={classes.profileImage}/>
                            </ImageLoader>
                        </Box>

                        <Box className={classes.nameContainer}>
                            <Typography classes={{root: classes.name}}>{name}</Typography>
                        </Box>
                    </Box>
                );
            },
        },
        {
            field: 'email',
            title: 'Email',
            filtering: true,
            defaultFilter: paginationParams.searchData['email'],
            cellStyle: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            },
        },
        {
            field: 'phone',
            title: 'Phone',
            filtering: true,
            defaultFilter: paginationParams.searchData['phone'],
            cellStyle: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            },
        },
        {
            field: 'privileges',
            title: 'Clinical Privileges',
            filtering: false,

            cellStyle: {
                width: '15%',
            },
            headerStyle: {
                width: '15%',
            },
            render: props => {
                return (
                    <>
                        <Switch
                            checked={props.clinicalPrivileges === true}
                            onClick={() => handleChange(props)}
                        />
                    </>
                );
            },
        },
        {
            field: 'enabled',
            title: 'Active Status',
            cellStyle: {
                width: '10%',
                textAlign: 'center',
            },
            headerStyle: {
                width: '10%',
                textAlign: 'center',
            },
            filtering: true,
            filterComponent: props => {
                return (
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                value={paginationParams.searchData.active}
                                onChange={e => handleStatusChange(e.target.value)}
                            >
                                <MenuItem value=''>All</MenuItem>
                                <MenuItem value='1'>Active</MenuItem>
                                <MenuItem value='0'>Blocked</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                );
            },
            render: ({enabled, isBanned}) => {
                return (
                    <ActiveStatusIcon enabled={enabled} isBanned={isBanned} isLabel={false} />
                );
            },
        },
    ];

    return (
        <Box>
            <AssignCaregiver
                openAssignCaregiverDialog={openAssignCaregiverDialog}
                setOpenAssignCaregiverDialog={setOpenAssignCaregiverDialog}
                setRefreshList={setRefreshList}
            />

            <Box className={classes.addButtonContainer}>
                <Button
                    variant='contained'
                    color='primary'
                    startIcon={<Icon>add</Icon>}
                    disableElevation={true}
                    onClick={() => setOpenAssignCaregiverDialog(true)}
                    classes={{root: classes.addButton}}
                >
                    Caregiver
                </Button>
            </Box>

            <Box>
                <MaterialTable
                    title=''
                    columns={columns}
                    data={caregivers.rows}
                    options={{
                        search: false,
                        filtering: true,
                        draggable: false,
                        sorting: false,
                        horizontalScroll: true,
                        pageSizeOptions: PAGE_SIZE_OPTIONS,
                        pageSize: paginationParams.rowsPerPage,
                        emptyRowsWhenPaging: false,
                    }}
                    pages={Math.ceil(caregivers.count / paginationParams.rowsPerPage)}
                    totalCount={caregivers.count}
                    page={paginationParams.page}
                    onChangeRowsPerPage={pageSize => {
                        let data = Object.assign({}, paginationParams);
                        data['rowsPerPage'] = pageSize;
                        setPaginationParams(data);
                    }}
                    onChangePage={page => {
                        let data = Object.assign({}, paginationParams);
                        data['page'] = page;
                        setPaginationParams(data);
                    }}
                    onFilterChange={filters => {
                        debounceOnFilterChange(filters);
                    }}
                />
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        caregivers: state.caregivers.caregivers,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCaregiversForHCOrg: (hcOrgId, data, isHideLoader = false) => {
            return dispatch(
                getCaregiversForHCOrgRequest(hcOrgId, data, isHideLoader)
            ).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        togglePrivileges: (caregiverId, data) => {
            return dispatch(toggleCaregiverPrivilegesRequest(caregiverId, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HealthcareCaregivers);
