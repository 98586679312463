import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Timeline} from '@material-ui/lab';
import UpdateItem from './components/UpdateItem/UpdateItem';
import {useStyles} from './styles';
import {getUpdatesRequest} from '../../NewUpdatesApiActions';
import {PAGE_SIZE} from '../../../../utilities/constants';
import ScrollRoader from '../../../../utilities/components/ScrollLoader/ScrollLoader';
import {getDepartment} from '../../../../utilities/storage';
import {Box, Checkbox, FormControlLabel, Paper, Typography,} from '@material-ui/core';

/**
 * function to render new updates
 * @param history: history from router
 * @param getUpdates: function to get updates
 * @param selectedDept: selected department
 * @param depts: departments list
 * @returns {JSX.Element}
 * @constructor
 */
const NewUpdatesList = ({history, getUpdates, selectedDept, depts}) => {
    const classes = useStyles();
    const [allTeam, setAllTeam] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [updateCount, setUpdateCount] = useState(0);
    const [newUpdates, setNewUpdates] = useState(null);

    useEffect(() => {
        if(selectedDept){
            getAllUpdates(allTeam);
        }
        //eslint-disable-next-line
    }, [page])

    useEffect(() => {
        if(allTeam && selectedDept){
            if (page > 1) {
                setPage(1);
            } else {
                getAllUpdates(allTeam);
            }

            window.scrollTo(0, 0);
        }
        
        //eslint-disable-next-line
    }, [allTeam]);

    /**
     * function to get all updates
     */
    const getAllUpdates = (isAllTeam) => {
        if (selectedDept) {
            getUpdates(
                isAllTeam ? '' : selectedDept.team_id,
                page,
                PAGE_SIZE
            ).then(res => {
                const result = res;
                if (newUpdates && page > 1) {
                    let data = Object.assign({}, newUpdates);
                    result.rows.forEach((update, ind) => {
                        data.rows.push(update);
                        if (ind === result.rows.length - 1) {
                            setNewUpdates(data);
                        }
                    });
                } else {
                    setNewUpdates(result);
                }
                if (page * PAGE_SIZE >= result.count) {
                    setHasMore(false);
                    setUpdateCount(result.count);
                } else {
                    setUpdateCount(page * PAGE_SIZE);
                    setHasMore(true);
                }
            });
        }
    };

    /**
     * function to fetch updates on scroll
     * @param pageNumber: page number for request
     */
    const fetchData = pageNumber => {
        if (newUpdates !== null && newUpdates.rows.length > 0) {
            setPage(pageNumber);
        } else {
            getAllUpdates(allTeam);
        }
    };

    return (
        <Box className={classes.container}>
            {depts && depts.length !== 1 && (
                <Box component={Paper} className={classes.actionsContainer}>
                    <Box className={classes.checkboxConatiner}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={!getDepartment()}
                                    color='primary'
                                    checked={allTeam}
                                    onChange={e => {
                                        setAllTeam(e.target.checked)

                                        if(!e.target.checked){
                                            if (page > 1) {
                                                setPage(1);
                                            } else {
                                                getAllUpdates(false);
                                            }

                                            window.scrollTo(0, 0);
                                        }
                                    }}
                                />
                            }
                            label='All Teams'
                            labelPlacement='end'
                        />
                    </Box>
                </Box>
            )}

            {selectedDept && (
                <InfiniteScroll
                    dataLength={newUpdates ? updateCount : 0}
                    next={() => fetchData(page + 1)}
                    hasMore={hasMore}
                    loader={<ScrollRoader/>}
                >
                    {newUpdates && newUpdates.count === 0 ? (
                        <Box className={classes.noContentContainer}>
                            <Typography classes={{root: classes.noLabel}}>
                                No Updates available
                            </Typography>
                        </Box>
                    ) : (
                        <Timeline>
                            {newUpdates &&
                            newUpdates.rows.map((update, index) => (
                                <UpdateItem
                                    refreshUpdateList={() => {
                                        if (page === 1) {
                                            getAllUpdates(allTeam);
                                        } else {
                                            setPage(1);
                                        }
                                    }}
                                    key={index}
                                    update={update}
                                    history={history}
                                    setNewUpdates={setNewUpdates}
                                />
                            ))}
                        </Timeline>
                    )}
                </InfiniteScroll>
            )}
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        selectedDept: state.sirenUsers.selectedDept,
        depts: state.sirenUsers.depts.teams,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUpdates: (id, offset, limit) => {
            return dispatch(getUpdatesRequest(id, offset, limit)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUpdatesList);
