import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        contentContainer: {
            padding: '0.8rem',
        },
        contentCardUpper: {
            textDecoration: 'none',
            color: 'rgba(0, 0, 0, 0.87)',
        },
        contentCard: {
            textDecoration: 'none',
            color: 'rgba(0, 0, 0, 0.87)',
            display: 'flex',
            alignItems: 'flex-start',
            height: '78px',
        },
        profileOuterContainer: {
            // width: '18%',
        },
        infoContainer: {
            width: '82%',
        },
        profileContainer: {
            position: 'relative',
            borderRadius: '100%',
            overflow: 'Hidden',
            marginRight: '20px',
        },
        profileImageError: {
            height: '80px',
            width: '80px',
            borderRadius: '100%',
            padding: '4px',
            border: '2px solid rgb(221, 221, 221)',
            verticalAlign: 'sub',
            textAlign: 'center',
        },
        profileImage: {
            height: '80px',
            width: '80px',
            borderRadius: '100%',
            padding: '4px',
            border: '2px solid rgb(221, 221, 221)',
            verticalAlign: 'sub',
        },
        critical: {
            border: '2px solid red',
        },
        jursyNumberContainer: {
            position: 'absolute',
            top: '-2px',
            left: '5px',
            height: '75px',
            width: '70px',
            borderRadius: '100%',
            overflow: 'hidden',
        },
        jursyNumber: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            textAlign: 'center',
            position: 'absolute',
            width: '100%',
            bottom: '0',
            lineHeight: '19px',
            height: '17px',
            color: 'white',
            fontSize: '14px',
            fontWeight: 'bold',
        },
        name: {
            fontSize: '14px',
            fontWeight: '450',
        },
        nameCritical: {
            color: 'red',
        },
        description: {
            fontSize: '15px',
            fontWeight: '450',
            // color: '#727272',
            marginTop: '0.1rem',
            marginBottom: '0.5rem',
        },
        createdContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        createdUserContainer: {
            marginRight: '5px',
        },
        createdBy: {
            fontSize: '11px',
        },
        date: {
            fontSize: '11px',
        },
        careHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '4px',
            marginLeft: '1px',
            marginTop: '-4px',
        },
        headerLabel: {
            color: '#727272',
        },
    };
});
