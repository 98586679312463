import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import InputMask from 'react-input-mask';
import ImageUploading from 'react-images-uploading';
import EditIcon from '@material-ui/icons/Edit';
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    Tooltip,
    Typography,
} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {DatePickerValidator, RadioGroupValidator} from '../../../../utilities/validators';
import {addAthleteRequest, getSignedUrlRequest, uploadToS3Request,} from '../../AthletesApiActions';
import {useStyles} from './styles';
import UtilHelper from '../../../../utilities/validators/UtilHelper';
import {ORGANIZATION_TYPES, IMAGE_FILE_TYPES} from '../../../../utilities/constants';

/**
 * function to render athlete form
 * @param selectedDept: selected department
 * @param openCreateAthleteModal: boolean to opens athlete form
 * @param setOpenCreateAthleteModal: function to change open state
 * @param addAthlete: function to add athlete
 * @param setSelectedAthlete: function to set selected athlete
 * @param getSignedUrl: function to get signed url
 * @param uploadToS3: function to upload file on signed url
 * @returns {JSX.Element}
 * @constructor
 */
const AddAthlete = ({
                        selectedDept,
                        openCreateAthleteModal,
                        setOpenCreateAthleteModal,
                        addAthlete,
                        setSelectedAthlete,
                        getSignedUrl,
                        uploadToS3,
                    }) => {
    const classes = useStyles();
    const [profileImage, setProfileImage] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [position, setPosition] = useState('');
    const [jerseyNumber, setJerseyNumber] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [socialSecurityNumber, setSocialSecurityNumber] = useState('');
    const [gender, setGender] = useState('');
    const [mrn, setMrn] = useState('');

    useEffect(() => {
        ValidatorForm.addValidationRule('isBlank', value =>
            UtilHelper.validateBlankSpace(value)
        );
        ValidatorForm.addValidationRule('isSSN', value =>
            UtilHelper.validateSocialSecurityNumber(value)
        );

        return () => {
            ValidatorForm.removeValidationRule('isBlank');
            ValidatorForm.removeValidationRule('isSSN');
        };
    }, []);

    /**
     * function to handle add athlete
     * @param e: even after form submit
     */
    const onSubmit = e => {
        e.preventDefault();

        const data = {
            firstName: firstName,
            lastName: lastName,
            position: position,
            dob: dateOfBirth ? moment(dateOfBirth).format('MM-DD-YYYY') : '',
            gender: gender,
            socialSecurityNumber: socialSecurityNumber,
            TeamId: selectedDept.team_id,
        };

        if (selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER) {
            data['mrn'] = mrn;
        }else{
            data['jerseyNum'] = jerseyNumber ? jerseyNumber : null;
        }

        if (profileImage) {
            const imageData = {
                fileName: profileImage[0].file.name,
                fileType: `.${profileImage[0].file.name.split('.').pop()}`,
            };

            getSignedUrl(imageData).then(res => {
                uploadToS3(res.urls, profileImage[0].file).then(() => {
                    data['imageUrl'] = res.path;
                    addAthlete(data).then(res => {
                        handleClose();
                        setSelectedAthlete(res);
                    });
                });
            });
        } else {
            addAthlete(data).then(res => {
                handleClose();
                setSelectedAthlete(res);
            });
        }
    };

    /**
     * function to handle dialog close
     */
    const handleClose = () => {
        setOpenCreateAthleteModal(false);

        setProfileImage(null);
        setFirstName('');
        setLastName('');
        setPosition('');
        setJerseyNumber('');
        setDateOfBirth(null);
        setSocialSecurityNumber('');
        setGender('');
    };

    return (
        <Dialog
            maxWidth='md'
            fullWidth
            open={openCreateAthleteModal}
            onClose={handleClose}
        >
            <DialogTitle>
                {selectedDept &&
                selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER &&
                'Add New Athlete'}
                {selectedDept &&
                selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER &&
                'Add New Patient'}
            </DialogTitle>

            <Divider/>

            <ValidatorForm onSubmit={onSubmit}>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ImageUploading
                                acceptType={IMAGE_FILE_TYPES}
                                maxFileSize={3000000}
                                value={profileImage}
                                onChange={img => {
                                    setProfileImage(img);
                                }}
                            >
                                {({onImageUpload, dragProps, errors}) => (
                                    <Box>
                                        <Box className={classes.avatarUploadContainer}>
                                            <Box
                                                className={classes.avatarUploadInnerContainer}
                                                onClick={onImageUpload}
                                                {...dragProps}
                                            >
                                                <Box>
                                                    <Tooltip placement='top' title='Add Photo' arrow>
                                                        <Avatar
                                                            className={classes.uploadAvatar}
                                                            src={profileImage && profileImage[0].dataURL}
                                                        />
                                                    </Tooltip>
                                                </Box>

                                                <Box className={classes.editIcon}>
                                                    <EditIcon color='primary'/>
                                                </Box>
                                            </Box>

                                            {errors && (
                                                <Box className={classes.errorContainer}>
                                                    {errors.acceptType && (
                                                        <Typography className={classes.error}>
                                                            Please select image file
                                                        </Typography>
                                                    )}

                                                    {errors.maxFileSize && (
                                                        <Typography className={classes.error}>
                                                            Selected image file size exceed max file size
                                                            (3MB)
                                                        </Typography>
                                                    )}
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                )}
                            </ImageUploading>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextValidator
                                label='First Name *'
                                variant='outlined'
                                fullWidth
                                size='small'
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                                validators={['required', 'isBlank']}
                                errorMessages={[
                                    'Fisrt name is required',
                                    'Please enter first name',
                                ]}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextValidator
                                label='Last Name *'
                                variant='outlined'
                                fullWidth
                                size='small'
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                                validators={['required', 'isBlank']}
                                errorMessages={[
                                    'Last name is required',
                                    'Please enter last name',
                                ]}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <FormControl>
                                <RadioGroupValidator
                                    row
                                    value={gender}
                                    onChange={e => setGender(e.target.value)}
                                    validators={['required']}
                                    errorMessages={['Gender is required']}
                                >
                                    <Box className={classes.radioContainer}>
                                        <Box className={classes.radioTextContainer}>
                                            <Typography>Gender *</Typography>
                                        </Box>
                                        <Box>
                                            <FormControlLabel
                                                value='Male'
                                                control={<Radio color='primary'/>}
                                                label='Male'
                                            />
                                            <FormControlLabel
                                                value='Female'
                                                control={<Radio color='primary'/>}
                                                label='Female'
                                            />
                                        </Box>
                                    </Box>
                                </RadioGroupValidator>
                            </FormControl>
                        </Grid>

                        {selectedDept && selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER && (
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextValidator
                                    label='Jersey Number'
                                    variant='outlined'
                                    fullWidth
                                    size='small'
                                    value={jerseyNumber}
                                    onChange={e => setJerseyNumber(e.target.value)}
                                    validators={['isBlank']}
                                    errorMessages={['Please enter jersey number']}
                                />
                            </Grid>
                        )}

                        {selectedDept && selectedDept.OrganizationType === ORGANIZATION_TYPES.HEALTHCARE_PROVIDER && (
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextValidator
                                    label='Medical Record Number'
                                    variant='outlined'
                                    fullWidth
                                    size='small'
                                    value={mrn}
                                    onChange={e => setMrn(e.target.value)}
                                    validators={['isBlank']}
                                    errorMessages={['Please enter medical record number']}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePickerValidator
                                    fullWidth
                                    label='Date of Birth'
                                    inputVariant='outlined'
                                    size='small'
                                    format='MM/dd/yyyy'
                                    maxDate={new Date()}
                                    value={dateOfBirth}
                                    onChange={date => setDateOfBirth(date)}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <InputMask
                                mask='999-99-9999'
                                value={socialSecurityNumber}
                                onChange={e => setSocialSecurityNumber(e.target.value)}
                                disabled={false}
                                maskChar='_'
                            >
                                {props => (
                                    <TextValidator
                                        {...props}
                                        label='Social Security Number'
                                        variant='outlined'
                                        fullWidth
                                        size='small'
                                        validators={['isSSN']}
                                        errorMessages={['Please enter valid number']}
                                    />
                                )}
                            </InputMask>
                        </Grid>

                        {selectedDept && selectedDept.OrganizationType !== ORGANIZATION_TYPES.HEALTHCARE_PROVIDER && (
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextValidator
                                    label='Position'
                                    variant='outlined'
                                    fullWidth
                                    size='small'
                                    value={position}
                                    onChange={e => setPosition(e.target.value)}
                                    validators={['isBlank']}
                                    errorMessages={['Please enter position']}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>

                <DialogActions classes={{root: classes.actionsContainer}}>
                    <Button
                        onClick={handleClose}
                        variant='outlined'
                        color='primary'
                        disableElevation={true}
                    >
                        Cancel
                    </Button>

                    <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        disableElevation={true}
                    >
                        Add
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
};

const mapStateToProps = state => {
    return {
        selectedDept: state.sirenUsers.selectedDept,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addAthlete: data => {
            return dispatch(addAthleteRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getSignedUrl: data => {
            return dispatch(getSignedUrlRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        uploadToS3: (url, file) => {
            return dispatch(uploadToS3Request(url, file)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAthlete);
