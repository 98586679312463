import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, NavLink, useParams, useRouteMatch} from 'react-router-dom';
import ConfirmationDialog from '../../../../utilities/components/ConfirmationDialog/ConfirmationDialog';
import {useStyles} from './styles';
import defaultProfile from '../../../../assets/group.png';
import {getIsTeamList, getUserInfo} from '../../../../utilities/storage';
import ImageLoader from 'react-load-image';
import Preloader from '../../../../utilities/components/Preloader/Preloader';
import {Box, Breadcrumbs, Button, Icon, Paper, Typography,} from '@material-ui/core';
import {getVendorByIdRequest, toggleActivationRequest,} from '../../IntegrationVendorsApiActions';
import {ROLES} from '../../../../utilities/constants'; 

/**
 * function to get message for activation dialog
 * @param vendor: user details
 * @param classes: classes from css
 * @returns {JSX.Element}
 * @constructor
 */
const ToggleActivationDialogMessage = ({vendor, classes}) => {
    return (
        <>
            {vendor && (
                <Box>
                    Are you sure you want to {vendor.active ? 'deactivate' : 'activate'}{' '}
                    the <span className={classes.dialogMessageLabel}>{vendor.name}</span>?
                </Box>
            )}
        </>
    );
};

/**
 * function to render vendors list
 * @param location: location from router
 * @param getVendorById: function to get vendor by id
 * @param vendor: vendor details
 * @param toggleActivation: function to toggle vendor activation
 * @returns {JSX.Element}
 * @constructor
 */
const VendorsProfile = ({
                            location,
                            getVendorById,
                            vendor,
                            toggleActivation,
                        }) => {
    const classes = useStyles();
    const role = getUserInfo().Roles[0].name;
    const params = useParams();
    const match = useRouteMatch();
    const [
        openToggleActivationConfirmationDialog,
        setOpenToggleActivationConfirmationDialog,
    ] = useState(false);
    const currentUrlPath = match.path.split(':')[0];

    useEffect(() => {
        getVendorById(params.id).then(res => {
        });
    }, [params.id, getVendorById]);

    const breadCrumbDataForVendorProfilePage = [
        {
            icon: 'assignment_ind',
            name: 'Integration Vendors',
            path: '/vendors',
        },
        {
            name: `${vendor ? `${vendor.name}` : ''}`,
            path: `/vendors/${params.id}`,
        },
    ];

    const breadCrumbDataForHcOrgVendorProfilePage = [
        {
            icon: 'local_hospital',
            name: 'Healthcare Organizations',
            path: '/healthcareOrgs',
        },
        {
            name: `${
                location.state && location.state
                    ? `${location.state.hcorgNameHistory}`
                    : ''
            }`,
            path: `/healthcareOrgs/${
                location.state && location.state.hcorgIdHistory
            }`,
        },
        {
            name: `${vendor ? `${vendor.name}` : ''}`,
            path: `/healthcareOrgs/vendors/${params.id}`,
        },
    ];

    /**
     * function to get breadcrumb data for current url
     * @return {Array}
     */
    const getBreadcrumbData = (urlPath) => {
        switch(urlPath){
            case '/healthcareOrgs/vendors/': {
                return breadCrumbDataForHcOrgVendorProfilePage
            }
            case '/vendors/': {
               return breadCrumbDataForVendorProfilePage
            }
            default: {
                return []
            }
        }
    }

    /**
     * function to toggle vendor activation
     */
    const onToggleActivation = () => {
        const data = {
            active: !vendor.active,
        };

        toggleActivation(params.id, data).then(res => {
            setOpenToggleActivationConfirmationDialog(false);
        });
    };

    return (
        <Box
            className={
                role === ROLES.SIRENMD_ADMIN &&
                getIsTeamList() === 'false'
                    ? ''
                    : classes.outerContainer
            }
        >
            <Box
                className={
                    role === ROLES.SIRENMD_ADMIN &&
                    getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainer
                        : getIsTeamList() === 'false'
                        ? classes.breadcrumbsContainerForAdmins
                        : classes.breadcrumbsContainerForUsers
                }
                component={Paper}
                p={1}
            >
                <Breadcrumbs>
                    {getBreadcrumbData(currentUrlPath).map((crumb, index) => (
                        <Box
                            key={index}
                            component={NavLink}
                            exact
                            to={{
                                pathname: crumb.path,
                                state: {
                                    hcorgIdHistory:
                                        location.state && location.state.hcorgIdHistory,
                                    hcorgNameHistory:
                                        location.state && location.state.hcorgNameHistory,
                                },
                            }}
                            className={classes.breadcrumbItem}
                            activeClassName={classes.activeBreadcrumbItem}
                        >
                            {crumb.icon && (
                                <Icon className={classes.breadcrumpIcon}>{crumb.icon}</Icon>
                            )}
                            <Box className={classes.breadcrumpLabel}>{crumb.name}</Box>
                        </Box>
                    ))}
                </Breadcrumbs>
            </Box>

            <Box mt={3}>
                {vendor && (
                    <>
                        <Box>
                            <Box component={Paper} className={classes.profileContainer}>
                                <Box className={classes.profileContentContainer}>
                                    <Box>
                                        <ImageLoader
                                            src={vendor.imageUrl ? vendor.imageUrl : defaultProfile}
                                        >
                                            <img
                                                alt='profile'
                                                className={`${classes.profileImage} ${
                                                    vendor.imageUrl ? '' : classes.imageBorder
                                                }`}
                                            />
                                            <div className={classes.profileImageError}>
                                                {vendor.id}
                                            </div>
                                            <Preloader
                                                className={`${classes.profileImage} ${
                                                    vendor.imageUrl ? '' : classes.imageBorder
                                                }`}
                                            />
                                        </ImageLoader>
                                    </Box>
                                    <Box className={classes.profileTextContainer}>
                                        <Typography
                                            classes={{
                                                root: classes.orgNameText,
                                            }}
                                        >
                                            {vendor.name}
                                        </Typography>
                                        <Typography
                                            classes={{
                                                root: classes.orgDescText,
                                            }}
                                        >
                                            {vendor.desc}
                                        </Typography>

                                        <Box className={classes.editDetailsButtonContainer}>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                disableElevation={true}
                                                startIcon={<Icon>edit</Icon>}
                                                component={Link}
                                                to={{
                                                    pathname: `${match.path.split(':')[0]}edit/${
                                                        params.id
                                                    }`,
                                                    state: {
                                                        hcorgIdHistory:
                                                            location.state && location.state.hcorgIdHistory,
                                                        hcorgNameHistory:
                                                            location.state && location.state.hcorgNameHistory,
                                                    },
                                                }}
                                                classes={{root: classes.profileButton}}
                                            >
                                                Edit Details
                                            </Button>
                                        </Box>

                                        <Box>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                disableElevation={true}
                                                startIcon={
                                                    vendor.active === false ? (
                                                        <Icon>check_circle_outline</Icon>
                                                    ) : (
                                                        <Icon>block</Icon>
                                                    )
                                                }
                                                onClick={() =>
                                                    setOpenToggleActivationConfirmationDialog(true)
                                                }
                                                classes={{
                                                    root: `${classes.profileButton} ${
                                                        vendor.active
                                                            ? classes.blockButton
                                                            : classes.activeButton
                                                    }`,
                                                }}
                                            >
                                                {vendor.active === false ? 'Activate' : 'Deactivate'}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </>
                )}

                {vendor && (
                    <Box>
                        <ConfirmationDialog
                            title={vendor.active ? 'Deactivate' : 'Activate'}
                            message={
                                <ToggleActivationDialogMessage
                                    vendor={vendor}
                                    classes={classes}
                                />
                            }
                            open={openToggleActivationConfirmationDialog}
                            setOpen={setOpenToggleActivationConfirmationDialog}
                            onConfirm={onToggleActivation}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        vendor: state.vendors.vendor,
    };
};

const mapDispatchToProp = dispatch => {
    return {
        getVendorById: id => {
            return dispatch(getVendorByIdRequest(id)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },

        toggleActivation: (vendorId, data) => {
            return dispatch(toggleActivationRequest(vendorId, data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProp)(VendorsProfile);
