import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => {
    return {
        actionsContainer: {
            padding: '16px 20px',
            paddingTop: '0px',
        },
        content:{
            marginTop: '1rem'
        }
    };
});
