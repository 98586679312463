export const styles = theme => {
    return {
        text: {
            color: theme.palette.error.main,
            fontSize: '0.75rem',
            marginLeft: '14px',
            marginTop: '5px',
        },
    };
};
