export const GET_ORGANISATIONS = 'GET_ORGANISATIONS';
export const GET_ORGANISATIONS_WITHOUT_PARAMS =
    'GET_ORGANISATIONS_WITHOUT_PARAMS';
export const ADD_ORGANISATION = 'ADD_ORGANISATION';
export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION';
export const GET_ORGANISATION_BY_ID = 'GET_ORGANISATION_BY_ID';

export const GET_ORG_TYPES = 'GET_ORG_TYPES';
export const GET_ORG_SUBTYPES = 'GET_ORG_SUBTYPES';
export const GET_READ_RECEIPT_STATUS = 'GET_READ_RECEIPT_STATUS';

export const GET_ORGS_FOR_HC_ORG = 'GET_ORGS_FOR_HC_ORG';
export const TOGGLE_ORG_ACTIVATION = 'TOGGLE_ORG_ACTIVATION';
export const SET_TAB_VALUE = 'SET_TAB_VALUE';

/**
 * action creator function to handle tab change
 * @param tabValue: tabValue from api response data
 * @returns {{tabValue, type: string}}
 */
export const handleTabChange = tabValue => {
    return {
        type: SET_TAB_VALUE,
        tabValue: tabValue,
    };
};

/**
 * action creator function to get organizations
 * @param orgs: orgs from api response data
 * @returns {{orgs, type: string}}
 */
export const getOrganisations = orgs => {
    return {
        type: GET_ORGANISATIONS,
        orgs: orgs,
    };
};

/**
 * action creator function to get organisations without params
 * @param orgs: orgs from api response data
 * @returns {{orgs, type: string}}
 */
export const getOrganisationsWithoutParams = orgs => {
    return {
        type: GET_ORGANISATIONS_WITHOUT_PARAMS,
        orgs: orgs,
    };
};

/**
 * action creator function to add organization
 * @param org: org from api response data
 * @returns {{org, type: string}}
 */
export const addOrganisation = org => {
    return {
        type: ADD_ORGANISATION,
        org: org,
    };
};

/**
 * action creator function to get organization by id
 * @param org: org from api response data
 * @returns {{org, type: string}}
 */
export const getOrgById = org => {
    return {
        type: GET_ORGANISATION_BY_ID,
        org: org,
    };
};

/**
 * action creator function to update organization
 * @param org: org from api response data
 * @returns {{org, type: string}}
 */
export const updateOrg = org => {
    return {
        type: UPDATE_ORGANISATION,
        org: org,
    };
};

/**
 * action creator function to get organization types
 * @param orgTypes: orgTypes from api response data
 * @returns {{orgTypes, type: string}}
 */
export const getOrgTypes = orgTypes => {
    return {
        type: GET_ORG_TYPES,
        orgTypes: orgTypes,
    };
};

/**
 * action creator function to get organization sub types
 * @param orgSubTypes: orgSubTypes from api response data
 * @returns {{orgSubTypes, type: string}}
 */
export const getOrgSubTypes = orgSubTypes => {
    return {
        type: GET_ORG_SUBTYPES,
        orgSubTypes: orgSubTypes,
    };
};

/**
 * action creator function to get read receipt status
 * @param readReceiptStatus: readReceiptStatus from api response data
 * @returns {{type: string, readReceiptStatus}}
 */
export const getReadReceiptStatus = readReceiptStatus => {
    return {
        type: GET_READ_RECEIPT_STATUS,
        readReceiptStatus: readReceiptStatus,
    };
};

/**
 * action creator function to get organizations for a healthcare organization
 * @param orgsForHCOrg: hcorg from api response data
 * @returns {{orgsForHCOrg, type: string}}
 */
export const getOrgsForHCOrg = orgsForHCOrg => {
    return {
        type: GET_ORGS_FOR_HC_ORG,
        orgsForHCOrg: orgsForHCOrg,
    };
};

/**
 * action creator function to toggle organization activation
 * @param org: org from api response data
 * @returns {{org, type: string}}
 */
export const toggleActivation = org => {
    return {
        type: TOGGLE_ORG_ACTIVATION,
        org: org,
    };
};
