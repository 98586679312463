import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider,} from '@material-ui/core';
import {ValidatorForm} from 'react-material-ui-form-validator';
import {DropzoneAreaValidator} from '../../../../utilities/validators';
import UtilHelper from '../../../../utilities/validators/UtilHelper';
import {useStyles} from './styles';
import {
    createCaregiverBatchRequest,
    getCaregiverBatchesRequest,
    getSignedUrlRequest,
    uploadToS3Request,
} from '../../DataUtilitiesApiActions';

/**
 * function to render caregiver batch uploader
 * @param open: to open the uploader dialog
 * @param setOpen: to change the uploader open state
 * @param createCaregiverBatch: function to create caregiver batch
 * @param getCaregiverBatches: function to get caregiver batches
 * @param getSignedUrl: function to get signed url
 * @param uploadToS3: function to upload file on signed url
 * @returns {JSX.Element}
 * @constructor
 */
const BatchUploader = ({
                           open,
                           setOpen,
                           createCaregiverBatch,
                           getCaregiverBatches,
                           getSignedUrl,
                           uploadToS3,
                       }) => {
    const classes = useStyles();
    const [file, setFile] = useState(null);

    useEffect(() => {
        ValidatorForm.addValidationRule('isExelFile', value =>
            UtilHelper.vaidateExelFile(value)
        );
        return () => {
            ValidatorForm.removeValidationRule('isExelFile');
        };
    }, []);

    /**
     * function to handle upload batch
     */
    const onUpload = () => {
        const fileData = {
            fileName: file[0].name,
            fileType: `.${file[0].name.split('.').pop()}`,
        };

        getSignedUrl(fileData).then(res => {
            uploadToS3(res.urls, file[0]).then(uploadRes => {
                const data = {
                    path: res.path,
                };
                createCaregiverBatch(data).then(res => {
                    handleClose();
                    getCaregiverBatches('CAREGIVER').then(res => {
                    });
                });
            });
        });
    };

    /**
     * function to close uploader dialog
     */
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog maxWidth='sm' fullWidth open={open} disableBackdropClick>
            <ValidatorForm onSubmit={onUpload}>
                <DialogTitle>Upload Batch</DialogTitle>
                <Divider/>

                <DialogContent classes={{root: classes.contentContainer}}>
                    <DropzoneAreaValidator
                        dropzoneClass={classes.dropzoneContainer}
                        dropzoneParagraphClass={classes.dropzoneText}
                        filesLimit={1}
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        useChipsForPreview
                        dropzoneText='Drag and drop or click a excel (.xlsx) file to import'
                        previewText='Selected file'
                        showAlerts={false}
                        value={file}
                        onChange={files => setFile(files)}
                        validators={['required', 'isExelFile']}
                        errorMessages={[
                            'file is required',
                            'Please select excel (.xlsx) file',
                        ]}
                    />
                </DialogContent>

                <DialogActions classes={{root: classes.actionsContainer}}>
                    <Button variant='outlined' onClick={handleClose} color='primary'>
                        Cancel
                    </Button>
                    <Button
                        type='submit'
                        variant='contained'
                        disableElevation
                        color='primary'
                    >
                        Upload
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        getSignedUrl: data => {
            return dispatch(getSignedUrlRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        uploadToS3: (url, file) => {
            return dispatch(uploadToS3Request(url, file)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        createCaregiverBatch: data => {
            return dispatch(createCaregiverBatchRequest(data)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getCaregiverBatches: type => {
            return dispatch(getCaregiverBatchesRequest(type)).then(
                res => {
                    return Promise.resolve(res);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchUploader);
